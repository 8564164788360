import {Component, EventEmitter, OnInit, ViewChild} from "@angular/core";
import {ActivatedRoute, NavigationEnd, Router} from "@angular/router";
import { FormGroup, FormControl, Validators, FormBuilder } from "@angular/forms";
import { AuthService } from "../../services/auth.service";
import { UserService } from "../../services/user.service";
import { ActiveService } from "../../services/active.service";
import { AccountService } from "../../services/account.service";
import { FileService } from "../../services/file.service";
import { ActorService } from "../../services/actor.service";
import { ItemService } from "../../services/item.service";
import { UploadOutput, UploadInput, UploadFile, humanizeBytes, UploaderOptions } from "ngx-uploader";
import {AngularMyDatePickerDirective, IAngularMyDpOptions, IMyDateModel} from "angular-mydatepicker";
import { Observable } from "rxjs";
import { delay } from "rxjs/operators";
import * as moment from "moment/moment";
declare var UIkit: any;

@Component({
  selector: "app-single-artist",
  templateUrl: "./single-artist.component.html",
  styleUrls: ["./single-artist.component.scss"]
})
export class SingleArtistComponent implements OnInit {
  public user: any = {};
  public activeArtist: any = {};
  public activeArtistId = "";
  public actorType: string;
  public activeAccount: any = {};
  public accounts: Array<Object> = [];
  public variables: Array<string> = [];
  public opportunityClients: Array<any> = [];
  public allClientGroups: Array<any> = [];
  public allLanguages: Array<any> = [];
  public selectedClientGroups: Array<any> = [];
  public selectedLanguages: Array<any> = [];
  public currentItem: any;
  public previousItems: Array<any> = [];
  public nextItems: Array<any> = [];
  public isLoading = true;
  public uploadLoading: Boolean = false;
  public editArtistForm: FormGroup;
  public addArtistImageModalWindow: any;
  public getFilteredCSVModalWindow: any;
  public options: UploaderOptions;
  public formData: FormData;
  public filesToUpload: UploadFile[];
  public uploadInput: EventEmitter<UploadInput>;
  public humanizeBytes: Function;
  public dragOver: boolean;
  public myDatePickerOptions: IAngularMyDpOptions = {
    dateFormat: "dd.mm.yyyy"
  };
  // @ViewChild("AngularMyDatePickerEditArtistBirthday") AngularMyDatePickerEditArtistBirthday: AngularMyDatePickerDirective;
  public imageLoadOffset$: any;
  public numberWithDecimal = new RegExp(/^[0-9]+(\.[0-9]{1,2})?$/);

  constructor(
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private formBuilder: FormBuilder,
    private auth: AuthService,
    private userService: UserService,
    private activeService: ActiveService,
    private accountService: AccountService,
    private actorService: ActorService,
    private itemService: ItemService,
    private fileService: FileService
  ) {
    this.activatedRoute.data.subscribe(data => {
      this.activeArtist = {
        ...data.resolvedData,
        items: data.resolvedData.items ?? [],
        exhibitions: data.resolvedData.exhibitions ?? [],
        collections: data.resolvedData.collections ?? [],
        bids: data.resolvedData.bids ?? [],
        reservations: data.resolvedData.reservations ?? [],
        trades: data.resolvedData.trades ?? [],
        interestedClients: data.resolvedData.interestedClients ?? [],
        clients: []
      };

      this.activeArtistId = data.resolvedData._id;
      if (!this.activeArtist.images) {
        this.activeArtist.images = [];
      }

      const mergedArrays = [
        ...this.activeArtist.bids,
        ...this.activeArtist.reservations,
        ...this.activeArtist.trades
      ];

      for (const item of mergedArrays) {
        // bidillä ja reservationilla on seller, customer
        // tradella on seller ja buyer

        if (item.customer && item.customer._id) {
          const index = this.activeArtist.clients.findIndex((actor: any) => actor._id === item.customer._id);
          if (index === -1) { this.activeArtist.clients.push(item.customer); }
        }

        if (item.buyer && item.buyer._id) {
          const index = this.activeArtist.clients.findIndex((actor: any) => actor._id === item.buyer._id);
          if (index === -1) { this.activeArtist.clients.push(item.buyer); }
        }

        if (item.seller && item.seller._id) {
          const index = this.activeArtist.clients.findIndex((actor: any) => actor._id === item.seller._id);
          if (index === -1) { this.activeArtist.clients.push(item.seller); }
        }
      }

      // interestedClients
      for (const interestedClient of this.activeArtist.interestedClients) {
        this.opportunityClients.push({
          _id: interestedClient._id,
          name: interestedClient.name,
          lastName: interestedClient.lastName,
          email: interestedClient.email,
          clientGroup: interestedClient.clientGroup,
          languages: interestedClient.languages,
          status: interestedClient.status,
        });
        this.addToAllLanguages(interestedClient.languages);
        this.addToAllClientGroups(interestedClient.clientGroup);
      }
    });


    this.router.events.subscribe((evt) => {
      if (!(evt instanceof NavigationEnd)) {
        return;
      }
      this.getPrevAndNextItems(this.router.getCurrentNavigation().extras.state);
    });

    this.editArtistForm = this.formBuilder.group({
      actorType: new FormControl("", [
        Validators.maxLength(100)
      ]),
      editArtistId: new FormControl("", [
        Validators.required,
        Validators.maxLength(100)
      ]),
      editArtistOwner: new FormControl("", [
        Validators.required,
        Validators.maxLength(100)
      ]),
      editArtistName: new FormControl("", [
        Validators.required,
        Validators.maxLength(100)
      ]),
      editArtistLastName: new FormControl("", [
        Validators.required,
        Validators.maxLength(100)
      ]),
      editArtistCode: new FormControl("", [
        Validators.maxLength(100)
      ]),
      // editArtistBirthday: new FormControl("", [
      //   Validators.maxLength(10)
      // ]),
      editArtistStreet: new FormControl("", [
        Validators.maxLength(100)
      ]),
      editArtistCity: new FormControl("", [
        Validators.maxLength(30)
      ]),
      editArtistState: new FormControl("", [
        Validators.maxLength(30)
      ]),
      editArtistZip: new FormControl("", [
        Validators.maxLength(10),
        Validators.pattern(this.numberWithDecimal)
      ]),
      editArtistCountry: new FormControl("", [
        Validators.maxLength(30)
      ]),
      editArtistEmail: new FormControl("", [
        Validators.maxLength(100)
      ]),
      editArtistPhone: new FormControl("", [
        Validators.maxLength(100),
        // Validators.pattern(this.numberWithDecimal)
      ]),
      editArtistDescription: new FormControl("", [
        Validators.maxLength(2048)
      ]),
      editArtistMultiplier: new FormControl("", [
        Validators.maxLength(10),
        Validators.pattern(this.numberWithDecimal)
      ]),
      editArtistImages: new FormControl(""),
      editArtistCoverImage: new FormControl(""),
      editArtistStatus: new FormControl("", [
        Validators.maxLength(30)
      ]),
      editArtistType: new FormControl("", [
        Validators.maxLength(30)
      ]),
      editArtistTags: new FormControl("", [
        Validators.maxLength(2048)
      ]),
      editArtistNotes: new FormControl("", [
        Validators.maxLength(2048)
      ]),
      editArtistComments: new FormControl("", [
        Validators.maxLength(2048)
      ]),
      editArtistVisibility: new FormControl("", [
        Validators.maxLength(10)
      ])
      /*editArtistAttachments: new FormControl('', [
        Validators.maxLength(100)
      ])*/
    });
    // this.activeArtist.birthday = this.activeArtist.birthday ? this.createDate(this.activeArtist.birthday) : null;

    this.filesToUpload = [];
    this.uploadInput = new EventEmitter<UploadInput>();
    this.humanizeBytes = humanizeBytes;
  }

  ngOnInit() {
    this.actorType = "Artist";
    this.getUser();
  }

  getPrevAndNextItems(state: any) {
    if (state && state.currentItem) {
      this.currentItem = state.currentItem;

      if (state.currentItem.images) {
        this.activeArtist.images = state.currentItem.images;
      }
    }

    if (state && state.previousItems) {
      if (!Array.isArray(state.previousItems)) {
        this.previousItems = [state.previousItems];
      } else {
        this.previousItems = state.previousItems;
      }
    }

    if (state && state.nextItems) {
      if (!Array.isArray(state.nextItems)) {
        this.nextItems = [state.nextItems];
      } else {
        this.nextItems = state.nextItems;
      }
    }
  }

  getAccountData() {
    const getObject = {
      accountId: this.activeAccount,
      requester: "artist",
      sortingKey: "_id",
      sortingOrder: "desc",
      skipQuery: 0,
      limitQuery: null,
      contentTypes: ["account", "variable"]
    };

    this.accountService.getAccountData(getObject).subscribe(
      data => {
        this.allClientGroups = data.account.clientGroupTitles ?? [];
        this.allLanguages = data.account.languageTitles ?? [];
        this.variables = data.variables ? data.variables : [];
      },
      () => {
        UIkit.notification({
          message: "Error fetching artist",
          status: "danger",
          pos: "top-right",
          timeout: 1500
        });
      },
      () => {
        this.isLoading = false;
      }
    );
  }

  addToAllLanguages(languages: Array<any>): void {
    if (languages && languages.length > 0) {
      languages.forEach((language: any) => {
        if (language.title) {
          const languageIncluded: boolean = this.allLanguages.filter(allLanguage => allLanguage.title.toLowerCase() === language.title.toLowerCase()).length > 0;
          if (!languageIncluded) {
            this.allLanguages.push(language);
          }
        }
      });
    }
  }

  addToAllClientGroups(clientGroups: Array<any>): void {
    if (clientGroups && clientGroups.length > 0) {
      clientGroups.forEach((clientGroup: any) => {
        if (clientGroup.title) {
          const clientGroupIncluded: boolean = this.allClientGroups.filter(allClientGroup => allClientGroup.title.toLowerCase() === clientGroup.title.toLowerCase()).length > 0;
          if (!clientGroupIncluded) {
            this.allClientGroups.push(clientGroup);
          }
        }
      });
    }
  }

  selectAllLanguages(): void {
    this.allLanguages.forEach((allLanguage: any) => {
      this.selectedLanguages.push(allLanguage);
    });
  }

  removeAllSelectedLanguages(): void {
    this.selectedLanguages.length = 0;
  }

  toggleSelectedLanguage(language: any): void {
    const languageIncluded: boolean = this.selectedLanguages.filter(selectedLanguage => selectedLanguage.title === language.title).length > 0;
    if (!languageIncluded) {
      this.selectedLanguages.push(language);
    } else {
      this.selectedLanguages.splice(this.selectedLanguages.indexOf(language), 1);
    }
  }

  isLanguageSelected(language: any): boolean {
    return this.selectedLanguages.filter(selectedLanguage => selectedLanguage && selectedLanguage.title === language.title).length > 0;
  }

  selectAllClientGroups(): void {
    this.allClientGroups.forEach((allClientGroup: any) => {
      this.selectedClientGroups.push(allClientGroup);
    });
  }

  removeAllSelectedClientGroups(): void {
    this.selectedClientGroups.length = 0;
  }

  toggleSelectedClientGroup(clientGroup: any): void {
    const clientGroupIncluded: boolean = this.selectedClientGroups.filter(selectedClientGroup => selectedClientGroup && selectedClientGroup.title === clientGroup.title).length > 0;
    if (!clientGroupIncluded) {
      this.selectedClientGroups.push(clientGroup);
    } else {
      this.selectedClientGroups.splice(this.selectedClientGroups.indexOf(clientGroup), 1);
    }
  }

  isClientGroupSelected(clientGroup: any): boolean {
    return this.selectedClientGroups.filter(selectedClientGroup => selectedClientGroup.title === clientGroup.title).length > 0;
  }

  getUser() {
    this.activeService.getActiveData().subscribe(
      data => {
        this.activeAccount = data.account.id;
        this.userService.getUser(data.user).subscribe(
          data => {
            this.user = data;
            /*if (this.user.account[0]) {
              this.user.role = this.user.account[0].role;
            }*/
            this.getUsersAccounts();
            this.getAccountData();
          },
          () => {
            UIkit.notification({
              message: "Error fetching user information",
              status: "danger",
              pos: "top-right",
              timeout: 1500
            });
          }
        );
      }
    );
  }

  // TODO: Tämäkin pitäis siirtää resolvattaksi ns. MasterServicen avulla, jolloin myös aktiivisen accountin tiedot tulisivat sieltä
  getUsersAccounts() {
    this.accountService.getUserAccounts(this.user).subscribe(
      data => {
        this.accounts = data;
      },
      () => {
        UIkit.notification({
          message: "Error fetching accounts",
          status: "danger",
          pos: "top-right",
          timeout: 1500
        });
      },
      () => this.isLoading = false
    );
  }

  editArtist() {
    const editArtistObject = {
      actorType: this.actorType,
      name: this.editArtistForm.value.editArtistName,
      lastName: this.editArtistForm.value.editArtistLastName,
      owner: this.editArtistForm.value.editArtistOwner,
      artistCode: this.editArtistForm.value.editArtistCode,
      artistType: this.editArtistForm.value.editArtistType,
      // birthday: (this.editArtistForm.value.editArtistBirthday && this.editArtistForm.value.editArtistBirthday.singleDate && this.editArtistForm.value.editArtistBirthday.singleDate.jsDate) ? moment(this.editArtistForm.value.editArtistBirthday.singleDate.jsDate).format() : this.editArtistForm.value.editArtistBirthday,
      street: this.editArtistForm.value.editArtistStreet,
      city: this.editArtistForm.value.editArtistCity,
      state: this.editArtistForm.value.editArtistState,
      zip: this.editArtistForm.value.editArtistZip,
      country: this.editArtistForm.value.editArtistCountry,
      email: this.editArtistForm.value.editArtistEmail,
      phone: this.editArtistForm.value.editArtistPhone,
      description: this.editArtistForm.value.editArtistDescription,
      priceMultiplier: this.editArtistForm.value.editArtistMultiplier,
      status: this.editArtistForm.value.editArtistStatus,
      tags: this.editArtistForm.value.editArtistTags,
      notes: this.editArtistForm.value.editArtistNotes,
      comments: this.editArtistForm.value.editArtistComments,
      images: this.activeArtist["images"],
      coverImage: this.activeArtist["coverImage"],
      visibility: this.editArtistForm.value.editArtistVisibility,
      attachments: this.activeArtist["attachments"]
    };

    this.actorService.editActor(this.editArtistForm.value.editArtistId, editArtistObject).subscribe(
      res => {
        UIkit.notification({
          message: "Saved successfully",
          status: "success",
          pos: "top-right",
          timeout: 1500
        });
      },
      error => {
        UIkit.notification({
          message: "Saving failed",
          status: "danger",
          pos: "top-right",
          timeout: 1500
        });
      }
    );
  }

  openArtwork(artworkId): void {
    this.router.navigateByUrl("/artwork/" + artworkId);
  }

  removeArtwork(artworkId): void {
    this.itemService.deleteItem(artworkId).subscribe(
      () => {
        this.ngOnInit();
        UIkit.notification({
          message: "Artwork removed successfully",
          status: "success",
          pos: "top-right",
          timeout: 1500
        });
      },
      () => {
        UIkit.notification({
          message: "Error removing artworks",
          status: "danger",
          pos: "top-right",
          timeout: 1500
        });
      }
    );
  }

  openImage(attachment) {
    window.open("/api/originalimage/" + this.activeAccount + "/" + attachment.fileId, "_blank");
  }

  removeImage(image) {
    const imageObject = {
      documentType: "artist",
      itemId: this.activeArtistId,
      fileId: image.fileId
    };

    this.fileService.removeFile(imageObject).subscribe(
      res => {
        const imageToSplice = this.activeArtist["images"].find((accountImage: any) => {
          return image._id === accountImage._id;
        });
        this.activeArtist.images.splice(this.activeArtist.images.indexOf(imageToSplice), 1);
        UIkit.notification({
          message: "Image removed successfully",
          status: "success",
          pos: "top-right",
          timeout: 1500
        });
      },
      error => {
        UIkit.notification({
          message: "Removing failed",
          status: "danger",
          pos: "top-right",
          timeout: 1500
        });
      }
    );
  }

  onAttachmentsUploadOutput(output: UploadOutput): void {
    const properFile = output.file && output.file.name && output.file.name.length > 0;

    if (output.type === "allAddedToQueue") {
      const event: UploadInput = {
        type: "uploadAll",
        url: "/api/file",
        method: "POST",
        includeWebKitFormBoundary: true
      };
      this.uploadInput.emit(event);
    } else if (output.type === "addedToQueue" && properFile) {
      if (this.filesToUpload.indexOf(output.file) == -1) {
        this.filesToUpload.push(output.file);
      }
    } else if (output.type === "uploading" && properFile) {
      if (!this.activeArtist["attachments"].some(image => image.placeholderId === output.file.id)) {
        this.uploadLoading = true;
        this.activeService.increasePendingRequests();
        this.activeArtist["attachments"].push({ placeholderId: output.file.id });
        const index = this.filesToUpload.findIndex(file => file.id === output.file.id);
        this.filesToUpload[index] = output.file;
      }
    } else if (output.type === "removed") {
      // remove file from array when removed
      this.filesToUpload = this.filesToUpload.filter((file: UploadFile) => file !== output.file);
    } else if (output.type === "dragOver") {
      this.dragOver = true;
    } else if (output.type === "dragOut") {
      this.dragOver = false;
    } else if (output.type === "drop") {
      this.dragOver = false;
    } else if (output.type === "removedAll") {
      this.filesToUpload.length = 0;
    } else if (output.type === "done") {
      const fileObject = {
        itemId: this.activeArtistId,
        fileId: output.file.response,
        name: output.file.name,
        contentType: output.file.type
      };
      this.removeFile(output.file.id);
      const indexToReplace = this.activeArtist["attachments"].findIndex(file => file.placeholderId === output.file.id);
      this.activeArtist["attachments"][indexToReplace] = fileObject;
      this.activeService.decreasePendingRequests();
      this.uploadLoading = false;

      /*this.activeArtist.images.push({
        /!*contentType: output.file.response.mimetype,
        filename: output.file.response.filename,*!/
        fileId: output.file.response.id
      });*/
      /*});*/
    }
  }

  startUpload(): void {
   const event: UploadInput = {
      type: "uploadAll",
      url: "/api/file",
      method: "POST",
      includeWebKitFormBoundary: true
    };
    this.uploadInput.emit(event);
  }

  cancelUpload(id: string): void {
    this.uploadInput.emit({ type: "cancel", id: id });
  }

  removeFile(id: string): void {
    this.uploadInput.emit({ type: "remove", id: id });
  }

  openAttachment(attachment) {
    this.itemService.openAttachment(this.activeAccount, attachment).then((response: any) => {
      if (response.url) {
        window.open(response.url);
      } else {
        UIkit.notification({
          message: "Error fetching data",
          status: "danger",
          pos: "top-right",
          timeout: 1500
        });
      }
    });
  }

  removeAttachment(attachment) {
    const attachmentObject = {
      documentType: "artist",
      itemId: this.activeArtistId,
      fileId: attachment.fileId
    };

    this.fileService.removeFile(attachmentObject).subscribe(
      res => {
        const attachmentToSplice = this.activeArtist.attachments.find((accountAttachment: any) => {
          return attachment.fileId === accountAttachment.fileId;
        });
        this.activeArtist.attachments.splice(this.activeArtist.attachments.indexOf(attachmentToSplice), 1);
        UIkit.notification({
          message: "File removed successfully",
          status: "success",
          pos: "top-right",
          timeout: 1500
        });
      },
      error => {
        UIkit.notification({
          message: "Removing failed",
          status: "danger",
          pos: "top-right",
          timeout: 1500
        });
      }
    );
  }

  onUploadOutput(output: UploadOutput): void {
    const properFile = output.file && output.file.name && output.file.name.length > 0;

    if (output.type === "allAddedToQueue") {
      const event: UploadInput = {
        type: "uploadAll",
        url: "/api/file",
        method: "POST",
        includeWebKitFormBoundary: true
      };
      this.uploadInput.emit(event);
    } else if (output.type === "addedToQueue" && properFile) {
      if (this.filesToUpload.indexOf(output.file) == -1) {
        this.filesToUpload.push(output.file);
      }
    } else if (output.type === "uploading" && properFile) {
      if (!this.activeArtist["images"].some(image => image.placeholderId === output.file.id)) {
        this.uploadLoading = true;
        this.activeService.increasePendingRequests();
        this.activeArtist["images"].push({ placeholderId: output.file.id });
        const index = this.filesToUpload.findIndex(file => file.id === output.file.id);
        this.filesToUpload[index] = output.file;
      }
    } else if (output.type === "removed") {
      // remove file from array when removed
      this.filesToUpload = this.filesToUpload.filter((file: UploadFile) => file !== output.file);
    } else if (output.type === "dragOver") {
      this.dragOver = true;
    } else if (output.type === "dragOut") {
      this.dragOver = false;
    } else if (output.type === "drop") {
      this.dragOver = false;
    } else if (output.type === "removedAll") {
      this.filesToUpload.length = 0;
    } else if (output.type === "done") {
      const fileObject = {
        itemId: this.activeArtistId,
        fileId: output.file.response
      };
      this.itemService.addArtistImageId(fileObject).subscribe(() => {
        this.removeFile(output.file.id);
        const indexToReplace = this.activeArtist["images"].findIndex(file => file.placeholderId === output.file.id);
        this.activeArtist["images"][indexToReplace] = fileObject;
        this.activeService.decreasePendingRequests();
        this.uploadLoading = false;
      });
    }
  }

  openAddArtistImage() {
    this.addArtistImageModalWindow = window.document.getElementById("addArtistImages");
    UIkit.modal(this.addArtistImageModalWindow).show();
  }

  selectCover(fileId: string): void {
    this.editArtistForm.patchValue(
      {
        editArtistCoverImage: fileId
      }
    );
    this.activeArtist["coverImage"] = fileId;
  }

  getCSV(): void {
    const getCSVObject = {
      Name: this.editArtistForm.value.editArtistName + " " + this.editArtistForm.value.editArtistLastName,
      "Artist code": this.editArtistForm.value.editArtistCode,
      "Artist type": this.editArtistForm.value.editArtistType,
      "Street": this.editArtistForm.value.editArtistStreet,
      "City": this.editArtistForm.value.editArtistCity,
      "State": this.editArtistForm.value.editArtistState,
      "Zip": this.editArtistForm.value.editArtistZip,
      "Email": this.editArtistForm.value.editArtistEmail,
      "Phone": this.editArtistForm.value.editArtistPhone,
      "Description": this.editArtistForm.value.editArtistDescription,
      "Price multiplier": this.editArtistForm.value.editArtistMultiplier,
      "Status": this.editArtistForm.value.editArtistStatus,
      "Tags": this.editArtistForm.value.editArtistTags,
      "Notes": this.editArtistForm.value.editArtistNotes,
      "Comments": this.editArtistForm.value.editArtistComments,
      "Artworks": this.activeArtist.items,
      /*images: this.activeArtist['images'],*/
      /*coverImage: this.editArtistForm.value.editArtistCoverImage,*/
      "Visibility": this.editArtistForm.value.editArtistVisibility,
    };
    this.fileService.getCSV("Artist", getCSVObject);
  }

  createDate(inputDate: string): any {
    return {
      isRange: false,
      singleDate: {
        date: {
          year: new Date(inputDate).getFullYear(),
          month: new Date(inputDate).getMonth() + 1,
          day: new Date(inputDate).getDate()
        }
      }
    };
  }

  updateDate(fieldName: string, dateObject: any) {
    this.editArtistForm.controls[fieldName].patchValue(moment(dateObject.singleDate.jsDate).format());
  }

  openExhibition(exhibitionId): void {
    this.router.navigateByUrl("/exhibition/" + exhibitionId);
  }

  openCollection(collectionId): void {
    this.router.navigateByUrl("/collection/" + collectionId);
  }

  openBid(bidId): void {
    this.router.navigateByUrl("/bid/" + bidId);
  }

  openReservation(reservationId): void {
    this.router.navigateByUrl("/reservation/" + reservationId);
  }

  openTrade(tradeId): void {
    this.router.navigateByUrl("/trade/" + tradeId);
  }

  openClient(clientId): void {
    this.router.navigateByUrl("/client/" + clientId);
  }

  getCSVforBid(item: any): void {
    if (item.items && item.items.length) {
      item.items = item.items.map((value: any) => {
        return {_id: value};
      });
    }

    const getCSVObject = {
      "Name": item.name,
      "Buyer name": item.customer,
      "Seller name": item.seller,
      "Agent name": (item.agent && item.agent.lastName) ? (item.agent.name + " " + item.agent.lastName) : item.agent,
      "Price": item.price,
      "Currency": item.currency,
      "Artworks": item.items,
      "Comments": item.comments,
      "Created": moment(item.created).locale("fi").format("YYYY"),
      "Type": item.type,
    };
    this.fileService.getCSV("Proposal", getCSVObject);
  }

  getCSVforAllBids(): void {
    const allBids: Array<any> = [];

    for (const item of this.activeArtist.bids) {
      const itemNames: Array<any> = [];

      if (item.items && item.items.length) {
        item.items = item.items.map((value: any) => {
          return {_id: value};
        });
      }

      const getCSVObject = {
        "Name": item.name,
        "Buyer name": item.customer,
        "Seller name": item.seller,
        "Agent name": (item.agent && item.agent.lastName) ? (item.agent.name + " " + item.agent.lastName) : item.agent,
        "Price": item.price,
        "Currency": item.currency,
        "Artworks": item.items,
        "Comments": item.comments,
        "Created": moment(item.created).locale("fi").format("YYYY"),
        "Type": item.type,
      };

      allBids.push(getCSVObject);
    }

    this.fileService.getCSV("Proposals", allBids);
  }

  getCSVforReservation(item: any): void {
    if (item.items && item.items.length) {
      item.items = item.items.map((value: any) => {
        return {_id: value};
      });
    }

    const getCSVObject = {
      "Name": item.name,
      "Buyer name": item.buyer,
      "Seller name": item.seller,
      "Agent name": item.agent,
      "Price": item.price,
      "Currency": item.currency,
      "Artworks": item.items,
      "Comments": item.comments,
      "Created": moment(item.created).locale("fi").format("YYYY"),
      "Type": item.type,
    };

    this.fileService.getCSV("Reservation", getCSVObject);
  }

  getCSVforAllReservations(): void {
    const allReservations: Array<any> = [];

    for (const item of this.activeArtist.reservations) {
      const itemNames: Array<any> = [];

      if (item.items && item.items.length) {
        item.items = item.items.map((value: any) => {
          return {_id: value};
        });
      }

      const getCSVObject = {
        "Name": item.name,
        "Buyer name": item.buyer,
        "Seller name": item.seller,
        "Agent name": item.agent,
        "Price": item.price,
        "Currency": item.currency,
        "Artworks": item.items,
        "Comments": item.comments,
        "Created": moment(item.created).locale("fi").format("YYYY"),
        "Type": item.type,
      };

      allReservations.push(getCSVObject);
    }

    this.fileService.getCSV("Reservations", allReservations);
  }

  getCSVforTrade(item: any): void {
    if (item.items && item.items.length) {
      item.items = item.items.map((value: any) => {
        return {_id: value};
      });
    }

    const getCSVObject = {
      "Name": item.name,
      "Buyer name": item.buyer,
      "Seller name": item.seller,
      "Agent name": item.agent,
      "Price": item.price,
      "Currency": item.currency,
      "Artworks": item.items,
      "Comments": item.comments,
      "Created": moment(item.created).locale("fi").format("YYYY"),
      "Type": item.type,
    };
    this.fileService.getCSV("Trade", getCSVObject);
  }

  getCSVforAllTrades(): void {
    const allTrades: Array<any> = [];

    for (const item of this.activeArtist.trades) {
      const itemNames: Array<any> = [];

      if (item.items && item.items.length) {
        item.items = item.items.map((value: any) => {
          return {_id: value};
        });
      }

      const getCSVObject = {
        "Name": item.name,
        "Buyer name": item.buyer,
        "Seller name": item.seller,
        "Agent name": item.agent,
        "Price": item.price,
        "Currency": item.currency,
        "Artworks": item.items,
        "Comments": item.comments,
        "Created": moment(item.created).locale("fi").format("YYYY"),
        "Type": item.type,
      };

      allTrades.push(getCSVObject);
    }

    this.fileService.getCSV("Trades", allTrades);
  }

  getCSVforOpportunity(item: any): void {
    let fullName = item.name;
    if (item.lastName) {
      fullName += " " + item.lastName;
    }

    const getCSVObject = {
      Name: fullName,
      Email: item.email,
      "Client group":  this.getArrayAsString(item.clientGroup),
      Status: item.status,
    };

    this.fileService.getCSV("Opportunity", getCSVObject);
  }

  getCSVforAllOpportunities(): void {
    const allClients: Array<any> = [];

    for (const item of this.opportunityClients) {
      let fullName = item.name;
      if (item.lastName) {
        fullName += " " + item.lastName;
      }

      const getCSVObject = {
        Name: fullName,
        Email: item.email,
        "Client group":  this.getArrayAsString(item.clientGroup),
        Status: item.status,
      };

      allClients.push(getCSVObject);
    }

    this.fileService.getCSV("Opportunities", allClients);
  }

  getFilteredCSV(csvType: string): void {
    if (csvType === "Opportunity") {
      const filteredClients: Array<any> = [];

      for (const client of this.opportunityClients) {
        let fullName = client.name;
        if (client.lastName) {
          fullName += " " + client.lastName;
        }

        const getCSVObject = {
          Name: fullName,
          Email: client.email,
          "Client group": this.getArrayAsString(client.clientGroup),
          Status: client.status,
        };

        let clientHasSelectedLanguage = false;
        let clientHasSelectedClientGroup = false;

        if (client.languages && client.languages.length > 0 && this.selectedLanguages.length > 0) {
          this.selectedLanguages.forEach((selectedLanguage: any) => {
            const languagesFound = client.languages.filter(clientLanguage => clientLanguage && clientLanguage.title.toLowerCase() === selectedLanguage.title.toLowerCase()).length > 0;
            if (languagesFound) {
              clientHasSelectedLanguage = true;
            }
          });
        }

        if (client.clientGroup && client.clientGroup.length > 0 && this.selectedClientGroups.length > 0) {
          this.selectedClientGroups.forEach((selectedClientGroup: any) => {
            const clientGroupsFound = client.clientGroup.filter(clientClientGroup => clientClientGroup && clientClientGroup.title.toLowerCase() === selectedClientGroup.title.toLowerCase()).length > 0;
            if (clientGroupsFound) {
              clientHasSelectedClientGroup = true;
            }
          });
        }

        if (clientHasSelectedLanguage || clientHasSelectedClientGroup) {
          filteredClients.push(getCSVObject);
        }
      }

      if (filteredClients && filteredClients.length) {
        this.fileService.getCSV("Opportunities", filteredClients);
      }
    }
  }

  openGetFilteredCSV(): void {
    this.getFilteredCSVModalWindow = window.document.getElementById("getFilteredCSVModal");
    UIkit.modal(this.getFilteredCSVModalWindow).show();
  }

  isArray(obj: any): boolean {
    return Array.isArray(obj);
  }

  getArrayAsString(arrayObject: any): string {
    let contents = "";

    if (Array.isArray(arrayObject)) {
      if (arrayObject.length > 0) {
        arrayObject.forEach((item: any, index: number) => {
          contents += item.title;
          if (index !== arrayObject.length - 1) {
            contents += ", ";
          }
        });
      } else {
        return arrayObject.toString() ?? "";
      }
    }

    return contents;
  }

  openPreviousItem(itemId?: string): void {
    let selectedItemIndex: number;
    let previousItem: any;

    if (this.previousItems.length === 0) {
      UIkit.notification({
        message: "Error opening previous item",
        status: "danger",
        pos: "top-right",
        timeout: 1500
      });
    }

    if (itemId) {
      selectedItemIndex = this.previousItems.findIndex((item: any) => item._id === itemId);

      previousItem = this.previousItems[selectedItemIndex];
      this.nextItems = [...this.previousItems.slice(selectedItemIndex + 1, this.previousItems.length), this.currentItem, ...this.nextItems];
      this.previousItems = this.previousItems.slice(0, selectedItemIndex) ?? [];
    }

    if (!itemId) {
      previousItem = this.previousItems.pop();

      if (this.previousItems.length > 0) {
        this.previousItems = this.previousItems.slice(0, this.previousItems.length);
      }

      this.nextItems = [this.currentItem, ...this.nextItems];
    }

    if (!previousItem) {
      return UIkit.notification({
        message: "Error opening previous item",
        status: "danger",
        pos: "top-right",
        timeout: 1500
      });
    }

    UIkit.dropdown(document.getElementById("itemsDropdown")).hide();

    this.router.navigateByUrl("/artist/" + previousItem._id,
      {
        state: {
          currentItem: previousItem,
          previousItems: this.previousItems,
          nextItems: this.nextItems,
        }
      }
    );
  }

  openNextItem(itemId?: string): void {
    let previousItems: Array<any> = this.previousItems;
    let nextItems: Array<any> = this.nextItems;
    let selectedItemIndex: number;
    let nextItem: any;

    if (this.nextItems.length === 0) {
      return UIkit.notification({
        message: "Error opening next item",
        status: "danger",
        pos: "top-right",
        timeout: 1500
      });
    }

    if (itemId) {
      selectedItemIndex = nextItems.findIndex((item: any) => item._id === itemId);

      nextItem = nextItems[selectedItemIndex];
      previousItems = [...previousItems, this.currentItem, ...nextItems.slice(0, selectedItemIndex)] ?? [];
      nextItems = nextItems.slice(selectedItemIndex + 1, nextItems.length) ?? [];

      if (!nextItem) {
        return UIkit.notification({
          message: "Error opening next item",
          status: "danger",
          pos: "top-right",
          timeout: 1500
        });
      }
    } else {
      nextItem = nextItems.shift();

      if (nextItems.length > 0) {
        nextItems = nextItems.slice(0, nextItems.length);
      }

      previousItems = [...previousItems, this.currentItem];
    }

    UIkit.dropdown(document.getElementById("itemsDropdown")).hide();

    this.router.navigateByUrl("/artist/" + nextItem._id,
      {
        state: {
          currentItem: nextItem,
          previousItems: previousItems,
          nextItems: nextItems,
        }
      }
    );
  }

  getCharactersLimitError(fieldName: string) {
    if (!fieldName || !this.editArtistForm.get(fieldName).value ) {
      return "";
    }

    const charactersOverLimit: number = this.editArtistForm.get(fieldName).value.length - this.editArtistForm.get(fieldName).errors.maxlength.requiredLength;

    return `Field length is ${ charactersOverLimit } characters over limit`;
  }

  getCharactersLeft(fieldName: string, charactersLimit: number) {
    if (!fieldName || !this.editArtistForm.get(fieldName).value ) {
      return "";
    }

    const fieldLength: number = this.editArtistForm.get(fieldName).value.length;
    const charactersOverLimit: number = fieldLength - charactersLimit;

    if (charactersOverLimit >= 0) { return `${ charactersLimit } / ${ charactersLimit }`; }

    return `${ fieldLength } / ${ charactersLimit }`;
  }
}
