<div class="uk-section uk-section-default uk-padding-remove-top" uk-height-viewport="offset-top: true">

  <app-sidenav contentType="Reservations"></app-sidenav>

  <div class="uk-container-expand uk-height-expand">
    <div uk-grid>
      <div class="uk-width-expand@m">
        <div class="uk-padding-small uk-padding-remove-top" *ngIf="!isLoading">
          <div class="uk-width-1-1 uk-text-right uk-margin-small-bottom uk-padding-remove">
            <app-search-content contentType="reservation" fields="['number', 'title']"></app-search-content>
            <button class="uk-button uk-button-text uk-margin-right" type="button"
                    (click)="showOpenReservations()">Show open
            </button>
            <div id="showOpen" class="uk-modal-container" uk-modal="bg-close: false; esc-close: false;">
              <div class="uk-modal-dialog">
                <div class="uk-modal-header">
                  <h2 class="uk-modal-title">Open reservations</h2>
                </div>
                <div class="uk-modal-body" uk-grid>
                  <div class="uk-width-1-1@s">
                    <table class="uk-table uk-table-middle uk-table-divider" *ngIf="openReservations.length">
                      <thead>
                      <tr>
                        <th class="uk-text-nowrap" [ngClass]="{'active': activeSort('sequence') }"
                            (click)="sortReservations('sequence')">Number<span uk-icon="chevron-down"
                                                                               [ngClass]="{'inverse': !sortAscending }"></span>
                        </th>
                        <th class="uk-text-wrap" [ngClass]="{'active': activeSort('name') }"
                            (click)="sortReservations('name')">Title<span uk-icon="chevron-down"
                                                                          [ngClass]="{'inverse': !sortAscending }"></span>
                        </th>
                        <th class="uk-text-nowrap uk-visible@m" [ngClass]="{'active': activeSort('customer') }"
                            (click)="sortReservations('customer')">Customer<span uk-icon="chevron-down"
                                                                                 [ngClass]="{'inverse': !sortAscending }"></span>
                        </th>
                        <th class="uk-text-nowrap uk-visible@m" [ngClass]="{'active': activeSort('type') }"
                            (click)="sortReservations('type')">Type<span uk-icon="chevron-down"
                                                                         [ngClass]="{'inverse': !sortAscending }"></span>
                        </th>
                        <th class="uk-text-nowrap uk-visible@m" [ngClass]="{'active': activeSort('price') }"
                            (click)="sortReservations('price')">Price<span uk-icon="chevron-down"
                                                                           [ngClass]="{'inverse': !sortAscending }"></span>
                        </th>
                        <th class="uk-text-nowrap uk-visible@m" [ngClass]="{'active': activeSort('valid') }"
                            (click)="sortReservations('valid')">Valid<span uk-icon="chevron-down"
                                                                           [ngClass]="{'inverse': !sortAscending }"></span>
                        </th>
                        <th class="uk-table-shrink"></th>
                      </tr>
                      </thead>
                      <tbody>
                      <tr *ngFor="let reservation of openReservations">
                        <td>{{ reservation.sequence }}</td>
                        <td>{{ reservation.name }}</td>
                        <td class="uk-visible@m">{{ reservation.customer.fullName }}</td>
                        <td class="uk-visible@m">{{ reservation.type }}</td>
                        <td class="uk-visible@m">{{ reservation.itemsTotalPrice }} <span *ngIf="reservation.itemsTotalPrice !== 'Unknown'">{{ account.tradingCurrency }}</span></td>
                        <td class="uk-visible@m">{{ reservation.valid | date: 'dd.MM.yyyy' }}</td>
                        <td class="uk-text-right">
                          <div class="uk-button-group">
                            <button (click)="openReservation(reservation._id)"
                                    class="uk-button uk-button-default uk-modal-close" type="button">Open
                            </button>
                            <div class="uk-inline" class="uk-visible@m">
                              <button class="uk-button uk-button-default" type="button"><span
                                uk-icon="icon:  chevron-down"></span></button>
                              <div uk-dropdown="mode: click; boundary: ! .uk-button-group; boundary-align: true;">
                                <ul class="uk-nav uk-dropdown-nav">
                                  <li><a (click)="removeReservation(reservation._id)">Remove</a></li>
                                </ul>
                              </div>
                            </div>
                          </div>
                        </td>
                      </tr>
                      </tbody>
                    </table>
                    <p class="uk-text-center" *ngIf="!openReservations.length">No open reservations</p>
                  </div>
                </div>
                <div class="uk-modal-footer">
                  <p class="uk-text-right">
                    <button class="uk-button uk-button-default uk-modal-close" type="button" id="showOpenClose">Close
                    </button>
                  </p>
                </div>
              </div>
            </div>
            <button class="uk-button uk-button-text uk-margin-right" type="button"
                    (click)="openAddNewReservation()">Add new
            </button>
            <div id="addNewReservation" class="uk-modal-container"
                 uk-modal="stack: true; bg-close: false; esc-close: false;">
              <div class="uk-modal-dialog">
                <form appNoSubmitEnter [formGroup]="addReservationForm">
                  <input type="hidden" name="addReservationOwner" name="addReservationOwner"
                         formControlName="addReservationOwner" [ngModel]="activeAccount">
                  <div class="uk-modal-header">
                    <h2 class="uk-modal-title">Add new reservation</h2>
                  </div>
                  <div class="uk-modal-body" uk-grid>
                    <div class="uk-width-1-2@s">
                      <div class="uk-margin">
                        <label class="uk-form-label" for="addReservationName">Title</label>
                        <div class="uk-form-controls">
                          <input id="addReservationName" name="addReservationName" formControlName="addReservationName"
                                 type="text" placeholder="Title ..." class="uk-input"
                                 [ngClass]="{'uk-form-danger': addReservationForm.get('addReservationName').touched && addReservationForm.get('addReservationName').errors }">
                          <div class="uk-text-danger uk-text-small"
                               *ngIf="addReservationForm.get('addReservationName').hasError('maxlength')"
                          >
                            {{ getCharactersLimitError('addReservationName') }}
                          </div>
                          <div class="uk-text-danger uk-text-small"
                               *ngIf="addReservationForm.get('addReservationName').hasError('pattern')"
                          >
                            Field value is incorrect
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="uk-width-1-2@s">
                      <div class="uk-margin">
                        <div class="uk-flex uk-flex-between uk-text-meta">
                          <label class="uk-form-label" for="addReservationDescription">Description</label>
                          <div class="uk-text-small">
                            {{ getCharactersLeft('addReservationDescription', 2048) }}
                          </div>
                        </div>
                        <div class="uk-form-controls">
                          <textarea id="addReservationDescription" name="addReservationDescription"
                                    formControlName="addReservationDescription" rows="5" placeholder="Description ..."
                                    class="uk-textarea"
                                    [ngClass]="{'uk-form-danger': addReservationForm.get('addReservationDescription').touched && addReservationForm.get('addReservationDescription').errors }"></textarea>
                          <div class="uk-text-danger uk-text-small"
                               *ngIf="addReservationForm.get('addReservationDescription').hasError('maxlength')"
                          >
                            {{ getCharactersLimitError('addReservationDescription') }}
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="uk-width-1-2@s">
                      <div class="uk-margin">
                        <label class="uk-form-label" for="addReservationType">Reservation type <span
                          class="required">*</span></label>
                        <div class="uk-form-controls">
                          <select id="addReservationType" name="addReservationType" formControlName="addReservationType"
                                  class="uk-select" (blur)="setReservationType()">
                            <option value="" disabled>Please select ...</option>
                            <option value="Buy">Buy</option>
                            <option value="Sell">Sell</option>
                          </select>
                        </div>
                      </div>
                    </div>
                    <div class="uk-width-1-2@s"
                         *ngIf="['Sell'].includes(addReservationForm.get('addReservationType').value)">
                      <div class="uk-margin">
                        <label class="uk-form-label" for="addReservationSeller">Seller <span
                          class="required">*</span></label>
                        <div class="uk-form-controls">
                          <input id="addReservationSeller" name="addReservationSeller"
                                 formControlName="addReservationSeller" type="text"
                                 placeholder="{{ accountActor.name }}" class="uk-input" [ngModel]="accountActor.name"
                                 disabled>
                          <div class="uk-text-danger uk-text-small"
                               *ngIf="addReservationForm.get('addReservationSeller').hasError('maxlength')"
                          >
                            {{ getCharactersLimitError('addReservationSeller') }}
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="uk-width-1-2@s"
                         *ngIf="['Sell'].includes(addReservationForm.get('addReservationType').value)">
                      <div class="uk-margin">
                        <label class="uk-form-label" for="addReservationCustomer">Customer <span
                          class="required">*</span></label>
                        <div class="uk-form-controls">
                          <!--<select id="addReservationCustomer" name="addReservationCustomer" formControlName="addReservationCustomer" class="uk-select">
                             <option value="" disabled>Please select ...</option>
                             <option *ngFor="let actor of actors" value="{{ actor._id }}">{{ actor.name }} {{ actor.lastName }}</option>
                           </select>-->

                          <ng-select [items]="actors"
                                     [virtualScroll]="true"
                                     [addTag]="false"
                                     [selectOnTab]="false"
                                     addTagText="Add new: "
                                     placeholder="Please select ..."
                                     bindLabel="fullName"
                                     id="addReservationCustomer"
                                     formControlName="addReservationCustomer"
                                     class="uk-select"
                                     #addReservationCustomer (change)="addReservationCustomer.blur()">
                            <!--<ng-template ng-header-tmp>
                              <div class="uk-text-muted uk-text-small">New value:</div>
                            </ng-template>-->
                          </ng-select>
                          <div class="uk-text-danger uk-text-small"
                               *ngIf="addReservationForm.get('addReservationCustomer').hasError('maxlength')"
                          >
                            {{ getCharactersLimitError('addReservationCustomer') }}
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="uk-width-1-2@s"
                         *ngIf="['Buy'].includes(addReservationForm.get('addReservationType').value)">
                      <div class="uk-margin">
                        <label class="uk-form-label" for="addReservationSeller">Seller <span
                          class="required">*</span></label>
                        <div class="uk-form-controls">
                          <ng-select [items]="actors"
                                     [virtualScroll]="true"
                                     [addTag]="false"
                                     [selectOnTab]="false"
                                     addTagText="Add new: "
                                     placeholder="Please select ..."
                                     bindLabel="fullName"
                                     id="addReservationSeller"
                                     formControlName="addReservationSeller"
                                     class="uk-select"
                                     #addReservationSeller (change)="addReservationSeller.blur()">
                          </ng-select>
                          <div class="uk-text-danger uk-text-small"
                               *ngIf="addReservationForm.get('addReservationSeller').hasError('maxlength')"
                          >
                            {{ getCharactersLimitError('addReservationSeller') }}
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="uk-width-1-2@s"
                         *ngIf="['Buy'].includes(addReservationForm.get('addReservationType').value)">
                      <div class="uk-margin">
                        <label class="uk-form-label" for="addReservationCustomer">Customer <span
                          class="required">*</span></label>
                        <div class="uk-form-controls">
                          <input id="addReservationCustomer" name="addReservationCustomer"
                                 formControlName="addReservationCustomer" type="text"
                                 placeholder="{{ accountActor.name }}" class="uk-input" [ngModel]="accountActor.name"
                                 disabled>
                          <div class="uk-text-danger uk-text-small"
                               *ngIf="addReservationForm.get('addReservationCustomer').hasError('maxlength')"
                          >
                            {{ getCharactersLimitError('addReservationCustomer') }}
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="uk-width-1-2@s">
                      <div class="uk-margin">
                        <label class="uk-form-label" for="addReservationCreated">Creation date</label>
                        <div class="uk-form-controls">
                          <input
                            id="addReservationCreated"
                            name="addReservationCreated"
                            formControlName="addReservationCreated"
                            type="text"
                            placeholder="Creation date ..."
                            class="uk-input"
                            [ngClass]="{'uk-form-danger': addReservationForm.get('addReservationCreated').touched && addReservationForm.get('addReservationCreated').errors }"
                            angular-mydatepicker
                            [options]="myDatePickerOptions"
                            #AngularMyDatePickerAddReservationCreated="angular-mydatepicker"
                            (click)="AngularMyDatePickerAddReservationCreated.toggleCalendar()"
                            (dateChanged)="updateDate('addReservationCreated', $event)"
                          />
                          <div class="uk-text-danger uk-text-small"
                               *ngIf="addReservationForm.get('addReservationCreated').hasError('maxlength')"
                          >
                            {{ getCharactersLimitError('addReservationCreated') }}
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="uk-width-1-2@s">
                      <div class="uk-margin">
                        <label class="uk-form-label" for="addReservationValid">Valid until date</label>
                        <div class="uk-form-controls">
                          <input id="addReservationValid" name="addReservationValid"
                                 formControlName="addReservationValid" type="text" placeholder="Valid until date ..."
                                 class="uk-input"
                                 [ngClass]="{'uk-form-danger': addReservationForm.get('addReservationValid').touched && addReservationForm.get('addReservationValid').errors }"
                                 angular-mydatepicker
                                 [options]="myDatePickerOptions"
                                 #AngularMyDatePickerAddReservationValid="angular-mydatepicker"
                                 (click)="AngularMyDatePickerAddReservationValid.toggleCalendar()"
                                 (dateChanged)="updateDate('addReservationValid', $event)"
                          />
                          <div class="uk-text-danger uk-text-small"
                               *ngIf="addReservationForm.get('addReservationValid').hasError('maxlength')"
                          >
                            {{ getCharactersLimitError('addReservationValid') }}
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="uk-width-1-2@s">
                      <div class="uk-margin">
                        <label class="uk-form-label" for="addReservationPrice">Price</label>
                        <div class="uk-form-controls">
                          <input id="addReservationPrice" name="addReservationPrice"
                                 formControlName="addReservationPrice" type="text" placeholder="Price ..."
                                 class="uk-input"
                                 [ngClass]="{'uk-form-danger': addReservationForm.get('addReservationPrice').touched && addReservationForm.get('addReservationPrice').errors }">
                          <div class="uk-text-danger uk-text-small"
                               *ngIf="addReservationForm.get('addReservationPrice').hasError('maxlength')"
                          >
                            {{ getCharactersLimitError('addReservationPrice') }}
                          </div>
                          <div class="uk-text-danger uk-text-small"
                               *ngIf="addReservationForm.get('addReservationPrice').hasError('pattern')"
                          >
                            Field value is incorrect
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="uk-width-1-2@s">
                      <div class="uk-margin">
                        <label class="uk-form-label" for="addReservationCurrency">Currency</label>
                        <div class="uk-form-controls">
                          <input id="addReservationCurrency" name="addReservationCurrency"
                                 formControlName="addReservationCurrency" type="text" placeholder="Currency ..."
                                 class="uk-input"
                                 [ngClass]="{'uk-form-danger': addReservationForm.get('addReservationCurrency').touched && addReservationForm.get('addReservationCurrency').errors }">
                          <div class="uk-text-danger uk-text-small"
                               *ngIf="addReservationForm.get('addReservationCurrency').hasError('maxlength')"
                          >
                            {{ getCharactersLimitError('addReservationCurrency') }}
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="uk-width-1-2@s">
                      <div class="uk-margin">
                        <label class="uk-form-label" for="addReservationVAT">VAT</label>
                        <div class="uk-form-controls">
                          <input id="addReservationVAT" name="addReservationVAT" formControlName="addReservationVAT"
                                 type="number" placeholder="VAT ..." class="uk-input"
                                 [ngClass]="{'uk-form-danger': addReservationForm.get('addReservationVAT').touched && addReservationForm.get('addReservationVAT').errors }">
                          <div class="uk-text-danger uk-text-small"
                               *ngIf="addReservationForm.get('addReservationVAT').hasError('maxlength')"
                          >
                            {{ getCharactersLimitError('addReservationVAT') }}
                          </div>
                          <div class="uk-text-danger uk-text-small"
                               *ngIf="addReservationForm.get('addReservationVAT').hasError('pattern')"
                          >
                            Field value is incorrect
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="uk-width-1-2@s">
                      <div class="uk-margin">
                        <label class="uk-form-label" for="addReservationOtherTerms">Other terms</label>
                        <div class="uk-form-controls">
                          <input id="addReservationOtherTerms" name="addReservationOtherTerms"
                                 formControlName="addReservationOtherTerms" type="text" placeholder="Other terms ..."
                                 class="uk-input"
                                 [ngClass]="{'uk-form-danger': addReservationForm.get('addReservationOtherTerms').touched && addReservationForm.get('addReservationOtherTerms').errors }">
                          <div class="uk-text-danger uk-text-small"
                               *ngIf="addReservationForm.get('addReservationOtherTerms').hasError('maxlength')"
                          >
                            {{ getCharactersLimitError('addReservationOtherTerms') }}
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="uk-width-1-2@s">
                      <div class="uk-margin">
                        <label class="uk-form-label" for="addReservationClientReference">Client reference</label>
                        <div class="uk-form-controls">
                          <input id="addReservationClientReference" name="addReservationClientReference"
                                 formControlName="addReservationClientReference" type="text"
                                 placeholder="Client reference ..." class="uk-input"
                                 [ngClass]="{'uk-form-danger': addReservationForm.get('addReservationClientReference').touched && addReservationForm.get('addReservationClientReference').errors }">
                          <div class="uk-text-danger uk-text-small"
                               *ngIf="addReservationForm.get('addReservationClientReference').hasError('maxlength')"
                          >
                            {{ getCharactersLimitError('addReservationClientReference') }}
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="uk-width-1-2@s">
                      <div class="uk-margin">
                        <label class="uk-form-label" for="addReservationSellerReference">Seller reference</label>
                        <div class="uk-form-controls">
                          <input id="addReservationSellerReference" name="addReservationSellerReference"
                                 formControlName="addReservationSellerReference" type="text"
                                 placeholder="Seller reference ..." class="uk-input"
                                 [ngClass]="{'uk-form-danger': addReservationForm.get('addReservationSellerReference').touched && addReservationForm.get('addReservationSellerReference').errors }">
                          <div class="uk-text-danger uk-text-small"
                               *ngIf="addReservationForm.get('addReservationSellerReference').hasError('maxlength')"
                          >
                            {{ getCharactersLimitError('addReservationSellerReference') }}
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="uk-width-1-2@s">
                      <div class="uk-margin">
                        <label class="uk-form-label" for="addReservationStatus">Approval status</label>
                        <div class="uk-form-controls">
                          <label><input id="addReservationStatus" name="addReservationStatus"
                                        formControlName="addReservationStatus" class="uk-checkbox" type="checkbox">
                            Approved</label>
                        </div>
                      </div>
                    </div>
                    <div class="uk-width-1-1 uk-margin-medium-top">
                      <h3 class="uk-margin-remove-bottom">Notes on reservation</h3>
                      <hr class="uk-margin-small-top">
                    </div>
                    <div class="uk-width-1-2@s uk-width-1-3@m">
                      <div class="uk-margin">
                        <div class="uk-flex uk-flex-between uk-text-meta">
                          <label class="uk-form-label" for="addReservationShowNotes">Notes</label>
                          <div class="uk-text-small">
                            {{ getCharactersLeft('addReservationShowNotes', 2048) }}
                          </div>
                        </div>
                        <div class="uk-form-controls">
                          <textarea id="addReservationShowNotes" name="addReservationShowNotes"
                                    formControlName="addReservationShowNotes" rows="5" type="text"
                                    placeholder="Notes shown on reservation ..." class="uk-textarea"
                                    [ngClass]="{'uk-form-danger': addReservationForm.get('addReservationShowNotes').touched && addReservationForm.get('addReservationShowNotes').errors }"></textarea>
                          <div class="uk-text-danger uk-text-small"
                               *ngIf="addReservationForm.get('addReservationShowNotes').hasError('maxlength')"
                          >
                            {{ getCharactersLimitError('addReservationShowNotes') }}
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="uk-width-1-1">
                      <div class="uk-margin">
                        <label class="uk-form-label">Related artworks</label>
                        <!--<form appNoSubmitEnter id="searchArtwork" [formGroup]="searchArtworkForm">
                          <div class="uk-margin">
                            <div class="uk-form-controls uk-flex">
                              <input id="searchArtworkTerm" name="searchArtworkTerm" formControlName="searchArtworkTerm" class="uk-input uk-margin-small-right" type="search" placeholder="Search artworks..." [ngClass]="{' uk-form-danger': searchArtworkForm.get('searchArtworkTerm').touched && searchArtworkForm.get('searchArtworkTerm').hasError('invalid') }" autocomplete="off">
                              <button class="uk-button uk-button-default" (click)="searchArtwork()">Search</button>
                            </div>
                          </div>
                        </form>-->
                        <div class="uk-text-right">
                          <button class="uk-button uk-button-default" (click)="openAdvancedSearch()">Add</button>
                        </div>
                        <!--<div class="uk-position-relative" [hidden]="!searchArtworkResults.length">
                          <div class="uk-text-right">
                            <button class="uk-button uk-button-primary" (click)="addArtworksToReservation()" [disabled]="!selectedArtworks.length">Add selected</button>
                          </div>
                          <hr class="uk-margin-small-bottom">
                        </div>
                        <div id="search-artwork-results-container" class="results-container" [hidden]="!searchArtworkResults.length">
                          <div class="uk-flex uk-flex-column">
                            &lt;!&ndash;<div class="uk-container-expand uk-height-expand uk-padding-small uk-padding-remove-left uk-padding-remove-right" *ngIf="!searchArtworkResults.length">
                              <ul class="search-results uk-flex-center" uk-tab>
                                <h3>No search results</h3>
                              </ul>
                            </div>&ndash;&gt;
                            <div class="uk-container-expand uk-height-expand">
                              <div class="uk-margin">
                                <div class="uk-width-1-1">
                                  <table class="uk-table uk-table-middle uk-table-divider uk-margin-remove-bottom">
                                    <thead>
                                    <tr>
                                      <th class="uk-table-shrink">Select</th>
                                      <th class="uk-table-shrink">Image</th>
                                      <th class="uk-width-expand">Name</th>
                                      <th class="uk-width-expand uk-text-nowrap">Artist</th>
                                      <th class="uk-table-shrink uk-text-nowrap">Technique</th>
                                    </tr>
                                    </thead>
                                    <tbody>
                                    <tr *ngFor="let artwork of searchArtworkResults">
                                      <td><input class="uk-checkbox" type="checkbox" (click)="selectReservationArtwork(artwork)"></td>
                                      <td>
                                        <div class="image-max-height uk-preserve-width uk-background-contain uk-background-muted uk-height-small uk-panel uk-flex uk-flex-center uk-flex-middle" width="60" [attr.data-src]="'/api/storagefile/' + activeAccount + '/' + artwork.coverImage + '/medium'" uk-img *ngIf="artwork.coverImage">

                                        </div>
                                        &lt;!&ndash; Jos ei ole coverImagea, niin sit otetaan eka image &ndash;&gt;
                                        &lt;!&ndash;<img class="uk-preserve-width" [src]="'/api/storagefile/' + artwork.images[0].fileId" width="60" *ngIf="!artwork.coverImage && artwork.images && artwork.images.length">&ndash;&gt;
                                        <div class="image-max-height uk-preserve-width uk-background-contain uk-background-muted uk-height-small uk-panel uk-flex uk-flex-center uk-flex-middle" width="60" [attr.data-src]="'/api/storagefile/' + activeAccount + '/' + artwork.images[0].fileId + '/medium'" uk-img *ngIf="!artwork.coverImage && artwork.images && artwork.images.length">

                                        </div>
                                        &lt;!&ndash; Jos ei ole coverImagea tai ekan imagea, ni sit otetaan placeholder &ndash;&gt;
                                        &lt;!&ndash;<img class="uk-preserve-width uk-border-circle" src="/api/image/artbuddy-logo-placeholder.svg" *ngIf="!artwork.coverImage && !artwork.images.length" width="60">&ndash;&gt;
                                        <div class="image-max-height uk-preserve-width uk-background-contain uk-background-muted uk-height-small uk-panel uk-flex uk-flex-center uk-flex-middle" width="60" [attr.data-src]="'/api/image/artbuddy-logo-placeholder.svg'" uk-img *ngIf="!artwork.coverImage && !artwork.images.length">

                                        </div>
                                      </td>
                                      <td>{{ artwork.name }}</td>
                                      <td>{{ artwork.artistName }}</td>
                                      <td>{{ artwork.technique }}</td>
                                    </tr>
                                    </tbody>
                                  </table>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>-->
                        <div class="uk-grid-match uk-child-width-1-2@s uk-child-width-1-3@m uk-child-width-1-4@xl"
                             uk-grid uk-sortable [hidden]="!activeReservation.items.length">
                          <div class="uk-card" *ngFor="let item of activeReservation.items">
                            <div
                              class="uk-card-header uk-flex uk-flex-middle uk-flex-between uk-padding-remove-horizontal">
                              <div class="uk-padding-remove-left">
                                <h3 class="uk-card-title uk-margin-remove-bottom">{{ item.name }}</h3>
                                <div class="uk-text-meta">
                                  <span *ngIf="item.artistName">{{ item.artistName }}</span>
                                  <span *ngIf="item.created">, {{ item.created }}</span>
                                </div>
                              </div>
                              <!--<div>
                                <div class="uk-text-meta">{{ item.availability }}</div>
                                <div class="uk-text-meta" *ngIf="item.price.price">{{ item.price.price }} {{ item.price.currency }}</div>
                              </div>-->
                            </div>
                            <div class="uk-card-body uk-padding-remove uk-background-muted artwork-image"
                                 [attr.data-src]="'/api/storagefile/' + activeAccount + '/' + item.coverImage + '/medium'"
                                 uk-img *ngIf="item.coverImage"></div>
                            <div class="uk-card-body uk-padding-remove uk-background-muted artwork-image"
                                 [attr.data-src]="'/api/storagefile/' + activeAccount + '/' + item.images[0].fileId + '/medium'"
                                 uk-img *ngIf="!item.coverImage && item.images && item.images.length"></div>
                            <div class="uk-card-body uk-padding-remove uk-background-muted artwork-image bg-max-size"
                                 *ngIf="!item.coverImage && !item.images.length"></div>
                            <div
                              class="uk-card-footer uk-flex uk-flex-middle uk-flex-between uk-padding-remove-horizontal">
                              <!--<div>
                                <div class="uk-text-meta" *ngIf="item.width && item.height">{{ item.width }} * {{ item.height }} cm</div>
                              </div>-->
                              <div class="uk-flex uk-flex-column">
                                <a (click)="removeArtworkFromReservation(item)"
                                   class="uk-button uk-button-text">Remove</a>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="uk-width-1-1 uk-margin-medium-top">
                      <h3 class="uk-margin-remove-bottom">Additional information</h3>
                      <hr class="uk-margin-small-top">
                    </div>
                    <div class="uk-width-1-2">
                      <div class="uk-margin">
                        <div class="uk-flex uk-flex-between uk-text-meta">
                          <label class="uk-form-label" for="addReservationNotes">Other information</label>
                          <div class="uk-text-small">
                            {{ getCharactersLeft('addReservationNotes', 2048) }}
                          </div>
                        </div>
                        <div class="uk-form-controls">
                          <textarea id="addReservationNotes" name="addReservationNotes"
                                    formControlName="addReservationNotes" rows="5" type="text"
                                    placeholder="Other information ..." class="uk-textarea"
                                    [ngClass]="{'uk-form-danger': addReservationForm.get('addReservationNotes').touched && addReservationForm.get('addReservationNotes').errors }"></textarea>
                          <div class="uk-text-danger uk-text-small"
                               *ngIf="addReservationForm.get('addReservationNotes').hasError('maxlength')"
                          >
                            {{ getCharactersLimitError('addReservationNotes') }}
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="uk-width-1-2">
                      <div class="uk-margin">
                        <div class="uk-flex uk-flex-between uk-text-meta">
                          <label class="uk-form-label" for="addReservationComments">Comments</label>
                          <div class="uk-text-small">
                            {{ getCharactersLeft('addReservationComments', 2048) }}
                          </div>
                        </div>
                        <div class="uk-form-controls">
                          <textarea id="addReservationComments" name="addReservationComments"
                                    formControlName="addReservationComments" rows="5" type="text"
                                    placeholder="Comments ..." class="uk-textarea"
                                    [ngClass]="{'uk-form-danger': addReservationForm.get('addReservationComments').touched && addReservationForm.get('addReservationComments').errors }"></textarea>
                          <div class="uk-text-danger uk-text-small"
                               *ngIf="addReservationForm.get('addReservationComments').hasError('maxlength')"
                          >
                            {{ getCharactersLimitError('addReservationComments') }}
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="uk-width-1-2">
                      <div class="uk-margin">
                        <label class="uk-form-label" for="addReservationTags">Tags</label>
                        <div class="uk-form-controls">
                          <input id="addReservationTags" name="addReservationTags" formControlName="addReservationTags"
                                 type="text" placeholder="Tags ..." class="uk-input"
                                 [ngClass]="{'uk-form-danger': addReservationForm.get('addReservationTags').touched && addReservationForm.get('addReservationTags').errors }">
                          <div class="uk-text-danger uk-text-small"
                               *ngIf="addReservationForm.get('addReservationTags').hasError('maxlength')"
                          >
                            {{ getCharactersLimitError('addReservationTags') }}
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="uk-width-1-2">
                      <div class="uk-margin">
                        <label class="uk-form-label" for="addReservationVisibility">Visibility</label>
                        <div class="uk-form-controls">
                          <label><input id="addReservationVisibility" name="addReservationVisibility"
                                        formControlName="addReservationVisibility" class="uk-checkbox" type="checkbox">
                            Public</label>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="uk-modal-footer">
                    <p class="uk-text-right">
                      <button class="uk-button uk-button-default uk-modal-close uk-margin-small-right" type="button"
                              id="addNewReservationCancel" (click)="resetForm()">Cancel
                      </button>
                      <button type="submit" [disabled]="!addReservationForm.valid" (click)="addReservation()"
                              class="uk-button uk-button-primary">Save
                      </button>
                    </p>
                  </div>
                </form>
              </div>
            </div>
            <button class="uk-button uk-button-text uk-margin-right" type="button">Sort <span uk-icon="icon: chevron-down"></span></button>
            <div uk-dropdown="mode: click;">
              <ul class="uk-nav uk-dropdown-nav">
<!--                <li [ngClass]="{'uk-active': activeSort('created') }" (click)="sortReservations('created')"><a>Created</a></li>-->
                <li [ngClass]="{'uk-active': activeSort('sequence') }" (click)="sortReservations('sequence')">
                  <a>Number</a>
                </li>
                <li [ngClass]="{'uk-active': activeSort('name') }" (click)="sortReservations('name')"><a>Title</a></li>
                <li [ngClass]="{'uk-active': activeSort('seller') }" (click)="sortReservations('seller')"><a>Seller</a>
                </li>
                <li [ngClass]="{'uk-active': activeSort('type') }" (click)="sortReservations('type')"><a>Type</a></li>
                <li [ngClass]="{'uk-active': activeSort('price') }" (click)="sortReservations('price')"><a
                >Price</a></li>
                <li [ngClass]="{'uk-active': activeSort('valid') }" (click)="sortReservations('valid')"><a
                >Valid</a></li>
              </ul>
            </div>
            <button class="uk-button uk-button-text" type="button">Limit {{ limitQuery ? ': ' + limitQuery : null }} <span uk-icon="icon: chevron-down"></span></button>
            <div uk-dropdown="mode: click;">
              <ul class="uk-nav uk-dropdown-nav">
                <li [ngClass]="{'uk-active': activeLimit(10) }" (click)="setLimit(10)"><a>10</a></li>
                <li [ngClass]="{'uk-active': activeLimit(25) }" (click)="setLimit(25)"><a>25</a></li>
                <li [ngClass]="{'uk-active': activeLimit(50) }" (click)="setLimit(50)"><a>50</a></li>
                <li [ngClass]="{'uk-active': activeLimit(100) }" (click)="setLimit(100)"><a>100</a></li>
                <li [ngClass]="{'uk-active': activeLimit(200) }" (click)="setLimit(200)"><a>200</a></li>
              </ul>
            </div>
          </div>
          <div class="uk-height-1-1">
            <table class="uk-table uk-table-middle uk-table-divider">
              <thead>
              <tr>
                <th class="uk-text-nowrap" [ngClass]="{'active': activeSort('sequence') }"
                    (click)="sortReservations('sequence')">Number<span uk-icon="chevron-down"
                                                                       [ngClass]="{'inverse': !sortAscending }"></span>
                </th>
                <th class="uk-text-wrap" [ngClass]="{'active': activeSort('name') }" (click)="sortReservations('name')">
                  Title<span uk-icon="chevron-down" [ngClass]="{'inverse': !sortAscending }"></span></th>
                <th class="uk-text-nowrap uk-visible@m" [ngClass]="{'active': activeSort('customer') }"
                    (click)="sortReservations('customer')">Customer<span uk-icon="chevron-down"
                                                                         [ngClass]="{'inverse': !sortAscending }"></span>
                </th>
                <th class="uk-text-nowrap uk-visible@m" [ngClass]="{'active': activeSort('type') }"
                    (click)="sortReservations('type')">Type<span uk-icon="chevron-down"
                                                                 [ngClass]="{'inverse': !sortAscending }"></span></th>
                <th class="uk-text-nowrap uk-visible@m" [ngClass]="{'active': activeSort('price') }"
                    (click)="sortReservations('price')">Price<span uk-icon="chevron-down"
                                                                   [ngClass]="{'inverse': !sortAscending }"></span></th>
                <th class="uk-text-nowrap uk-visible@m" [ngClass]="{'active': activeSort('valid') }"
                    (click)="sortReservations('valid')">Valid<span uk-icon="chevron-down"
                                                                   [ngClass]="{'inverse': !sortAscending }"></span></th>
                <th class="uk-table-shrink"></th>
              </tr>
              </thead>
              <tbody>
              <tr *ngFor="let reservation of reservations">
                <!--<td>
                  <img class="uk-preserve-width uk-border-circle" src="/api/image/artbuddy-logo-placeholder.svg" *ngIf="!reservation.images.length" width="60">
                  <img class="uk-preserve-width" [src]="'/api/file/' + reservation.images[0].fileId" width="60" *ngIf="reservation.images.length">
                </td>-->
                <!--[ngStyle]="{'background-image': 'url(/api/file/' + image.fileId + ')'}"-->
                <td>{{ reservation.sequence }}</td>
                <td (click)="openReservation(reservation._id)" class="pointer">{{ reservation.name }}</td>
                <td class="uk-visible@m">{{ reservation.customer.fullName }}</td>
                <td class="uk-visible@m">{{ reservation.type }}</td>
                <td class="uk-visible@m">{{ reservation.itemsTotalPrice }} <span *ngIf="reservation.itemsTotalPrice !== 'Unknown'">{{ account.tradingCurrency }}</span></td>
                <td class="uk-visible@m">{{ reservation.valid | date: 'dd.MM.yyyy' }}</td>
                <td class="uk-text-right">
                  <div class="uk-button-group">
                    <button (click)="openReservation(reservation._id)" class="uk-button uk-button-text uk-margin-right" type="button">Open</button>
                    <div class="uk-inline" class="uk-visible@m">
                      <button class="uk-button uk-button-link" type="button"><span uk-icon="icon: chevron-down"></span></button>
                      <div uk-dropdown="mode: click; boundary: ! .uk-button-group; boundary-align: true;">
                        <ul class="uk-nav uk-dropdown-nav">
                          <li><a (click)="removeReservation(reservation._id)">Remove</a></li>
                        </ul>
                      </div>
                    </div>
                  </div>
                </td>
              </tr>
              </tbody>
            </table>
          </div>
          <ul class="uk-pagination uk-flex-center uk-flex-middle uk-margin-medium-top" *ngIf="pages.length > 1">
            <li class="uk-margin-small-right" [ngClass]="{'uk-disabled': (currentPage === 1) }">
              <a (click)="setCurrentPage(currentPage-1)">
                <span class="uk-margin-small-right" uk-pagination-previous></span> Previous
              </a>
            </li>
            <li>
              <select class="uk-select" [ngModel]="currentPage" (change)="setCurrentPageString($event.target.value)">
                <option *ngFor="let page of pages; let index = index; first as isFirst; last as isLast"
                        [value]="index+1">{{ index + 1 }}</option>
              </select>
            </li>
            <li class="uk-margin-small-left" [ngClass]="{'uk-disabled': (currentPage === pageCount) }">
              <a (click)="setCurrentPage(currentPage+1)">
                Next <span class="uk-margin-small-left" uk-pagination-next></span>
              </a>
            </li>
          </ul>
        </div>
      </div>
    </div>
  </div>
</div>
<app-advanced-search-modal (setResults)="setArtworkSearchResults($event)"
                           *ngIf="showAdvancedArtworkSearchModalWindow"></app-advanced-search-modal>
