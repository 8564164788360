<div class="uk-section uk-section-default uk-padding-remove-vertical">

  <app-sidenav contentType="Collections" [contentItem]="activeCollection.name"></app-sidenav>

  <div class="uk-container-expand uk-height-expand">
    <ul uk-tab *ngIf="!isLoading">
      <li class="uk-active"><a href="#collectioninfo"><h4>Basic information</h4></a></li>
      <li><a href="#collectionartworks"><h4>Artworks</h4></a></li>
      <li *ngIf="artists.length"><a href="#collectionartists"><h4>Artists</h4></a></li>
    </ul>
    <ul class="uk-switcher uk-card uk-card-body uk-padding-remove-top" *ngIf="!isLoading">
      <div id="collectioninfo" class="uk-padding-small">
        <form appNoSubmitEnter [formGroup]="editCollectionForm" (ngSubmit)="editCollection()" class="uk-grid-small"
              uk-grid>
          <input type="hidden" name="editCollectionOwner" name="editCollectionOwner"
                 formControlName="editCollectionOwner" [ngModel]="activeAccount">
          <input type="hidden" name="editCollectionId" name="editCollectionId" formControlName="editCollectionId"
                 [ngModel]="activeCollection._id">
          <input type="hidden" name="editCollectionOwner" name="editCollectionOwner"
                 formControlName="editCollectionOwner" [ngModel]="activeCollection.owner">
          <div class="uk-width-1-2@s uk-width-1-3@m">
            <div class="uk-margin">
              <label class="uk-form-label" for="editCollectionName">Name <span class="required">*</span></label>
              <div class="uk-form-controls">
                <input id="editCollectionName" name="editCollectionName" formControlName="editCollectionName"
                       type="text" [ngModel]="activeCollection.name" placeholder="Name ..." class="uk-input"
                       [ngClass]="{'uk-form-danger': editCollectionForm.get('editCollectionName').touched && editCollectionForm.get('editCollectionName').errors }">
                <div class="uk-text-danger uk-text-small"
                     *ngIf="editCollectionForm.get('editCollectionName').hasError('maxlength')"
                >
                  {{ getCharactersLimitError('editCollectionName') }}
                </div>
                <div class="uk-text-danger uk-text-small"
                     *ngIf="editCollectionForm.get('editCollectionName').hasError('pattern')"
                >
                  Field value is incorrect
                </div>
              </div>
            </div>
          </div>
          <div class="uk-width-1-2@s uk-width-1-3@m">
            <div class="uk-margin">
              <div class="uk-flex uk-flex-between uk-text-meta">
                <label class="uk-form-label" for="editCollectionDescription">Description</label>
                <div class="uk-text-small">
                  {{ getCharactersLeft('editCollectionDescription', 2048) }}
                </div>
              </div>
              <div class="uk-form-controls">
                <textarea id="editCollectionDescription" name="editCollectionDescription"
                          formControlName="editCollectionDescription" rows="5" [ngModel]="activeCollection.description"
                          placeholder="Description ..." class="uk-textarea"
                          [ngClass]="{'uk-form-danger': editCollectionForm.get('editCollectionDescription').touched && editCollectionForm.get('editCollectionDescription').errors }"></textarea>
                <div class="uk-text-danger uk-text-small"
                     *ngIf="editCollectionForm.get('editCollectionDescription').hasError('maxlength')"
                >
                  {{ getCharactersLimitError('editCollectionDescription') }}
                </div>
              </div>
            </div>
          </div>
          <div class="uk-width-1-2@s uk-width-1-3@m">
            <div class="uk-margin">
              <label class="uk-form-label" for="editCollectionType">Collection type</label>
              <div class="uk-form-controls">
                <select id="editCollectionType" name="editCollectionType" formControlName="editCollectionType"
                        [ngModel]="activeCollection.type" class="uk-select">
                  <option value="" disabled>Please select ...</option>
                  <option value="Internal">Internal</option>
                  <option value="Joint">Joint</option>
                </select>
              </div>
            </div>
          </div>
          <div class="uk-width-1-2@s uk-width-1-3@m">
            <div class="uk-margin">
              <label class="uk-form-label" for="editCollectionLocation">Location</label>
              <div class="uk-form-controls">
                <ng2-completer id="editCollectionLocation" name="editCollectionLocation"
                               formControlName="editCollectionLocation" [(ngModel)]="activeCollection.location"
                               [datasource]="variables.locations" [minSearchLength]="0"
                               [textNoResults]="null"></ng2-completer>
                <div class="uk-text-danger uk-text-small"
                     *ngIf="editCollectionForm.get('editCollectionLocation').hasError('maxlength')"
                >
                  {{ getCharactersLimitError('editCollectionLocation') }}
                </div>
              </div>
            </div>
          </div>
          <!--<div class="uk-width-1-1@s">
            <div class="uk-margin">
              <label class="uk-form-label" for="editCollectionAttachments">Liitetiedostot</label>
              <div class="uk-form-controls">
                <div uk-form-custom="target: true">
                  <input type="file">
                  <input class="uk-input uk-form-width-medium" type="text" placeholder="Valitse tiedosto" disabled>
                </div>
                <button class="uk-button uk-button-default">Lisää</button>
              </div>
            </div>
          </div>-->
          <div class="uk-width-1-1 uk-margin-medium-top">
            <h3 class="uk-margin-remove-bottom">Additional information</h3>
            <hr class="uk-margin-small-top">
          </div>
          <div class="uk-width-1-1@s">
            <div class="uk-margin">
              <label class="uk-form-label">Attachments</label>
              <div class="uk-form-controls uk-margin-small">
                <dl class="uk-description-list" *ngIf="activeCollection.attachments.length">
                  <div class="uk-flex uk-flex-middle" *ngFor="let attachment of activeCollection.attachments">
                    <div class="uk-padding-small">
                      <img class="contenttype" width="40" height="40"
                           src="/api/storagefile/{{ activeAccount }}/{{ attachment.fileId }}/small"
                           *ngIf="['image/png', 'image/jpg', 'image/jpeg', 'image/heic', 'image/gif', 'image/svg+xml'].includes(attachment.contentType)"/>
                      <img class="contenttype" width="40" height="40" src="/api/file/5a5de6b1229e86403d67db11"
                           *ngIf="['application/msword', 'application/vnd.openxmlformats-officedocument.wordprocessingml.document'].includes(attachment.contentType)"/>
                      <img class="contenttype" width="40" height="40" src="/api/file/5a5de6b1229e86403d67db10"
                           *ngIf="['application/octet-stream', 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'].includes(attachment.contentType)"/>
                      <img class="contenttype" width="40" height="40" src="/api/file/5a5de6b1229e86403d67db12"
                           *ngIf="['application/pdf'].includes(attachment.contentType)"/>
                      <img class="contenttype" width="40" height="40" src="/api/file/5a5de7bc229e86403d67db1b"
                           *ngIf="['text/plain'].includes(attachment.contentType)"/>
                      <img class="contenttype" width="40" height="40" src="/api/file/5a5de6b1229e86403d67db0f"
                           *ngIf="!(['image/png', 'image/jpg', 'image/jpeg', 'image/heic', 'image/gif', 'image/svg+xml', 'application/msword', 'application/vnd.openxmlformats-officedocument.wordprocessingml.document', 'application/octet-stream', 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet', 'application/pdf', 'text/plain'].includes(attachment.contentType))"/>
                    </div>
                    <div class="uk-width-expand">{{ attachment.name }}</div>
                    <div class="uk-button-group">
                      <button (click)="openAttachment(attachment)" class="uk-button uk-button-default" type="button">
                        Open
                      </button>
                      <div class="uk-inline uk-text-right">
                        <button class="uk-button uk-button-default" type="button"><span
                          uk-icon="icon:  chevron-down"></span></button>
                        <div uk-dropdown="mode: click; boundary: ! .uk-button-group; boundary-align: true;">
                          <ul class="uk-nav uk-dropdown-nav">
                            <li><a (click)="removeAttachment(attachment)">Remove</a></li>
                          </ul>
                        </div>
                      </div>
                    </div>
                  </div>
                </dl>
                <p *ngIf="!activeCollection.attachments.length">No related attachments</p>
              </div>
            </div>
          </div>
          <div class="uk-width-1-1@s">
            <div class="uk-margin">
              <label class="uk-form-label">Add new attachments</label>
              <div class="uk-form-controls">
                <div class="drop-container" ngFileDrop [options]="options"
                     (uploadOutput)="onAttachmentsUploadOutput($event)" (click)="attachmentFile.click()"
                     [uploadInput]="uploadInput">
                  <div class="js-upload uk-placeholder uk-text-center" [ngClass]="{ 'is-drop-over': dragOver }">
                    <span
                      class="uk-text-middle">Add files by dropping them here or open file dialog by clicking here.<br>Maximum allowed file size is 5 Mb.</span>
                  </div>
                  <input #attachmentFile type="file" ngFileSelect [options]="{ concurrency: 1, maxFileSize: 5000000 }"
                         [uploadInput]="uploadInput" (uploadOutput)="onAttachmentsUploadOutput($event)" multiple
                         class="file-input">
                </div>
              </div>
              <!--              <progress id="js-progressbar" class="uk-progress" value="0" max="100" hidden></progress>-->
              <!--              <div class="table-container" *ngIf="filesToUpload.length">-->
              <!--                <table class="uk-table uk-table-middle uk-margin-top">-->
              <!--                  <caption>Files to upload</caption>-->
              <!--                  <thead>-->
              <!--                  <tr>-->
              <!--                    <th class="uk-table-shrink uk-text-capitalize">Image</th>-->
              <!--                    <th class="uk-width-expand uk-text-capitalize">Name</th>-->
              <!--                    <th class="uk-width-expand uk-text-capitalize">Size</th>-->
              <!--                    <th class="uk-table-shrink uk-text-capitalize"></th>-->
              <!--                  </tr>-->
              <!--                  </thead>-->
              <!--                  <tbody>-->
              <!--                  <tr *ngFor="let file of filesToUpload; let i = index;">-->
              <!--                    <td class="uk-background-muted"><img class="uk-preserve-width uk-border-circle" src="/api/image/artbuddy-logo-placeholder.svg" width="60" uk-img></td>-->
              <!--                    <td>{{ file.name }}</td>-->
              <!--                    <td class="uk-text-nowrap">{{ file.size | bytes: 2 }}</td>-->
              <!--                    <td><button (click)="removeFile(file.id)" class="uk-button uk-button-default" type="button">Remove</button></td>-->
              <!--                  </tr>-->
              <!--                  </tbody>-->
              <!--                </table>-->
              <!--                <button type="button" (click)="startUpload()" class="uk-button uk-button-primary">Start upload</button>-->
              <!--              </div>-->
            </div>
          </div>
          <div class="uk-width-1-2@s uk-width-1-3@m">
            <div class="uk-margin">
              <div class="uk-flex uk-flex-between uk-text-meta">
                <label class="uk-form-label" for="editCollectionNotes">Other information</label>
                <div class="uk-text-small">
                  {{ getCharactersLeft('editCollectionNotes', 2048) }}
                </div>
              </div>
              <div class="uk-form-controls">
                <textarea id="editCollectionNotes" name="editCollectionNotes" formControlName="editCollectionNotes"
                          rows="5" [ngModel]="activeCollection.notes" type="text" placeholder="Other information ..."
                          class="uk-textarea"
                          [ngClass]="{'uk-form-danger': editCollectionForm.get('editCollectionNotes').touched && editCollectionForm.get('editCollectionNotes').errors}"></textarea>
                <div class="uk-text-danger uk-text-small"
                     *ngIf="editCollectionForm.get('editCollectionNotes').hasError('maxlength')"
                >
                  {{ getCharactersLimitError('editCollectionNotes') }}
                </div>
              </div>
            </div>
          </div>
          <div class="uk-width-1-2@s uk-width-1-3@m">
            <div class="uk-margin">
              <div class="uk-flex uk-flex-between uk-text-meta">
                <label class="uk-form-label" for="editCollectionComments">Comments</label>
                <div class="uk-text-small">
                  {{ getCharactersLeft('editCollectionComments', 2048) }}
                </div>
              </div>
              <div class="uk-form-controls">
                <textarea id="editCollectionComments" name="editCollectionComments"
                          formControlName="editCollectionComments" rows="5" [ngModel]="activeCollection.comments"
                          type="text" placeholder="Comments ..." class="uk-textarea"
                          [ngClass]="{'uk-form-danger': editCollectionForm.get('editCollectionComments').touched && editCollectionForm.get('editCollectionComments').errors }"></textarea>
                <div class="uk-text-danger uk-text-small"
                     *ngIf="editCollectionForm.get('editCollectionComments').hasError('maxlength')"
                >
                  {{ getCharactersLimitError('editCollectionComments') }}
                </div>
              </div>
            </div>
          </div>
          <div class="uk-width-1-2@s uk-width-1-3@m">
            <div class="uk-margin">
              <label class="uk-form-label" for="editCollectionTags">Tags</label>
              <div class="uk-form-controls">
                <input id="editCollectionTags" name="editCollectionTags" formControlName="editCollectionTags"
                       [ngModel]="activeCollection.tags" type="text" placeholder="Tags ..." class="uk-input"
                       [ngClass]="{'uk-form-danger': editCollectionForm.get('editCollectionTags').touched && editCollectionForm.get('editCollectionTags').errors }">
                <div class="uk-text-danger uk-text-small"
                     *ngIf="editCollectionForm.get('editCollectionTags').hasError('maxlength')"
                >
                  {{ getCharactersLimitError('editCollectionTags') }}
                </div>
              </div>
            </div>
          </div>
          <div class="uk-width-1-2@s uk-width-1-3@m">
            <div class="uk-margin">
              <label class="uk-form-label" for="editCollectionVisibility">Visibility</label>
              <div class="uk-form-controls">
                <label><input id="editCollectionVisibility" name="editCollectionVisibility"
                              formControlName="editCollectionVisibility" [ngModel]="activeCollection.visibility"
                              class="uk-checkbox" type="checkbox"> Public</label>
              </div>
            </div>
          </div>
          <div class="uk-width-1-1 uk-margin-medium-top">
            <hr class="uk-margin-small-top">
          </div>
          <div class="uk-width-1-1 uk-margin-remove sticky-bottom uk-background-default" uk-sticky="bottom: true">
            <div class="uk-width-1-1">
              <div class="uk-flex uk-flex-middle uk-flex-between uk-margin">
                <app-editing-history
                  [updatedAt]="activeCollection.updatedAt"
                  [name]="activeCollection.requestUser?.name"
                  [email]="activeCollection.requestUser?.email"
                ></app-editing-history>
                <div class="uk-text-right">
                  <button type="button" class="uk-button uk-button-default uk-margin-small-right"
                          (click)="openPrintList()" [disabled]="!activeCollection.items.length">Print list
                  </button>
                  <button type="button" class="uk-button uk-button-default uk-margin-small-right"
                          (click)="openAdvancedSearch()">Advanced search
                  </button>
                  <button type="submit" class="uk-button uk-button-primary"
                          [disabled]="!editCollectionForm.valid || uploadLoading">Save
                  </button>
                </div>
              </div>
            </div>
          </div>
        </form>
        <div id="createList" class="uk-modal-container" uk-modal="bg-close: false; esc-close: false; cls-panel:
       'uk-overflow-hidden'">
          <div class="uk-modal-dialog">
            <div class="uk-modal-header">
              <h2 class="uk-modal-title">Print list</h2>
            </div>
            <div class="uk-modal-body">
              <p>You can rearrange the order in which the collection's artworks are printed out by dragging.</p>
              <div id="createListSortable" class="uk-child-width-1-1" uk-sortable="handle: .uk-card">
                <div class="uk-card uk-grid-collapse uk-child-width-expand uk-flex-middle uk-margin"
                     *ngFor="let item of activeCollection.items" uk-grid [attr.data-artworkid]="item._id">
                  <div class="uk-width-auto">
                    <span class="uk-sortable-handle uk-margin-small-right uk-padding-small"
                          uk-icon="icon: table"></span>
                  </div>

                  <!-- Näytetään coverImage jos on -->
                  <div
                    class="image-max-height uk-preserve-width uk-background-contain uk-background-muted uk-width-small uk-height-small uk-panel uk-flex uk-flex-center uk-flex-middle"
                    width="150"
                    [attr.data-src]="'/api/storagefile/' + activeAccount + '/' + item.coverImage + '/medium'" uk-img
                    *ngIf="item.coverImage">

                  </div>

                  <!-- Jos ei ole coverImagea, niin sit otetaan eka image -->
                  <div
                    class="image-max-height uk-preserve-width uk-background-contain uk-background-muted uk-width-small uk-height-small uk-panel uk-flex uk-flex-center uk-flex-middle"
                    width="150"
                    [attr.data-src]="'/api/storagefile/' + activeAccount + '/' + item.images[0].fileId + '/medium'"
                    uk-img *ngIf="!item.coverImage && item.images && item.images.length">

                  </div>

                  <!-- Jos ei ole coverImagea tai ekan imagea, ni sit otetaan placeholder -->
                  <div
                    class="image-max-height uk-preserve-width uk-background-contain uk-background-muted uk-width-small uk-height-small uk-panel uk-flex uk-flex-center uk-flex-middle"
                    width="150" [attr.data-src]="'/api/image/artbuddy-logo-placeholder.svg'" uk-img
                    *ngIf="!item.coverImage && !item.images.length">

                  </div>
                  <div>
                    <div class="uk-card-body">
                      <h6 class="uk-card-title uk-margin-small-bottom">{{ item.name }}</h6>
                      <p class="uk-margin-remove">Artist: {{ item.artistName }}</p>
                      <p class="uk-margin-remove">Notes: {{ item.listNotes }}</p>
                      <p>
                        <input class="uk-checkbox uk-margin-small-right" type="checkbox" value="true"
                               (change)="updateShowPrice(item, $event)"> Hide price
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="uk-modal-footer">
              <p class="uk-text-right">
                <button class="uk-button uk-button-default uk-modal-close uk-margin-small-right" type="button"
                        id="addNewCollectionCancel">Cancel
                </button>
                <button
                  type="submit"
                  (click)="printList()"
                  class="uk-button uk-button-primary minWidth125"
                  *ngIf="!activeServiceIsLoading"
                >
                  <span>Print</span>
                </button>
                <button
                  disabled
                  class="uk-button uk-button-default minWidth125"
                  *ngIf="activeServiceIsLoading"
                >
                  <span><div uk-spinner="ratio:0.7"></div></span>
                </button>
              </p>
            </div>
          </div>
        </div>
      </div>
      <div id="collectionartworks" class="uk-padding-small uk-padding-remove-top">
        <nav class="uk-navbar-container navbar-search-artwork-container" uk-navbar>
          <div class="nav-overlay uk-navbar-right">
            <button class="uk-button uk-button-default uk-margin-small-right" type="button"
                    (click)="openAdvancedSearch()">Add artwork
            </button>
            <button class="uk-button uk-button-default" type="button" (click)="openSlideshow($event)"
                    [disabled]="!activeCollection.items.length">Slideshow
            </button>
          </div>
          <div id="nav-search-artwork" class="nav-overlay uk-navbar-container uk-navbar-left uk-flex-1" hidden>
            <div class="uk-navbar-item uk-width-expand">
              <form id="searchArtwork" [formGroup]="searchArtworkForm" (ngSubmit)="searchArtwork()"
                    class="uk-search uk-search-navbar uk-width-1-1">
                <input id="searchArtworkTerm" name="searchArtworkTerm" formControlName="searchArtworkTerm"
                       class="uk-search-input" type="search" placeholder="Search artworks..."
                       [ngClass]="{' uk-form-danger': searchArtworkForm.get('searchArtworkTerm').touched && searchArtworkForm.get('searchArtworkTerm').hasError('invalid') }"
                       autocomplete="off" autofocus>
                <button type="submit" class="uk-button uk-button-primary" hidden>Search</button>
              </form>
            </div>
            <a class="uk-navbar-toggle" uk-close
               uk-toggle="target: #collectionartworks .nav-overlay; animation: uk-animation-fade"
               (click)="emptySearch()"></a>
          </div>
        </nav>
        <div id="search-artwork-results-container">
          <div class="uk-flex uk-flex-column">
            <!--<div class="uk-container-expand uk-height-expand uk-padding-small uk-padding-remove-left uk-padding-remove-right" *ngIf="!searchArtworkResults.length">
              <ul class="search-results uk-flex-center" uk-tab>
                <h3>No search results</h3>
              </ul>
            </div>-->
            <div class="uk-container-expand uk-height-expand">
              <div class="uk-margin">
                <div class="uk-width-1-1">
                  <table class="uk-table uk-table-middle uk-table-divider uk-margin-remove-bottom">
                    <thead>
                    <tr>
                      <th class="uk-table-shrink">Select</th>
                      <th class="uk-table-shrink">Image</th>
                      <th class="uk-width-expand">Name</th>
                      <th class="uk-width-expand uk-text-nowrap">Artist</th>
                      <th class="uk-table-shrink uk-text-nowrap">Technique</th>
                    </tr>
                    </thead>
                    <tbody>
                    <tr *ngFor="let artwork of searchArtworkResults">
                      <td><input class="uk-checkbox" type="checkbox" (click)="selectCollectionArtwork(artwork)"></td>
                      <td>
                        <div
                          class="image-max-height uk-preserve-width uk-background-contain uk-background-muted uk-height-small uk-panel uk-flex uk-flex-center uk-flex-middle"
                          width="60"
                          [attr.data-src]="'/api/storagefile/' + activeAccount + '/' + artwork.coverImage + '/medium'"
                          uk-img *ngIf="artwork.coverImage">

                        </div>
                        <!-- Jos ei ole coverImagea, niin sit otetaan eka image -->
                        <!--<img class="uk-preserve-width" [src]="'/api/storagefile/' + artwork.images[0].fileId" width="60" *ngIf="!artwork.coverImage && artwork.images && artwork.images.length">-->
                        <div
                          class="image-max-height uk-preserve-width uk-background-contain uk-background-muted uk-height-small uk-panel uk-flex uk-flex-center uk-flex-middle"
                          width="60"
                          [attr.data-src]="'/api/storagefile/' + activeAccount + '/' + artwork.images[0].fileId + '/medium'"
                          uk-img *ngIf="!artwork.coverImage && artwork.images && artwork.images.length">

                        </div>
                        <!-- Jos ei ole coverImagea tai ekan imagea, ni sit otetaan placeholder -->
                        <!--<img class="uk-preserve-width uk-border-circle" src="/api/image/artbuddy-logo-placeholder.svg" *ngIf="!artwork.coverImage && !artwork.images.length" width="60">-->
                        <div
                          class="image-max-height uk-preserve-width uk-background-contain uk-background-muted uk-height-small uk-panel uk-flex uk-flex-center uk-flex-middle"
                          width="60" [attr.data-src]="'/api/image/artbuddy-logo-placeholder.svg'" uk-img
                          *ngIf="!artwork.coverImage && !artwork.images.length">

                        </div>
                      </td>
                      <td>{{ artwork.name }}</td>
                      <td>{{ artwork.artistName }}</td>
                      <td>{{ artwork.technique }}</td>
                    </tr>
                    </tbody>
                  </table>
                  <hr class="uk-margin-small-top">
                  <div class="uk-text-right">
                    <button type="submit" class="uk-button uk-button-primary" (click)="addArtworksToCollection()"
                            [disabled]="!selectedArtworks.length">Add artworks
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="uk-grid-match uk-child-width-1-2@s uk-child-width-1-3@m uk-child-width-1-4@xl" uk-grid uk-sortable>
          <div class="uk-card" *ngFor="let item of activeCollection.items">
            <div class="uk-card-header uk-flex uk-flex-middle uk-flex-between uk-padding-remove-horizontal">
              <div class="uk-padding-remove-left">
                <h3 class="uk-card-title uk-margin-remove-bottom">{{ item.name }}</h3>
                <div class="uk-text-meta">
                  <span *ngIf="item.artistName">{{ item.artistName }}</span>
                  <span *ngIf="item.created">, {{ item.created }}</span>
                </div>
              </div>
              <div>
                <!--<div class="uk-text-meta">{{ item.availability | shorten: 50: '...' }}</div>-->
                <div class="uk-text-meta"
                     *ngIf="item.price.price">{{ item.price.price }} {{ item.price.currency }}</div>
              </div>
            </div>
            <div class="uk-card-body uk-padding-remove uk-background-muted artwork-image"
                 [attr.data-src]="'/api/storagefile/' + activeAccount + '/' + item.coverImage + '/medium'" uk-img
                 *ngIf="item.coverImage"></div>
            <div class="uk-card-body uk-padding-remove uk-background-muted artwork-image"
                 [attr.data-src]="'/api/storagefile/' + activeAccount + '/' + item.images[0].fileId + '/medium'" uk-img
                 *ngIf="!item.coverImage && item.images && item.images.length"></div>
            <div class="uk-card-body uk-padding-remove uk-background-muted artwork-image bg-max-size"
                 *ngIf="!item.coverImage && !item.images.length"></div>
            <div class="uk-card-footer uk-flex uk-flex-middle uk-flex-between uk-padding-remove-horizontal">
              <div>
                <div class="uk-text-meta" *ngIf="item.width && item.height">{{ item.width }} * {{ item.height }}cm
                </div>
                <!--<div class="uk-text-meta">{{ item.technique }}</div>-->
              </div>
              <div class="uk-flex uk-flex-column">
                <a (click)="removeArtworkFromCollection(item)" class="uk-button uk-button-text">Remove</a>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div id="collectionartists" class="uk-padding-small">
        <table class="uk-table uk-table-middle uk-table-divider">
          <thead>
          <tr>
            <!--<th class="uk-table-shrink"></th>-->
            <!--<th class="uk-table-shrink">Image</th>-->
            <th class="uk-width-expand">Name</th>
            <th class="uk-width-expand uk-text-nowrap">Multiplier</th>
            <th class="uk-table-shrink uk-text-nowrap">Status</th>
            <th class="uk-table-shrink"></th>
          </tr>
          </thead>
          <tbody>
          <tr *ngFor="let artist of artists">
            <!--<td><input class="uk-checkbox" type="checkbox"></td>-->
            <!--<td><img class="uk-preserve-width uk-border-circle"
                     src="/api/image/artbuddy-logo-placeholder.svg"
                     width="60"></td>-->
            <td>{{ artist.name }} {{ artist.lastName }}</td>
            <td>{{ artist.priceMultiplier }}</td>
            <td>{{ artist.status }}</td>
            <td class="uk-text-right">
              <button (click)="openArtist(artist._id)" class="uk-button uk-button-default" type="button">Open</button>
            </td>
          </tr>
          </tbody>
        </table>
      </div>
    </ul>
  </div>
</div>
<app-advanced-search-modal (setResults)="setArtworkSearchResults($event)"
                           *ngIf="showAdvancedArtworkSearchModalWindow"></app-advanced-search-modal>
