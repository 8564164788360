import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {Observable} from 'rxjs';
import 'rxjs/add/operator/map';

@Injectable()
export class SearchService {
  results: Array<any> = [];

  constructor(
    private http: HttpClient
  ) {}

  searchGlobal(term: any): Observable<any> {
    return this.http.get(`/api/searchGlobal/${term.searchGlobalAccountId}/${term.searchGlobalUserId}/${term.searchGlobalTerm}`);
  }

  searchArtwork(term: any): Observable<any> {
    return this.http.get(`/api/searchArtwork/${term.searchArtworkAccountId}/${term.searchArtworkUserId}/${term.searchArtworkTerm}`);
  }

  createIndex(term: any): Observable<any> {
    /*return this.http.get(`/api/search/${term.accountId}/${term.contentType}/${term.subType}`);*/
    return this.http.get(`/api/createindex/${term.accountId}`);
  }
}
