import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-sidenav',
  templateUrl: './sidenav.component.html',
  styleUrls: ['./sidenav.component.scss']
})
export class SidenavComponent {
  @Input() contentType: string;
  @Input() contentItem: string;

  constructor() {}

  setBreadcrumbs(contentType: string, contentItem: string): void {
    this.contentType = contentType;
    this.contentItem = contentItem;
  }

  getContentTypeInLowercase(contentType: string): string {
    if (contentType === 'Proposals') {
      contentType = 'Bids';
    }

    return contentType.toLowerCase();
  }
}
