import { NgModule } from "@angular/core";
import { BrowserModule } from "@angular/platform-browser";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { NgxUploaderModule } from "ngx-uploader";
import { NgPipesModule } from "ngx-pipes";

import { ToastComponent } from "./toast/toast.component";
import { LoadingComponent } from "./loading/loading.component";
import { AttachmentsComponent } from "./attachments/attachments.component";
import { AutoFocusDirective } from "./autofocus";
import {EditingHistoryComponent} from "./editingHistory/editingHistory.component";

@NgModule({
  imports: [
    BrowserModule,
    FormsModule,
    ReactiveFormsModule,
    NgxUploaderModule,
    NgPipesModule
  ],
  exports: [
    // Shared Modules
    BrowserModule,
    FormsModule,
    ReactiveFormsModule,
    // Shared Components
    ToastComponent,
    LoadingComponent,
    AttachmentsComponent,
    AutoFocusDirective,
    EditingHistoryComponent
  ],
  declarations: [
    ToastComponent,
    LoadingComponent,
    AttachmentsComponent,
    AutoFocusDirective,
    EditingHistoryComponent
  ],
  providers: [
    ToastComponent
  ]
})
export class SharedModule { }
