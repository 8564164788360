export const originalAlgoliaEnvironment = {
  production: false,
  ALGOLIASEARCH_APPLICATION_ID: "89BMG3CMOZ",
  ALGOLIASEARCH_API_KEY: "adf46be8fdad2e8cbfd8908c0d424328"
};

export const newAlgoliaEnvironment = {
  production: false,
  ALGOLIASEARCH_APPLICATION_ID: "U356HURG25",
  ALGOLIASEARCH_API_KEY: "5fbc53ea1bfb54fca7dcd1e724ce5d3c"
};
