<div id="header">
  <nav class="uk-navbar-container uk-nav navbar-collapse collapse" uk-navbar="mode: click">
    <a class="uk-navbar-item uk-logo" routerLink="/">
      <img class="uk-preserve-width" src="assets/images/artbuddy_logo.svg" width="150">
    </a>
    <div>
    <div class="statusWrapper" [ngClass]="{'error': !backendConnection }">
      <div class="dot animate-fade"></div>
      <div class="description">Error connecting to server. Please wait...</div>
    </div>
    </div>
    <div class="nav-overlay uk-navbar-left uk-flex-right uk-width-1-1">
      <a class="uk-navbar-toggle uk-hidden@l" uk-toggle="target: #offcanvas-menu" *ngIf="loggedIn">
        <span uk-navbar-toggle-icon></span> <span class="uk-margin-small-left">Menu</span>
      </a>
      <div id="offcanvas-menu" uk-offcanvas="flip: true; overlay: true; mode: push" uk-flex>
        <div class="uk-offcanvas-bar uk-flex uk-flex-column uk-text-left">
          <button class="uk-offcanvas-close" type="button" uk-close></button>
          <ul class="uk-nav uk-nav-primary uk-margin-auto-vertical uk-nav-parent-icon" uk-nav>
            <li class="uk-active"><a routerLink="/" (click)="closeOffcanvasNav()">Overview</a></li>
            <li class="uk-parentNOT">
              <a routerLink="/artists" (click)="closeOffcanvasNav()">Artists</a>
            </li>
            <li class="uk-parentNOT">
              <a routerLink="/artworks" (click)="closeOffcanvasNav()">Artworks</a>
            </li>
            <li class="uk-parentNOT">
              <a routerLink="/exhibitions" (click)="closeOffcanvasNav()">Exhibitions</a>
            </li>
            <li class="uk-parentNOT">
              <a routerLink="/collections" (click)="closeOffcanvasNav()">Collections</a>
            </li>
            <li class="uk-parentNOT" *ngIf="!(['artcollector'].includes(account.type))">
              <a routerLink="/clients" (click)="closeOffcanvasNav()">Clients</a>
            </li>
<!--            <li class="uk-parentNOT">-->
<!--              <a routerLink="/estimates" (click)="closeOffcanvasNav()">Estimates</a>-->
<!--            </li>-->
            <li class="uk-parentNOT" *ngIf="!(['artcollector'].includes(account.type))">
              <a routerLink="/bids" (click)="closeOffcanvasNav()">Proposals</a>
            </li>
            <li class="uk-parentNOT" *ngIf="!(['artcollector'].includes(account.type))">
              <a routerLink="/reservations" (click)="closeOffcanvasNav()">Reservations</a>
            </li>
            <li class="uk-parentNOT" *ngIf="!(['artcollector'].includes(account.type))">
              <a routerLink="/trades" (click)="closeOffcanvasNav()">Trades</a>
            </li>
          </ul>
          <div class="uk-logo uk-margin-small-bottom"><img class="uk-preserve-width" src="assets/images/artbuddy_logo_neg.svg" width="150"></div>
          <div class="uk-text-middle">© 2023 Artbuddy. All rights reserved.</div>
        </div>
      </div>
      <ul class="uk-navbar-nav uk-visible@l" (click)="closeNav()" *ngIf="loggedIn">
        <!--<li [ngClass]="{' uk-active': activeContentType == 'overview' }"><a routerLink="/">Overview</a></li>-->
        <li [ngClass]="{' uk-active': activeContentType == 'artists' }">
          <a routerLink="/artists">Artists</a>
        </li>
        <li [ngClass]="{' uk-active': activeContentType == 'artworks' }">
          <a routerLink="/artworks">Artworks</a>
        </li>
        <li [ngClass]="{' uk-active': activeContentType == 'exhibitions' }">
          <a routerLink="/exhibitions">Exhibitions</a>
        </li>
        <li [ngClass]="{' uk-active': activeContentType == 'collections' }">
          <a routerLink="/collections">Collections</a>
        </li>
        <li [ngClass]="{' uk-active': activeContentType == 'clients' }" *ngIf="!(['artcollector'].includes(account.type))">
          <a routerLink="/clients">Clients</a>
        </li>
<!--        <li [ngClass]="{' uk-active': activeContentType == 'estimates' }">-->
<!--          <a routerLink="/estimates">Estimates</a>-->
<!--        </li>-->
        <li [ngClass]="{' uk-active': activeContentType == 'bids' }" *ngIf="!(['artcollector'].includes(account.type))">
          <a routerLink="/bids">Proposals</a>
        </li>
        <li [ngClass]="{' uk-active': activeContentType == 'reservations' }" *ngIf="!(['artcollector'].includes(account.type))">
          <a routerLink="/reservations">Reservations</a>
        </li>
        <li [ngClass]="{' uk-active': activeContentType == 'trades' }" *ngIf="!(['artcollector'].includes(account.type))">
          <a routerLink="/trades">Trades</a>
        </li>
      </ul>
    </div>
    <!--<div class="nav-overlay uk-navbar-left uk-flex-1" hidden>
      <div class="uk-navbar-item uk-width-expand">
        <form [formGroup]="searchGlobalForm" (ngSubmit)="searchGlobal()" class="uk-search uk-search-navbar uk-width-1-1">
          <input id="searchGlobalTerm" name="searchGlobalTerm" formControlName="searchGlobalTerm" class="uk-search-input" type="search" placeholder="Search all content..." [ngClass]="{' uk-form-danger': searchGlobalForm.get('searchGlobalTerm').touched && searchGlobalForm.get('searchGlobalTerm').hasError('invalid') }" autocomplete="off" autofocus>
        </form>
        <div class="advanced-search-wrapper">
          <button class="uk-button uk-button-text" (click)="openAdvancedSearch()">Advanced search</button>
        </div>
        <a id="navSearchBar" class="uk-navbar-toggle" uk-close uk-toggle="target: .nav-overlay; animation: uk-animation-fade" (click)="emptySearch()"></a>
      </div>
    </div>-->
    <div class="nav-overlay uk-navbar-right" *ngIf="loggedIn">
      <ul class="uk-navbar-nav">
        <li class="uk-margin-left">
          <a class="uk-navbar-toggle" id="search-icon" uk-search-icon (click)="openAdvancedSearch()"></a>
        </li>
        <li>
          <a><div class="no-image uk-border-circle uk-icon-button" uk-icon="icon: user; ratio: 1.0"></div></a>
          <div uk-dropdown="pos: bottom-right">
            <ul class="uk-nav uk-navbar-dropdown-nav">
              <li class="uk-nav-header">User profile</li>
              <div class="avatar-container uk-border-circle uk-margin-auto-left uk-margin-auto-right uk-margin-small-top uk-margin-small-bottom uk-text-center">
                <img class="uk-border-circle" src="https://getuikit.com/docs/images/avatar.jpg" width="100" height="100" alt="{{ auth.currentUser.username }} avatar" *ngIf="auth.currentUser.avatar">
                <div class="no-image uk-border-circle uk-icon-button" uk-icon="icon: user; ratio: 1.5"></div>
              </div>
              <li class="uk-margin-small-bottom uk-text-center uk-text-break">{{ auth.currentUser.username }}</li>
              <li><a routerLink="/profile">My profile</a></li>
              <!--<li><a routerLink="/register" routerLinkActive="active" *ngIf="loggedIn">Register</a></li>-->
              <li class="uk-nav-divider"></li>
              <!--Accounts-->
              <li class="uk-nav-header">Account</li>
              <li><a (click)="openAccountSettings()">Account settings</a></li>
              <!--<li><a routerLink="/accounts" *ngIf="auth.isAdmin">Account extras</a></li>-->
              <li><a routerLink="/accounts">All accounts</a></li>
              <li class="uk-nav-divider"></li>
              <li><a routerLink="/logout" routerLinkActive="active">Log out</a></li>
            </ul>
          </div>
        </li>
      </ul>
    </div>
  </nav>
</div>

