<div class="uk-section uk-section-default uk-padding-remove-top" uk-height-viewport="offset-top: true">

  <app-sidenav contentType="Exhibitions"></app-sidenav>

  <div class="uk-container-expand uk-height-expand">
    <div uk-grid>
      <div class="uk-width-expand@m">
        <div class="uk-padding-small uk-padding-remove-top" *ngIf="!isLoading">
          <div class="uk-width-1-1 uk-text-right uk-margin-small-bottom uk-padding-remove">
            <app-search-content contentType="exhibition" fields="['name', 'startDate']"></app-search-content>
            <button class="uk-button uk-button-text uk-margin-right" type="button"
                    (click)="openAddNewExhibition()">Add new
            </button>
            <div id="addNewExhibition" class="uk-modal-container"
                 uk-modal="stack: true; bg-close: false; esc-close: false;">
              <div class="uk-modal-dialog">
                <form appNoSubmitEnter [formGroup]="addExhibitionForm">
                  <input type="hidden" name="addExhibitionOwner" name="addExhibitionOwner"
                         formControlName="addExhibitionOwner" [ngModel]="activeAccount">
                  <div class="uk-modal-header">
                    <h2 class="uk-modal-title">Add new exhibition</h2>
                  </div>
                  <div class="uk-modal-body" uk-grid>
                    <div class="uk-width-1-2@s">
                      <div class="uk-margin">
                        <label class="uk-form-label" for="addExhibitionName">Name <span
                          class="required">*</span></label>
                        <div class="uk-form-controls">
                          <input id="addExhibitionName" name="addExhibitionName" formControlName="addExhibitionName"
                                 type="text" placeholder="Name ..." class="uk-input"
                                 [ngClass]="{'uk-form-danger': addExhibitionForm.get('addExhibitionName').touched && addExhibitionForm.get('addExhibitionName').errors }"
                                 (blur)="validateName()">
                          <div class="uk-text-danger uk-text-small"
                               *ngIf="addExhibitionForm.get('addExhibitionName').hasError('maxlength')"
                          >
                            {{ getCharactersLimitError('addExhibitionName') }}
                          </div>
                          <div class="uk-text-danger uk-text-small"
                               *ngIf="addExhibitionForm.get('addExhibitionName').hasError('pattern')"
                          >
                            Field value is incorrect
                          </div>
                          <span *ngIf="addExhibitionForm.controls.addExhibitionName.hasError('exists')"
                                class="uk-text-danger uk-text-small">Name might not be unique</span>
                        </div>
                      </div>
                    </div>
                    <div class="uk-width-1-2@s">
                      <div class="uk-margin">
                        <div class="uk-flex uk-flex-between uk-text-meta">
                          <label class="uk-form-label" for="addExhibitionDescription">Description</label>
                          <div class="uk-text-small">
                            {{ getCharactersLeft('addExhibitionDescription', 2048) }}
                          </div>
                        </div>
                        <div class="uk-form-controls">
                          <textarea id="addExhibitionDescription" name="addExhibitionDescription"
                                    formControlName="addExhibitionDescription" rows="5" placeholder="Description ..."
                                    class="uk-textarea"
                                    [ngClass]="{'uk-form-danger': addExhibitionForm.get('addExhibitionDescription').touched && addExhibitionForm.get('addExhibitionDescription').errors }"></textarea>
                          <div class="uk-text-danger uk-text-small"
                               *ngIf="addExhibitionForm.get('addExhibitionDescription').hasError('maxlength')"
                          >
                            {{ getCharactersLimitError('addExhibitionDescription') }}
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="uk-width-1-2@s">
                      <div class="uk-margin">
                        <label class="uk-form-label" for="addExhibitionStartDate">Start date</label>
                        <div class="uk-form-controls">
                          <input
                            id="addExhibitionStartDate"
                            name="addExhibitionStartDate"
                            formControlName="addExhibitionStartDate"
                            type="text"
                            placeholder="Start date ..."
                            class="uk-input"
                            [ngClass]="{'uk-form-danger': addExhibitionForm.get('addExhibitionStartDate').touched && addExhibitionForm.get('addExhibitionStartDate').errors }"
                            angular-mydatepicker
                            [options]="myDatePickerOptions"
                            #AngularMyDatePickerAddExhibitionStartDate="angular-mydatepicker"
                            (click)="AngularMyDatePickerAddExhibitionStartDate.toggleCalendar()"
                            (dateChanged)="updateDate('addExhibitionStartDate', $event)"
                          />
                          <div class="uk-text-danger uk-text-small"
                               *ngIf="addExhibitionForm.get('addExhibitionStartDate').hasError('maxlength')"
                          >
                            {{ getCharactersLimitError('addExhibitionStartDate') }}
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="uk-width-1-2@s">
                      <div class="uk-margin">
                        <label class="uk-form-label" for="addExhibitionEndDate">End date</label>
                        <div class="uk-form-controls">
                          <input
                            id="addExhibitionEndDate"
                            name="addExhibitionEndDate"
                            formControlName="addExhibitionEndDate"
                            type="text"
                            placeholder="End date ..."
                            class="uk-input"
                            [ngClass]="{'uk-form-danger': addExhibitionForm.get('addExhibitionEndDate').touched && addExhibitionForm.get('addExhibitionEndDate').errors }"
                            angular-mydatepicker
                            [options]="myDatePickerOptions"
                            #AngularMyDatePickerAddExhibitionEndDate="angular-mydatepicker"
                            (click)="AngularMyDatePickerAddExhibitionEndDate.toggleCalendar()"
                            (dateChanged)="updateDate('addExhibitionEndDate', $event)"
                          />
                          <div class="uk-text-danger uk-text-small"
                               *ngIf="addExhibitionForm.get('addExhibitionEndDate').hasError('maxlength')"
                          >
                            {{ getCharactersLimitError('addExhibitionEndDate') }}
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="uk-width-1-2@s">
                      <div class="uk-margin">
                        <label class="uk-form-label" for="addExhibitionType">Exhibition type</label>
                        <div class="uk-form-controls">
                          <select id="addExhibitionType" name="addExhibitionType" formControlName="addExhibitionType"
                                  class="uk-select">
                            <option value="" disabled>Please select ...</option>
                            <option value="Internal">Internal</option>
                            <option value="Joint">Joint</option>
                          </select>
                        </div>
                      </div>
                    </div>
                    <div class="uk-width-1-2@s">
                      <div class="uk-margin">
                        <label class="uk-form-label" for="addExhibitionLocation">Location</label>
                        <div class="uk-form-controls">
                          <ng2-completer id="addExhibitionLocation" name="addExhibitionLocation"
                                         formControlName="addExhibitionLocation" [datasource]="variables.locations"
                                         [minSearchLength]="0" [textNoResults]="null"></ng2-completer>
                          <div class="uk-text-danger uk-text-small"
                               *ngIf="addExhibitionForm.get('addExhibitionLocation').hasError('maxlength')"
                          >
                            {{ getCharactersLimitError('addExhibitionLocation') }}
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="uk-width-1-1">
                      <div class="uk-margin">
                        <label class="uk-form-label">Related artworks</label>
                        <!--<form appNoSubmitEnter id="searchArtwork" [formGroup]="searchArtworkForm">
                          <div class="uk-margin">
                            <div class="uk-form-controls uk-flex">
                              <input id="searchArtworkTerm" name="searchArtworkTerm" formControlName="searchArtworkTerm" class="uk-input uk-margin-small-right" type="search" placeholder="Search artworks..." [ngClass]="{' uk-form-danger': searchArtworkForm.get('searchArtworkTerm').touched && searchArtworkForm.get('searchArtworkTerm').hasError('invalid') }" autocomplete="off">
                              <button class="uk-button uk-button-default" (click)="searchArtwork()">Search</button>
                            </div>
                          </div>
                        </form>-->
                        <div class="uk-text-right">
                          <button class="uk-button uk-button-default" (click)="openAdvancedSearch()">Add</button>
                        </div>
                        <!--<div class="uk-position-relative" [hidden]="!searchArtworkResults.length">
                          <div class="uk-text-right">
                            <button class="uk-button uk-button-primary" (click)="addArtworksToExhibition()" [disabled]="!selectedArtworks.length">Add selected</button>
                          </div>
                          <hr class="uk-margin-small-bottom">
                        </div>
                        <div id="search-artwork-results-container" class="results-container" [hidden]="!searchArtworkResults.length">
                          <div class="uk-flex uk-flex-column">
                            &lt;!&ndash;<div class="uk-container-expand uk-height-expand uk-padding-small uk-padding-remove-left uk-padding-remove-right" *ngIf="!searchArtworkResults.length">
                              <ul class="search-results uk-flex-center" uk-tab>
                                <h3>No search results</h3>
                              </ul>
                            </div>&ndash;&gt;
                            <div class="uk-container-expand uk-height-expand">
                              <div class="uk-margin">
                                <div class="uk-width-1-1">
                                  <table class="uk-table uk-table-middle uk-table-divider uk-margin-remove-bottom">
                                    <thead>
                                    <tr>
                                      <th class="uk-table-shrink">Select</th>
                                      <th class="uk-table-shrink">Image</th>
                                      <th class="uk-width-expand">Name</th>
                                      <th class="uk-width-expand uk-text-nowrap">Artist</th>
                                      <th class="uk-table-shrink uk-text-nowrap">Technique</th>
                                    </tr>
                                    </thead>
                                    <tbody>
                                    <tr *ngFor="let artwork of searchArtworkResults">
                                      <td><input class="uk-checkbox" type="checkbox" (click)="selectExhibitionArtwork(artwork)"></td>
                                      <td>
                                        <div class="image-max-height uk-preserve-width uk-background-contain uk-background-muted uk-height-small uk-panel uk-flex uk-flex-center uk-flex-middle" width="60" [attr.data-src]="'/api/storagefile/' + activeAccount + '/' + artwork.coverImage + '/medium'" uk-img *ngIf="artwork.coverImage">

                                        </div>
                                        &lt;!&ndash; Jos ei ole coverImagea, niin sit otetaan eka image &ndash;&gt;
                                        &lt;!&ndash;<img class="uk-preserve-width" [src]="'/api/storagefile/' + artwork.images[0].fileId" width="60" *ngIf="!artwork.coverImage && artwork.images && artwork.images.length">&ndash;&gt;
                                        <div class="image-max-height uk-preserve-width uk-background-contain uk-background-muted uk-height-small uk-panel uk-flex uk-flex-center uk-flex-middle" width="60" [attr.data-src]="'/api/storagefile/' + activeAccount + '/' + artwork.images[0].fileId + '/medium'" uk-img *ngIf="!artwork.coverImage && artwork.images && artwork.images.length">

                                        </div>
                                        &lt;!&ndash; Jos ei ole coverImagea tai ekan imagea, ni sit otetaan placeholder &ndash;&gt;
                                        &lt;!&ndash;<img class="uk-preserve-width uk-border-circle" src="/api/image/artbuddy-logo-placeholder.svg" *ngIf="!artwork.coverImage && !artwork.images.length" width="60">&ndash;&gt;
                                        <div class="image-max-height uk-preserve-width uk-background-contain uk-background-muted uk-height-small uk-panel uk-flex uk-flex-center uk-flex-middle" width="60" [attr.data-src]="'/api/image/artbuddy-logo-placeholder.svg'" uk-img *ngIf="!artwork.coverImage && !artwork.images.length">

                                        </div>
                                      </td>
                                      <td>{{ artwork.name }}</td>
                                      <td>{{ artwork.artistName }}</td>
                                      <td>{{ artwork.technique }}</td>
                                    </tr>
                                    </tbody>
                                  </table>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>-->
                        <div class="uk-grid-match uk-child-width-1-2@s uk-child-width-1-3@m uk-child-width-1-4@xl"
                             uk-grid uk-sortable [hidden]="!activeExhibition.items.length">
                          <div class="uk-card" *ngFor="let item of activeExhibition.items">
                            <div
                              class="uk-card-header uk-flex uk-flex-middle uk-flex-between uk-padding-remove-horizontal">
                              <div class="uk-padding-remove-left">
                                <h3 class="uk-card-title uk-margin-remove-bottom">{{ item.name }}</h3>
                                <div class="uk-text-meta">
                                  <span *ngIf="item.artistName">{{ item.artistName }}</span>
                                  <span *ngIf="item.created">, {{ item.created }}</span>
                                </div>
                              </div>
                              <!--<div>
                                <div class="uk-text-meta">{{ item.availability }}</div>
                                <div class="uk-text-meta" *ngIf="item.price.price">{{ item.price.price }} {{ item.price.currency }}</div>
                              </div>-->
                            </div>
                            <div class="uk-card-body uk-padding-remove uk-background-muted artwork-image"
                                 [attr.data-src]="'/api/storagefile/' + activeAccount + '/' + item.coverImage + '/medium'"
                                 uk-img *ngIf="item.coverImage"></div>
                            <div class="uk-card-body uk-padding-remove uk-background-muted artwork-image"
                                 [attr.data-src]="'/api/storagefile/' + activeAccount + '/' + item.images[0].fileId + '/medium'"
                                 uk-img *ngIf="!item.coverImage && item.images && item.images.length"></div>
                            <div class="uk-card-body uk-padding-remove uk-background-muted artwork-image bg-max-size"
                                 *ngIf="!item.coverImage && !item.images.length"></div>
                            <div
                              class="uk-card-footer uk-flex uk-flex-middle uk-flex-between uk-padding-remove-horizontal">
                              <!--<div>
                                <div class="uk-text-meta" *ngIf="item.width && item.height">{{ item.width }} * {{ item.height }} cm</div>
                              </div>-->
                              <div class="uk-flex uk-flex-column">
                                <a (click)="removeArtworkFromExhibition(item)"
                                   class="uk-button uk-button-text">Remove</a>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="uk-width-1-1">
                      <label class="uk-form-label">Installation images</label>
                    </div>
                    <div class="uk-width-1-1">
                      <div class="uk-position-relative uk-visible-toggle" uk-slider>
                        <ul class="uk-slider-items uk-child-width-1-2@s uk-child-width-1-3@m uk-grid">
                          <li class="uk-text-center"
                              *ngFor="let image of activeExhibition['installShots']; let index = index">
                            <div class="uk-inline-clip uk-transition-toggle uk-width-1-1" tabindex="index"
                                 *ngIf="image.fileId">
                              <div
                                class="uk-background-contain uk-background-muted uk-height-medium uk-panel uk-flex uk-flex-center uk-flex-middle"
                                [attr.data-src]="'/api/storagefile/' + activeAccount + '/' + image.fileId + '/medium'"
                                uk-img>
                              </div>
                            </div>
                          </li>
                        </ul>
                        <a class="uk-position-center-left uk-position-small uk-hidden-hover" uk-slidenav-previous
                           uk-slider-item="previous"></a>
                        <a class="uk-position-center-right uk-position-small uk-hidden-hover" uk-slidenav-next
                           uk-slider-item="next"></a>
                      </div>
                    </div>
                    <div class="uk-width-1-1">
                      <div class="uk-margin">
                        <p *ngIf="!activeExhibition['installShots'].length">No install shots</p>
                        <div class="uk-form-controls">
                          <div class="drop-container" ngFileDrop
                               [options]="{ concurrency: 1, allowedContentTypes: ['image/jpeg', 'image/jpg', 'image/png', 'image/gif'], maxFileSize: 5000000 }"
                               (uploadOutput)="onUploadOutput($event)" (click)="imageFile.click()"
                               [uploadInput]="uploadInput">
                            <div class="js-upload uk-placeholder uk-text-center"
                                 [ngClass]="{ 'is-drop-over': dragOver }">
                              <span class="uk-text-middle">Add images by dropping them here or open file dialog by clicking here.<br>Allowed filetypes include JPG, PNG and GIF.<br>Maximum allowed file size is 5 Mb.</span>
                            </div>
                            <input #imageFile type="file" ngFileSelect
                                   [options]="{ allowedContentTypes: ['image/jpeg', 'image/jpg', 'image/png', 'image/gif'], maxFileSize: 5000000 }"
                                   [uploadInput]="uploadInput" (uploadOutput)="onUploadOutput($event)" multiple
                                   class="file-input">
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="uk-width-1-1 uk-margin-medium-top">
                      <h3 class="uk-margin-remove-bottom">Additional information</h3>
                      <hr class="uk-margin-small-top">
                    </div>
                    <div class="uk-width-1-2">
                      <div class="uk-margin">
                        <div class="uk-flex uk-flex-between uk-text-meta">
                          <label class="uk-form-label" for="addExhibitionNotes">Other information</label>
                          <div class="uk-text-small">
                            {{ getCharactersLeft('addExhibitionNotes', 2048) }}
                          </div>
                        </div>
                        <div class="uk-form-controls">
                          <textarea id="addExhibitionNotes" name="addExhibitionNotes"
                                    formControlName="addExhibitionNotes" rows="5" type="text"
                                    placeholder="Other information ..." class="uk-textarea"
                                    [ngClass]="{'uk-form-danger': addExhibitionForm.get('addExhibitionNotes').touched && addExhibitionForm.get('addExhibitionNotes').errors }"></textarea>
                        </div>
                      </div>
                    </div>
                    <div class="uk-width-1-2">
                      <div class="uk-margin">
                        <div class="uk-flex uk-flex-between uk-text-meta">
                          <label class="uk-form-label" for="addExhibitionComments">Comments</label>
                          <div class="uk-text-small">
                            {{ getCharactersLeft('addExhibitionComments', 2048) }}
                          </div>
                        </div>
                        <div class="uk-form-controls">
                          <textarea id="addExhibitionComments" name="addExhibitionComments"
                                    formControlName="addExhibitionComments" rows="5" type="text"
                                    placeholder="Comments ..." class="uk-textarea"
                                    [ngClass]="{'uk-form-danger': addExhibitionForm.get('addExhibitionComments').touched && addExhibitionForm.get('addExhibitionComments').errors }"></textarea>
                        </div>
                      </div>
                    </div>
                    <div class="uk-width-1-2">
                      <div class="uk-margin">
                        <label class="uk-form-label" for="addExhibitionTags">Tags</label>
                        <div class="uk-form-controls">
                          <input id="addExhibitionTags" name="addExhibitionTags" formControlName="addExhibitionTags"
                                 type="text" placeholder="Tags ..." class="uk-input"
                                 [ngClass]="{'uk-form-danger': addExhibitionForm.get('addExhibitionTags').touched && addExhibitionForm.get('addExhibitionTags').errors }">
                        </div>
                      </div>
                    </div>
                    <div class="uk-width-1-2">
                      <div class="uk-margin">
                        <label class="uk-form-label" for="addExhibitionVisibility">Visibility</label>
                        <div class="uk-form-controls">
                          <label><input id="addExhibitionVisibility" name="addExhibitionVisibility"
                                        formControlName="addExhibitionVisibility" class="uk-checkbox" type="checkbox">
                            Public</label>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="uk-modal-footer">
                    <p class="uk-text-right">
                      <button class="uk-button uk-button-default uk-modal-close uk-margin-small-right" type="button"
                              id="addNewExhibitionCancel" (click)="resetForm()">Cancel
                      </button>
                      <button type="submit" [disabled]="!addExhibitionForm.valid" class="uk-button uk-button-primary"
                              (click)="addExhibition()">Save
                      </button>
                    </p>
                  </div>
                </form>
              </div>
            </div>
            <button class="uk-button uk-button-text uk-margin-right" type="button">Sort <span uk-icon="icon: chevron-down"></span></button>
            <div uk-dropdown="mode: click;">
              <ul class="uk-nav uk-dropdown-nav">
<!--                <li [ngClass]="{'uk-active': activeSort('created') }" (click)="sortExhibitions('created')"><a>Created</a></li>-->
                <li [ngClass]="{'uk-active': activeSort('name') }" (click)="sortExhibitions('name')"><a>Name</a></li>
                <li [ngClass]="{'uk-active': activeSort('type') }" (click)="sortExhibitions('type')"><a>Type</a></li>
                <li [ngClass]="{'uk-active': activeSort('startDate') }" (click)="sortExhibitions('startDate')"><a
                >Start date</a></li>
                <li [ngClass]="{'uk-active': activeSort('location') }" (click)="sortExhibitions('location')"><a
                >Location</a></li>
              </ul>
            </div>
            <button class="uk-button uk-button-text" type="button">Limit {{ limitQuery ? ': ' + limitQuery : null }} <span uk-icon="icon: chevron-down"></span></button>
            <div uk-dropdown="mode: click;">
              <ul class="uk-nav uk-dropdown-nav">
                <li [ngClass]="{'uk-active': activeLimit(10) }" (click)="setLimit(10)"><a>10</a></li>
                <li [ngClass]="{'uk-active': activeLimit(25) }" (click)="setLimit(25)"><a>25</a></li>
                <li [ngClass]="{'uk-active': activeLimit(50) }" (click)="setLimit(50)"><a>50</a></li>
                <li [ngClass]="{'uk-active': activeLimit(100) }" (click)="setLimit(100)"><a>100</a></li>
                <li [ngClass]="{'uk-active': activeLimit(200) }" (click)="setLimit(200)"><a>200</a></li>
              </ul>
            </div>
          </div>
          <div class="uk-height-1-1">
            <table class="uk-table uk-table-middle uk-table-divider">
              <thead>
              <tr>
                <th class="uk-text-nowrap" [ngClass]="{'active': activeSort('name') }"
                    (click)="sortExhibitions('name')">Name<span uk-icon="chevron-down"
                                                                [ngClass]="{'inverse': !sortAscending }"></span></th>
                <th class="uk-text-nowrap uk-visible@m" [ngClass]="{'active': activeSort('type') }"
                    (click)="sortExhibitions('type')">Type<span uk-icon="chevron-down"
                                                                [ngClass]="{'inverse': !sortAscending }"></span></th>
                <th class="uk-text-nowrap uk-visible@m" [ngClass]="{'active': activeSort('startDate') }"
                    (click)="sortExhibitions('startDate')">Start date<span uk-icon="chevron-down"
                                                                           [ngClass]="{'inverse': !sortAscending }"></span>
                </th>
                <th class="uk-text-nowrap uk-visible@m" [ngClass]="{'active': activeSort('location') }"
                    (click)="sortExhibitions('location')">Location<span uk-icon="chevron-down"
                                                                        [ngClass]="{'inverse': !sortAscending }"></span>
                </th>
                <th class="uk-table-shrink"></th>
              </tr>
              </thead>
              <tbody>
              <tr *ngFor="let exhibition of exhibitions">
                <!--<td>
                  <img class="uk-preserve-width uk-border-circle" src="/api/image/artbuddy-logo-placeholder.svg" *ngIf="!exhibition.images.length" width="60">
                  <img class="uk-preserve-width" [src]="'/api/storagefile/' + exhibition.images[0].fileId" width="60" *ngIf="exhibition.images.length">
                </td>-->
                <!--[ngStyle]="{'background-image': 'url(/api/file/' + image.fileId + ')'}"-->

                <td (click)="openExhibition(exhibition._id)" class="pointer">{{ exhibition.name }}</td>
                <td class="uk-visible@m">{{ exhibition.type }}</td>
                <td class="uk-visible@m">{{ exhibition.startDate | date: 'd.M.yyyy' }}</td>
                <td class="uk-visible@m">{{ exhibition.location }}</td>
                <td class="uk-text-right">
                  <div class="uk-button-group">
                    <button (click)="openExhibition(exhibition._id)" class="uk-button uk-button-text uk-margin-right" type="button">Open</button>
                    <div class="uk-inline" class="uk-visible@m">
                      <button class="uk-button uk-button-link" type="button"><span uk-icon="icon: chevron-down"></span></button>
                      <div uk-dropdown="mode: click; boundary: ! .uk-button-group; boundary-align: true;">
                        <ul class="uk-nav uk-dropdown-nav">
                          <li><a (click)="removeExhibition(exhibition._id)">Remove</a></li>
                        </ul>
                      </div>
                    </div>
                  </div>
                </td>
              </tr>
              </tbody>
            </table>
          </div>
          <ul class="uk-pagination uk-flex-center uk-flex-middle uk-margin-medium-top" *ngIf="pages.length > 1">
            <li class="uk-margin-small-right" [ngClass]="{'uk-disabled': (currentPage === 1) }">
              <a (click)="setCurrentPage(currentPage-1)">
                <span class="uk-margin-small-right" uk-pagination-previous></span> Previous
              </a>
            </li>
            <li>
              <select class="uk-select" [ngModel]="currentPage" (change)="setCurrentPageString($event.target.value)">
                <option *ngFor="let page of pages; let index = index; first as isFirst; last as isLast"
                        [value]="index+1">{{ index + 1 }}</option>
              </select>
            </li>
            <li class="uk-margin-small-left" [ngClass]="{'uk-disabled': (currentPage === pageCount) }">
              <a (click)="setCurrentPage(currentPage+1)">
                Next <span class="uk-margin-small-left" uk-pagination-next></span>
              </a>
            </li>
          </ul>
        </div>
      </div>
    </div>
  </div>
</div>
<app-advanced-search-modal (setResults)="setArtworkSearchResults($event)"
                           *ngIf="showAdvancedArtworkSearchModalWindow"></app-advanced-search-modal>
