<app-toast [message]="toast.message"></app-toast>

<div id="forgot" uk-height-viewport="offset-top: 80" class="uk-flex uk-flex-middle uk-text-center uk-section">
  <div class="uk-container uk-width-viewport">
    <div class="uk-grid-match uk-child-width-expand@m" uk-grid>
      <div class="uk-flex-center">
        <div class="uk-card uk-card-default uk-width-1-2@s">
          <div class="uk-card-header no-min-height">
            <div class="uk-grid-small uk-flex-middle" uk-grid>
              <div class="uk-width-expand">
                <h3 class="uk-card-title uk-margin-remove-bottom">Forgot password?</h3>
              </div>
            </div>
          </div>
          <form appNoSubmitEnter [formGroup]="forgotForm" (ngSubmit)="forgot()" class="uk-form-stacked">
            <div class="uk-card-body uk-text-left">
              <div class="uk-margin">
                <div class="uk-width-1-1">
                  <div class="uk-margin">
                    <label class="uk-form-label" for="forgotEmail">Email</label>
                    <div class="uk-form-controls">
                      <input autoFocus id="forgotEmail" name="forgotEmail" formControlName="forgotEmail" type="text" placeholder="Email ..." class="uk-input" [ngClass]="{' uk-form-danger': forgotForm.get('forgotEmail').touched && forgotForm.get('forgotEmail').hasError('required') }">
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="uk-card-footer uk-text-center">
              <button type="submit" class="uk-button uk-button-primary">Reset password</button>
            </div>
          </form>
        </div>
      </div>
    </div>
  </div>
</div>
