<div class="uk-section uk-section-default uk-padding-remove-top" uk-height-viewport="offset-top: true">

  <app-sidenav contentType="Clients"></app-sidenav>

  <div class="uk-container-expand uk-height-expand">
    <div uk-grid>
      <div class="uk-width-expand@m">
        <div class="uk-padding-small uk-padding-remove-top" *ngIf="!isLoading">
          <div class="uk-width-1-1 uk-text-right uk-margin-small-bottom uk-padding-remove">
            <app-search-content contentType="client" fields="['name', 'status']"></app-search-content>
            <button class="uk-button uk-button-text uk-margin-right" type="button">Add new <span uk-icon="icon: chevron-down"></span></button>
            <div uk-dropdown="mode: click;">
              <ul class="uk-nav uk-dropdown-nav">
                <li><a (click)="openAddNewPrivatePerson()">New private person</a></li>
                <li><a (click)="openAddNewOrganisation()">New organisation</a></li>
              </ul>
            </div>
            <div id="addNewClient" class="uk-modal-container" uk-modal="bg-close: false; esc-close: false;">
              <div class="uk-modal-dialog">
                <form appNoSubmitEnter [formGroup]="addClientForm" (ngSubmit)="addClient()">
                  <input type="hidden" name="addClientActorType" name="addClientActorType"
                         formControlName="addClientActorType" [ngModel]="actorType">
                  <input type="hidden" name="addClientOwner" name="addClientOwner" formControlName="addClientOwner"
                         [ngModel]="activeAccount">
                  <div class="uk-modal-header">
                    <h2 class="uk-modal-title">Add new client</h2>
                  </div>
                  <div class="uk-modal-body" uk-grid>
                    <div class="uk-width-1-1">
                      <h3 class="uk-margin-remove-bottom">Basic information</h3>
                      <hr class="uk-margin-small-top">
                    </div>
                    <div class="uk-width-1-2@s">
                      <div class="uk-margin">
                        <label class="uk-form-label" for="addClientName">Name <span class="required">*</span></label>
                        <div class="uk-form-controls">
                          <input id="addClientName" name="addClientName" formControlName="addClientName" type="text"
                                 placeholder="Name ..." class="uk-input"
                                 [ngClass]="{'uk-form-danger': addClientForm.get('addClientName').touched && addClientForm.get('addClientName').errors }"
                                 (blur)="validateName()">
                          <div class="uk-text-danger uk-text-small"
                               *ngIf="addClientForm.get('addClientName').hasError('maxlength')"
                          >
                            {{ getCharactersLimitError('addClientName') }}
                          </div>
                          <div class="uk-text-danger uk-text-small"
                               *ngIf="addClientForm.get('addClientName').hasError('pattern')"
                          >
                            Field value is incorrect
                          </div>
                          <span *ngIf="addClientForm.controls.addClientName.hasError('exists')"
                                class="uk-text-danger uk-text-small">Name might not be unique</span>
                        </div>
                      </div>
                    </div>
                    <div class="uk-width-1-2@s" *ngIf="clientType == 'PrivatePerson'">
                      <div class="uk-margin">
                        <label class="uk-form-label" for="addClientLastName">Last name <span
                          class="required">*</span></label>
                        <div class="uk-form-controls">
                          <input id="addClientLastName" name="addClientLastName" formControlName="addClientLastName"
                                 type="text" placeholder="Last name ..." class="uk-input"
                                 [ngClass]="{'uk-form-danger': addClientForm.get('addClientLastName').touched && addClientForm.get('addClientLastName').errors }"
                                 (blur)="validateName()">
                          <div class="uk-text-danger uk-text-small"
                               *ngIf="addClientForm.get('addClientLastName').hasError('maxlength')"
                          >
                            {{ getCharactersLimitError('addClientLastName') }}
                          </div>
                          <span *ngIf="addClientForm.controls.addClientLastName.hasError('exists')"
                                class="uk-text-danger uk-text-small">Name might not be unique</span>
                        </div>
                      </div>
                    </div>
                    <!--<div class="uk-width-1-2@s" *ngIf="clientType == 'PrivatePerson'">
                      <div class="uk-margin">
                        <label class="uk-form-label" for="addClientSSN">Social security number</label>
                        <div class="uk-form-controls">
                          <input id="addClientSSN" name="addClientSSN" formControlName="addClientSSN" type="text" placeholder="SSN ..." class="uk-input" [ngClass]="{'uk-form-danger': addClientForm.get('addClientSSN').touched && addClientForm.get('addClientSSN').hasError('required') }">
                        </div>
                      </div>
                    </div>-->
                    <div class="uk-width-1-2@s" *ngIf="clientType == 'Organisation'">
                      <div class="uk-margin">
                        <label class="uk-form-label" for="addClientBID">Business identification number</label>
                        <div class="uk-form-controls">
                          <input id="addClientBID" name="addClientBID" formControlName="addClientBID" type="text"
                                 placeholder="BID ..." class="uk-input"
                                 [ngClass]="{'uk-form-danger': addClientForm.get('addClientBID').touched && addClientForm.get('addClientBID').errors }">
                          <div class="uk-text-danger uk-text-small"
                               *ngIf="addClientForm.get('addClientBID').hasError('maxlength')"
                          >
                            {{ getCharactersLimitError('addClientBID') }}
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="uk-width-1-2@s">
                      <div class="uk-margin">
                        <label class="uk-form-label" for="addClientClientCode">Client code</label>
                        <div class="uk-form-controls">
                          <input id="addClientClientCode" name="addClientClientCode"
                                 formControlName="addClientClientCode" type="text" placeholder="Client code ..."
                                 class="uk-input"
                                 [ngClass]="{'uk-form-danger': addClientForm.get('addClientClientCode').touched && addClientForm.get('addClientClientCode').errors }">
                          <div class="uk-text-danger uk-text-small"
                               *ngIf="addClientForm.get('addClientClientCode').hasError('maxlength')"
                          >
                            {{ getCharactersLimitError('addClientClientCode') }}
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="uk-width-1-2@s">
                      <div class="uk-margin">
                        <label class="uk-form-label" for="addClientBillingInfo">Billing information</label>
                        <div class="uk-form-controls">
                          <input id="addClientBillingInfo" name="addClientBillingInfo"
                                 formControlName="addClientBillingInfo" type="text"
                                 placeholder="Billing information ..." class="uk-input"
                                 [ngClass]="{'uk-form-danger': addClientForm.get('addClientBillingInfo').touched && addClientForm.get('addClientBillingInfo').errors }">
                          <div class="uk-text-danger uk-text-small"
                               *ngIf="addClientForm.get('addClientBillingInfo').hasError('maxlength')"
                          >
                            {{ getCharactersLimitError('addClientBillingInfo') }}
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="uk-width-1-2@s ">
                      <div class="uk-margin">
                        <div class="uk-flex uk-flex-between uk-text-meta">
                          <label class="uk-form-label" for="addClientDescription">Description</label>
                          <div class="uk-text-small">
                            {{ getCharactersLeft('addClientDescription', 2048) }}
                          </div>
                        </div>
                        <div class="uk-form-controls">
                          <textarea id="addClientDescription" name="addClientDescription"
                                    formControlName="addClientDescription" rows="5" placeholder="Description ..."
                                    class="uk-textarea"
                                    [ngClass]="{'uk-form-danger': addClientForm.get('addClientDescription').touched && addClientForm.get('addClientDescription').errors }"></textarea>
                          <div class="uk-text-danger uk-text-small"
                               *ngIf="addClientForm.get('addClientDescription').hasError('maxlength')"
                          >
                            {{ getCharactersLimitError('addClientDescription') }}
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="uk-width-1-2@s">
                      <div class="uk-margin">
                        <label class="uk-form-label" for="addClientGroup">Client group</label>
                        <div class="uk-form-controls">
                          <ng-select
                            [items]="activeClientClientGroups"
                            [ngModel]="selectedClientGroups"
                            [ngModelOptions]="{standalone: true}"
                            [multiple]="true"
                            [addTag]="false"
                            [selectOnTab]="false"
                            [closeOnSelect]="false"
                            [clearable]="false"
                            bindLabel="title"
                            placeholder="Please select ..."
                            id="addClientGroup"
                            class="uk-select"
                            #addClientGroup
                            (change)="addClientGroup.blur()"
                          >
                            <ng-template ng-option-tmp let-item="item">
                              <div class="uk-margin-small-right" (click)="setClientGroups(item)">
                                <input class="uk-checkbox uk-margin-small-right" type="checkbox"
                                       [checked]="clientGroupIsSelected(item)">
                                {{item.title}}
                              </div>
                            </ng-template>
                          </ng-select>
                        </div>
                      </div>
                    </div>
                    <div class="uk-width-1-2@s">
                      <div class="uk-margin">
                        <label class="uk-form-label" for="addLanguages">Languages</label>
                        <div class="uk-form-controls">
                          <ng-select
                            [items]="activeClientLanguages"
                            [ngModel]="selectedLanguages"
                            [ngModelOptions]="{standalone: true}"
                            [multiple]="true"
                            [addTag]="false"
                            [selectOnTab]="false"
                            [closeOnSelect]="false"
                            [clearable]="false"
                            bindLabel="title"
                            placeholder="Please select ..."
                            id="addLanguages"
                            class="uk-select"
                            #addLanguage
                            (change)="addLanguage.blur()"
                          >
                            <ng-template ng-option-tmp let-item="item">
                              <div class="uk-margin-small-right" (click)="setLanguages(item)">
                                <input class="uk-checkbox uk-margin-small-right" type="checkbox"
                                       [checked]="languageIsSelected(item)">
                                {{item.title}}
                              </div>
                            </ng-template>
                          </ng-select>
                        </div>
                      </div>
                    </div>
                    <div class="uk-width-1-2@s">
                      <div class="uk-margin">
                        <label class="uk-form-label" for="addClientOrganisation">Organisation</label>
                        <div class="uk-form-controls">
                          <input id="addClientOrganisation" name="addClientOrganisation"
                                 formControlName="addClientOrganisation" type="text" placeholder="Organisation ..."
                                 class="uk-input"
                                 [ngClass]="{'uk-form-danger': addClientForm.get('addClientOrganisation').touched && addClientForm.get('addClientOrganisation').errors }">
                          <div class="uk-text-danger uk-text-small"
                               *ngIf="addClientForm.get('addClientOrganisation').hasError('maxlength')"
                          >
                            {{ getCharactersLimitError('addClientOrganisation') }}
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="uk-width-1-2@s">
                      <div class="uk-margin">
                        <label class="uk-form-label" for="addClientContactMethod">Contact method</label>
                        <div class="uk-form-controls">
                          <ng-select
                            [items]="activeClientContactMethods"
                            [ngModel]="selectedContactMethods"
                            [ngModelOptions]="{standalone: true}"
                            [multiple]="true"
                            [addTag]="false"
                            [selectOnTab]="false"
                            [closeOnSelect]="false"
                            [clearable]="false"
                            bindLabel="title"
                            placeholder="Please select ..."
                            id="addClientContactMethod"
                            class="uk-select"
                            #addContactMethod
                            (change)="addContactMethod.blur()"
                          >
                            <ng-template ng-option-tmp let-item="item">
                              <div class="uk-margin-small-right" (click)="setContactMethods(item)">
                                <input class="uk-checkbox uk-margin-small-right" type="checkbox"
                                       [checked]="contactMethodIsSelected(item)">
                                {{item.title}}
                              </div>
                            </ng-template>
                          </ng-select>
<!--                          <ng2-completer id="addClientContactMethod" name="addClientContactMethod"-->
<!--                                         formControlName="addClientContactMethod"-->
<!--                                         [datasource]="variables.contactMethods" [minSearchLength]="0"-->
<!--                                         [textNoResults]="null"></ng2-completer>-->
                          <div class="uk-text-danger uk-text-small"
                               *ngIf="addClientForm.get('addClientContactMethod').hasError('maxlength')"
                          >
                            {{ getCharactersLimitError('addClientContactMethod') }}
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="uk-width-1-1 uk-margin-medium-top">
                      <h3 class="uk-margin-remove-bottom">Contact information</h3>
                      <hr class="uk-margin-small-top">
                    </div>
                    <div class="uk-width-1-2@s">
                      <div class="uk-margin">
                        <label class="uk-form-label" for="addClientStreetAddress">Street address</label>
                        <div class="uk-form-controls">
                          <input id="addClientStreetAddress" name="addClientStreetAddress"
                                 formControlName="addClientStreetAddress" type="text" placeholder="Street address ..."
                                 class="uk-input"
                                 [ngClass]="{'uk-form-danger': addClientForm.get('addClientStreetAddress').touched && addClientForm.get('addClientStreetAddress').errors }">
                          <div class="uk-text-danger uk-text-small"
                               *ngIf="addClientForm.get('addClientStreetAddress').hasError('maxlength')"
                          >
                            {{ getCharactersLimitError('addClientStreetAddress') }}
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="uk-width-1-2@s">
                      <div class="uk-margin">
                        <label class="uk-form-label" for="addClientCity">City</label>
                        <div class="uk-form-controls">
                          <input id="addClientCity" name="addClientCity" formControlName="addClientCity" type="text"
                                 placeholder="City ..." class="uk-input"
                                 [ngClass]="{'uk-form-danger': addClientForm.get('addClientCity').touched && addClientForm.get('addClientCity').errors }">
                          <div class="uk-text-danger uk-text-small"
                               *ngIf="addClientForm.get('addClientCity').hasError('maxlength')"
                          >
                            {{ getCharactersLimitError('addClientCity') }}
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="uk-width-1-2@s">
                      <div class="uk-margin">
                        <label class="uk-form-label" for="addClientState">State</label>
                        <div class="uk-form-controls">
                          <input id="addClientState" name="addClientState" formControlName="addClientState" type="text"
                                 placeholder="State ..." class="uk-input"
                                 [ngClass]="{'uk-form-danger': addClientForm.get('addClientState').touched && addClientForm.get('addClientState').errors }">
                          <div class="uk-text-danger uk-text-small"
                               *ngIf="addClientForm.get('addClientState').hasError('maxlength')"
                          >
                            {{ getCharactersLimitError('addClientState') }}
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="uk-width-1-2@s">
                      <div class="uk-margin">
                        <label class="uk-form-label" for="addClientZip">Postal code</label>
                        <div class="uk-form-controls">
                          <input id="addClientZip" name="addClientZip" formControlName="addClientZip" type="text"
                                 placeholder="Postal code ..." class="uk-input"
                                 [ngClass]="{'uk-form-danger': addClientForm.get('addClientZip').touched && addClientForm.get('addClientZip').errors }">
                          <div class="uk-text-danger uk-text-small"
                               *ngIf="addClientForm.get('addClientZip').hasError('maxlength')"
                          >
                            {{ getCharactersLimitError('addClientZip') }}
                          </div>
                          <div class="uk-text-danger uk-text-small"
                               *ngIf="addClientForm.get('addClientBillingPostalCode').hasError('pattern')"
                          >
                            Field value is incorrect
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="uk-width-1-2@s">
                      <div class="uk-margin">
                        <label class="uk-form-label" for="addClientCountry">Country</label>
                        <div class="uk-form-controls">
                          <input id="addClientCountry" name="addClientCountry" formControlName="addClientCountry"
                                 type="text" placeholder="Country ..." class="uk-input"
                                 [ngClass]="{'uk-form-danger': addClientForm.get('addClientCountry').touched && addClientForm.get('addClientCountry').errors }">
                          <div class="uk-text-danger uk-text-small"
                               *ngIf="addClientForm.get('addClientCountry').hasError('maxlength')"
                          >
                            {{ getCharactersLimitError('addClientCountry') }}
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="uk-width-1-2@s">
                      <div class="uk-margin">
                        <label class="uk-form-label" for="addClientPhone">Phone number</label>
                        <div class="uk-form-controls">
                          <input id="addClientPhone" name="addClientPhone" formControlName="addClientPhone" type="text"
                                 placeholder="Phone number ..." class="uk-input"
                                 [ngClass]="{'uk-form-danger': addClientForm.get('addClientPhone').touched && addClientForm.get('addClientPhone').errors }">
                          <div class="uk-text-danger uk-text-small"
                               *ngIf="addClientForm.get('addClientPhone').hasError('maxlength')"
                          >
                            {{ getCharactersLimitError('addClientPhone') }}
                          </div>
                          <div class="uk-text-danger uk-text-small"
                               *ngIf="addClientForm.get('addClientPhone').hasError('pattern')"
                          >
                            Field value is incorrect
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="uk-width-1-2@s">
                      <div class="uk-margin">
                        <label class="uk-form-label" for="addClientEmail">Email</label>
                        <div class="uk-form-controls">
                          <input id="addClientEmail" name="addClientEmail" formControlName="addClientEmail" type="text"
                                 placeholder="Email ..." class="uk-input"
                                 [ngClass]="{'uk-form-danger': addClientForm.get('addClientEmail').touched && addClientForm.get('addClientEmail').errors }">
                          <div class="uk-text-danger uk-text-small"
                               *ngIf="addClientForm.get('addClientEmail').hasError('maxlength')"
                          >
                            {{ getCharactersLimitError('addClientEmail') }}
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="uk-width-1-1 uk-margin-medium-top" *ngIf="clientType == 'Organisation'">
                      <h5 class="uk-margin-remove-bottom">Visiting address</h5>
                      <hr class="uk-margin-small-top">
                    </div>
                    <div class="uk-width-1-2@s" *ngIf="clientType == 'Organisation'">
                      <div class="uk-margin">
                        <label class="uk-form-label" for="addClientVisitingStreetAddress">Street address</label>
                        <div class="uk-form-controls">
                          <input id="addClientVisitingStreetAddress" name="addClientVisitingStreetAddress"
                                 formControlName="addClientVisitingStreetAddress" type="text"
                                 placeholder="Street address ..." class="uk-input"
                                 [ngClass]="{'uk-form-danger': addClientForm.get('addClientVisitingStreetAddress').touched && addClientForm.get('addClientVisitingStreetAddress').errors }">
                          <div class="uk-text-danger uk-text-small"
                               *ngIf="addClientForm.get('addClientVisitingStreetAddress').hasError('maxlength')"
                          >
                            {{ getCharactersLimitError('addClientVisitingStreetAddress') }}
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="uk-width-1-2@s" *ngIf="clientType == 'Organisation'">
                      <div class="uk-margin">
                        <label class="uk-form-label" for="addClientVisitingCity">City</label>
                        <div class="uk-form-controls">
                          <input id="addClientVisitingCity" name="addClientVisitingCity"
                                 formControlName="addClientVisitingCity" type="text" placeholder="City ..."
                                 class="uk-input"
                                 [ngClass]="{'uk-form-danger': addClientForm.get('addClientVisitingCity').touched && addClientForm.get('addClientVisitingCity').errors }">
                          <div class="uk-text-danger uk-text-small"
                               *ngIf="addClientForm.get('addClientVisitingCity').hasError('maxlength')"
                          >
                            {{ getCharactersLimitError('addClientVisitingCity') }}
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="uk-width-1-2@s" *ngIf="clientType == 'Organisation'">
                      <div class="uk-margin">
                        <label class="uk-form-label" for="addClientVisitingState">State</label>
                        <div class="uk-form-controls">
                          <input id="addClientVisitingState" name="addClientVisitingState"
                                 formControlName="addClientVisitingState" type="text" placeholder="State ..."
                                 class="uk-input"
                                 [ngClass]="{'uk-form-danger': addClientForm.get('addClientVisitingState').touched && addClientForm.get('addClientVisitingState').errors }">
                          <div class="uk-text-danger uk-text-small"
                               *ngIf="addClientForm.get('addClientVisitingState').hasError('maxlength')"
                          >
                            {{ getCharactersLimitError('addClientVisitingState') }}
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="uk-width-1-2@s" *ngIf="clientType == 'Organisation'">
                      <div class="uk-margin">
                        <label class="uk-form-label" for="addClientVisitingCountry">Country</label>
                        <div class="uk-form-controls">
                          <input id="addClientVisitingCountry" name="addClientVisitingCountry"
                                 formControlName="addClientVisitingCountry" type="text" placeholder="Country ..."
                                 class="uk-input"
                                 [ngClass]="{'uk-form-danger': addClientForm.get('addClientVisitingCountry').touched && addClientForm.get('addClientVisitingCountry').errors }">
                          <div class="uk-text-danger uk-text-small"
                               *ngIf="addClientForm.get('addClientVisitingCountry').hasError('maxlength')"
                          >
                            {{ getCharactersLimitError('addClientVisitingCountry') }}
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="uk-width-1-1 uk-margin-medium-top">
                      <h5 class="uk-margin-remove-bottom">Contact person</h5>
                      <hr class="uk-margin-small-top">
                    </div>
                    <div class="uk-width-1-2@s">
                      <div class="uk-margin">
                        <label class="uk-form-label" for="addClientContactPersonName">Name</label>
                        <div class="uk-form-controls">
                          <input id="addClientContactPersonName" name="addClientContactPersonName"
                                 formControlName="addClientContactPersonName" type="text" placeholder="Name ..."
                                 class="uk-input"
                                 [ngClass]="{'uk-form-danger': addClientForm.get('addClientContactPersonName').touched && addClientForm.get('addClientContactPersonName').errors }">
                          <div class="uk-text-danger uk-text-small"
                               *ngIf="addClientForm.get('addClientContactPersonName').hasError('maxlength')"
                          >
                            {{ getCharactersLimitError('addClientContactPersonName') }}
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="uk-width-1-2@s" *ngIf="clientType == 'Organisation'">
                      <div class="uk-margin">
                        <label class="uk-form-label" for="addClientContactPersonLastName">Last name</label>
                        <div class="uk-form-controls">
                          <input id="addClientContactPersonLastName" name="addClientContactPersonLastName"
                                 formControlName="addClientContactPersonLastName" type="text"
                                 placeholder="Last name ..." class="uk-input"
                                 [ngClass]="{'uk-form-danger': addClientForm.get('addClientContactPersonLastName').touched && addClientForm.get('addClientContactPersonLastName').errors}">
                          <div class="uk-text-danger uk-text-small"
                               *ngIf="addClientForm.get('addClientContactPersonLastName').hasError('maxlength')"
                          >
                            {{ getCharactersLimitError('addClientContactPersonLastName') }}
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="uk-width-1-2@s">
                      <div class="uk-margin">
                        <label class="uk-form-label" for="addClientContactPersonRole">Role</label>
                        <div class="uk-form-controls">
                          <input id="addClientContactPersonRole" name="addClientContactPersonRole"
                                 formControlName="addClientContactPersonRole" type="text" placeholder="Role ..."
                                 class="uk-input"
                                 [ngClass]="{'uk-form-danger': addClientForm.get('addClientContactPersonRole').touched && addClientForm.get('addClientContactPersonRole').errors }">
                          <div class="uk-text-danger uk-text-small"
                               *ngIf="addClientForm.get('addClientContactPersonRole').hasError('maxlength')"
                          >
                            {{ getCharactersLimitError('addClientContactPersonRole') }}
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="uk-width-1-2@s">
                      <div class="uk-margin">
                        <label class="uk-form-label" for="addClientContactPersonEmail">Email</label>
                        <div class="uk-form-controls">
                          <input id="addClientContactPersonEmail" name="addClientContactPersonEmail"
                                 formControlName="addClientContactPersonEmail" type="text" placeholder="Email ..."
                                 class="uk-input"
                                 [ngClass]="{'uk-form-danger': addClientForm.get('addClientContactPersonEmail').touched && addClientForm.get('addClientContactPersonEmail').errors }">
                          <div class="uk-text-danger uk-text-small"
                               *ngIf="addClientForm.get('addClientContactPersonEmail').hasError('maxlength')"
                          >
                            {{ getCharactersLimitError('addClientContactPersonEmail') }}
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="uk-width-1-2@s">
                      <div class="uk-margin">
                        <label class="uk-form-label" for="addClientContactPersonPhone">Phone number</label>
                        <div class="uk-form-controls">
                          <input id="addClientContactPersonPhone" name="addClientContactPersonPhone"
                                 formControlName="addClientContactPersonPhone" type="text"
                                 placeholder="Phone number ..." class="uk-input"
                                 [ngClass]="{'uk-form-danger': addClientForm.get('addClientContactPersonPhone').touched && addClientForm.get('addClientContactPersonPhone').errors }">
                          <div class="uk-text-danger uk-text-small"
                               *ngIf="addClientForm.get('addClientContactPersonPhone').hasError('maxlength')"
                          >
                            {{ getCharactersLimitError('addClientContactPersonPhone') }}
                          </div>
                          <div class="uk-text-danger uk-text-small"
                               *ngIf="addClientForm.get('addClientContactPersonPhone').hasError('pattern')"
                          >
                            Field value is incorrect
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="uk-width-1-1 uk-margin-medium-top" *ngIf="clientType == 'Organisation'">
                      <h5 class="uk-margin-remove-bottom">Billing information</h5>
                      <hr class="uk-margin-small-top">
                    </div>
                    <div class="uk-width-1-2@s" *ngIf="clientType == 'Organisation'">
                      <div class="uk-margin">
                        <label class="uk-form-label" for="addClientBillingPostalCode">Postal code</label>
                        <div class="uk-form-controls">
                          <input id="addClientBillingPostalCode" name="addClientBillingPostalCode"
                                 formControlName="addClientBillingPostalCode" type="text" placeholder="Postal code ..."
                                 class="uk-input"
                                 [ngClass]="{'uk-form-danger': addClientForm.get('addClientBillingPostalCode').touched && addClientForm.get('addClientBillingPostalCode').errors }">
                          <div class="uk-text-danger uk-text-small"
                               *ngIf="addClientForm.get('addClientBillingPostalCode').hasError('maxlength')"
                          >
                            {{ getCharactersLimitError('addClientBillingPostalCode') }}
                          </div>
                          <div class="uk-text-danger uk-text-small"
                               *ngIf="addClientForm.get('addClientBillingPostalCode').hasError('pattern')"
                          >
                            Field value is incorrect
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="uk-width-1-2@s" *ngIf="clientType == 'Organisation'">
                      <div class="uk-margin">
                        <label class="uk-form-label" for="addClientBillingElectronicInvoiceAddress">
                          Electronic invoice address</label>
                        <div class="uk-form-controls">
                          <input id="addClientBillingElectronicInvoiceAddress"
                                 name="addClientBillingElectronicInvoiceAddress"
                                 formControlName="addClientBillingElectronicInvoiceAddress" type="text" placeholder="
Electronic invoice address ..." class="uk-input"
                                 [ngClass]="{'uk-form-danger': addClientForm.get('addClientBillingElectronicInvoiceAddress').touched && addClientForm.get('addClientBillingElectronicInvoiceAddress').errors }">
                          <div class="uk-text-danger uk-text-small"
                               *ngIf="addClientForm.get('addClientBillingElectronicInvoiceAddress').hasError('maxlength')"
                          >
                            {{ getCharactersLimitError('addClientBillingElectronicInvoiceAddress') }}
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="uk-width-1-2@s" *ngIf="clientType == 'Organisation'">
                      <div class="uk-margin">
                        <label class="uk-form-label" for="addClientBillingEmail">Email</label>
                        <div class="uk-form-controls">
                          <input id="addClientBillingEmail" name="addClientBillingEmail"
                                 formControlName="addClientBillingEmail" type="text" placeholder="Email ..."
                                 class="uk-input"
                                 [ngClass]="{'uk-form-danger': addClientForm.get('addClientBillingEmail').touched && addClientForm.get('addClientBillingEmail').errors }">
                          <div class="uk-text-danger uk-text-small"
                               *ngIf="addClientForm.get('addClientBillingEmail').hasError('maxlength')"
                          >
                            {{ getCharactersLimitError('addClientBillingEmail') }}
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="uk-width-1-1 uk-margin-medium-top">
                      <h3 class="uk-margin-remove-bottom">Additional information</h3>
                      <hr class="uk-margin-small-top">
                    </div>
                    <div class="uk-width-1-2">
                      <div class="uk-margin">
                        <div class="uk-flex uk-flex-between uk-text-meta">
                          <label class="uk-form-label" for="addClientNotes">Other information</label>
                          <div class="uk-text-small">
                            {{ getCharactersLeft('addClientNotes', 2048) }}
                          </div>
                        </div>
                        <div class="uk-form-controls">
                          <textarea id="addClientNotes" name="addClientNotes" formControlName="addClientNotes" rows="5"
                                    type="text" placeholder="Other information ..." class="uk-textarea"
                                    [ngClass]="{'uk-form-danger': addClientForm.get('addClientNotes').touched && addClientForm.get('addClientNotes').errors }"></textarea>
                          <div class="uk-text-danger uk-text-small"
                               *ngIf="addClientForm.get('addClientNotes').hasError('maxlength')"
                          >
                            {{ getCharactersLimitError('addClientNotes') }}
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="uk-width-1-2">
                      <div class="uk-margin">
                        <div class="uk-flex uk-flex-between uk-text-meta">
                          <label class="uk-form-label" for="addClientComments">Comments</label>
                          <div class="uk-text-small">
                            {{ getCharactersLeft('addClientComments', 2048) }}
                          </div>
                        </div>
                        <div class="uk-form-controls">
                          <textarea id="addClientComments" name="addClientComments" formControlName="addClientComments"
                                    rows="5" type="text" placeholder="Comments ..." class="uk-textarea"
                                    [ngClass]="{'uk-form-danger': addClientForm.get('addClientComments').touched && addClientForm.get('addClientComments').errors }"></textarea>
                          <div class="uk-text-danger uk-text-small"
                               *ngIf="addClientForm.get('addClientComments').hasError('maxlength')"
                          >
                            {{ getCharactersLimitError('addClientComments') }}
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="uk-width-1-2">
                      <div class="uk-margin">
                        <label class="uk-form-label" for="addClientTags">Tags</label>
                        <div class="uk-form-controls">
                          <input id="addClientTags" name="addClientTags" formControlName="addClientTags" type="text"
                                 placeholder="Tags ..." class="uk-input"
                                 [ngClass]="{'uk-form-danger': addClientForm.get('addClientTags').touched && addClientForm.get('addClientTags').errors }">
                          <div class="uk-text-danger uk-text-small"
                               *ngIf="addClientForm.get('addClientTags').hasError('maxlength')"
                          >
                            {{ getCharactersLimitError('addClientTags') }}
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="uk-width-1-2">
                      <div class="uk-margin">
                        <label class="uk-form-label" for="addClientStatus">Status</label>
                        <div class="uk-form-controls">
                          <label><input id="addClientStatus" name="addClientStatus" formControlName="addClientStatus"
                                        class="uk-checkbox" type="checkbox"> Active</label>
                        </div>
                      </div>
                    </div>
                    <div class="uk-width-1-2">
                      <div class="uk-margin">
                        <label class="uk-form-label" for="addClientVisibility">Visibility</label>
                        <div class="uk-form-controls">
                          <label><input id="addClientVisibility" name="addClientVisibility"
                                        formControlName="addClientVisibility" class="uk-checkbox" type="checkbox">
                            Public</label>
                        </div>
                      </div>
                    </div>
                    <!--<div class="uk-width-1-1">
                      <div class="uk-margin">
                        <label class="uk-form-label" for="addClientAttachments">Attachments</label>
                        <div class="uk-form-controls">
                          <div uk-form-custom="target: true">
                            <input type="file">
                            <input id="addClientAttachments" name="addClientAttachments" formControlName="addClientAttachments" type="text" placeholder="Attachments ..." class="uk-input" [ngClass]="{'uk-form-danger': addClientForm.get('addClientAttachments').touched && addClientForm.get('addClientAttachments').hasError('required') }">
                          </div>
                          <button class="uk-button uk-button-default">Add</button>
                        </div>
                      </div>
                    </div>-->
                  </div>
                  <div class="uk-modal-footer">
                    <p class="uk-text-right">
                      <!--                      <button class="uk-button uk-button-danger" (click)="getFormValidationErrors()">Check</button>-->
                      <button class="uk-button uk-button-default uk-modal-close uk-margin-small-right" type="button"
                              id="addNewClientCancel" (click)="resetForm()">Cancel
                      </button>
                      <button type="submit" [disabled]="!addClientForm.valid" class="uk-button uk-button-primary">Save
                      </button>
                    </p>
                  </div>
                </form>
              </div>
            </div>
            <button class="uk-button uk-button-text uk-margin-right" type="button">Get CSV <span uk-icon="icon: chevron-down"></span></button>
            <div uk-dropdown="mode: click;">
              <ul class="uk-nav uk-dropdown-nav">
                <li><a (click)="getCSVforAllClients()">Get CSV</a></li>
                <li><a (click)="openGetFilteredCSV()">Get filtered CSV</a></li>
              </ul>
            </div>
            <button class="uk-button uk-button-text uk-margin-right" type="button">Sort <span uk-icon="icon: chevron-down"></span></button>
            <div uk-dropdown="mode: click;">
              <ul class="uk-nav uk-dropdown-nav">
<!--                <li [ngClass]="{'uk-active': activeSort('created') }" (click)="sortItems('created')"><a>Created</a></li>-->
                <li [ngClass]="{'uk-active': activeSort('name') }" (click)="sortItems('name')"><a>Name</a></li>
                <li [ngClass]="{'uk-active': activeSort('_type') }" (click)="sortItems('_type')"><a>Client type</a></li>
                <li [ngClass]="{'uk-active': activeSort('clientGroup') }" (click)="sortItems('clientGroup')"><a>Client
                  group</a></li>
                <li [ngClass]="{'uk-active': activeSort('status') }" (click)="sortItems('status')"><a>Status</a></li>
              </ul>
            </div>
            <button class="uk-button uk-button-text" type="button">Limit {{ limitQuery ? ': ' + limitQuery : null }} <span uk-icon="icon: chevron-down"></span></button>
            <div uk-dropdown="mode: click;">
              <ul class="uk-nav uk-dropdown-nav">
                <li [ngClass]="{'uk-active': activeLimit(10) }" (click)="setLimit(10)"><a>10</a></li>
                <li [ngClass]="{'uk-active': activeLimit(25) }" (click)="setLimit(25)"><a>25</a></li>
                <li [ngClass]="{'uk-active': activeLimit(50) }" (click)="setLimit(50)"><a>50</a></li>
                <li [ngClass]="{'uk-active': activeLimit(100) }" (click)="setLimit(100)"><a>100</a></li>
                <li [ngClass]="{'uk-active': activeLimit(200) }" (click)="setLimit(200)"><a>200</a></li>
                <li [ngClass]="{'uk-active': activeLimit(500) }" (click)="setLimit(500)"><a>500</a></li>
              </ul>
            </div>
          </div>
          <div class="uk-height-1-1">
            <table class="uk-table uk-table-middle uk-table-divider">
              <thead>
              <tr>
                <!--<th class="uk-table-shrink"></th>-->
                <!--<th class="uk-table-shrink">Image</th>-->
                <th class="uk-text-nowrap" [ngClass]="{'active': activeSort('name') }" (click)="sortItems('name')">
                  Name<span uk-icon="chevron-down" [ngClass]="{'inverse': !sortAscending }"></span></th>
                <th class="uk-text-nowrap uk-visible@m" [ngClass]="{'active': activeSort('_type') }"
                    (click)="sortItems('_type')">Client type<span uk-icon="chevron-down"
                                                                  [ngClass]="{'inverse': !sortAscending }"></span></th>
                <th class="uk-text-nowrap uk-visible@m" [ngClass]="{'active': activeSort('clientGroup') }"
                    (click)="sortItems('clientGroup')">Client group<span uk-icon="chevron-down"
                                                                         [ngClass]="{'inverse': !sortAscending }"></span>
                </th>
                <th class="uk-text-nowrap uk-visible@m" [ngClass]="{'active': activeSort('status') }"
                    (click)="sortItems('status')">Status<span uk-icon="chevron-down"
                                                              [ngClass]="{'inverse': !sortAscending }"></span></th>
                <th class="uk-table-shrink"></th>
              </tr>
              </thead>
              <tbody>
              <tr *ngFor="let client of clients">
                <!--<td><input class="uk-checkbox" type="checkbox"></td>-->
                <!--<td class="uk-background-muted"><img class="uk-preserve-width uk-border-circle" src="/api/image/artbuddy-logo-placeholder.svg" width="60" uk-img></td>-->
                <td (click)="openClient(client._id)" class="pointer">{{ client.name }} {{ client.lastName }}</td>
                <td class="uk-visible@m">{{ client._type === "PrivatePerson" ? "Private person" : "Organisation" }}</td>
                <td class="uk-visible@m">
                  <div *ngFor="let group of client.clientGroup">
                    {{group.title}}
                  </div>
                </td>
                <td class="uk-visible@m">
                  <span *ngIf="client.status">Active</span>
                  <span *ngIf="!client.status">Inactive</span>
                </td>
                <td class="uk-text-right">
                  <div class="uk-button-group">
                    <button (click)="openClient(client._id)" class="uk-button uk-button-text uk-margin-right" type="button">Open</button>
                    <div class="uk-inline" class="uk-visible@m">
                      <button class="uk-button uk-button-link" type="button"><span uk-icon="icon: chevron-down"></span></button>
                      <div uk-dropdown="mode: click; boundary: ! .uk-button-group; boundary-align: true;">
                        <ul class="uk-nav uk-dropdown-nav">
                          <li><a (click)="removeClient(client._id)">Remove</a></li>
                        </ul>
                      </div>
                    </div>
                  </div>
                </td>
              </tr>
              </tbody>
            </table>
          </div>
          <ul class="uk-pagination uk-flex-center uk-flex-middle uk-margin-medium-top" *ngIf="pages.length > 1">
            <li class="uk-margin-small-right" [ngClass]="{'uk-disabled': (currentPage === 1) }">
              <a (click)="setCurrentPage(currentPage-1)">
                <span class="uk-margin-small-right" uk-pagination-previous></span> Previous
              </a>
            </li>
            <li>
              <select class="uk-select" [ngModel]="currentPage" (change)="setCurrentPageString($event.target.value)">
                <option *ngFor="let page of pages; let index = index; first as isFirst; last as isLast"
                        [value]="index+1">{{ index + 1 }}</option>
              </select>
            </li>
            <li class="uk-margin-small-left" [ngClass]="{'uk-disabled': (currentPage === pageCount) }">
              <a (click)="setCurrentPage(currentPage+1)">
                Next <span class="uk-margin-small-left" uk-pagination-next></span>
              </a>
            </li>
          </ul>
        </div>
      </div>
    </div>
  </div>
  <div id="getFilteredCSVModal" class="uk-modal-container" uk-modal="bg-close: false; esc-close: false;">
    <div class="uk-modal-dialog">
      <div class="uk-modal-header">
        <h2 class="uk-modal-title">Get filtered CSV</h2>
      </div>
      <div class="uk-modal-body">
        <div class="uk-width-1-1">
          <div class="uk-width-1-1">
            <h3 class="uk-margin-remove-bottom">Client groups</h3>
          </div>
          <div *ngIf="allClientGroups.length === 0" class="uk-container uk-padding-small">
            No client groups found
          </div>
          <table class="uk-table uk-table-small" [hidden]="!allClientGroups.length">
            <thead>
            <tr>
              <th class="uk-padding-remove-left" colspan="2">
                <button
                  (click)="selectAllClientGroups()"
                  class="uk-button uk-button-default"
                  type="button"
                >
                  Select all
                </button>
                <button
                  (click)="removeAllSelectedClientGroups()"
                  class="uk-button uk-button-default uk-margin-left"
                  type="button"
                >
                  Select none
                </button>
              </th>
            </tr>
            <hr class="uk-margin-small-top">
            </thead>
            <tbody>
            <tr *ngFor="let clientGroup of allClientGroups">
              <td class="uk-width-expand">
                <input class="uk-checkbox"
                       type="checkbox"
                       (click)="toggleSelectedClientGroup(clientGroup)"
                       [checked]="isClientGroupSelected(clientGroup)">
                <span class="uk-margin-left"
                      (click)="toggleSelectedClientGroup(clientGroup)">
                    {{ clientGroup.title }}
                  </span>
              </td>
            </tr>
            </tbody>
          </table>

          <div class="uk-width-1-1 uk-margin-medium-top">
            <h3 class="uk-margin-remove-bottom">Contact methods</h3>
          </div>
          <div *ngIf="allContactMethods.length === 0" class="uk-container uk-padding-small">
            No contact methods found
          </div>
          <table class="uk-table uk-table-small" [hidden]="!allContactMethods.length">
            <thead>
            <tr>
              <th class="uk-padding-remove-left" colspan="2">
                <button
                  (click)="selectAllContactMethods()"
                  class="uk-button uk-button-default"
                  type="button"
                >
                  Select all
                </button>
                <button
                  (click)="removeAllSelectedContactMethods()"
                  class="uk-button uk-button-default uk-margin-left"
                  type="button"
                >
                  Select none
                </button>
              </th>
            </tr>
            <hr class="uk-margin-small-top">
            </thead>
            <tbody>
            <tr *ngFor="let language of allContactMethods">
              <td class="uk-width-expand">
                <input class="uk-checkbox"
                       type="checkbox"
                       (click)="toggleSelectedContactMethod(language)"
                       [checked]="isContactMethodSelected(language)">
                <span class="uk-margin-left"
                      (click)="toggleSelectedContactMethod(language)">
                    {{ language.title }}
                  </span>
              </td>
            </tr>
            </tbody>
          </table>

          <div class="uk-width-1-1 uk-margin-medium-top">
            <h3 class="uk-margin-remove-bottom">Languages</h3>
          </div>
          <div *ngIf="allLanguages.length === 0" class="uk-container uk-padding-small">
            No languages found
          </div>
          <table class="uk-table uk-table-small" [hidden]="!allLanguages.length">
            <thead>
            <tr>
              <th class="uk-padding-remove-left" colspan="2">
                <button
                  (click)="selectAllLanguages()"
                  class="uk-button uk-button-default"
                  type="button"
                >
                  Select all
                </button>
                <button
                  (click)="removeAllSelectedLanguages()"
                  class="uk-button uk-button-default uk-margin-left"
                  type="button"
                >
                  Select none
                </button>
              </th>
            </tr>
            <hr class="uk-margin-small-top">
            </thead>
            <tbody>
            <tr *ngFor="let language of allLanguages">
              <td class="uk-width-expand">
                <input class="uk-checkbox"
                       type="checkbox"
                       (click)="toggleSelectedLanguage(language)"
                       [checked]="isLanguageSelected(language)">
                <span class="uk-margin-left"
                      (click)="toggleSelectedLanguage(language)">
                    {{ language.title }}
                  </span>
              </td>
            </tr>
            </tbody>
          </table>
        </div>
      </div>
      <div class="uk-modal-footer">
        <p class="uk-text-right">
          <button class="uk-button uk-button-default uk-modal-close uk-margin-small-right"
                  type="button" id="getFilteredCSVModalCancel">Cancel
          </button>
          <button type="submit" class="uk-button uk-button-primary" (click)="getFilteredCSV()"
                  [disabled]="(createCSVSelectedLanguages.length === 0) && (createCSVSelectedClientGroups.length === 0)">
            Get CSV
          </button>
        </p>
      </div>
    </div>
  </div>
</div>
