import { Injectable } from '@angular/core';
import { HttpEvent, HttpInterceptor, HttpHandler, HttpRequest, HttpResponse, HttpErrorResponse } from '@angular/common/http';
import { ActiveService } from './active.service';
import { Observable } from 'rxjs';
import 'rxjs/add/operator/do';
import 'rxjs/add/operator/debounceTime';

declare var UIkit: any;

@Injectable()
export class CustomHttpInterceptor implements HttpInterceptor {
  constructor(
    private activeService: ActiveService
  ) {}

  intercept (req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    this.activeService.increasePendingRequests();
    return next.handle(req).debounceTime(60000)
      .do(
        (event: any) => {
          if (event instanceof HttpResponse) {
            this.activeService.decreasePendingRequests();
          } else if (event instanceof HttpErrorResponse) { // TODO: When does this fire?
            UIkit.notification({
              message: 'Error fetching data',
              status: 'danger',
              pos: 'top-right',
              timeout: 1500
            });
            this.activeService.decreasePendingRequests();
          }
        },
        (err: any) => {
          if (err.status !== 403) {
            UIkit.notification({
              message: 'Error fetching data',
              status: 'danger',
              pos: 'top-right',
              timeout: 1500
            });
          }
          this.activeService.decreasePendingRequests();
        }
      );
  }
}
