import {Component, EventEmitter, OnInit, ViewChild} from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { FormGroup, FormControl, Validators, FormBuilder } from '@angular/forms';
import { AuthService } from '../../services/auth.service';
import { UserService } from '../../services/user.service';
import { ActiveService } from '../../services/active.service';
import { AccountService } from '../../services/account.service';
import { FileService } from '../../services/file.service';
import { SearchService } from '../../services/search.service';
import { ItemService } from '../../services/item.service';
import { ExhibitionService } from '../../services/exhibition.service';
import { UploadOutput, UploadInput, UploadFile, humanizeBytes, UploaderOptions } from 'ngx-uploader';
import {AngularMyDatePickerDirective, IAngularMyDpOptions, IMyDateModel} from 'angular-mydatepicker';
import * as moment from 'moment/moment';
import { Observable } from 'rxjs';
import { delay } from 'rxjs/operators';

declare var UIkit: any;

@Component({
  selector: 'app-single-exhibition',
  templateUrl: './single-exhibition.component.html',
  styleUrls: ['./single-exhibition.component.scss']
})
export class SingleExhibitionComponent implements OnInit {
  public user: any = {};
  public activeExhibition: any = {};
  public activeExhibitionId: string = '';
  public actorType: string;
  public activeAccount: any = {};
  private activeUser: string;
  public accounts: Array<Object> = [];
  public artists: Array<Object> = [];
  public variables: Array<string> = [];
  public isLoading = true;
  public uploadLoading: Boolean = false;
  public addNewArtworkModalWindow: any;
  public advancedArtworkSearchModalWindow: any;
  public showAdvancedArtworkSearchModalWindow: boolean = false;
  public addInstallShotsModalWindow: any;
  public editExhibitionForm: FormGroup;
  public searchArtworkForm: FormGroup;
  public searchArtworkResults: Array<Object> = [];
  public selectedArtworks: Array<Object> = [];
  public searchResultsFound: boolean = false;
  public myDatePickerOptions: IAngularMyDpOptions = {
    dateFormat: 'dd.mm.yyyy'
  };
  @ViewChild('AngularMyDatePickerEditExhibitionStartDate') AngularMyDatePickerEditExhibitionStartDate: AngularMyDatePickerDirective;
  @ViewChild('AngularMyDatePickerEditExhibitionEndDate') AngularMyDatePickerEditExhibitionEndDate: AngularMyDatePickerDirective;
  public options: UploaderOptions;
  public formData: FormData;
  public filesToUpload: UploadFile[];
  public uploadInput: EventEmitter<UploadInput>;
  public humanizeBytes: Function;
  public dragOver: boolean;
  public imageLoadOffset$: any;
  public printAsList = true;

  constructor(
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private formBuilder: FormBuilder,
    private auth: AuthService,
    private userService: UserService,
    private activeService: ActiveService,
    private accountService: AccountService,
    private exhibitionService: ExhibitionService,
    private fileService: FileService,
    private searchService: SearchService,
    private itemService: ItemService
  ) {
    this.activatedRoute.data.subscribe(data => {
      this.activeExhibition = data.resolvedData;
      if (this.activeExhibition.items) {
        this.activeExhibition.items.map(item => {
          item.created = moment.utc(item.created).isValid() ? moment.utc(item.created).locale('fi').format('YYYY') : null;
          /*switch (item.availability)
          {
            case 0:
              item.availability = 'Sold';
              break;
            case 1:
              item.availability = 'Booked';
              break;

            case 2: default:
            item.availability = 'Available';
            break;
          }*/
          const artistFound = this.artists.find((artist: any) => {
            return artist._id === item.artist;
          });
          if (artistFound === undefined) {
            this.artists.push({
              _id: item.artist,
              name: item.artistName,
              priceMultiplier: item.artistPriceMultiplier,
              status: item.artistStatus
            });
          }
        });
      } else {
        this.activeExhibition.items = [];
      }

      this.activeExhibition.startDate = this.createDate(this.activeExhibition.startDate);
      this.activeExhibition.endDate = this.createDate(this.activeExhibition.endDate);
      if (!this.activeExhibition['installShots']) {
        this.activeExhibition['installShots'] = [];
      }

      this.activeExhibitionId = data.resolvedData._id;
    });

    this.editExhibitionForm = this.formBuilder.group({
      editExhibitionId: new FormControl('', [
        Validators.required,
        Validators.maxLength(100)
      ]),
      editExhibitionOwner: new FormControl('', [
        Validators.required,
        Validators.maxLength(100)
      ]),
      editExhibitionName: new FormControl('', [
        Validators.required,
        Validators.maxLength(100)
      ]),
      editExhibitionDescription: new FormControl('', [
        Validators.maxLength(2048)
      ]),
      editExhibitionStartDate: new FormControl('', [
        Validators.maxLength(10)
      ]),
      editExhibitionEndDate: new FormControl('', [
        Validators.maxLength(10)
      ]),
      editExhibitionType: new FormControl('', [
        Validators.maxLength(30)
      ]),
      editExhibitionLocation: new FormControl('', [
        Validators.maxLength(30)
      ]),
      editExhibitionNotes: new FormControl('', [
        Validators.maxLength(2048)
      ]),
      editExhibitionComments: new FormControl('', [
        Validators.maxLength(2048)
      ]),
      editExhibitionTags: new FormControl('', [
        Validators.maxLength(2048)
      ]),
      editExhibitionVisibility: new FormControl('', [
        Validators.maxLength(10)
      ])
      /*editExhibitionAttachments: new FormControl('', [
        Validators.maxLength(100)
      ])*/
    });

    this.searchArtworkForm = this.formBuilder.group({
      searchArtworkTerm: new FormControl('', [
        Validators.required,
        Validators.minLength(3),
        Validators.maxLength(50)
      ])
    });

    this.filesToUpload = [];
    this.uploadInput = new EventEmitter<UploadInput>();
    this.humanizeBytes = humanizeBytes;
  }

  ngOnInit() {
    if ([null, undefined].includes(this.activeExhibitionId)) {
      this.activeExhibitionId = this.exhibitionService.getCurrentExhibition();
    }

    this.getUser();
  }

  getAccountData() {
    const getObject = {
      accountId: this.activeAccount,
      requester: 'exhibition',
      sortingKey: '_id',
      sortingOrder: 'desc',
      skipQuery: 0,
      limitQuery: null,
      contentTypes: ['variable']
    };

    this.accountService.getAccountData(getObject).subscribe(
      data => {
        this.variables = data.variables ? data.variables : [];
      },
      () => {
        UIkit.notification({
          message: 'Error fetching artwork',
          status: 'danger',
          pos: 'top-right',
          timeout: 1500
        });
      },
      () => this.isLoading = false
    );
  }

  getUser() {
    this.activeService.getActiveData().subscribe(
      data => {
        this. activeAccount = data.account.id;
        this.activeUser = data.user.id;
        this.userService.getUser(data.user).subscribe(
          data => {
            this.user = data;
            /*if (this.user.account[0]) {
              this.user.role = this.user.account[0].role;
            }*/
            this.getUsersAccounts();
            this.getAccountData();
          },
          () => {
            UIkit.notification({
              message: 'Error fetching user information',
              status: 'danger',
              pos: 'top-right',
              timeout: 1500
            });
          }
        );
      }
    );
  }

  // TODO: Tämäkin pitäis siirtää resolvattaksi ns. MasterServicen avulla, jolloin myös aktiivisen accountin tiedot tulisivat sieltä
  getUsersAccounts() {
    this.accountService.getUserAccounts(this.user).subscribe(
      data => {
        this.accounts = data;
      },
      () => {
        UIkit.notification({
          message: 'Error fetching accounts',
          status: 'danger',
          pos: 'top-right',
          timeout: 1500
        });
      },
      () => this.isLoading = false
    );
  }

  editExhibition(silent?: Boolean) {
    const editExhibitionObject = {
      id: this.editExhibitionForm.value.editExhibitionId,
      owner: this.editExhibitionForm.value.editExhibitionOwner,
      name: this.editExhibitionForm.value.editExhibitionName,
      description: this.editExhibitionForm.value.editExhibitionDescription,
      items: this.activeExhibition.items,
      installShots: this.activeExhibition['installShots'],
      startDate: this.editExhibitionForm.value.editExhibitionStartDate ? moment(this.editExhibitionForm.value.editExhibitionStartDate.singleDate.jsDate).format() : moment(new Date),
      endDate: this.editExhibitionForm.value.editExhibitionEndDate ? moment(this.editExhibitionForm.value.editExhibitionEndDate.singleDate.jsDate).format() : moment(new Date),
      type: this.editExhibitionForm.value.editExhibitionType,
      location: this.editExhibitionForm.value.editExhibitionLocation,
      notes: this.editExhibitionForm.value.editExhibitionNotes,
      comments: this.editExhibitionForm.value.editExhibitionComments,
      tags: this.editExhibitionForm.value.editExhibitionTags,
      visibility: this.editExhibitionForm.value.editExhibitionVisibility,
      attachments: this.activeExhibition.attachments
    };
    this.exhibitionService.editExhibition(editExhibitionObject).subscribe(
      res => {
        if (silent !== true) {
          UIkit.notification({
            message: 'Saved successfully',
            status: 'success',
            pos: 'top-right',
            timeout: 1500
          });
        }
      },
      error => {
        if (silent !== true) {
          UIkit.notification({
            message: 'Saving failed',
            status: 'danger',
            pos: 'top-right',
            timeout: 1500
          });
        }
      }
    );
  }

  searchArtwork() {
    /*this.isLoading = true;*/
    const searchObject = {
      searchArtworkAccountId: this.activeAccount,
      searchArtworkUserId: this.activeUser,
      searchArtworkTerm: this.searchArtworkForm.value.searchArtworkTerm
    };
    this.searchService.searchArtwork(searchObject).subscribe(
      res => {
        if (res.length) {
          if (this.searchArtworkResults) {
            this.searchArtworkResults.length = 0;
          }
          res.forEach(artwork => {
            artwork.allResultType = 'artwork';
            this.searchArtworkResults.push(artwork);
          });
          /*this.isLoading = false;*/
          this.searchResultsFound = true;
          document.getElementById('search-artwork-results-container').classList.add('active');



          UIkit.notification({
            message: 'Search matched ' + this.searchArtworkResults.length + ' results',
            status: 'success',
            pos: 'top-right',
            timeout: 1500
          });
        } else {
          if (this.searchArtworkResults) {
            this.searchArtworkResults.length = 0;
          }
          UIkit.notification({
            message: 'No search results',
            status: 'danger',
            pos: 'top-right',
            timeout: 1500
          });
        }
      },
      error => {
        if (this.searchArtworkResults) {
          this.searchArtworkResults.length = 0;
        }
        UIkit.notification({
          message: 'No search results',
          status: 'danger',
          pos: 'top-right',
          timeout: 1500
        });
      }
    );
  }

  emptySearch(): void {
    /* This results container is now replaced with advanced search modal */
    /*document.getElementById('search-artwork-results-container').classList.remove('active');
    let element: HTMLElement = document.getElementsByClassName('uk-navbar-toggle uk-close')[1] as HTMLElement;
    element.click();*/


    setTimeout(() => {
      this.searchResultsFound = false;
      if (this.searchArtworkResults) {
        this.searchArtworkResults.length = 0;
      }
      if (this.selectedArtworks) {
        this.selectedArtworks.length = 0;
      }
      this.searchArtworkForm.patchValue({ searchArtworkTerm: ''});
    }, 300);
  }

  selectExhibitionArtwork(artwork): void {
    this.selectedArtworks.indexOf(artwork) === -1 ?
      this.selectedArtworks.push(artwork) : this.selectedArtworks.splice(this.selectedArtworks.indexOf(artwork), 1);
  }

  addArtworksToExhibition(): void {
    this.selectedArtworks.map((item: any) => {
      const itemFound = this.activeExhibition.items.find((activeExhibitionItem: any) => {
        return activeExhibitionItem._id === item._id;
      });
      if (itemFound === undefined) {
        this.activeExhibition.items.push(item);
      }
      const artistFound = this.artists.find((artist: any) => {
        return artist._id === item.artist;
      });
      if (artistFound === undefined) {
        this.artists.push({
          _id: item.artist,
          name: item.artistName,
          priceMultiplier: item.artistPriceMultiplier,
          status: item.artistStatus
        });
      }
    });

    this.editExhibition(true);
    this.emptySearch();
  }

  removeArtworkFromExhibition(item): void {
    this.activeExhibition.items.splice(this.activeExhibition.items.indexOf(item), 1);
    const artistToSplice = this.artists.find((artist: any) => {
      return artist._id === item.artist;
    });
    this.artists.splice(this.artists.indexOf(artistToSplice), 1);
  }

  openSlideshow($event): void {
    $event.preventDefault();
    UIkit.lightboxPanel({
      /* Ei tarvitse, jos muistaa käyttää $event.preventDefaultia
        autoplay: true,
        autoplayInterval: 5000,
        delayControls: 1000,*/
      items: this.activeExhibition.items.map(item => {
        let itemImage;
        if (item.coverImage) {
          itemImage = '/api/image/' + item.coverImage;
        } else if (item.images[0]) {
          itemImage = '/api/image/' + item.images[0].fileId;
        } else {
          itemImage = '/api/image/artbuddy-logo-placeholder.svg';
        }

        return {
          source: itemImage,
          caption: item.name
        };
      })
    }).show();
  }

  openInstallShotsSlideshow($event): void {
    $event.preventDefault();
    UIkit.lightboxPanel({
      items: this.activeExhibition['installShots'].map(item => {
        return {
          source: '/api/image/' + item.fileId,
          caption: item.name
        };
      })
    }).show();
  }

  openArtist(artistId): void {
    this.router.navigateByUrl('/artist/' + artistId);
  }

  onUploadOutput(output: UploadOutput): void {
    const properFile = output.file && output.file.name && output.file.name.length > 0;

    if (output.type === 'allAddedToQueue') {
      const event: UploadInput = {
        type: 'uploadAll',
        url: '/api/file',
        method: 'POST',
        includeWebKitFormBoundary: true
      };
      this.uploadInput.emit(event);
    } else if (output.type === 'addedToQueue' && properFile) {
      if (this.filesToUpload.indexOf(output.file) == -1) {
        this.filesToUpload.push(output.file);
      }
    } else if (output.type === 'uploading' && properFile) {
      if (!this.activeExhibition['installShots'].some(image => image.placeholderId === output.file.id)) {
        this.uploadLoading = true;
        this.activeService.increasePendingRequests();
        this.activeExhibition['installShots'].push({placeholderId: output.file.id});
        const index = this.filesToUpload.findIndex(file => file.id === output.file.id);
        this.filesToUpload[index] = output.file;
      }
    } else if (output.type === 'removed') {
      this.filesToUpload = this.filesToUpload.filter((file: UploadFile) => file !== output.file);
    } else if (output.type === 'dragOver') {
      this.dragOver = true;
    } else if (output.type === 'dragOut') {
      this.dragOver = false;
    } else if (output.type === 'drop') {
      this.dragOver = false;
    } else if (output.type === 'removedAll') {
      this.filesToUpload.length = 0;
    } else if (output.type === 'done') {
      const fileObject = {
        itemId: this.activeExhibitionId,
        fileId: output.file.response
      };

      this.itemService.addExhibitionImageId(fileObject).subscribe(() => {
        this.removeFile(output.file.id);
        const indexToReplace = this.activeExhibition['installShots'].findIndex(file => file.placeholderId === output.file.id);
        this.activeExhibition['installShots'][indexToReplace] = fileObject;
        this.activeService.decreasePendingRequests();
        this.uploadLoading = false;

        this.activeExhibition['installShots'] = this.activeExhibition['installShots'].filter(image => {
          return !('placeholderId' in image);
        });
      });
    }
  }

  onAttachmentsUploadOutput(output: UploadOutput): void {
    const properFile = output.file && output.file.name && output.file.name.length > 0;

    if (output.type === 'allAddedToQueue') {
      const event: UploadInput = {
        type: 'uploadAll',
        url: '/api/file',
        method: 'POST',
        includeWebKitFormBoundary: true
      };
      this.uploadInput.emit(event);
    } else if (output.type === 'addedToQueue' && properFile) {
      if (this.filesToUpload.indexOf(output.file) == -1) {
        this.filesToUpload.push(output.file);
      }
    } else if (output.type === 'uploading' && properFile) {
      if (!this.activeExhibition['attachments'].some(image => image.placeholderId === output.file.id)) {
        this.uploadLoading = true;
        this.activeService.increasePendingRequests();
        this.activeExhibition['attachments'].push({ placeholderId: output.file.id });
        const index = this.filesToUpload.findIndex(file => file.id === output.file.id);
        this.filesToUpload[index] = output.file;
      }
    } else if (output.type === 'removed') {
      // remove file from array when removed
      this.filesToUpload = this.filesToUpload.filter((file: UploadFile) => file !== output.file);
    } else if (output.type === 'dragOver') {
      this.dragOver = true;
    } else if (output.type === 'dragOut') {
      this.dragOver = false;
    } else if (output.type === 'drop') {
      this.dragOver = false;
    } else if (output.type === 'removedAll') {
      this.filesToUpload.length = 0;
    } else if (output.type === 'done') {
      const fileObject = {
        itemId: this.activeExhibitionId,
        fileId: output.file.response,
        name: output.file.name,
        contentType: output.file.type
      };
      this.removeFile(output.file.id);
      const indexToReplace = this.activeExhibition['attachments'].findIndex(file => file.placeholderId === output.file.id);
      this.activeExhibition['attachments'][indexToReplace] = fileObject;
      this.activeService.decreasePendingRequests();
      this.uploadLoading = false;

      /*this.activeExhibition.images.push({
        /!*contentType: output.file.response.mimetype,
        filename: output.file.response.filename,*!/
        fileId: output.file.response.id
      });*/
      /*});*/
    }
  }

  startUpload(): void {
    const event: UploadInput = {
      type: 'uploadAll',
      url: '/api/file',
      method: 'POST',
      includeWebKitFormBoundary: true
    };
    this.uploadInput.emit(event);
  }

  cancelUpload(id: string): void {
    this.uploadInput.emit({ type: 'cancel', id: id });
  }

  removeFile(id: string): void {
    this.uploadInput.emit({ type: 'remove', id: id });
  }

  openAddInstallShot() {
    this.addInstallShotsModalWindow = window.document.getElementById('addInstallShots');
    UIkit.modal(this.addInstallShotsModalWindow).show();
  }

  openAttachment(attachment) {
    this.itemService.openAttachment(this.activeAccount, attachment).then((response: any) => {
      if (response.url) {
        window.open(response.url);
      } else {
        UIkit.notification({
          message: 'Error fetching data',
          status: 'danger',
          pos: 'top-right',
          timeout: 1500
        });
      }
    });
  }

  removeAttachment(attachment) {
    const attachmentObject = {
      documentType: 'exhibition',
      itemId: this.activeExhibitionId,
      fileId: attachment.fileId
    };

    this.fileService.removeFile(attachmentObject).subscribe(
      res => {
        const attachmentToSplice = this.activeExhibition.attachments.find((accountAttachment: any) => {
          return attachment.fileId === accountAttachment.fileId;
        });
        this.activeExhibition.attachments.splice(this.activeExhibition.attachments.indexOf(attachmentToSplice), 1);
        UIkit.notification({
          message: 'File removed successfully',
          status: 'success',
          pos: 'top-right',
          timeout: 1500
        });
      },
      error => {
        UIkit.notification({
          message: 'Removing failed',
          status: 'danger',
          pos: 'top-right',
          timeout: 1500
        });
      }
    );
  }

  openImage(attachment) {
    window.open('/api/originalimage/' + this.activeAccount + '/' + attachment.fileId, '_blank');
  }

  removeImage(image) {
    const imageObject = {
      documentType: 'item',
      itemId: this.activeExhibitionId,
      fileId: image.fileId
    };

    this.fileService.removeFile(imageObject).subscribe(
      res => {
        const imageToSplice = this.activeExhibition['installShots'].find((accountImage: any) => {
          return image._id === accountImage._id;
        });
        this.activeExhibition['installShots'].splice(this.activeExhibition['installShots'].indexOf(imageToSplice), 1);
        UIkit.notification({
          message: 'Image removed successfully',
          status: 'success',
          pos: 'top-right',
          timeout: 1500
        });
      },
      error => {
        UIkit.notification({
          message: 'Removing failed',
          status: 'danger',
          pos: 'top-right',
          timeout: 1500
        });
      }
    );
  }

  getCSV(): void {
    const getCSVObject = {
      'Name': this.editExhibitionForm.value.editExhibitionName,
      'Description': this.editExhibitionForm.value.editExhibitionDescription,
      'Artworks': this.activeExhibition.items,
      'Start date': this.editExhibitionForm.value.editExhibitionStartDate ? moment(this.editExhibitionForm.value.editExhibitionStartDate.singleDate.jsDate).format() : moment(new Date),
      'End date': this.editExhibitionForm.value.editExhibitionEndDate ? moment(this.editExhibitionForm.value.editExhibitionEndDate.singleDate.jsDate).format() : moment(new Date),
      'Type': this.editExhibitionForm.value.editExhibitionType,
      'Location': this.editExhibitionForm.value.editExhibitionLocation,
      'Notes': this.editExhibitionForm.value.editExhibitionNotes,
      'Comments': this.editExhibitionForm.value.editExhibitionComments,
      'Tags': this.editExhibitionForm.value.editExhibitionTags,
      'Visibility': this.editExhibitionForm.value.editExhibitionVisibility
    };
    this.fileService.getCSV('Exhibition', getCSVObject);
  }

  openPrintList(asList: boolean): void {
    this.printAsList = asList;
    UIkit.modal(window.document.getElementById('createList')).show();
  }

  updatePrintItem(item: any, event) {
    const changedItemIndex = this.activeExhibition.items.findIndex(exhibitionItem => exhibitionItem._id === item._id);
    this.activeExhibition.items[changedItemIndex].hideFromPDF = event.currentTarget.checked;
  }

  updateItemSettings(settingsType: string, item: any, event) {
    const changedItemIndex = this.activeExhibition.items.findIndex(collectionItem => collectionItem._id === item._id);
    this.activeExhibition.items[changedItemIndex][settingsType] = event.currentTarget.checked;
  }

  getExhibitionPDF(): void {
    let exhibitionObject = {
      items: [],
      notes: this.editExhibitionForm.value.editExhibitionNotes,
      pricing: {},
      printAsList: this.printAsList
    };

    if (this.activeExhibition.items.length) {
      this.activeExhibition.items.forEach(item => {
        if (item.hideFromPDF !== true) {
          const itemName = item.name;
          let itemImage = null;

          if (item.coverImage) {
            itemImage = item.coverImage;
          } else if (item.images && item.images.length) {
            itemImage = item.images[0].fileId;
          }

          const itemArtistName = item.artistName;
          const itemCreated = moment.utc(item.created).isValid() ? moment.utc(item.created).locale('fi').format('YYYY') : null;
          const itemTechnique = item.technique;

          /* Näitä tietoja ei varmaankaan tarvita */
          let itemSize = '';
          if (item.height) {
            itemSize += item.height;
          }
          if (item.width) {
            if (item.height) {
              itemSize += ' x ';
            }
            itemSize += item.width;
          }
          if (item.depth) {
            if (item.height) {
              itemSize += ' x ';
            }
            itemSize += item.depth;
          }

          const itemPrice = item.price.price + ' ' + item.price.currency;
          const itemAvailability = item.availability;

          let ExhibitionItem: any = {};
          ExhibitionItem = {
            'id': item._id,
            'account': this.activeAccount,
            'Artist': itemArtistName,
            'Item name': itemName,
            'showQRCode': item.showQRCode === true,
          };

          if (itemCreated) {
            ExhibitionItem['Item created'] = itemCreated;
          }

          if (itemTechnique) {
            ExhibitionItem['Item technique'] = itemTechnique;
          }

          if (itemSize) {
            ExhibitionItem['Item size'] = itemSize + ' cm';
          }

          if (!item.hidePrice) {
            if (item.price.price) {
              ExhibitionItem['Item price'] = item.price.price + ' ' + (item.price.currency ? item.price.currency : '');
            }
          }

          /*if (itemAvailability) {
            ExhibitionItem['Item availability'] = itemAvailability;
          }*/

          if (item.listNotes) {
            ExhibitionItem['Item notes'] = item.listNotes;
          }

          if (itemImage) {
            ExhibitionItem.Image = itemImage;
          }

          exhibitionObject.items.push(ExhibitionItem);
        }
      });
    }

    this.fileService.getPDF('Exhibition', exhibitionObject);
  }

  openAdvancedSearch(): void {
    this.showAdvancedArtworkSearchModalWindow = true;
    setTimeout(() => {
      this.advancedArtworkSearchModalWindow = window.document.getElementById('advancedArtworkSearch');
      UIkit.modal(this.advancedArtworkSearchModalWindow).show();
    }, 500);

  }

  setArtworkSearchResults(searchResult: Array<any>) {
    searchResult.forEach(item => this.selectedArtworks.push(item));
    this.addArtworksToExhibition();
  }

  createDate(inputDate: string): any {
    return {
      isRange: false,
      singleDate: {
        date: {
          year: new Date(inputDate).getFullYear(),
          month: new Date(inputDate).getMonth() + 1,
          day: new Date(inputDate).getDate()
        }
      }
    };
  }

  updateDate(fieldName: string, dateObject: any) {
    this.editExhibitionForm.controls[fieldName].patchValue(moment(dateObject.singleDate.jsDate).format());
  }

  getCharactersLimitError(fieldName: string) {
    if (!fieldName || !this.editExhibitionForm.get(fieldName).value ) {
      return ''
    }

    const charactersOverLimit: number = this.editExhibitionForm.get(fieldName).value.length - this.editExhibitionForm.get(fieldName).errors.maxlength.requiredLength;

    return `Field length is ${ charactersOverLimit } characters over limit`;
  }

  getCharactersLeft(fieldName: string, charactersLimit: number) {
    if (!fieldName || !this.editExhibitionForm.get(fieldName).value ) {
      return ''
    }

    const fieldLength: number = this.editExhibitionForm.get(fieldName).value.length;
    const charactersOverLimit: number = fieldLength - charactersLimit;

    if (charactersOverLimit >= 0) { return `${ charactersLimit } / ${ charactersLimit }` }

    return `${ fieldLength } / ${ charactersLimit }`;
  }
}
