import {Component, Inject, forwardRef, Optional} from '@angular/core';
import { BaseWidget, NgAisInstantSearch } from 'angular-instantsearch';
import { connectClearRefinements } from 'instantsearch.js/es/connectors';
import {NgAisIndex} from 'angular-instantsearch';

@Component({
  selector: 'app-ais-clear-refinements',
  template: `<button class="uk-button uk-button-default" type="button" (click)="state.refine()" [ngClass]="{'uk-disabled': !state.hasRefinements }">Clear all</button>`
})
// tslint:disable-next-line:component-class-suffix
export class SearchAisClearRefinements extends BaseWidget {
  public state: {
    hasRefinements: boolean;
    refine: Function;
    createURL: Function;
    widgetParams: object;
  };
  constructor(
    @Inject(forwardRef(() => NgAisIndex))
    @Optional()
    public parentIndex: NgAisIndex,
    @Inject(forwardRef(() => NgAisInstantSearch))
    public instantSearchInstance: NgAisInstantSearch
  ) {
    super('ClearRefinements');
    this.createWidget(connectClearRefinements, {
      // instance options
    });
  }

}
