import {Component, EventEmitter, OnInit, ViewChild} from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { UserService } from '../../services/user.service';
import { TradeService } from '../../services/trade.service';
import { AccountService } from '../../services/account.service';
import { AuthService } from '../../services/auth.service';
import { ActiveService } from '../../services/active.service';
import { FileService } from '../../services/file.service';
import { SearchService } from '../../services/search.service';

import { UploadOutput, UploadInput, UploadFile, humanizeBytes, UploaderOptions } from 'ngx-uploader';
import {AngularMyDatePickerDirective, IAngularMyDpOptions, IMyDateModel} from 'angular-mydatepicker';
import * as moment from 'moment/moment';
import { Observable } from 'rxjs';
import { delay } from 'rxjs/operators';
declare var UIkit: any;

@Component({
  selector: 'app-trades',
  templateUrl: './trades.component.html',
  styleUrls: ['./trades.component.scss']
})
export class TradesComponent implements OnInit {
  public user: any = {};
  public activeTrade: any = {};
  private activeUser: string;
  public accountActor: any = {};
  public actorType: string;
  public activeAccount: string;
  public account: Object = {};
  public artists: Array<Object> = [];
  public actors: Array<Object> = [];
  public trades: Array<Object> = [];
  public VATRates: Array<Object> = [];
  public isLoading = true;
  public addTradeForm: FormGroup;
  public addNewTradeModalWindow: any;
  public advancedArtworkSearchModalWindow: any;
  public showAdvancedArtworkSearchModalWindow: boolean = false;
  public sortingKey: string;
  public sortAscending: boolean = false;
  public currentPage: number = 1;
  public limitQuery: number = 10;
  public pageCount: number = 1;
  public pages: Array<any> = [];
  public searchArtworkForm: FormGroup;
  public searchArtworkResults: Array<Object> = [];
  public selectedArtworks: Array<Object> = [];
  public searchResultsFound: boolean = false;
  public myDatePickerOptions: IAngularMyDpOptions = {
    dateFormat: 'dd.mm.yyyy'
  };
  private todaysDate: IMyDateModel;
  private validDate: IMyDateModel;
  @ViewChild('AngularMyDatePickerAddTradeCreated') AngularMyDatePickerAddTradeCreated: AngularMyDatePickerDirective;
  public options: UploaderOptions;
  public formData: FormData;
  public filesToUpload: UploadFile[];
  public uploadInput: EventEmitter<UploadInput>;
  public humanizeBytes: Function;
  public dragOver: boolean;
  public imageLoadOffset$: any;
  public numberWithDecimal = new RegExp(/^[0-9]+(\.[0-9]{1,2})?$/);

  constructor(
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private formBuilder: FormBuilder,
    private auth: AuthService,
    private activeService: ActiveService,
    private userService: UserService,
    private accountService: AccountService,
    private tradeService: TradeService,
    private fileService: FileService,
    private searchService: SearchService
  ) {
    this.activatedRoute.data.subscribe(data => {
      this.activeAccount = data.resolvedData.account.id;
      this.user = data.resolvedData.user.id;
    });

    this.todaysDate = {
      isRange: false,
      singleDate: {date: {year: new Date().getFullYear(), month: new Date().getMonth() + 1, day: new Date().getDate()}}
    };
    this.validDate = {
      isRange: false,
      singleDate: {date: {year: new Date().getFullYear(), month: new Date().getMonth() + 1, day: new Date().getDate()}}
    };

    this.addTradeForm = this.formBuilder.group({
      addTradeOwner: new FormControl('', [
        Validators.required,
        Validators.maxLength(100)
      ]),
      addTradeName: new FormControl('', [
        Validators.required,
        Validators.maxLength(100)
      ]),
      addTradeDescription: new FormControl('', [
        Validators.maxLength(2048)
      ]),
      addTradeType: new FormControl('', [
        Validators.required,
        Validators.maxLength(100)
      ]),
      addTradeSeller: new FormControl(null, [
        Validators.required,
        Validators.maxLength(100)
      ]),
      addTradeAgent: new FormControl(null, [
        Validators.maxLength(100)
      ]),
      addTradeBuyer: new FormControl(null, [
        Validators.required,
        Validators.maxLength(100)
      ]),
      addTradeCreated: new FormControl('', [
        Validators.maxLength(10)
      ]),
      addTradeCurrency: new FormControl('', [
        Validators.maxLength(10)
      ]),
      addTradeVAT: new FormControl('', [
        Validators.maxLength(10),
        Validators.pattern(this.numberWithDecimal)
      ]),
      addTradePrice: new FormControl('', [
        Validators.maxLength(10),
        Validators.pattern(this.numberWithDecimal)
      ]),
      addTradeDiscount: new FormControl('', [
        Validators.maxLength(10),
        Validators.pattern(this.numberWithDecimal)
      ]),
      addTradeAttachments: new FormControl('', [
        Validators.maxLength(100)
      ]),
      addTradeShowNotes: new FormControl( '', [
        Validators.maxLength(100)
      ]),
      addTradeTags: new FormControl('', [
        Validators.maxLength(2048)
      ]),
      addTradeNotes: new FormControl('', [
        Validators.maxLength(2048)
      ]),
      addTradeComments: new FormControl('', [
        Validators.maxLength(2048)
      ]),
      addTradeVisibility: new FormControl('', [
        Validators.maxLength(10)
      ])
    });

    this.searchArtworkForm = this.formBuilder.group({
      searchArtworkTerm: new FormControl('', [
        Validators.required,
        Validators.minLength(3),
        Validators.maxLength(50)
      ])
    });

    this.activeTrade.items = [];
    this.filesToUpload = [];
    this.uploadInput = new EventEmitter<UploadInput>();
    this.humanizeBytes = humanizeBytes;
  }

  ngOnInit() {
    this.isLoading = true;
    this.addTradeForm.patchValue({
      addTradeOwner: this.activeAccount
    });
    this.getAccountData();

    this.activeService.getActiveData().subscribe(
      data => {
        this.activeUser = data.user.id;
      }
    );
  }

  getAccountData() {
    const getObject = {
      accountId: this.activeAccount,
      requester: 'trades',
      sortingKey: this.sortingKey ? this.sortingKey : '_id',
      sortingOrder: this.sortAscending ? 'asc' : 'desc',
      skipQuery: (this.currentPage - 1) * this.limitQuery,
      limitQuery: this.limitQuery,
      contentTypes: ['account', 'artist', 'trade', 'tradeCount', 'actor', 'client', 'clientCount']
    };

    this.accountService.getAccountData(getObject).subscribe(
      data => {
        this.account = data.account;
        this.VATRates = data.account.VATRates;
        this.artists = data.artists;
        this.trades = data.trades;
        this.actors = data.actors;
        this.accountActor = data.account.actor;
        this.accountActor.fullName = data.account.actor.lastName ? data.account.actor.name + ' ' + data.account.actor.lastName : data.account.actor.name;

        this.actors = this.actors.map((actor: any) => {
          let fullName = actor.name;
          if (actor.lastName) { fullName += ' ' + actor.lastName; }

          return {
            ...actor,
            fullName: fullName
          };
        });

        this.pageCount = Math.ceil(data.tradeCount / this.limitQuery);
        if (this.pageCount > 0) {
          this.pages = Array(this.pageCount).fill(null);
        }

        this.addTradeForm.patchValue({
          addTradeCurrency: data.account.tradingCurrency ?? "EUR",
          addTradeVAT: data.account.vat
        });
      },
      () => {
        UIkit.notification({
          message: 'Error fetching clients',
          status: 'danger',
          pos: 'top-right',
          timeout: 1500
        });
      },
      () => this.isLoading = false
    );
  }

  openAddNewTrade() {
    this.addNewTradeModalWindow = window.document.getElementById('addNewTrade');
    UIkit.modal(this.addNewTradeModalWindow).show();
  }

  addTrade() {
    const addTradeObject = {
      owner: this.addTradeForm.value.addTradeOwner,
      name: this.addTradeForm.value.addTradeName,
      description: this.addTradeForm.value.addTradeDescription,
      items: this.activeTrade.items,
      type: this.addTradeForm.value.addTradeType,
      seller: this.addTradeForm.value.addTradeSeller,
      agent: this.addTradeForm.value.addTradeAgent ? this.addTradeForm.value.addTradeAgent : null,
      buyer: this.addTradeForm.value.addTradeBuyer,
      created: this.addTradeForm.value.addTradeCreated ? moment(this.addTradeForm.value.addTradeCreated.singleDate.jsDate).format() : moment(new Date),
      currency: this.addTradeForm.value.addTradeCurrency,
      vat: this.addTradeForm.value.addTradeVAT,
      price: this.addTradeForm.value.addTradePrice,
      discount: this.addTradeForm.value.addTradeDiscount,
      /*attachments: this.addTradeForm.value.addTradeAttachments,*/
      showNotes: this.addTradeForm.value.addTradeShowNotes,
      tags: this.addTradeForm.value.addTradeTags,
      notes: this.addTradeForm.value.addTradeNotes,
      comments: this.addTradeForm.value.addTradeComments,
      visibility: this.addTradeForm.value.addTradeVisibility
    };

    this.tradeService.addTrade(addTradeObject).subscribe(
      res => {
        this.ngOnInit();
        UIkit.modal(this.addNewTradeModalWindow).hide();
        this.resetForm();
        UIkit.notification({
          message: 'Saved successfully',
          status: 'success',
          pos: 'top-right',
          timeout: 1500
        });
      },
      error => {
        UIkit.notification({
          message: 'Saving failed',
          status: 'danger',
          pos: 'top-right',
          timeout: 1500
        });
      }
    );
  }

  openTrade(tradeId): void {
    this.router.navigateByUrl('/trade/' + tradeId);
  }

  removeTrade(tradeId): void {
    this.tradeService.deleteTrade(tradeId).subscribe(
      () => {
        this.ngOnInit();
        UIkit.notification({
          message: 'Trade removed successfully',
          status: 'success',
          pos: 'top-right',
          timeout: 1500
        });
      },
      () => {
        UIkit.notification({
          message: 'Error removing trades',
          status: 'danger',
          pos: 'top-right',
          timeout: 1500
        });
      }
    );
  }

  sortTrades(sortingKey: string): void {
    if (sortingKey !== this.sortingKey) {
      this.sortingKey = sortingKey;
    } else {
      this.sortAscending = !this.sortAscending;
    }
    this.getAccountData();
  }

  activeSort(key: string): boolean {
    return this.sortingKey === key;
  }

  resetForm(): void {
    this.addTradeForm.reset();
    this.addTradeForm.patchValue({
      addTradeOwner: this.activeAccount,
      addTradeType: '',
      addTradeSeller: '',
      addTradeAgent: '',
      addTradeBuyer: ''
    });

    this.activeTrade.items = [];
    this.searchArtworkResults = [];
    this.emptySearch();
  }

  searchArtwork() {
    /*this.isLoading = true;*/
    if (this.searchArtworkForm.value.searchArtworkTerm.trim().length === 0) {
      return;
    }

    const searchObject = {
      searchArtworkAccountId: this.activeAccount,
      searchArtworkUserId: this.activeUser,
      searchArtworkTerm: this.searchArtworkForm.value.searchArtworkTerm
    };
    this.searchService.searchArtwork(searchObject).subscribe(
      res => {
        if (res.length) {
          if (this.searchArtworkResults) {
            this.searchArtworkResults.length = 0;
          }
          res.forEach(artwork => {
            artwork.allResultType = 'artwork';
            this.searchArtworkResults.push(artwork);
          });
          /*this.isLoading = false;*/
          this.searchResultsFound = true;
          /*document.getElementById('search-artwork-results-container').classList.add('active');*/
          Array.prototype.forEach.call(document.getElementsByClassName('results-container'), function (element) {
            element.classList.add('active');
          });



          UIkit.notification({
            message: 'Search matched ' + this.searchArtworkResults.length + ' results',
            status: 'success',
            pos: 'top-right',
            timeout: 1500
          });
        } else {
          if (this.searchArtworkResults) {
            this.searchArtworkResults.length = 0;
          }
          UIkit.notification({
            message: 'No search results',
            status: 'danger',
            pos: 'top-right',
            timeout: 1500
          });
        }
      },
      error => {
        if (this.searchArtworkResults) {
          this.searchArtworkResults.length = 0;
        }
        UIkit.notification({
          message: 'No search results',
          status: 'danger',
          pos: 'top-right',
          timeout: 1500
        });
      }
    );
  }

  emptySearch(): void {
    /*document.getElementById('search-artwork-results-container').classList.remove('active');*/
    /*Array.prototype.forEach.call(document.getElementsByClassName('results-container'), function (element) {
      element.classList.remove('active');
    });*/

    /*let element: HTMLElement = document.getElementsByClassName('uk-navbar-toggle uk-close')[1] as HTMLElement;
    element.click();*/
    setTimeout(() => {
      this.searchResultsFound = false;
      if (this.searchArtworkResults) {
        this.searchArtworkResults.length = 0;
      }
      if (this.selectedArtworks) {
        this.selectedArtworks.length = 0;
      }
      this.searchArtworkForm.patchValue({ searchArtworkTerm: ''});
    }, 300);
  }

  selectTradeArtwork(artwork): void {
    this.selectedArtworks.indexOf(artwork) === -1 ?
      this.selectedArtworks.push(artwork) : this.selectedArtworks.splice(this.selectedArtworks.indexOf(artwork), 1);
  }

  addArtworksToTrade(): void {
    this.selectedArtworks.map((item: any) => {
      const itemFound = this.activeTrade.items.find((activeTradeItem: any) => {
        return activeTradeItem._id === item._id;
      });
      if (itemFound === undefined) {
        this.activeTrade.items.push(item);
      }
      const artistFound = this.artists.find((artist: any) => {
        return artist._id === item.artist;
      });
      if (artistFound === undefined) {
        this.artists.push({
          _id: item.artist,
          name: item.artistName,
          priceMultiplier: item.artistPriceMultiplier,
          status: item.artistStatus
        });
      }
    });

    this.emptySearch();
  }

  removeArtworkFromTrade(item): void {
    this.activeTrade.items.splice(this.activeTrade.items.indexOf(item), 1);
    const artistToSplice = this.artists.find((artist: any) => {
      return artist._id === item.artist;
    });
    this.artists.splice(this.artists.indexOf(artistToSplice), 1);
  }

  openSlideshow($event): void {
    $event.preventDefault();
    UIkit.lightboxPanel({
      /* Ei tarvitse, jos muistaa käyttää $event.preventDefaultia
        autoplay: true,
        autoplayInterval: 5000,
        delayControls: 1000,*/
      items: this.activeTrade.items.map(item => {
        let itemImage;
        if (item.coverImage) {
          itemImage = '/api/image/' + item.coverImage;
        } else if (item.images[0]) {
          itemImage = '/api/image/' + item.images[0].fileId;
        } else {
          itemImage = '/api/image/artbuddy-logo-placeholder.svg'
        }

        return {
          source: itemImage,
          caption: item.name
        };
      })
    }).show();
  }

  openAdvancedSearch(): void {
    this.showAdvancedArtworkSearchModalWindow = true;
    setTimeout(() => {
      this.advancedArtworkSearchModalWindow = window.document.getElementById('advancedArtworkSearch');
      UIkit.modal(this.advancedArtworkSearchModalWindow).show();
    }, 500);

  }

  setArtworkSearchResults(searchResult: Array<any>) {
    searchResult.forEach(item => this.selectedArtworks.push(item));
    this.addArtworksToTrade();
  }

  setLimit(limit: number): void {
    if (limit !== this.limitQuery) {
      this.limitQuery = limit;
      /* Set currentPage to 0 to start from the first page */
      this.currentPage = 1;
      this.getAccountData();
    }
  }

  activeLimit(limit: number): boolean {
    return this.limitQuery === limit;
  }

  setCurrentPageString(page: string): void {
    this.setCurrentPage(parseInt(page, 0));
  }

  setCurrentPage(page: number): void {
    if ((page !== this.currentPage) && (page <= this.pageCount)) {
      this.currentPage = page;
      this.getAccountData();
    }
  }

  updateDate(fieldName: string, dateObject: any) {
    this.addTradeForm.controls[fieldName].patchValue(moment(dateObject.singleDate.jsDate).format());
  }

  getCharactersLimitError(fieldName: string) {
    if (!fieldName || !this.addTradeForm.get(fieldName).value ) {
      return ''
    }

    const charactersOverLimit: number = this.addTradeForm.get(fieldName).value.length - this.addTradeForm.get(fieldName).errors.maxlength.requiredLength;

    return `Field length is ${ charactersOverLimit } characters over limit`;
  }

  getCharactersLeft(fieldName: string, charactersLimit: number) {
    if (!fieldName || !this.addTradeForm.get(fieldName).value ) {
      return ''
    }

    const fieldLength: number = this.addTradeForm.get(fieldName).value.length;
    const charactersOverLimit: number = fieldLength - charactersLimit;

    if (charactersOverLimit >= 0) { return `${ charactersLimit } / ${ charactersLimit }` }

    return `${ fieldLength } / ${ charactersLimit }`;
  }
}
