import {Component, EventEmitter, OnInit, ViewChild, ChangeDetectorRef, AfterContentChecked} from '@angular/core';
import {FormBuilder, FormControl, FormGroup, Validators} from '@angular/forms';
import {ActivatedRoute, NavigationEnd, Router} from '@angular/router';
import {ActiveService} from '../../services/active.service';
import {AuthService} from '../../services/auth.service';
import {UserService} from '../../services/user.service';
import {ItemService} from '../../services/item.service';
import {AccountService} from '../../services/account.service';
import {FileService} from '../../services/file.service';
import {ActorService} from '../../services/actor.service';
import {humanizeBytes, UploaderOptions, UploadFile, UploadInput, UploadOutput} from 'ngx-uploader';
import {AngularMyDatePickerDirective, IAngularMyDpOptions} from 'angular-mydatepicker';
import 'rxjs/add/observable/merge';
import * as moment from 'moment/moment';

declare var UIkit: any;

@Component({
  selector: 'app-single-artwork',
  templateUrl: './single-artwork.component.html',
  styleUrls: ['./single-artwork.component.scss']
})
export class SingleArtworkComponent implements OnInit, AfterContentChecked {
  public user: any = {};
  public activeData: Object = {};
  public activeArtwork: any = {};
  public activeArtworkEditions: Array<Object> = [];
  public activeArtworkBids: Array<Object> = [];
  public activeArtworkEstimates: Array<Object> = [];
  public activeArtworkTrades: Array<Object> = [];
  public activeArtworkExhibitions: Array<Object> = [];
  public activeArtworkReservations: Array<Object> = [];
  public activeArtworkId: string = '';
  public activeAccount: any = {};
  public actorType: string;
  public clients: Array<Object> = [];
  public account: Object = {};
  public artists: Array<Object> = [];
  public items: Array<Object> = [];
  public variables: Array<string> = [];
  public isLoading = true;
  public uploadLoading: Boolean = false;
  public editArtworkForm: FormGroup;
  public addArtworkEditionForm: FormGroup;
  public addArtworkImageModalWindow: any;
  public addArtworkEditionModal: any;
  public sortingKey: string = '-sequence';
  public sortAscending: boolean = false;
  public currentItem: any;
  public previousItems: Array<any> = [];
  public nextItems: Array<any> = [];
  public options: UploaderOptions;
  public formData: FormData;
  public filesToUpload: UploadFile[];
  public uploadInput: EventEmitter<UploadInput>;
  public humanizeBytes: Function;
  public dragOver: boolean;
  public myDatePickerOptions: IAngularMyDpOptions = {
    dateFormat: 'dd.mm.yyyy'
  };
  // @ViewChild('AngularMyDatePickerEditArtworkCreated') AngularMyDatePickerEditArtworkCreated: AngularMyDatePickerDirective;
  // @ViewChild('AngularMyDatePickerEditArtworkStarted') AngularMyDatePickerEditArtworkStarted: AngularMyDatePickerDirective;
  @ViewChild('AngularMyDatePickerEditArtworkPurchaseDate') AngularMyDatePickerEditArtworkPurchaseDate: AngularMyDatePickerDirective;
  public imageLoadOffset$: any;
  loadingImage = '/api/file/5a7193132d68a10c580620e5';
  public artistMultiplier: string;
  public openPDF: any = {
    price: false,
    code: false,
    comments: false,
    notesInPrint: false
  };
  public numberWithDecimal = new RegExp(/^[0-9]+(\.[0-9]{1,2})?$/);

  constructor(
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private activeService: ActiveService,
    private formBuilder: FormBuilder,
    private auth: AuthService,
    private userService: UserService,
    private accountService: AccountService,
    private actorService: ActorService,
    private itemService: ItemService,
    private fileService: FileService,
    private changeDetector: ChangeDetectorRef
  ) {
    this.activeService.activeDataChange.subscribe(data => {
      this.activeData = data.item;
    });

    this.router.events.subscribe((evt) => {
      if (!(evt instanceof NavigationEnd)) {
        return;
      }

      this.getPrevAndNextItems(this.router.getCurrentNavigation().extras.state);
    });

    this.editArtworkForm = this.formBuilder.group({
      editArtworkOwner: new FormControl('', [
        Validators.required,
        Validators.maxLength(50)
      ]),
      editArtworkName: new FormControl('', [
        Validators.required,
        Validators.maxLength(125)
      ]),
      editArtworkDescription: new FormControl('', [
        Validators.maxLength(2048)
      ]),
      editArtworkImages: new FormControl(''),
      editArtworkCoverImage: new FormControl(''),
      editArtworkCode: new FormControl('', [
        Validators.maxLength(30)
      ]),
      editArtworkHeight: new FormControl('', [
        Validators.maxLength(15),
        Validators.pattern(this.numberWithDecimal)
      ]),
      editArtworkWidth: new FormControl('', [
        Validators.maxLength(15),
        Validators.pattern(this.numberWithDecimal)
      ]),
      editArtworkDepth: new FormControl('', [
        Validators.maxLength(15)
      ]),
      editArtworkWeight: new FormControl('', [
        Validators.maxLength(15),
        Validators.pattern(this.numberWithDecimal)
      ]),
      editArtworkFrame: new FormControl('', [
        Validators.maxLength(5)
      ]),
      editArtworkFrameHeight: new FormControl('', [
        Validators.maxLength(15)
      ]),
      editArtworkFrameWidth: new FormControl('', [
        Validators.maxLength(15)
      ]),
      editArtworkFrameDepth: new FormControl('', [
        Validators.maxLength(15)
      ]),
      editArtworkStarted: new FormControl('', [
        Validators.maxLength(4),
        Validators.pattern(this.numberWithDecimal)
      ]),
      editArtworkCreated: new FormControl(null, [
        Validators.maxLength(4),
        Validators.pattern(this.numberWithDecimal)
      ]),
      editArtworkSRP: new FormControl('', [
        Validators.maxLength(10),
        // Validators.pattern(this.numberWithDecimal)
      ]),
      editArtworkPrice: new FormControl('', [
        Validators.maxLength(10),
        // Validators.pattern(this.numberWithDecimal)
      ]),
      editArtworkArtist: new FormControl(null, [
        Validators.maxLength(100)
      ]),
      editArtworkTechnique: new FormControl('', [
        Validators.maxLength(150)
      ]),
      editArtworkMaterial: new FormControl('', [
        Validators.maxLength(30)
      ]),
      editArtworkType: new FormControl({value: '', disabled: true}, [
        Validators.maxLength(30)
      ]),
      editArtworkSeller: new FormControl('', [
        Validators.maxLength(100)
      ]),
      editArtworkSellerContactInfo: new FormControl('', [
        Validators.maxLength(2048)
      ]),
      editArtworkPurchaseDate: new FormControl('', [
        Validators.maxLength(30)
      ]),
      editArtworkPurchasePrice: new FormControl('', [
        Validators.maxLength(10),
        // Validators.pattern(this.numberWithDecimal)
      ]),
      editArtworkMinimumPrice: new FormControl('', [
        Validators.maxLength(10),
        // Validators.pattern(this.numberWithDecimal)
      ]),
      editArtworkMaximumPrice: new FormControl('', [
        Validators.maxLength(10),
        // Validators.pattern(this.numberWithDecimal)
      ]),
      editArtworkEstimateBasis: new FormControl('', [
        Validators.maxLength(30)
      ]),
      editArtworkEstimator: new FormControl('', [
        Validators.maxLength(100)
      ]),
      editArtworkEstimatorInformation: new FormControl('', [
        Validators.maxLength(2048)
      ]),
      editArtworkCollateralValue: new FormControl('', [
        Validators.maxLength(10),
        Validators.pattern(this.numberWithDecimal)
      ]),
      editArtworkConservationDate: new FormControl(null, [
        Validators.maxLength(30)
      ]),
      editArtworkConservationCompany: new FormControl('', [
        Validators.maxLength(100)
      ]),
      editArtworkConservationName: new FormControl('', [
        Validators.maxLength(100)
      ]),
      editArtworkConservationDetails: new FormControl('', [
        Validators.maxLength(2048)
      ]),
      editArtworkPriceMultiplier: new FormControl(null, [
        Validators.maxLength(5)
      ]),
      editArtworkAvailability: new FormControl(null, [
        Validators.maxLength(30)
      ]),
      editArtworkLogistics: new FormControl(null, [
        Validators.maxLength(30)
      ]),
      editArtworkPackaging: new FormControl(null, [
        Validators.maxLength(30)
      ]),
      editArtworkClonable: new FormControl('', [
        Validators.maxLength(5)
      ]),
      editArtworkCloneOnOrder: new FormControl('', [
        Validators.maxLength(5)
      ]),
      editArtworkArtistsproof: new FormControl('', [
        Validators.maxLength(30)
      ]),
      editArtworkSigning: new FormControl('', [
        Validators.maxLength(30)
      ]),
      editArtworkLocation: new FormControl(null, [
        Validators.maxLength(30)
      ]),
      editArtworkLocationInformation: new FormControl('', [
        Validators.maxLength(2048)
      ]),
      editArtworkDuration: new FormControl('', [
        Validators.maxLength(30)
      ]),
      editArtworkDeliveryOnOrder: new FormControl('', [
        Validators.maxLength(30)
      ]),
      editArtworkAttachments: new FormControl('', [
        Validators.maxLength(10)
      ]),
      editArtworkTags: new FormControl('', [
        Validators.maxLength(2048)
      ]),
      editArtworkListNotes: new FormControl('', [
        Validators.maxLength(2048)
      ]),
      editArtworkNotes: new FormControl('', [
        Validators.maxLength(2048)
      ]),
      editArtworkComments: new FormControl('', [
        Validators.maxLength(2048)
      ]),
      editArtworkVisibility: new FormControl('', [
        Validators.maxLength(10)
      ])
    });

    this.addArtworkEditionForm = this.formBuilder.group({
      addArtworkEditionAmount: new FormControl('', [
        Validators.required,
        Validators.maxLength(100)
      ])
    });

    this.activatedRoute.data.subscribe(data => {
      this.activeArtwork = data.resolvedData.item;
      this.editArtworkForm.patchValue({
        editArtworkName: data.resolvedData.item.name,
        editArtworkDescription: data.resolvedData.item.description,
        editArtworkCode: data.resolvedData.item.code,
        editArtworkHeight: data.resolvedData.item.height,
        editArtworkWidth: data.resolvedData.item.width,
        editArtworkDepth: data.resolvedData.item.depth,
        editArtworkWeight: data.resolvedData.item.weight,
        editArtworkFrame: data.resolvedData.item.frame,
        editArtworkFrameHeight: data.resolvedData.item.frameHeight,
        editArtworkFrameWidth: data.resolvedData.item.frameWidth,
        editArtworkFrameDepth: data.resolvedData.item.frameDepth,
        editArtworkStarted: data.resolvedData.item.started,
        editArtworkCreated: data.resolvedData.item.created,
        editArtworkSRP: data.resolvedData.item.srp?.price,
        editArtworkPrice: data.resolvedData.item.price?.price,
        editArtworkArtist: data.resolvedData.item.artist,
        editArtworkTechnique: data.resolvedData.item.technique,
        editArtworkMaterial: data.resolvedData.item.material,
        editArtworkEstimateBasis: data.resolvedData.item.estimateBasis,
        editArtworkType: data.resolvedData.item._type,
        editArtworkSeller: data.resolvedData.item.seller,
        editArtworkSellerContactInfo: data.resolvedData.item.sellerContactInfo,
        // editArtworkPurchaseDate: data.resolvedData.item.purchaseDate,
        editArtworkPurchasePrice: data.resolvedData.item.purchasePrice,
        editArtworkMinimumPrice: data.resolvedData.item.minimumPrice,
        editArtworkMaximumPrice: data.resolvedData.item.maximumPrice,
        editArtworkEstimator: data.resolvedData.item.estimator,
        editArtworkEstimatorInformation: data.resolvedData.item.estimatorInformation,
        editArtworkCollateralValue: data.resolvedData.item.collateralValue,
        // editArtworkConservationDate: data.resolvedData.item.conservationDate,
        editArtworkConservationCompany: data.resolvedData.item.conservationCompany,
        editArtworkConservationName: data.resolvedData.item.conservationName,
        editArtworkConservationDetails: data.resolvedData.item.conservationDetails,
        editArtworkConservationEstimateBasis: data.resolvedData.item.conservationEstimateBasis,
        editArtworkPriceMultiplier: data.resolvedData.item.priceMultiplier,
        editArtworkAvailability: data.resolvedData.item.availability,
        editArtworkLogistics: data.resolvedData.item.logistics,
        editArtworkPackaging: data.resolvedData.item.packaging,
        editArtworkClonable: data.resolvedData.item.clonable,
        editArtworkCloneOnOrder: data.resolvedData.item.cloneOnOrder,
        editArtworkArtistsproof: data.resolvedData.item.artistsproof,
        editArtworkSigning: data.resolvedData.item.signing,
        editArtworkLocation: data.resolvedData.item.location,
        editArtworkLocationInformation: data.resolvedData.item.locationInformation,
        editArtworkDuration: data.resolvedData.item.duration,
        editArtworkDeliveryOnOrder: data.resolvedData.item.deliveryOnOrder,
        editArtworkAttachments: data.resolvedData.item.attachments,
        editArtworkTags: data.resolvedData.item.tags,
        editArtworkListNotes: data.resolvedData.item.listNotes,
        editArtworkNotes: data.resolvedData.item.notes,
        editArtworkComments: data.resolvedData.item.comments,
        editArtworkVisibility: data.resolvedData.item.visibility
      });

      // Debug: log form errors
      // setTimeout(() => {
      //   Object.keys(this.editArtworkForm.controls).forEach(key => {
      //     const controlErrors: any = this.editArtworkForm.get(key).errors;
      //     if (controlErrors != null) {
      //       Object.keys(controlErrors).forEach(keyError => {
      //         console.log('Key control: ' + key + ', keyError: ' + keyError + ', err value: ', controlErrors[keyError]);
      //       });
      //     }
      //   });
      // },3000)

      this.activeArtworkEditions = data.resolvedData.editions;
      this.activeArtworkBids = data.resolvedData.bids;
      this.activeArtworkEstimates = data.resolvedData.estimates;
      this.activeArtworkTrades = data.resolvedData.trades;
      this.activeArtworkExhibitions = data.resolvedData.exhibitions;
      this.activeArtworkReservations = data.resolvedData.reservations;

      if (!this.activeArtwork.images) {
        this.activeArtwork.images = [];
      }

      this.activeArtworkId = data.resolvedData.item._id;
      /* This is only changing the active pane, not its tab active CSS class because of UIkit issue
      setTimeout(() => {
        const ArtworkFirstPane = window.document.getElementById('artworkPaneSwither');
        if (ArtworkFirstPane) {
          UIkit.switcher(ArtworkFirstPane).show(0);
        }
      }, 100);
      */
      // this.activeArtwork.started = this.activeArtwork.started ? this.createDate(this.activeArtwork.started) : null;
      // this.activeArtwork.created = this.activeArtwork.created ? this.createDate(this.activeArtwork.created) : null;
      this.activeArtwork.purchaseDate = this.activeArtwork.purchaseDate ? this.createDate(this.activeArtwork.purchaseDate) : null;
      this.activeArtwork.conservationDate = this.activeArtwork.conservationDate ? this.createDate(this.activeArtwork.conservationDate) : null;

      // 12.3.2021 Otin pois, koska this.activeAccount ei ole vielä tiedossa constructorissa
      // this.getAccountData();
      // if (window.document.getElementById('basicinfo')) {
      //   window.document.getElementById('basicinfo').click();
      // }

      if (this.router.getCurrentNavigation().extras.state && this.router.getCurrentNavigation().extras.state.shouldRefreshData) {
        this.getAccountData();
      }
    });

    this.filesToUpload = [];
    this.uploadInput = new EventEmitter<UploadInput>();
    this.humanizeBytes = humanizeBytes;
  }

  editionsAmountArray(n: number): any[] {
    return Array(n + 1);
  }

  setDate(): void {
    // Set today date using the patchValue function
    let date = new Date();
    this.editArtworkForm.patchValue({
      myDate: {
        date: {
          year: date.getFullYear(),
          month: date.getMonth() + 1,
          day: date.getDate()
        }
      }
    });
  }

  clearDate(): void {
    // Clear the date using the patchValue function
    this.editArtworkForm.patchValue({myDate: null});
  }

  ngOnInit() {
    this.activeService.getActiveData().subscribe(
      data => {
        this.user = data.user.id;
        this.activeAccount = data.account.id;
      },
      () => {
        UIkit.notification({
          message: 'Error fetching artwork information',
          status: 'danger',
          pos: 'top-right',
          timeout: 1500
        });
      },
      () => this.isLoading = false
    );
    this.getAccountData();
  }

  ngAfterContentChecked(): void {
    this.changeDetector.detectChanges();
  }

  getPrevAndNextItems(state: any) {
    if (state && state.currentItem) {
      this.currentItem = state.currentItem;
    }
    if (state && state.previousItems) {
      if (!Array.isArray(state.previousItems)) {
        this.previousItems = [state.previousItems];
      } else {
        this.previousItems = state.previousItems;
      }
    }
    if (state && state.nextItems) {
      if (!Array.isArray(state.nextItems)) {
        this.nextItems = [state.nextItems];
      } else {
        this.nextItems = state.nextItems;
      }
    }
  }

  getAccountData() {
    const getObject = {
      accountId: this.activeAccount,
      requester: 'item',
      sortingKey: this.sortingKey ? this.sortingKey : '_id',
      sortingOrder: this.sortAscending ? 'asc' : 'desc',
      skipQuery: 0,
      limitQuery: null,
      contentTypes: ['account', 'artist', 'item', 'variable']
    };

    this.accountService.getAccountData(getObject).subscribe(
      data => {
        this.account = data.account;
        this.artists = data.artists
          .map((artist: any) => {
            artist.fullName = artist.name + ' ' + artist.lastName;

            return artist;
          });

        /* Update editArtworkArtist to show correct arting in Artist ng-select */
        if (this.activeArtwork && this.activeArtwork.artist) {
          this.activeArtwork.artist.fullName = this.activeArtwork.artist.name + ' ' + this.activeArtwork.artist.lastName;

          this.editArtworkForm.patchValue({
            editArtworkArtist: this.activeArtwork.artist
          });
        }

        this.items = data.items;
        this.variables = data.variables ? data.variables : [];

        const activeArtist: any = this.artists.filter((artist: any) => {
          return artist._id === this.activeArtwork.artist;
        });

        if (activeArtist && activeArtist[0] && activeArtist[0].priceMultiplier) {
          this.artistMultiplier = activeArtist[0].priceMultiplier;
        }
      },
      () => {
        UIkit.notification({
          message: 'Error fetching artwork',
          status: 'danger',
          pos: 'top-right',
          timeout: 1500
        });
      },
      () => {
        this.isLoading = false;
        window.document.getElementById('basicinfo').click();
      }
    );
  }

  onUploadOutput(output: UploadOutput): void {
    const properFile = output.file && output.file.name && output.file.name.length > 0;

    if (output.type === 'allAddedToQueue') {
      const event: UploadInput = {
        type: 'uploadAll',
        url: '/api/file',
        method: 'POST',
        includeWebKitFormBoundary: true
      };
      this.uploadInput.emit(event);
    } else if (output.type === 'addedToQueue' && properFile) {
      if (this.filesToUpload.indexOf(output.file) == -1) {
        this.filesToUpload.push(output.file);
      }
    } else if (output.type === 'uploading' && properFile) {
      if (!this.activeArtwork['images'].some(image => image.placeholderId === output.file.id)) {
        this.uploadLoading = true;
        this.activeService.increasePendingRequests();
        this.activeArtwork['images'].push({placeholderId: output.file.id});
        const index = this.filesToUpload.findIndex(file => file.id === output.file.id);
        this.filesToUpload[index] = output.file;
      }
    } else if (output.type === 'removed') {
      // remove file from array when removed
      this.filesToUpload = this.filesToUpload.filter((file: UploadFile) => file !== output.file);
    } else if (output.type === 'dragOver') {
      this.dragOver = true;
    } else if (output.type === 'dragOut') {
      this.dragOver = false;
    } else if (output.type === 'drop') {
      this.dragOver = false;
    } else if (output.type === 'removedAll') {
      this.filesToUpload.length = 0;
    } else if (output.type === 'done') {
      const fileObject = {
        itemId: this.activeArtworkId,
        fileId: output.file.response // alkuperäinen: output.file.response.id
      };

      this.itemService.addItemImageId(fileObject).subscribe(() => {
        this.removeFile(output.file.id);
        const indexToReplace = this.activeArtwork['images'].findIndex(file => file.placeholderId === output.file.id);
        this.activeArtwork['images'][indexToReplace] = fileObject;
        this.activeService.decreasePendingRequests();
        this.uploadLoading = false;

        this.activeArtwork['images'] = this.activeArtwork['images'].filter(image => {
          return !('placeholderId' in image);
        });

      });
    }
  }

  onAttachmentsUploadOutput(output: UploadOutput): void {
    const properFile = output.file && output.file.name && output.file.name.length > 0;

    if (output.type === 'allAddedToQueue') {
      const event: UploadInput = {
        type: 'uploadAll',
        url: '/api/file',
        method: 'POST',
        includeWebKitFormBoundary: true
      };
      this.uploadInput.emit(event);
    } else if (output.type === 'addedToQueue' && properFile) {
      if (this.filesToUpload.indexOf(output.file) == -1) {
        this.filesToUpload.push(output.file);
      }
    } else if (output.type === 'uploading' && properFile) {
      if (!this.activeArtwork['attachments'].some(image => image.placeholderId === output.file.id)) {
        this.uploadLoading = true;
        this.activeService.increasePendingRequests();
        this.activeArtwork['attachments'].push({placeholderId: output.file.id});
        const index = this.filesToUpload.findIndex(file => file.id === output.file.id);
        this.filesToUpload[index] = output.file;
      }
    } else if (output.type === 'removed') {
      // remove file from array when removed
      this.filesToUpload = this.filesToUpload.filter((file: UploadFile) => file !== output.file);
    } else if (output.type === 'dragOver') {
      this.dragOver = true;
    } else if (output.type === 'dragOut') {
      this.dragOver = false;
    } else if (output.type === 'drop') {
      this.dragOver = false;
    } else if (output.type === 'removedAll') {
      this.filesToUpload.length = 0;
    } else if (output.type === 'done') {
      const fileObject = {
        itemId: this.activeArtworkId,
        fileId: output.file.response,
        name: output.file.name,
        contentType: output.file.type
      };

      this.removeFile(output.file.id);
      const indexToReplace = this.activeArtwork['attachments'].findIndex(file => file.placeholderId === output.file.id);
      this.activeArtwork['attachments'][indexToReplace] = fileObject;
      this.activeService.decreasePendingRequests();
      this.uploadLoading = false;
    }
  }

  startUpload(): void {
    const event: UploadInput = {
      type: 'uploadAll',
      url: '/api/file',
      method: 'POST',
      includeWebKitFormBoundary: true
    };
    this.uploadInput.emit(event);
  }

  cancelUpload(id: string): void {
    this.uploadInput.emit({type: 'cancel', id: id});
  }

  removeFile(id: string): void {
    this.uploadInput.emit({type: 'remove', id: id});
  }

  removeAllFiles(): void {
    this.uploadInput.emit({type: 'removeAll'});
  }

  onChange($event: any) {
    this.editArtworkForm.value.editArtworkImages = $event.target.files[0];
  }


  openAddArtworkImage() {
    this.addArtworkImageModalWindow = window.document.getElementById('addArtworkImages');
    UIkit.modal(this.addArtworkImageModalWindow).show();
  }

  selectCover(fileId: string): void {
    this.editArtworkForm.patchValue(
      {
        editArtworkCoverImage: fileId
      }
    );
    this.activeArtwork['coverImage'] = fileId;
  }

  openAddArtworkEditionModal() {
    this.addArtworkEditionModal = window.document.getElementById('addArtworkEditionModal');
    UIkit.modal(this.addArtworkEditionModal).show();
  }

  editArtwork() {
    const editArtworkObject = {
      id: this.activeArtworkId,
      owner: this.editArtworkForm.value.editArtworkOwner,
      name: this.editArtworkForm.value.editArtworkName,
      description: this.editArtworkForm.value.editArtworkDescription,
      images: this.activeArtwork['images'],
      coverImage: this.editArtworkForm.value.editArtworkCoverImage,
      code: this.editArtworkForm.value.editArtworkCode,
      /*length: this.editArtworkForm.value.editArtworkLength,*/
      height: this.editArtworkForm.value.editArtworkHeight,
      width: this.editArtworkForm.value.editArtworkWidth,
      depth: this.editArtworkForm.value.editArtworkDepth,
      weight: this.editArtworkForm.value.editArtworkWeight,
      frame: this.editArtworkForm.value.editArtworkFrame,
      frameHeight: this.editArtworkForm.value.editArtworkFrameHeight,
      frameWidth: this.editArtworkForm.value.editArtworkFrameWidth,
      frameDepth: this.editArtworkForm.value.editArtworkFrameDepth,
      started: this.editArtworkForm.value.editArtworkStarted,
      created: this.editArtworkForm.value.editArtworkCreated,
      price: this.editArtworkForm.value.editArtworkPrice,
      srp: this.editArtworkForm.value.editArtworkSRP,
      artist: this.editArtworkForm.value.editArtworkArtist,
      technique: this.editArtworkForm.value.editArtworkTechnique,
      material: this.editArtworkForm.value.editArtworkMaterial,
      type: this.activeArtwork._type,
      seller: this.editArtworkForm.value.editArtworkSeller,
      sellerContactInfo: this.editArtworkForm.value.editArtworkSellerContactInfo,
      purchaseDate: this.editArtworkForm.value.editArtworkPurchaseDate ? moment(this.editArtworkForm.value.editArtworkPurchaseDate.singleDate.jsDate).format() : moment(new Date),
      purchasePrice: this.editArtworkForm.value.editArtworkPurchasePrice,
      minimumPrice: this.editArtworkForm.value.editArtworkMinimumPrice,
      maximumPrice: this.editArtworkForm.value.editArtworkMaximumPrice,
      estimateBasis: this.editArtworkForm.value.editArtworkEstimateBasis,
      estimator: this.editArtworkForm.value.editArtworkEstimator,
      estimatorInformation: this.editArtworkForm.value.editArtworkEstimatorInformation,
      collateralValue: this.editArtworkForm.value.editArtworkCollateralValue,
      conservationDate: this.editArtworkForm.value.editArtworkConservationDate ? moment(this.editArtworkForm.value.editArtworkConservationDate.singleDate.jsDate).format() : moment(new Date),
      conservationCompany: this.editArtworkForm.value.editArtworkConservationCompany,
      conservationName: this.editArtworkForm.value.editArtworkConservationName,
      conservationDetails: this.editArtworkForm.value.editArtworkConservationDetails,
      priceMultiplier: this.editArtworkForm.value.editArtworkPriceMultiplier,
      availability: this.editArtworkForm.value.editArtworkAvailability,
      logistics: this.editArtworkForm.value.editArtworkLogistics,
      packaging: this.editArtworkForm.value.editArtworkPackaging,
      clonable: this.editArtworkForm.value.editArtworkClonable,
      cloneOnOrder: this.editArtworkForm.value.editArtworkCloneOnOrder,
      artistsproof: this.editArtworkForm.value.editArtworkArtistsproof,
      signing: this.editArtworkForm.value.editArtworkSigning,
      location: this.editArtworkForm.value.editArtworkLocation,
      locationInformation: this.editArtworkForm.value.editArtworkLocationInformation,
      duration: this.editArtworkForm.value.editArtworkDuration,
      deliveryOnOrder: this.editArtworkForm.value.editArtworkDeliveryOnOrder,
      attachments: this.activeArtwork.attachments,
      tags: this.editArtworkForm.value.editArtworkTags,
      listNotes: this.editArtworkForm.value.editArtworkListNotes,
      notes: this.editArtworkForm.value.editArtworkNotes,
      comments: this.editArtworkForm.value.editArtworkComments,
      visibility: this.editArtworkForm.value.editArtworkVisibility
    };
    this.itemService.editItem(editArtworkObject).subscribe(
      res => {
        this.ngOnInit();
        UIkit.notification({
          message: 'Saved successfully',
          status: 'success',
          pos: 'top-right',
          timeout: 1500
        });
      },
      error => {
        UIkit.notification({
          message: 'Saving failed',
          status: 'danger',
          pos: 'top-right',
          timeout: 1500
        });
      }
    );
  }

  addArtworkEdition() {
    const addArtworkEditionObject = {
      id: this.activeArtworkId,
      owner: this.activeAccount,
      name: this.activeArtwork.name,
      description: this.activeArtwork.description,
      images: this.activeArtwork['images'],
      coverImage: this.activeArtwork.coverImage,
      code: this.activeArtwork.code,
      length: this.activeArtwork.length,
      width: this.activeArtwork.width,
      height: this.activeArtwork.height,
      weight: this.activeArtwork.weight,
      frame: this.activeArtwork.frame,
      frameHeight: this.activeArtwork.frameHeight,
      frameWidth: this.activeArtwork.frameWidth,
      frameDepth: this.activeArtwork.drameDepth,
      started: this.activeArtwork.started,
      created: this.activeArtwork.created,
      price: this.activeArtwork.price,
      srp: this.activeArtwork.srp,
      artist: this.activeArtwork.artist,
      technique: this.activeArtwork.technique,
      material: this.activeArtwork.material,
      type: this.activeArtwork._type,
      seller: this.activeArtwork.seller,
      sellerContactInfo: this.activeArtwork.sellerContactInfo,
      purchaseDate: this.activeArtwork.purchaseDate ? moment(this.activeArtwork.purchaseDate.singleDate.jsDate).format() : moment(new Date),
      purchasePrice: this.activeArtwork.purchasePrice,
      minimumPrice: this.activeArtwork.minimumPrice,
      maximumPrice: this.activeArtwork.maximumPrice,
      estimateBasis: this.activeArtwork.estimateBasis,
      estimator: this.activeArtwork.estimator,
      estimatorInformation: this.activeArtwork.estimatorInformation,
      collateralValue: this.activeArtwork.collateralValue,
      conservationDate: this.activeArtwork.conservationDate ? moment(this.activeArtwork.conservationDate.singleDate.jsDate).format() : moment(new Date),
      conservationCompany: this.activeArtwork.conservationCompany,
      conservationName: this.activeArtwork.conservationName,
      conservationDetails: this.activeArtwork.conservationDetails,
      availability: this.activeArtwork.availability,
      logistics: this.activeArtwork.logistics,
      packaging: this.activeArtwork.packaging,
      clonable: this.activeArtwork.clonable,
      cloneOnOrder: this.activeArtwork.cloneOnOrder,
      artistsproof: this.activeArtwork.artistsproof,
      location: this.activeArtwork.location,
      locationInformation: this.activeArtwork.locationInformation,
      duration: this.activeArtwork.duration,
      deliveryOnOrder: this.activeArtwork.deliveryOnOrder,
      attachments: this.activeArtwork.attachments,
      tags: this.activeArtwork.tags,
      listNotes: this.activeArtwork.listNotes,
      notes: this.activeArtwork.notes,
      comments: this.activeArtwork.comments,
      visibility: this.activeArtwork.visibility
    };
    const editionObject = {
      item: addArtworkEditionObject,
      currentEditionsAmount: this.activeArtworkEditions.length + 1,
      newEditionsAmount: this.addArtworkEditionForm.value.addArtworkEditionAmount
    };
    this.itemService.addItemEdition(editionObject).subscribe(
      data => {
        data.forEach(item => {
          this.activeArtworkEditions.indexOf(item) === -1 ?
            this.activeArtworkEditions.push(item) : this.activeArtworkEditions.splice(this.activeArtworkEditions.indexOf(item), 1);
        });
        UIkit.modal(this.addArtworkEditionModal).hide();
        UIkit.notification({
          message: 'Saved successfully',
          status: 'success',
          pos: 'top-right',
          timeout: 1500
        });
      },
      error => {
        UIkit.notification({
          message: 'Saving failed',
          status: 'danger',
          pos: 'top-right',
          timeout: 1500
        });
      }
    );
  }

  openArtworkEdition(artworkId: string): void {
    this.router.navigateByUrl('/artwork/' + artworkId)
      .then(() => window.document.getElementById('basicinfo').click());
  }

  openImage(attachment) {
    window.open('/api/originalimage/' + this.activeAccount + '/' + attachment.fileId, '_blank');
  }

  openAttachment(attachment) {
    this.itemService.openAttachment(this.activeAccount, attachment).then((response: any) => {
      if (response.url) {
        window.open(response.url);
      } else {
        UIkit.notification({
          message: 'Error fetching data',
          status: 'danger',
          pos: 'top-right',
          timeout: 1500
        });
      }
    });
  }

  removeAttachment(attachment) {
    const attachmentObject = {
      documentType: 'item',
      itemId: this.activeArtworkId,
      fileId: attachment.fileId
    };

    this.fileService.removeFile(attachmentObject).subscribe(
      res => {
        const attachmentToSplice = this.activeArtwork.attachments.find((accountAttachment: any) => {
          return attachment.fileId === accountAttachment.fileId;
        });
        this.activeArtwork.attachments.splice(this.activeArtwork.attachments.indexOf(attachmentToSplice), 1);
        UIkit.notification({
          message: 'File removed successfully',
          status: 'success',
          pos: 'top-right',
          timeout: 1500
        });
      },
      error => {
        UIkit.notification({
          message: 'Removing failed',
          status: 'danger',
          pos: 'top-right',
          timeout: 1500
        });
      }
    );
  }

  removeImage(image) {
    const imageObject = {
      documentType: 'item',
      itemId: this.activeArtworkId,
      fileId: image.fileId
    };

    this.fileService.removeFile(imageObject).subscribe(
      res => {
        const imageToSplice = this.activeArtwork.images.find((accountImage: any) => {
          return image._id === accountImage._id;
        });
        this.activeArtwork.images.splice(this.activeArtwork.images.indexOf(imageToSplice), 1);
        UIkit.notification({
          message: 'Image removed successfully',
          status: 'success',
          pos: 'top-right',
          timeout: 1500
        });
      },
      error => {
        UIkit.notification({
          message: 'Removing failed',
          status: 'danger',
          pos: 'top-right',
          timeout: 1500
        });
      }
    );
  }

  removeArtworkEdition(artworkId): void {
    this.itemService.deleteItem(artworkId).subscribe(
      item => {
        const removedEdition = this.activeArtwork.editions.find((edition: any) => edition._id === artworkId);
        this.activeArtworkEditions.indexOf(removedEdition) === -1 ?
          this.activeArtworkEditions.push(removedEdition) : this.activeArtworkEditions.splice(this.activeArtwork.editions.indexOf(removedEdition), 1);
        UIkit.notification({
          message: 'Edition removed successfully',
          status: 'success',
          pos: 'top-right',
          timeout: 1500
        });
      },
      () => {
        UIkit.notification({
          message: 'Error removing edition',
          status: 'danger',
          pos: 'top-right',
          timeout: 1500
        });
      }
    );
  }

  getArtworkEditions(): void {
    this.itemService.getItem(this.activeArtwork._id).subscribe(
      data => {
        this.activeArtworkEditions = data.editions;
      },
      err => {
        UIkit.notification({
          message: 'Error fetching editions',
          status: 'danger',
          pos: 'top-right',
          timeout: 1500
        });
      }
    );
  }

  sortItems(sortingKey: string): void {
    if (sortingKey !== this.sortingKey) {
      this.sortingKey = sortingKey;
    } else {
      this.sortAscending = !this.sortAscending;
    }
  }

  sortBids(sortingKey: string): void {
    if (sortingKey !== this.sortingKey) {
      this.sortingKey = sortingKey;
    } else {
      this.sortAscending = !this.sortAscending;
    }
  }

  sortEstimates(sortingKey: string): void {
    if (sortingKey !== this.sortingKey) {
      this.sortingKey = sortingKey;
    } else {
      this.sortAscending = !this.sortAscending;
    }
  }

  sortTrades(sortingKey: string): void {
    if (sortingKey !== this.sortingKey) {
      this.sortingKey = sortingKey;
    } else {
      this.sortAscending = !this.sortAscending;
    }
  }

  sortReservations(sortingKey: string): void {
    if (sortingKey !== this.sortingKey) {
      this.sortingKey = sortingKey;
    } else {
      this.sortAscending = !this.sortAscending;
    }
  }

  activeSort(key: string): boolean {
    return this.sortingKey === key;
  }

  openEstimate(estimateId): void {
    this.router.navigateByUrl('/estimate/' + estimateId);
  }

  openBid(bidId): void {
    this.router.navigateByUrl('/bid/' + bidId);
  }

  openTrade(tradeId): void {
    this.router.navigateByUrl('/trade/' + tradeId);
  }

  openReservation(reservationId): void {
    this.router.navigateByUrl('/reservation/' + reservationId);
  }

  sortExhibitions(sortingKey: string): void {
    if (sortingKey !== this.sortingKey) {
      this.sortingKey = sortingKey;
    } else {
      this.sortAscending = !this.sortAscending;
    }
  }

  openExhibition(exhibitionId): void {
    this.router.navigateByUrl('/exhibition/' + exhibitionId);
  }

  getCSV(): void {
    const getCSVObject = {
      Name: this.editArtworkForm.value.editArtworkName,
      Description: this.editArtworkForm.value.editArtworkDescription,
      Images: this.activeArtwork['images'],
      /*coverImage: this.editArtworkForm.value.editArtworkCoverImage,*/
      Code: this.editArtworkForm.value.editArtworkCode,
      Length: this.editArtworkForm.value.editArtworkHeight,
      Width: this.editArtworkForm.value.editArtworkWidth,
      Height: this.editArtworkForm.value.editArtworkDepth,
      Weight: this.editArtworkForm.value.editArtworkWeight,
      Frame: this.editArtworkForm.value.editArtworkFrame,
      'Frame height': this.editArtworkForm.value.editArtworkFrameHeight,
      'Frame width': this.editArtworkForm.value.editArtworkFrameWidth,
      'Frame depth': this.editArtworkForm.value.editArtworkFrameDepth,
      Started: this.editArtworkForm.value.editArtworkStarted,
      Created: this.editArtworkForm.value.editArtworkCreated,
      Price: this.editArtworkForm.value.editArtworkPrice,
      'Suggested retail price': this.editArtworkForm.value.editArtworkSRP,
      'Artist name': this.editArtworkForm.value.editArtworkArtist,
      Technique: this.editArtworkForm.value.editArtworkTechnique,
      Material: this.editArtworkForm.value.editArtworkMaterial,
      Type: this.activeArtwork._type,
      'Seller name': this.editArtworkForm.value.editArtworkSeller,
      'Seller contact info': this.editArtworkForm.value.editArtworkSellerContactInfo,
      'Purchase date': this.editArtworkForm.value.editArtworkPurchaseDate ? moment(this.editArtworkForm.value.editArtworkPurchaseDate.singleDate.jsDate).format() : moment(new Date),
      'Purchase price': this.editArtworkForm.value.editArtworkPurchasePrice,
      'Minimum price': this.editArtworkForm.value.editArtworkMinimumPrice,
      'Maximum price': this.editArtworkForm.value.editArtworkMaximumPrice,
      'Estimate basis': this.editArtworkForm.value.editArtworkEstimateBasis,
      'Estimator': this.editArtworkForm.value.editArtworkEstimator,
      'Estimator information': this.editArtworkForm.value.editArtworkEstimatorInformation,
      'Collateral value': this.editArtworkForm.value.editArtworkCollateralValue,
      'Conservation date': this.editArtworkForm.value.editArtworkConservationDate ? moment(this.editArtworkForm.value.editArtworkConservationDate.singleDate.jsDate).format() : moment(new Date),
      'Conservation company': this.editArtworkForm.value.editArtworkConservationCompany,
      'Conservation name': this.editArtworkForm.value.editArtworkConservationName,
      'Conservation details': this.editArtworkForm.value.editArtworkConservationDetails,
      Availability: this.editArtworkForm.value.editArtworkAvailability,
      Logistics: this.editArtworkForm.value.editArtworkLogistics,
      Packaging: this.editArtworkForm.value.editArtworkPackaging,
      Clonable: this.editArtworkForm.value.editArtworkClonable,
      'Clone on order': this.editArtworkForm.value.editArtworkCloneOnOrder,
      'Artist proof': this.editArtworkForm.value.editArtworkArtistsproof,
      'Artist signing': this.editArtworkForm.value.editArtworkSigning,
      Location: this.editArtworkForm.value.editArtworkLocation,
      'Location information': this.editArtworkForm.value.editArtworkLocationInformation,
      'Duration': this.editArtworkForm.value.editArtworkDuration,
      'Delivery on order': this.editArtworkForm.value.editArtworkDeliveryOnOrder,
      /*attachments: this.activeArtwork.attachments,*/
      Tags: this.editArtworkForm.value.editArtworkTags,
      'List notes': this.editArtworkForm.value.editArtworkListNotes,
      Notes: this.editArtworkForm.value.editArtworkNotes,
      Comments: this.editArtworkForm.value.editArtworkComments,
      Visibility: this.editArtworkForm.value.editArtworkVisibility
    };
    this.fileService.getCSV('Artwork', getCSVObject);
  }

  openSlideshow($event): void {
    $event.preventDefault();
    UIkit.lightboxPanel({
      /* Ei tarvitse, jos muistaa käyttää $event.preventDefaultia
        autoplay: true,
        autoplayInterval: 5000,
        delayControls: 1000,*/
      items: this.activeArtwork.images.map(item => {
        let itemImage;
        itemImage = '/api/image/' + item.fileId;

        return {
          source: itemImage
        };
      })
    }).show();
  }

  getArtworkPDF(): void {
    let artworkObject = {
      items: []
    };

    if (this.activeArtwork['images'].length) {
      /* Käytetään vain ensimmäistä kuvaa, ei kaikkia */
      /*this.activeArtwork['images'].forEach(item => {*/
      let item: any = this.activeArtwork;
      let itemImage = "";
      const itemName = this.editArtworkForm.value.editArtworkName;

      if (item.coverImage) {
        const itemCoverImage = this.activeArtwork['images'].find(image => image.fileId === item.coverImage);
        if (itemCoverImage) {
          itemImage = itemCoverImage.fileId;
        }
      } else if (item.images && item.images.length) {
        itemImage = this.activeArtwork['images'][0].fileId;
      }

      const itemArtistName = this.editArtworkForm.value.editArtworkArtist.fullName;

      const artworkStarted = moment.utc(this.editArtworkForm.value.editArtworkStarted).isValid() ? moment.utc(new Date(this.editArtworkForm.value.editArtworkStarted)).locale('fi').format('YYYY') : null;
      const artworkCreated = moment.utc(this.editArtworkForm.value.editArtworkCreated).isValid() ? moment.utc(new Date(this.editArtworkForm.value.editArtworkCreated)).locale('fi').format('YYYY') : null;

      let itemCreated = "";

      if (artworkStarted) {
        itemCreated += artworkStarted;
      }

      if (artworkCreated) {
        if (artworkStarted) {
          itemCreated += " - ";
        }
        itemCreated += artworkCreated;
      }

      const itemTechnique = this.editArtworkForm.value.editArtworkTechnique;

      let itemSize = '';
      if (this.editArtworkForm.value.editArtworkHeight) {
        itemSize += this.editArtworkForm.value.editArtworkHeight;
      }
      if (this.editArtworkForm.value.editArtworkWidth) {
        if (this.editArtworkForm.value.editArtworkHeight) {
          itemSize += ' x ';
        }
        itemSize += this.editArtworkForm.value.editArtworkWidth;
      }
      if (this.editArtworkForm.value.editArtworkDepth) {
        if (this.editArtworkForm.value.editArtworkHeight) {
          itemSize += ' x ';
        }
        itemSize += this.editArtworkForm.value.editArtworkDepth;
      }

      const itemPrice = this.editArtworkForm.value.editArtworkPrice ? (this.editArtworkForm.value.editArtworkPrice + ' EUR') : null;
      const itemAvailability = this.editArtworkForm.value.editArtworkAvailability;

      let artworkItem: any = {};
      artworkItem = {
        'id': this.activeArtworkId,
        'account': this.activeAccount,
        'Artist': itemArtistName,
        'Item name': itemName,
        'showPrice': true,
      };

      if (itemCreated) {
        artworkItem['Item created'] = itemCreated;
      }

      if (itemTechnique) {
        artworkItem['Item technique'] = itemTechnique;
      }

      if (itemSize) {
        artworkItem['Item size'] = itemSize + ' cm';
      }

      if (this.openPDF.price && itemPrice) {
        artworkItem = {
          ...artworkItem,
          'Item price': itemPrice
        };
      }

      if (this.openPDF.code && this.editArtworkForm.value.editArtworkCode) {
        artworkItem = {
          ...artworkItem,
          'Item code': `Artwork code: ${this.editArtworkForm.value.editArtworkCode}`
        };
      }

      artworkItem = {
        ...artworkItem,
        /*'Item availability': itemAvailability,*/
        Image: itemImage
      };

      if (this.openPDF.comments) {
        artworkItem = {
          ...artworkItem,
          'Comments': this.editArtworkForm.value.editArtworkComments,
        };
      }

      if (this.openPDF.notesInPrint) {
        artworkItem = {
          ...artworkItem,
          'Item notes': this.editArtworkForm.value.editArtworkListNotes,
        };
      }

      /*if (this.openPDF.price) {
        artworkItem = {
          'account': this.activeAccount,
          'Artist': itemArtistName,
          'Item name': itemName,
          'Item created': itemCreated,
          'Item technique': itemTechnique,
          'Item price': itemPrice,
          'Item availability': itemAvailability,
          'Comments': this.editArtworkForm.value.editArtworkComments,
          Image: itemImage
        };
      } else {
        artworkItem = {
          'account': this.activeAccount,
          'Artist': itemArtistName,
          'Item name': itemName,
          'Item created': itemCreated,
          'Item technique': itemTechnique,
          'Item availability': itemAvailability,
          'Comments': this.editArtworkForm.value.editArtworkComments,
          Image: itemImage
        };
      }*/

      if (itemImage !== undefined) {
        artworkObject.items.push(artworkItem);
      }
      /*});*/
    }

    /*
    artworkObject.pricing = {
      total: this.getItemsTotalPrice(),
      discount: this.getDiscountedPrice(),
      grandTotal: this.getItemsDiscountedPrice(),
      currency: this.tradingCurrency
    };
    */

    this.fileService.getPDF('Artwork', artworkObject);
  }

  createDate(inputDate: string): any {
    return {
      isRange: false,
      singleDate: {
        date: {
          year: new Date(inputDate).getFullYear(),
          month: new Date(inputDate).getMonth() + 1,
          day: new Date(inputDate).getDate()
        }
      }
    };
  }

  updateDate(fieldName: string, dateObject: any) {
    this.editArtworkForm.controls[fieldName].patchValue(moment(dateObject.singleDate.jsDate).format());
  }

  getCharactersLimitError(fieldName: string) {
    if (!fieldName || !this.editArtworkForm.get(fieldName).value) {
      return undefined;
    }

    const charactersOverLimit: number = this.editArtworkForm.get(fieldName).value.length - this.editArtworkForm.get(fieldName).errors.maxlength.requiredLength;

    setTimeout(() => {

    }, 500);
  }

  getCharactersLeft(fieldName: string, charactersLimit: number) {
    if (!fieldName || !this.editArtworkForm.get(fieldName).value) {
      return '';
    }

    const fieldLength: number = this.editArtworkForm.get(fieldName).value.length;
    const charactersOverLimit: number = fieldLength - charactersLimit;

    if (charactersOverLimit >= 0) {
      return `${charactersLimit} / ${charactersLimit}`;
    }

    return `${fieldLength} / ${charactersLimit}`;
  }

  openPreviousItem(itemId?: string): void {
    let selectedItemIndex: number;
    let previousItem: any;

    if (this.previousItems.length === 0) {
      UIkit.notification({
        message: 'Error opening previous item',
        status: 'danger',
        pos: 'top-right',
        timeout: 1500
      });
    }

    if (itemId) {
      selectedItemIndex = this.previousItems.findIndex((item: any) => item._id === itemId);

      previousItem = this.previousItems[selectedItemIndex];
      this.nextItems = [...this.previousItems.slice(selectedItemIndex + 1, this.previousItems.length), this.currentItem, ...this.nextItems];
      this.previousItems = this.previousItems.slice(0, selectedItemIndex) ?? [];
    }

    if (!itemId) {
      previousItem = this.previousItems.pop();

      if (this.previousItems.length > 0) {
        this.previousItems = this.previousItems.slice(0, this.previousItems.length);
      }

      this.nextItems = [this.currentItem, ...this.nextItems];
    }

    if (!previousItem) {
      return UIkit.notification({
        message: 'Error opening previous item',
        status: 'danger',
        pos: 'top-right',
        timeout: 1500
      });
    }

    UIkit.dropdown(document.getElementById('itemsDropdown')).hide();

    this.router.navigateByUrl('/artwork/' + previousItem._id,
      {
        state: {
          currentItem: previousItem,
          previousItems: this.previousItems,
          nextItems: this.nextItems,
          shouldRefreshData: true
        }
      }
    );
  }

  openNextItem(itemId?: string): void {
    let previousItems: Array<any> = this.previousItems;
    let nextItems: Array<any> = this.nextItems;
    let selectedItemIndex: number;
    let nextItem: any;

    if (this.nextItems.length === 0) {
      return UIkit.notification({
        message: 'Error opening next item',
        status: 'danger',
        pos: 'top-right',
        timeout: 1500
      });
    }

    if (itemId) {
      selectedItemIndex = nextItems.findIndex((item: any) => item._id === itemId);

      nextItem = nextItems[selectedItemIndex];
      previousItems = [...previousItems, this.currentItem, ...nextItems.slice(0, selectedItemIndex)] ?? [];
      nextItems = nextItems.slice(selectedItemIndex + 1, nextItems.length) ?? [];

      if (!nextItem) {
        return UIkit.notification({
          message: 'Error opening next item',
          status: 'danger',
          pos: 'top-right',
          timeout: 1500
        });
      }
    } else {
      nextItem = nextItems.shift();

      if (nextItems.length > 0) {
        nextItems = nextItems.slice(0, nextItems.length);
      }

      previousItems = [...previousItems, this.currentItem];
    }

    UIkit.dropdown(document.getElementById('itemsDropdown')).hide();

    this.router.navigateByUrl('/artwork/' + nextItem._id,
      {
        state: {
          currentItem: nextItem,
          previousItems: previousItems,
          nextItems: nextItems,
          shouldRefreshData: true
        }
      }
    );
  }

}
