<div class="uk-section uk-section-default uk-padding-remove-vertical">

  <app-sidenav contentType="Profile" [contentItem]="actor.name"></app-sidenav>

  <div class="uk-container-expand uk-height-expand">
    <ul uk-tab *ngIf="!isLoading">
      <li class="uk-active"><a href="#personal"><h4>Personal settings</h4></a></li>
      <li><a href="#profile"><h4>Profile settings</h4></a></li>
    </ul>
    <ul class="uk-switcher uk-card uk-card-body uk-padding-remove-top" *ngIf="!isLoading">
      <div id="personal" class="uk-padding-small uk-padding-remove-top">
        <form appNoSubmitEnter [formGroup]="personalForm" (ngSubmit)="submitPersonalForm()" autocomplete="off" class="uk-grid-small" uk-grid>
          <input type="hidden" name="profileFormId" name="personalFormId" formControlName="personalFormId" [ngModel]="actor._id">
          <input type="hidden" name="profileFormOwner" name="personalFormOwner" formControlName="personalFormOwner" [ngModel]="actor.owner">
          <div class="uk-section uk-section-default uk-width-1-1 uk-padding-small">
            <div class="uk-container uk-container-small">
              <h4 class="uk-margin-remove-bottom">Personal settings</h4>
              <hr class="uk-margin-small-top">
              <div class="uk-width-1-1">
                <div class="uk-margin">
                  <label class="uk-form-label" for="personalFormName">Name</label>
                  <div class="uk-form-controls">
                    <input id="personalFormName" name="personalFormName" formControlName="personalFormName" type="text" [ngModel]="actor.name" placeholder="Name ..." class="uk-input" [ngClass]="{'uk-form-danger': personalForm.get('personalFormName').touched && personalForm.get('personalFormName').hasError('required') }">
                  </div>
                </div>
              </div>
              <div class="uk-width-1-1">
                <div class="uk-margin">
                  <label class="uk-form-label" for="personalFormStreet">Street</label>
                  <div class="uk-form-controls">
                    <input id="personalFormStreet" name="personalFormStreet" formControlName="personalFormStreet" type="text" [ngModel]="actor.street" placeholder="Street ..." class="uk-input" [ngClass]="{'uk-form-danger': (personalForm.get('personalFormStreet').touched && personalForm.get('personalFormStreet').hasError('required') || personalForm.get('personalFormStreet').invalid) }">
                  </div>
                </div>
              </div>
              <div class="uk-width-1-1">
                <div class="uk-margin">
                  <label class="uk-form-label" for="personalFormCity">City</label>
                  <div class="uk-form-controls">
                    <input id="personalFormCity" name="personalFormCity" formControlName="personalFormCity" type="text" [ngModel]="actor.city" placeholder="City ..." class="uk-input" [ngClass]="{'uk-form-danger': (personalForm.get('personalFormCity').touched && personalForm.get('personalFormCity').hasError('required') || personalForm.get('personalFormCity').invalid) }">
                  </div>
                </div>
              </div>
              <div class="uk-width-1-1">
                <div class="uk-margin">
                  <label class="uk-form-label" for="personalFormState">State</label>
                  <div class="uk-form-controls">
                    <input id="personalFormState" name="personalFormState" formControlName="personalFormState" type="text" [ngModel]="actor.state" placeholder="State ..." class="uk-input" [ngClass]="{'uk-form-danger': (personalForm.get('personalFormState').touched && personalForm.get('personalFormState').hasError('required') || personalForm.get('personalFormState').invalid) }">
                  </div>
                </div>
              </div>
              <div class="uk-width-1-1">
                <div class="uk-margin">
                  <label class="uk-form-label" for="personalFormZip">Zip</label>
                  <div class="uk-form-controls">
                    <input id="personalFormZip" name="personalFormZip" formControlName="personalFormZip" type="text" [ngModel]="actor.zip" placeholder="Zip ..." class="uk-input" [ngClass]="{'uk-form-danger': (personalForm.get('personalFormZip').touched && personalForm.get('personalFormZip').hasError('required') || personalForm.get('personalFormZip').invalid) }">
                  </div>
                </div>
              </div>
              <div class="uk-width-1-1">
                <div class="uk-margin">
                  <label class="uk-form-label" for="personalFormCountry">Country</label>
                  <div class="uk-form-controls">
                    <input id="personalFormCountry" name="personalFormCountry" formControlName="personalFormCountry" type="text" [ngModel]="actor.country" placeholder="Country ..." class="uk-input" [ngClass]="{'uk-form-danger': (personalForm.get('personalFormCountry').touched && personalForm.get('personalFormCountry').hasError('required') || personalForm.get('personalFormCountry').invalid) }">
                  </div>
                </div>
              </div>
              <div class="uk-width-1-1">
                <div class="uk-margin">
                  <label class="uk-form-label" for="personalFormPhone">Phone</label>
                  <div class="uk-form-controls">
                    <input id="personalFormPhone" name="personalFormPhone" formControlName="personalFormPhone" type="text" [ngModel]="actor.phone" placeholder="Phone ..." class="uk-input" [ngClass]="{'uk-form-danger': (personalForm.get('personalFormPhone').touched && personalForm.get('personalFormPhone').hasError('required') || personalForm.get('personalFormPhone').invalid) }">
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="uk-width-1-1 uk-margin-medium-top">
            <hr class="uk-margin-small-top">
          </div>
          <div class="uk-width-1-1">
            <div class="uk-margin">
              <div class="uk-form-controls uk-text-right">
                <button type="submit" [disabled]="!personalForm.valid" class="uk-button uk-button-primary">Save</button>
              </div>
            </div>
          </div>
        </form>
      </div>
      <div id="profile" class="uk-padding-small">
        <form appNoSubmitEnter [formGroup]="profileForm" (ngSubmit)="submitProfileForm()" autocomplete="off" class="uk-grid-small" uk-grid>
          <input type="hidden" name="id" name="id" formControlName="id" [ngModel]="user._id">
          <div class="uk-section uk-section-default uk-width-1-1 uk-padding-small uk-padding-remove-top uk-margin-bottom">
            <div class="uk-container uk-container-small">
              <h4 class="uk-margin-remove-bottom">Profile</h4>
              <hr class="uk-margin-small-top">
              <p>Your email address is your identity on Artbuddy and is used to log in.</p>
              <div class="uk-width-1-1">
                <div class="uk-margin">
                  <label class="uk-form-label" for="profileFormEmail">Email</label>
                  <div class="uk-form-controls">
                    <input id="profileFormEmail" name="profileFormEmail" formControlName="profileFormEmail" type="text" placeholder="Email ..." class="uk-input" [ngClass]="{'uk-form-danger': (profileForm.get('profileFormEmail').touched && profileForm.get('profileFormEmail').hasError('required') || profileForm.get('profileFormEmail').invalid) }">
                  </div>
                </div>
              </div>
              <div class="uk-width-1-1">
                <div class="uk-margin">
                  <label class="uk-form-label" for="profileFormPassword">Password</label>
                  <div class="uk-form-controls">
                    <input id="profileFormPassword" name="profileFormPassword" formControlName="profileFormPassword" type="password" placeholder="Password ..." class="uk-input" [ngClass]="{'uk-form-danger': (profileForm.get('profileFormPassword').touched && profileForm.get('profileFormPassword').hasError('required') || profileForm.get('profileFormPassword').invalid) }">
                  </div>
                </div>
              </div>
              <!--<div class="uk-width-1-1">
                <div class="uk-margin">
                  <label class="uk-form-label" for="profileFormAvatar">Add new attachments</label>
                  <div class="uk-width-1-1 uk-flex-inline">
                    <div class="avatar-container uk-border-circle uk-background-muted uk-margin-auto-left uk-margin-auto-right uk-margin-small-top uk-margin-small-bottom uk-text-center">
                      <img class="uk-border-circle" src="/api/file/name/Pitka-copy-mobiili.png" width="100" height="100" alt="{{ auth.currentUser.username }} avatar">
                    </div>
                  </div>
                  <div class="uk-form-controls">
                    <div class="drop-container" ngFileDrop [options]="options" (uploadOutput)="onUploadOutput($event)" [uploadInput]="uploadInput">
                      <div class="js-upload uk-placeholder uk-text-center" [ngClass]="{ 'is-drop-over': dragOver }">
                        <span class="uk-text-middle">Attach files by dropping them here or by</span>
                        <div uk-form-custom>
                          <input type="file" multiple>
                          <span class="uk-link">selecting one</span>.
                        </div>
                      </div>
                    </div>
                  </div>
                  <progress id="js-progressbar" class="uk-progress" value="0" max="100" hidden></progress>
                  <div class="table-container" *ngIf="filesToUpload.length">
                    <table class="uk-table uk-table-middle uk-margin-top">
                      <caption>Files to upload</caption>
                      <thead>
                        <tr>
                          <th class="uk-table-shrink uk-text-capitalize">Image</th>
                          <th class="uk-width-expand uk-text-capitalize">Name</th>
                          <th class="uk-width-expand uk-text-capitalize">Size</th>
                          <th class="uk-table-shrink uk-text-capitalize"></th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr *ngFor="let file of filesToUpload; let i = index;">
                          <td class="uk-background-muted"><img class="uk-preserve-width uk-border-circle" src="/api/image/artbuddy-logo-placeholder.svg" width="60" uk-img></td>
                          <td>{{ file.name }}</td>
                          <td class="uk-text-nowrap">{{ file.size | bytes: 2 }}</td>
                          <td><button (click)="removeFile(file.id)" class="uk-button uk-button-default" type="button">Delete</button></td>
                        </tr>
                      </tbody>
                    </table>
                    <button type="button" (click)="startUpload()" class="uk-button uk-button-primary">Start upload</button>
                  </div>
                </div>
              </div>-->
              <div class="uk-width-1-1 uk-margin-medium-top">
                <hr class="uk-margin-small-top">
              </div>
              <div class="uk-width-1-1">
                <div class="uk-margin">
                  <div class="uk-form-controls uk-text-right">
                    <button type="submit" [disabled]="!profileForm.valid" class="uk-button uk-button-primary">Save</button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </form>
      </div>
    </ul>
  </div>
</div>
