import {Component, Inject, forwardRef, Optional} from '@angular/core';
import { BaseWidget, NgAisInstantSearch } from 'angular-instantsearch';
import { connectStats } from 'instantsearch.js/es/connectors';
import {NgAisIndex} from 'angular-instantsearch';

@Component({
  selector: 'app-ais-stats',
  template: `<div>Showing <span class="total-amount">{{state.nbHits}} results</span></div>`
})
export class SearchAisStatsComponent extends BaseWidget {
  public state: {
    hitsPerPage: number;
    nbHits: number;
    nbPages: number;
    page: number;
    processingTimeMS: number;
    query: string;
    widgetParams: object;
  };
  constructor(
    @Inject(forwardRef(() => NgAisIndex))
    @Optional()
    public parentIndex: NgAisIndex,
    @Inject(forwardRef(() => NgAisInstantSearch))
    public instantSearchInstance: NgAisInstantSearch
  ) {
    super('Stats');
    this.createWidget(connectStats, {
      // instance options
    });
  }
}
