import {Component, EventEmitter, OnInit, ViewChild} from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { FormGroup, FormControl, Validators, FormBuilder } from '@angular/forms';
import { AuthService } from '../../services/auth.service';
import { UserService } from '../../services/user.service';
import { ActiveService } from '../../services/active.service';
import { AccountService } from '../../services/account.service';
import { FileService } from '../../services/file.service';
import { SearchService } from '../../services/search.service';
import { CollectionService } from '../../services/collection.service';
import { ItemService } from '../../services/item.service';
import { UploadOutput, UploadInput, UploadFile, humanizeBytes, UploaderOptions } from 'ngx-uploader';
import {AngularMyDatePickerDirective, IAngularMyDpOptions, IMyDateModel} from 'angular-mydatepicker';
import * as moment from 'moment/moment';
import { Observable } from 'rxjs';
import { delay } from 'rxjs/operators';
import { ShortenPipe } from 'ngx-pipes';

declare var UIkit: any;

@Component({
  selector: 'app-single-collection',
  templateUrl: './single-collection.component.html',
  styleUrls: ['./single-collection.component.scss'],
  providers: [ ShortenPipe ]
})
export class SingleCollectionComponent implements OnInit {
  public user: any = {};
  public activeCollection: any = {};
  public activeCollectionId: string = '';
  public actorType: string;
  public activeAccount: any = {};
  private activeUser: string;
  public accounts: Array<Object> = [];
  public artists: Array<Object> = [];
  public variables: Array<string> = [];
  public isLoading = true;
  public activeServiceIsLoading = false;
  public uploadLoading: Boolean = false;
  public createListModalWindow: any;
  public advancedArtworkSearchModalWindow: any;
  public showAdvancedArtworkSearchModalWindow: boolean = false;
  public editCollectionForm: FormGroup;
  public searchArtworkForm: FormGroup;
  public searchArtworkResults: Array<Object> = [];
  public selectedArtworks: Array<Object> = [];
  public searchResultsFound: boolean = false;
  public myDatePickerOptions: IAngularMyDpOptions = {
    dateFormat: 'dd.mm.yyyy'
  };
  private todaysDate: IMyDateModel;
  private validDate: IMyDateModel;
  @ViewChild('AngularMyDatePickerAddReservationCreated') myDp: AngularMyDatePickerDirective;
  @ViewChild('AngularMyDatePickerAddReservationValid') myDp2: AngularMyDatePickerDirective;
  public options: UploaderOptions;
  public formData: FormData;
  public filesToUpload: UploadFile[];
  public uploadInput: EventEmitter<UploadInput>;
  public humanizeBytes: Function;
  public dragOver: boolean;
  public imageLoadOffset$: any;

  constructor(
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private formBuilder: FormBuilder,
    private auth: AuthService,
    private userService: UserService,
    private activeService: ActiveService,
    private accountService: AccountService,
    private collectionService: CollectionService,
    private fileService: FileService,
    private searchService: SearchService,
    private itemService: ItemService,
    private shorten: ShortenPipe
  ) {
    this.activatedRoute.data.subscribe(data => {
      this.activeCollection = data.resolvedData;
      if (this.activeCollection.items) {
        this.activeCollection.items.map(item => {
          item.created = moment.utc(item.created).isValid() ? moment.utc(item.created).locale('fi').format('YYYY') : null;

          /*switch (item.availability)
          {
            case 0:
              item.availability = 'Sold';
              break;
            case 1:
              item.availability = 'Booked';
              break;
            case 2: default:
            item.availability = 'Available';
            break;
          }*/
          const artistFound = this.artists.find((artist: any) => {
            return artist._id === item.artist;
          });
          if (artistFound === undefined) {
            this.artists.push({
              _id: item.artist,
              name: item.artistName,
              priceMultiplier: item.artistPriceMultiplier,
              status: item.artistStatus
            });
          }
        });
      } else {
        this.activeCollection.items = [];
      }

      if (this.activeCollection.items && this.activeCollection.items.length) {
        this.activeCollection.items.sort((a, b) => b.name - a.name);
      }

      /*this.activeCollection.startDate = { jsdate: new Date(this.activeCollection.startDate) };
      this.activeCollection.endDate = { jsdate: new Date(this.activeCollection.endDate) };*/
      this.activeCollectionId = data.resolvedData._id;
    });

    this.editCollectionForm = this.formBuilder.group({
      editCollectionId: new FormControl('', [
        Validators.required,
        Validators.maxLength(100)
      ]),
      editCollectionOwner: new FormControl('', [
        Validators.required,
        Validators.maxLength(100)
      ]),
      editCollectionName: new FormControl('', [
        Validators.required,
        Validators.maxLength(100)
      ]),
      editCollectionDescription: new FormControl('', [
        Validators.maxLength(2048)
      ]),
      editCollectionType: new FormControl('', [
        Validators.maxLength(2048)
      ]),
      editCollectionLocation: new FormControl('', [
        Validators.maxLength(30)
      ]),
      editCollectionNotes: new FormControl('', [
        Validators.maxLength(2048)
      ]),
      editCollectionComments: new FormControl('', [
        Validators.maxLength(2048)
      ]),
      editCollectionTags: new FormControl('', [
        Validators.maxLength(2048)
      ]),
      editCollectionVisibility: new FormControl('', [
        Validators.maxLength(10)
      ])
      /*editCollectionAttachments: new FormControl('', [
        Validators.maxLength(100)
      ])*/
    });

    this.searchArtworkForm = this.formBuilder.group({
      searchArtworkTerm: new FormControl('', [
        Validators.required,
        Validators.minLength(3),
        Validators.maxLength(50)
      ])
    });

    this.filesToUpload = [];
    this.uploadInput = new EventEmitter<UploadInput>();
    this.humanizeBytes = humanizeBytes;
  }

  ngOnInit() {
    if ([null, undefined].includes(this.activeCollectionId)) {
      this.activeCollectionId = this.collectionService.getCurrentCollection();
    }

    this.getUser();
  }

  getAccountData() {
    const getObject = {
      accountId: this.activeAccount,
      requester: 'collection',
      sortingKey: '_id',
      sortingOrder: 'desc',
      skipQuery: 0,
      limitQuery: null,
      contentTypes: ['variable']
    };

    this.accountService.getAccountData(getObject).subscribe(
      data => {
        this.variables = data.variables ? data.variables : [];
      },
      () => {
        UIkit.notification({
          message: 'Error fetching artwork',
          status: 'danger',
          pos: 'top-right',
          timeout: 1500
        });
      },
      () => {
        this.isLoading = false;
      }
    );
  }

  getUser() {
    this.activeService.getActiveData().subscribe(
      data => {
        this.activeAccount = data.account.id;
        this.activeUser = data.user.id;
        this.userService.getUser(data.user).subscribe(
          data => {
            this.user = data;
            /*if (this.user.account[0]) {
              this.user.role = this.user.account[0].role;
            }*/
            this.getUsersAccounts();
            this.getAccountData();
          },
          () => {
            UIkit.notification({
              message: 'Error fetching user information',
              status: 'danger',
              pos: 'top-right',
              timeout: 1500
            });
          }
        );
      }
    );

    this.activeService.loadingStatusChange.subscribe(
      loadingStatus => {
        this.activeServiceIsLoading = loadingStatus;
      }
    );
  }

  getUsersAccounts() {
    this.accountService.getUserAccounts(this.user).subscribe(
      data => {
        this.accounts = data;
      },
      () => {
        UIkit.notification({
          message: 'Error fetching accounts',
          status: 'danger',
          pos: 'top-right',
          timeout: 1500
        });
      },
      () => this.isLoading = false
    );
  }

  editCollection(silent?: Boolean) {
    const editCollectionObject = {
      id: this.editCollectionForm.value.editCollectionId,
      owner: this.editCollectionForm.value.editCollectionOwner,
      name: this.editCollectionForm.value.editCollectionName,
      description: this.editCollectionForm.value.editCollectionDescription,
      items: this.activeCollection.items,
      /*startDate: moment.utc(this.editCollectionForm.value.editCollectionStartDate.jsdate).format(),
      endDate: moment.utc(this.editCollectionForm.value.editCollectionEndDate.jsdate).format(),*/
      type: this.editCollectionForm.value.editCollectionType,
      location: this.editCollectionForm.value.editCollectionLocation,
      notes: this.editCollectionForm.value.editCollectionNotes,
      comments: this.editCollectionForm.value.editCollectionComments,
      tags: this.editCollectionForm.value.editCollectionTags,
      visibility: this.editCollectionForm.value.editCollectionVisibility,
      attachments: this.activeCollection.attachments
    };
    this.collectionService.editCollection(editCollectionObject).subscribe(
      res => {
        if (silent !== true) {
          UIkit.notification({
            message: 'Saved successfully',
            status: 'success',
            pos: 'top-right',
            timeout: 1500
          });
        }
      },
      error => {
        if (silent !== true) {
          UIkit.notification({
            message: 'Saving failed',
            status: 'danger',
            pos: 'top-right',
            timeout: 1500
          });
        }
      }
    );
  }

  searchArtwork() {
    /*this.isLoading = true;*/
    const searchObject = {
      searchArtworkAccountId: this.activeAccount,
      searchArtworkUserId: this.activeUser,
      searchArtworkTerm: this.searchArtworkForm.value.searchArtworkTerm
    };
    this.searchService.searchArtwork(searchObject).subscribe(
      res => {
        if (res.length) {
          if (this.searchArtworkResults) {
            this.searchArtworkResults.length = 0;
          }
          res.forEach(artwork => {
            artwork.allResultType = 'artwork';
            this.searchArtworkResults.push(artwork);
          });
          /*this.isLoading = false;*/
          this.searchResultsFound = true;
          document.getElementById('search-artwork-results-container').classList.add('active');



          UIkit.notification({
            message: 'Search matched ' + this.searchArtworkResults.length + ' results',
            status: 'success',
            pos: 'top-right',
            timeout: 1500
          });
        } else {
          if (this.searchArtworkResults) {
            this.searchArtworkResults.length = 0;
          }
          UIkit.notification({
            message: 'No search results',
            status: 'danger',
            pos: 'top-right',
            timeout: 1500
          });
        }
      },
      error => {
        if (this.searchArtworkResults) {
          this.searchArtworkResults.length = 0;
        }
        UIkit.notification({
          message: 'No search results',
          status: 'danger',
          pos: 'top-right',
          timeout: 1500
        });
      }
    );
  }

  emptySearch(): void {
    /*document.getElementById('search-artwork-results-container').classList.remove('active');
    let element: HTMLElement = document.getElementsByClassName('uk-navbar-toggle uk-close')[1] as HTMLElement;
    element.click();*/
    setTimeout(() => {
      this.searchResultsFound = false;
      if (this.searchArtworkResults) {
        this.searchArtworkResults.length = 0;
      }
      if (this.selectedArtworks) {
        this.selectedArtworks.length = 0;
      }
      this.searchArtworkForm.patchValue({ searchArtworkTerm: ''});
    }, 300);
  }

  selectCollectionArtwork(artwork): void {
    this.selectedArtworks.indexOf(artwork) === -1 ?
      this.selectedArtworks.push(artwork) : this.selectedArtworks.splice(this.selectedArtworks.indexOf(artwork), 1);
  }

  addArtworksToCollection(): void {
    this.selectedArtworks.map((item: any) => {
      const itemFound = this.activeCollection.items.find((activeCollectionItem: any) => {
        return activeCollectionItem._id === item._id;
      });
      if (itemFound === undefined) {
        this.activeCollection.items.push(item);
      }
      const artistFound = this.artists.find((artist: any) => {
        return artist._id === item.artist;
      });
      if (artistFound === undefined) {
        this.artists.push({
          _id: item.artist,
          name: item.artistName,
          priceMultiplier: item.artistPriceMultiplier,
          status: item.artistStatus
        });
      }
    });

    this.editCollection(true);
    this.emptySearch();
  }

  removeArtworkFromCollection(item): void {
    this.activeCollection.items.splice(this.activeCollection.items.indexOf(item), 1);
    const artistToSplice = this.artists.find((artist: any) => {
      return artist._id === item.artist;
    });
    this.artists.splice(this.artists.indexOf(artistToSplice), 1);
  }

  openSlideshow($event): void {
    $event.preventDefault();
    UIkit.lightboxPanel({
    /* Ei tarvitse, jos muistaa käyttää $event.preventDefaultia
      autoplay: true,
      autoplayInterval: 5000,
      delayControls: 1000,*/
      items: this.activeCollection.items.map(item => {
        let itemImage;
        if (item.coverImage) {
          itemImage = '/api/image/' + item.coverImage;
        } else if (item.images[0]) {
          itemImage = '/api/image/' + item.images[0].fileId;
        } else {
          itemImage = '/api/image/artbuddy-logo-placeholder.svg'
        }

        return {
          source: itemImage,
          caption: item.name
        }
      })
    }).show();
  }

  openArtist(artistId): void {
    this.router.navigateByUrl('/artist/' + artistId);
  }

  onAttachmentsUploadOutput(output: UploadOutput): void {
    const properFile = output.file && output.file.name && output.file.name.length > 0;

    if (output.type === 'allAddedToQueue') {
      const event: UploadInput = {
        type: 'uploadAll',
        url: '/api/file',
        method: 'POST',
        includeWebKitFormBoundary: true
      };
      this.uploadInput.emit(event);
    } else if (output.type === 'addedToQueue' && properFile) {
      if (this.filesToUpload.indexOf(output.file) == -1) {
        this.filesToUpload.push(output.file);
      }
    } else if (output.type === 'uploading' && properFile) {
      if (!this.activeCollection['attachments'].some(image => image.placeholderId === output.file.id)) {
        this.uploadLoading = true;
        this.activeService.increasePendingRequests();
        this.activeCollection['attachments'].push({ placeholderId: output.file.id });
        const index = this.filesToUpload.findIndex(file => file.id === output.file.id);
        this.filesToUpload[index] = output.file;
      }
    } else if (output.type === 'removed') {
      // remove file from array when removed
      this.filesToUpload = this.filesToUpload.filter((file: UploadFile) => file !== output.file);
    } else if (output.type === 'dragOver') {
      this.dragOver = true;
    } else if (output.type === 'dragOut') {
      this.dragOver = false;
    } else if (output.type === 'drop') {
      this.dragOver = false;
    } else if (output.type === 'removedAll') {
      this.filesToUpload.length = 0;
    } else if (output.type === 'done') {
      const fileObject = {
        itemId: this.activeCollectionId,
        fileId: output.file.response,
        name: output.file.name,
        contentType: output.file.type
      };
      this.removeFile(output.file.id);
      const indexToReplace = this.activeCollection['attachments'].findIndex(file => file.placeholderId === output.file.id);
      this.activeCollection['attachments'][indexToReplace] = fileObject;
      this.activeService.decreasePendingRequests();
      this.uploadLoading = false;

      /*this.activeCollection.images.push({
        /!*contentType: output.file.response.mimetype,
        filename: output.file.response.filename,*!/
        fileId: output.file.response.id
      });*/
      /*});*/
    }
  }

  startUpload(): void {
    const event: UploadInput = {
      type: 'uploadAll',
      url: '/api/file',
      method: 'POST',
      includeWebKitFormBoundary: true
    };
    this.uploadInput.emit(event);
  }

  cancelUpload(id: string): void {
    this.uploadInput.emit({ type: 'cancel', id: id });
  }

  removeFile(id: string): void {
    this.uploadInput.emit({ type: 'remove', id: id });
  }

  openAttachment(attachment) {
    this.itemService.openAttachment(this.activeAccount, attachment).then((response: any) => {
      if (response.url) {
        window.open(response.url);
      } else {
        UIkit.notification({
          message: 'Error fetching data',
          status: 'danger',
          pos: 'top-right',
          timeout: 1500
        });
      }
    });
  }

  removeAttachment(attachment) {
    const attachmentObject = {
      documentType: 'collection',
      itemId: this.activeCollectionId,
      fileId: attachment.fileId
    };

    this.fileService.removeFile(attachmentObject).subscribe(
      res => {
        const attachmentToSplice = this.activeCollection.attachments.find((accountAttachment: any) => {
          return attachment.fileId === accountAttachment.fileId;
        });
        this.activeCollection.attachments.splice(this.activeCollection.attachments.indexOf(attachmentToSplice), 1);
        UIkit.notification({
          message: 'File removed successfully',
          status: 'success',
          pos: 'top-right',
          timeout: 1500
        });
      },
      error => {
        UIkit.notification({
          message: 'Removing failed',
          status: 'danger',
          pos: 'top-right',
          timeout: 1500
        });
      }
    );
  }

  openPrintList(): void {
    this.createListModalWindow = window.document.getElementById('createList');
    UIkit.modal(this.createListModalWindow).show();
  }

  printList(): void {
    const resultArray = this.activeCollection.items;

    const printListObject = {
      items: [],
      notes: {},
      pricing: {}
    };

    if (resultArray.length) {
      resultArray.forEach(item => {
        const itemName = item.name;
        let itemImage = null;

        if (item.coverImage) {
          itemImage = item.coverImage;
        } else if (item.images && item.images.length) {
          itemImage = item.images[0].fileId;
        }

        const itemArtistName = item.artistName;
        const itemCreated = moment.utc(item.created).isValid() ? moment.utc(item.created).locale('fi').format('YYYY') : null;
        const itemTechnique = item.technique;

        /* Näitä tietoja ei varmaankaan tarvita */
        let itemSize = '';
        if (item.height) {
          itemSize += item.height;
        }
        if (item.width) {
          if (item.height) {
            itemSize += ' x ';
          }
          itemSize += item.width;
        }
        if (item.depth) {
          if (item.height) {
            itemSize += ' x ';
          }
          itemSize += item.depth;
        }

        const itemAvailability = this.shorten.transform(item.availability, 50, '...');

        let listObject: any = {};
        listObject = {
          'id': item._id,
          'account': this.activeAccount,
          'Artist': itemArtistName,
          'Item name': itemName,
          "showPrice": true, // item.showPrice === true
        };

        if (itemCreated) {
          listObject['Item created'] = itemCreated;
        }

        if (itemTechnique) {
          listObject['Item technique'] = itemTechnique;
        }

        if (itemSize) {
          listObject['Item size'] = itemSize + ' cm';
        }

        if (!item.hidePrice) {
          if (item.price.price) {
            listObject['Item price'] = item.price.price + ' ' + (item.price.currency ? item.price.currency : '');
          }
        }

        /*if (itemAvailability) {
          listObject['Item availability'] = itemAvailability;
        }*/

        if (item.listNotes) {
          listObject['Item notes'] = item.listNotes;
        }

        if (itemImage) {
          listObject.Image = itemImage;
        }

        printListObject.items.push(listObject);
      });
    }

    /*bidObject.pricing = {
      total: this.getItemsTotalPrice(),
      discount: this.getDiscountedPrice(),
      grandTotal: this.getItemsDiscountedPrice(),
      vatTotal: this.getItemsVAT().toFixed(2),
      currency: this.tradingCurrency
    };*/

    this.fileService.getPDF('Collection-list', printListObject);
  }

  updateShowPrice(item: any, event) {
    const changedItemIndex = this.activeCollection.items.findIndex(collectionItem => collectionItem._id === item._id);
    this.activeCollection.items[changedItemIndex].hidePrice = event.currentTarget.checked;
  }

  openAdvancedSearch(): void {
    this.showAdvancedArtworkSearchModalWindow = true;
    setTimeout(() => {
      this.advancedArtworkSearchModalWindow = window.document.getElementById('advancedArtworkSearch');
      UIkit.modal(this.advancedArtworkSearchModalWindow).show();
    }, 500);
  }

  setArtworkSearchResults(searchResult: Array<any>) {
    searchResult.forEach(item => this.selectedArtworks.push(item));
    this.addArtworksToCollection();
  }

  getCharactersLimitError(fieldName: string) {
    if (!fieldName || !this.editCollectionForm.get(fieldName).value ) {
      return ''
    }

    const charactersOverLimit: number = this.editCollectionForm.get(fieldName).value.length - this.editCollectionForm.get(fieldName).errors.maxlength.requiredLength;

    return `Field length is ${ charactersOverLimit } characters over limit`;
  }

  getCharactersLeft(fieldName: string, charactersLimit: number) {
    if (!fieldName || !this.editCollectionForm.get(fieldName).value ) {
      return ''
    }

    const fieldLength: number = this.editCollectionForm.get(fieldName).value.length;
    const charactersOverLimit: number = fieldLength - charactersLimit;

    if (charactersOverLimit >= 0) { return `${ charactersLimit } / ${ charactersLimit }` }

    return `${ fieldLength } / ${ charactersLimit }`;
  }
}
