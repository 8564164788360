import {Component, Inject, forwardRef, Input, OnInit, Optional} from '@angular/core';
import { BaseWidget, NgAisInstantSearch } from 'angular-instantsearch';
import { connectMenu } from 'instantsearch.js/es/connectors';
import {NgAisIndex} from 'angular-instantsearch';

@Component({
  selector: 'app-ais-menu-select',
  template: `<div class="uk-margin-small" *ngIf="state.items && state.items.length">
    <label class="uk-form-label header-row" [hidden]="!title" *ngIf="!hideToggleShowMore">
      <div class="label-container">{{ title }}</div>
      <div class="action-container">
        <button class="uk-button uk-button-text capitalize" (click)="state.toggleShowMore()">
          <span *ngIf="!state.isShowingMore">Show all</span>
          <span *ngIf="state.isShowingMore">Show less</span>
        </button>
      </div>
    </label>
    <div class="uk-form-controls">
      <select class="uk-select" (change)="state.refine($event.target.value)">
        <!--<option value="">Please select ...</option>
        <option *ngFor="let item of state.items" [value]="item.value" [selected]="item.isRefined">{{ item.label | ucfirst }}</option>-->
        <option value="" *ngIf="!hideMore">{{ moreOptions }}</option>
        <option *ngFor="let item of state.items" [value]="item.value" [selected]="item.isRefined">{{ item.label | ucfirst }}</option>
      </select>
    </div>
  </div>`
})
export class SearchAisMenuComponent extends BaseWidget implements OnInit {
  @Input() title: string;
  @Input() attribute: string;
  @Input() sortName: string;
  @Input() sortDescription: string;
  @Input() defaultValue: string;
  @Input() hideMore: boolean;
  @Input() hideToggleShowMore = false;
  @Input() moreOptions = 'Please select ...';

  public state: {
    items: { label: string; value: string }[];
    refine: Function;
    createURL: Function;
    isShowingMore: boolean;
    canToggleShowMore: boolean;
    toggleShowMore: Function;
    widgetParams: object;
    /*currentRefinement: 'artist'*/
  };

  constructor(
    @Inject(forwardRef(() => NgAisIndex))
    @Optional()
    public parentIndex: NgAisIndex,
    @Inject(forwardRef(() => NgAisInstantSearch))
    public instantSearchInstance: NgAisInstantSearch
  ) {
    super('MenuSelect');
  }

  hasRefinements(currentState: any): boolean {
    const values = [];
    Object.keys(currentState.instantSearchInstance.helper.state.disjunctiveFacetsRefinements).forEach(key => {
      currentState.instantSearchInstance.helper.state.disjunctiveFacetsRefinements[key].forEach(value => values.push(value));
    });
    Object.keys(currentState.instantSearchInstance.helper.state.hierarchicalFacetsRefinements).forEach(key => {
      currentState.instantSearchInstance.helper.state.hierarchicalFacetsRefinements[key].forEach(value => {
        if (value !== this.defaultValue) {
          values.push(value);
        }
      });
    });

    return values.length === 0;
  }

  public ngOnInit() {
    if (this.attribute) {
      this.createWidget(connectMenu,
        {
          attribute: this.attribute,
          sortBy: [this.sortName + ':' + this.sortDescription],
          autoHideContainer: false,
          showMore: true,
          limit: 5,
          showMoreLimit: 9999
          /*transformItems: (items) => {
            return items.filter(item => {
            // Tämä ei toimi, koska item sisältää { label: "painting"
            // value: "painting" }, mutta ei esim. { _type: 'artwork' }
              console.log(item._type, this.attribute);
              return item._type === this.attribute;
            });
          }*/
        }
      );
      super.ngOnInit();
      setTimeout(() => {
        const currentState: any = this.state;
        if (currentState && currentState.widgetParams && currentState.widgetParams.attribute === 'contentType' && this.defaultValue) {
          const cleanSearchState = (
            currentState.instantSearchInstance.helper.state.query === '' &&
            this.hasRefinements(currentState)
          );

          if (cleanSearchState) {
            this.state.refine(this.defaultValue);
          }
        }
      }, 0);
    }
  }
}
