import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import algoliasearch from 'algoliasearch/lite';
import {environment} from '../../../environments/environment';
import {ActiveService} from '../../services/active.service';
import {Router} from '@angular/router';

@Component({
  selector: 'app-search-content',
  templateUrl: './search.component.html',
  styleUrls: ['./search.component.scss']
})
export class SearchComponent implements OnInit {
  public algolia: any = {};
  public algoliaSearchParameters: any;
  @Input() contentType: string;
  @Input() fields: Array<string>;
  @Output() clearSearch: EventEmitter<any> = new EventEmitter();
  public searchResultsVisible = false;

  constructor(
    private router: Router,
    private activeService: ActiveService,
  ) {}

  ngOnInit(): void {
    this.activeService.getActiveData().subscribe(
      data => {
        this.algolia = {
          indexName: data.account.id,
          searchClient: algoliasearch(
            environment.ALGOLIASEARCH_APPLICATION_ID,
            environment.ALGOLIASEARCH_API_KEY,
          ),
          routing: true,
          // searchFunction(helper) {
          //   console.log(helper);
          //   helper.search();
          // },
        };

        this.algoliaSearchParameters = {
          hitsPerPage: 100,
          distinct: true,
          facetsRefinements: {
            contentType: [this.contentType]
          },
        };
      }
    );
  }

  openAdvancedSearchResult(resultItem: any): void {
    const clearButton = document.getElementById('clearAdvancedSearch');
    if (clearButton) {
      clearButton.click();
    }

    this.router.navigateByUrl(`/${resultItem.contentType}/${resultItem._id}`);
  }

  showResults(event: boolean) {
    const searchresults = document.getElementById('searchresults');
    if (searchresults) {
      searchresults.scrollTop = 0;
    }

    event ? this.searchResultsVisible = true : this.searchResultsVisible = false;
  }

  hideSearchResult(): void {
    this.clearSearch.emit();
    this.searchResultsVisible = false;
  }

  filterHiddenDocuments(hits: any): any {
    if (!hits || hits.length === 0) {
      return [];
    }

    return hits.filter((hit: any) => {
      return hit.hidden !== true;
    });
  }
}
