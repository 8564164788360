<div class="uk-child-width-expand" uk-grid>
  <div class="uk-flex uk-flex-center uk-margin uk-margin-large-top uk-text-left uk-padding">
    <div class="uk-width-3-5@m uk-padding-small">
      <h1>Terms and conditions (T&C)</h1>
      <p>Bridgi Oy, Finnish Business ID 2790319-7</p>

      <div class="section uk-margin-large-top">
        <h3 class="uk-margin-remove-top">Executive summary</h3>
        <p>Artbuddy is a web based, software-as-a-service tool for managing artworks and related datasets such as exhibitions, collections, artists, customers and offers. These terms and conditions determine how you can legally use the Artbuddy service, how personal data is managed, and what your rights and obligations are.</p>
        <p>Section 1 specifically concerns your relationship with us, Bridgi Oy when you register for the service and use the service.</p>
        <p>Section 2 concerns more directly your artworks, and your legal relationship with your own clients/artists/collectors and so forth, that you manage in the Artbuddy service.</p>
      </div>

      <div class="section uk-margin-large-top">
        <h2>Section 1</h2>
        <h3 class="uk-margin-remove-top">General terms and conditions</h3>
        <p>You (licensee) agree to abide by these terms and conditions that are part of your contract with Bridgi Oy. Violation of these rules may result in the immediate cancellation of your service without refunds. You must be 16 years of age to use this service.</p>
        <p>We (Bridgi Oy/licensor) grant you a non-exclusive, non-transferable, non-sublicensable, revocable license to use the Artbuddy service. You may use the service for your internal activities, for example within a gallery or museum, but not offer this software as a service to third parties, without prior approval from us.</p>
        <p>We reserve the right to change the terms of service at any time, for any reason. For purchases, the terms applicable at the time of purchase shall apply.</p>
        <p>We reserve the right to change the service. Typically but not always, such changes affect the production environment of the service and not the contents or service level of the service. Changes may be necessary for security reasons or due to legal requirements.</p>
        <p>You will be notified of any material changes to the service or the terms of service, and a good time in advance, both as deemed appropriate solely by us.</p>
        <p>We reserve the right to cancel or to refuse to provide service to anyone at any time, for any reason.</p>
      </div>

      <div class="section uk-margin-large-top">
        <h3 class="uk-margin-remove-top">Your subscription and using the service</h3>
        <p>The price of subscriptions is indicated on the Artbuddy website. The currency used for prices and invoicing is the Euro. Value added tax shall be added to the price when applicable. We reserve the right to adjust the recurring price of the service, and will notify you at least 90 days in advance. In case of price change you have the right to terminate the service by notifying us 30 days before the effective date of the change. This procedure mainly applies to situations where you have selected automatic renewal of your subscription. For services that are not specified in this contract, we reserve the right to invoice actual costs incurred or our standard hourly fee.</p>
        <p>To use the service you need to register for the service and create your own profile. We reserve the right to remove any unused or misleading profile, or profile that infringes on the intellectual property of others, and to transfer such profiles to the rightsholder.</p>
        <p>These T&C’s are part of the contract you enter into when you subscribe to the free trial version or full service, by giving your credit card details. You are sent a confirmation and invoice of your subscription to the email address provided, and the same confirmation and invoice will be available in your profile. Other payment options must be agreed beforehand with us. In all cases, unless otherwise agreed, invoices must be paid within 7 days net. If the invoice is addressed to a third party upon your request, are you still fully responsible for performance. We reserve the right to check your credit rating if payment is not made up front.</p>
        <p>If you are a consumer, you have the right to cancel your subscription within 14 days of from the time at which the contract concerning the digital content was finalised. The cancellation can easily be done on the Artbuddy website, but a cancellation form is also attached to these T&C’s that can be sent by post.</p>
        <p>The Artbuddy service, its visual elements and related documentation are the protected intellectual property of Brigdi Oy, including copyright and related rights, as well as patents, trademarks and any other registered or unregistered intellectual property.</p>
        <p>You are not allowed to crawl, scrape, or otherwise cache any content from Artbuddy including but not limited to user profiles and photos. You are not allowed to modify, adapt or hack Artbuddy or change another website so as to wrongfully imply that it is associated with Artbuddy. You are not allowed to access Artbuddy’s API by any other means other than the Artbuddy application itself. You are not allowed to use web URLs in your name without prior written consent from us. You are not allowed to transmit viruses or any other code of a destructive nature.</p>
      </div>

      <div class="section uk-margin-large-top">
        <h3 class="uk-margin-remove-top">Limited warranty, liabilities and dispute resolution</h3>
        <p>We promise to maintain this service available at a 99% monthly service level, and for it to include functionalities as described on the Artbuddy homepage and in the technical documentation. In case of material breach of this quality promise, you may cancel your subscription and the payments made for those months when or after you notified us of the breach, will be returned to you, as the entire compensation for such breach.</p>
        <p>Unless specified in this contract, all express or implied conditions, representations and warranties, including any implied warranty, fitness for a particular purpose or non-infringement are disclaimed, except to the extent these disclaimers are held to be legally invalid.</p>
        <p>We are not liable for any delay, damage or impediment beyond our control, that we could not have been reasonably foreseen. We are liable for intent and gross negligence. We are not liable for loss or alteration of data, beyond that we back up data at least XX days.  For these liabilities and in any other case of liability, our liability shall be limited to 20.000 euro per instance of damage, for a total maximum of 35.000 euro.</p>
        <p>Neither party shall be liable for any indirect or consequential damage, such as loss of profits or damage due to decrease or interruption in turnover.</p>
        <p>In case of disputes that cannot be settled between the parties through negotiations, a consumer may refer the issue to the European online dispute resolution platform complaints board (https://ec.europa.eu/consumers/odr/ ). Otherwise, and for non-consumers, the district court of Helsinki, Finland shall have jurisdiction.</p>
        <p>Any question not covered by this contract will be governed by the law of Finland. If you are a consumer resident in the EU or the United States, these terms cannot be of detriment to the mandatory consumer protection law of your country.</p>
        <p>Any changes to this contract need to be made in writing. If an individual term in this contract is invalid, other terms are unaffected.</p>
        <p>The parties shall keep secret all confidential information that becomes known to them in the performance of this contract, even after the termination of this contract.</p>
      </div>

      <div class="section uk-margin-large-top">
        <h2>Section 2</h2>
        <h3 class="uk-margin-remove-top">Terms and conditions concerning licensee content and use of content</h3>
        <p>You may use the service only for legal, art related activities. You are solely responsible for the way you use the service, and for the content you add to the service.</p>
        <p>Bridgi Oy takes no responsibility for the authenticity of artworks. While we are not obliged by law to monitor artworks, we do not tolerate any replicas that are not clearly announced as such (forgeries).</p>
        <p>We take no responsibility for ownership rights, intellectual property rights or any other rights for artworks managed in the service.</p>
        <p>We take no responsibility for rights concerning personal data of others that the licensee has uploaded or entered into the service, and that the licensee manages in the service. The privacy policy includes important information on your rights and duties as a data controller, that are part of this contract.</p>
        <p>While we are not obliged to monitor content, we reserve the right to do so.</p>
        <p>Other users and the public at large may notify us about any forgeries in the service or any other infringements of rights. Such notifications must be presented to us in writing, and we reserve the right not to examine anonymous notifications, as we are not obliged by law to do so. We reserve the right tell the affected licensee of the identity of the party that notified us, so that the affected licensee may exercise his or her own rights.</p>
        <p>We reserve the right to ask the licensee for evidence of the legality of the use case in question, and the right to suspend or cancel the subscription at any time. We reserve the right to add any disclaimer or warning deemed appropriate by us, to content that can be made public in the service.</p>
        <p>You should not use the service for any content that is against the Artbuddy brand or is grossly indecent. You may not use the service for any content that is unlawful. While we do not have the obligation to monitor content, we reserve the right, as an alternative to suspension or termination of the service, to remove any content from the service and without any compensation. You will be notified of such removal and the content will be returned to you, unless prohibited by law.</p>
        <p>The licensee is liable for any civil- or criminal proceedings, sanctions or judgments that may result from licensee content that infringes the rights of others and other unlawful use of licensee content. The licensee will defend at its own expense and indemnify Bridgi Oy against any claims resulting from licensee content or use of licensee content.</p>
      </div>
    </div>
  </div>
</div>
