import {Component, EventEmitter, OnInit, ViewChild} from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import {FormGroup, FormControl, Validators, FormBuilder, ValidationErrors} from '@angular/forms';
import { AuthService } from '../../services/auth.service';
import { ActiveService } from '../../services/active.service';
import { UserService } from '../../services/user.service';
import { AccountService } from '../../services/account.service';
import { FileService } from '../../services/file.service';
import { ActorService } from '../../services/actor.service';
import { UploadOutput, UploadInput, UploadFile, humanizeBytes, UploaderOptions } from 'ngx-uploader';
declare var UIkit: any;

@Component({
  selector: 'app-all-clients',
  templateUrl: './clients.component.html',
  styleUrls: ['./clients.component.scss']
})
export class ClientsComponent implements OnInit {
  public user: any = {};
  public actorType: string;
  public clientType: string;
  public activeAccount: string;
  public clients: Array<Object> = [];
  public allClients: Array<Object> = [];
  public variables: Array<string> = [];
  public isLoading = true;
  public addClientForm: FormGroup;
  public addNewClientModalWindow: any;
  public getFilteredCSVModalWindow: any;
  public activeClientClientGroups: Array<any> = [];
  public selectedClientGroups: Array<any> = [];
  public activeClientLanguages: Array<any> = [];
  public selectedContactMethods: Array<any> = [];
  public activeClientContactMethods: Array<any> = [];
  public selectedLanguages: Array<any> = [];
  public allLanguages: Array<any> = [];
  public allClientGroups: Array<any> = [];
  public allContactMethods: Array<any> = [];
  public createCSVSelectedLanguages: Array<any> = [];
  public createCSVSelectedClientGroups: Array<any> = [];
  public createCSVSelectedContactMethods: Array<any> = [];
  public sortingKey: string;
  public sortAscending = false;
  public currentPage = 1;
  public limitQuery = 10;
  public pageCount = 1;
  public pages: Array<any> = [];
  public numberWithDecimal = new RegExp(/^[0-9]+(\.[0-9]{1,2})?$/);

  constructor(
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private formBuilder: FormBuilder,
    private auth: AuthService,
    private activeService: ActiveService,
    private userService: UserService,
    private accountService: AccountService,
    private actorService: ActorService,
    private fileService: FileService
  ) {
    this.activatedRoute.data.subscribe(
      data => {
        this.activeAccount = data.resolvedData.account.id;
        this.user = data.resolvedData.user.id;
      },
      error => {
        UIkit.notification({
          message: 'Error fetching user information',
          status: 'danger',
          pos: 'top-right',
          timeout: 1500
        });
      }
    );

    this.addClientForm = this.formBuilder.group(
      {
      addClientActorType: new FormControl('', [
        Validators.required,
        Validators.maxLength(50)
      ]),
      addClientOwner: new FormControl('', [
        Validators.required,
        Validators.maxLength(50)
      ]),
      addClientClientCode: new FormControl('', [
        Validators.maxLength(100)
      ]),
      addClientBillingInfo: new FormControl('', [
        Validators.maxLength(2048)
      ]),
      addClientDescription: new FormControl('', [
        Validators.maxLength(2048)
      ]),
      addClientOrganisation: new FormControl('', [
        Validators.maxLength(100)
      ]),
      addClientGroup: new FormControl('', [
        Validators.maxLength(100)
      ]),
      addClientLanguages: new FormControl('', [
        Validators.maxLength(100)
      ]),
      addClientContactMethod: new FormControl('', [
        Validators.maxLength(30)
      ]),
      addClientStatus: new FormControl('', [
        Validators.maxLength(30)
      ]),
      addClientName: new FormControl('', [
        Validators.required,
        Validators.maxLength(100)
      ]),
      addClientLastName: new FormControl('', [
        Validators.maxLength(100)
      ]),
      /*addClientSSN: new FormControl('', [
        Validators.maxLength(100)
      ]),*/
      addClientBID: new FormControl('', [
        Validators.maxLength(100)
      ]),
      addClientStreetAddress: new FormControl('', [
        Validators.maxLength(100)
      ]),
      addClientCity: new FormControl('', [
        Validators.maxLength(30)
      ]),
      addClientState: new FormControl('', [
        Validators.maxLength(30)
      ]),
      addClientZip: new FormControl('', [
        Validators.maxLength(30),
        Validators.pattern(this.numberWithDecimal)
      ]),
      addClientCountry: new FormControl('', [
        Validators.maxLength(30)
      ]),
      addClientPhone: new FormControl('', [
        Validators.maxLength(30),
        // Validators.pattern(this.numberWithDecimal)
      ]),
      addClientEmail: new FormControl('', [
        Validators.maxLength(100)
      ]),
      addClientVisitingStreetAddress: new FormControl('', [
        Validators.maxLength(100)
      ]),
      addClientVisitingCity: new FormControl('', [
        Validators.maxLength(30)
      ]),
      addClientVisitingState: new FormControl('', [
        Validators.maxLength(30)
      ]),
      addClientVisitingZip: new FormControl('', [
        Validators.maxLength(10),
        Validators.pattern(this.numberWithDecimal)
      ]),
      addClientVisitingCountry: new FormControl('', [
        Validators.maxLength(30)
      ]),
      addClientBillingPostalCode: new FormControl('', [
        Validators.maxLength(30),
        Validators.pattern(this.numberWithDecimal)
      ]),
      addClientBillingElectronicInvoiceAddress: new FormControl('', [
        Validators.maxLength(100)
      ]),
      addClientBillingEmail: new FormControl('', [
        Validators.maxLength(30)
      ]),
      addClientContactPersonName: new FormControl('', [
        Validators.maxLength(100)
      ]),
      addClientContactPersonLastName: new FormControl('', [
        Validators.maxLength(30)
      ]),
      addClientContactPersonRole: new FormControl('', [
        Validators.maxLength(30)
      ]),
      addClientContactPersonEmail: new FormControl('', [
        Validators.maxLength(30)
      ]),
      addClientContactPersonPhone: new FormControl('', [
        Validators.maxLength(30),
        // Validators.pattern(this.numberWithDecimal)
      ]),
      addClientAttachments: new FormControl('', [
        Validators.maxLength(100)
      ]),
      addClientTags: new FormControl('', [
        Validators.maxLength(2048)
      ]),
      addClientNotes: new FormControl('', [
        Validators.maxLength(2048)
      ]),
      addClientComments: new FormControl('', [
        Validators.maxLength(2048)
      ]),
      addClientVisibility: new FormControl('', [
        Validators.maxLength(10)
      ]),
      addClientLinks: new FormControl('', [
        Validators.maxLength(2048)
      ])
    });
  }

  ngOnInit() {
    this.isLoading = true;
    this.actorType = 'Client';
    /*this.getUser();*/
    this.getAccountClients();
  }

  /*getUser() {
    this.userService.getUser(this.auth.currentUser).subscribe(
      data => {
        this.user = data;
        if (this.user.account[0]) {
          this.user.role = this.user.account[0].role;
        }
      },
      error => {
        UIkit.notification({
          message: 'Error fetching user information',
          status: 'danger',
          pos: 'top-right',
          timeout: 1500
        });
      },
      /!*() => this.isLoading = false*!/
    );
  }*/

  getAccountData() {
    const getObject = {
      accountId: this.activeAccount,
      requester: 'clients',
      sortingKey: this.sortingKey ? this.sortingKey : '_id',
      sortingOrder: this.sortAscending ? 'asc' : 'desc',
      skipQuery: (this.currentPage - 1) * this.limitQuery,
      limitQuery: this.limitQuery,
      contentTypes: ['client', 'clientCount', 'variable']
    };

    this.accountService.getAccountData(getObject).subscribe(
      data => {
        this.variables = data.variables ? data.variables : [];
        this.clients = data.clients;
        this.allClients = data.allClients;
        this.pageCount = Math.ceil(data.clientsCount / this.limitQuery);
        if (this.pageCount > 0) {
          this.pages = Array(this.pageCount).fill(null);
        }
      },
      () => {
        UIkit.notification({
          message: 'Error fetching clients',
          status: 'danger',
          pos: 'top-right',
          timeout: 1500
        });
      },
      () => this.isLoading = false
    );
  }

  getAccountClients() {
    const getObject = {
      accountId: this.activeAccount,
      requester: 'clients',
      sortingKey: this.sortingKey ? this.sortingKey : '_id',
      sortingOrder: this.sortAscending ? 'asc' : 'desc',
      skipQuery: (this.currentPage - 1) * this.limitQuery,
      limitQuery: this.limitQuery,
      contentTypes: ['account', 'client', 'clientCount', 'variables']
    };

    this.accountService.getAccountData(getObject).subscribe(
      data => {
        this.variables = data.variables ? data.variables : [];
        this.clients = data.clients;
        this.allClients = data.allClients;

        this.allClients = this.allClients.map((client: any) => {
          if (client.clientGroup) {
            client.clientGroup = client.clientGroup.map((item: any) => {
              if (typeof item === 'object' && item.title) {
                return item;
              }

              if (typeof client.clientGroup[0] === 'object' && (!('title' in client.clientGroup[0]))) {

                const keys = Object.keys(client.clientGroup[0]);
                if (keys.length === 1 && keys[0] === '_id') {
                  return {
                    title: '',
                  };
                }

                return {
                  title:  Object.values(client.clientGroup[0]).join(''),
                };
              }
              return {
                title: item.title,
              };
            });
          }

          this.addToAllLanguages(client.languages);
          this.addToAllClientGroups(client.clientGroup);
          this.addToAllContactMethods(client.contactMethod);

          return client;
        });

        this.activeClientClientGroups = data.account.clientGroupTitles ?? [];
        this.activeClientContactMethods = data.account.contactMethodTitles ?? [];
        this.activeClientLanguages = data.account.languageTitles ?? [];

        this.pageCount = Math.ceil(data.clientsCount / this.limitQuery);
        if (this.pageCount > 0) {
          this.pages = Array(this.pageCount).fill(null);
        }
      },
      () => {
        UIkit.notification({
          message: 'Error fetching clients',
          status: 'danger',
          pos: 'top-right',
          timeout: 1500
        });
      },
      () => this.isLoading = false
    );
  }

  openAddNewPrivatePerson() {
    this.clientType = 'PrivatePerson';
    this.addNewClientModalWindow = window.document.getElementById('addNewClient');
    UIkit.modal(this.addNewClientModalWindow).show();
  }

  openAddNewOrganisation() {
    this.clientType = 'Organisation';
    this.addNewClientModalWindow = window.document.getElementById('addNewClient');
    UIkit.modal(this.addNewClientModalWindow).show();
  }

  clientGroupIsSelected(item: any): boolean {
    return this.selectedClientGroups.findIndex((clientGroup: any) => clientGroup.title === item.title) > -1;
  }

  setClientGroups(item: any): void {
    const foundIndex = this.selectedClientGroups.findIndex((clientGroup: any) => clientGroup.title === item.title);

    if (foundIndex > -1) {
      this.selectedClientGroups.splice(foundIndex, 1);
    } else {
      this.selectedClientGroups.push(item);
    }
  }

  contactMethodIsSelected(item: any): boolean {
    return this.selectedContactMethods.findIndex((clientGroup: any) => clientGroup.title === item.title) > -1;
  }

  setContactMethods(item: any): void {
    const foundIndex = this.selectedContactMethods.findIndex((clientGroup: any) => clientGroup.title === item.title);

    if (foundIndex > -1) {
      this.selectedContactMethods.splice(foundIndex, 1);
    } else {
      this.selectedContactMethods.push(item);
    }
  }

  languageIsSelected(item: any): boolean {
    return this.selectedLanguages.findIndex((language: any) => language.title === item.title) > -1;
  }

  setLanguages(item: any): void {
    const foundIndex = this.selectedLanguages.findIndex((language: any) => language.title === item.title);

    if (foundIndex > -1) {
      this.selectedLanguages.splice(foundIndex, 1);
    } else {
      this.selectedLanguages.push(item);
    }
  }

  addClient() {
    const addClientObject = {
      actorType: this.addClientForm.value.addClientActorType,
      clientType: this.clientType,
      owner: this.addClientForm.value.addClientOwner,
      clientCode: this.addClientForm.value.addClientClientCode,
      billingInfo: this.addClientForm.value.addClientBillingInfo,
      description: this.addClientForm.value.addClientDescription,
      organisation: this.addClientForm.value.addClientOrganisation,
      clientGroup: this.selectedClientGroups ?? [],
      languages: this.selectedLanguages ?? [],
      contactMethod: this.selectedContactMethods ?? [],
      status: this.addClientForm.value.addClientStatus,
      name: this.addClientForm.value.addClientName,
      lastName: this.addClientForm.value.addClientLastName,
      /*ssn: this.addClientForm.value.addClientSSN,*/
      bid: this.addClientForm.value.addClientBID,
      street: this.addClientForm.value.addClientStreetAddress,
      city: this.addClientForm.value.addClientCity,
      state: this.addClientForm.value.addClientState,
      zip: this.addClientForm.value.addClientZip,
      country: this.addClientForm.value.addClientCountry,
      phone: this.addClientForm.value.addClientPhone,
      email: this.addClientForm.value.addClientEmail,
      visitingStreet: this.addClientForm.value.addClientVisitingStreetAddress,
      visitingCity: this.addClientForm.value.addClientVisitingCity,
      visitingState: this.addClientForm.value.addClientVisitingState,
      visitingZip: this.addClientForm.value.addClientVisitingZip,
      visitingCountry: this.addClientForm.value.addClientVisitingCountry,
      billingPostalCode: this.addClientForm.value.addClientBillingPostalCode,
      billingElectronicInvoiceAddress: this.addClientForm.value.addClientBillingElectronicInvoiceAddress,
      billingEmail: this.addClientForm.value.addClientBillingEmail,
      contactPersonName: this.addClientForm.value.addClientContactPersonName,
      contactPersonLastName: this.addClientForm.value.addClientContactPersonLastName,
      contactPersonRole: this.addClientForm.value.addClientContactPersonRole,
      contactPersonPhone: this.addClientForm.value.addClientContactPersonPhone,
      contactPersonEmail: this.addClientForm.value.addClientContactPersonEmail,
      tags: this.addClientForm.value.addClientTags,
      notes: this.addClientForm.value.addClientNotes,
      comments: this.addClientForm.value.addClientComments,
      visibility: this.addClientForm.value.addClientVisibility,
      links: this.addClientForm.value.addClientLinks
    };
    this.actorService.addActor(addClientObject).subscribe(
      res => {
        this.ngOnInit();
        UIkit.modal(this.addNewClientModalWindow).hide();
        this.resetForm();
        UIkit.notification({
          message: 'Saved successfully',
          status: 'success',
          pos: 'top-right',
          timeout: 1500
        });
      },
      error => {
        UIkit.notification({
          message: 'Saving failed',
          status: 'danger',
          pos: 'top-right',
          timeout: 1500
        });
      }
    );
  }

  getFormValidationErrors() {
    const form = this.addClientForm;

    const result = [];
    Object.keys(form.controls).forEach(key => {

      const controlErrors: ValidationErrors = form.get(key).errors;
      if (controlErrors) {
        Object.keys(controlErrors).forEach(keyError => {
          result.push({
            'control': key,
            'error': keyError,
            'value': controlErrors[keyError]
          });
        });
      }
    });
  }

  openClient(clientId): void {
    this.actorService.setCurrentActor(clientId);
    this.router.navigateByUrl('/client/' + clientId);
  }

  removeClient(clientId): void {
    this.actorService.deleteActor(clientId).subscribe(
      () => {
        this.ngOnInit();
        UIkit.notification({
          message: 'Client removed successfully',
          status: 'success',
          pos: 'top-right',
          timeout: 1500
        });
      },
      () => {
        UIkit.notification({
          message: 'Error removing client',
          status: 'danger',
          pos: 'top-right',
          timeout: 1500
        });
      }
    );
  }

  sortItems(sortingKey: string): void {
    if (sortingKey !== this.sortingKey) {
      this.sortingKey = sortingKey;
    } else {
      this.sortAscending = !this.sortAscending;
    }
    this.getAccountClients();
  }

  activeSort(key: string): boolean {
    return this.sortingKey === key;
  }

  resetForm(): void {
    this.addClientForm.reset();
    this.addClientForm.patchValue({
      addClientOwner: this.activeAccount
    });
  }

  setLimit(limit: number): void {
    if (limit !== this.limitQuery) {
      this.limitQuery = limit;
      /* Set currentPage to 0 to start from the first page*/
      this.currentPage = 1;
      this.getAccountData();
    }
  }

  activeLimit(limit: number): boolean {
    return this.limitQuery === limit;
  }

  setCurrentPageString(page: string): void {
    this.setCurrentPage(parseInt(page, 0));
  }

  setCurrentPage(page: number): void {
    if ((page !== this.currentPage) && (page <= this.pageCount)) {
      this.currentPage = page;
      this.getAccountData();
    }
  }

  getFormErrors(form: any) {
    if (form instanceof FormControl) {
      // Return FormControl errors or null
      return form.errors ? form.errors : null;
    }
    if (form instanceof FormGroup) {
      const groupErrors = form.errors;
      // Form group can contain errors itself, in that case add'em
      const formErrors = groupErrors ? {groupErrors} : {};
      Object.keys(form.controls).forEach(key => {
        // Recursive call of the FormGroup fields
        const error = this.getFormErrors(form.get(key));
        if (error !== null) {
          // Only add error if not null
          formErrors[key] = error;
        }
      });
      // Return FormGroup errors or null
      return Object.keys(formErrors).length > 0 ? formErrors : null;
    }
  }

  public validateName(): void {
    this.addClientForm.controls['addClientName'].setErrors({ exists: null });
    this.addClientForm.controls['addClientLastName'].setErrors({ exists: null });

    const actorObject = {
      owner: this.addClientForm.value.addClientOwner,
      name: this.addClientForm.value.addClientName,
      lastName: this.addClientForm.value.addClientLastName,
      type: this.clientType
    };

    this.actorService.validateActorName(actorObject).subscribe(
      response => {
        if (response.error) {
          this.addClientForm.controls['addClientName'].setErrors({ exists: true });
          this.addClientForm.controls['addClientLastName'].setErrors({ exists: true });
        } else {
          this.addClientForm.controls['addClientName'].setErrors({ exists: null });
          this.addClientForm.controls['addClientLastName'].setErrors({ exists: null });

          this.addClientForm.controls['addClientName'].updateValueAndValidity();
          this.addClientForm.controls['addClientLastName'].updateValueAndValidity();
        }
      }
    );
  }

  addToAllLanguages(languages: Array<any>): void {
    if (languages && languages.length > 0) {
      languages.forEach((language: any) => {
        if (language.title) {
          const languageIncluded: boolean = this.allLanguages.filter(allLanguage => allLanguage.title.toLowerCase() === language.title.toLowerCase()).length > 0;
          if (!languageIncluded) {
            this.allLanguages.push(language);
          }
        }
      });
    }
  }

  addToAllClientGroups(clientGroups: Array<any>): void {
    if (clientGroups && clientGroups.length > 0) {
      clientGroups.forEach((clientGroup: any) => {
        if (clientGroup.title) {
          const clientGroupIncluded: boolean = this.allClientGroups.filter(allClientGroup => allClientGroup.title.toLowerCase() === clientGroup.title.toLowerCase()).length > 0;
          if (!clientGroupIncluded) {
            this.allClientGroups.push(clientGroup);
          }
        }
      });
    }
  }

  addToAllContactMethods(contactMethods: Array<any>): void {
    if (contactMethods && contactMethods.length > 0) {
      contactMethods.forEach((contactMethod: any) => {
        if (contactMethod.title) {
          const contactMethodIncluded: boolean = this.allContactMethods.filter(allContactMethod => allContactMethod.title.toLowerCase() === contactMethod.title.toLowerCase()).length > 0;
          if (!contactMethodIncluded) {
            this.allContactMethods.push(contactMethod);
          }
        }
      });
    }
  }

  selectAllLanguages(): void {
    this.allLanguages.forEach((allLanguage: any) => {
      this.createCSVSelectedLanguages.push(allLanguage);
    });
  }

  removeAllSelectedLanguages(): void {
    this.createCSVSelectedLanguages.length = 0;
  }

  toggleSelectedLanguage(language: any): void {
    const languageIncluded: boolean = this.createCSVSelectedLanguages.filter(selectedLanguage => selectedLanguage.title === language.title).length > 0;
    if (!languageIncluded) {
      this.createCSVSelectedLanguages.push(language);
    } else {
      this.createCSVSelectedLanguages.splice(this.createCSVSelectedLanguages.indexOf(language), 1);
    }
  }

  isLanguageSelected(language: any): boolean {
    return this.createCSVSelectedLanguages.filter(selectedLanguage => selectedLanguage.title === language.title).length > 0;
  }

  selectAllClientGroups(): void {
    this.allClientGroups.forEach((allClientGroup: any) => {
      this.createCSVSelectedClientGroups.push(allClientGroup);
    });
  }

  removeAllSelectedClientGroups(): void {
    this.createCSVSelectedClientGroups.length = 0;
  }

  toggleSelectedClientGroup(clientGroup: any): void {
    const clientGroupIncluded: boolean = this.createCSVSelectedClientGroups.filter(selectedClientGroup => selectedClientGroup.title === clientGroup.title).length > 0;
    if (!clientGroupIncluded) {
      this.createCSVSelectedClientGroups.push(clientGroup);
    } else {
      this.createCSVSelectedClientGroups.splice(this.createCSVSelectedClientGroups.indexOf(clientGroup), 1);
    }
  }

  isClientGroupSelected(clientGroup: any): boolean {
    return this.createCSVSelectedClientGroups.filter(selectedClientGroup => selectedClientGroup.title === clientGroup.title).length > 0;
  }

  selectAllContactMethods(): void {
    this.allContactMethods.forEach((allContactMethod: any) => {
      this.createCSVSelectedContactMethods.push(allContactMethod);
    });
  }

  removeAllSelectedContactMethods(): void {
    this.createCSVSelectedContactMethods.length = 0;
  }

  toggleSelectedContactMethod(contactMethod: any): void {
    const contactMethodIncluded: boolean = this.createCSVSelectedContactMethods.filter(selectedContactMethod => selectedContactMethod.title === contactMethod.title).length > 0;
    if (!contactMethodIncluded) {
      this.createCSVSelectedContactMethods.push(contactMethod);
    } else {
      this.createCSVSelectedContactMethods.splice(this.createCSVSelectedContactMethods.indexOf(contactMethod), 1);
    }
  }

  isContactMethodSelected(contactMethod: any): boolean {
    return this.createCSVSelectedContactMethods.filter(selectedContactMethod => selectedContactMethod.title === contactMethod.title).length > 0;
  }

  getCSVforAllClients(): void {
    const allClients: Array<any> = [];

    let item: any;
    for (item of this.clients) {
      let fullName = item.name;
      if (item.lastName) {
        fullName += ' ' + item.lastName;
      }

      const getCSVObject = {
        Name: fullName,
        Email: item.email,
        'Client group':  this.getArrayAsString(item.clientGroup),
        Status: item.status,
      };

      allClients.push(getCSVObject);
    }

    this.fileService.getCSV('Opportunities', allClients);
  }

  getFilteredCSV(): void {
    this.fileService.getCSV('Opportunities', {
      requester: 'filteredCSV',
      accountId: this.activeAccount,
      selectedLanguages: this.createCSVSelectedLanguages,
      selectedContactMethods: this.createCSVSelectedContactMethods,
      selectedClientGroups: this.createCSVSelectedClientGroups,
    });
  }

  openGetFilteredCSV(): void {
    this.getFilteredCSVModalWindow = window.document.getElementById('getFilteredCSVModal');
    UIkit.modal(this.getFilteredCSVModalWindow).show();
  }

  getArrayAsString(arrayObject: any): string {
    let contents = '';

    if (Array.isArray(arrayObject)) {
      if (arrayObject.length > 0) {
        arrayObject.forEach((item: any, index: number) => {
          contents += item.title;
          if (index !== arrayObject.length - 1) {
            contents += ', ';
          }
        });
      } else {
        return arrayObject.toString() ?? '';
      }
    }

    return contents;
  }

  getCharactersLimitError(fieldName: string) {
    if (!fieldName || !this.addClientForm.get(fieldName).value ) {
      return ''
    }

    const charactersOverLimit: number = this.addClientForm.get(fieldName).value.length - this.addClientForm.get(fieldName).errors.maxlength.requiredLength;

    return `Field length is ${ charactersOverLimit } characters over limit`;
  }

  getCharactersLeft(fieldName: string, charactersLimit: number) {
    if (!fieldName || !this.addClientForm.get(fieldName).value ) {
      return ''
    }

    const fieldLength: number = this.addClientForm.get(fieldName).value.length;
    const charactersOverLimit: number = fieldLength - charactersLimit;

    if (charactersOverLimit >= 0) { return `${ charactersLimit } / ${ charactersLimit }` }

    return `${ fieldLength } / ${ charactersLimit }`;
  }
}
