import {Component, EventEmitter, OnInit, ViewChild} from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { FormGroup, FormControl, Validators, FormBuilder } from '@angular/forms';
import { AuthService } from '../../services/auth.service';
import { UserService } from '../../services/user.service';
import { FileService } from '../../services/file.service';
import { AccountService } from '../../services/account.service';
import { ActiveService } from '../../services/active.service';
import { UploadOutput, UploadInput, UploadFile, humanizeBytes, UploaderOptions } from 'ngx-uploader';

declare var UIkit: any;

@Component({
  selector: 'app-account',
  templateUrl: './accounts.component.html',
  styleUrls: ['./accounts.component.scss']
})
export class AccountsComponent implements OnInit {
  public user: any = {};
  public isAdmin = false;
  public users: Array<Object> = [];
  public accounts: Array<any> = [];
  public attachments: Array<Object> = [];
  public variables: Array<string> = [];
  public activeAccount: any = {};
  public isLoading = true;
  public addAccountForm: FormGroup;
  public registerForm: FormGroup;
  public options: UploaderOptions;
  public formData: FormData;
  public filesToUpload: UploadFile[];
  public uploadInput: EventEmitter<UploadInput>;
  public humanizeBytes: Function;
  public dragOver: boolean;
  public registerModalWindow: any;
  public addNewAccountModalWindow: any;
  public sortingKey: string;
  public sortAscending = false;
  public onlyActive = false;

  protected currencies =
    [
      'AFN',
      'EUR',
      'ALL',
      'DZD',
      'USD',
      'AOA',
      'XCD',
      'ARS',
      'AMD',
      'AWG',
      'AUD',
      'AZN',
      'BSD',
      'BHD',
      'BDT',
      'BBD',
      'BYN',
      'BZD',
      'XOF',
      'BMD',
      'INR',
      'BTN',
      'BOB',
      'BOV',
      'BAM',
      'BWP',
      'NOK',
      'BRL',
      'BND',
      'BGN',
      'BIF',
      'CVE',
      'KHR',
      'XAF',
      'CAD',
      'KYD',
      'CLP',
      'CLF',
      'CNY',
      'COP',
      'COU',
      'KMF',
      'CDF',
      'NZD',
      'CRC',
      'HRK',
      'CUP',
      'CUC',
      'ANG',
      'CZK',
      'DKK',
      'DJF',
      'DOP',
      'EGP',
      'SVC',
      'ERN',
      'ETB',
      'FKP',
      'FJD',
      'XPF',
      'GMD',
      'GEL',
      'GHS',
      'GIP',
      'GTQ',
      'GBP',
      'GNF',
      'GYD',
      'HTG',
      'HNL',
      'HKD',
      'HUF',
      'ISK',
      'IDR',
      'XDR',
      'IRR',
      'IQD',
      'ILS',
      'JMD',
      'JPY',
      'JOD',
      'KZT',
      'KES',
      'KPW',
      'KRW',
      'KWD',
      'KGS',
      'LAK',
      'LBP',
      'LSL',
      'ZAR',
      'LRD',
      'LYD',
      'CHF',
      'MOP',
      'MKD',
      'MGA',
      'MWK',
      'MYR',
      'MVR',
      'MRU',
      'MUR',
      'XUA',
      'MXN',
      'MXV',
      'MDL',
      'MNT',
      'MAD',
      'MZN',
      'MMK',
      'NAD',
      'NPR',
      'NIO',
      'NGN',
      'OMR',
      'PKR',
      'PAB',
      'PGK',
      'PYG',
      'PEN',
      'PHP',
      'PLN',
      'QAR',
      'RON',
      'RUB',
      'RWF',
      'SHP',
      'WST',
      'STN',
      'SAR',
      'RSD',
      'SCR',
      'SLL',
      'SGD',
      'XSU',
      'SBD',
      'SOS',
      'SSP',
      'LKR',
      'SDG',
      'SRD',
      'SZL',
      'SEK',
      'CHE',
      'CHW',
      'SYP',
      'TWD',
      'TJS',
      'TZS',
      'THB',
      'TOP',
      'TTD',
      'TND',
      'TRY',
      'TMT',
      'UGX',
      'UAH',
      'AED',
      'USN',
      'UYU',
      'UYI',
      'UZS',
      'VUV',
      'VEF',
      'VND',
      'YER',
      'ZMW',
      'ZWL',
      'XBA',
      'XBB',
      'XBC',
      'XBD',
      'XTS',
      'XXX',
      'XAU',
      'XPD',
      'XPT',
      'XAG'
    ];

  protected timezones =
    [
      'Europe/Helsinki'
    ];

  protected measurementUnits =
    [
      'Millimeter (mm)',
      'Centimeter (cm)',
      'Meter (m)',
      'Inch (in)',
      'Foot (ft)',
      'Yard (yd)',
    ];

  constructor(
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private formBuilder: FormBuilder,
    private auth: AuthService,
    private activeService: ActiveService,
    private userService: UserService,
    private accountService: AccountService,
    private fileService: FileService,
    private authService: AuthService
  ) {
    this.activatedRoute.data.subscribe(data => {
      this.activeAccount = data.resolvedData.account.id;
      this.user = data.resolvedData.user.id;
      this.isAdmin = this.authService.isAdmin;
    });

    this.addAccountForm = this.formBuilder.group({
      addAccountName: new FormControl('', [
        Validators.required,
        Validators.maxLength(100)
      ]),
      addAccountVisibleName: new FormControl('', [
        Validators.maxLength(100)
      ]),
      addAccountOwner: new FormControl('', [
        Validators.maxLength(100)
      ]),
      addAccountStreet: new FormControl('', [
        Validators.maxLength(100)
      ]),
      addAccountCity: new FormControl('', [
        Validators.maxLength(100)
      ]),
      addAccountState: new FormControl('', [
        Validators.maxLength(100)
      ]),
      addAccountZip: new FormControl('', [
        Validators.maxLength(100)
      ]),
      addAccountCountry: new FormControl('', [
        Validators.maxLength(100)
      ]),
      addAccountEmail: new FormControl('', [
        Validators.maxLength(100)
      ]),
      addAccountPhone: new FormControl('', [
        Validators.maxLength(100)
      ]),
      addAccountNotes: new FormControl('', [
        Validators.maxLength(2048)
      ]),
      addAccountCurrency: new FormControl('', [
        Validators.maxLength(2048)
      ]),
      addAccountVAT: new FormControl('', [
        Validators.maxLength(2048)
      ]),
      addAccountType: new FormControl('', [
        Validators.maxLength(2048)
      ]),
      addAccountPricing: new FormControl('', [
        Validators.maxLength(2048)
      ]),
      addAccountTimezone: new FormControl('', [
        Validators.maxLength(2048)
      ]),
      addAccountMeasurementUnit: new FormControl('', [
        Validators.maxLength(2048)
      ]),
      addAccountAttachments: new FormControl('', [
        Validators.maxLength(100)
      ])
    });

    this.filesToUpload = []; // local uploading files array
    this.uploadInput = new EventEmitter<UploadInput>(); // input events, we use this to emit data to ngx-uploader
    this.humanizeBytes = humanizeBytes;
  }

  ngOnInit() {
    this.isLoading = true;
    this.getUsersAccounts();
  }

  /*getUser() {
    this.userService.getUser(this.auth.currentUser).subscribe(
      data => {
        this.user = data;
        this.getUsersAccounts();
      },
      error => {
        UIkit.notification({
          message: 'Error fetching user information',
          status: 'danger',
          pos: 'top-right',
          timeout: 1500
        });
      },
      () => this.isLoading = false
    );
  }*/

  getAccountData() {
    const getObject = {
      accountId: this.activeAccount,
      requester: 'accounts',
      sortingKey: this.sortingKey ? this.sortingKey : '_id',
      sortingOrder: this.sortAscending ? 'asc' : 'desc',
      skipQuery: 0,
      limitQuery: 99999,
      contentTypes: ['account', 'variable']
    };

    this.accountService.getAccountData(getObject).subscribe(
      data => {
        this.variables = data.variables ? data.variables : [];
        this.addAccountForm.patchValue({
          addAccountTimezone: 'Europe/Helsinki'
        });
      },
      () => {
        UIkit.notification({
          message: 'Error fetching account data',
          status: 'danger',
          pos: 'top-right',
          timeout: 1500
        });
      },
      () => this.isLoading = false
    );
  }

  getUsersAccounts() {
    this.accountService.getUserAccounts({ _id: this.user }, this.sortingKey, this.sortAscending).subscribe(
      data => {
        this.accounts = data;

        if (this.accounts.length == 1) {
          this.setActiveAccount(this.accounts[0]._id);
        }
        this.getAccountData();
      },
      () => {
        UIkit.notification({
          message: 'Error fetching users',
          status: 'danger',
          pos: 'top-right',
          timeout: 1500
        });
      },
      () => this.isLoading = false
    );
  }

  openAddNew() {
    this.registerModalWindow = window.document.getElementById('addnew');
    UIkit.modal(this.registerModalWindow).show();
  }

  removeUser(user) {
    this.userService.deleteUser(user).subscribe(
      res => {
        this.userService.getUsers().subscribe(() => {
          this.getUsersAccounts();
          UIkit.notification({
            message: 'User removed successfully',
            status: 'success',
            pos: 'top-right',
            timeout: 1500
          });
        });
      },
      () => {
        UIkit.notification({
          message: 'Removing user failed',
          status: 'danger',
          pos: 'top-right',
          timeout: 1500
        });
      }
    );
  }

  register() {
    /*const registerObject = {
      email: this.registerForm.value.email,
      password: this.registerForm.value.password,
      role: this.registerForm.value.role
    };*/
    this.userService.register(this.registerForm.value).subscribe(
      res => {
        this.userService.getUsers().subscribe(() => {
          this.getUsersAccounts();
          UIkit.modal(this.registerModalWindow).hide();
          UIkit.notification({
            message: 'New user added successfully',
            status: 'success',
            pos: 'top-right',
            timeout: 1500
          });
        });
      },
      error => {
        UIkit.notification({
          message: 'Adding a new user failed',
          status: 'danger',
          pos: 'top-right',
          timeout: 1500
        });
      }
    );
  }

  onUploadOutput(output: UploadOutput): void {
    const properFile = output.file && output.file.name && output.file.name.length > 0;

    if (output.type === 'allAddedToQueue') {
      const event: UploadInput = {
        type: 'uploadAll',
        url: '/api/file',
        method: 'POST',
        includeWebKitFormBoundary: true
      };
      this.uploadInput.emit(event);
    } else if (output.type === 'addedToQueue' && properFile) {
      if (this.filesToUpload.indexOf(output.file) == -1) {
        this.filesToUpload.push(output.file);
      }
    } else if (output.type === 'uploading' && properFile) {
      const index = this.filesToUpload.findIndex(file => typeof output.file !== 'undefined' && file.id === output.file.id);
      this.filesToUpload[index] = output.file;
    } else if (output.type === 'removed') {
      // remove file from array when removed
      this.filesToUpload = this.filesToUpload.filter((file: UploadFile) => file !== output.file);
    } else if (output.type === 'dragOver') {
      this.dragOver = true;
    } else if (output.type === 'dragOut') {
      this.dragOver = false;
    } else if (output.type === 'drop') {
      this.dragOver = false;
    } else if (output.type === 'removedAll') {
      this.filesToUpload.length = 0;
    } else if (output.type === 'done') {
      this.removeFile(output.file.id);
      this.attachments.push({
        mimetype: output.file.response.mimetype,
        filename: output.file.response.filename,
        id: output.file.response.id
      });
    }
  }

  startUpload(): void {
    const event: UploadInput = {
      type: 'uploadAll',
      url: '/api/file',
      method: 'POST',
    };
    this.uploadInput.emit(event);
  }

  cancelUpload(id: string): void {
    this.uploadInput.emit({ type: 'cancel', id: id });
  }

  removeFile(id: string): void {
    this.uploadInput.emit({ type: 'remove', id: id });
  }

  removeAllFiles(): void {
    this.uploadInput.emit({ type: 'removeAll' });
  }

  setAccount(index: number): void {
    setTimeout(() => {
      this.activeAccount = this.accounts[index];
    }, 200); // Timeout for the fade in animation
  }

  addAccount() {
    const addAccountObject = {
      name: this.addAccountForm.value.addAccountName,
      visibleName: this.addAccountForm.value.addAccountVisibleName,
      owner: this.addAccountForm.value.addAccountOwner,
      street: this.addAccountForm.value.addAccountStreet,
      city: this.addAccountForm.value.addAccountCity,
      state: this.addAccountForm.value.addAccountState,
      zip: this.addAccountForm.value.addAccountZip,
      country: this.addAccountForm.value.addAccountCountry,
      email: this.addAccountForm.value.addAccountEmail,
      phone: this.addAccountForm.value.addAccountPhone,
      notes: this.addAccountForm.value.addAccountNotes,
      currency: this.addAccountForm.value.addAccountCurrency,
      vat: this.addAccountForm.value.addAccountVAT,
      type: this.addAccountForm.value.addAccountType,
      pricing: this.addAccountForm.value.addAccountPricing,
      timezone: this.addAccountForm.value.addAccountTimezone,
      measurementUnit: this.addAccountForm.value.addAccountMeasurementUnit,
      PDFFont: this.addAccountForm.value.PDFFont,
      /*attachments: this.addAccountForm.value.addAccountAttachments*/
    };
    this.accountService.addAccount(addAccountObject).subscribe(
      data => {
        this.ngOnInit();
        UIkit.modal(this.addNewAccountModalWindow).hide();
        UIkit.notification({
          message: 'Saved successfully',
          status: 'success',
          pos: 'top-right',
          timeout: 1500
        });
      },
      error => {
        UIkit.notification({
          message: 'Saving failed',
          status: 'danger',
          pos: 'top-right',
          timeout: 1500
        });
      }
    );
  }

  closeAccount(account) {
    const closeAccountObject = {
      id: account._id,
      status: false
    };
    this.accountService.editAccountStatus(closeAccountObject).subscribe(
      res => {
        this.ngOnInit();
        UIkit.notification({
          message: 'Account closed successfully',
          status: 'success',
          pos: 'top-right',
          timeout: 1500
        });
      },
      error => {
        UIkit.notification({
          message: 'Account closing failed',
          status: 'danger',
          pos: 'top-right',
          timeout: 1500
        });
      }
    );
  }

  activateAccount(account) {
    const activateAccountObject = {
      id: account._id,
      status: true
    };
    this.accountService.editAccountStatus(activateAccountObject).subscribe(
      res => {
        this.ngOnInit();
        UIkit.notification({
          message: 'Account activated successfully',
          status: 'success',
          pos: 'top-right',
          timeout: 1500
        });
      },
      error => {
        UIkit.notification({
          message: 'Account activation failed',
          status: 'danger',
          pos: 'top-right',
          timeout: 1500
        });
      }
    );
  }

  deleteAccount(account) {
    const deleteAccountObject = {
      id: account._id
    };
    this.accountService.deleteAccount(deleteAccountObject).subscribe(
      res => {
        this.ngOnInit();
        UIkit.notification({
          message: 'Account deleted successfully',
          status: 'success',
          pos: 'top-right',
          timeout: 1500
        });
      },
      error => {
        UIkit.notification({
          message: 'Account deletion failed',
          status: 'danger',
          pos: 'top-right',
          timeout: 1500
        });
      }
    );
  }

  setUserLatestAccount(account) {
    const closeAccountObject = {
      id: account._id,
      account: account._id
    };
    this.accountService.editAccountStatus(closeAccountObject).subscribe(
      res => {
        this.ngOnInit();
        UIkit.notification({
          message: 'Account closed successfully',
          status: 'success',
          pos: 'top-right',
          timeout: 1500
        });
      },
      error => {
        UIkit.notification({
          message: 'Account closing failed',
          status: 'danger',
          pos: 'top-right',
          timeout: 1500
        });
      }
    );
  }

  addFile(): void {
    const fileSelected: File = this.addAccountForm.value.editAccountAttachments;
    this.accountService.uploadFile(fileSelected)
      .subscribe(res => {});
  }

  onChange($event: any) {
    this.addAccountForm.value.editAccountAttachments = $event.target.files[0];
  }

  openAddNewAccount() {
    /*this.actorType = 'privatePerson'*/
    this.addNewAccountModalWindow = window.document.getElementById('addNewAccount');
    UIkit.modal(this.addNewAccountModalWindow).show();
  }

  openAccount(accountId): void {
    this.router.navigateByUrl('/account/' + accountId);
  }

  setActiveAccount(accountId): void {
    this.activeAccount = accountId;
    this.activeService.setActiveAccount(accountId);
    this.userService.setUsersActiveAccount(this.auth.currentUser, accountId).subscribe(data => { /*console.log(data)*/ });
  }

  sortItems(sortingKey: string): void {
    if (sortingKey !== this.sortingKey) {
      this.sortingKey = sortingKey;
    } else {
      this.sortAscending = !this.sortAscending;
    }
    this.getUsersAccounts();
  }

  activeSort(key: string): boolean {
    return this.sortingKey === key;
  }

  showOnlyActive(): void {
    this.onlyActive = !this.onlyActive;
  }

  getOnlyActive(): any {
    if (this.onlyActive) {
      return true;
    } else {
      return [ true, false ];
    }
  }

  getAccountName(account: any): string {
    return account.accountActor.visibleName ?? account.accountActor.name;
  }
}
