import { Injectable } from '@angular/core';
import {HttpClient, HttpHeaders} from '@angular/common/http';
import { AccountService } from '../services/account.service';
import { ActiveService } from '../services/active.service';
import { Observable } from 'rxjs';
import 'rxjs/add/operator/map';
import { Subject } from 'rxjs';

@Injectable()
export class ActorService {
  private httpOptions = {
    headers: new HttpHeaders({
      'Content-Type':  'application/json',
      'charset': 'UTF-8'
    })
  };
  private currentAccount: any;
  currentActor: string = '';
  currentActorChange: Subject<string> = new Subject<string>();

  constructor(
    private http: HttpClient,
    private activeService: ActiveService,
    private accountService: AccountService
  ) {
    this.activeService.activeDataChange.subscribe(data => {
      this.currentAccount = data.account.id;
    });
  }

  setCurrentActor(actorId): void {
    this.currentActor = actorId;
    this.currentActorChange.next(this.currentActor);
  }

  getCurrentActor(): string {
    return this.currentActor;
  }

  getActors(getObject: any): Observable<any> {
    return this.http.get(`/api/actors/${ getObject.accountId }/${ getObject.requester }/${ getObject.actorType }/${ getObject.sortingKey }/${ getObject.sortingOrder }/${ getObject.skipQuery }/${ getObject.limitQuery }`);
  }

  countActors(): Observable<any> {
    return this.http.get('/api/actors/count');
  }

  addActor(actor): Observable<any> {
    return this.http.post('/api/actor', JSON.stringify(actor), this.httpOptions);
  }

  getActor(artistId): Observable<any> {
    return this.http.get(`/api/actor/${artistId}`);
  }

  getUserActor(user): Observable<any> {
    return this.http.get(`/api/useractor/${user._id}`);
  }

  editActor(actorId, actorObject): Observable<any> {
    return this.http.put(`/api/actor/${ actorId }`, JSON.stringify(actorObject), this.httpOptions);
  }

  deleteActor(actorId): Observable<any> {
    return this.http.delete(`/api/actor/${ actorId }`, this.httpOptions);
  }

  validateActorName(actor: any): Observable<any> {
    return this.http.post(
      `/api/validateActorName`,
      JSON.stringify(actor),
      this.httpOptions
    );
  }

  addInterestingArtist(InterestingArtistObject): Observable<any> {
    return this.http.post(`/api/actor/addInterestingArtist/${InterestingArtistObject}`, JSON.stringify(InterestingArtistObject), this.httpOptions);
  }

  deleteInterestingArtist(clientId, InterestingArtistObject): Observable<any> {
    return this.http.put(`/api/actor/deleteInterestingArtist/${clientId}`, JSON.stringify(InterestingArtistObject), this.httpOptions);
  }

  addExternalNotes(ExternalNotesObject): Observable<any> {
    return this.http.post(`/api/actor/addExternalNotes/${ExternalNotesObject.addExternalNotesFormClient}`, JSON.stringify(ExternalNotesObject), this.httpOptions);
  }

  editExternalNotes(ExternalNotesObject): Observable<any> {
    return this.http.put(`/api/actor/updateExternalNotes/${ExternalNotesObject.editExternalNotesFormClient}`, JSON.stringify(ExternalNotesObject), this.httpOptions);
  }

  deleteExternalNotes(clientId, ExternalNotesObject): Observable<any> {
    return this.http.put(`/api/actor/deleteExternalNotes/${clientId}`, JSON.stringify(ExternalNotesObject), this.httpOptions);
  }

}
