import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import 'rxjs/add/operator/map';
import 'rxjs/add/observable/of';
import { Subject } from 'rxjs';

import { currencies } from './currencies';

@Injectable()
export class ActiveService {
  private activeData: any = {};
  public activeDataChange: Subject<any> = new Subject<any>();
  private pendingRequests = 0;
  private loadingStatus = false;
  public loadingStatusChange: Subject<any> = new Subject<any>();

  constructor(
    private http: HttpClient
  ) {
    this.activeData = {
      user: {
        id: '',
      },
      account: {
        id: ''
      },
      artist: {
        id: ''
      },
      item: {
        id: ''
      },
      exhibition: {
        id: ''
      },
      collection: {
        id: ''
      },
      client: {
        id: ''
      },
      estimate: {
        id: ''
      },
      bid: {
        id: ''
      },
      reservation: {
        id: ''
      },
      trade: {
        id: ''
      },
      variables: {
        currencies: currencies
      },
      selections: {
        sortingKey: undefined,
        sortAscending: false,
        currentPage: 1,
        limitQuery: 10,
      }
    };
  }

  getActiveData(): Observable<any> {
    if (this.activeData.user.id) {
      return Observable.of(this.activeData);
    } else {
      return this.activeDataChange;
    }
  }

  resetActiveData(): void {
    this.activeData = {
      user: {
        id: '',
      },
      account: {
        id: ''
      },
      artist: {
        id: ''
      },
      item: {
        id: ''
      },
      exhibition: {
        id: ''
      },
      collection: {
        id: ''
      },
      client: {
        id: ''
      },
      estimate: {
        id: ''
      },
      bid: {
        id: ''
      },
      reservation: {
        id: ''
      },
      trade: {
        id: ''
      },
      variables: {
        currencies: currencies
      },
      selections: {
        sortingKey: undefined,
        sortAscending: false,
        currentPage: 1,
        limitQuery: 10,
      }
    };
    this.setActiveData();
  }

  setActiveData(): void {
    this.activeDataChange.next(this.activeData);
  }

  increasePendingRequests(): void {
    this.pendingRequests++;
    if (this.pendingRequests > 0) {
      this.loadingStatus = true;
      this.setLoadingStatus(this.loadingStatus);
    }
  }

  decreasePendingRequests(): void {
    if (this.pendingRequests > 0) {
      this.pendingRequests--;

      if (this.pendingRequests == 0) {
        this.loadingStatus = false;
        this.setLoadingStatus(this.loadingStatus);
      }
    }
    if (this.pendingRequests == 0) {
      this.loadingStatus = false;
      this.setLoadingStatus(this.loadingStatus);
    }
  }

  setLoadingStatus(newCondition: boolean): void {
    this.loadingStatus = newCondition;
    this.loadingStatusChange.next(this.loadingStatus);
  }

  setActiveUser(id: string): void {
    this.activeData.user.id = id;
    this.setActiveData();
  }

  setActiveAccount(id: string): void {
    this.activeData.account.id = id;
    this.setActiveData();
  }

  setActiveArtist(id: string): void {
    this.activeData.artist.id = id;
    this.setActiveData();
  }

  setActiveItem(id: string): void {
    this.activeData.item.id = id;
    this.setActiveData();
  }

  setActiveExhibition(id: string): void {
    this.activeData.exhibition.id = id;
    this.setActiveData();
  }

  setActiveCollection(id: string): void {
    this.activeData.collection.id = id;
    this.setActiveData();
  }

  setActiveClient(id: string): void {
    this.activeData.client.id = id;
    this.setActiveData();
  }

  setActiveEstimate(id: string): void {
    this.activeData.estimate.id = id;
    this.setActiveData();
  }

  setActiveBid(id: string): void {
    this.activeData.bid.id = id;
    this.setActiveData();
  }

  setActiveReservation(id: string): void {
    this.activeData.reservation.id = id;
    this.setActiveData();
  }

  setActiveTrade(id: string): void {
    this.activeData.trade.id = id;
    this.setActiveData();
  }

  setActiveSortingKey(value: string): void {
    this.activeData.selections.sortingKey = value;
    this.setActiveData();
  }

  setActiveSortAscending(value: boolean): void {
    this.activeData.selections.sortAscending = value;
    this.setActiveData();
  }

  setActiveLimitQuery(value: number): void {
    this.activeData.selections.limitQuery = value;
    this.setActiveData();
  }

  setActiveCurrentPage(value: number): void {
    this.activeData.selections.currentPage = value;
    this.setActiveData();
  }

  clearSelections(): void {
    this.activeData.selections = {
      sortingKey: undefined,
      sortAscending: false,
      currentPage: 1,
      limitQuery: 10,
    };
    this.setActiveData();
  }

  queryServerStatus(): Observable<any> {
   const token = localStorage.getItem("token");

   const httpOptions = {
      headers: new HttpHeaders({
        "Content-Type": "application/json",
        "charset": "UTF-8",
        "Authorization": "Bearer " + token
      })
    };

   return this.http.get("/api/status", httpOptions);
  }
}
