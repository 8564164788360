<app-sidenav contentType="Overview"></app-sidenav>

<div id="save" class="uk-section uk-preserve-color">
  <div class="uk-container">
    <!-- Overview
    <div class="uk-grid-match uk-child-width-expand@s uk-text-center" uk-grid>
      <div class="uk-width-3-5@m">
        <div class="uk-card uk-card-default uk-flex uk-flex-column">
          <div class="uk-card-header no-min-height">
            <div class="uk-grid-small uk-flex-middle" uk-grid>
              <div class="uk-width-expand">
                <h3 class="uk-card-title uk-margin-remove-bottom">Yhteenveto</h3>
                <p class="uk-text-meta uk-margin-remove-top">12 kokoelmaa</p>
              </div>
            </div>
          </div>
          <div class="uk-card-body uk-text-left">
            <dl class="uk-description-list">
              <dt>74 asiakasta</dt>
              <dd>Tänä vuonna 18 uutta asiakasta</dd>
              <dt>32 tarjousta</dt>
              <dd>24 tarjousta hyväksytty, 8 tarjousta avoinna</dd>
              <dt>78 näyttelyä</dt>
              <dd>Yhteensä 78 näyttelyä, joista 3 tulevaa</dd>
              <dt>80 taiteilijaa</dt>
              <dd>46 edustettua taiteilijaa, 34 vierailevaa</dd>
              <dt>12 kokoelmaa</dt>
              <dd>12 kokoelmaa</dd>
              <dt></dt>
              <dd>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.</dd>
            </dl>
          </div>
        </div>
      </div>
    </div>-->
    <div class="uk-grid-match uk-child-width-expand@s uk-text-center" uk-grid>
      <div uk-height-match="target: .uk-card-body">
        <div class="uk-card uk-card-default uk-flex uk-flex-column">
          <div class="uk-card-header no-min-height">
            <div class="uk-grid-small uk-flex-middle" uk-grid>
              <div class="uk-width-expand">
                <h3 class="uk-card-title uk-margin-remove-bottom">Artists</h3>
                <p class="uk-text-meta uk-margin-remove-top" *ngIf="counts.artists">{{ counts.artists }} artists</p>
              </div>
            </div>
          </div>
          <div class="uk-card-body uk-padding-small uk-flex uk-flex-center uk-flex-auto" [ngClass]="{ 'uk-flex-middle': !artists.length }">
          <table class="uk-table uk-table-responsive uk-table-middle uk-table-divider uk-text-left" *ngIf="artists.length">
              <thead>
              <tr>
                <th>Image</th>
                <th class="">Name</th>
                <th></th>
              </tr>
              </thead>
              <tbody>
              <tr *ngFor="let artist of artists | slice:0:5">
                <td>
                  <div class="image-max-height uk-preserve-width uk-background-contain uk-background-muted uk-height-small uk-panel uk-flex uk-flex-center uk-flex-middle uk-margin-auto uk-margin-right@m ilkka" width="60" [attr.data-src]="'/api/storagefile/' + activeAccount + '/' + artist.coverImage + '/small'" uk-img *ngIf="artist.coverImage"></div>
                  <div class="image-max-height uk-preserve-width uk-background-contain uk-background-muted uk-height-small uk-panel uk-flex uk-flex-center uk-flex-middle uk-margin-auto uk-margin-right@m ilkka2" width="60" [attr.data-src]="'/api/storagefile/' + activeAccount + '/' + artist.images[0].fileId + '/small'" uk-img *ngIf="!artist.coverImage && artist.images && artist.images.length">
                  </div>
                  <div class="image-max-height uk-preserve-width uk-background-contain uk-background-muted uk-height-small uk-panel uk-flex uk-flex-center uk-flex-middle uk-margin-auto uk-margin-right@m ilkka3" width="60" [attr.data-src]="'/api/image/artbuddy-logo-placeholder.svg'" uk-img *ngIf="!artist.coverImage && !artist.images.length">
                  </div>
                </td>
                <td class="uk-text-center uk-text-left@m">{{ artist.name }} {{ artist.lastName }}</td>
                <td class="uk-text-center uk-text-right@m">
                  <div class="uk-button-group">
                    <button (click)="openArtist(artist._id)" class="uk-button uk-button-default" type="button">Open</button>
                  </div>
                </td>
              </tr>
              </tbody>
            </table>
            <p *ngIf="!artists.length">No artists found</p>
          </div>
          <div class="uk-card-footer">
            <a routerLink="/artists" class="uk-button uk-button-text">Artists</a>
          </div>
        </div>
      </div>
      <div>
        <div class="uk-card uk-card-default uk-flex uk-flex-column">
          <div class="uk-card-header no-min-height">
            <div class="uk-grid-small uk-flex-middle" uk-grid>
              <div class="uk-width-expand">
                <h3 class="uk-card-title uk-margin-remove-bottom">Artworks</h3>
                <p class="uk-text-meta uk-margin-remove-top" *ngIf="counts.artworks">{{ counts.artworks }} items</p>
              </div>
            </div>
          </div>
          <div class="uk-card-body uk-padding-small uk-flex uk-flex-center uk-flex-auto" [ngClass]="{ 'uk-flex-middle': !artworks.length }">
            <table class="uk-table uk-table-responsive uk-table-middle uk-table-divider uk-text-left" *ngIf="artworks.length">
              <thead>
              <tr>
                <th>Image</th>
                <th>Name</th>
                <th></th>
              </tr>
              </thead>
              <tbody>
              <tr *ngFor="let artwork of artworks | slice:0:5">
                <td>
                  <div class="image-max-height uk-preserve-width uk-background-contain uk-background-muted uk-height-small uk-panel uk-flex uk-flex-center uk-flex-middle uk-margin-auto uk-margin-right@m" width="60" [attr.data-src]="'/api/storagefile/' + activeAccount + '/' + artwork.coverImage + '/small'" uk-img *ngIf="artwork.coverImage">
                  </div>
                  <div class="image-max-height uk-preserve-width uk-background-contain uk-background-muted uk-height-small uk-panel uk-flex uk-flex-center uk-flex-middle uk-margin-auto uk-margin-right@m" width="60" [attr.data-src]="'/api/storagefile/' + activeAccount + '/' + artwork.images[0].fileId + '/small'" uk-img *ngIf="!artwork.coverImage && artwork.images && artwork.images.length">
                  </div>
                  <div class="image-max-height uk-preserve-width uk-background-contain uk-background-muted uk-height-small uk-panel uk-flex uk-flex-center uk-flex-middle uk-margin-auto uk-margin-right@m" width="60" [attr.data-src]="'/api/image/artbuddy-logo-placeholder.svg'" uk-img *ngIf="!artwork.coverImage && !artwork.images.length">
                  </div>
                </td>
                <td class="uk-text-center uk-text-left@m">{{ artwork.name }}</td>
                <td class="uk-text-center uk-text-right@m">
                  <div class="uk-button-group">
                    <button (click)="openArtwork(artwork._id)" class="uk-button uk-button-default" type="button">Open</button>
                  </div>
                </td>
              </tr>
              </tbody>
            </table>
            <p *ngIf="!artworks.length">No artworks found</p>
          </div>
          <div class="uk-card-footer">
            <a routerLink="/artworks" class="uk-button uk-button-text">Artworks</a>
          </div>
        </div>
      </div>
    </div>
    <div class="uk-grid-match uk-child-width-expand@s uk-text-center" uk-grid *ngIf="!isCollector">
      <div>
        <div class="uk-card uk-card-default uk-flex uk-flex-column">
          <div class="uk-card-header no-min-height">
            <div class="uk-grid-small uk-flex-middle" uk-grid>
              <div class="uk-width-expand">
                <h3 class="uk-card-title uk-margin-remove-bottom">Clients</h3>
                <p class="uk-text-meta uk-margin-remove-top" *ngIf="counts.clients">{{ counts.clients }} client<span *ngIf="clients.length > 1">s</span></p>
              </div>
            </div>
          </div>
          <div class="uk-card-body uk-padding-small uk-flex uk-flex-center uk-flex-auto" [ngClass]="{ 'uk-flex-middle': !clients.length }">
            <table class="uk-table uk-table-responsive uk-table-middle uk-table-divider uk-text-left" *ngIf="clients.length">
              <thead>
              <tr>
                <th>Name</th>
                <th>Client group</th>
                <th></th>
              </tr>
              </thead>
              <tbody>
              <tr *ngFor="let client of clients | slice:0:5">
                <!--<td>
                  <div class="image-max-height uk-preserve-width uk-background-contain uk-background-muted uk-height-small uk-panel uk-flex uk-flex-center uk-flex-middle uk-margin-auto uk-margin-right@m" width="60" [attr.data-src]="'/api/storagefile/' + client.coverImage" uk-img *ngIf="client.coverImage">
                  </div>
                  <div class="image-max-height uk-preserve-width uk-background-contain uk-background-muted uk-height-small uk-panel uk-flex uk-flex-center uk-flex-middle uk-margin-auto uk-margin-right@m" width="60" [attr.data-src]="client.photos[0].fileId" uk-img *ngIf="!client.coverImage && client.photos && client.photos.length">
                  </div>
                  <div class="image-max-height uk-preserve-width uk-background-contain uk-background-muted uk-height-small uk-panel uk-flex uk-flex-center uk-flex-middle uk-margin-auto uk-margin-right@m" width="60" [attr.data-src]="'/api/image/artbuddy-logo-placeholder.svg'" uk-img *ngIf="!client.coverImage && !client.photos.length">
                  </div>
                </td>-->
                <td class="uk-text-center uk-text-left@m">{{ client.name }} {{ client.lastName }}</td>
<!--                <td class="uk-text-center uk-text-left@m">-->
<!--                  <span *ngIf="client.clientGroup && client.clientGroup.length">-->
<!--                    <span *ngFor="let clientGroup of client.clientGroup; let index=index">-->
<!--                      <span *ngIf="index < 5">{{ clientGroup.title }}</span>-->
<!--                      <span *ngIf="index < client.clientGroup.length-1">, </span>-->
<!--                    </span>-->
<!--                  </span>-->
<!--                  <span *ngIf="!client.clientGroup || !client.clientGroup.length">Not set</span>-->
<!--                </td>-->
                <td class="uk-text-center uk-text-left@m">
                  <span *ngIf="client.clientGroup">{{ client.clientGroup }}</span>
                  <span *ngIf="!client.clientGroup || !client.clientGroup.length">Not set</span>
                </td>
                <td class="uk-text-center uk-text-right@m">
                  <div class="uk-button-group">
                    <button (click)="openClient(client._id)" class="uk-button uk-button-default" type="button">Open</button>
                  </div>
                </td>
              </tr>
              </tbody>
            </table>
            <p *ngIf="!clients.length">No clients found</p>
          </div>
          <div class="uk-card-footer">
            <a routerLink="/clients" class="uk-button uk-button-text">Clients</a>
          </div>
        </div>
      </div>
      <div>
        <div class="uk-card uk-card-default uk-flex uk-flex-column">
          <div class="uk-card-header no-min-height">
            <div class="uk-grid-small uk-flex-middle" uk-grid>
              <div class="uk-width-expand">
                <h3 class="uk-card-title uk-margin-remove-bottom">Proposals</h3>
                <p class="uk-text-meta uk-margin-remove-top" *ngIf="counts.bids">{{ counts.bids }} proposal<span *ngIf="bids.length > 1">s</span></p>
              </div>
            </div>
          </div>
          <div class="uk-card-body uk-padding-small uk-flex uk-flex-center uk-flex-auto" [ngClass]="{ 'uk-flex-middle': !bids.length }">
            <table class="uk-table uk-table-responsive uk-table-middle uk-table-divider uk-text-left" *ngIf="bids.length">
              <thead>
              <tr>
                <th>Number</th>
                <th>Name</th>
                <th></th>
              </tr>
              </thead>
              <tbody>
              <tr *ngFor="let bid of bids | slice:0:5">
                <td class="uk-text-center uk-text-left@m">{{ bid.sequence }}</td>
                <td class="uk-text-center uk-text-left@m">{{ bid.name }}</td>
                <td class="uk-text-center uk-text-right@m">
                  <div class="uk-button-group">
                    <button (click)="openBid(bid._id)" class="uk-button uk-button-default" type="button">Open</button>
                  </div>
                </td>
              </tr>
              </tbody>
            </table>
            <p *ngIf="!bids.length">No proposals found</p>
          </div>
          <div class="uk-card-footer">
            <a routerLink="/bids" class="uk-button uk-button-text">Proposals</a>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
