<div class="uk-section uk-section-default uk-padding-remove-vertical"
     uk-height-viewport="offset-top: true">

  <app-sidenav contentType="Search"></app-sidenav>

  <div class="uk-container-expand uk-height-expand" *ngIf="!isLoading">
    <div uk-grid>
      <div class="uk-width-expand@m">
        <div class="uk-padding-small uk-padding-remove-top uk-height-1-1">
          <ais-instantsearch
            [config]="algolia">
            <ais-configure [searchParameters]="algoliaSearchParameters">
            </ais-configure>
            <div class="uk-width-1-1 search-form-wrapper uk-margin-bottom">
              <!--defaultValue="artwork"-->
              <app-ais-menu-select attribute="contentType"
                                   title=""
                                   moreOptions="All ..."
                                   [hideToggleShowMore]="true"
                                   sortName="name"
                                   sortDescription="asc"
                                   [defaultValue]="defaultContentType"
                                   class="uk-margin-small-right">
              </app-ais-menu-select>
              <app-ais-search-box class="uk-width-expand"></app-ais-search-box>
            </div>
            <div class="uk-width-1-1">
              <div class="uk-grid-collapse uk-height-1-1 hide-overflow-x" uk-grid>
                <div id="search-terms" class="uk-width-1-1 uk-width-1-3@s uk-width-1-4@m">
                  <form class="uk-form-stacked">
                    <h3 class="section-title uk-margin-small-bottom">Search criteria</h3>
                    <app-ais-refinement-list attribute="name" title="Name" sortName="name"
                                         sortDescription="asc" refinementAttribute="Name" searchable="true">
                    </app-ais-refinement-list>

                    <app-ais-refinement-list attribute="lastName" title="Last name" sortName="lastName"
                                         sortDescription="asc" refinementAttribute="lastName" searchable="true">
                    </app-ais-refinement-list>
                    <!--<app-ais-menu-select attribute="artistName" title="Artist name" sortName="artistName" sortDescription="asc"></app-ais-menu-select>-->
                    <app-ais-refinement-list attribute="artistName" title="Artist name"
                                             sortName="artistName" sortDescription="asc"
                                             refinementAttribute="artistName" searchable="true">
                    </app-ais-refinement-list>
                    <!--<ais-refinement-list attribute="artistName"></ais-refinement-list>-->
                    <app-ais-refinement-list attribute="visibleName" title="Visible name"
                                         sortName="visibleName"
                                         sortDescription="asc" refinementAttribute="visibleName" searchable="true">
                    </app-ais-refinement-list>
<!--                    <app-ais-menu-select attribute="code" title="Code"-->
<!--                                         sortName="code"-->
<!--                                         sortDescription="asc"></app-ais-menu-select>-->
<!--                    <app-ais-refinement-list  attribute="code"-->
<!--                                             title="Code"-->
<!--                                             sortName="code"-->
<!--                                             sortDescription="asc"-->
<!--                                             refinementAttribute="code"-->
<!--                                             searchable="true"-->
<!--                    ></app-ais-refinement-list>-->
<!--                    <app-ais-menu-select attribute="code" title="Code" sortName="code"-->
<!--                                         sortDescription="asc"></app-ais-menu-select>-->
                    <app-ais-range-input-select attribute="width"
                                                title="Width"></app-ais-range-input-select>
                    <app-ais-range-input-select attribute="height"
                                                title="Height"></app-ais-range-input-select>
                    <app-ais-range-input-select attribute="depth"
                                                title="Depth"></app-ais-range-input-select>
                    <app-ais-range-input-select attribute="frameWidth"
                                                title="Frame width"></app-ais-range-input-select>
                    <app-ais-range-input-select attribute="frameHeight"
                                                title="Frame height"></app-ais-range-input-select>
                    <app-ais-range-input-select attribute="frameDepth"
                                                title="Frame depth"></app-ais-range-input-select>
                    <app-ais-menu-select attribute="_type" title="Type"
                                         sortName="_type"
                                         sortDescription="asc"></app-ais-menu-select>
                    <app-ais-refinement-list attribute="technique" title="Technique"
                                         sortName="technique"
                                         sortDescription="asc" refinementAttribute="technique" searchable="true">
                    </app-ais-refinement-list>
                    <app-ais-menu-select attribute="material" title="Material" sortName="material"
                                         sortDescription="asc"></app-ais-menu-select>
<!--                    <app-ais-refinement-list attribute="artistCode" title="Artist code"-->
<!--                                         sortName="artistCode"-->
<!--                                         sortDescription="asc" refinementAttribute="artistCode" searchable="true"></app-ais-refinement-list>-->
                    <app-ais-menu-select attribute="artistCode" title="Artist code" sortName="artistCode" sortDescription="asc"></app-ais-menu-select>
                    <app-ais-menu-select attribute="artistType" title="Artist type"
                                         sortName="artistType"
                                         sortDescription="asc"></app-ais-menu-select>
<!--                    <app-ais-refinement-list attribute="priceMultiplier" title="Price multiplier"-->
<!--                                         sortName="priceMultiplier"-->
<!--                                         sortDescription="asc" refinementAttribute="priceMultiplier" searchable="true"></app-ais-refinement-list>-->
                    <app-ais-menu-select attribute="priceMultiplier" title="Price multiplier" sortName="priceMultiplier" sortDescription="asc"></app-ais-menu-select>
                    <app-ais-menu-select attribute="logistics" title="Logistics"
                                         sortName="logistics"
                                         sortDescription="asc"></app-ais-menu-select>
                    <app-ais-menu-select attribute="packaging" title="Packaging"
                                         sortName="packaging"
                                         sortDescription="asc"></app-ais-menu-select>
                    <!--<app-ais-menu-select attribute="availability" title="Availability" sortName="name"
                                         sortDescription="asc"></app-ais-menu-select>-->
                    <app-ais-refinement-list attribute="availability" title="Availability"
                                             sortName="name"
                                             sortDescription="asc"
                                             refinementAttribute="availability" searchable="true">
                    </app-ais-refinement-list>
                    <app-ais-range-input-select attribute="price.price"
                                                title="Price"></app-ais-range-input-select>
                    <app-ais-menu-select attribute="created" title="Created" sortName="created"
                                         sortDescription="asc"></app-ais-menu-select>
                    <app-ais-menu-select attribute="started" title="Started" sortName="started"
                                         sortDescription="asc"></app-ais-menu-select>
                    <app-ais-menu-select attribute="city" title="City" sortName="city"
                                         sortDescription="asc"></app-ais-menu-select>
                    <app-ais-refinement-list attribute="location" title="Location" sortName="location"
                                         sortDescription="asc" refinementAttribute="location" searchable="true">
                    </app-ais-refinement-list>
                    <app-ais-menu-select attribute="estimateBasis" title="Estimate basis"
                                         sortName="estimateBasis"
                                         sortDescription="asc"></app-ais-menu-select>
                    <app-ais-menu-select attribute="clientCode"
                                                title="Client code"></app-ais-menu-select>
                    <app-ais-menu-select attribute="clientGroup"
                                                title="Client group"></app-ais-menu-select>
<!--                    <app-ais-menu-select attribute="code"-->
<!--                                         title="Artwork code"></app-ais-menu-select>-->
                    <app-ais-menu-select attribute="code"
                                         sortName="code"
                                         sortDescription="asc"
                                         title="Artwork code">
                    </app-ais-menu-select>
                  </form>
                </div>
                <div id="search-results" class="uk-width-2-3@s uk-width-3-4@m">
                  <div id="results-amount"
                       class="uk-flex uk-flex-between uk-flex-middle uk-background-muted">
                    <div class="wrapper">
                      <h5 class="section-title uk-margin-small-bottom">Results count</h5>
                      <app-ais-stats></app-ais-stats>
                    </div>
                  </div>
                  <div id="search-criteria" class="uk-flex uk-flex-between uk-flex-middle">
                    <div class="criteria-wrapper">
                      <h5 class="section-title uk-margin-small-bottom">Active search criteria</h5>
                      <ais-current-refinements></ais-current-refinements>
                    </div>
                    <div class="button-container">
                      <app-ais-clear-refinements></app-ais-clear-refinements>
                    </div>
                  </div>
                  <div id="results-sorting" class="uk-flex uk-flex-left uk-flex-middle">
                    <div class="wrapper">
                      <h5 class="section-title uk-margin-small-bottom">Results sorting</h5>
                      <app-ais-sort-by [activeAccount]="activeAccount"></app-ais-sort-by>
                    </div>
                  </div>
                  <app-ais-refresh-results></app-ais-refresh-results>
                  <div id="results-items">
                    <h3 class="section-title uk-margin-small-bottom">Search results</h3>
                    <ais-hits>
                      <ng-template let-hits="hits" let-results="results">
                        <div *ngIf="filterHiddenDocuments(hits)" class="uk-container uk-padding-small">
                          No results found matching <strong>{{results?.query}}</strong>.
                        </div>
                        <table class="uk-table uk-table-middle uk-table-divider"
                               [hidden]="!hits.length">
                          <thead>
                          <tr>
                            <th colspan="3">
                              <button
                                id="toggleSelected"
                                (click)="toggleSelected(hits)"
                                class="uk-button uk-button-default"
                                type="button"
                              >
                                Toggle selected
                              </button>
                              <button
                                (click)="selectAll(hits)"
                                class="uk-button uk-button-default uk-margin-left"
                                type="button"
                              >
                                Select all
                              </button>
                              <button
                                id="removeSelections"
                                (click)="removeSelections()"
                                class="uk-button uk-button-default uk-margin-left"
                                type="button"
                              >
                                Select none
                              </button>
                            </th>
                            <th colspan="3">
                              <app-ais-hits-per-page></app-ais-hits-per-page>
                            </th>
                          </tr>
                          <tr>
                            <th class="uk-table-shrink">Select</th>
                            <th class="uk-table-shrink">Image</th>
                            <th class="uk-width-expand">Name</th>
                            <th class="uk-table-shrink"></th>
                          </tr>
                          </thead>
                          <tbody>
                          <tr *ngFor="let hit of hits; trackBy: trackByObjectID" class="hit" [id]="'hit' + hit._id" [hidden]="hit.hidden">
                            <td>
                              <ng-container *ngIf="['artwork'].includes(hit.contentType)">
                                <input (change)="toggleSelect($event, hit)" [checked]="getSelected(hit._id)" class="uk-checkbox"
                                       type="checkbox" id="{{ 'toggleSelect-' + hit._id }}">
                              </ng-container>
                            </td>
                            <td (click)="openAdvancedSearchResult(hit, hits)">
                              <ng-container *ngIf="['artist', 'artwork'].includes(hit.contentType)">
                                <!-- Näytetään coverImage jos on -->
                                <div
                                  class="image-max-height uk-preserve-width uk-background-contain uk-background-muted uk-height-small uk-panel uk-flex uk-flex-center uk-flex-middle"
                                  width="60"
                                  [attr.data-src]="'/api/storagefile/' + activeAccount + '/' + hit.coverImage + '/medium'"
                                  uk-img *ngIf="hit.coverImage">

                                </div>

                                <!-- Jos ei ole coverImagea, niin sit otetaan eka image -->
                                <div
                                  class="image-max-height uk-preserve-width uk-background-contain uk-background-muted uk-height-small uk-panel uk-flex uk-flex-center uk-flex-middle"
                                  width="60"
                                  [attr.data-src]="'/api/storagefile/' + activeAccount + '/' + hit.images[0].fileId + '/medium'"
                                  uk-img
                                  *ngIf="!hit.coverImage && hit.images && hit.images.length">

                                </div>

                                <!-- Jos ei ole coverImagea tai ekan imagea, ni sit otetaan placeholder -->
                                <div
                                  class="image-max-height uk-preserve-width uk-background-contain uk-background-muted uk-height-small uk-panel uk-flex uk-flex-center uk-flex-middle"
                                  width="60"
                                  [attr.data-src]="'/api/image/artbuddy-logo-placeholder.svg'"
                                  uk-img *ngIf="!hit.coverImage && !hit.images.length">

                                </div>
                              </ng-container>
                            </td>
                            <td>
                              <div
                                (click)="toggleSelect(hit)">{{ hit.name }} {{ hit.lastName }}</div>
                              <div class="uk-text-muted">{{ hit.contentType | ucfirst }}</div>
                            </td>
                            <td class="uk-text-right">
                              <div class="uk-button-group">
                                <button (click)="openAdvancedSearchResult(hit, hits)" class="uk-button uk-button-default" type="button">Open</button>
                                <div class="uk-inline uk-text-right">
                                  <button class="uk-button uk-button-default" type="button"><span uk-icon="icon:  chevron-down"></span></button>
                                  <div uk-dropdown="mode: click; boundary: ! .uk-button-group; boundary-align: true;">
                                    <ul class="uk-nav uk-dropdown-nav">
                                      <li><a (click)="openRemoveItem(hit)">Remove</a></li>
                                    </ul>
                                  </div>
                                </div>
                              </div>
                            </td>
                          </tr>
                          </tbody>
                        </table>
                      </ng-template>
                    </ais-hits>
                  </div>
                  <div id="results-paging">
                    <ais-pagination></ais-pagination>
                  </div>
                </div>
              </div>
            </div>
          </ais-instantsearch>
          <div class="uk-width-1-1 uk-margin-medium-top">
            <hr class="uk-margin-small-top">
          </div>
          <div class="uk-width-1-1">
            <div class="uk-margin">
              <div class="uk-text-right bottom-row">
                <div class="selected-count uk-text-muted uk-margin-small-right" *ngIf="selectedArtworks.length">
                  <span>Selected {{ selectedArtworks.length }} </span>
                  <span *ngIf="selectedArtworks.length === 1">item</span>
                  <span *ngIf="selectedArtworks.length > 1">items</span>
                </div>
                <button type="button" class="uk-button uk-button-default uk-margin-small-right"
                        [disabled]="!selectedArtworks.length" (click)="openRemoveMultipleItems()">Remove
                </button>
                <button type="button" class="uk-button uk-button-default uk-margin-small-right" [disabled]="!selectedArtworks.length">Open PDF
                </button>
                <div uk-dropdown="mode: click">
                  <ul class="uk-nav uk-dropdown-nav">
                    <li><a (click)="openPrintListModal(true)"> Print as list</a></li>
                    <li><a (click)="openPrintListModal(false)"> Print one artwork per page</a></li>
                  </ul>
                </div>
                <button type="button" class="uk-button uk-button-default uk-margin-small-right"
                        [disabled]="!selectedArtworks.length">Add to
                </button>
                <div id="addToDropdown" uk-dropdown="mode: click">
                  <ul class="uk-nav uk-dropdown-nav">
                    <li class="uk-nav-header">Collections</li>
                    <li><a (click)="createCollection()" type="button">New collection</a></li>
                    <li><a (click)="openAddToExistingCollection()" type="button">Existing
                      collection</a></li>
                    <li class="uk-nav-divider"></li>
                    <li class="uk-nav-header">Proposals</li>
                    <li><a (click)="createBid()" type="button">New proposal</a></li>
                    <li><a (click)="openAddToExistingBid()" type="button">Existing proposal</a></li>
                  </ul>
                </div>
                <!--<button type="submit" class="uk-button uk-button-primary" [disabled]="!editCollectionForm.valid">Save</button>-->
              </div>
            </div>
          </div>
          <div id="createList" class="uk-modal-container" uk-modal="bg-close: false; esc-close: false; cls-panel:
       'uk-overflow-hidden'">
            <div class="uk-modal-dialog">
              <div class="uk-modal-header">
                <h2 class="uk-modal-title">Print</h2>
              </div>
              <div class="uk-modal-body">
                <p>You can rearrange the order in which the artworks are printed out by dragging.</p>
                <div>
                  <button
                    (click)="togglePriceSelections()"
                    class="uk-button uk-button-default uk-margin-small-right"
                    type="button"
                  >
                    Toggle price
                  </button>
                  <button
                    (click)="toggleArtworkCodeSelections()"
                    class="uk-button uk-button-default uk-margin-small-right"
                    type="button"
                  >
                    Toggle artwork code
                  </button>
                  <button
                    (click)="toggleQRCodeSelections()"
                    class="uk-button uk-button-default"
                    type="button"
                    *ngIf="!printAsList"
                  >
                    Toggle QR code
                  </button>
                </div>
                <div id="createListSortable" class="uk-child-width-1-1"
                     uk-sortable="handle: .uk-card">
                  <div
                    class="uk-card uk-grid-collapse uk-child-width-expand uk-flex-middle uk-margin"
                    *ngFor="let item of selectedArtworks" uk-grid [attr.data-artworkid]="item._id">
                    <div class="uk-width-auto">
                      <span class="uk-sortable-handle uk-margin-small-right uk-padding-small"
                            uk-icon="icon: table"></span>
                    </div>
                    <!-- Näytetään coverImage jos on -->
                    <div
                      class="image-max-height uk-preserve-width uk-background-contain uk-background-muted uk-width-small uk-height-small uk-panel uk-flex uk-flex-center uk-flex-middle"
                      width="150"
                      [attr.data-src]="'/api/storagefile/' + activeAccount + '/' + item.coverImage + '/medium'"
                      uk-img *ngIf="item.coverImage">
                    </div>
                    <!-- Jos ei ole coverImagea, niin sit otetaan eka image -->
                    <div
                      class="image-max-height uk-preserve-width uk-background-contain uk-background-muted uk-width-small uk-height-small uk-panel uk-flex uk-flex-center uk-flex-middle"
                      width="150"
                      [attr.data-src]="'/api/storagefile/' + activeAccount + '/' + item.images[0].fileId + '/medium'"
                      uk-img *ngIf="!item.coverImage && item.images && item.images.length">
                    </div>
                    <!-- Jos ei ole coverImagea tai ekan imagea, ni sit otetaan placeholder -->
                    <div
                      class="image-max-height uk-preserve-width uk-background-contain uk-background-muted uk-width-small uk-height-small uk-panel uk-flex uk-flex-center uk-flex-middle"
                      width="150" [attr.data-src]="'/api/image/artbuddy-logo-placeholder.svg'"
                      uk-img *ngIf="!item.coverImage && !item.images.length">
                    </div>
                    <div>
                      <div class="uk-card-body">
                        <h6 class="uk-card-title uk-margin-small-bottom">{{ item.name }}</h6>
                        <p class="uk-margin-remove">Artist: {{ item.artistName }}</p>
                        <p class="uk-margin-remove">Notes: {{ item.listNotes }}</p>
                        <p>
                          <input id="showPrice-{{item._id}}" class="uk-checkbox uk-margin-small-right" type="checkbox"
                                 (change)="updateItemSettings('showPrice', item, $event)"><span class="uk-margin-right">Show price</span>
                          <input id="showArtworkCode-{{item._id}}" class="uk-checkbox uk-margin-small-right" type="checkbox"
                                 (change)="updateItemSettings('showArtworkCode', item, $event)"><span class="uk-margin-right">Show artwork code</span>
                          <ng-container *ngIf="!printAsList">
                            <input id="showQRCode-{{item._id}}" class="uk-checkbox" type="checkbox"
                                   (change)="updateItemSettings('showQRCode', item, $event)">
                            <span class="uk-margin-small-left">Show QR code</span>
                          </ng-container>
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="uk-modal-footer">
                <p class="uk-text-right">
                  <button class="uk-button uk-button-default uk-modal-close uk-margin-small-right"
                          type="button" id="addNewCollectionCancel">Cancel
                  </button>
                  <button type="submit" class="uk-button uk-button-primary" (click)="printList()">
                    Print
                  </button>
                </p>
              </div>
            </div>
          </div>
          <div id="addToExistingCollection" class="uk-modal-container"
               uk-modal="bg-close: false; esc-close: false;">
            <div class="uk-modal-dialog">
              <div class="uk-modal-header">
                <h2 class="uk-modal-title">Add to collection</h2>
              </div>
              <div class="uk-modal-body" uk-grid>
                <div class="uk-width-1-1@s">
                  <p>You're about to add the following artworks: <span class="uk-text-bold"
                                                                       *ngFor="let artwork of selectedArtworks; let index = index">{{ artwork.name }}
                    <span *ngIf="index < (selectedArtworks.length - 1)">, </span>   </span></p>
                  <div class="uk-margin">
                    <table class="uk-table uk-table-middle uk-table-divider">
                      <thead>
                      <tr>
                        <th class="uk-text-nowrap" [ngClass]="{'active': activeSort('name') }"
                            (click)="sortCollections('name')">Name<span uk-icon="chevron-down"
                                                                        [ngClass]="{'inverse': !sortAscending }"></span>
                        </th>
                        <th class="uk-text-nowrap uk-visible@m"
                            [ngClass]="{'active': activeSort('type') }"
                            (click)="sortCollections('type')">Type<span uk-icon="chevron-down"
                                                                        [ngClass]="{'inverse': !sortAscending }"></span>
                        </th>
                        <th class="uk-text-nowrap uk-visible@m"
                            [ngClass]="{'active': activeSort('location') }"
                            (click)="sortCollections('location')">Location<span
                          uk-icon="chevron-down" [ngClass]="{'inverse': !sortAscending }"></span>
                        </th>
                        <th class="uk-table-shrink"></th>
                      </tr>
                      </thead>
                      <tbody>
                      <tr *ngFor="let collection of collections">
                        <td>{{ collection.name }}</td>
                        <td class="uk-visible@m">{{ collection.type }}</td>
                        <td class="uk-visible@m">{{ collection.location }}</td>
                        <td class="uk-text-right">
                          <div class="uk-button-group">
                            <button class="uk-button uk-button-default" type="button"
                                    (click)="addToExistingCollection(collection)">Select
                            </button>
                          </div>
                        </td>
                      </tr>
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
              <div class="uk-modal-footer">
                <p class="uk-text-right">
                  <button class="uk-button uk-button-default uk-modal-close uk-margin-small-right"
                          type="button" id="addToExistingCollectionCancel"
                          (click)="closeAddToExistingCollection()">Close
                  </button>
                </p>
              </div>
            </div>
          </div>
          <div id="addToExistingBid" class="uk-modal-container"
               uk-modal="bg-close: false; esc-close: false;">
            <div class="uk-modal-dialog">
              <div class="uk-modal-header">
                <h2 class="uk-modal-title">Add to proposal</h2>
              </div>
              <div class="uk-modal-body" uk-grid>
                <div class="uk-width-1-1@s">
                  <p>You're about to add the following artworks: <span class="uk-text-bold"
                                                                       *ngFor="let artwork of selectedArtworks; let index = index">{{ artwork.name }}
                    <span *ngIf="index < (selectedArtworks.length - 1)">, </span>   </span></p>
                  <div class="uk-margin">
                    <table class="uk-table uk-table-middle uk-table-divider">
                      <thead>
                      <tr>
                        <th class="uk-text-nowrap" [ngClass]="{'active': activeSort('name') }"
                            (click)="sortBids('name')">Name<span uk-icon="chevron-down"
                                                                 [ngClass]="{'inverse': !sortAscending }"></span>
                        </th>
                        <th class="uk-text-nowrap uk-visible@m"
                            [ngClass]="{'active': activeSort('type') }" (click)="sortBids('type')">
                          Type<span uk-icon="chevron-down"
                                    [ngClass]="{'inverse': !sortAscending }"></span></th>
                        <th class="uk-text-nowrap uk-visible@m"
                            [ngClass]="{'active': activeSort('location') }"
                            (click)="sortBids('location')">Location<span uk-icon="chevron-down"
                                                                         [ngClass]="{'inverse': !sortAscending }"></span>
                        </th>
                        <th class="uk-table-shrink"></th>
                      </tr>
                      </thead>
                      <tbody>
                      <tr *ngFor="let bid of bids">
                        <td>{{ bid.name }}</td>
                        <td class="uk-visible@m">{{ bid.type }}</td>
                        <td class="uk-visible@m">{{ bid.location }}</td>
                        <td class="uk-text-right">
                          <div class="uk-button-group">
                            <button class="uk-button uk-button-default" type="button"
                                    (click)="addToExistingBid(bid)">Select
                            </button>
                          </div>
                        </td>
                      </tr>
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
              <div class="uk-modal-footer">
                <p class="uk-text-right">
                  <button class="uk-button uk-button-default uk-modal-close uk-margin-small-right"
                          type="button" id="addToExistingBidCancel"
                          (click)="closeAddToExistingBid()">Close
                  </button>
                </p>
              </div>
            </div>
          </div>
          <div id="removeItem" uk-modal>
            <div class="uk-modal-dialog height-auto">
              <button class="uk-modal-close-default" type="button" uk-close></button>
              <div class="uk-modal-header">
                <h2 class="uk-modal-title">Remove item</h2>
              </div>
              <div class="uk-modal-body">
                <p>Please verify that you wish to remove the selected item by clicking the Remove button below.</p>
              </div>
              <div class="uk-modal-footer uk-text-right">
                <button class="uk-button uk-button-default uk-modal-close uk-margin-small-right" type="button" id="removeArtworkCancel" (click)="resetArtworkToRemove()">Cancel
                </button>
                <button type="submit" class="uk-button uk-button-primary uk-modal-close" (click)="removeItem(itemToRemove)">Remove
                </button>
              </div>
            </div>
          </div>
          <div id="removeMultipleItems" uk-modal>
            <div class="uk-modal-dialog height-auto">
              <button class="uk-modal-close-default" type="button" uk-close></button>
              <div class="uk-modal-header">
                <h2 class="uk-modal-title">Remove items</h2>
              </div>
              <div class="uk-modal-body">
                <p>Please verify that you wish to remove the selected items by clicking the Remove button below.</p>
              </div>
              <div class="uk-modal-footer uk-text-right">
                <button class="uk-button uk-button-default uk-modal-close uk-margin-small-right" type="button">Cancel
                </button>
                <button type="submit" class="uk-button uk-button-primary uk-modal-close" [disabled]="!selectedArtworks.length" (click)="removeMultiple()">Remove
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
