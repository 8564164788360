import {Component, EventEmitter, OnInit, ViewChild} from "@angular/core";
import { FormBuilder, FormControl, FormGroup, Validators } from "@angular/forms";
import { AbstractControl, AsyncValidatorFn, ValidatorFn, ValidationErrors } from "@angular/forms";

import { ActivatedRoute, Router } from "@angular/router";
import { UserService } from "../../services/user.service";
import { ItemService } from "../../services/item.service";
import { AccountService } from "../../services/account.service";
import { AuthService } from "../../services/auth.service";
import { ActiveService } from "../../services/active.service";
import { FileService } from "../../services/file.service";
import { ActorService } from "../../services/actor.service";
import * as moment from "moment/moment";
import {AngularMyDatePickerDirective, IAngularMyDpOptions, IMyDateModel} from "angular-mydatepicker";
import { humanizeBytes, UploadFile, UploadInput, UploadOutput } from "ngx-uploader";

declare var UIkit: any;

@Component({
  selector: "app-artworks",
  templateUrl: "./artworks.component.html",
  styleUrls: ["./artworks.component.scss"]
})
export class ArtworksComponent implements OnInit {
  public user: any = {};
  public actorType: string;
  public activeAccount: string;
  public activeArtwork: any = {}; // For adding images & attachments
  public account: Object = {};
  public artists: Array<Object> = [];
  public items: Array<Object> = [];
  public variables: Array<string> = [];
  public isLoading = true;
  public uploadLoading: Boolean = false;
  public addArtworkForm: FormGroup;
  public addNewArtworkModalWindow: any;
  public removeArtworkModalWindow: any;
  public sortingKey: string;
  public sortAscending = false;
  public currentPage = 1;
  public limitQuery = 10;
  public pageCount = 1;
  public pages: Array<any> = [];
  public myDatePickerOptions: IAngularMyDpOptions = {
    dateFormat: "dd.mm.yyyy"
  };
  private todaysDate: IMyDateModel;
  private validDate: IMyDateModel;
  @ViewChild("AngularMyDatePickerAddArtworkPurchaseDate") AngularMyDatePickerAddArtworkPurchaseDate: AngularMyDatePickerDirective;
  public addArtworkImageModalWindow: any;
  public filesToUpload: UploadFile[];
  public uploadInput: EventEmitter<UploadInput>;
  public humanizeBytes: Function;
  public dragOver: boolean;
  public artistMultiplier;
  public imageLoadOffset$: any;
  public artworkNameMightNotBeUnique = false;
  public artworkArtworkCodeMightNotBeUnique = false;
  public numberWithDecimal = new RegExp(/^[0-9]+(\.[0-9]{1,2})?$/);
  private artworkToRemove: string;

  constructor(
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private formBuilder: FormBuilder,
    private auth: AuthService,
    private activeService: ActiveService,
    private userService: UserService,
    private accountService: AccountService,
    private itemService: ItemService,
    private fileService: FileService,
    private actorService: ActorService
  ) {
    this.activatedRoute.data.subscribe(data => {
      this.activeAccount = data.resolvedData.account.id;
      this.user = data.resolvedData.user.id;
    });

    this.activeService.getActiveData().subscribe(
      data => {
        this.sortingKey = data.selections.sortingKey;
        this.sortAscending = data.selections.sortAscending;
        this.currentPage = data.selections.currentPage;
        this.limitQuery = data.selections.limitQuery;
      }
    );

    this.addArtworkForm = this.formBuilder.group({
      addArtworkOwner: new FormControl("", [
        Validators.required,
        Validators.maxLength(50)
      ]),
      addArtworkName: new FormControl(
        "", [
          Validators.required,
          Validators.maxLength(125),
        ]
      ),
      addArtworkDescription: new FormControl("", [
        Validators.maxLength(2048)
      ]),
      addArtworkImages: new FormControl(""),
      addArtworkCoverImage: new FormControl(""),
      addArtworkCode: new FormControl("", [
        Validators.maxLength(30)
      ]),
      addArtworkHeight: new FormControl("", [
        Validators.maxLength(15),
        Validators.pattern(this.numberWithDecimal)
      ]),
      addArtworkWidth: new FormControl("", [
        Validators.maxLength(15),
        Validators.pattern(this.numberWithDecimal)
      ]),
      addArtworkDepth: new FormControl("", [
        Validators.maxLength(15),
        Validators.pattern(this.numberWithDecimal)
      ]),
      addArtworkWeight: new FormControl("", [
        Validators.maxLength(15),
        Validators.pattern(this.numberWithDecimal)
      ]),
      addArtworkFrame: new FormControl("", []),
      addArtworkFrameHeight: new FormControl("", [
        Validators.maxLength(15),
        Validators.pattern(this.numberWithDecimal)
      ]),
      addArtworkFrameWidth: new FormControl("", [
        Validators.maxLength(15),
        Validators.pattern(this.numberWithDecimal)
      ]),
      addArtworkFrameDepth: new FormControl("", [
        Validators.maxLength(15),
        Validators.pattern(this.numberWithDecimal)
      ]),
      addArtworkStarted: new FormControl("", [
        Validators.maxLength(4),
        Validators.pattern(this.numberWithDecimal)
      ]),
      addArtworkCreated: new FormControl("", [
        Validators.maxLength(4),
        Validators.pattern(this.numberWithDecimal)
      ]),
      addArtworkSRP: new FormControl("", [
        Validators.maxLength(10),
        Validators.pattern(this.numberWithDecimal)
      ]),
      addArtworkPrice: new FormControl("", [
        Validators.maxLength(10),
        Validators.pattern(this.numberWithDecimal)
      ]),
      addArtworkArtist: new FormControl(null, [
        Validators.required,
        Validators.maxLength(100)
      ]),
      addArtworkTechnique: new FormControl("", [
        Validators.maxLength(150),
      ]),
      addArtworkMaterial: new FormControl("", [
        Validators.maxLength(30),
      ]),
      addArtworkType: new FormControl("", [
        Validators.required,
        Validators.maxLength(30),
      ]),
      addArtworkSeller: new FormControl("", [
        Validators.maxLength(100),
        Validators.pattern(this.numberWithDecimal)
      ]),
      addArtworkSellerContactInfo: new FormControl("", [
        Validators.maxLength(2048),
      ]),
      addArtworkPurchaseDate: new FormControl("", [
        Validators.maxLength(10),
      ]),
      addArtworkPurchasePrice: new FormControl("", [
        Validators.maxLength(10),
        Validators.pattern(this.numberWithDecimal)
      ]),
      addArtworkMinimumPrice: new FormControl("", [
        Validators.maxLength(10),
        Validators.pattern(this.numberWithDecimal)
      ]),
      addArtworkMaximumPrice: new FormControl("", [
        Validators.maxLength(10),
        Validators.pattern(this.numberWithDecimal)
      ]),
      addArtworkEstimateBasis: new FormControl("", [
        Validators.maxLength(30),
      ]),
      addArtworkEstimator: new FormControl("", [
        Validators.maxLength(100),
      ]),
      addArtworkEstimatorInformation: new FormControl("", [
        Validators.maxLength(2048)
      ]),
      addArtworkCollateralValue: new FormControl("", [
        Validators.maxLength(10),
        Validators.pattern(this.numberWithDecimal)
      ]),
      addArtworkConservationDate: new FormControl(null, [
        Validators.maxLength(10),
      ]),
      addArtworkConservationCompany: new FormControl("", [
        Validators.maxLength(100),
      ]),
      addArtworkConservationName: new FormControl("", [
        Validators.maxLength(100),
      ]),
      addArtworkConservationDetails: new FormControl("", [
        Validators.maxLength(2048),
      ]),
      addArtworkPriceMultiplier: new FormControl(null, [
        Validators.maxLength(5),
        Validators.pattern(this.numberWithDecimal)
      ]),
      addArtworkAvailability: new FormControl(null, [
        Validators.maxLength(30),
      ]),
      addArtworkLogistics: new FormControl(null, [
        Validators.maxLength(30),
      ]),
      addArtworkPackaging: new FormControl(null, [
        Validators.maxLength(30),
      ]),
      addArtworkClonable: new FormControl("", [
        Validators.maxLength(5),
      ]),
      addArtworkCloneOnOrder: new FormControl("", [
        Validators.maxLength(5),
      ]),
      addArtworkArtistsproof: new FormControl("", [
        Validators.maxLength(30),
      ]),
      addArtworkSigning: new FormControl("", [
        Validators.maxLength(30),
      ]),
      addArtworkLocation: new FormControl(null, [
        Validators.maxLength(30),
      ]),
      addArtworkLocationInformation: new FormControl("", [
        Validators.maxLength(2048)
      ]),
      addArtworkDuration: new FormControl("", [
        Validators.maxLength(15)
      ]),
      addArtworkDeliveryOnOrder: new FormControl("", [
        Validators.maxLength(30),
      ]),
      addArtworkAttachments: new FormControl("", [
        Validators.maxLength(10),
      ]),
      addArtworkTags: new FormControl("", [
        Validators.maxLength(2048)
      ]),
      addArtworkListNotes: new FormControl("", [
        Validators.maxLength(2048)
      ]),
      addArtworkNotes: new FormControl("", [
        Validators.maxLength(2048)
      ]),
      addArtworkComments: new FormControl("", [
        Validators.maxLength(2048)
      ]),
      addArtworkVisibility: new FormControl("", [
        Validators.maxLength(10),
      ]),
      updateOn: "submit"
    });

    this.todaysDate = {
      isRange: false,
      singleDate: {date: {year: new Date().getFullYear(), month: new Date().getMonth() + 1, day: new Date().getDate()}}
    };
    this.validDate = {
      isRange: false,
      singleDate: {date: {year: new Date().getFullYear(), month: new Date().getMonth() + 1, day: new Date().getDate()}}
    };

    this.activeArtwork.images = [];
    this.activeArtwork.attachments = [];
    this.filesToUpload = [];
    this.uploadInput = new EventEmitter<UploadInput>();
    this.humanizeBytes = humanizeBytes;
  }

  ngOnInit() {
    this.isLoading = true;
    this.addArtworkForm.patchValue({
      addArtworkOwner: this.activeAccount
    });
    this.getAccountData();
    this.getNextArtworkCode();
  }

  public validateName(): void {
    const itemObject = {
      owner: this.addArtworkForm.value.addArtworkOwner,
      name: this.addArtworkForm.value.addArtworkName/*,
      type: this.addArtworkForm.value.addArtworkType*/
    };

    this.itemService.validateItemName(itemObject).subscribe(
      response => {
        if (response.error) {
          this.artworkNameMightNotBeUnique = true;
        } else {
          this.artworkNameMightNotBeUnique = false;
        }
      }
    );
  }

  public validateArtworkCode(): void {
    const itemObject = {
      owner: this.addArtworkForm.value.addArtworkOwner,
      code: this.addArtworkForm.value.addArtworkCode
    };

    this.itemService.validateItemArtworkCode(itemObject).subscribe(
      response => {
        if (response.error) {
          this.artworkArtworkCodeMightNotBeUnique = true;
        } else {
          this.artworkArtworkCodeMightNotBeUnique = false;
        }
      }
    );
  }

  getAccountData() {
    const getObject = {
      accountId: this.activeAccount,
      requester: "artworks",
      sortingKey: this.sortingKey ? this.sortingKey : "_id",
      sortingOrder: this.sortAscending ? "asc" : "desc",
      skipQuery: (this.currentPage - 1) * this.limitQuery,
      limitQuery: this.limitQuery,
      contentTypes: ["account", "artist", "item", "variable", "itemCount"]
    };

    this.accountService.getAccountData(getObject).subscribe(
      data => {
        this.account = data.account;
        this.artists = data.artists
          .map((artist: any) => {
            artist.fullName = artist.name + " " + artist.lastName;
            return artist;
          });
        this.items = data.items;
        this.variables = data.variables ? data.variables : [];
        this.pageCount = Math.ceil(data.itemsCount / this.limitQuery);
        if (this.pageCount > 0) {
          this.pages = Array(this.pageCount).fill(null);
        }
      },
      () => {
        UIkit.notification({
          message: "Error fetching clients",
          status: "danger",
          pos: "top-right",
          timeout: 1500
        });
      },
      () => {
        this.isLoading = false;
      }
    );
  }

  getNextArtworkCode(): void {
    this.isLoading = true;
    this.accountService.getAccount({ _id: this.activeAccount }).subscribe(
      data => {
        const artworkCodePlaceholder = data.account.account.artworkCodeName ? data.account.account.artworkCodeName + "-" + data.account.account.artworkCodeNumber : data.account.account.artworkCodeNumber;
        this.addArtworkForm.patchValue({
          addArtworkCode: artworkCodePlaceholder
        });
      },
      () => {
        UIkit.notification({
          message: "Error fetching account",
          status: "danger",
          pos: "top-right",
          timeout: 1500
        });
      },
      () => {
        this.isLoading = false;
      }
    );
  }

  openAddNewArtwork() {
    this.addNewArtworkModalWindow = window.document.getElementById("addNewArtwork");
    UIkit.modal(this.addNewArtworkModalWindow).show();
  }

  openRemoveArtwork(artworkId: string) {
    if (!artworkId) { return; }

    this.artworkToRemove = artworkId;
    this.removeArtworkModalWindow = window.document.getElementById("removeArtwork");
    UIkit.modal(this.removeArtworkModalWindow).show();
  }

  onUploadOutput(output: UploadOutput): void {
    const properFile = output.file && output.file.name && output.file.name.length > 0;

    if (output.type === "allAddedToQueue") {
      const event: UploadInput = {
        type: "uploadAll",
        url: "/api/file",
        method: "POST",
        includeWebKitFormBoundary: true
      };
      this.uploadInput.emit(event);
    } else if (output.type === "addedToQueue" && properFile) {
      if (this.filesToUpload.indexOf(output.file) === -1) {
        this.filesToUpload.push(output.file);
      }
    } else if (output.type === "uploading" && properFile) {
      if (!this.activeArtwork["images"].some(image => image.placeholderId === output.file.id)) {
        this.uploadLoading = true;
        this.activeService.increasePendingRequests();
        this.activeArtwork["images"].push({ placeholderId: output.file.id });
        const index = this.filesToUpload.findIndex(file => file.id === output.file.id);
        this.filesToUpload[index] = output.file;
      }
    } else if (output.type === "removed") {
      // remove file from array when removed
      this.filesToUpload = this.filesToUpload.filter((file: UploadFile) => file !== output.file);
    } else if (output.type === "dragOver") {
      this.dragOver = true;
    } else if (output.type === "dragOut") {
      this.dragOver = false;
    } else if (output.type === "drop") {
      this.dragOver = false;
    } else if (output.type === "removedAll") {
      this.filesToUpload.length = 0;
    } else if (output.type === "done") {

      const fileObject = {
        itemId: output.file.response,
        fileId: output.file.response,
      };

      this.removeFile(output.file.id);
      const indexToReplace = this.activeArtwork["images"].findIndex(file => file.placeholderId === output.file.id);
      this.activeArtwork["images"][indexToReplace] = fileObject;
      this.activeService.decreasePendingRequests();
      this.uploadLoading = false;
    }
  }

  onAttachmentsUploadOutput(output: UploadOutput): void {
    const properFile = output.file && output.file.name && output.file.name.length > 0;

    if (output.type === "allAddedToQueue") {
      const event: UploadInput = {
        type: "uploadAll",
        url: "/api/file",
        method: "POST",
        includeWebKitFormBoundary: true
      };
      this.uploadInput.emit(event);
    } else if (output.type === "addedToQueue" && properFile) {
      if (this.filesToUpload.indexOf(output.file) == -1) {
        this.filesToUpload.push(output.file);
      }
    } else if (output.type === "uploading" && properFile) {
      if (!this.activeArtwork["attachments"].some(image => image.placeholderId === output.file.id)) {
        this.uploadLoading = true;
        this.activeService.increasePendingRequests();
        this.activeArtwork["attachments"].push({ placeholderId: output.file.id });
        const index = this.filesToUpload.findIndex(file => file.id === output.file.id);
        this.filesToUpload[index] = output.file;
      }
    } else if (output.type === "removed") {
      // remove file from array when removed
      this.filesToUpload = this.filesToUpload.filter((file: UploadFile) => file !== output.file);
    } else if (output.type === "dragOver") {
      this.dragOver = true;
    } else if (output.type === "dragOut") {
      this.dragOver = false;
    } else if (output.type === "drop") {
      this.dragOver = false;
    } else if (output.type === "removedAll") {
      this.filesToUpload.length = 0;
    } else if (output.type === "done") {
      const fileObject = {
        itemId: output.file.response, // this.activeArtworkId Doesnt exist yet
        fileId: output.file.response,
        name: output.file.name,
        contentType: output.file.type
      };

      this.removeFile(output.file.id);
      const indexToReplace = this.activeArtwork["attachments"].findIndex(file => file.placeholderId === output.file.id);
      this.activeArtwork["attachments"][indexToReplace] = fileObject;
      this.activeService.decreasePendingRequests();
      this.uploadLoading = false;
    }
  }

  startUpload(): void {
    const event: UploadInput = {
      type: "uploadAll",
      url: "/api/file",
      method: "POST",
      includeWebKitFormBoundary: true,
    };
    this.uploadInput.emit(event);
  }

  cancelUpload(id: string): void {
    this.uploadInput.emit({ type: "cancel", id: id });
  }

  removeFile(id: string): void {
    this.uploadInput.emit({ type: "remove", id: id });
  }

  openAddArtworkImage() {
    this.addArtworkImageModalWindow = window.document.getElementById("addArtworkImages");
    UIkit.modal(this.addArtworkImageModalWindow).show();
  }

  selectCover(fileId: string): void {
    this.addArtworkForm.patchValue(
      {
        addArtworkCoverImage: fileId
      }
    );
    this.activeArtwork["coverImage"] = fileId;
  }

  addArtwork() {
    const addArtworkObject = {
      owner: this.addArtworkForm.value.addArtworkOwner,
      name: this.addArtworkForm.value.addArtworkName,
      description: this.addArtworkForm.value.addArtworkDescription,
      images: this.activeArtwork["images"],
      coverImage: this.activeArtwork["coverImage"],
      code: this.addArtworkForm.value.addArtworkCode,
      /*length: this.addArtworkForm.value.addArtworkHeight,*/
      width: this.addArtworkForm.value.addArtworkWidth,
      height: this.addArtworkForm.value.addArtworkHeight,
      depth: this.addArtworkForm.value.addArtworkDepth,
      weight: this.addArtworkForm.value.addArtworkWeight,
      frame: this.addArtworkForm.value.addArtworkFrame,
      frameHeight: this.addArtworkForm.value.addArtworkFrameHeight,
      frameWidth: this.addArtworkForm.value.addArtworkFrameWidth,
      frameDepth: this.addArtworkForm.value.addArtworkFrameDepth,
      started: this.addArtworkForm.value.addArtworkStarted,
      created: this.addArtworkForm.value.addArtworkCreated,
      price: this.addArtworkForm.value.addArtworkPrice,
      srp: this.addArtworkForm.value.addArtworkSRP,
      artist: this.addArtworkForm.value.addArtworkArtist,
      technique: this.addArtworkForm.value.addArtworkTechnique,
      material: this.addArtworkForm.value.addArtworkMaterial,
      type: this.addArtworkForm.value.addArtworkType,
      seller: this.addArtworkForm.value.addArtworkSeller,
      sellerContactInfo: this.addArtworkForm.value.addArtworkSellerContactInfo,
      purchaseDate: this.addArtworkForm.value.addArtworkPurchaseDate ? moment(this.addArtworkForm.value.addArtworkPurchaseDate.singleDate.jsDate).format() : moment(new Date),
      purchasePrice: this.addArtworkForm.value.addArtworkPurchasePrice,
      minimumPrice: this.addArtworkForm.value.addArtworkMinimumPrice,
      maximumPrice: this.addArtworkForm.value.addArtworkMaximumPrice,
      estimateBasis: this.addArtworkForm.value.addArtworkEstimateBasis,
      estimator: this.addArtworkForm.value.addArtworkEstimator,
      estimatorInformation: this.addArtworkForm.value.addArtworkEstimatorInformation,
      collateralValue: this.addArtworkForm.value.addArtworkCollateralValue,
      conservationDate: this.addArtworkForm.value.addArtworkConservationDate ? moment(this.addArtworkForm.value.addArtworkConservationDate.singleDate.jsDate).format() : moment(new Date),
      conservationCompany: this.addArtworkForm.value.addArtworkConservationCompany,
      conservationName: this.addArtworkForm.value.addArtworkConservationName,
      conservationDetails: this.addArtworkForm.value.addArtworkConservationDetails,
      priceMultiplier: this.addArtworkForm.value.addArtworkPriceMultiplier,
      availability: this.addArtworkForm.value.addArtworkAvailability,
      logistics: this.addArtworkForm.value.addArtworkLogistics,
      packaging: this.addArtworkForm.value.addArtworkPackaging,
      clonable: this.addArtworkForm.value.addArtworkClonable,
      cloneOnOrder: this.addArtworkForm.value.addArtworkCloneOnOrder,
      artistsproof: this.addArtworkForm.value.addArtworkArtistsproof,
      signing: this.addArtworkForm.value.addArtworkSigning,
      location: this.addArtworkForm.value.addArtworkLocation,
      locationInformation: this.addArtworkForm.value.addArtworkLocationInformation,
      duration: this.addArtworkForm.value.addArtworkDuration,
      deliveryOnOrder: this.addArtworkForm.value.addArtworkDeliveryOnOrder,
      attachments: this.activeArtwork.attachments,
      tags: this.addArtworkForm.value.addArtworkTags,
      listNotes: this.addArtworkForm.value.addArtworkListNotes,
      notes: this.addArtworkForm.value.addArtworkNotes,
      comments: this.addArtworkForm.value.addArtworkComments,
      visibility: this.addArtworkForm.value.addArtworkVisibility
    };
    this.itemService.addItem(addArtworkObject).subscribe(
      res => {
        this.ngOnInit();
        UIkit.modal(this.addNewArtworkModalWindow).hide();
        this.resetForm();
        UIkit.notification({
          message: "Saved successfully",
          status: "success",
          pos: "top-right",
          timeout: 1500
        });
      },
      error => {
        UIkit.notification({
          message: "Saving failed",
          status: "danger",
          pos: "top-right",
          timeout: 1500
        });
      }
    );
  }

  openArtwork(artworkId): void {
    let previousItems: Array<any>;
    let nextItems: Array<any>;
    const selectedItemIndex: number = this.items.findIndex((artwork: any) => artwork._id === artworkId);
    const itemsCount: number = this.items.length;

    if (selectedItemIndex > 0) {
      previousItems = this.items.slice(0, selectedItemIndex);
    }
    if (selectedItemIndex < itemsCount) {
      nextItems = this.items.slice(selectedItemIndex + 1, itemsCount);
    }

    this.router.navigateByUrl("/artwork/" + artworkId,
      {
        state: {
          currentItem: this.items[selectedItemIndex],
          previousItems: previousItems,
          nextItems: nextItems,
        }
      }
    );
  }

  resetArtworkToRemove(): void {
    this.artworkToRemove = null;
  }

  removeArtwork(): void {
    if (!this.artworkToRemove) {
      return;
    }

    this.itemService.deleteItem(this.artworkToRemove).subscribe(
      () => {
        this.ngOnInit();
        UIkit.notification({
          message: "Artwork removed successfully",
          status: "success",
          pos: "top-right",
          timeout: 1500
        });
      },
      () => {
        UIkit.notification({
          message: "Error removing artwork",
          status: "danger",
          pos: "top-right",
          timeout: 1500
        });
      }
    );
  }

  openAttachment(attachment) {
    this.itemService.openAttachment(this.activeAccount, attachment).then((response: any) => {
      if (response.url) {
        window.open(response.url);
      } else {
        UIkit.notification({
          message: "Error fetching data",
          status: "danger",
          pos: "top-right",
          timeout: 1500
        });
      }
    });
  }

  removeAttachment(attachment) {
    const attachmentToSplice = this.activeArtwork.attachments.find((accountAttachment: any) => {
      return attachment.fileId === accountAttachment.fileId;
    });
    this.activeArtwork.attachments.splice(this.activeArtwork.attachments.indexOf(attachmentToSplice), 1);

    UIkit.notification({
      message: "File removed successfully",
      status: "success",
      pos: "top-right",
      timeout: 1500
    });

    /*const attachmentObject = {
      documentType: 'item',
      itemId: attachment.itemId,
      fileId: attachment.fileId
    };

    this.fileService.removeFile(attachmentObject).subscribe(
      res => {
        const attachmentToSplice = this.activeArtwork.attachments.find((accountAttachment: any) => {
          return attachment.fileId === accountAttachment.fileId;
        });
        this.activeArtwork.attachments.splice(this.activeArtwork.attachments.indexOf(attachmentToSplice), 1);
        UIkit.notification({
          message: 'File removed successfully',
          status: 'success',
          pos: 'top-right',
          timeout: 1500
        });
      },
      error => {
        UIkit.notification({
          message: 'Removing failed',
          status: 'danger',
          pos: 'top-right',
          timeout: 1500
        });
      }
    );*/
  }

  sortItems(sortingKey: string): void {
    if (sortingKey !== this.sortingKey) {
      this.sortingKey = sortingKey;
    } else {
      this.sortAscending = !this.sortAscending;
    }
    this.activeService.setActiveSortingKey(this.sortingKey);
    this.activeService.setActiveSortAscending(this.sortAscending);
    this.getAccountData();
  }

  activeSort(key: string): boolean {
    return this.sortingKey === key;
  }

  setLimit(limit: number): void {
    if (limit !== this.limitQuery) {
      this.limitQuery = limit;
      /* Set currentPage to 0 to start from the first page */
      this.currentPage = 1;
      this.activeService.setActiveLimitQuery(this.limitQuery);
      this.activeService.setActiveCurrentPage(this.currentPage);
      this.getAccountData();
    }
  }

  activeLimit(limit: number): boolean {
    return this.limitQuery === limit;
  }

  setCurrentPageString(page: string): void {
    this.setCurrentPage(parseInt(page, 0));
  }

  setCurrentPage(page: number): void {
    if ((page !== this.currentPage) && (page <= this.pageCount)) {
      this.currentPage = page;
      this.activeService.setActiveCurrentPage(this.currentPage);
      this.getAccountData();
    }
  }

  resetForm(): void {
    const previousArtist = this.addArtworkForm.value.addArtworkArtist;
    const previousArtworkType = this.addArtworkForm.value.addArtworkType;

    this.addArtworkForm.reset();
    this.addArtworkForm.patchValue({
      addArtworkOwner: this.activeAccount,
      addArtworkArtist: null,
      addArtworkType: "",
    });

    this.getNextArtworkCode();

    this.activeArtwork.images = [];
    this.activeArtwork.attachments = [];
    this.filesToUpload = [];
  }

  getArtistInformation(): void {
    const actorId = this.addArtworkForm.value.addArtworkArtist;
    if (![null, undefined, ""].includes(actorId)) {
      this.actorService.getActor(actorId).subscribe(
        response => {
          if (response && response.actor && response.actor.priceMultiplier) {
            this.artistMultiplier = response.actor.priceMultiplier;
            if ([null, undefined, ""].includes(this.addArtworkForm.value.addArtworkPriceMultiplier)) {
              this.addArtworkForm.patchValue({
                addArtworkPriceMultiplier: this.artistMultiplier
              });
            }
          }
        },
        () => {
          UIkit.notification({
            message: "Error fetching information",
            status: "danger",
            pos: "top-right",
            timeout: 1500
          });
        }
      );
    }
  }

  getCharactersLimitError(fieldName: string) {
    if (!fieldName || !this.addArtworkForm.get(fieldName).value ) {
      return "";
    }

    const charactersOverLimit: number = this.addArtworkForm.get(fieldName).value.length - this.addArtworkForm.get(fieldName).errors.maxlength.requiredLength;

    return `Field length is ${ charactersOverLimit } characters over limit`;
  }

  getCharactersLeft(fieldName: string, charactersLimit: number) {
    if (!fieldName || !this.addArtworkForm.get(fieldName).value ) {
      return "";
    }

    const fieldLength: number = this.addArtworkForm.get(fieldName).value.length;
    const charactersOverLimit: number = fieldLength - charactersLimit;

    if (charactersOverLimit >= 0) { return `${ charactersLimit } / ${ charactersLimit }`; }

    return `${ fieldLength } / ${ charactersLimit }`;
  }

  updateDate(fieldName: string, dateObject: any) {
    this.addArtworkForm.controls[fieldName].patchValue(moment(dateObject.singleDate.jsDate).format());
  }
}
