<div class="uk-child-width-expand uk-flex-middle uk-flex-center" uk-height-viewport="offset-top: true" uk-grid>
  <div class="uk-first-column uk-text-center uk-height-1-1 uk-flex uk-flex-center uk-flex-middle">
    <div class="card">
      <h1 class="card-header">Page not found</h1>
      <div class="card-block">
        <p>The page you requested was not found.</p>
        <a routerLink="/" class="uk-button uk-button-primary uk-margin-medium-top">Go to overview</a>
      </div>
    </div>
  </div>
  <!--<div class="uk-background-muted uk-flex uk-flex-bottom uk-height-1-1">
    <div class="uk-flex uk-flex-around">
      <div class="uk-flex uk-flex-middle uk-margin-remove-vertical uk-margin-large-right uk-logo">
        <img class="uk-preserve-width" src="assets/images/artbuddy_logo.svg" width="150">
      </div>
      <div class="uk-text-middle uk-margin-small-bottom">
        <p>© 2023 Artbuddy. All rights reserved.</p>
        <p>With any questions or technical matters please contact support@artbuddy.com.</p>
        <p>
          <a routerLink="/terms" class="uk-link-text uk-margin-medium-right">Terms of service</a>
          <a routerLink="/terms" class="uk-link-text uk-margin-medium-right">Privacy policy</a>
        </p>
      </div>
    </div>
  </div>-->
</div>
