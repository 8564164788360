<app-toast [message]="toast.message"></app-toast>

<div id="register" uk-height-viewport="offset-top: 80" class="uk-flex uk-flex-middle uk-text-center uk-section">
  <div class="uk-container uk-width-viewport">
    <div class="uk-grid-match uk-child-width-expand@m" uk-grid>
      <div class="uk-flex-center">
        <div class="uk-card uk-card-default uk-width-1-2@s">
          <div class="uk-card-header no-min-height">
            <div class="uk-grid-small uk-flex-middle" uk-grid>
              <div class="uk-width-expand">
                <h3 class="uk-card-title uk-margin-remove-bottom">Register</h3>
              </div>
            </div>
          </div>
          <form appNoSubmitEnter [formGroup]="registerForm" (ngSubmit)="register()" class="uk-form-stacked">
            <div class="uk-card-body uk-text-left">
              <div class="uk-margin">
                <div class="uk-width-1-1">
                  <div class="uk-margin">
                    <label class="uk-form-label" for="registerEmail">Email</label>
                    <div class="uk-form-controls">
                      <input autoFocus id="registerEmail" name="registerEmail" formControlName="registerEmail" type="text" placeholder="Email ..." class="uk-input" [ngClass]="{'uk-form-danger': (registerForm.get('registerEmail').touched && registerForm.get('registerEmail').hasError('required')) || registerForm.get('registerEmail').invalid, 'uk-form-blank': registerForm.get('registerEmail').valid || registerForm.get('registerEmail').untouched }">
                      <div *ngIf="registerForm.get('registerEmail').invalid && (registerForm.get('registerEmail').dirty || registerForm.get('registerEmail').touched)" class="uk-text-danger uk-text-small">
                        <div *ngIf="registerForm.get('registerEmail').hasError('required')">
                          Email is required
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="uk-width-1-1">
                  <div class="uk-margin">
                    <label class="uk-form-label" for="registerPassword">Password</label>
                    <div class="uk-form-controls">
                      <input id="registerPassword" name="registerPassword" formControlName="registerPassword" type="password" placeholder="Password ..." class="uk-input" [ngClass]="{'uk-form-danger': ((registerForm.get('registerPassword').touched && registerForm.get('registerPassword').hasError('required')) || registerForm.get('registerPassword').invalid), 'uk-form-blank': registerForm.get('registerPassword').valid || registerForm.get('registerPassword').untouched }">
                      <div *ngIf="registerForm.get('registerPassword').invalid && (registerForm.get('registerPassword').dirty || registerForm.get('registerPassword').touched)" class="uk-text-danger uk-text-small">
                        <div *ngIf="registerForm.get('registerPassword').hasError('required')">
                          Password is required
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="uk-width-1-1">
                  <div class="uk-margin">
                    <label class="uk-form-label" for="registerRole">Role</label>
                    <div class="uk-form-controls">
                      <select id="registerRole" name="registerRole" formControlName="registerRole" class="uk-select">
                        <option value="" disabled>Please select ...</option>
                        <option value="admin">Admin</option>
                        <option value="employee">Employee</option>
                        <option value="user">User</option>
                      </select>
                      <div *ngIf="registerForm.get('registerRole').invalid && registerForm.get('registerRole').touched" class="uk-text-danger uk-text-small">
                        <div *ngIf="registerForm.get('registerRole').hasError('required')">
                          Role is required
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="uk-card-footer uk-text-center">
              <button type="submit" class="uk-button uk-button-primary" [disabled]="!registerForm.valid">Register</button>
            </div>
          </form>
        </div>
      </div>
    </div>
  </div>
</div>
