import {Component, EventEmitter, OnInit} from '@angular/core';
import {FormBuilder, FormControl, FormGroup, Validators} from '@angular/forms';
import {ActivatedRoute, Router} from '@angular/router';
import {ActiveService} from '../../services/active.service';
import {AuthService} from '../../services/auth.service';
import {UserService} from '../../services/user.service';
import {AccountService} from '../../services/account.service';
import {FileService} from '../../services/file.service';
import {ActorService} from '../../services/actor.service';
import {ItemService} from '../../services/item.service';
import {humanizeBytes, UploaderOptions, UploadFile, UploadInput, UploadOutput} from 'ngx-uploader';

declare var UIkit: any;

@Component({
  selector: 'app-single-client',
  templateUrl: './single-client.component.html',
  styleUrls: ['./single-client.component.scss']
})
export class SingleClientComponent implements OnInit {
  public user: any = {};
  public activeData: Object = {};
  public actorType: string;
  public clientType: string;
  public activeClient: any = {};
  public activeClientId = '';
  public activeAccount: any = {};
  public clients: Array<Object> = [];
  public variables: any = [];
  public isLoading = true;
  public uploadLoading: Boolean = false;
  public editClientForm: FormGroup;
  public allArtists: any = [];
  public activeClientArtworks: Array<Object> = [];
  public activeClientArtists: Array<Object> = [];
  public activeClientBids: Array<Object> = [];
  public activeClientReservations: Array<Object> = [];
  public activeClientTrades: Array<any> = [];
  public activeClientClientGroups: Array<any> = [];
  public activeClientContactMethods: Array<any> = [];
  public selectedClientGroups: Array<any> = [];
  public selectedContactMethods: Array<any> = [];
  public activeClientLanguages: Array<any> = [];
  public selectedLanguages: Array<any> = [];
  public addExternalNotesForm: FormGroup;
  public editExternalNotesForm: FormGroup;
  public addInterestingArtistModalWindow: any;
  public addExternalNotesModalWindow: any;
  public viewExternalNotesModalWindow: any;
  public editExternalNotesModalWindow: any;
  public activeExternalNote: any = null;
  public numberWithDecimal = new RegExp(/^[0-9]+(\.[0-9]{1,2})?$/);

  public options: UploaderOptions;
  public formData: FormData;
  public filesToUpload: UploadFile[];
  public uploadInput: EventEmitter<UploadInput>;
  public humanizeBytes: Function;
  public dragOver: boolean;

  public sortingKey: string;
  public sortAscending = false;
  public viewList = true;

  constructor(
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private activeService: ActiveService,
    private formBuilder: FormBuilder,
    private auth: AuthService,
    private userService: UserService,
    private accountService: AccountService,
    private actorService: ActorService,
    private fileService: FileService,
    private itemService: ItemService
  ) {
    this.activeService.activeDataChange.subscribe(data => {
      this.activeData = data;
    });

    this.activatedRoute.data.subscribe(data => {
      this.activeClient = data.resolvedData.actor;
      this.activeClientId = data.resolvedData.actor._id;
      this.clientType = data.resolvedData.actor._type;
    });

    this.editClientForm = this.formBuilder.group({
      editClientActorType: new FormControl('', [
        Validators.maxLength(100),
        Validators.maxLength(50)
      ]),
      editClientOwner: new FormControl('', [
        Validators.maxLength(100),
        Validators.maxLength(50)
      ]),
      editClientName: new FormControl('', [
        Validators.required,
        Validators.maxLength(100)
      ]),
      editClientLastName: new FormControl('', [
        Validators.maxLength(100)
      ]),
      editClientBID: new FormControl('', [
        Validators.maxLength(100)
      ]),
      /*editClientSSN: new FormControl('', [
        Validators.maxLength(100)
      ]),*/
      editClientClientCode: new FormControl('', [
        Validators.maxLength(100)
      ]),
      editClientBillingInfo: new FormControl('', [
        Validators.maxLength(2048)
      ]),
      editClientDescription: new FormControl('', [
        Validators.maxLength(2048)
      ]),
      editClientOrganisation: new FormControl('', [
        Validators.maxLength(100)
      ]),
      editClientGroup: new FormControl('', [
        Validators.maxLength(100)
      ]),
      editClientLanguages: new FormControl('', [
        Validators.maxLength(100)
      ]),
      editClientContactMethod: new FormControl('', [
        Validators.maxLength(30)
      ]),
      editClientStatus: new FormControl('', [
        Validators.maxLength(30)
      ]),
      editClientStreetAddress: new FormControl('', [
        Validators.maxLength(100)
      ]),
      editClientCity: new FormControl('', [
        Validators.maxLength(30)
      ]),
      editClientState: new FormControl('', [
        Validators.maxLength(30)
      ]),
      editClientZip: new FormControl('', [
        Validators.maxLength(30),
        Validators.pattern(this.numberWithDecimal)
      ]),
      editClientCountry: new FormControl('', [
        Validators.maxLength(30)
      ]),
      editClientPhone: new FormControl('', [
        Validators.maxLength(30),
        // Validators.pattern(this.numberWithDecimal)
      ]),
      editClientEmail: new FormControl('', [
        Validators.maxLength(100)
      ]),
      editClientVisitingStreetAddress: new FormControl('', [
        Validators.maxLength(100)
      ]),
      editClientVisitingCity: new FormControl('', [
        Validators.maxLength(30)
      ]),
      editClientVisitingState: new FormControl('', [
        Validators.maxLength(30)
      ]),
      editClientVisitingZip: new FormControl('', [
        Validators.maxLength(30),
        Validators.pattern(this.numberWithDecimal)
      ]),
      editClientVisitingCountry: new FormControl('', [
        Validators.maxLength(30)
      ]),
      editClientBillingPostalCode: new FormControl('', [
        Validators.maxLength(30),
        Validators.pattern(this.numberWithDecimal)
      ]),
      editClientBillingElectronicInvoiceAddress: new FormControl('', [
        Validators.maxLength(100)
      ]),
      editClientBillingEmail: new FormControl('', [
        Validators.maxLength(100)
      ]),
      editClientContactPersonName: new FormControl('', [
        Validators.maxLength(100)
      ]),
      editClientContactPersonLastName: new FormControl('', [
        Validators.maxLength(100)
      ]),
      editClientContactPersonRole: new FormControl('', [
        Validators.maxLength(2048)
      ]),
      editClientContactPersonEmail: new FormControl('', [
        Validators.maxLength(100)
      ]),
      editClientContactPersonPhone: new FormControl('', [
        Validators.maxLength(30),
        // Validators.pattern(this.numberWithDecimal)
      ]),
      editClientAttachments: new FormControl('', [
        Validators.maxLength(100)
      ]),
      editClientType: new FormControl('', [
        Validators.maxLength(30)
      ]),
      editClientNotes: new FormControl('', [
        Validators.maxLength(2048)
      ]),
      editClientComments: new FormControl('', [
        Validators.maxLength(2048)
      ]),
      editClientTags: new FormControl('', [
        Validators.maxLength(2048)
      ]),
      editClientVisibility: new FormControl('', [
        Validators.maxLength(100)
      ])
    });

    this.addExternalNotesForm = this.formBuilder.group({
      addExternalNotesFormClient: new FormControl('', [
        Validators.required,
        Validators.maxLength(100)
      ]),
      addExternalNotesFormNote: new FormControl('', [
        Validators.maxLength(100)
      ])
    });

    this.editExternalNotesForm = this.formBuilder.group({
      editExternalNotesFormClient: new FormControl('', [
        Validators.required,
        Validators.maxLength(100)
      ]),
      editExternalNotesFormExternalNotesId: new FormControl('', [
        Validators.maxLength(100)
      ]),
      editExternalNotesFormNote: new FormControl('', [
        Validators.maxLength(10000),
        Validators.required
      ])
    });

    this.filesToUpload = [];
    this.uploadInput = new EventEmitter<UploadInput>();
    this.humanizeBytes = humanizeBytes;
  }

  ngOnInit() {
    this.actorType = 'Client';
    this.activeService.getActiveData().subscribe(
      data => {
        this.user = data.user.id;
        this.activeAccount = data.account.id;
        this.getAccountData();
      },
      () => {
        UIkit.notification({
          message: 'Error fetching user information',
          status: 'danger',
          pos: 'top-right',
          timeout: 1500
        });
      },
      () => {}
    );
  }

  getAccountData() {
    const getObject = {
      accountId: this.activeAccount,
      requester: 'client',
      requesterId: this.activeClientId,
      sortingKey: this.sortingKey ? this.sortingKey : '_id',
      sortingOrder: this.sortAscending ? 'asc' : 'desc',
      skipQuery: 0,
      limitQuery: null,
      contentTypes: ['account', 'artist', 'bid', 'reservation', 'trade', 'variable']
    };

    this.accountService.getAccountData(getObject).subscribe(
      data => {
        this.activeClientClientGroups = data.account.clientGroupTitles ?? [];
        this.activeClientLanguages = data.account.languageTitles ?? [];
        this.activeClientContactMethods = data.account.contactMethodTitles ?? [];
        this.allArtists = data.artists ?? [];
        this.activeClientArtists = [];
        this.activeClientBids = data.bids ?? [];
        this.activeClientReservations = data.reservations ?? [];
        this.activeClientTrades = data.trades ?? [];
        this.activeClientReservations = this.activeClientReservations.filter((reservation: any) => {
          return (reservation.buyer && reservation.buyer._id === this.activeClientId) || (reservation.seller && reservation.seller._id === this.activeClientId);
        });
        this.activeClientTrades = this.activeClientTrades.filter((trade: any) => {
          const activeClientFound = (trade.buyer && trade.buyer._id === this.activeClientId) || (trade.seller && trade.seller._id === this.activeClientId);
          if (activeClientFound && trade.items && trade.items.length) {
            trade.items.forEach(item => {
              if (!this.activeClientArtworks.some((artwork: any) => artwork._id === item._id)) {
                this.activeClientArtworks.push(item);

                if (!this.activeClientArtists.some((artist: any) => artist._id === item.artist._id)) {
                  this.activeClientArtists.push(item.artist);
                }
              }
            });
          }
          return activeClientFound;
        });
        this.activeClient.interestingArtists = this.activeClient.interestingArtists.map((interestingArtist: any) => {
          const allArtistIndex = this.allArtists.findIndex(allArtist => allArtist._id === interestingArtist._id);
          if (allArtistIndex !== -1) {
            return this.allArtists[allArtistIndex];
          } else {
            console.warn('No interestingArtists found', interestingArtist);
          }
        }).filter(value => value); // filter out undefineds, if some artist was not found (breaks the view)

        if (!this.activeClient.clientGroup || this.activeClient.clientGroup.length === 0 || this.activeClient.clientGroup[0].length === 0) {
          this.selectedClientGroups = [];
        } else if (typeof this.activeClient.clientGroup === 'string') {
          this.selectedClientGroups = [
            { title: this.activeClient.clientGroup }
          ];
        } else {
          this.selectedClientGroups = this.activeClient.clientGroup.map((item: any) => {
            if (typeof item === 'object' && item.title) {
              return item;
            }

            if (typeof this.activeClient.clientGroup[0] === 'object' && (!('title' in this.activeClient.clientGroup[0]))) {

              const keys = Object.keys(this.activeClient.clientGroup[0]);
              if (keys.length === 1 && keys[0] === '_id') {
                return {
                  title: '',
                };
              }

              return {
                title: Object.values(this.activeClient.clientGroup[0]).join(''),
              };
            }
            return {
              title: item.title,
            };
          });
        }

        if (!this.activeClient.contactMethod || this.activeClient.contactMethod.length === 0 || this.activeClient.contactMethod[0].length === 0) {
          this.selectedContactMethods = [];
        } else if (typeof this.activeClient.contactMethod === 'string') {
          this.selectedContactMethods = [
            { title: this.activeClient.contactMethod }
          ];
        } else {
          this.selectedContactMethods = this.activeClient.contactMethod.map((item: any) => {
            if (typeof item === 'object' && item.title) {
              return item;
            }

            if (typeof this.activeClient.contactMethod[0] === 'object' && (!('title' in this.activeClient.contactMethod[0]))) {

              const keys = Object.keys(this.activeClient.contactMethod[0]);
              if (keys.length === 1 && keys[0] === '_id') {
                return {
                  title: '',
                };
              }

              return {
                title: Object.values(this.activeClient.contactMethod[0]).join(''),
              };
            }
            return {
              title: item.title,
            };
          });
        }

        this.selectedClientGroups = this.selectedClientGroups.filter(item => !['', undefined, null].includes(item.title));
        this.selectedContactMethods = this.selectedContactMethods.filter(item => !['', undefined, null].includes(item.title));

        if (!this.activeClient.languages || this.activeClient.languages.length === 0 || this.activeClient.languages[0].length === 0) {
          this.selectedLanguages = [];
        } else if (typeof this.activeClient.languages === 'string') {
          this.selectedLanguages = [
            { title: this.activeClient.languages }
          ];
        } else {
          this.selectedLanguages = this.activeClient.languages.map((item: any) => {
            return {
              title: item.title,
            };
          });
        }

        this.variables = data.variables ? data.variables : [];
      },
      () => {
        UIkit.notification({
          message: 'Error fetching client',
          status: 'danger',
          pos: 'top-right',
          timeout: 1500
        });
      },
      () => {
        const mergedArrays = [
          ...this.activeClientBids,
          ...this.activeClientReservations,
          ...this.activeClientTrades
        ];

        for (const item of mergedArrays) {
          if (item.items && item.items.length) {
            for (const artwork of item.items) {
              const index = this.activeClientArtists.findIndex((artist: any) => {
                if (
                  !artist ||
                  !artist._id ||
                  !artwork ||
                  !artwork.artist ||
                  !artwork.artist._id
                ) {
                  return -1;
                }

                return artist._id === artwork.artist._id;
              });

              if (
                index === -1 &&
                artwork &&
                artwork.artist
              ) {
                this.activeClientArtists.push(artwork.artist);
              }
            }
          }
        }

        this.isLoading = false;
      }
    );
  }

  clientGroupIsSelected(item: any): boolean {
    return this.selectedClientGroups.findIndex((clientGroup: any) => clientGroup.title === item.title) > -1;
  }

  setClientGroups(item: any): void {
    const foundIndex = this.selectedClientGroups.findIndex((clientGroup: any) => clientGroup.title === item.title);

    if (foundIndex > -1) {
      this.selectedClientGroups.splice(foundIndex, 1);
    } else {
      this.selectedClientGroups.push(item);
    }
  }

  contactMethodIsSelected(item: any): boolean {
    return this.selectedContactMethods.findIndex((clientGroup: any) => clientGroup.title === item.title) > -1;
  }

  setContactMethod(item: any): void {
    const foundIndex = this.selectedContactMethods.findIndex((clientGroup: any) => clientGroup.title === item.title);

    if (foundIndex > -1) {
      this.selectedContactMethods.splice(foundIndex, 1);
    } else {
      this.selectedContactMethods.push(item);
    }
  }

  languageIsSelected(item: any): boolean {
    return this.selectedLanguages.findIndex((language: any) => language.title === item.title) > -1;
  }

  setLanguages(item: any): void {
    const foundIndex = this.selectedLanguages.findIndex((language: any) => language.title === item.title);

    if (foundIndex > -1) {
      this.selectedLanguages.splice(foundIndex, 1);
    } else {
      this.selectedLanguages.push(item);
    }
  }

  editClient() {
    const editClientObject = {
      actorType: this.editClientForm.value.editClientActorType,
      clientType: this.clientType,
      owner: this.activeAccount,
      clientCode: this.editClientForm.value.editClientClientCode,
      name: this.editClientForm.value.editClientName,
      lastName: this.editClientForm.value.editClientLastName,
      /*ssn: this.editClientForm.value.editClientSSN,*/
      bid: this.editClientForm.value.editClientBID,
      billingInfo: this.editClientForm.value.editClientBillingInfo,
      description: this.editClientForm.value.editClientDescription,
      organisation: this.editClientForm.value.editClientOrganisation,
      clientGroup: this.selectedClientGroups,
      contactMethod: this.selectedContactMethods,
      languages: this.selectedLanguages,
      status: this.editClientForm.value.editClientStatus,
      street: this.editClientForm.value.editClientStreetAddress,
      city: this.editClientForm.value.editClientCity,
      state: this.editClientForm.value.editClientState,
      zip: this.editClientForm.value.editClientZip,
      country: this.editClientForm.value.editClientCountry,
      phone: this.editClientForm.value.editClientPhone,
      email: this.editClientForm.value.editClientEmail,
      visitingStreet: this.editClientForm.value.editClientVisitingStreetAddress,
      visitingCity: this.editClientForm.value.editClientVisitingCity,
      visitingState: this.editClientForm.value.editClientVisitingState,
      visitingZip: this.editClientForm.value.editClientVisitingZip,
      visitingCountry: this.editClientForm.value.editClientVisitingCountry,
      billingPostalCode: this.editClientForm.value.editClientBillingPostalCode,
      billingElectronicInvoiceAddress: this.editClientForm.value.editClientBillingElectronicInvoiceAddress,
      billingEmail: this.editClientForm.value.editClientBillingEmail,
      contactPersonName: this.editClientForm.value.editClientContactPersonName,
      contactPersonLastName: this.editClientForm.value.editClientContactPersonLastName,
      contactPersonRole: this.editClientForm.value.editClientContactPersonRole,
      contactPersonPhone: this.editClientForm.value.editClientContactPersonPhone,
      contactPersonEmail: this.editClientForm.value.editClientContactPersonEmail,
      // attachments: this.editClientForm.value.editClientAttachments,
      interestingArtists: this.activeClient.interestingArtists,
      externalNotes: this.activeClient.externalNotes,
      attachments: this.activeClient.attachments,
      notes: this.editClientForm.value.editClientNotes,
      comments: this.editClientForm.value.editClientComments,
      tags: this.editClientForm.value.editClientTags,
      visibility: this.editClientForm.value.editClientVisibility,
      links: this.editClientForm.value.editClientLinks
    };
    this.actorService.editActor(this.activeClient._id, editClientObject).subscribe(
      res => {
        UIkit.notification({
          message: 'Saved successfully',
          status: 'success',
          pos: 'top-right',
          timeout: 1500
        });
      },
      error => {
        UIkit.notification({
          message: 'Saving failed',
          status: 'danger',
          pos: 'top-right',
          timeout: 1500
        });
      }
    );
  }

  onAttachmentsUploadOutput(output: UploadOutput): void {
    const properFile = output.file && output.file.name && output.file.name.length > 0;

    if (output.type === 'allAddedToQueue') {
      const event: UploadInput = {
        type: 'uploadAll',
        url: '/api/file',
        method: 'POST',
        includeWebKitFormBoundary: true
      };
      this.uploadInput.emit(event);
    } else if (output.type === 'addedToQueue' && properFile) {
      if (this.filesToUpload.indexOf(output.file) == -1) {
        this.filesToUpload.push(output.file);
      }
    } else if (output.type === 'uploading' && properFile) {
      if (!this.activeClient['attachments'].some(image => image.placeholderId === output.file.id)) {
        this.uploadLoading = true;
        this.activeService.increasePendingRequests();
        this.activeClient['attachments'].push({ placeholderId: output.file.id });
        const index = this.filesToUpload.findIndex(file => file.id === output.file.id);
        this.filesToUpload[index] = output.file;
      }
    } else if (output.type === 'removed') {
      // remove file from array when removed
      this.filesToUpload = this.filesToUpload.filter((file: UploadFile) => file !== output.file);
    } else if (output.type === 'dragOver') {
      this.dragOver = true;
    } else if (output.type === 'dragOut') {
      this.dragOver = false;
    } else if (output.type === 'drop') {
      this.dragOver = false;
    } else if (output.type === 'removedAll') {
      this.filesToUpload.length = 0;
    } else if (output.type === 'done') {
      const fileObject = {
        itemId: this.activeClientId,
        fileId: output.file.response,
        name: output.file.name,
        contentType: output.file.type
      };
      this.removeFile(output.file.id);
      const indexToReplace = this.activeClient['attachments'].findIndex(file => file.placeholderId === output.file.id);
      this.activeClient['attachments'][indexToReplace] = fileObject;
      this.activeService.decreasePendingRequests();
      this.uploadLoading = false;

      /*this.activeClient.images.push({
        /!*contentType: output.file.response.mimetype,
        filename: output.file.response.filename,*!/
        fileId: output.file.response.id
      });*/
      /*});*/
    }
  }

  startUpload(): void {
    const event: UploadInput = {
      type: 'uploadAll',
      url: '/api/file',
      method: 'POST',
      includeWebKitFormBoundary: true
    };
    this.uploadInput.emit(event);
  }

  cancelUpload(id: string): void {
    this.uploadInput.emit({ type: 'cancel', id: id });
  }

  removeFile(id: string): void {
    this.uploadInput.emit({ type: 'remove', id: id });
  }

  openAttachment(attachment) {
    this.itemService.openAttachment(this.activeAccount, attachment).then((response: any) => {
      if (response.url) {
        window.open(response.url);
      } else {
        UIkit.notification({
          message: 'Error fetching data',
          status: 'danger',
          pos: 'top-right',
          timeout: 1500
        });
      }
    });
  }

  removeAttachment(attachment) {
    const attachmentObject = {
      documentType: this.clientType.toLowerCase(),
      itemId: this.activeClientId,
      fileId: attachment.fileId
    };

    this.fileService.removeFile(attachmentObject).subscribe(
      res => {
        const attachmentToSplice = this.activeClient.attachments.find((accountAttachment: any) => {
          return attachment.fileId === accountAttachment.fileId;
        });
        this.activeClient.attachments.splice(this.activeClient.attachments.indexOf(attachmentToSplice), 1);
        UIkit.notification({
          message: 'File removed successfully',
          status: 'success',
          pos: 'top-right',
          timeout: 1500
        });
      },
      error => {
        UIkit.notification({
          message: 'Removing failed',
          status: 'danger',
          pos: 'top-right',
          timeout: 1500
        });
      }
    );
  }

  openAddInterestingArtist(): void {
    this.addInterestingArtistModalWindow = window.document.getElementById('addInterestingArtist');
    UIkit.modal(this.addInterestingArtistModalWindow).show();
  }

  toggleInterestingArtist(artist: any): void {
    this.activeClient.interestingArtists.indexOf(artist) === -1 ?
      this.activeClient.interestingArtists.push(artist) : this.activeClient.interestingArtists.splice(this.activeClient.interestingArtists.indexOf(artist), 1);
  }

  isInInterestingArtists(artist: any): boolean {
    return this.activeClient.interestingArtists.indexOf(artist) !== -1;
  }

  // Tämä ei ole käytössä, koska tiedot tallennetaan Save-napin kautta
  addInterestingArtist(): void {
    this.actorService.addInterestingArtist(this.activeClient.interestingArtists).subscribe(
      (response) => {
        UIkit.modal(this.addInterestingArtistModalWindow).hide();
        UIkit.notification({
          message: 'New artist added successfully',
          status: 'success',
          pos: 'top-right',
          timeout: 1500
        });
      },
      () => {
        UIkit.notification({
          message: 'Saving data failed',
          status: 'danger',
          pos: 'top-right',
          timeout: 1500
        });
      }
    );
  }

  openAddExternalNotes(): void {
    this.addExternalNotesModalWindow = window.document.getElementById('addExternalNotes');
    this.addExternalNotesForm.patchValue({ addExternalNotesFormNote: ''});

    UIkit.modal(this.addExternalNotesModalWindow).show();
  }

  openViewExternalNotes(externalNote: any): void {
    this.viewExternalNotesModalWindow = window.document.getElementById('viewExternalNotes');
    this.activeExternalNote = externalNote.note;
    UIkit.modal(this.viewExternalNotesModalWindow).show();
  }

  toggleHeight(note) {
    note.expand = !note.expand;
  }

  openEditExternalNotes(externalNote: any): void {
    this.editExternalNotesModalWindow = window.document.getElementById('editExternalNotes');

    this.editExternalNotesForm.patchValue({
      editExternalNotesFormClient: this.activeClientId,
      editExternalNotesFormExternalNotesId: externalNote._id,
      editExternalNotesFormNote: externalNote.note
    });

    UIkit.modal(this.editExternalNotesModalWindow).show();
  }

  addExternalNotes(): void {
    this.actorService.addExternalNotes(this.addExternalNotesForm.value).subscribe(
      (response) => {
        const addedExternalNotesDocument = response.externalNotes[response.externalNotes.length - 1];

        this.activeClient.externalNotes.push({
          _id: addedExternalNotesDocument._id,
          note: addedExternalNotesDocument.note
        });

        this.addExternalNotesForm.patchValue({ addExternalNotesFormNote: ''});

        UIkit.modal(this.addExternalNotesModalWindow).hide();

        UIkit.notification({
          message: 'New note added successfully',
          status: 'success',
          pos: 'top-right',
          timeout: 1500
        });
      },
      () => {
        UIkit.notification({
          message: 'Saving a new note failed',
          status: 'danger',
          pos: 'top-right',
          timeout: 1500
        });
      }
    );
  }

  editExternalNotes() {
    this.isLoading = true;
    this.actorService.editExternalNotes(this.editExternalNotesForm.value).subscribe(
      res => {
        const foundIndex = this.activeClient.externalNotes.findIndex((externalNotes) => externalNotes._id === this.editExternalNotesForm.value.editExternalNotesFormExternalNotesId);

        if (foundIndex >= 0) {
          this.activeClient.externalNotes[foundIndex] = {
            note: this.editExternalNotesForm.value.editExternalNotesFormNote,
          };
        }

        this.editExternalNotesForm.patchValue({ editExternalNotesFormExternalNotesId: ''});
        this.editExternalNotesForm.patchValue({ editExternalNotesFormNote: ''});

        UIkit.modal(this.editExternalNotesModalWindow).hide();

        UIkit.notification({
          message: 'Note updated successfully',
          status: 'success',
          pos: 'top-right',
          timeout: 1500
        });
      },
      error => {
        UIkit.notification({
          message: 'Updating note failed',
          status: 'danger',
          pos: 'top-right',
          timeout: 1500
        });
      },
      () => this.isLoading = false
    );
  }

  removeExternalNotes(ExternalNotes) {
    this.actorService.deleteExternalNotes(this.activeClientId, ExternalNotes).subscribe(
      res => {
        const ExternalNotesIndex = this.activeClient.externalNotes.findIndex((ExternalNotes: any) => ExternalNotes._id == ExternalNotes._id);

        this.activeClient.externalNotes.splice(ExternalNotesIndex, 1);

        UIkit.notification({
          message: 'Note removed successfully',
          status: 'success',
          pos: 'top-right',
          timeout: 1500
        });
      },
      error => {
        UIkit.notification({
          message: 'Removing note failed',
          status: 'danger',
          pos: 'top-right',
          timeout: 1500
        });
      }
    );
  }

  resetExternalNotesForm(): void {
    this.addExternalNotesForm.reset();
    this.addExternalNotesForm.patchValue({
      addExternalNotesFormClient: this.activeClientId,
      addExternalNotesFormNote: '',
    });
  }

  resetActiveExternalNote(): void {
    this.activeExternalNote = null;
  }

  getExternalNote(): any {
    return this.activeExternalNote;
  }

  sortArtworks(sortingKey: string): void {
    if (sortingKey !== this.sortingKey) {
      this.sortingKey = sortingKey;
    } else {
      this.sortAscending = !this.sortAscending;
    }
    this.getAccountData();
  }


  sortBids(sortingKey: string): void {
    if (sortingKey !== this.sortingKey) {
      this.sortingKey = sortingKey;
    } else {
      this.sortAscending = !this.sortAscending;
    }
    this.getAccountData();
  }

  sortReservations(sortingKey: string): void {
    if (sortingKey !== this.sortingKey) {
      this.sortingKey = sortingKey;
    } else {
      this.sortAscending = !this.sortAscending;
    }
    this.getAccountData();
  }

  sortTrades(sortingKey: string): void {
    if (sortingKey !== this.sortingKey) {
      this.sortingKey = sortingKey;
    } else {
      this.sortAscending = !this.sortAscending;
    }
    this.getAccountData();
  }

  activeSort(key: string): boolean {
    return this.sortingKey === key;
  }

  openArtwork(artworkId): void {
    this.router.navigateByUrl('/artwork/' + artworkId);
  }

  openArtist(artistId): void {
    this.router.navigateByUrl('/artist/' + artistId);
  }

  openBid(bidId): void {
    this.router.navigateByUrl('/bid/' + bidId);
  }

  openReservation(reservationId): void {
    this.router.navigateByUrl('/reservation/' + reservationId);
  }

  openTrade(tradeId): void {
    this.router.navigateByUrl('/trade/' + tradeId);
  }

  setViewList(value): void {
    this.viewList = value;
  }

  getCharactersLimitError(fieldName: string) {
    if (!fieldName || !this.editClientForm.get(fieldName).value ) {
      return ''
    }

    const charactersOverLimit: number = this.editClientForm.get(fieldName).value.length - this.editClientForm.get(fieldName).errors.maxlength.requiredLength;

    return `Field length is ${ charactersOverLimit } characters over limit`;
  }

  getCharactersLeft(fieldName: string, charactersLimit: number) {
    if (!fieldName || !this.editClientForm.get(fieldName).value ) {
      return ''
    }

    const fieldLength: number = this.editClientForm.get(fieldName).value.length;
    const charactersOverLimit: number = fieldLength - charactersLimit;

    if (charactersOverLimit >= 0) { return `${ charactersLimit } / ${ charactersLimit }` }

    return `${ fieldLength } / ${ charactersLimit }`;
  }
}
