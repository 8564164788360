import {Component, EventEmitter, OnInit, ViewChild} from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { UserService } from '../../services/user.service';
import { CollectionService } from '../../services/collection.service';
import { AccountService } from '../../services/account.service';
import { AuthService } from '../../services/auth.service';
import { ActiveService } from '../../services/active.service';
import { FileService } from '../../services/file.service';
import { SearchService } from '../../services/search.service';

import { UploadOutput, UploadInput, UploadFile, humanizeBytes, UploaderOptions } from 'ngx-uploader';
import {AngularMyDatePickerDirective, IAngularMyDpOptions, IMyDateModel} from 'angular-mydatepicker';
import * as moment from 'moment/moment';
import { Observable } from 'rxjs';
import { delay } from 'rxjs/operators';
declare var UIkit: any;

@Component({
  selector: 'app-collections',
  templateUrl: './collections.component.html',
  styleUrls: ['./collections.component.scss']
})
export class CollectionsComponent implements OnInit {
  public user: any = {};
  public activeCollection: any = {};
  private activeUser: string;
  public actorType: string;
  public activeAccount: string;
  public account: Object = {};
  public artists: Array<Object> = [];
  public collections: Array<Object> = [];
  public variables: Array<string> = [];
  public isLoading = true;
  public addCollectionForm: FormGroup;
  public addNewCollectionModalWindow: any;
  public advancedArtworkSearchModalWindow: any;
  public showAdvancedArtworkSearchModalWindow: boolean = false;
  public sortingKey: string;
  public sortAscending: boolean = false;
  public currentPage: number = 1;
  public limitQuery: number = 10;
  public pageCount: number = 1;
  public pages: Array<any> = [];
  public searchArtworkForm: FormGroup;
  public searchArtworkResults: Array<Object> = [];
  public selectedArtworks: Array<Object> = [];
  public searchResultsFound: boolean = false;
  public myDatePickerOptions: IAngularMyDpOptions = {
    dateFormat: 'dd.mm.yyyy'
  };
  private todaysDate: IMyDateModel;
  private validDate: IMyDateModel;
  @ViewChild('AngularMyDatePickerAddReservationCreated') myDp: AngularMyDatePickerDirective;
  @ViewChild('AngularMyDatePickerAddReservationValid') myDp2: AngularMyDatePickerDirective;
  public options: UploaderOptions;
  public formData: FormData;
  public filesToUpload: UploadFile[];
  public uploadInput: EventEmitter<UploadInput>;
  public humanizeBytes: Function;
  public dragOver: boolean;
  public imageLoadOffset$: any;

  constructor(
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private formBuilder: FormBuilder,
    private auth: AuthService,
    private activeService: ActiveService,
    private userService: UserService,
    private accountService: AccountService,
    private collectionService: CollectionService,
    private fileService: FileService,
    private searchService: SearchService
  ) {
    this.activatedRoute.data.subscribe(data => {
      this.activeAccount = data.resolvedData.account.id;
      this.user = data.resolvedData.user.id
    });

    this.addCollectionForm = this.formBuilder.group({
      addCollectionOwner: new FormControl('', [
        Validators.required,
        Validators.maxLength(100)
      ]),
      addCollectionName: new FormControl('', [
        Validators.required,
        Validators.maxLength(100)
      ]),
      addCollectionDescription: new FormControl('', [
        Validators.maxLength(2048)
      ]),
      addCollectionLocation: new FormControl('', [
        Validators.maxLength(30)
      ]),
      addCollectionType: new FormControl('', [
        Validators.maxLength(30)
      ]),
      addCollectionAttachments: new FormControl('', [
        Validators.maxLength(10)
      ]),
      addCollectionTags: new FormControl('', [
        Validators.maxLength(2048)
      ]),
      addCollectionNotes: new FormControl('', [
        Validators.maxLength(2048)
      ]),
      addCollectionComments: new FormControl('', [
        Validators.maxLength(2048)
      ]),
      addCollectionVisibility: new FormControl('', [
        Validators.maxLength(10)
      ])
    });

    this.searchArtworkForm = this.formBuilder.group({
      searchArtworkTerm: new FormControl('', [
        Validators.required,
        Validators.minLength(3),
        Validators.maxLength(50)
      ])
    });

    this.activeCollection.items = [];
    this.filesToUpload = [];
    this.uploadInput = new EventEmitter<UploadInput>();
    this.humanizeBytes = humanizeBytes;
  }

  ngOnInit() {
    this.isLoading = true;
    this.addCollectionForm.patchValue({
      addCollectionOwner: this.activeAccount
    });
    this.getAccountData();

    this.activeService.getActiveData().subscribe(
      data => {
        this.activeUser = data.user.id;
      }
    );
  }

  getAccountData() {
    const getObject = {
      accountId: this.activeAccount,
      requester: 'collections',
      sortingKey: this.sortingKey ? this.sortingKey : '_id',
      sortingOrder: this.sortAscending ? 'asc' : 'desc',
      skipQuery: (this.currentPage - 1) * this.limitQuery,
      limitQuery: this.limitQuery,
      contentTypes: ['account', 'artist', 'collection', 'collectionCount', 'variable']
    };
    this.accountService.getAccountData(getObject).subscribe(
      data => {
        this.account = data.account;
        this.artists = data.artists;
        this.collections = data.collections;
        this.variables = data.variables ? data.variables : [];

        this.pageCount = Math.ceil(data.collectionCount / this.limitQuery);
        if (this.pageCount > 0) {
          this.pages = Array(this.pageCount).fill(null);
        }
      },
      () => {
        UIkit.notification({
          message: 'Error fetching clients',
          status: 'danger',
          pos: 'top-right',
          timeout: 1500
        });
      },
      () => this.isLoading = false
    );
  }

  openAddNewCollection() {
    this.addNewCollectionModalWindow = window.document.getElementById('addNewCollection');
    UIkit.modal(this.addNewCollectionModalWindow).show();
  }

  addCollection() {
    const addCollectionObject = {
      owner: this.addCollectionForm.value.addCollectionOwner,
      name: this.addCollectionForm.value.addCollectionName,
      description: this.addCollectionForm.value.addCollectionDescription,
      items: this.activeCollection.items,
      location: this.addCollectionForm.value.addCollectionLocation,
      type: this.addCollectionForm.value.addCollectionType,
      /*attachments: this.addCollectionForm.value.addCollectionAttachments,*/
      tags: this.addCollectionForm.value.addCollectionTags,
      notes: this.addCollectionForm.value.addCollectionNotes,
      comments: this.addCollectionForm.value.addCollectionComments,
      visibility: this.addCollectionForm.value.addCollectionVisibility
    };
    this.collectionService.addCollection(addCollectionObject).subscribe(
      res => {
        this.ngOnInit();
        UIkit.modal(this.addNewCollectionModalWindow).hide();
        this.resetForm();
        UIkit.notification({
          message: 'Saved successfully',
          status: 'success',
          pos: 'top-right',
          timeout: 1500
        });
      },
      error => {
        UIkit.notification({
          message: 'Saving failed',
          status: 'danger',
          pos: 'top-right',
          timeout: 1500
        });
      }
    );
  }

  openCollection(collectionId): void {
    this.router.navigateByUrl('/collection/' + collectionId);
  }

  removeCollection(collectionId): void {
    this.collectionService.deleteCollection(collectionId).subscribe(
      () => {
        this.ngOnInit();
        UIkit.notification({
          message: 'Collection removed successfully',
          status: 'success',
          pos: 'top-right',
          timeout: 1500
        });
      },
      () => {
        UIkit.notification({
          message: 'Error removing collections',
          status: 'danger',
          pos: 'top-right',
          timeout: 1500
        });
      }
    );
  }

  sortCollections(sortingKey: string): void {
    if (sortingKey !== this.sortingKey) {
      this.sortingKey = sortingKey;
    } else {
      this.sortAscending = !this.sortAscending;
    }
    this.getAccountData();
  }

  activeSort(key: string): boolean {
    return this.sortingKey === key;
  }

  resetForm(): void {
    this.addCollectionForm.reset();
    this.addCollectionForm.patchValue({
      addCollectionOwner: this.activeAccount,
      addCollectionType: ''
    });
    this.activeCollection.items = [];
    this.searchArtworkResults = [];
    this.emptySearch();
  }

  searchArtwork() {
    /*this.isLoading = true;*/
    if (this.searchArtworkForm.value.searchArtworkTerm.trim().length === 0) {
      return
    }

    const searchObject = {
      searchArtworkAccountId: this.activeAccount,
      searchArtworkUserId: this.activeUser,
      searchArtworkTerm: this.searchArtworkForm.value.searchArtworkTerm
    };
    this.searchService.searchArtwork(searchObject).subscribe(
      res => {
        if (res.length) {
          if (this.searchArtworkResults) {
            this.searchArtworkResults.length = 0;
          }
          res.forEach(artwork => {
            artwork.allResultType = 'artwork';
            this.searchArtworkResults.push(artwork);
          });
          /*this.isLoading = false;*/
          this.searchResultsFound = true;
          /*document.getElementById('search-artwork-results-container').classList.add('active');*/
          Array.prototype.forEach.call(document.getElementsByClassName('results-container'), function (element) {
            element.classList.add('active');
          });



          UIkit.notification({
            message: 'Search matched ' + this.searchArtworkResults.length + ' results',
            status: 'success',
            pos: 'top-right',
            timeout: 1500
          });
        } else {
          if (this.searchArtworkResults) {
            this.searchArtworkResults.length = 0;
          }
          UIkit.notification({
            message: 'No search results',
            status: 'danger',
            pos: 'top-right',
            timeout: 1500
          });
        }
      },
      error => {
        if (this.searchArtworkResults) {
          this.searchArtworkResults.length = 0;
        }
        UIkit.notification({
          message: 'No search results',
          status: 'danger',
          pos: 'top-right',
          timeout: 1500
        });
      }
    );
  }

  emptySearch(): void {
    /*document.getElementById('search-artwork-results-container').classList.remove('active');*/
    /*Array.prototype.forEach.call(document.getElementsByClassName('results-container'), function (element) {
      element.classList.remove('active');
    });*/

    /*let element: HTMLElement = document.getElementsByClassName('uk-navbar-toggle uk-close')[1] as HTMLElement;
    element.click();*/
    setTimeout(() => {
      this.searchResultsFound = false;
      if (this.searchArtworkResults) {
        this.searchArtworkResults.length = 0;
      }
      if (this.selectedArtworks) {
        this.selectedArtworks.length = 0;
      }
      this.searchArtworkForm.patchValue({ searchArtworkTerm: ''});
    }, 300);
  }

  selectCollectionArtwork(artwork): void {
    this.selectedArtworks.indexOf(artwork) === -1 ?
      this.selectedArtworks.push(artwork) : this.selectedArtworks.splice(this.selectedArtworks.indexOf(artwork), 1);
  }

  addArtworksToCollection(): void {
    this.selectedArtworks.map((item: any) => {
      const itemFound = this.activeCollection.items.find((activeCollectionItem: any) => {
        return activeCollectionItem._id === item._id;
      });
      if (itemFound === undefined) {
        this.activeCollection.items.push(item);
      }
      const artistFound = this.artists.find((artist: any) => {
        return artist._id === item.artist;
      });
      if (artistFound === undefined) {
        this.artists.push({
          _id: item.artist,
          name: item.artistName,
          priceMultiplier: item.artistPriceMultiplier,
          status: item.artistStatus
        });
      }
    });

    this.emptySearch();
  }

  removeArtworkFromCollection(item): void {
    this.activeCollection.items.splice(this.activeCollection.items.indexOf(item), 1);
    const artistToSplice = this.artists.find((artist: any) => {
      return artist._id === item.artist;
    });
    this.artists.splice(this.artists.indexOf(artistToSplice), 1);
  }

  openSlideshow($event): void {
    $event.preventDefault();
    UIkit.lightboxPanel({
      /* Ei tarvitse, jos muistaa käyttää $event.preventDefaultia
        autoplay: true,
        autoplayInterval: 5000,
        delayControls: 1000,*/
      items: this.activeCollection.items.map(item => {
        let itemImage;
        if (item.coverImage) {
          itemImage = '/api/image/' + item.coverImage;
        } else if (item.images[0]) {
          itemImage = '/api/image/' + item.images[0].fileId;
        } else {
          itemImage = '/api/image/artbuddy-logo-placeholder.svg'
        }

        return {
          source: itemImage,
          caption: item.name
        }
      })
    }).show();
  }



  openAdvancedSearch(): void {
    this.showAdvancedArtworkSearchModalWindow = true;
    setTimeout(() => {
      this.advancedArtworkSearchModalWindow = window.document.getElementById('advancedArtworkSearch');
      UIkit.modal(this.advancedArtworkSearchModalWindow).show();
    }, 500);

  }

  setArtworkSearchResults(searchResult: Array<any>) {
    searchResult.forEach(item => this.selectedArtworks.push(item));
    this.addArtworksToCollection();
  }

  setLimit(limit: number): void {
    if (limit !== this.limitQuery) {
      this.limitQuery = limit;
      /* Set currentPage to 0 to start from the first page */
      this.currentPage = 1;
      this.getAccountData();
    }
  }

  activeLimit(limit: number): boolean {
    return this.limitQuery === limit;
  }

  setCurrentPageString(page: string): void {
    this.setCurrentPage(parseInt(page, 0));
  }

  setCurrentPage(page: number): void {
    if ((page !== this.currentPage) && (page <= this.pageCount)) {
      this.currentPage = page;
      this.getAccountData();
    }
  }

  public validateName(): void {
    this.addCollectionForm.controls['addCollectionName'].setErrors({ exists: null });

    const actorObject = {
      owner: this.addCollectionForm.value.addCollectionOwner,
      name: this.addCollectionForm.value.addCollectionName,
      type: 'collection'
    };

    this.collectionService.validateCollectionName(actorObject).subscribe(
      response => {
        if (response.error) {
          this.addCollectionForm.controls['addCollectionName'].setErrors({ exists: true });
        } else {
          this.addCollectionForm.controls['addCollectionName'].setErrors({ exists: null });
          this.addCollectionForm.controls['addCollectionName'].updateValueAndValidity();
        }
      }
    );
  }

  getCharactersLimitError(fieldName: string) {
    if (!fieldName || !this.addCollectionForm.get(fieldName).value ) {
      return ''
    }

    const charactersOverLimit: number = this.addCollectionForm.get(fieldName).value.length - this.addCollectionForm.get(fieldName).errors.maxlength.requiredLength;

    return `Field length is ${ charactersOverLimit } characters over limit`;
  }

  getCharactersLeft(fieldName: string, charactersLimit: number) {
    if (!fieldName || !this.addCollectionForm.get(fieldName).value ) {
      return ''
    }

    const fieldLength: number = this.addCollectionForm.get(fieldName).value.length;
    const charactersOverLimit: number = fieldLength - charactersLimit;

    if (charactersOverLimit >= 0) { return `${ charactersLimit } / ${ charactersLimit }` }

    return `${ fieldLength } / ${ charactersLimit }`;
  }
}
