import { Injectable } from '@angular/core';
import { AccountService } from '../services/account.service';
import { ActiveService } from '../services/active.service';
import { Observable } from 'rxjs';
import { Subject } from 'rxjs';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import 'rxjs/add/operator/map';

@Injectable()
export class TradeService {
  private httpOptions = {
    headers: new HttpHeaders({
      'Content-Type': 'application/json',
      charset: 'UTF-8'
    })
  };
  private currentAccount: any;
  currentTrade: string = '';
  currentTradeChange: Subject<string> = new Subject<string>();

  constructor(
    private http: HttpClient,
    private activeService: ActiveService,
    private accountService: AccountService
  ) {
    this.activeService.activeDataChange.subscribe(data => {
      this.currentAccount = data.account.id;
    });
  }

  setCurrentTrade(tradeId): void {
    this.currentTrade = tradeId;
    this.currentTradeChange.next(this.currentTrade);
  }

  getCurrentTrade(): string {
    return this.currentTrade;
  }

  getTrades(accountId: String, TradeType: String): Observable<any> {
    /*let activeAccount;
    this.activeService.getActiveData().subscribe(data => {
      activeAccount = data.account.id;
    });*/
    return this.http.get(`/api/trades/${accountId}/${TradeType}`);
  }

  countTrades(): Observable<any> {
    return this.http.get('/api/trades/count');
  }

  addTrade(trade): Observable<any> {
    return this.http.post('/api/trade', JSON.stringify(trade), this.httpOptions);
  }

  getTrade(trade): Observable<any> {
    return this.http.get(`/api/trade/${trade}`);
  }

  getUserTrade(trade): Observable<any> {
    return this.http.get(`/api/userTrade/${trade._id}`);
  }

  editTrade(trade): Observable<any> {
    if (!trade.id) {
      trade.id = trade.personalFormId;
    }
    return this.http.put(
      `/api/trade/${trade.id}`,
      JSON.stringify(trade),
      this.httpOptions
    );
  }

  deleteTrade(tradeId): Observable<any> {
    return this.http.delete(`/api/trade/${tradeId}`, this.httpOptions);
  }

  tradeImageId(fileObject: object): Observable<any> {
    return this.http.post(
      '/api/tradeImageId',
      JSON.stringify(fileObject),
      this.httpOptions
    );
  }
}
