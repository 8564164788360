<div class="uk-section uk-section-default uk-padding-remove-vertical">

  <app-sidenav contentType="Reservations" [contentItem]="activeReservation.name"></app-sidenav>

  <div class="uk-container-expand uk-height-expand">
    <ul uk-tab *ngIf="!isLoading">
      <li class="uk-active"><a href="#reservationinfo"><h4>Basic information</h4></a></li>
      <li><a href="#reservationartworks"><h4>Artworks</h4></a></li>
      <!--<li><a href="#reservationartists"><h4>Artists</h4></a></li>-->
    </ul>
    <ul class="uk-switcher uk-card uk-card-body uk-padding-remove-top" *ngIf="!isLoading">
      <div id="reservationinfo" class="uk-padding-small">
        <form appNoSubmitEnter [formGroup]="editReservationForm" (ngSubmit)="editReservation()" class="uk-grid-small"
              uk-grid>
          <input type="hidden" name="editReservationOwner" name="editReservationOwner"
                 formControlName="editReservationOwner" [ngModel]="activeAccount">
          <input type="hidden" name="editReservationId" name="editReservationId" formControlName="editReservationId"
                 [ngModel]="activeReservation._id">
          <input type="hidden" name="editReservationOwner" name="editReservationOwner"
                 formControlName="editReservationOwner" [ngModel]="activeReservation.owner">
          <div class="uk-width-1-2@s uk-width-1-3@m">
            <div class="uk-margin">
              <label class="uk-form-label" for="editReservationName">Title</label>
              <div class="uk-form-controls">
                <input id="editReservationName" name="editReservationName" formControlName="editReservationName"
                       type="text" [ngModel]="activeReservation.name" placeholder="Title ..." class="uk-input"
                       [ngClass]="{'uk-form-danger': editReservationForm.get('editReservationName').touched && editReservationForm.get('editReservationName').errors }">
                <div class="uk-text-danger uk-text-small"
                     *ngIf="editReservationForm.get('editReservationName').hasError('maxlength')"
                >
                  {{ getCharactersLimitError('editReservationName') }}
                </div>
                <div class="uk-text-danger uk-text-small"
                     *ngIf="editReservationForm.get('editReservationName').hasError('pattern')"
                >
                  Field value is incorrect
                </div>
              </div>
            </div>
          </div>
          <div class="uk-width-1-2@s uk-width-1-3@m">
            <div class="uk-margin">
              <div class="uk-flex uk-flex-between uk-text-meta">
                <label class="uk-form-label" for="editReservationDescription">Description</label>
                <div class="uk-text-small">
                  {{ getCharactersLeft('editReservationDescription', 2048) }}
                </div>
              </div>
              <div class="uk-form-controls">
                <textarea id="editReservationDescription" name="editReservationDescription"
                          formControlName="editReservationDescription" rows="5"
                          [ngModel]="activeReservation.description" placeholder="Description ..." class="uk-textarea"
                          [ngClass]="{'uk-form-danger': editReservationForm.get('editReservationDescription').touched && editReservationForm.get('editReservationDescription').errors }"></textarea>
                <div class="uk-text-danger uk-text-small"
                     *ngIf="editReservationForm.get('editReservationDescription').hasError('maxlength')"
                >
                  {{ getCharactersLimitError('editReservationDescription') }}
                </div>
              </div>
            </div>
          </div>
          <div class="uk-width-1-2@s uk-width-1-3@m">
            <div class="uk-margin">
              <label class="uk-form-label" for="editReservationType">Reservation type</label>
              <div class="uk-form-controls">
                <select id="editReservationType" name="editReservationType" formControlName="editReservationType"
                        [ngModel]="activeReservation.type" class="uk-select">
                  <option value="" disabled>Please select ...</option>
                  <option value="Buy">Buy</option>
                  <option value="Sell">Sell</option>
                </select>
              </div>
            </div>
          </div>
          <div class="uk-width-1-2@s uk-width-1-3@m"
               *ngIf="['Sell'].includes(editReservationForm.get('editReservationType').value)">
            <div class="uk-margin">
              <label class="uk-form-label" for="editReservationSeller">Seller <span class="required">*</span></label>
              <div class="uk-form-controls">
                <input id="editReservationSeller" name="editReservationSeller" formControlName="editReservationSeller"
                       type="text" placeholder="{{ accountActor.name }}" class="uk-input" [ngModel]="accountActor.name">
                <div class="uk-text-danger uk-text-small"
                     *ngIf="editReservationForm.get('editReservationSeller').hasError('maxlength')"
                >
                  {{ getCharactersLimitError('editReservationSeller') }}
                </div>
              </div>
            </div>
          </div>
          <div class="uk-width-1-2@s uk-width-1-3@m"
               *ngIf="['Sell'].includes(editReservationForm.get('editReservationType').value)">
            <div class="uk-margin">
              <label class="uk-form-label" for="editReservationCustomer">Customer <span
                class="required">*</span></label>
              <div class="uk-form-controls">
                <select id="editReservationCustomer" name="editReservationCustomer"
                        formControlName="editReservationCustomer" [ngModel]="activeReservation.customer"
                        class="uk-select">
                  <option value="" disabled>Please select ...</option>
                  <option *ngFor="let actor of actors"
                          value="{{ actor._id }}">{{ actor.name }} {{ actor.lastName }}</option>
                </select>
                <!--<select id="editReservationCustomer" name="editReservationCustomer" formControlName="editReservationCustomer" [ngModel]="activeReservation.customer" class="uk-select">
                  <option *ngFor="let actor of actor" [ngValue]="actor._id" >{{ actor.name }} {{ actor.lastName }}</option>
                </select>-->
              </div>
            </div>
          </div>
          <div class="uk-width-1-2@s uk-width-1-3@m"
               *ngIf="['Buy'].includes(editReservationForm.get('editReservationType').value)">
            <div class="uk-margin">
              <label class="uk-form-label" for="editReservationSeller">Seller <span class="required">*</span></label>
              <div class="uk-form-controls">
                <select id="editReservationSeller" name="editReservationSeller" formControlName="editReservationSeller"
                        [ngModel]="activeReservation.seller" class="uk-select">
                  <option value="" disabled>Please select ...</option>
                  <option *ngFor="let actor of actors"
                          value="{{ actor._id }}">{{ actor.name }} {{ actor.lastName }}</option>
                </select>
              </div>
            </div>
          </div>
          <div class="uk-width-1-2@s uk-width-1-3@m"
               *ngIf="['Buy'].includes(editReservationForm.get('editReservationType').value)">
            <div class="uk-margin">
              <label class="uk-form-label" for="editReservationCustomer">Customer <span
                class="required">*</span></label>
              <div class="uk-form-controls">
                <input id="editReservationCustomer" name="editReservationCustomer"
                       formControlName="editReservationCustomer" type="text" placeholder="{{ accountActor.name }}"
                       class="uk-input" [ngModel]="accountActor.name">
                <div class="uk-text-danger uk-text-small"
                     *ngIf="editReservationForm.get('editReservationCustomer').hasError('maxlength')"
                >
                  {{ getCharactersLimitError('editReservationCustomer') }}
                </div>
              </div>
            </div>
          </div>
          <div class="uk-width-1-2@s uk-width-1-3@m">
            <div class="uk-margin">
              <label class="uk-form-label" for="editReservationCreated">Creation date</label>
              <div class="uk-form-controls">
                <input
                  id="editReservationCreated"
                  name="editReservationCreated"
                  formControlName="editReservationCreated"
                  [ngModel]="activeReservation.created"
                  type="text"
                  placeholder="Creation date ..."
                  class="uk-input"
                  [ngClass]="{'uk-form-danger': editReservationForm.get('editReservationCreated').touched && editReservationForm.get('editReservationCreated').errors }"
                  angular-mydatepicker
                  [options]="myDatePickerOptions"
                  #AngularMyDatePickerEditReservationCreated="angular-mydatepicker"
                  (click)="AngularMyDatePickerEditReservationCreated.toggleCalendar()"
                  (dateChanged)="updateDate('editReservationCreated', $event)"
                />
                <div class="uk-text-danger uk-text-small"
                     *ngIf="editReservationForm.get('editReservationCreated').hasError('maxlength')"
                >
                  {{ getCharactersLimitError('editReservationCreated') }}
                </div>
              </div>
            </div>
          </div>
          <div class="uk-width-1-2@s uk-width-1-3@m">
            <div class="uk-margin">
              <label class="uk-form-label" for="editReservationValid">Valid until date</label>
              <div class="uk-form-controls">
                <input
                  id="editReservationValid"
                  name="editReservationValid"
                  formControlName="editReservationValid"
                  [ngModel]="activeReservation.valid"
                  type="text"
                  placeholder="Creation date ..."
                  class="uk-input"
                  [ngClass]="{'uk-form-danger': editReservationForm.get('editReservationValid').touched && editReservationForm.get('editReservationValid').errors }"
                  angular-mydatepicker
                  [options]="myDatePickerOptions"
                  #AngularMyDatePickerEditReservationValid="angular-mydatepicker"
                  (click)="AngularMyDatePickerEditReservationValid.toggleCalendar()"
                  (dateChanged)="updateDate('editReservationValid', $event)"
                />
                <div class="uk-text-danger uk-text-small"
                     *ngIf="editReservationForm.get('editReservationValid').hasError('maxlength')"
                >
                  {{ getCharactersLimitError('editReservationValid') }}
                </div>
              </div>
            </div>
          </div>
          <div class="uk-width-1-2@s uk-width-1-3@m">
            <div class="uk-margin">
              <div class="uk-flex uk-flex-between uk-text-meta">
                <label class="uk-form-label" for="editReservationOtherTerms">Other terms</label>
                <div class="uk-text-small">
                  {{ getCharactersLeft('editReservationOtherTerms', 2048) }}
                </div>
              </div>
              <div class="uk-form-controls">
                <textarea id="editReservationOtherTerms" name="editReservationOtherTerms"
                          formControlName="editReservationOtherTerms" rows="5" [ngModel]="activeReservation.otherTerms"
                          placeholder="Other terms ..." class="uk-textarea"
                          [ngClass]="{'uk-form-danger': editReservationForm.get('editReservationOtherTerms').touched && editReservationForm.get('editReservationOtherTerms').errors }"></textarea>
                <div class="uk-text-danger uk-text-small"
                     *ngIf="editReservationForm.get('editReservationOtherTerms').hasError('maxlength')"
                >
                  {{ getCharactersLimitError('editReservationOtherTerms') }}
                </div>
              </div>
            </div>
          </div>
          <div class="uk-width-1-2@s uk-width-1-3@m">
            <div class="uk-margin">
              <div class="uk-flex uk-flex-between uk-text-meta">
                <label class="uk-form-label" for="editReservationClientReference">Client reference</label>
                <div class="uk-text-small">
                  {{ getCharactersLeft('editReservationClientReference', 2048) }}
                </div>
              </div>
              <div class="uk-form-controls">
                <textarea id="editReservationClientReference" name="editReservationClientReference"
                          formControlName="editReservationClientReference" rows="5"
                          [ngModel]="activeReservation.clientReference" placeholder="Client reference ..."
                          class="uk-textarea"
                          [ngClass]="{'uk-form-danger': editReservationForm.get('editReservationClientReference').touched && editReservationForm.get('editReservationClientReference').errors }"></textarea>
                <div class="uk-text-danger uk-text-small"
                     *ngIf="editReservationForm.get('editReservationClientReference').hasError('maxlength')"
                >
                  {{ getCharactersLimitError('editReservationClientReference') }}
                </div>
              </div>
            </div>
          </div>
          <div class="uk-width-1-2@s uk-width-1-3@m">
            <div class="uk-margin">
              <div class="uk-flex uk-flex-between uk-text-meta">
                <label class="uk-form-label" for="editReservationSellerReference">Seller reference</label>
                <div class="uk-text-small">
                  {{ getCharactersLeft('editReservationSellerReference', 2048) }}
                </div>
              </div>
              <div class="uk-form-controls">
                <textarea id="editReservationSellerReference" name="editReservationSellerReference"
                          formControlName="editReservationSellerReference" rows="5"
                          [ngModel]="activeReservation.sellerReference" placeholder="Seller reference ..."
                          class="uk-textarea"
                          [ngClass]="{'uk-form-danger': editReservationForm.get('editReservationSellerReference').touched && editReservationForm.get('editReservationSellerReference').errors }"></textarea>
                <div class="uk-text-danger uk-text-small"
                     *ngIf="editReservationForm.get('editReservationSellerReference').hasError('maxlength')"
                >
                  {{ getCharactersLimitError('editReservationSellerReference') }}
                </div>
              </div>
            </div>
          </div>
          <div class="uk-width-1-2@s uk-width-1-3@m" *ngIf="activeReservation.items.length">
            <div class="uk-margin">
              <label class="uk-form-label" for="editReservationCurrency">Currency</label>
              <div class="uk-form-controls">
                <input id="editReservationCurrency" name="editReservationCurrency"
                       formControlName="editReservationCurrency" type="text" placeholder="Currency ..."
                       [ngModel]="tradingCurrency" class="uk-input"
                       [ngClass]="{'uk-form-danger': editReservationForm.get('editReservationCurrency').touched && editReservationForm.get('editReservationCurrency').errors }">
                <div class="uk-text-danger uk-text-small"
                     *ngIf="editReservationForm.get('editReservationCurrency').hasError('maxlength')"
                >
                  {{ getCharactersLimitError('editReservationCurrency') }}
                </div>
              </div>
            </div>
          </div>
          <div class="uk-width-1-2@s uk-width-1-3@m">
            <div class="uk-margin">
              <label class="uk-form-label" for="editReservationStatus">Approval status</label>
              <div class="uk-form-controls">
                <label><input id="editReservationStatus" name="editReservationStatus"
                              formControlName="editReservationStatus" [ngModel]="activeReservation.status"
                              class="uk-checkbox" type="checkbox"> Approved</label>
              </div>
            </div>
          </div>
          <div class="uk-width-1-1 uk-margin-medium-top">
            <h3 class="uk-margin-remove-bottom">Pricing information</h3>
            <hr class="uk-margin-small-top">
          </div>
          <div class="uk-width-1-1">
            <div class="uk-margin">
              <table class="uk-table uk-table-middle uk-table-divider uk-margin-remove-bottom"
                     *ngIf="activeReservation.items.length">
                <thead>
                <tr>
                  <th>Image</th>
                  <th>Name</th>
                  <th class="uk-text-nowrap">Artist</th>
                  <th class="uk-text-nowrap">VAT %</th>
                  <th class="uk-text-nowrap">List price<br>incl. VAT</th>
                  <th class="uk-text-nowrap">Discount</th>
                  <th class="uk-text-nowrap uk-text-right">Total price</th>
                </tr>
                </thead>
                <tbody>
                <tr *ngFor="let item of activeReservation.items">
                  <td>
                    <div
                      class="image-max-height uk-preserve-width uk-background-contain uk-background-muted uk-height-small uk-panel uk-flex uk-flex-center uk-flex-middle"
                      width="60"
                      [attr.data-src]="'/api/storagefile/' + activeAccount + '/' + item.coverImage + '/small'" uk-img
                      *ngIf="item.coverImage">

                    </div>
                    <!-- Jos ei ole coverImagea, niin sit otetaan eka image -->
                    <div
                      class="image-max-height uk-preserve-width uk-background-contain uk-background-muted uk-height-small uk-panel uk-flex uk-flex-center uk-flex-middle"
                      width="60"
                      [attr.data-src]="'/api/storagefile/' + activeAccount + '/' + item.images[0].fileId + '/small'"
                      uk-img *ngIf="!item.coverImage && item.images && item.images.length">

                    </div>
                    <!-- Jos ei ole coverImagea tai ekan imagea, ni sit otetaan placeholder -->
                    <div
                      class="image-max-height uk-preserve-width uk-background-contain uk-background-muted uk-height-small uk-panel uk-flex uk-flex-center uk-flex-middle"
                      width="60" [attr.data-src]="'/api/image/artbuddy-logo-placeholder.svg'" uk-img
                      *ngIf="!item.coverImage && !item.images.length">

                    </div>
                  </td>
                  <td>{{ item.name }}</td>
                  <td>{{ item.artistName }}</td>
                  <td>
                    <div class="uk-form-controls">
                      <select (change)="setItemVATPercentage(item._id, $event)" [ngModel]="item.VATPercentage"
                              [ngModelOptions]="{standalone: true}" class="uk-select">
                        <option *ngFor="let VAT of VATRates" value="{{ VAT.percentage }}">{{ VAT.percentage }} %
                          ({{ VAT.description }})
                        </option>
                      </select>
                      <!--<span *ngIf="getItemVATAmount(item) > 0">
                        {{ getItemVATAmount(item) | round: 2 }} {{ item.price.currency }}
                      </span>-->
                    </div>
                  </td>
                  <!--<td>{{ getItemPriceWithoutVAT(item) | round: 2 }} {{ item.price.currency }}</td>-->
                  <td>{{ item.price.price | round: 2 | number : '1.2-2' }} {{ item.price.currency }}</td>
                  <td>
                    <div class="uk-form-controls">
                      <input type="number" (input)="setItemDiscount(item._id, $event.target.value)"
                             [placeholder]="item.price.price" [ngModel]="item.discount"
                             [ngModelOptions]="{standalone: true}" class="uk-input"
                             [ngClass]="{'uk-form-danger': ((item.totalPrice > item.price.price) || item.totalPrice < 0) }">
                      <div class="measurement-unit-container">
                        <span
                          class="uk-badge uk-background-muted uk-text-muted">{{ tradingCurrency | lowercase }}</span>
                      </div>
                    </div>
                  </td>
                  <td
                    class="uk-text-right">{{ getItemDiscountedPrice(item) | round: 2 | number : '1.2-2' }} {{ item.price.currency }}</td>
                </tr>
                <tr [hidden]="getDiscountedPrice().price <= 0">
                  <td colspan="4" class="uk-text-right">Total</td>
                  <td>{{ getItemsTotalPrice() | round: 2 | number : '1.2-2' }} {{ activeReservation.items[0].price.currency }}</td>
                  <td colspan="2"></td>
                </tr>
                <tr [hidden]="getDiscountedPrice().price <= 0">
                  <td colspan="6" class="uk-text-right">Discount <span
                    *ngIf="getDiscountedPrice().price > 0">{{ getDiscountedPrice().percentage | round: 2 | number : '1.2-2' }}
                    %</span></td>
                  <td class="uk-text-right" *ngIf="getDiscountedPrice().price > 0">
                    - {{ getDiscountedPrice().price | round: 2 | number : '1.2-2' }} {{ activeReservation.items[0].price.currency }}
                  </td>
                  <td class="uk-text-right" *ngIf="getDiscountedPrice().price == 0">
                    0
                  </td>
                </tr>
                <tr>
                  <td colspan="6" class="uk-text-right bold">Total</td>
                  <td
                    class="uk-text-right">{{ getItemsDiscountedPrice() | round: 2 | number : '1.2-2' }} {{ activeReservation.items[0].price.currency | lowercase }}</td>
                </tr>
                <ng-container *ngIf="getUniqueVATs().length > 1">
                  <tr>
                    <td colspan="6" class="uk-text-right uk-text-meta uk-text-right">VAT total</td>
                    <td
                      class="uk-text-meta uk-text-right">{{ getItemsVAT() | round: 2 | number : '1.2-2' }} {{ tradingCurrency | lowercase }}</td>
                  </tr>
                  <tr *ngFor="let VAT of getUniqueVATs()" class="vat-row uk-text-right">
                    <td colspan="6" class="uk-text-right uk-text-meta">VAT {{ VAT.percentage }} %</td>
                    <td
                      class="uk-text-meta">{{ VAT.amount | round: 2 | number : '1.2-2' }} {{ tradingCurrency | lowercase }}</td>
                  </tr>
                </ng-container>
                <ng-container *ngIf="getUniqueVATs().length <= 1">
                  <tr>
                    <td colspan="6" class="uk-text-right uk-text-meta uk-text-right"
                        *ngFor="let VAT of getUniqueVATs()">VAT {{ VAT.percentage }} %
                    </td>
                    <td class="uk-text-meta uk-text-right"
                        *ngFor="let VAT of getUniqueVATs()">{{ VAT.amount | round: 2 | number : '1.2-2' }} {{ tradingCurrency | lowercase }}</td>
                  </tr>
                </ng-container>
                </tbody>
              </table>
              <p class="uk-text-left" *ngIf="!activeReservation.items.length">No artworks added</p>
            </div>
          </div>
          <!--<div class="uk-width-1-2@s uk-width-1-3@m" *ngIf="activeReservation.items.length">
            <div class="uk-margin">
              <label class="uk-form-label" for="editReservationPrice">Price</label>
              <div class="uk-form-controls">
                <input id="editReservationPrice" name="editReservationPrice" formControlName="editReservationPrice" type="text" [ngModel]="activeReservation.price" placeholder="Price ..." class="uk-input" [ngClass]="{'uk-form-danger': ((editReservationForm.get('editReservationPrice').touched && editReservationForm.get('editReservationPrice').errors) || editReservationForm.get('editReservationPrice').value > getItemsTotalPrice() || editReservationForm.get('editReservationPrice').value < 0) }">
              </div>
            </div>
          </div>-->
          <!--<div class="uk-width-1-2@s uk-width-1-3@m" *ngIf="activeReservation.items.length">
            <div class="uk-margin">
              <label class="uk-form-label" for="editReservationPrice">Discount</label>
              <div class="uk-form-controls" *ngIf="getDiscountedPrice().price > 0">
                <label>{{ getDiscountedPrice().price | round: 2 }} {{ activeReservation.items[0].price.currency }} / {{ getDiscountedPrice().percentage | round: 2 }} %</label>
              </div>
              <div class="uk-form-controls" *ngIf="getDiscountedPrice().price == 0">
                <label>No discount given</label>
              </div>
            </div>
          </div>-->
          <!--<div class="uk-width-1-2@s uk-width-1-3@m" *ngIf="activeReservation.items.length">
            <div class="uk-margin">
              <label class="uk-form-label" for="editReservationCurrency">Currency</label>
              <div class="uk-form-controls">
                <input id="editReservationCurrency" name="editReservationCurrency" formControlName="editReservationCurrency" type="text" placeholder="Currency ..." [ngModel]="tradingCurrency" class="uk-input" [ngClass]="{'uk-form-danger': editReservationForm.get('editReservationCurrency').touched && editReservationForm.get('editReservationCurrency').errors }">
              </div>
            </div>
          </div>-->
          <div class="uk-width-1-1 uk-margin-medium-top">
            <h3 class="uk-margin-remove-bottom">Notes on reservation</h3>
            <hr class="uk-margin-small-top">
          </div>
          <div class="uk-width-1-2@s uk-width-1-3@m">
            <div class="uk-margin">
              <div class="uk-flex uk-flex-between uk-text-meta">
                <label class="uk-form-label" for="editReservationShowNotes">Notes</label>
                <div class="uk-text-small">
                  {{ getCharactersLeft('editReservationShowNotes', 2048) }}
                </div>
              </div>
              <div class="uk-form-controls">
                <textarea id="editReservationShowNotes" name="editReservationShowNotes"
                          formControlName="editReservationShowNotes" rows="5" [ngModel]="activeReservation.showNotes"
                          type="text" placeholder="Notes shown on reservation ..." class="uk-textarea"
                          [ngClass]="{'uk-form-danger': editReservationForm.get('editReservationShowNotes').touched && editReservationForm.get('editReservationShowNotes').errors }"></textarea>
                <div class="uk-text-danger uk-text-small"
                     *ngIf="editReservationForm.get('editReservationShowNotes').hasError('maxlength')"
                >
                  {{ getCharactersLimitError('editReservationShowNotes') }}
                </div>
              </div>
            </div>
          </div>
          <div class="uk-width-1-1 uk-margin-medium-top">
            <h3 class="uk-margin-remove-bottom">Additional information</h3>
            <hr class="uk-margin-small-top">
          </div>
          <div class="uk-width-1-1@s">
            <div class="uk-margin">
              <label class="uk-form-label">Attachments</label>
              <div class="uk-form-controls uk-margin-small">
                <dl class="uk-description-list" *ngIf="activeReservation.attachments.length">
                  <div class="uk-flex uk-flex-middle" *ngFor="let attachment of activeReservation.attachments">
                    <div class="uk-padding-small">
                      <img class="contenttype" width="40" height="40"
                           src="/api/storagefile/{{ activeAccount }}/{{ attachment.fileId }}/small"
                           *ngIf="['image/png', 'image/jpg', 'image/jpeg', 'image/heic', 'image/gif', 'image/svg+xml'].includes(attachment.contentType)"/>
                      <img class="contenttype" width="40" height="40" src="/api/file/5a5de6b1229e86403d67db11"
                           *ngIf="['application/msword', 'application/vnd.openxmlformats-officedocument.wordprocessingml.document'].includes(attachment.contentType)"/>
                      <img class="contenttype" width="40" height="40" src="/api/file/5a5de6b1229e86403d67db10"
                           *ngIf="['application/octet-stream', 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'].includes(attachment.contentType)"/>
                      <img class="contenttype" width="40" height="40" src="/api/file/5a5de6b1229e86403d67db12"
                           *ngIf="['application/pdf'].includes(attachment.contentType)"/>
                      <img class="contenttype" width="40" height="40" src="/api/file/5a5de7bc229e86403d67db1b"
                           *ngIf="['text/plain'].includes(attachment.contentType)"/>
                      <img class="contenttype" width="40" height="40" src="/api/file/5a5de6b1229e86403d67db0f"
                           *ngIf="!(['image/png', 'image/jpg', 'image/jpeg', 'image/heic', 'image/gif', 'image/svg+xml', 'application/msword', 'application/vnd.openxmlformats-officedocument.wordprocessingml.document', 'application/octet-stream', 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet', 'application/pdf', 'text/plain'].includes(attachment.contentType))"/>
                    </div>
                    <div class="uk-width-expand">{{ attachment.name }}</div>
                    <div class="uk-button-group">
                      <button (click)="openAttachment(attachment)" class="uk-button uk-button-default" type="button">
                        Open
                      </button>
                      <div class="uk-inline uk-text-right">
                        <button class="uk-button uk-button-default" type="button"><span
                          uk-icon="icon:  chevron-down"></span></button>
                        <div uk-dropdown="mode: click; boundary: ! .uk-button-group; boundary-align: true;">
                          <ul class="uk-nav uk-dropdown-nav">
                            <li><a (click)="removeAttachment(attachment)">Remove</a></li>
                          </ul>
                        </div>
                      </div>
                    </div>
                  </div>
                </dl>
                <p *ngIf="!activeReservation.attachments.length">No related attachments</p>
              </div>
            </div>
          </div>
          <div class="uk-width-1-1@s">
            <div class="uk-margin">
              <label class="uk-form-label">Add new attachments</label>
              <div class="uk-form-controls">
                <div class="drop-container" ngFileDrop [options]="options"
                     (uploadOutput)="onAttachmentsUploadOutput($event)" (click)="attachmentFile.click()"
                     [uploadInput]="uploadInput">
                  <div class="js-upload uk-placeholder uk-text-center" [ngClass]="{ 'is-drop-over': dragOver }">
                    <span
                      class="uk-text-middle">Add files by dropping them here or open file dialog by clicking here.<br>Maximum allowed file size is 5 Mb.</span>
                  </div>
                  <input #attachmentFile type="file" ngFileSelect [options]="{ concurrency: 1, maxFileSize: 5000000 }"
                         [uploadInput]="uploadInput" (uploadOutput)="onAttachmentsUploadOutput($event)" multiple
                         class="file-input">
                </div>
              </div>
              <!--              <progress id="js-progressbar" class="uk-progress" value="0" max="100" hidden></progress>-->
              <!--              <div class="table-container" *ngIf="filesToUpload.length">-->
              <!--                <table class="uk-table uk-table-middle uk-margin-top">-->
              <!--                  <caption>Files to upload</caption>-->
              <!--                  <thead>-->
              <!--                  <tr>-->
              <!--                    <th class="uk-table-shrink uk-text-capitalize">Image</th>-->
              <!--                    <th class="uk-width-expand uk-text-capitalize">Name</th>-->
              <!--                    <th class="uk-width-expand uk-text-capitalize">Size</th>-->
              <!--                    <th class="uk-table-shrink uk-text-capitalize"></th>-->
              <!--                  </tr>-->
              <!--                  </thead>-->
              <!--                  <tbody>-->
              <!--                  <tr *ngFor="let file of filesToUpload; let i = index;">-->
              <!--                    <td class="uk-background-muted"><img class="uk-preserve-width uk-border-circle" src="/api/image/artbuddy-logo-placeholder.svg" width="60" uk-img></td>-->
              <!--                    <td>{{ file.name }}</td>-->
              <!--                    <td class="uk-text-nowrap">{{ file.size | bytes: 2 }}</td>-->
              <!--                    <td><button (click)="removeFile(file.id)" class="uk-button uk-button-default" type="button">Remove</button></td>-->
              <!--                  </tr>-->
              <!--                  </tbody>-->
              <!--                </table>-->
              <!--                <button type="button" (click)="startUpload()" class="uk-button uk-button-primary">Start upload</button>-->
              <!--              </div>-->
            </div>
          </div>
          <div class="uk-width-1-2@s uk-width-1-3@m">
            <div class="uk-margin">
              <div class="uk-flex uk-flex-between uk-text-meta">
                <label class="uk-form-label" for="editReservationNotes">Other information</label>
                <div class="uk-text-small">
                  {{ getCharactersLeft('editReservationNotes', 2048) }}
                </div>
              </div>
              <div class="uk-form-controls">
                <textarea id="editReservationNotes" name="editReservationNotes" formControlName="editReservationNotes"
                          rows="5" [ngModel]="activeReservation.notes" type="text" placeholder="Other information ..."
                          class="uk-textarea"
                          [ngClass]="{'uk-form-danger': editReservationForm.get('editReservationNotes').touched && editReservationForm.get('editReservationNotes').errors }"></textarea>
                <div class="uk-text-danger uk-text-small"
                     *ngIf="editReservationForm.get('editReservationNotes').hasError('maxlength')"
                >
                  {{ getCharactersLimitError('editReservationNotes') }}
                </div>
              </div>
            </div>
          </div>
          <div class="uk-width-1-2@s uk-width-1-3@m">
            <div class="uk-margin">
              <div class="uk-flex uk-flex-between uk-text-meta">
                <label class="uk-form-label" for="editReservationComments">Comments</label>
                <div class="uk-text-small">
                  {{ getCharactersLeft('editReservationComments', 2048) }}
                </div>
              </div>
              <div class="uk-form-controls">
                <textarea id="editReservationComments" name="editReservationComments"
                          formControlName="editReservationComments" rows="5" [ngModel]="activeReservation.comments"
                          type="text" placeholder="Comments ..." class="uk-textarea"
                          [ngClass]="{'uk-form-danger': editReservationForm.get('editReservationComments').touched && editReservationForm.get('editReservationComments').errors }"></textarea>
                <div class="uk-text-danger uk-text-small"
                     *ngIf="editReservationForm.get('editReservationComments').hasError('maxlength')"
                >
                  {{ getCharactersLimitError('editReservationComments') }}
                </div>
              </div>
            </div>
          </div>
          <div class="uk-width-1-2@s uk-width-1-3@m">
            <div class="uk-margin">
              <label class="uk-form-label" for="editReservationTags">Tags</label>
              <div class="uk-form-controls">
                <input id="editReservationTags" name="editReservationTags" formControlName="editReservationTags"
                       [ngModel]="activeReservation.tags" type="text" placeholder="Tags ..." class="uk-input"
                       [ngClass]="{'uk-form-danger': editReservationForm.get('editReservationTags').touched && editReservationForm.get('editReservationTags').errors }">
                <div class="uk-text-danger uk-text-small"
                     *ngIf="editReservationForm.get('editReservationTags').hasError('maxlength')"
                >
                  {{ getCharactersLimitError('editReservationTags') }}
                </div>
              </div>
            </div>
          </div>
          <div class="uk-width-1-2@s uk-width-1-3@m">
            <div class="uk-margin">
              <label class="uk-form-label" for="editReservationVisibility">Visibility</label>
              <div class="uk-form-controls">
                <label><input id="editReservationVisibility" name="editReservationVisibility"
                              formControlName="editReservationVisibility" [ngModel]="activeReservation.visibility"
                              class="uk-checkbox" type="checkbox"> Public</label>
              </div>
            </div>
          </div>
          <div class="uk-width-1-1 uk-margin-medium-top">
            <hr class="uk-margin-small-top">
          </div>
          <div class="uk-width-1-1 uk-margin-remove sticky-bottom uk-background-default" uk-sticky="bottom: true">
            <div class="uk-width-1-1">
              <div class="uk-flex uk-flex-middle uk-flex-between uk-margin">
                <app-editing-history
                  [updatedAt]="activeReservation.updatedAt"
                  [name]="activeReservation.requestUser?.name"
                  [email]="activeReservation.requestUser?.email"
                ></app-editing-history>
                <div class="uk-text-right">
                  <button type="button" class="uk-button uk-button-default uk-margin-small-right"
                          [disabled]="!editReservationForm.valid || isLoading" (click)="getCSV()">Get CSV
                  </button>
                  <button type="button" class="uk-button uk-button-default uk-margin-small-right"
                          [disabled]="!editReservationForm.valid || !activeReservation.items.length || isLoading"
                          (click)="getReservationPDF()">Open PDF
                  </button>
                  <button type="button" class="uk-button uk-button-default uk-margin-small-right"
                          [disabled]="!editReservationForm.valid || editReservationForm.get('editReservationStatus').value || !activeReservation.items.length || isLoading"
                          (click)="createTrade()">Create trade
                  </button>
                  <button type="submit" class="uk-button uk-button-primary"
                          [disabled]="!editReservationForm.valid || uploadLoading || isLoading">Save
                  </button>
                </div>
              </div>
            </div>
          </div>
        </form>
      </div>
      <div id="reservationartworks" class="uk-padding-small uk-padding-remove-top">
        <nav class="uk-navbar-container navbar-search-artwork-container" uk-navbar>
          <div class="nav-overlay uk-navbar-right">
            <button class="uk-button uk-button-default uk-margin-small-right" type="button"
                    (click)="openAdvancedSearch()">Add artwork
            </button>
            <button class="uk-button uk-button-default" type="button" (click)="openSlideshow($event)"
                    [disabled]="!activeReservation.items.length">Slideshow
            </button>
          </div>
          <!--<div id="nav-search-artwork" class="nav-overlay uk-navbar-container uk-navbar-left uk-flex-1" hidden>
            <div class="uk-navbar-item uk-width-expand">
              <form id="searchArtwork" [formGroup]="searchArtworkForm" (ngSubmit)="searchArtwork()" class="uk-search uk-search-navbar uk-width-1-1">
                <input id="searchArtworkTerm" name="searchArtworkTerm" formControlName="searchArtworkTerm" class="uk-search-input" type="search" placeholder="Search artworks..." [ngClass]="{' uk-form-danger': searchArtworkForm.get('searchArtworkTerm').touched && searchArtworkForm.get('searchArtworkTerm').hasError('invalid') }" autocomplete="off" autofocus>
                <button type="submit" class="uk-button uk-button-primary" hidden>Search</button>
              </form>
            </div>
            <a class="uk-navbar-toggle" uk-close uk-toggle="target: #reservationartworks .nav-overlay; animation: uk-animation-fade" (click)="emptySearch()"></a>
          </div>-->
        </nav>
        <!--<div id="search-artwork-results-container">
          <div class="uk-flex uk-flex-column">
            &lt;!&ndash;<div class="uk-container-expand uk-height-expand uk-padding-small uk-padding-remove-left uk-padding-remove-right" *ngIf="!searchArtworkResults.length">
              <ul class="search-results uk-flex-center" uk-tab>
                <h3>No search results</h3>
              </ul>
            </div>&ndash;&gt;
            <div class="uk-container-expand uk-height-expand">
              <div class="uk-margin">
                <div class="uk-width-1-1">
                  <table class="uk-table uk-table-middle uk-table-divider uk-margin-remove-bottom">
                    <thead>
                      <tr>
                        <th class="uk-table-shrink">Select</th>
                        <th class="uk-table-shrink">Image</th>
                        <th class="uk-width-expand">Name</th>
                        <th class="uk-width-expand uk-text-nowrap">Artist</th>
                        <th class="uk-table-shrink uk-text-nowrap">Technique</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr *ngFor="let artwork of searchArtworkResults">
                        <td><input class="uk-checkbox" type="checkbox" (click)="selectReservationArtwork(artwork)"></td>
                        <td>
                          <div class="image-max-height uk-preserve-width uk-background-contain uk-background-muted uk-height-small uk-panel uk-flex uk-flex-center uk-flex-middle" width="60" [attr.data-src]="'/api/storagefile/' + activeAccount + '/' + artwork.coverImage + '/small'" uk-img *ngIf="artwork.coverImage">

                          </div>
                          &lt;!&ndash; Jos ei ole coverImagea, niin sit otetaan eka image &ndash;&gt;
                          &lt;!&ndash;<img class="uk-preserve-width" [src]="'/api/storagefile/' + artwork.images[0].fileId" width="60" *ngIf="!artwork.coverImage && artwork.images && artwork.images.length">&ndash;&gt;
                          <div class="image-max-height uk-preserve-width uk-background-contain uk-background-muted uk-height-small uk-panel uk-flex uk-flex-center uk-flex-middle" width="60" [attr.data-src]="'/api/storagefile/' + activeAccount + '/' + artwork.images[0].fileId + '/small'" uk-img *ngIf="!artwork.coverImage && artwork.images && artwork.images.length">

                          </div>
                          &lt;!&ndash; Jos ei ole coverImagea tai ekan imagea, ni sit otetaan placeholder &ndash;&gt;
                          &lt;!&ndash;<img class="uk-preserve-width uk-border-circle" src="/api/image/artbuddy-logo-placeholder.svg" *ngIf="!artwork.coverImage && !artwork.images.length" width="60">&ndash;&gt;
                          <div class="image-max-height uk-preserve-width uk-background-contain uk-background-muted uk-height-small uk-panel uk-flex uk-flex-center uk-flex-middle" width="60" [attr.data-src]="'/api/image/artbuddy-logo-placeholder.svg'" uk-img *ngIf="!artwork.coverImage && !artwork.images.length">

                          </div>
                        </td>
                        <td>{{ artwork.name }}</td>
                        <td>{{ artwork.artistName }}</td>
                        <td>{{ artwork.technique }}</td>
                      </tr>
                    </tbody>
                  </table>
                  <hr class="uk-margin-small-top">
                  <div class="uk-text-right">
                    <button type="submit" class="uk-button uk-button-primary" (click)="addArtworksToReservation()" [disabled]="!selectedArtworks.length">Add artworks</button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>-->
        <div class="uk-grid-match uk-child-width-1-2@s uk-child-width-1-3@m uk-child-width-1-4@xl" uk-grid uk-sortable>
          <div class="uk-card" *ngFor="let item of activeReservation.items">
            <div class="uk-card-header uk-flex uk-flex-middle uk-flex-between uk-padding-remove-horizontal">
              <div class="uk-padding-remove-left">
                <h3 class="uk-card-title uk-margin-remove-bottom">{{ item.name }}</h3>
                <div class="uk-text-meta">{{ item.artistName }}</div>
              </div>
              <!--<div>
                <div class="uk-text-meta">{{ item.availability | shorten: 50: '...' }}</div>
                <div class="uk-text-meta" *ngIf="item.price.price">{{ item.price.price }} {{ item.price.currency }}</div>
              </div>-->
            </div>
            <div class="uk-card-body uk-padding-remove uk-background-muted artwork-image"
                 [attr.data-src]="'/api/storagefile/' + activeAccount + '/' + item.coverImage + '/medium'" uk-img
                 *ngIf="item.coverImage"></div>
            <div class="uk-card-body uk-padding-remove uk-background-muted artwork-image"
                 [attr.data-src]="'/api/storagefile/' + activeAccount + '/' + item.images[0].fileId + '/medium'" uk-img
                 *ngIf="!item.coverImage && item.images && item.images.length"></div>
            <div class="uk-card-body uk-padding-remove uk-background-muted artwork-image bg-max-size"
                 [attr.data-src]="'/api/image/artbuddy-logo-placeholder.svg'" uk-img
                 *ngIf="!item.coverImage && !item.images.length"></div>
            <div class="uk-card-footer uk-flex uk-flex-middle uk-flex-between uk-padding-remove-horizontal">
              <div>
                <div class="uk-text-meta" *ngIf="item.width && item.height">{{ item.width }} * {{ item.height }}cm
                </div>
                <!--<div class="uk-text-meta">{{ item.technique }}</div>-->
              </div>
              <div class="uk-flex uk-flex-column">
                <a (click)="removeArtworkFromReservation(item)" class="uk-button uk-button-text">Remove</a>
              </div>
            </div>
          </div>
        </div>
      </div>
      <!--<div id="reservationartists" class="uk-padding-small">
        <table class="uk-table uk-table-middle uk-table-divider">
          <thead>
          <tr>
            <th class="uk-table-shrink"></th>
            <th class="uk-table-shrink">Image</th>
            <th class="uk-width-expand">Name</th>
            <th class="uk-width-expand uk-text-nowrap">Multiplier</th>
            <th class="uk-table-shrink uk-text-nowrap">Status</th>
            <th class="uk-table-shrink"></th>
          </tr>
          </thead>
          <tbody>
          <tr *ngFor="let artist of artists">
            &lt;!&ndash;<td><input class="uk-checkbox" type="checkbox"></td>&ndash;&gt;
            <td><img class="uk-preserve-width uk-border-circle"
                     src="/api/image/artbuddy-logo-placeholder.svg"
                     width="60"></td>
            <td>{{ artist.name }}</td>
            <td>{{ artist.priceMultiplier }}</td>
            <td>{{ artist.status }}</td>
            <td>
              <button (click)="openArtist(artist._id)" class="uk-button uk-button-default" type="button">Open</button>
            </td>
          </tr>
          </tbody>
        </table>
      </div>-->
    </ul>
  </div>
</div>
<app-advanced-search-modal (setResults)="setArtworkSearchResults($event)"
                           *ngIf="showAdvancedArtworkSearchModalWindow"></app-advanced-search-modal>
