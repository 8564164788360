<div class="uk-section uk-section-default uk-padding-remove-top" uk-height-viewport="offset-top: true">

  <app-sidenav contentType="Proposals"></app-sidenav>

  <div class="uk-container-expand uk-height-expand">
    <div uk-grid>
      <div class="uk-width-expand@m">
        <div class="uk-padding-small uk-padding-remove-top" *ngIf="!isLoading">
          <div class="uk-width-1-1 uk-text-right uk-margin-small-bottom uk-padding-remove">
            <app-search-content contentType="bid" fields="['number', 'title']"></app-search-content>
            <button class="uk-button uk-button-text uk-margin-right" type="button" (click)="showOpenBids()">
              Show open
            </button>
            <div id="showOpen" class="uk-modal-container" uk-modal="bg-close: false; esc-close: false;">
              <div class="uk-modal-dialog">
                <div class="uk-modal-header">
                  <h2 class="uk-modal-title">Open proposals</h2>
                </div>
                <div class="uk-modal-body" uk-grid>
                  <div class="uk-width-1-1@s">
                    <table class="uk-table uk-table-middle uk-table-divider" *ngIf="openBids.length">
                      <thead>
                      <tr>
                        <th class="uk-text-nowrap" [ngClass]="{'active': activeSort('sequence') }"
                            (click)="sortBids('sequence')">Number<span uk-icon="chevron-down"
                                                                       [ngClass]="{'inverse': !sortAscending }"></span>
                        </th>
                        <th class="uk-text-wrap" [ngClass]="{'active': activeSort('name') }" (click)="sortBids('name')">
                          Title<span uk-icon="chevron-down" [ngClass]="{'inverse': !sortAscending }"></span></th>
                        <th class="uk-text-nowrap uk-visible@m" [ngClass]="{'active': activeSort('customer') }"
                            (click)="sortBids('customer')">Customer<span uk-icon="chevron-down"
                                                                         [ngClass]="{'inverse': !sortAscending }"></span>
                        </th>
                        <th class="uk-text-nowrap uk-visible@m" [ngClass]="{'active': activeSort('type') }"
                            (click)="sortBids('type')">Type<span uk-icon="chevron-down"
                                                                 [ngClass]="{'inverse': !sortAscending }"></span></th>
                        <th class="uk-text-nowrap uk-visible@m" [ngClass]="{'active': activeSort('price') }"
                            (click)="sortBids('price')">Price<span uk-icon="chevron-down"
                                                                   [ngClass]="{'inverse': !sortAscending }"></span></th>
                        <th class="uk-text-nowrap uk-visible@m" [ngClass]="{'active': activeSort('valid') }"
                            (click)="sortBids('valid')">Valid<span uk-icon="chevron-down"
                                                                   [ngClass]="{'inverse': !sortAscending }"></span></th>
                        <th class="uk-table-shrink"></th>
                      </tr>
                      </thead>
                      <tbody>
                      <tr *ngFor="let bid of openBids">
                        <td>{{ bid.sequence }}</td>
                        <td>{{ bid.name }}</td>
                        <td class="uk-visible@m">{{ bid.customer.name }} {{ bid.customer.lastName }}</td>
                        <td class="uk-visible@m">{{ bid.type }}</td>
                        <td class="uk-visible@m">{{ bid.itemsTotalPrice }} <span *ngIf="bid.itemsTotalPrice !== 'Unknown'">{{ account.tradingCurrency }}</span></td>
                        <td class="uk-visible@m">{{ bid.valid | date: 'dd.MM.yyyy' }}</td>
                        <td class="uk-text-right">
                          <div class="uk-button-group">
                            <button (click)="openBid(bid._id)" class="uk-button uk-button-default uk-modal-close"
                                    type="button">Open
                            </button>
                            <div class="uk-inline" class="uk-visible@m">
                              <button class="uk-button uk-button-default" type="button"><span
                                uk-icon="icon:  chevron-down"></span></button>
                              <div uk-dropdown="mode: click; boundary: ! .uk-button-group; boundary-align: true;">
                                <ul class="uk-nav uk-dropdown-nav">
                                  <li><a (click)="removeBid(bid._id)">Remove</a></li>
                                </ul>
                              </div>
                            </div>
                          </div>
                        </td>
                      </tr>
                      </tbody>
                    </table>
                    <p class="uk-text-center" *ngIf="!openBids.length">No open proposals</p>
                  </div>
                </div>
                <div class="uk-modal-footer">
                  <p class="uk-text-right">
                    <button class="uk-button uk-button-default uk-modal-close" type="button" id="showOpenClose">Close
                    </button>
                  </p>
                </div>
              </div>
            </div>
            <button class="uk-button uk-button-text uk-margin-right" type="button" (click)="openAddNewBid()">
              Add new
            </button>
            <div id="addNewBid" class="uk-modal-container" uk-modal="stack: true; bg-close: false; esc-close: false;">
              <div class="uk-modal-dialog">
                <form appNoSubmitEnter [formGroup]="addBidForm">
                  <input type="hidden" name="addBidOwner" name="addBidOwner" formControlName="addBidOwner"
                         [ngModel]="activeAccount">
                  <div class="uk-modal-header">
                    <h2 class="uk-modal-title">Add new proposal</h2>
                  </div>
                  <div class="uk-modal-body" uk-grid>
                    <div class="uk-width-1-2@s">
                      <div class="uk-margin">
                        <label class="uk-form-label" for="addBidName">Title</label>
                        <div class="uk-form-controls">
                          <input id="addBidName" name="addBidName" formControlName="addBidName" type="text"
                                 placeholder="Title ..." class="uk-input"
                                 [ngClass]="{'uk-form-danger': addBidForm.get('addBidName').touched && addBidForm.get('addBidName').errors }">
                          <div class="uk-text-danger uk-text-small"
                               *ngIf="addBidForm.get('addBidName').hasError('maxlength')"
                          >
                            {{ getCharactersLimitError('addBidName') }}
                          </div>
                          <div class="uk-text-danger uk-text-small"
                               *ngIf="addBidForm.get('addBidName').hasError('pattern')"
                          >
                            Field value is incorrect
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="uk-width-1-2@s">
                      <div class="uk-margin">
                        <div class="uk-flex uk-flex-between uk-text-meta">
                          <label class="uk-form-label" for="addBidDescription">Description</label>
                          <div class="uk-text-small">
                            {{ getCharactersLeft('addBidDescription', 2048) }}
                          </div>
                        </div>
                        <div class="uk-form-controls">
                          <textarea id="addBidDescription" name="addBidDescription" formControlName="addBidDescription"
                                    rows="5" placeholder="Description ..." class="uk-textarea"
                                    [ngClass]="{'uk-form-danger': addBidForm.get('addBidDescription').touched && addBidForm.get('addBidDescription').errors }"></textarea>
                          <div class="uk-text-danger uk-text-small"
                               *ngIf="addBidForm.get('addBidDescription').hasError('maxlength')"
                          >
                            {{ getCharactersLimitError('addBidDescription') }}
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="uk-width-1-2@s">
                      <div class="uk-margin">
                        <label class="uk-form-label" for="addBidType">Proposal type <span
                          class="required">*</span></label>
                        <div class="uk-form-controls">
                          <select id="addBidType" name="addBidType" formControlName="addBidType" class="uk-select"
                                  (blur)="setBidType()">
                            <option value="" disabled>Please select ...</option>
                            <option value="Buy">Buy</option>
                            <option value="Sell">Sell</option>
                          </select>
                        </div>
                      </div>
                    </div>
                    <div class="uk-width-1-2@s" *ngIf="['Sell'].includes(addBidForm.get('addBidType').value)">
                      <div class="uk-margin">
                        <label class="uk-form-label" for="addBidSeller">Seller <span class="required">*</span></label>
                        <div class="uk-form-controls">
                          <input id="addBidSeller" name="addBidSeller" formControlName="addBidSeller" type="text"
                                 placeholder="{{ accountActor.name }}" class="uk-input" [ngModel]="accountActor.name"
                                 disabled>
                        </div>
                      </div>
                    </div>
                    <div class="uk-width-1-2@s" *ngIf="['Sell'].includes(addBidForm.get('addBidType').value)">
                      <div class="uk-margin">
                        <label class="uk-form-label" for="addBidCustomer">Customer <span
                          class="required">*</span></label>
                        <div class="uk-form-controls">
                          <!--<select id="addBidCustomer" name="addBidCustomer" formControlName="addBidCustomer" class="uk-select">
                             <option value="" disabled>Please select ...</option>
                             <option *ngFor="let actor of actors" value="{{ actor._id }}">{{ actor.name }} {{ actor.lastName }}</option>
                           </select>-->

                          <ng-select [items]="clients"
                                     [virtualScroll]="true"
                                     [addTag]="false"
                                     [selectOnTab]="false"
                                     addTagText="Add new: "
                                     placeholder="Please select ..."
                                     bindLabel="fullName"
                                     id="addBidCustomer"
                                     formControlName="addBidCustomer"
                                     class="uk-select"
                                     #addBidCustomer (change)="addBidCustomer.blur()">
                            <!--<ng-template ng-header-tmp>
                              <div class="uk-text-muted uk-text-small">New value:</div>
                            </ng-template>-->
                          </ng-select>
                          <div class="uk-text-danger uk-text-small"
                               *ngIf="addBidForm.get('addBidCustomer').hasError('maxlength')"
                          >
                            {{ getCharactersLimitError('addBidCustomer') }}
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="uk-width-1-2@s" *ngIf="['Buy'].includes(addBidForm.get('addBidType').value)">
                      <div class="uk-margin">
                        <label class="uk-form-label" for="addBidSeller">Seller <span class="required">*</span></label>
                        <div class="uk-form-controls">
                          <ng-select [items]="actors"
                                     [virtualScroll]="true"
                                     [addTag]="false"
                                     [selectOnTab]="false"
                                     addTagText="Add new: "
                                     placeholder="Please select ..."
                                     bindLabel="fullName"
                                     id="addBidSeller"
                                     formControlName="addBidSeller"
                                     class="uk-select"
                                     #addBidSeller (change)="addBidSeller.blur()">
                          </ng-select>
                          <div class="uk-text-danger uk-text-small"
                               *ngIf="addBidForm.get('addBidSeller').hasError('maxlength')"
                          >
                            {{ getCharactersLimitError('addBidSeller') }}
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="uk-width-1-2@s" *ngIf="['Buy'].includes(addBidForm.get('addBidType').value)">
                      <div class="uk-margin">
                        <label class="uk-form-label" for="addBidCustomer">Customer <span
                          class="required">*</span></label>
                        <div class="uk-form-controls">
                          <input id="addBidCustomer" name="addBidCustomer" formControlName="addBidCustomer" type="text"
                                 placeholder="{{ accountActor.name }}" class="uk-input" [ngModel]="accountActor.name"
                                 disabled>
                        </div>
                      </div>
                    </div>
                    <div class="uk-width-1-2@s">
                      <div class="uk-margin">
                        <label class="uk-form-label" for="addBidCreated">Creation date</label>
                        <div class="uk-form-controls">
                          <input
                            id="addBidCreated"
                            name="addBidCreated"
                            formControlName="addBidCreated"
                            type="text"
                            placeholder="Creation date ..."
                            class="uk-input"
                            [ngClass]="{'uk-form-danger': addBidForm.get('addBidCreated').touched && addBidForm.get('addBidCreated').errors }"
                            angular-mydatepicker
                            [options]="myDatePickerOptions"
                            #AngularMyDatePickerAddBidCreated="angular-mydatepicker"
                            (click)="AngularMyDatePickerAddBidCreated.toggleCalendar()"
                            (dateChanged)="updateDate('addBidCreated', $event)"
                          />
                          <div class="uk-text-danger uk-text-small"
                               *ngIf="addBidForm.get('addBidCreated').hasError('maxlength')"
                          >
                            {{ getCharactersLimitError('addBidCreated') }}
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="uk-width-1-2@s">
                      <div class="uk-margin">
                        <label class="uk-form-label" for="addBidValid">Valid until date</label>
                        <div class="uk-form-controls">
                          <input
                            id="addBidValid"
                            name="addBidValid"
                            formControlName="addBidValid"
                            type="text"
                            placeholder="Valid until date ..."
                            class="uk-input"
                            [ngClass]="{'uk-form-danger': addBidForm.get('addBidValid').touched && addBidForm.get('addBidValid').errors }"
                            angular-mydatepicker
                            [options]="myDatePickerOptions"
                            #AngularMyDatePickerAddBidValid="angular-mydatepicker"
                            (click)="AngularMyDatePickerAddBidValid.toggleCalendar()"
                            (dateChanged)="updateDate('addBidValid', $event)"
                          />
                          <div class="uk-text-danger uk-text-small"
                               *ngIf="addBidForm.get('addBidValid').hasError('maxlength')"
                          >
                            {{ getCharactersLimitError('addBidValid') }}
                          </div>
                        </div>
                      </div>
                    </div>
                    <!--                    <div class="uk-width-1-2@s">-->
                    <!--                      <div class="uk-margin">-->
                    <!--                        <label class="uk-form-label" for="addBidPrice">Price</label>-->
                    <!--                        <div class="uk-form-controls">-->
                    <!--                          <input id="addBidPrice" name="addBidPrice" formControlName="addBidPrice" type="text" placeholder="Price ..." class="uk-input" [ngClass]="{'uk-form-danger': addBidForm.get('addBidPrice').touched && addBidForm.get('addBidPrice').errors }">-->
                    <!--                        </div>-->
                    <!--                      </div>-->
                    <!--                    </div>-->
                    <div class="uk-width-1-2@s">
                      <div class="uk-margin">
                        <label class="uk-form-label" for="addBidCurrency">Currency</label>
                        <div class="uk-form-controls">
                          <input id="addBidCurrency" name="addBidCurrency" formControlName="addBidCurrency" type="text"
                                 placeholder="Currency ..." class="uk-input"
                                 [ngClass]="{'uk-form-danger': addBidForm.get('addBidCurrency').touched && addBidForm.get('addBidCurrency').errors }">
                          <div class="uk-text-danger uk-text-small"
                               *ngIf="addBidForm.get('addBidCurrency').hasError('maxlength')"
                          >
                            {{ getCharactersLimitError('addBidCurrency') }}
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="uk-width-1-2@s">
                      <div class="uk-margin">
                        <label class="uk-form-label" for="addBidVAT">VAT</label>
                        <div class="uk-form-controls">
                          <!--                          <input id="addBidVAT" name="addBidVAT" formControlName="addBidVAT" type="number" placeholder="VAT ..." class="uk-input" [ngClass]="{'uk-form-danger': addBidForm.get('addBidVAT').touched && addBidForm.get('addBidVAT').errors }">-->
                          <!--                          <select (change)="setItemVATPercentage(item._id, $event)" [ngModel]="item.VATPercentage" [ngModelOptions]="{standalone: true}" class="uk-select">-->
                          <!--                            <option value="24">24 %</option>-->
                          <!--                            <option *ngFor="let VAT of VATRates" value="{{ VAT.percentage }}">{{ VAT.percentage }} % ({{ VAT.description }})</option>-->
                          <!--                          </select>-->
                          <select id="addBidVAT" name="addBidVAT" formControlName="addBidVAT" class="uk-select">
                            <option *ngFor="let VAT of VATRates" value="{{ VAT.percentage }}">{{ VAT.percentage }} %
                          </select>
                        </div>
                      </div>
                    </div>
                    <div class="uk-width-1-2@s">
                      <div class="uk-margin">
                        <label class="uk-form-label" for="addBidOtherTerms">Other terms</label>
                        <div class="uk-form-controls">
                          <input id="addBidOtherTerms" name="addBidOtherTerms" formControlName="addBidOtherTerms"
                                 type="text" placeholder="Other terms ..." class="uk-input"
                                 [ngClass]="{'uk-form-danger': addBidForm.get('addBidOtherTerms').touched && addBidForm.get('addBidOtherTerms').errors }">
                          <div class="uk-text-danger uk-text-small"
                               *ngIf="addBidForm.get('addBidOtherTerms').hasError('maxlength')"
                          >
                            {{ getCharactersLimitError('addBidOtherTerms') }}
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="uk-width-1-2@s">
                      <div class="uk-margin">
                        <label class="uk-form-label" for="addBidClientReference">Client reference</label>
                        <div class="uk-form-controls">
                          <input id="addBidClientReference" name="addBidClientReference"
                                 formControlName="addBidClientReference" type="text" placeholder="Client reference ..."
                                 class="uk-input"
                                 [ngClass]="{'uk-form-danger': addBidForm.get('addBidClientReference').touched && addBidForm.get('addBidClientReference').errors }">
                          <div class="uk-text-danger uk-text-small"
                               *ngIf="addBidForm.get('addBidClientReference').hasError('maxlength')"
                          >
                            {{ getCharactersLimitError('addBidClientReference') }}
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="uk-width-1-2@s">
                      <div class="uk-margin">
                        <label class="uk-form-label" for="addBidSellerReference">Seller reference</label>
                        <div class="uk-form-controls">
                          <input id="addBidSellerReference" name="addBidSellerReference"
                                 formControlName="addBidSellerReference" type="text" placeholder="Seller reference ..."
                                 class="uk-input"
                                 [ngClass]="{'uk-form-danger': addBidForm.get('addBidSellerReference').touched && addBidForm.get('addBidSellerReference').errors }">
                          <div class="uk-text-danger uk-text-small"
                               *ngIf="addBidForm.get('addBidSellerReference').hasError('maxlength')"
                          >
                            {{ getCharactersLimitError('addBidSellerReference') }}
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="uk-width-1-2@s">
                      <div class="uk-margin">
                        <label class="uk-form-label" for="addBidStatus">Approval status</label>
                        <div class="uk-form-controls">
                          <label><input id="addBidStatus" name="addBidStatus" formControlName="addBidStatus"
                                        class="uk-checkbox" type="checkbox"> Approved</label>
                        </div>
                      </div>
                    </div>
                    <div class="uk-width-1-1 uk-margin-medium-top">
                      <h3 class="uk-margin-remove-bottom">Notes on proposal</h3>
                      <hr class="uk-margin-small-top">
                    </div>
                    <div class="uk-width-1-2@s uk-width-1-3@m">
                      <div class="uk-margin">
                        <div class="uk-flex uk-flex-between uk-text-meta">
                          <label class="uk-form-label" for="addBidShowNotes">Notes</label>
                          <div class="uk-text-small">
                            {{ getCharactersLeft('addBidShowNotes', 2048) }}
                          </div>
                        </div>
                        <div class="uk-form-controls">
                          <textarea id="addBidShowNotes" name="addBidShowNotes" formControlName="addBidShowNotes"
                                    rows="5" type="text" placeholder="Notes shown on proposal ..." class="uk-textarea"
                                    [ngClass]="{'uk-form-danger': addBidForm.get('addBidShowNotes').touched && addBidForm.get('addBidShowNotes').errors }"></textarea>
                          <div class="uk-text-danger uk-text-small"
                               *ngIf="addBidForm.get('addBidShowNotes').hasError('maxlength')"
                          >
                            {{ getCharactersLimitError('addBidShowNotes') }}
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="uk-width-1-1">
                      <div class="uk-margin">
                        <label class="uk-form-label">Related artworks</label>
                        <!--<form appNoSubmitEnter id="searchArtwork" [formGroup]="searchArtworkForm">
                          <div class="uk-margin">
                            <div class="uk-form-controls uk-flex">
                              <input id="searchArtworkTerm" name="searchArtworkTerm" formControlName="searchArtworkTerm" class="uk-input uk-margin-small-right" type="search" placeholder="Search artworks..." [ngClass]="{' uk-form-danger': searchArtworkForm.get('searchArtworkTerm').touched && searchArtworkForm.get('searchArtworkTerm').hasError('invalid') }" autocomplete="off">
                              <button class="uk-button uk-button-default" (click)="searchArtwork()">Search</button>
                            </div>
                          </div>
                        </form>-->
                        <div class="uk-text-right">
                          <button class="uk-button uk-button-default" (click)="openAdvancedSearch()">Add</button>
                        </div>
                        <!--<div class="uk-position-relative" [hidden]="!searchArtworkResults.length">
                          <div class="uk-text-right">
                            <button class="uk-button uk-button-primary" (click)="addArtworksToBid()" [disabled]="!selectedArtworks.length">Add selected</button>
                          </div>
                          <hr class="uk-margin-small-bottom">
                        </div>
                        <div id="search-artwork-results-container" class="results-container" [hidden]="!searchArtworkResults.length">
                          <div class="uk-flex uk-flex-column">
                            &lt;!&ndash;<div class="uk-container-expand uk-height-expand uk-padding-small uk-padding-remove-left uk-padding-remove-right" *ngIf="!searchArtworkResults.length">
                              <ul class="search-results uk-flex-center" uk-tab>
                                <h3>No search results</h3>
                              </ul>
                            </div>&ndash;&gt;
                            <div class="uk-container-expand uk-height-expand">
                              <div class="uk-margin">
                                <div class="uk-width-1-1">
                                  <table class="uk-table uk-table-middle uk-table-divider uk-margin-remove-bottom">
                                    <thead>
                                    <tr>
                                      <th class="uk-table-shrink">Select</th>
                                      <th class="uk-table-shrink">Image</th>
                                      <th class="uk-width-expand">Name</th>
                                      <th class="uk-width-expand uk-text-nowrap">Artist</th>
                                      <th class="uk-table-shrink uk-text-nowrap">Technique</th>
                                    </tr>
                                    </thead>
                                    <tbody>
                                    <tr *ngFor="let artwork of searchArtworkResults">
                                      <td><input class="uk-checkbox" type="checkbox" (click)="selectBidArtwork(artwork)"></td>
                                      <td>
                                        <div class="image-max-height uk-preserve-width uk-background-contain uk-background-muted uk-height-small uk-panel uk-flex uk-flex-center uk-flex-middle" width="60" [attr.data-src]="'/api/storagefile/' + activeAccount + '/' + artwork.coverImage + '/medium'" uk-img *ngIf="artwork.coverImage">

                                        </div>
                                        &lt;!&ndash; Jos ei ole coverImagea, niin sit otetaan eka image &ndash;&gt;
                                        &lt;!&ndash;<img class="uk-preserve-width" [src]="'/api/storagefile/' + artwork.images[0].fileId" width="60" *ngIf="!artwork.coverImage && artwork.images && artwork.images.length">&ndash;&gt;
                                        <div class="image-max-height uk-preserve-width uk-background-contain uk-background-muted uk-height-small uk-panel uk-flex uk-flex-center uk-flex-middle" width="60" [attr.data-src]="'/api/storagefile/' + activeAccount + '/' + artwork.images[0].fileId + '/medium'" uk-img *ngIf="!artwork.coverImage && artwork.images && artwork.images.length">

                                        </div>
                                        &lt;!&ndash; Jos ei ole coverImagea tai ekan imagea, ni sit otetaan placeholder &ndash;&gt;
                                        &lt;!&ndash;<img class="uk-preserve-width uk-border-circle" src="/api/image/artbuddy-logo-placeholder.svg" *ngIf="!artwork.coverImage && !artwork.images.length" width="60">&ndash;&gt;
                                        <div class="image-max-height uk-preserve-width uk-background-contain uk-background-muted uk-height-small uk-panel uk-flex uk-flex-center uk-flex-middle" width="60" [attr.data-src]="'/api/image/artbuddy-logo-placeholder.svg'" uk-img *ngIf="!artwork.coverImage && !artwork.images.length">

                                        </div>
                                      </td>
                                      <td>{{ artwork.name }}</td>
                                      <td>{{ artwork.artistName }}</td>
                                      <td>{{ artwork.technique }}</td>
                                    </tr>
                                    </tbody>
                                  </table>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>-->
                        <div class="uk-grid-match uk-child-width-1-2@s uk-child-width-1-3@m uk-child-width-1-4@xl"
                             uk-grid uk-sortable [hidden]="!activeBid.items.length">
                          <div class="uk-card" *ngFor="let item of activeBid.items">
                            <div
                              class="uk-card-header uk-flex uk-flex-middle uk-flex-between uk-padding-remove-horizontal">
                              <div class="uk-padding-remove-left">
                                <h3 class="uk-card-title uk-margin-remove-bottom">{{ item.name }}</h3>
                                <div class="uk-text-meta">
                                  <span *ngIf="item.artistName">{{ item.artistName }}</span>
                                  <span *ngIf="item.created">, {{ item.created }}</span>
                                </div>
                              </div>
                              <!--<div>
                                <div class="uk-text-meta">{{ item.availability }}</div>
                                <div class="uk-text-meta" *ngIf="item.price.price">{{ item.price.price }} {{ item.price.currency }}</div>
                              </div>-->
                            </div>
                            <div class="uk-card-body uk-padding-remove uk-background-muted artwork-image"
                                 [attr.data-src]="'/api/storagefile/' + activeAccount + '/' + item.coverImage + '/medium'"
                                 uk-img *ngIf="item.coverImage"></div>
                            <div class="uk-card-body uk-padding-remove uk-background-muted artwork-image"
                                 [attr.data-src]="'/api/storagefile/' + activeAccount + '/' + item.images[0].fileId + '/medium'"
                                 uk-img *ngIf="!item.coverImage && item.images && item.images.length"></div>
                            <div class="uk-card-body uk-padding-remove uk-background-muted artwork-image bg-max-size"
                                 *ngIf="!item.coverImage && !item.images.length"></div>
                            <div
                              class="uk-card-footer uk-flex uk-flex-middle uk-flex-between uk-padding-remove-horizontal">
                              <!--<div>
                                <div class="uk-text-meta" *ngIf="item.width && item.height">{{ item.width }} * {{ item.height }} cm</div>
                              </div>-->
                              <div class="uk-flex uk-flex-column">
                                <a (click)="removeArtworkFromBid(item)" class="uk-button uk-button-text">Remove</a>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="uk-width-1-1 uk-margin-medium-top">
                      <h3 class="uk-margin-remove-bottom">Additional information</h3>
                      <hr class="uk-margin-small-top">
                    </div>
                    <div class="uk-width-1-2">
                      <div class="uk-margin">
                        <div class="uk-flex uk-flex-between uk-text-meta">
                          <label class="uk-form-label" for="addBidNotes">Other information</label>
                          <div class="uk-text-small">
                            {{ getCharactersLeft('addBidNotes', 2048) }}
                          </div>
                        </div>
                        <div class="uk-form-controls">
                          <textarea id="addBidNotes" name="addBidNotes" formControlName="addBidNotes" rows="5"
                                    type="text" placeholder="Other information ..." class="uk-textarea"
                                    [ngClass]="{'uk-form-danger': addBidForm.get('addBidNotes').touched && addBidForm.get('addBidNotes').errors }"></textarea>
                          <div class="uk-text-danger uk-text-small"
                               *ngIf="addBidForm.get('addBidNotes').hasError('maxlength')"
                          >
                            {{ getCharactersLimitError('addBidNotes') }}
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="uk-width-1-2">
                      <div class="uk-margin">
                        <div class="uk-flex uk-flex-between uk-text-meta">
                          <label class="uk-form-label" for="addBidComments">Comments</label>
                          <div class="uk-text-small">
                            {{ getCharactersLeft('addBidComments', 2048) }}
                          </div>
                        </div>
                        <div class="uk-form-controls">
                          <textarea id="addBidComments" name="addBidComments" formControlName="addBidComments" rows="5"
                                    type="text" placeholder="Comments ..." class="uk-textarea"
                                    [ngClass]="{'uk-form-danger': addBidForm.get('addBidComments').touched && addBidForm.get('addBidComments').errors }"></textarea>
                          <div class="uk-text-danger uk-text-small"
                               *ngIf="addBidForm.get('addBidComments').hasError('maxlength')"
                          >
                            {{ getCharactersLimitError('addBidComments') }}
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="uk-width-1-2">
                      <div class="uk-margin">
                        <label class="uk-form-label" for="addBidTags">Tags</label>
                        <div class="uk-form-controls">
                          <input id="addBidTags" name="addBidTags" formControlName="addBidTags" type="text"
                                 placeholder="Tags ..." class="uk-input"
                                 [ngClass]="{'uk-form-danger': addBidForm.get('addBidTags').touched && addBidForm.get('addBidTags').errors }">
                          <div class="uk-text-danger uk-text-small"
                               *ngIf="addBidForm.get('addBidTags').hasError('maxlength')"
                          >
                            {{ getCharactersLimitError('addBidTags') }}
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="uk-width-1-2">
                      <div class="uk-margin">
                        <label class="uk-form-label" for="addBidVisibility">Visibility</label>
                        <div class="uk-form-controls">
                          <label><input id="addBidVisibility" name="addBidVisibility" formControlName="addBidVisibility"
                                        class="uk-checkbox" type="checkbox"> Public</label>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="uk-modal-footer">
                    <p class="uk-text-right">
                      <button class="uk-button uk-button-default uk-modal-close uk-margin-small-right" type="button"
                              id="addNewBidCancel" (click)="resetForm()">Cancel
                      </button>
                      <button type="submit" [disabled]="!addBidForm.valid" (click)="addBid()"
                              class="uk-button uk-button-primary">Save
                      </button>
                    </p>
                  </div>
                </form>
              </div>
            </div>
            <button class="uk-button uk-button-text uk-margin-right" type="button">Sort <span uk-icon="icon: chevron-down"></span></button>
            <div uk-dropdown="mode: click;">
              <ul class="uk-nav uk-dropdown-nav">
<!--                <li [ngClass]="{'uk-active': activeSort('created') }" (click)="sortBids('created')"><a>Created</a></li>-->
                <li [ngClass]="{'uk-active': activeSort('sequence') }" (click)="sortBids('sequence')"><a>Number</a></li>
                <li [ngClass]="{'uk-active': activeSort('name') }" (click)="sortBids('name')"><a>Title</a></li>
                <li [ngClass]="{'uk-active': activeSort('seller') }" (click)="sortBids('seller')"><a>Seller</a></li>
                <li [ngClass]="{'uk-active': activeSort('type') }" (click)="sortBids('type')"><a>Type</a></li>
                <li [ngClass]="{'uk-active': activeSort('price') }" (click)="sortBids('price')"><a
                >Price</a></li>
                <li [ngClass]="{'uk-active': activeSort('valid') }" (click)="sortBids('valid')"><a
                >Valid</a></li>
              </ul>
            </div>
            <button class="uk-button uk-button-text" type="button">Limit {{ limitQuery ? ': ' + limitQuery : null }} <span uk-icon="icon: chevron-down"></span></button>
            <div uk-dropdown="mode: click;">
              <ul class="uk-nav uk-dropdown-nav">
                <li [ngClass]="{'uk-active': activeLimit(10) }" (click)="setLimit(10)"><a>10</a></li>
                <li [ngClass]="{'uk-active': activeLimit(25) }" (click)="setLimit(25)"><a>25</a></li>
                <li [ngClass]="{'uk-active': activeLimit(50) }" (click)="setLimit(50)"><a>50</a></li>
                <li [ngClass]="{'uk-active': activeLimit(100) }" (click)="setLimit(100)"><a>100</a></li>
                <li [ngClass]="{'uk-active': activeLimit(200) }" (click)="setLimit(200)"><a>200</a></li>
              </ul>
            </div>
          </div>
          <div class="uk-height-1-1">
            <table class="uk-table uk-table-middle uk-table-divider">
              <thead>
              <tr>
                <th class="uk-text-nowrap" [ngClass]="{'active': activeSort('sequence') }"
                    (click)="sortBids('sequence')">Number<span uk-icon="chevron-down"
                                                               [ngClass]="{'inverse': !sortAscending }"></span></th>
                <th class="uk-text-wrap" [ngClass]="{'active': activeSort('name') }" (click)="sortBids('name')">
                  Title<span uk-icon="chevron-down" [ngClass]="{'inverse': !sortAscending }"></span></th>
                <th class="uk-text-nowrap uk-visible@m" [ngClass]="{'active': activeSort('customer') }"
                    (click)="sortBids('customer')">Customer<span uk-icon="chevron-down"
                                                                 [ngClass]="{'inverse': !sortAscending }"></span></th>
                <th class="uk-text-nowrap uk-visible@m" [ngClass]="{'active': activeSort('type') }"
                    (click)="sortBids('type')">Type<span uk-icon="chevron-down"
                                                         [ngClass]="{'inverse': !sortAscending }"></span></th>
                <th class="uk-text-nowrap uk-visible@m" [ngClass]="{'active': activeSort('price') }"
                    (click)="sortBids('price')">Price<span uk-icon="chevron-down"
                                                           [ngClass]="{'inverse': !sortAscending }"></span></th>
                <th class="uk-text-nowrap uk-visible@m" [ngClass]="{'active': activeSort('valid') }"
                    (click)="sortBids('valid')">Valid<span uk-icon="chevron-down"
                                                           [ngClass]="{'inverse': !sortAscending }"></span></th>
                <th class="uk-table-shrink"></th>
              </tr>
              </thead>
              <tbody>
              <tr *ngFor="let bid of bids">
                <!--<td>
                  <img class="uk-preserve-width uk-border-circle" src="/api/image/artbuddy-logo-placeholder.svg" *ngIf="!bid.images.length" width="60">
                  <img class="uk-preserve-width" [src]="'/api/file/' + bid.images[0].fileId" width="60" *ngIf="bid.images.length">
                </td>-->
                <!--[ngStyle]="{'background-image': 'url(/api/file/' + image.fileId + ')'}"-->
                <td>{{ bid.sequence }}</td>
                <td (click)="openBid(bid._id)" class="pointer">{{ bid.name }}</td>
                <td class="uk-visible@m">{{ bid.customer?.name }} {{ bid.customer?.lastName }}</td>
                <td class="uk-visible@m">{{ bid.type }}</td>
                <td class="uk-visible@m">{{ bid.itemsTotalPrice }} <span *ngIf="bid.itemsTotalPrice !== 'Unknown'">{{ account.tradingCurrency }}</span></td>
                <td class="uk-visible@m">{{ bid.valid | date: 'dd.MM.yyyy' }}</td>
                <td class="uk-text-right">
                  <div class="uk-button-group">
                    <button (click)="openBid(bid._id)" class="uk-button uk-button-text uk-margin-right" type="button">Open</button>
                    <div class="uk-inline" class="uk-visible@m">
                      <button class="uk-button uk-button-link" type="button"><span uk-icon="icon: chevron-down"></span></button>
                      <div uk-dropdown="mode: click; boundary: ! .uk-button-group; boundary-align: true;">
                        <ul class="uk-nav uk-dropdown-nav">
                          <li><a (click)="removeBid(bid._id)">Remove</a></li>
                        </ul>
                      </div>
                    </div>
                  </div>
                </td>
              </tr>
              </tbody>
            </table>
          </div>
          <ul class="uk-pagination uk-flex-center uk-flex-middle uk-margin-medium-top" *ngIf="pages.length > 1">
            <li class="uk-margin-small-right" [ngClass]="{'uk-disabled': (currentPage === 1) }">
              <a (click)="setCurrentPage(currentPage-1)">
                <span class="uk-margin-small-right" uk-pagination-previous></span> Previous
              </a>
            </li>
            <li>
              <select class="uk-select" [ngModel]="currentPage" (change)="setCurrentPageString($event.target.value)">
                <option *ngFor="let page of pages; let index = index; first as isFirst; last as isLast"
                        [value]="index+1">{{ index + 1 }}</option>
              </select>
            </li>
            <li class="uk-margin-small-left" [ngClass]="{'uk-disabled': (currentPage === pageCount) }">
              <a (click)="setCurrentPage(currentPage+1)">
                Next <span class="uk-margin-small-left" uk-pagination-next></span>
              </a>
            </li>
          </ul>
        </div>
      </div>
    </div>
  </div>
</div>
<app-advanced-search-modal (setResults)="setArtworkSearchResults($event)"
                           *ngIf="showAdvancedArtworkSearchModalWindow"></app-advanced-search-modal>
