<div class="uk-section uk-section-default uk-padding-remove-vertical">

  <app-sidenav contentType="Estimates" [contentItem]="activeEstimate.name"></app-sidenav>

  <div class="uk-container-expand uk-height-expand">
    <ul uk-tab *ngIf="!isLoading">
      <li class="uk-active"><a href="#estimateinfo"><h4>Basic information</h4></a></li>
      <!--<li><a href="#estimateartworks"><h4>Artworks</h4></a></li>-->
      <!--<li><a href="#estimateartists"><h4>Artists</h4></a></li>-->
    </ul>
    <ul class="uk-switcher uk-card uk-card-body uk-padding-remove-top" *ngIf="!isLoading">
      <div id="estimateinfo" class="uk-padding-small">
        <form appNoSubmitEnter [formGroup]="editEstimateForm" (ngSubmit)="editEstimate()" class="uk-grid-small" uk-grid>
          <input type="hidden" name="editEstimateOwner" name="editEstimateOwner" formControlName="editEstimateOwner" [ngModel]="activeAccount">
          <input type="hidden" name="editEstimateId" name="editEstimateId" formControlName="editEstimateId" [ngModel]="activeEstimate._id">
          <input type="hidden" name="editEstimateOwner" name="editEstimateOwner" formControlName="editEstimateOwner" [ngModel]="activeEstimate.owner">
          <div class="uk-width-1-2@s uk-width-1-3@m">
            <div class="uk-margin">
              <label class="uk-form-label" for="editEstimateName">Name <span class="required">*</span></label>
              <div class="uk-form-controls">
                <input id="editEstimateName" name="editEstimateName" formControlName="editEstimateName" type="text" [ngModel]="activeEstimate.name" placeholder="Name ..." class="uk-input" [ngClass]="{'uk-form-danger': editEstimateForm.get('editEstimateName').touched && editEstimateForm.get('editEstimateName').hasError('required') }">
              </div>
            </div>
          </div>
          <div class="uk-width-1-2@s uk-width-1-3@m">
            <div class="uk-margin">
              <label class="uk-form-label" for="editEstimateDescription">Description</label>
              <div class="uk-form-controls">
                <textarea id="editEstimateDescription" name="editEstimateDescription" formControlName="editEstimateDescription" rows="5" [ngModel]="activeEstimate.description" placeholder="Description ..." class="uk-textarea" [ngClass]="{'uk-form-danger': editEstimateForm.get('editEstimateDescription').touched && editEstimateForm.get('editEstimateDescription').hasError('required') }"></textarea>
              </div>
            </div>
          </div>
          <div class="uk-width-1-2@s uk-width-1-3@m">
            <div class="uk-margin">
              <label class="uk-form-label" for="editEstimateEstimator">Estimator</label>
              <div class="uk-form-controls">
                <select id="editEstimateEstimator" name="editEstimateEstimator" formControlName="editEstimateEstimator" [ngModel]="activeEstimate.estimator" class="uk-select">
                  <option *ngFor="let actor of actors" value="{{ actor._id }}">{{ actor.name }} {{ actor.lastName }}</option>
                </select>
              </div>
            </div>
          </div>
          <div class="uk-width-1-1 uk-margin-medium-top">
            <h3 class="uk-margin-remove-bottom">Related artworks</h3>
            <hr class="uk-margin-small-top">
          </div>
          <div class="uk-width-1-1 uk-text-right">
            <nav class="uk-navbar-container navbar-search-artwork-container" uk-navbar>
              <div class="nav-overlay uk-navbar-right">
                <button class="uk-button uk-button-default uk-margin-small-right" type="button" (click)="openAdvancedSearch()">Add artwork</button>
                <button class="uk-button uk-button-default" type="button" (click)="openSlideshow($event)" [disabled]="!this.activeEstimate.items.length">Slideshow</button>
              </div>
              <!--<div id="nav-search-artwork" class="nav-overlay uk-navbar-container uk-navbar-left uk-flex-1" hidden>
                <div class="uk-navbar-item uk-width-expand">
                  <form id="searchArtwork" [formGroup]="searchArtworkForm" (ngSubmit)="searchArtwork()" class="uk-search uk-search-navbar uk-width-1-1">
                    <input id="searchArtworkTerm" name="searchArtworkTerm" formControlName="searchArtworkTerm" class="uk-search-input" type="search" placeholder="Search artworks..." [ngClass]="{' uk-form-danger': searchArtworkForm.get('searchArtworkTerm').touched && searchArtworkForm.get('searchArtworkTerm').hasError('invalid') }" autocomplete="off" autofocus>
                    <button type="submit" class="uk-button uk-button-primary" hidden>Search</button>
                  </form>
                </div>
                <a class="uk-navbar-toggle" uk-close uk-toggle="target: #estimateartworks .nav-overlay; animation: uk-animation-fade" (click)="emptySearch()"></a>
              </div>-->
            </nav>
          </div>
          <div class="uk-width-1-1">
            <div class="uk-margin">
              <!--<div id="search-artwork-results-container">
                <div class="uk-flex uk-flex-column">
                  <div class="uk-container-expand uk-height-expand">
                    <div class="uk-margin">
                      <div class="uk-width-1-1">
                        <table class="uk-table uk-table-middle uk-table-divider uk-margin-remove-bottom">
                          <thead>
                          <tr>
                            <th class="uk-table-shrink">Select</th>
                            <th class="uk-table-shrink">Image</th>
                            <th class="uk-width-expand">Name</th>
                            <th class="uk-width-expand uk-text-nowrap">Artist</th>
                            <th class="uk-table-shrink uk-text-nowrap">Technique</th>
                          </tr>
                          </thead>
                          <tbody>
                          <tr *ngFor="let artwork of searchArtworkResults">
                            <td><input class="uk-checkbox" type="checkbox" (click)="selectEstimateArtwork(artwork)"></td>
                            <td>
                              <div class="image-max-height uk-preserve-width uk-background-contain uk-background-muted uk-height-small uk-panel uk-flex uk-flex-center uk-flex-middle" width="60" [attr.data-src]="'/api/storagefile/' + activeAccount + '/' + artwork.coverImage + '/medium'" uk-img *ngIf="artwork.coverImage">

                              </div>
                              &lt;!&ndash; Jos ei ole coverImagea, niin sit otetaan eka image &ndash;&gt;
                              &lt;!&ndash;<img class="uk-preserve-width" [src]="'/api/storagefile/' + artwork.images[0].fileId" width="60" *ngIf="!artwork.coverImage && artwork.images && artwork.images.length">&ndash;&gt;
                              <div class="image-max-height uk-preserve-width uk-background-contain uk-background-muted uk-height-small uk-panel uk-flex uk-flex-center uk-flex-middle" width="60" [attr.data-src]="'/api/storagefile/' + activeAccount + '/' + artwork.images[0].fileId + '/medium'" uk-img *ngIf="!artwork.coverImage && artwork.images && artwork.images.length">

                              </div>
                              &lt;!&ndash; Jos ei ole coverImagea tai ekan imagea, ni sit otetaan placeholder &ndash;&gt;
                              &lt;!&ndash;<img class="uk-preserve-width uk-border-circle" src="/api/image/artbuddy-logo-placeholder.svg" *ngIf="!artwork.coverImage && !artwork.images.length" width="60">&ndash;&gt;
                              <div class="image-max-height uk-preserve-width uk-background-contain uk-background-muted uk-height-small uk-panel uk-flex uk-flex-center uk-flex-middle" width="60" [attr.data-src]="'/api/image/artbuddy-logo-placeholder.svg'" uk-img *ngIf="!artwork.coverImage && !artwork.images.length">

                              </div>
                            </td>
                            <td>{{ artwork.name }}</td>
                            <td>{{ artwork.artistName }}</td>
                            <td>{{ artwork.technique }}</td>
                          </tr>
                          </tbody>
                        </table>
                        <hr class="uk-margin-small-top">
                        <div class="uk-text-right">
                          <button type="submit" class="uk-button uk-button-primary" (click)="addArtworksToEstimate()" [disabled]="!selectedArtworks.length">Add artworks</button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>-->
              <p *ngIf="!this.activeEstimate.items.length">No related artworks</p>
              <div class="uk-grid-match uk-child-width-1-2@s uk-child-width-1-3@m uk-child-width-1-4@xl" uk-grid uk-sortable *ngIf="this.activeEstimate.items.length">
                <div class="uk-card" *ngFor="let item of this.activeEstimate.items">
                  <div class="uk-card-header uk-flex uk-flex-middle uk-flex-between uk-padding-remove-horizontal">
                    <div class="uk-padding-remove-left">
                      <h3 class="uk-card-title uk-margin-remove-bottom">{{ item.name }}</h3>
                      <div class="uk-text-meta">
                                  <span *ngIf="item.artistName">{{ item.artistName }}</span>
                                  <span *ngIf="item.created">, {{ item.created }}</span>
                                </div>
                    </div>
                    <!--<div>
                      <div class="uk-text-meta">{{ item.availability }}</div>
                      <div class="uk-text-meta" *ngIf="item.price.price">{{ item.price.price }} {{ item.price.currency }}</div>
                    </div>-->
                  </div>
                  <div class="uk-card-body uk-padding-remove uk-background-muted artwork-image" [attr.data-src]="'/api/storagefile/' + activeAccount + '/' + item.coverImage + '/medium'" uk-img *ngIf="item.coverImage"></div>
                  <div class="uk-card-body uk-padding-remove uk-background-muted artwork-image" [attr.data-src]="'/api/storagefile/' + activeAccount + '/' + item.images[0].fileId + '/medium'" uk-img *ngIf="!item.coverImage && item.images && item.images.length"></div>
                  <div class="uk-card-body uk-padding-remove uk-background-muted artwork-image bg-max-size" [attr.data-src]="'/api/image/artbuddy-logo-placeholder.svg'" uk-img *ngIf="!item.coverImage && !item.images.length"></div>
                  <div class="uk-card-footer uk-flex uk-flex-middle uk-flex-between uk-padding-remove-horizontal">
                    <div>
                      <div class="uk-text-meta" *ngIf="item.width && item.height">{{ item.width }} * {{ item.height }} cm</div>
                      <!--<div class="uk-text-meta">{{ item.technique }}</div>-->
                    </div>
                    <div class="uk-flex uk-flex-column">
                      <a (click)="removeArtworkFromEstimate(item)"  class="uk-button uk-button-text">Remove</a>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="uk-width-1-2@s uk-width-1-3@m">
            <div class="uk-margin">
              <label class="uk-form-label" for="editEstimateCreated">Creation date</label>
              <div class="uk-form-controls">
                <input
                  id="editEstimateCreated"
                  name="editEstimateCreated"
                  formControlName="editEstimateCreated"
                  [ngModel]="activeEstimate.created"
                  type="text"
                  placeholder="Creation date ..."
                  class="uk-input"
                  [ngClass]="{'uk-form-danger': editEstimateForm.get('editEstimateCreated').touched && editEstimateForm.get('editEstimateCreated').hasError('required') }"
                  angular-mydatepicker
                  [options]="myDatePickerOptions"
                  #AngularMyDatePickerEditEstimateCreated="angular-mydatepicker"
                  (click)="AngularMyDatePickerEditEstimateCreated.toggleCalendar()"
                  (dateChanged)="updateDate('editEstimateCreated', $event)"
                />
              </div>
            </div>
          </div>
          <div class="uk-width-1-2@s uk-width-1-3@m">
            <div class="uk-margin">
              <label class="uk-form-label" for="editEstimateBasis">Basis</label>
              <div class="uk-form-controls">
                <ng2-completer id="editEstimateBasis" name="editEstimateBasis" formControlName="editEstimateBasis" [(ngModel)]="activeEstimate.estimateBasis" [datasource]="variables.estimateBasis" [minSearchLength]="0" [textNoResults]="null"></ng2-completer>
              </div>
            </div>
          </div>
          <div class="uk-width-1-2@s uk-width-1-3@m">
            <div class="uk-margin">
              <label class="uk-form-label" for="editEstimateMinValue">Minimum value</label>
              <div class="uk-form-controls">
                <input id="editEstimateMinValue" name="editEstimateMinValue" formControlName="editEstimateMinValue" type="text" [ngModel]="activeEstimate.minValue" placeholder="Minimum value ..." class="uk-input" [ngClass]="{'uk-form-danger': editEstimateForm.get('editEstimateMinValue').touched && editEstimateForm.get('editEstimateMinValue').hasError('required') }">
              </div>
            </div>
          </div>
          <div class="uk-width-1-2@s uk-width-1-3@m">
            <div class="uk-margin">
              <label class="uk-form-label" for="editEstimateMaxValue">Maximum value</label>
              <div class="uk-form-controls">
                <input id="editEstimateMaxValue" name="editEstimateMaxValue" formControlName="editEstimateMaxValue" type="text" [ngModel]="activeEstimate.maxValue" placeholder="Maximum value ..." class="uk-input" [ngClass]="{'uk-form-danger': editEstimateForm.get('editEstimateMaxValue').touched && editEstimateForm.get('editEstimateMaxValue').hasError('required') }">
              </div>
            </div>
          </div>
          <div class="uk-width-1-2@s uk-width-1-3@m">
            <div class="uk-margin">
              <label class="uk-form-label" for="editEstimateInsuranceValue">Insurance value</label>
              <div class="uk-form-controls">
                <input id="editEstimateInsuranceValue" name="editEstimateInsuranceValue" formControlName="editEstimateInsuranceValue" type="text" [ngModel]="activeEstimate.insuranceValue" placeholder="Insurance value ..." class="uk-input" [ngClass]="{'uk-form-danger': editEstimateForm.get('editEstimateInsuranceValue').touched && editEstimateForm.get('editEstimateInsuranceValue').hasError('required') }">
              </div>
            </div>
          </div>
          <div class="uk-width-1-2@s uk-width-1-3@m">
            <div class="uk-margin">
              <label class="uk-form-label" for="editEstimateCurrency">Currency</label>
              <div class="uk-form-controls">
                <input id="editEstimateCurrency" name="editEstimateCurrency" formControlName="editEstimateCurrency" type="text" [ngModel]="activeEstimate.currency" placeholder="Currency ..." class="uk-input" [ngClass]="{'uk-form-danger': editEstimateForm.get('editEstimateCurrency').touched && editEstimateForm.get('editEstimateCurrency').hasError('required') }">
              </div>
            </div>
          </div>
          <div class="uk-width-1-1 uk-margin-medium-top">
            <h3 class="uk-margin-remove-bottom">Notes on estimate</h3>
            <hr class="uk-margin-small-top">
          </div>
          <div class="uk-width-1-2@s uk-width-1-3@m">
            <div class="uk-margin">
              <label class="uk-form-label" for="editEstimateShowNotes">Notes</label>
              <div class="uk-form-controls">
                <textarea id="editEstimateShowNotes" name="editEstimateShowNotes" formControlName="editEstimateShowNotes" rows="5" [ngModel]="activeEstimate.showNotes" type="text" placeholder="Notes shown on estimate ..." class="uk-textarea" [ngClass]="{'uk-form-danger': editEstimateForm.get('editEstimateShowNotes').touched && editEstimateForm.get('editEstimateShowNotes').hasError('required') }"></textarea>
              </div>
            </div>
          </div>
          <div class="uk-width-1-1 uk-margin-medium-top">
            <h3 class="uk-margin-remove-bottom">Additional information</h3>
            <hr class="uk-margin-small-top">
          </div>
          <div class="uk-width-1-1@s">
            <div class="uk-margin">
              <label class="uk-form-label">Attachments</label>
              <div class="uk-form-controls uk-margin-small">
                <dl class="uk-description-list" *ngIf="activeEstimate.attachments.length">
                  <div class="uk-flex uk-flex-middle" *ngFor="let attachment of activeEstimate.attachments">
                    <div class="uk-padding-small">
                      <img class="contenttype" width="40" height="40" src="/api/storagefile/{{ activeAccount }}/{{ attachment.fileId }}/small" *ngIf="['image/png', 'image/jpg', 'image/jpeg', 'image/heic', 'image/gif', 'image/svg+xml'].includes(attachment.contentType)" />
                      <img class="contenttype" width="40" height="40" src="/api/file/5a5de6b1229e86403d67db11" *ngIf="['application/msword', 'application/vnd.openxmlformats-officedocument.wordprocessingml.document'].includes(attachment.contentType)" />
                      <img class="contenttype" width="40" height="40" src="/api/file/5a5de6b1229e86403d67db10" *ngIf="['application/octet-stream', 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'].includes(attachment.contentType)" />
                      <img class="contenttype" width="40" height="40" src="/api/file/5a5de6b1229e86403d67db12" *ngIf="['application/pdf'].includes(attachment.contentType)" />
                      <img class="contenttype" width="40" height="40" src="/api/file/5a5de7bc229e86403d67db1b" *ngIf="['text/plain'].includes(attachment.contentType)" />
                      <img class="contenttype" width="40" height="40" src="/api/file/5a5de6b1229e86403d67db0f" *ngIf="!(['image/png', 'image/jpg', 'image/jpeg', 'image/heic', 'image/gif', 'image/svg+xml', 'application/msword', 'application/vnd.openxmlformats-officedocument.wordprocessingml.document', 'application/octet-stream', 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet', 'application/pdf', 'text/plain'].includes(attachment.contentType))" />
                    </div>
                    <div class="uk-width-expand">{{ attachment.name }}</div>
                    <div class="uk-button-group">
                      <button (click)="openAttachment(attachment)" class="uk-button uk-button-default" type="button">Open</button>
                      <div class="uk-inline uk-text-right">
                        <button class="uk-button uk-button-default" type="button"><span uk-icon="icon:  chevron-down"></span></button>
                        <div uk-dropdown="mode: click; boundary: ! .uk-button-group; boundary-align: true;">
                          <ul class="uk-nav uk-dropdown-nav">
                            <li><a (click)="removeAttachment(attachment)">Remove</a></li>
                          </ul>
                        </div>
                      </div>
                    </div>
                  </div>
                </dl>
                <p *ngIf="!activeEstimate.attachments.length">No related attachments</p>
              </div>
            </div>
          </div>
          <div class="uk-width-1-1@s">
            <div class="uk-margin">
              <label class="uk-form-label">Add new attachments</label>
              <div class="uk-form-controls">
                <div class="drop-container" ngFileDrop [options]="options" (uploadOutput)="onAttachmentsUploadOutput($event)" (click)="attachmentFile.click()" [uploadInput]="uploadInput">
                  <div class="js-upload uk-placeholder uk-text-center" [ngClass]="{ 'is-drop-over': dragOver }">
                    <span class="uk-text-middle">Add files by dropping them here or open file dialog by clicking here.<br>Maximum allowed file size is 5 Mb.</span>
                  </div>
                  <input #attachmentFile type="file" ngFileSelect [options]="{ concurrency: 1, maxFileSize: 5000000 }" [uploadInput]="uploadInput" (uploadOutput)="onAttachmentsUploadOutput($event)" multiple class="file-input">
                </div>
              </div>
<!--              <progress id="js-progressbar" class="uk-progress" value="0" max="100" hidden></progress>-->
<!--              <div class="table-container" *ngIf="filesToUpload.length">-->
<!--                <table class="uk-table uk-table-middle uk-margin-top">-->
<!--                  <caption>Files to upload</caption>-->
<!--                  <thead>-->
<!--                  <tr>-->
<!--                    <th class="uk-table-shrink uk-text-capitalize">Image</th>-->
<!--                    <th class="uk-width-expand uk-text-capitalize">Name</th>-->
<!--                    <th class="uk-width-expand uk-text-capitalize">Size</th>-->
<!--                    <th class="uk-table-shrink uk-text-capitalize"></th>-->
<!--                  </tr>-->
<!--                  </thead>-->
<!--                  <tbody>-->
<!--                  <tr *ngFor="let file of filesToUpload; let i = index;">-->
<!--                    <td class="uk-background-muted"><img class="uk-preserve-width uk-border-circle" src="/api/image/artbuddy-logo-placeholder.svg" width="60" uk-img></td>-->
<!--                    <td>{{ file.name }}</td>-->
<!--                    <td class="uk-text-nowrap">{{ file.size | bytes: 2 }}</td>-->
<!--                    <td><button (click)="removeFile(file.id)" class="uk-button uk-button-default" type="button">Remove</button></td>-->
<!--                  </tr>-->
<!--                  </tbody>-->
<!--                </table>-->
<!--                <button type="button" (click)="startUpload()" class="uk-button uk-button-primary">Start upload</button>-->
<!--              </div>-->
            </div>
          </div>
          <div class="uk-width-1-2@s uk-width-1-3@m">
            <div class="uk-margin">
              <label class="uk-form-label" for="editEstimateNotes">Other information</label>
              <div class="uk-form-controls">
                <textarea id="editEstimateNotes" name="editEstimateNotes" formControlName="editEstimateNotes" rows="5" [ngModel]="activeEstimate.notes" type="text" placeholder="Other information ..." class="uk-textarea" [ngClass]="{'uk-form-danger': editEstimateForm.get('editEstimateNotes').touched && editEstimateForm.get('editEstimateNotes').hasError('required') }"></textarea>
              </div>
            </div>
          </div>
          <div class="uk-width-1-2@s uk-width-1-3@m">
            <div class="uk-margin">
              <label class="uk-form-label" for="editEstimateComments">Comments</label>
              <div class="uk-form-controls">
                <textarea id="editEstimateComments" name="editEstimateComments" formControlName="editEstimateComments" rows="5" [ngModel]="activeEstimate.comments" type="text" placeholder="Comments ..." class="uk-textarea" [ngClass]="{'uk-form-danger': editEstimateForm.get('editEstimateComments').touched && editEstimateForm.get('editEstimateComments').hasError('required') }"></textarea>
              </div>
            </div>
          </div>
          <div class="uk-width-1-2@s uk-width-1-3@m">
            <div class="uk-margin">
              <label class="uk-form-label" for="editEstimateTags">Tags</label>
              <div class="uk-form-controls">
                <input id="editEstimateTags" name="editEstimateTags" formControlName="editEstimateTags" [ngModel]="activeEstimate.tags" type="text" placeholder="Tags ..." class="uk-input" [ngClass]="{'uk-form-danger': editEstimateForm.get('editEstimateTags').touched && editEstimateForm.get('editEstimateTags').hasError('required') }">
              </div>
            </div>
          </div>
          <div class="uk-width-1-2@s uk-width-1-3@m">
            <div class="uk-margin">
              <label class="uk-form-label" for="editEstimateVisibility">Visibility</label>
              <div class="uk-form-controls">
                <label><input id="editEstimateVisibility" name="editEstimateVisibility" formControlName="editEstimateVisibility" [ngModel]="activeEstimate.visibility" class="uk-checkbox" type="checkbox"> Public</label>
              </div>
            </div>
          </div>
          <div class="uk-width-1-1 uk-margin-large-top"></div>
          <div class="uk-width-1-1 uk-margin-remove sticky-bottom uk-background-default" uk-sticky="bottom: true">
            <div class="uk-width-1-1">
              <div class="uk-margin">
                <div class="uk-text-right">
                  <button type="button" class="uk-button uk-button-default uk-margin-small-right" [disabled]="!editEstimateForm.valid || !activeEstimate.items.length" (click)="getEstimatePDF()">Open PDF</button>
                  <button type="submit" class="uk-button uk-button-primary" [disabled]="!editEstimateForm.valid || uploadLoading">Save</button>
                </div>
              </div>
            </div>
          </div>
        </form>
      </div>
      <!--<div id="estimateartworks" class="uk-padding-small uk-padding-remove-top">
        <nav class="uk-navbar-container navbar-search-artwork-container" uk-navbar>
          <div class="nav-overlay uk-navbar-right">
            <button class="uk-button uk-button-default uk-margin-small-right" type="button" uk-toggle="target: #estimateartworks .nav-overlay; animation: uk-animation-fade">Add artwork</button>
            <button class="uk-button uk-button-default" type="button" (click)="openSlideshow($event)" [disabled]="!this.activeEstimate.items.length">Slideshow</button>
          </div>
          <div id="nav-search-artwork" class="nav-overlay uk-navbar-container uk-navbar-left uk-flex-1" hidden>
            <div class="uk-navbar-item uk-width-expand">
              <form id="searchArtwork" [formGroup]="searchArtworkForm" (ngSubmit)="searchArtwork()" class="uk-search uk-search-navbar uk-width-1-1">
                <input id="searchArtworkTerm" name="searchArtworkTerm" formControlName="searchArtworkTerm" class="uk-search-input" type="search" placeholder="Search artworks..." [ngClass]="{' uk-form-danger': searchArtworkForm.get('searchArtworkTerm').touched && searchArtworkForm.get('searchArtworkTerm').hasError('invalid') }" autocomplete="off" autofocus>
                <button type="submit" class="uk-button uk-button-primary" hidden>Search</button>
              </form>
            </div>
            <a class="uk-navbar-toggle" uk-close uk-toggle="target: #estimateartworks .nav-overlay; animation: uk-animation-fade" (click)="emptySearch()"></a>
          </div>
        </nav>
        <div id="search-artwork-results-container">
          <div class="uk-flex uk-flex-column">
            &lt;!&ndash;<div class="uk-container-expand uk-height-expand uk-padding-small uk-padding-remove-left uk-padding-remove-right" *ngIf="!searchArtworkResults.length">
              <ul class="search-results uk-flex-center" uk-tab>
                <h3>No search results</h3>
              </ul>
            </div>&ndash;&gt;
            <div class="uk-container-expand uk-height-expand">
              <div class="uk-margin">
                <div class="uk-width-1-1">
                  <table class="uk-table uk-table-middle uk-table-divider uk-margin-remove-bottom">
                    <thead>
                      <tr>
                        <th class="uk-table-shrink">Select</th>
                        <th class="uk-table-shrink">Image</th>
                        <th class="uk-width-expand">Name</th>
                        <th class="uk-width-expand uk-text-nowrap">Artist</th>
                        <th class="uk-table-shrink uk-text-nowrap">Technique</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr *ngFor="let artwork of searchArtworkResults">
                        <td><input class="uk-checkbox" type="checkbox" (click)="selectEstimateArtwork(artwork)"></td>
                        <td>
                          <div class="image-max-height uk-preserve-width uk-background-contain uk-background-muted uk-height-small uk-panel uk-flex uk-flex-center uk-flex-middle" width="60" [attr.data-src]="'/api/storagefile/' + artwork.coverImage" uk-img *ngIf="artwork.coverImage">

                          </div>
                          &lt;!&ndash; Jos ei ole coverImagea, niin sit otetaan eka image &ndash;&gt;
                          &lt;!&ndash;<img class="uk-preserve-width" [src]="'/api/storagefile/' + artwork.images[0].fileId" width="60" *ngIf="!artwork.coverImage && artwork.images && artwork.images.length">&ndash;&gt;
                          <div class="image-max-height uk-preserve-width uk-background-contain uk-background-muted uk-height-small uk-panel uk-flex uk-flex-center uk-flex-middle" width="60" [attr.data-src]="'/api/storagefile/' + artwork.images[0].fileId" uk-img *ngIf="!artwork.coverImage && artwork.images && artwork.images.length">

                          </div>
                          &lt;!&ndash; Jos ei ole coverImagea tai ekan imagea, ni sit otetaan placeholder &ndash;&gt;
                          &lt;!&ndash;<img class="uk-preserve-width uk-border-circle" src="/api/image/artbuddy-logo-placeholder.svg" *ngIf="!artwork.coverImage && !artwork.images.length" width="60">&ndash;&gt;
                          <div class="image-max-height uk-preserve-width uk-background-contain uk-background-muted uk-height-small uk-panel uk-flex uk-flex-center uk-flex-middle" width="60" [attr.data-src]="'/api/image/artbuddy-logo-placeholder.svg'" uk-img *ngIf="!artwork.coverImage && !artwork.images.length">

                          </div>
                        </td>
                        <td>{{ artwork.name }}</td>
                        <td>{{ artwork.artistName }}</td>
                        <td>{{ artwork.technique }}</td>
                      </tr>
                    </tbody>
                  </table>
                  <hr class="uk-margin-small-top">
                  <div class="uk-text-right">
                    <button type="submit" class="uk-button uk-button-primary" (click)="addArtworksToEstimate()" [disabled]="!selectedArtworks.length">Add artworks</button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="uk-grid-match uk-child-width-1-2@s uk-child-width-1-3@m uk-child-width-1-4@xl" uk-grid uk-sortable>
          <div class="uk-card" *ngFor="let item of this.activeEstimate.items">
            <div class="uk-card-header uk-flex uk-flex-middle uk-flex-between uk-padding-remove-horizontal">
              <div class="uk-padding-remove-left">
                <h3 class="uk-card-title uk-margin-remove-bottom">{{ item.name }}</h3>
                <div class="uk-text-meta">
                                  <span *ngIf="item.artistName">{{ item.artistName }}</span>
                                  <span *ngIf="item.created">, {{ item.created }}</span>
                                </div>
              </div>
              <div>
                <div class="uk-text-meta">{{ item.availability }}</div>
                <div class="uk-text-meta" *ngIf="item.price.price">{{ item.price.price }} {{ item.price.currency }}</div>
              </div>
            </div>
            <div class="uk-card-body uk-padding-remove uk-background-muted artwork-image" [ngStyle]="{'background-image': 'url(/api/storagefile/' + activeAccount + '/' + item.coverImage + ')'}" *ngIf="item.coverImage"></div>
            <div class="uk-card-body uk-padding-remove uk-background-muted artwork-image" [ngStyle]="{'background-image': 'url(/api/storagefile/' + activeAccount + '/' + item.images[0].fileId + ')'}" *ngIf="!item.coverImage && item.images && item.images.length"></div>
            <div class="uk-card-body uk-padding-remove uk-background-muted artwork-image bg-max-size" [attr.data-src]="'/api/image/artbuddy-logo-placeholder.svg'" uk-img *ngIf="!item.coverImage && !item.images.length"></div>
            <div class="uk-card-footer uk-flex uk-flex-middle uk-flex-between uk-padding-remove-horizontal">
              <div>
                <div class="uk-text-meta" *ngIf="item.width && item.height">{{ item.width }} * {{ item.height }} cm</div>
                &lt;!&ndash;<div class="uk-text-meta">{{ item.technique }}</div>&ndash;&gt;
              </div>
              <div class="uk-flex uk-flex-column">
                <a (click)="removeArtworkFromEstimate(item)"  class="uk-button uk-button-text">Remove</a>
              </div>
            </div>
          </div>
        </div>
      </div>-->
      <div id="estimateartists" class="uk-padding-small">
        <table class="uk-table uk-table-middle uk-table-divider">
          <thead>
          <tr>
            <th class="uk-table-shrink"></th>
            <th class="uk-table-shrink">Image</th>
            <th class="uk-width-expand">Name</th>
            <th class="uk-width-expand uk-text-nowrap">Multiplier</th>
            <th class="uk-table-shrink uk-text-nowrap">Status</th>
            <th class="uk-table-shrink"></th>
          </tr>
          </thead>
          <tbody>
          <tr *ngFor="let artist of artists">
            <!--<td><input class="uk-checkbox" type="checkbox"></td>-->
            <td><img class="uk-preserve-width uk-border-circle"
                     src="/api/image/artbuddy-logo-placeholder.svg" uk-img
                     width="60"></td>
            <td>{{ artist.name }} {{ artist.lastName }}</td>
            <td>{{ artist.priceMultiplier }}</td>
            <td>{{ artist.status }}</td>
            <td>
              <button (click)="openArtist(artist._id)" class="uk-button uk-button-default" type="button">Open</button>
            </td>
          </tr>
          </tbody>
        </table>
      </div>
    </ul>
  </div>
</div>
<app-advanced-search-modal (setResults)="setArtworkSearchResults($event)" *ngIf="showAdvancedArtworkSearchModalWindow"></app-advanced-search-modal>
