<div class="uk-section uk-section-default uk-padding-remove-vertical">

  <app-sidenav contentType="Trades" [contentItem]="activeTrade.name"></app-sidenav>

  <div class="uk-container-expand uk-height-expand">
    <ul uk-tab *ngIf="!isLoading">
      <li class="uk-active"><a href="#tradeinfo"><h4>Basic information</h4></a></li>
      <!--<li><a href="#tradeartworks"><h4>Artworks</h4></a></li>-->
      <!--<li><a href="#tradeartists"><h4>Artists</h4></a></li>-->
    </ul>
    <ul class="uk-switcher uk-card uk-card-body uk-padding-remove-top" *ngIf="!isLoading">
      <div id="tradeinfo" class="uk-padding-small">
        <form appNoSubmitEnter [formGroup]="editTradeForm" (ngSubmit)="editTrade()" class="uk-grid-small" uk-grid>
          <input type="hidden" name="editTradeOwner" name="editTradeOwner" formControlName="editTradeOwner"
                 [ngModel]="activeAccount">
          <input type="hidden" name="editTradeId" name="editTradeId" formControlName="editTradeId"
                 [ngModel]="activeTrade._id">
          <input type="hidden" name="editTradeOwner" name="editTradeOwner" formControlName="editTradeOwner"
                 [ngModel]="activeTrade.owner">
          <div class="uk-width-1-2@s uk-width-1-3@m">
            <div class="uk-margin">
              <label class="uk-form-label" for="editTradeName">Name <span class="required">*</span></label>
              <div class="uk-form-controls">
                <input id="editTradeName" name="editTradeName" formControlName="editTradeName" type="text"
                       [ngModel]="activeTrade.name" placeholder="Name ..." class="uk-input"
                       [ngClass]="{'uk-form-danger': editTradeForm.get('editTradeName').touched && editTradeForm.get('editTradeName').errors }">
                <div class="uk-text-danger uk-text-small"
                     *ngIf="editTradeForm.get('editTradeName').hasError('maxlength')"
                >
                  {{ getCharactersLimitError('editTradeName') }}
                </div>
                <div class="uk-text-danger uk-text-small"
                     *ngIf="editTradeForm.get('editTradeName').hasError('pattern')"
                >
                  Field value is incorrect
                </div>
              </div>
            </div>
          </div>
          <div class="uk-width-1-2@s uk-width-1-3@m">
            <div class="uk-margin">
              <div class="uk-flex uk-flex-between uk-text-meta">
                <label class="uk-form-label" for="editTradeDescription">Description</label>
                <div class="uk-text-small">
                  {{ getCharactersLeft('editTradeDescription', 2048) }}
                </div>
              </div>
              <div class="uk-form-controls">
                <textarea id="editTradeDescription" name="editTradeDescription" formControlName="editTradeDescription"
                          rows="5" [ngModel]="activeTrade.description" placeholder="Description ..." class="uk-textarea"
                          [ngClass]="{'uk-form-danger': editTradeForm.get('editTradeDescription').touched && editTradeForm.get('editTradeDescription').errors }"></textarea>
                <div class="uk-text-danger uk-text-small"
                     *ngIf="editTradeForm.get('editTradeDescription').hasError('maxlength')"
                >
                  {{ getCharactersLimitError('editTradeDescription') }}
                </div>
              </div>
            </div>
          </div>
          <div class="uk-width-1-2@s uk-width-1-3@m">
            <div class="uk-margin">
              <label class="uk-form-label" for="editTradeSeller">Seller <span class="required">*</span></label>
              <div class="uk-form-controls">
                <!--                <select id="editTradeSeller" name="editTradeSeller" formControlName="editTradeSeller" [ngModel]="activeTrade.seller" class="uk-select">-->
                <!--                  <option *ngFor="let actor of actors" [ngValue]="actor._id" >{{ actor.name }} {{ actor.lastName }}</option>-->
                <!--                </select>-->
                <ng-select [items]="actors"
                           [virtualScroll]="true"
                           [addTag]="false"
                           [selectOnTab]="false"
                           addTagText="Add new: "
                           placeholder="Please select ..."
                           bindLabel="fullName"
                           id="editTradeSeller"
                           formControlName="editTradeSeller"
                           class="uk-select"
                           [(ngModel)]="activeTrade.seller.fullName"
                           #editTradeSeller (change)="editTradeSeller.blur()">
                </ng-select>
                <div class="uk-text-danger uk-text-small"
                     *ngIf="editTradeForm.get('editTradeSeller').hasError('maxlength')"
                >
                  {{ getCharactersLimitError('editTradeSeller') }}
                </div>
              </div>
            </div>
          </div>
          <div class="uk-width-1-2@s uk-width-1-3@m" [hidden]="activeTrade.type === 'Direct'">
            <div class="uk-margin">
              <label class="uk-form-label" for="editTradeAgent">Agent <span class="required">*</span></label>
              <div class="uk-form-controls">
                <!--                <select id="editTradeAgent" name="editTradeAgent" formControlName="editTradeAgent" [ngModel]="activeTrade.agent" class="uk-select">-->
                <!--                  <option *ngFor="let actor of actors" [ngValue]="actor._id" >{{ actor.name }} {{ actor.lastName }}</option>-->
                <!--                </select>-->
                <ng-select [items]="actors"
                           [virtualScroll]="true"
                           [addTag]="false"
                           [selectOnTab]="false"
                           addTagText="Add new: "
                           placeholder="Please select ..."
                           bindLabel="fullName"
                           id="editTradeAgent"
                           formControlName="editTradeAgent"
                           class="uk-select"
                           [(ngModel)]="activeTrade.agent.fullName"
                           #editTradeAgent (change)="editTradeAgent.blur()">
                </ng-select>
                <div class="uk-text-danger uk-text-small"
                     *ngIf="editTradeForm.get('editTradeAgent').hasError('maxlength')"
                >
                  {{ getCharactersLimitError('editTradeAgent') }}
                </div>
              </div>
            </div>
          </div>
          <div class="uk-width-1-2@s uk-width-1-3@m">
            <div class="uk-margin">
              <label class="uk-form-label" for="editTradeBuyer">Buyer <span class="required">*</span></label>
              <div class="uk-form-controls">
                <!--                <select id="editTradeBuyer" name="editTradeBuyer" formControlName="editTradeBuyer" [ngModel]="activeTrade.buyer" class="uk-select">-->
                <!--                  <option *ngFor="let actor of actors" [ngValue]="actor._id" >{{ actor.name }} {{ actor.lastName }}</option>-->
                <!--                </select>-->
                <ng-select [items]="actors"
                           [virtualScroll]="true"
                           [addTag]="false"
                           [selectOnTab]="false"
                           addTagText="Add new: "
                           placeholder="Please select ..."
                           bindLabel="fullName"
                           id="editTradeBuyer"
                           formControlName="editTradeBuyer"
                           class="uk-select"
                           [(ngModel)]="activeTrade.buyer.fullName"
                           #editTradeBuyer (change)="editTradeBuyer.blur()">
                </ng-select>
                <div class="uk-text-danger uk-text-small"
                     *ngIf="editTradeForm.get('editTradeBuyer').hasError('maxlength')"
                >
                  {{ getCharactersLimitError('editTradeBuyer') }}
                </div>
              </div>
            </div>
          </div>
          <div class="uk-width-1-1 uk-margin-medium-top">
            <h3 class="uk-margin-remove-bottom">Related artworks</h3>
            <hr class="uk-margin-small-top">
          </div>
          <div class="uk-width-1-1 uk-text-right">
            <nav class="uk-navbar-container navbar-search-artwork-container" uk-navbar>
              <div class="nav-overlay uk-navbar-right">
                <button class="uk-button uk-button-default uk-margin-small-right" type="button"
                        (click)="openAdvancedSearch()">Add artwork
                </button>
                <button class="uk-button uk-button-default" type="button" (click)="openSlideshow($event)"
                        [disabled]="!this.activeTrade.items.length">Slideshow
                </button>
              </div>
              <!--<div id="nav-search-artwork" class="nav-overlay uk-navbar-container uk-navbar-left uk-flex-1" hidden>
                <div class="uk-navbar-item uk-width-expand">
                  <form id="searchArtwork" [formGroup]="searchArtworkForm" (ngSubmit)="searchArtwork()" class="uk-search uk-search-navbar uk-width-1-1">
                    <input id="searchArtworkTerm" name="searchArtworkTerm" formControlName="searchArtworkTerm" class="uk-search-input" type="search" placeholder="Search artworks..." [ngClass]="{' uk-form-danger': searchArtworkForm.get('searchArtworkTerm').touched && searchArtworkForm.get('searchArtworkTerm').hasError('invalid') }" autocomplete="off" autofocus>
                    <button type="submit" class="uk-button uk-button-primary" hidden>Search</button>
                  </form>
                </div>
                <a class="uk-navbar-toggle" uk-close uk-toggle="target: #tradeartworks .nav-overlay; animation: uk-animation-fade" (click)="emptySearch()"></a>
              </div>-->
            </nav>
          </div>
          <div class="uk-width-1-1">
            <div class="uk-margin">
              <!--<div id="search-artwork-results-container">
                <div class="uk-flex uk-flex-column">
                  <div class="uk-container-expand uk-height-expand">
                    <div class="uk-margin">
                      <div class="uk-width-1-1">
                        <table class="uk-table uk-table-middle uk-table-divider uk-margin-remove-bottom">
                          <thead>
                          <tr>
                            <th class="uk-table-shrink">Select</th>
                            <th class="uk-table-shrink">Image</th>
                            <th class="uk-width-expand">Name</th>
                            <th class="uk-width-expand uk-text-nowrap">Artist</th>
                            <th class="uk-table-shrink uk-text-nowrap">Technique</th>
                          </tr>
                          </thead>
                          <tbody>
                          <tr *ngFor="let artwork of searchArtworkResults">
                            <td><input class="uk-checkbox" type="checkbox" (click)="selectTradeArtwork(artwork)"></td>
                            <td>
                              <div class="image-max-height uk-preserve-width uk-background-contain uk-background-muted uk-height-small uk-panel uk-flex uk-flex-center uk-flex-middle" width="60" [attr.data-src]="'/api/storagefile/' + activeAccount + '/' + artwork.coverImage + '/small'" uk-img *ngIf="artwork.coverImage">

                              </div>
                              &lt;!&ndash; Jos ei ole coverImagea, niin sit otetaan eka image &ndash;&gt;
                              &lt;!&ndash;<img class="uk-preserve-width" [src]="'/api/storagefile/' + artwork.images[0].fileId" width="60" *ngIf="!artwork.coverImage && artwork.images && artwork.images.length">&ndash;&gt;
                              <div class="image-max-height uk-preserve-width uk-background-contain uk-background-muted uk-height-small uk-panel uk-flex uk-flex-center uk-flex-middle" width="60" [attr.data-src]="'/api/storagefile/' + activeAccount + '/' + artwork.images[0].fileId + '/small'" uk-img *ngIf="!artwork.coverImage && artwork.images && artwork.images.length">

                              </div>
                              &lt;!&ndash; Jos ei ole coverImagea tai ekan imagea, ni sit otetaan placeholder &ndash;&gt;
                              &lt;!&ndash;<img class="uk-preserve-width uk-border-circle" src="/api/image/artbuddy-logo-placeholder.svg" *ngIf="!artwork.coverImage && !artwork.images.length" width="60">&ndash;&gt;
                              <div class="image-max-height uk-preserve-width uk-background-contain uk-background-muted uk-height-small uk-panel uk-flex uk-flex-center uk-flex-middle" width="60" [attr.data-src]="'/api/image/artbuddy-logo-placeholder.svg'" uk-img *ngIf="!artwork.coverImage && !artwork.images.length">

                              </div>
                            </td>
                            <td>{{ artwork.name }}</td>
                            <td>{{ artwork.artistName }}</td>
                            <td>{{ artwork.technique }}</td>
                          </tr>
                          </tbody>
                        </table>
                        <hr class="uk-margin-small-top">
                        <div class="uk-text-right">
                          <button type="submit" class="uk-button uk-button-primary" (click)="addArtworksToTrade()" [disabled]="!selectedArtworks.length">Add artworks</button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>-->
              <p *ngIf="!this.activeTrade.items.length">No related artworks</p>
              <div class="uk-grid-match uk-child-width-1-2@s uk-child-width-1-3@m uk-child-width-1-4@xl" uk-grid
                   uk-sortable *ngIf="activeTrade.items.length">
                <div class="uk-card" *ngFor="let item of activeTrade.items">
                  <div class="uk-card-header uk-flex uk-flex-middle uk-flex-between uk-padding-remove-horizontal">
                    <div class="uk-padding-remove-left">
                      <h3 class="uk-card-title uk-margin-remove-bottom">{{ item.name }}</h3>
                      <div class="uk-text-meta">
                        <span *ngIf="item.artistName">{{ item.artistName }}</span>
                        <span *ngIf="item.created">, {{ item.created }}</span>
                      </div>
                    </div>
                    <!--<div>
                      <div class="uk-text-meta">{{ item.availability }}</div>
                      <div class="uk-text-meta" *ngIf="item.price.price">{{ item.price.price }} {{ item.price.currency }}</div>
                    </div>-->
                  </div>
                  <div
                    class="uk-card-body uk-padding-remove uk-background-muted artwork-image uk-flex uk-flex-center uk-flex-middle"
                    [attr.data-src]="'/api/storagefile/' + activeAccount + '/' + item.coverImage + '/medium'" uk-img
                    *ngIf="item.coverImage">

                  </div>
                  <div
                    class="uk-card-body uk-padding-remove uk-background-muted artwork-image uk-flex uk-flex-center uk-flex-middle"
                    [attr.data-src]="'/api/storagefile/' + activeAccount + '/' + item.images[0].fileId + '/medium'"
                    uk-img *ngIf="!item.coverImage && item.images && item.images.length">

                  </div>
                  <div class="uk-card-body uk-padding-remove uk-background-muted artwork-image bg-max-size"
                       [attr.data-src]="'/api/image/artbuddy-logo-placeholder.svg'" uk-img
                       *ngIf="!item.coverImage && !item.images.length">

                  </div>
                  <div class="uk-card-footer uk-flex uk-flex-middle uk-flex-between uk-padding-remove-horizontal">
                    <div>
                      <div class="uk-text-meta" *ngIf="item.width && item.height">{{ item.width }} * {{ item.height }}
                        cm
                      </div>
                      <!--<div class="uk-text-meta">{{ item.technique }}</div>-->
                    </div>
                    <div class="uk-flex uk-flex-column">
                      <a (click)="removeArtworkFromTrade(item)" class="uk-button uk-button-text">Remove</a>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="uk-width-1-2@s uk-width-1-3@m">
            <div class="uk-margin">
              <label class="uk-form-label" for="editTradeCreated">Creation date</label>
              <div class="uk-form-controls">
                <input
                  id="editTradeCreated"
                  name="editTradeCreated"
                  formControlName="editTradeCreated"
                  [ngModel]="activeTrade.created"
                  type="text"
                  placeholder="Creation date ..."
                  class="uk-input"
                  [ngClass]="{'uk-form-danger': editTradeForm.get('editTradeCreated').touched && editTradeForm.get('editTradeCreated').errors }"
                  angular-mydatepicker
                  [options]="myDatePickerOptions"
                  #AngularMyDatePickerEditTradeCreated="angular-mydatepicker"
                  (click)="AngularMyDatePickerEditTradeCreated.toggleCalendar()"
                  (dateChanged)="updateDate('editTradeCreated', $event)"
                />
                <div class="uk-text-danger uk-text-small"
                     *ngIf="editTradeForm.get('editTradeCreated').hasError('maxlength')"
                >
                  {{ getCharactersLimitError('editTradeCreated') }}
                </div>
              </div>
            </div>
          </div>
          <div class="uk-width-1-2@s uk-width-1-3@m">
            <div class="uk-margin">
              <label class="uk-form-label" for="editTradePrice">Total price</label>
              <div class="uk-form-controls">
                <input id="editTradePrice" name="editTradePrice" formControlName="editTradePrice" type="text"
                       [ngModel]="activeTrade.price" placeholder="Price ..." class="uk-input"
                       [ngClass]="{'uk-form-danger': editTradeForm.get('editTradePrice').touched && editTradeForm.get('editTradePrice').errors }">
                <div class="uk-text-danger uk-text-small"
                     *ngIf="editTradeForm.get('editTradePrice').hasError('maxlength')"
                >
                  {{ getCharactersLimitError('editTradePrice') }}
                </div>
                <div class="uk-text-danger uk-text-small"
                     *ngIf="editTradeForm.get('editTradePrice').hasError('pattern')"
                >
                  Field value is incorrect
                </div>
              </div>
            </div>
          </div>
          <div class="uk-width-1-2@s uk-width-1-3@m">
            <div class="uk-margin">
              <label class="uk-form-label" for="editTradePrice">Discount</label>
              <div class="uk-form-controls">
                <input id="editTradeDiscount" name="editTradeDiscount" formControlName="editTradeDiscount" type="text"
                       [ngModel]="activeTrade.discount" placeholder="Discount ..." class="uk-input"
                       [ngClass]="{'uk-form-danger': editTradeForm.get('editTradeDiscount').touched && editTradeForm.get('editTradeDiscount').errors }">
                <div class="uk-text-danger uk-text-small"
                     *ngIf="editTradeForm.get('editTradeDiscount').hasError('maxlength')"
                >
                  {{ getCharactersLimitError('editTradeDiscount') }}
                </div>
                <div class="uk-text-danger uk-text-small"
                     *ngIf="editTradeForm.get('editTradeDiscount').hasError('pattern')"
                >
                  Field value is incorrect
                </div>
              </div>
            </div>
          </div>
          <div class="uk-width-1-2@s uk-width-1-3@m">
            <div class="uk-margin">
              <label class="uk-form-label" for="editTradeCurrency">Currency</label>
              <div class="uk-form-controls">
                <input id="editTradeCurrency" name="editTradeCurrency" formControlName="editTradeCurrency" type="text"
                       [ngModel]="tradingCurrency" placeholder="Currency ..." class="uk-input"
                       [ngClass]="{'uk-form-danger': editTradeForm.get('editTradeCurrency').touched && editTradeForm.get('editTradeCurrency').errors }">
                <div class="uk-text-danger uk-text-small"
                     *ngIf="editTradeForm.get('editTradeCurrency').hasError('maxlength')"
                >
                  {{ getCharactersLimitError('editTradeCurrency') }}
                </div>
              </div>
            </div>
          </div>
          <div class="uk-width-1-2@s uk-width-1-3@m">
            <div class="uk-margin">
              <label class="uk-form-label" for="editTradeVAT">VAT</label>
              <div class="uk-form-controls">
                <input id="editTradeVAT" name="editTradeVAT" formControlName="editTradeVAT" type="text"
                       [ngModel]="tradingVAT" placeholder="VAT ..." class="uk-input"
                       [ngClass]="{'uk-form-danger': editTradeForm.get('editTradeVAT').touched && editTradeForm.get('editTradeVAT').errors }">
                <div class="uk-text-danger uk-text-small"
                     *ngIf="editTradeForm.get('editTradeVAT').hasError('maxlength')"
                >
                  {{ getCharactersLimitError('editTradeVAT') }}
                </div>
              </div>
            </div>
          </div>
          <div class="uk-width-1-1 uk-margin-medium-top">
            <h3 class="uk-margin-remove-bottom">Notes on trade</h3>
            <hr class="uk-margin-small-top">
          </div>
          <div class="uk-width-1-2@s uk-width-1-3@m">
            <div class="uk-margin">
              <div class="uk-flex uk-flex-between uk-text-meta">
                <label class="uk-form-label" for="editTradeShowNotes">Notes</label>
                <div class="uk-text-small">
                  {{ getCharactersLeft('editTradeShowNotes', 2048) }}
                </div>
              </div>
              <div class="uk-form-controls">
                <textarea id="editTradeShowNotes" name="editTradeShowNotes" formControlName="editTradeShowNotes"
                          rows="5"
                          [ngModel]="activeTrade.showNotes" type="text" placeholder="Notes shown on trade ..."
                          class="uk-textarea"
                          [ngClass]="{'uk-form-danger': editTradeForm.get('editTradeShowNotes').touched && editTradeForm.get('editTradeShowNotes').errors }"></textarea>
                <div class="uk-text-danger uk-text-small"
                     *ngIf="editTradeForm.get('editTradeShowNotes').hasError('maxlength')"
                >
                  {{ getCharactersLimitError('editTradeShowNotes') }}
                </div>
              </div>
            </div>
          </div>
          <div class="uk-width-1-1 uk-margin-medium-top">
            <h3 class="uk-margin-remove-bottom">Additional information</h3>
            <hr class="uk-margin-small-top">
          </div>
          <div class="uk-width-1-1@s">
            <div class="uk-margin">
              <label class="uk-form-label">Attachments</label>
              <div class="uk-form-controls uk-margin-small">
                <dl class="uk-description-list" *ngIf="activeTrade.attachments.length">
                  <div class="uk-flex uk-flex-middle" *ngFor="let attachment of activeTrade.attachments">
                    <div class="uk-padding-small">
                      <img class="contenttype" width="40" height="40"
                           src="/api/storagefile/{{ activeAccount }}/{{ attachment.fileId }}/small"
                           *ngIf="['image/png', 'image/jpg', 'image/jpeg', 'image/heic', 'image/gif', 'image/svg+xml'].includes(attachment.contentType)"/>
                      <img class="contenttype" width="40" height="40" src="/api/file/5a5de6b1229e86403d67db11"
                           *ngIf="['application/msword', 'application/vnd.openxmlformats-officedocument.wordprocessingml.document'].includes(attachment.contentType)"/>
                      <img class="contenttype" width="40" height="40" src="/api/file/5a5de6b1229e86403d67db10"
                           *ngIf="['application/octet-stream', 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'].includes(attachment.contentType)"/>
                      <img class="contenttype" width="40" height="40" src="/api/file/5a5de6b1229e86403d67db12"
                           *ngIf="['application/pdf'].includes(attachment.contentType)"/>
                      <img class="contenttype" width="40" height="40" src="/api/file/5a5de7bc229e86403d67db1b"
                           *ngIf="['text/plain'].includes(attachment.contentType)"/>
                      <img class="contenttype" width="40" height="40" src="/api/file/5a5de6b1229e86403d67db0f"
                           *ngIf="!(['image/png', 'image/jpg', 'image/jpeg', 'image/heic', 'image/gif', 'image/svg+xml', 'application/msword', 'application/vnd.openxmlformats-officedocument.wordprocessingml.document', 'application/octet-stream', 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet', 'application/pdf', 'text/plain'].includes(attachment.contentType))"/>
                    </div>
                    <div class="uk-width-expand">{{ attachment.name }}</div>
                    <div class="uk-button-group">
                      <button (click)="openAttachment(attachment)" class="uk-button uk-button-default" type="button">
                        Open
                      </button>
                      <div class="uk-inline uk-text-right">
                        <button class="uk-button uk-button-default" type="button"><span
                          uk-icon="icon:  chevron-down"></span></button>
                        <div uk-dropdown="mode: click; boundary: ! .uk-button-group; boundary-align: true;">
                          <ul class="uk-nav uk-dropdown-nav">
                            <li><a (click)="removeAttachment(attachment)">Remove</a></li>
                          </ul>
                        </div>
                      </div>
                    </div>
                  </div>
                </dl>
                <p *ngIf="!activeTrade.attachments.length">No related attachments</p>
              </div>
            </div>
          </div>
          <div class="uk-width-1-1@s">
            <div class="uk-margin">
              <label class="uk-form-label">Add new attachments</label>
              <div class="uk-form-controls">
                <div class="drop-container" ngFileDrop [options]="options"
                     (uploadOutput)="onAttachmentsUploadOutput($event)" (click)="attachmentFile.click()"
                     [uploadInput]="uploadInput">
                  <div class="js-upload uk-placeholder uk-text-center" [ngClass]="{ 'is-drop-over': dragOver }">
                    <span
                      class="uk-text-middle">Add files by dropping them here or open file dialog by clicking here.<br>Maximum allowed file size is 5 Mb.</span>
                  </div>
                  <input #attachmentFile type="file" ngFileSelect [options]="{ concurrency: 1, maxFileSize: 5000000 }"
                         [uploadInput]="uploadInput" (uploadOutput)="onAttachmentsUploadOutput($event)" multiple
                         class="file-input">
                </div>
              </div>
              <!--              <progress id="js-progressbar" class="uk-progress" value="0" max="100" hidden></progress>-->
              <!--              <div class="table-container" *ngIf="filesToUpload.length">-->
              <!--                <table class="uk-table uk-table-middle uk-margin-top">-->
              <!--                  <caption>Files to upload</caption>-->
              <!--                  <thead>-->
              <!--                  <tr>-->
              <!--                    <th class="uk-table-shrink uk-text-capitalize">Image</th>-->
              <!--                    <th class="uk-width-expand uk-text-capitalize">Name</th>-->
              <!--                    <th class="uk-width-expand uk-text-capitalize">Size</th>-->
              <!--                    <th class="uk-table-shrink uk-text-capitalize"></th>-->
              <!--                  </tr>-->
              <!--                  </thead>-->
              <!--                  <tbody>-->
              <!--                  <tr *ngFor="let file of filesToUpload; let i = index;">-->
              <!--                    <td class="uk-background-muted"><img class="uk-preserve-width uk-border-circle" src="/api/image/artbuddy-logo-placeholder.svg" width="60" uk-img></td>-->
              <!--                    <td>{{ file.name }}</td>-->
              <!--                    <td class="uk-text-nowrap">{{ file.size | bytes: 2 }}</td>-->
              <!--                    <td><button (click)="removeFile(file.id)" class="uk-button uk-button-default" type="button">Remove</button></td>-->
              <!--                  </tr>-->
              <!--                  </tbody>-->
              <!--                </table>-->
              <!--                <button type="button" (click)="startUpload()" class="uk-button uk-button-primary">Start upload</button>-->
              <!--              </div>-->
            </div>
          </div>
          <div class="uk-width-1-2@s uk-width-1-3@m">
            <div class="uk-margin">
              <div class="uk-flex uk-flex-between uk-text-meta">
                <label class="uk-form-label" for="editTradeNotes">Other information</label>
                <div class="uk-text-small">
                  {{ getCharactersLeft('editTradeNotes', 2048) }}
                </div>
              </div>
              <div class="uk-form-controls">
                <textarea id="editTradeNotes" name="editTradeNotes" formControlName="editTradeNotes" rows="5"
                          [ngModel]="activeTrade.notes" type="text" placeholder="Other information ..."
                          class="uk-textarea"
                          [ngClass]="{'uk-form-danger': editTradeForm.get('editTradeNotes').touched && editTradeForm.get('editTradeNotes').errors }"></textarea>
                <div class="uk-text-danger uk-text-small"
                     *ngIf="editTradeForm.get('editTradeNotes').hasError('maxlength')"
                >
                  {{ getCharactersLimitError('editTradeNotes') }}
                </div>
              </div>
            </div>
          </div>
          <div class="uk-width-1-2@s uk-width-1-3@m">
            <div class="uk-margin">
              <div class="uk-flex uk-flex-between uk-text-meta">
                <label class="uk-form-label" for="editTradeComments">Comments</label>
                <div class="uk-text-small">
                  {{ getCharactersLeft('editTradeComments', 2048) }}
                </div>
              </div>
              <div class="uk-form-controls">
                <textarea id="editTradeComments" name="editTradeComments" formControlName="editTradeComments" rows="5"
                          [ngModel]="activeTrade.comments" type="text" placeholder="Comments ..." class="uk-textarea"
                          [ngClass]="{'uk-form-danger': editTradeForm.get('editTradeComments').touched && editTradeForm.get('editTradeComments').errors }"></textarea>
                <div class="uk-text-danger uk-text-small"
                     *ngIf="editTradeForm.get('editTradeComments').hasError('maxlength')"
                >
                  {{ getCharactersLimitError('editTradeComments') }}
                </div>
              </div>
            </div>
          </div>
          <div class="uk-width-1-2@s uk-width-1-3@m">
            <div class="uk-margin">
              <label class="uk-form-label" for="editTradeTags">Tags</label>
              <div class="uk-form-controls">
                <input id="editTradeTags" name="editTradeTags" formControlName="editTradeTags"
                       [ngModel]="activeTrade.tags" type="text" placeholder="Tags ..." class="uk-input"
                       [ngClass]="{'uk-form-danger': editTradeForm.get('editTradeTags').touched && editTradeForm.get('editTradeTags').errors }">
                <div class="uk-text-danger uk-text-small"
                     *ngIf="editTradeForm.get('editTradeTags').hasError('maxlength')"
                >
                  {{ getCharactersLimitError('editTradeTags') }}
                </div>
              </div>
            </div>
          </div>
          <div class="uk-width-1-2@s uk-width-1-3@m">
            <div class="uk-margin">
              <label class="uk-form-label" for="editTradeVisibility">Visibility</label>
              <div class="uk-form-controls">
                <label><input id="editTradeVisibility" name="editTradeVisibility" formControlName="editTradeVisibility"
                              [ngModel]="activeTrade.visibility" class="uk-checkbox" type="checkbox"> Public</label>
              </div>
            </div>
          </div>
          <div class="uk-width-1-1 uk-margin-medium-top">
            <hr class="uk-margin-small-top">
          </div>
          <div class="uk-width-1-1 uk-margin-remove sticky-bottom uk-background-default" uk-sticky="bottom: true">
            <div class="uk-width-1-1">
              <div class="uk-flex uk-flex-middle uk-flex-between uk-margin">
                <app-editing-history
                  [updatedAt]="activeTrade.updatedAt"
                  [name]="activeTrade.requestUser?.name"
                  [email]="activeTrade.requestUser?.email"
                ></app-editing-history>
                <div class="uk-text-right">
                  <button type="button" class="uk-button uk-button-default uk-margin-small-right"
                          [disabled]="!editTradeForm.valid || !activeTrade.items.length || isLoading" (click)="getTradePDF()">
                    Open PDF
                  </button>
                  <button type="submit" class="uk-button uk-button-primary"
                          [disabled]="!editTradeForm.valid || uploadLoading || isLoading">Save
                  </button>
                </div>
              </div>
            </div>
          </div>
        </form>
      </div>
      <!--<div id="tradeartworks" class="uk-padding-small uk-padding-remove-top">
        <nav class="uk-navbar-container navbar-search-artwork-container" uk-navbar>
          <div class="nav-overlay uk-navbar-right">
            <button class="uk-button uk-button-default uk-margin-small-right" type="button" uk-toggle="target: #tradeartworks .nav-overlay; animation: uk-animation-fade">Add artwork</button>
            <button class="uk-button uk-button-default" type="button" (click)="openSlideshow($event)" [disabled]="!this.activeTrade.items.length">Slideshow</button>
          </div>
          <div id="nav-search-artwork" class="nav-overlay uk-navbar-container uk-navbar-left uk-flex-1" hidden>
            <div class="uk-navbar-item uk-width-expand">
              <form id="searchArtwork" [formGroup]="searchArtworkForm" (ngSubmit)="searchArtwork()" class="uk-search uk-search-navbar uk-width-1-1">
                <input id="searchArtworkTerm" name="searchArtworkTerm" formControlName="searchArtworkTerm" class="uk-search-input" type="search" placeholder="Search artworks..." [ngClass]="{' uk-form-danger': searchArtworkForm.get('searchArtworkTerm').touched && searchArtworkForm.get('searchArtworkTerm').hasError('invalid') }" autocomplete="off" autofocus>
                <button type="submit" class="uk-button uk-button-primary" hidden>Search</button>
              </form>
            </div>
            <a class="uk-navbar-toggle" uk-close uk-toggle="target: #tradeartworks .nav-overlay; animation: uk-animation-fade" (click)="emptySearch()"></a>
          </div>
        </nav>
        <div id="search-artwork-results-container">
          <div class="uk-flex uk-flex-column">
            <div class="uk-container-expand uk-height-expand">
              <div class="uk-margin">
                <div class="uk-width-1-1">
                  <table class="uk-table uk-table-middle uk-table-divider uk-margin-remove-bottom">
                    <thead>
                      <tr>
                        <th class="uk-table-shrink">Select</th>
                        <th class="uk-table-shrink">Image</th>
                        <th class="uk-width-expand">Name</th>
                        <th class="uk-width-expand uk-text-nowrap">Artist</th>
                        <th class="uk-table-shrink uk-text-nowrap">Technique</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr *ngFor="let artwork of searchArtworkResults">
                        <td><input class="uk-checkbox" type="checkbox" (click)="selectTradeArtwork(artwork)"></td>
                        <td>
                          <div class="image-max-height uk-preserve-width uk-background-contain uk-background-muted uk-height-small uk-panel uk-flex uk-flex-center uk-flex-middle" width="60" [attr.data-src]="'/api/storagefile/' + artwork.coverImage" uk-img *ngIf="artwork.coverImage">

                          </div>
                          &lt;!&ndash; Jos ei ole coverImagea, niin sit otetaan eka image &ndash;&gt;
                          &lt;!&ndash;<img class="uk-preserve-width" [src]="'/api/storagefile/' + artwork.images[0].fileId" width="60" *ngIf="!artwork.coverImage && artwork.images && artwork.images.length">&ndash;&gt;
                          <div class="image-max-height uk-preserve-width uk-background-contain uk-background-muted uk-height-small uk-panel uk-flex uk-flex-center uk-flex-middle" width="60" [attr.data-src]="'/api/storagefile/' + artwork.images[0].fileId" uk-img *ngIf="!artwork.coverImage && artwork.images && artwork.images.length">

                          </div>
                          &lt;!&ndash; Jos ei ole coverImagea tai ekan imagea, ni sit otetaan placeholder &ndash;&gt;
                          &lt;!&ndash;<img class="uk-preserve-width uk-border-circle" src="/api/image/artbuddy-logo-placeholder.svg" *ngIf="!artwork.coverImage && !artwork.images.length" width="60">&ndash;&gt;
                          <div class="image-max-height uk-preserve-width uk-background-contain uk-background-muted uk-height-small uk-panel uk-flex uk-flex-center uk-flex-middle" width="60" [attr.data-src]="'/api/image/artbuddy-logo-placeholder.svg'" uk-img *ngIf="!artwork.coverImage && !artwork.images.length">

                          </div>
                        </td>
                        <td>{{ artwork.name }}</td>
                        <td>{{ artwork.artist }}</td>
                        <td>{{ artwork.technique }}</td>
                      </tr>
                    </tbody>
                  </table>
                  <hr class="uk-margin-small-top">
                  <div class="uk-text-right">
                    <button type="submit" class="uk-button uk-button-primary" (click)="addArtworksToTrade()" [disabled]="!selectedArtworks.length">Add artworks</button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <p *ngIf="!this.activeTrade.items.length">No related artwork</p>
        <div class="uk-grid-match uk-child-width-1-2@s uk-child-width-1-3@m uk-child-width-1-4@xl" uk-grid uk-sortable>
          <div class="uk-card" *ngFor="let item of this.activeTrade.items">
            <div class="uk-card-header uk-flex uk-flex-middle uk-flex-between uk-padding-remove-horizontal">
              <div class="uk-padding-remove-left">
                <h3 class="uk-card-title uk-margin-remove-bottom">{{ item.name }}</h3>
                <div class="uk-text-meta">{{ item.artistName }}, {{ item.created ? (item.created | date: 'yyyy') : '' }}</div>
              </div>
              <div>
                <div class="uk-text-meta">{{ item.availability }}</div>
                <div class="uk-text-meta" *ngIf="item.price.price">{{ item.price.price }} {{ item.price.currency }}</div>
              </div>
            </div>
            <div class="uk-card-body uk-padding-remove uk-background-muted artwork-image" [ngStyle]="{'background-image': 'url(/api/storagefile/' + activeAccount + '/' + item.coverImage + ')'}" *ngIf="item.coverImage"></div>
            <div class="uk-card-body uk-padding-remove uk-background-muted artwork-image" [ngStyle]="{'background-image': 'url(/api/storagefile/' + activeAccount + '/' + item.images[0].fileId + ')'}" *ngIf="!item.coverImage && item.images && item.images.length"></div>
            <div class="uk-card-body uk-padding-remove uk-background-muted artwork-image bg-max-size" [attr.data-src]="'/api/image/artbuddy-logo-placeholder.svg'" uk-img *ngIf="!item.coverImage && !item.images.length"></div>
            <div class="uk-card-footer uk-flex uk-flex-middle uk-flex-between uk-padding-remove-horizontal">
              <div>
                <div class="uk-text-meta" *ngIf="item.width && item.height">{{ item.width }} * {{ item.height }} cm</div>
                &lt;!&ndash;<div class="uk-text-meta">{{ item.technique }}</div>&ndash;&gt;
              </div>
              <div class="uk-flex uk-flex-column">
                <a (click)="removeArtworkFromTrade(item)"  class="uk-button uk-button-text">Remove</a>
              </div>
            </div>
          </div>
        </div>
      </div>-->
      <!--<div id="tradeartists" class="uk-padding-small">
        <table class="uk-table uk-table-middle uk-table-divider">
          <thead>
          <tr>
            <th class="uk-table-shrink"></th>
            <th class="uk-table-shrink">Image</th>
            <th class="uk-width-expand">Name</th>
            <th class="uk-width-expand uk-text-nowrap">Multiplier</th>
            <th class="uk-table-shrink uk-text-nowrap">Status</th>
            <th class="uk-table-shrink"></th>
          </tr>
          </thead>
          <tbody>
          <tr *ngFor="let artist of artists">
            &lt;!&ndash;<td><input class="uk-checkbox" type="checkbox"></td>&ndash;&gt;
            <td><img class="uk-preserve-width uk-border-circle"
                     src="/api/image/artbuddy-logo-placeholder.svg"
                     width="60"></td>
            <td>{{ artist.name }}</td>
            <td>{{ artist.priceMultiplier }}</td>
            <td>{{ artist.status }}</td>
            <td>
              <button (click)="openArtist(artist._id)" class="uk-button uk-button-default" type="button">Open</button>
            </td>
          </tr>
          </tbody>
        </table>
      </div>-->
    </ul>
  </div>
</div>
<app-advanced-search-modal (setResults)="setArtworkSearchResults($event)"
                           *ngIf="showAdvancedArtworkSearchModalWindow"></app-advanced-search-modal>
