import {Component, Inject, forwardRef, Input, OnInit, Optional} from '@angular/core';
import { BaseWidget, NgAisInstantSearch } from 'angular-instantsearch';
import { connectRange } from 'instantsearch.js/es/connectors';
import {NgAisIndex} from 'angular-instantsearch';

@Component({
  selector: 'app-ais-range-input-select',
  template: `<div class="uk-margin-small" *ngIf="state.range && !(state.range.min === 0 && state.range.max === 0)" >
    <label class="uk-form-label">{{ title }}</label>
    <div class="uk-form-controls uk-flex">
      <div class="uk-inline uk-width-expand">
        <input class="uk-input" type="number" #min [value]="state.start[0]" min="state.range.min" max="state.range.max" placeholder="From..." (blur)="state.refine([min.value, max.value])" />
        <a class="uk-form-icon uk-form-icon-flip" (click)="state.refine([min.value, max.value])" uk-icon="icon: plus"></a>
      </div>
      <div class="uk-inline uk-width-expand uk-margin-small-left">
        <input class="uk-input" type="number" #max [value]="state.start[1]" min="state.range.min" max="state.range.max" placeholder="To..." (blur)="state.refine([min.value, max.value])" />
        <a class="uk-form-icon uk-form-icon-flip" (click)="state.refine([min.value, max.value])" uk-icon="icon: plus"></a>
      </div>
    </div>
  </div>`
})
export class SearchAisRangeInputComponent extends BaseWidget implements OnInit {
  @Input() title: string;
  @Input() attribute: string;
  @Input() sortName: string;
  @Input() sortDescription: string;

  public state: {
    start: number[];
    range: any;
    refine: Function;
    widgetParams: object;
  };

  constructor(
    @Inject(forwardRef(() => NgAisIndex))
    @Optional()
    public parentIndex: NgAisIndex,
    @Inject(forwardRef(() => NgAisInstantSearch))
    public instantSearchInstance: NgAisInstantSearch
  ) {
    super('RangeInput');
  }

  public ngOnInit() {
    if (this.attribute) {
      // @ts-ignore
      this.createWidget(connectRange,
        {
          attribute: this.attribute,
          sortBy: [this.sortName + ':' + this.sortDescription ],
          autoHideContainer: true,
          /*transformItems: (items) => {
            console.log(items);
            return items;
          }*/
        });
      super.ngOnInit();
    }
  }
}
