import {Component, EventEmitter, OnInit, ViewChild} from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { FormGroup, FormControl, Validators, FormBuilder } from '@angular/forms';
import { AuthService } from '../../services/auth.service';
import { UserService } from '../../services/user.service';
import { ActiveService } from '../../services/active.service';
import { AccountService } from '../../services/account.service';
import { FileService } from '../../services/file.service';
import { SearchService } from '../../services/search.service';
import { EstimateService } from '../../services/estimate.service';
import { ItemService } from '../../services/item.service';
import { UploadOutput, UploadInput, UploadFile, humanizeBytes, UploaderOptions } from 'ngx-uploader';
import {AngularMyDatePickerDirective, IAngularMyDpOptions, IMyDateModel} from 'angular-mydatepicker';
import * as moment from 'moment/moment';

declare var UIkit: any;

@Component({
  selector: 'app-single-estimate',
  templateUrl: './single-estimate.component.html',
  styleUrls: ['./single-estimate.component.scss']
})
export class SingleEstimateComponent implements OnInit {
  public user: any = {};
  public activeEstimate: any = {};
  public activeEstimateId: string = '';
  public actorType: string;
  public activeAccount: any = {};
  private activeUser: string;
  public accounts: Array<Object> = [];
  public artists: Array<Object> = [];
  public users: Array<Object> = [];
  public actors: Array<Object> = [];
  public variables: Array<string> = [];
  public isLoading = true;
  public uploadLoading: Boolean = false;
  public addNewArtworkModalWindow: any;
  public advancedArtworkSearchModalWindow: any;
  public showAdvancedArtworkSearchModalWindow: boolean = false;
  public editEstimateForm: FormGroup;
  public searchArtworkForm: FormGroup;
  public searchArtworkResults: Array<Object> = [];
  public selectedArtworks: Array<Object> = [];
  public searchResultsFound: boolean = false;
  public myDatePickerOptions: IAngularMyDpOptions = {
    dateFormat: 'dd.mm.yyyy'
  };
  private todaysDate: IMyDateModel;
  private validDate: IMyDateModel;
  @ViewChild('AngularMyDatePickerEditEstimateCreated') AngularMyDatePickerEditEstimateCreated: AngularMyDatePickerDirective;
  public options: UploaderOptions;
  public formData: FormData;
  public filesToUpload: UploadFile[];
  public uploadInput: EventEmitter<UploadInput>;
  public humanizeBytes: Function;
  public dragOver: boolean;
  public imageLoadOffset$: any;

  constructor(
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private formBuilder: FormBuilder,
    private auth: AuthService,
    private userService: UserService,
    private activeService: ActiveService,
    private accountService: AccountService,
    private estimateService: EstimateService,
    private fileService: FileService,
    private searchService: SearchService,
    private itemService: ItemService
  ) {
    this.activatedRoute.data.subscribe(data => {
      this.activeEstimate = data.resolvedData;
      if (this.activeEstimate.items) {
        this.activeEstimate.items.map(item => {
          item.created = moment(item.created).locale('fi').format('L');
          /*switch (item.availability) {
            case 0:
              item.availability = 'Sold';
              break;
            case 1:
              item.availability = 'Booked';
              break;
            case 2: default:
            item.availability = 'Available';
            break;
          }*/
          const artistFound = this.artists.find((artist: any) => {
            return artist._id === item.artist;
          });
          if (artistFound === undefined) {
            this.artists.push({
              _id: item.artist,
              name: item.artistName,
              priceMultiplier: item.artistPriceMultiplier,
              status: item.artistStatus
            });
          }
        });
      } else {
        this.activeEstimate.items = [];
      }
      this.activeEstimate.created = this.createDate(this.activeEstimate.created);
      this.activeEstimateId = data.resolvedData._id;
    });

    this.editEstimateForm = this.formBuilder.group({
      editEstimateId: new FormControl('', [
        Validators.required,
        Validators.maxLength(100)
      ]),
      editEstimateOwner: new FormControl('', [
        Validators.required,
        Validators.maxLength(100)
      ]),
      editEstimateName: new FormControl('', [
        Validators.required,
        Validators.maxLength(100)
      ]),
      editEstimateDescription: new FormControl('', [
        Validators.maxLength(2048)
      ]),
      editEstimateEstimator: new FormControl('', [
        Validators.required,
        Validators.maxLength(100)
      ]),
      editEstimateCreated: new FormControl('', [
        Validators.maxLength(100)
      ]),
      editEstimateBasis: new FormControl('', [
        Validators.maxLength(100)
      ]),
      editEstimateCurrency: new FormControl({value: '', disabled: true}, Validators.maxLength(5)),
      editEstimateMinValue: new FormControl('', [
        Validators.maxLength(100)
      ]),
      editEstimateMaxValue: new FormControl('', [
        Validators.maxLength(100)
      ]),
      editEstimateInsuranceValue: new FormControl('', [
        Validators.maxLength(100)
      ]),
      editEstimateShowNotes: new FormControl('', [
        Validators.maxLength(1000)
      ]),
      editEstimateAttachments: new FormControl('', [
        Validators.maxLength(100)
      ]),
      editEstimateTags: new FormControl('', [
        Validators.maxLength(100)
      ]),
      editEstimateNotes: new FormControl('', [
        Validators.maxLength(2048)
      ]),
      editEstimateComments: new FormControl('', [
        Validators.maxLength(2048)
      ]),
      editEstimateVisibility: new FormControl('', [
        Validators.maxLength(100)
      ])
    });

    this.searchArtworkForm = this.formBuilder.group({
      searchArtworkTerm: new FormControl('', [
        Validators.required,
        Validators.minLength(3),
        Validators.maxLength(50)
      ])
    });

    this.filesToUpload = [];
    this.uploadInput = new EventEmitter<UploadInput>();
    this.humanizeBytes = humanizeBytes;
  }

  ngOnInit() {
    if ([null, undefined].includes(this.activeEstimateId)) {
      this.activeEstimateId = this.estimateService.getCurrentEstimate();
    }

    this.getUser();
  }

  getUser() {
    this.activeService.getActiveData().subscribe(
      data => {
        this.activeAccount = data.account.id;
        this.activeUser = data.user.id;
        this.userService.getUser(data.user).subscribe(
          data => {
            this.user = data;
            /*if (this.user.account[0]) {
              this.user.role = this.user.account[0].role;
            }*/
            /* 9.5. Tarvitaanko tätä mihinkään? */
            /*this.getUsersAccounts();*/
          },
          () => {
            UIkit.notification({
              message: 'Error fetching user information',
              status: 'danger',
              pos: 'top-right',
              timeout: 1500
            });
          }
        );
      }
    );
    this.getAccountData();
  }

  getAccountData() {
    const getObject = {
      accountId: this.activeAccount,
      requester: 'estimate',
      sortingKey: '_id',
      sortingOrder: 'desc',
      skipQuery: 0,
      limitQuery: null,
      contentTypes: ['actor', 'artist', 'user', 'variable']
    };

    this.accountService.getAccountData(getObject).subscribe(
      data => {
        this.users = data.users;
        this.actors = data.actors;
        this.artists = data.artists;
        this.variables = data.variables ? data.variables : [];
      },
      () => {
        UIkit.notification({
          message: 'Error fetching data',
          status: 'danger',
          pos: 'top-right',
          timeout: 1500
        });
      },
      () => this.isLoading = false
    );
  }

  /* 9.5. Tätä ei varmaan tarvita mihinkään */
  /*getUsersAccounts() {
    this.accountService.getUserAccounts(this.user).subscribe(
      data => {
        this.accounts = data;
      },
      () => {
        UIkit.notification({
          message: 'Error fetching accounts',
          status: 'danger',
          pos: 'top-right',
          timeout: 1500
        });
      },
      () => this.isLoading = false
    );
  }*/

  editEstimate() {
    const editEstimateObject = {
      id: this.editEstimateForm.value.editEstimateId,
      owner: this.editEstimateForm.value.editEstimateOwner,
      name: this.editEstimateForm.value.editEstimateName,
      description: this.editEstimateForm.value.editEstimateDescription,
      items: this.activeEstimate.items,
      estimator: this.editEstimateForm.value.editEstimateEstimator,
      created: this.editEstimateForm.value.editEstimateCreated ? moment(this.editEstimateForm.value.editEstimateCreated.singleDate.jsDate).format() : moment(new Date),
      estimateBasis: this.editEstimateForm.value.editEstimateBasis,
      currency: this.editEstimateForm.value.editEstimateCurrency,
      minValue: this.editEstimateForm.value.editEstimateMinValue,
      maxValue: this.editEstimateForm.value.editEstimateMaxValue,
      insuranceValue: this.editEstimateForm.value.editEstimateInsuranceValue,
      showNotes: this.editEstimateForm.value.editEstimateShowNotes,
      attachments: this.activeEstimate.attachments,
      tags: this.editEstimateForm.value.editEstimateTags,
      notes: this.editEstimateForm.value.editEstimateNotes,
      comments: this.editEstimateForm.value.editEstimateComments,
      visibility: this.editEstimateForm.value.editEstimateVisibility
    };
    this.estimateService.editEstimate(editEstimateObject).subscribe(
      res => {
        UIkit.notification({
          message: 'Saved successfully',
          status: 'success',
          pos: 'top-right',
          timeout: 1500
        });
      },
      error => {
        UIkit.notification({
          message: 'Saving failed',
          status: 'danger',
          pos: 'top-right',
          timeout: 1500
        });
      }
    );
  }

  searchArtwork() {
    /*this.isLoading = true;*/
    const searchObject = {
      searchArtworkAccountId: this.activeAccount,
      searchArtworkUserId: this.activeUser,
      searchArtworkTerm: this.searchArtworkForm.value.searchArtworkTerm
    };
    this.searchService.searchArtwork(searchObject).subscribe(
      res => {
        if (res.length) {
          if (this.searchArtworkResults) {
            this.searchArtworkResults.length = 0;
          }
          res.forEach(artwork => {
            artwork.allResultType = 'artwork';
            this.searchArtworkResults.push(artwork);
          });
          /*this.isLoading = false;*/
          this.searchResultsFound = true;
          document.getElementById('search-artwork-results-container').classList.add('active');



          UIkit.notification({
            message: 'Search matched ' + this.searchArtworkResults.length + ' results',
            status: 'success',
            pos: 'top-right',
            timeout: 1500
          });
        } else {
          if (this.searchArtworkResults) {
            this.searchArtworkResults.length = 0;
          }
          UIkit.notification({
            message: 'No search results',
            status: 'danger',
            pos: 'top-right',
            timeout: 1500
          });
        }
      },
      error => {
        if (this.searchArtworkResults) {
          this.searchArtworkResults.length = 0;
        }
        UIkit.notification({
          message: 'No search results',
          status: 'danger',
          pos: 'top-right',
          timeout: 1500
        });
      }
    );
  }

  emptySearch(): void {
    /*document.getElementById('search-artwork-results-container').classList.remove('active');
    let element: HTMLElement = document.getElementsByClassName('uk-navbar-toggle uk-close')[1] as HTMLElement;
    element.click();*/

    setTimeout(() => {
      this.searchResultsFound = false;
      if (this.searchArtworkResults) {
        this.searchArtworkResults.length = 0;
      }
      if (this.selectedArtworks) {
        this.selectedArtworks.length = 0;
      }
      this.searchArtworkForm.patchValue({ searchArtworkTerm: ''});
    }, 300);
  }

  selectEstimateArtwork(artwork): void {
    this.selectedArtworks.indexOf(artwork) === -1 ?
      this.selectedArtworks.push(artwork) : this.selectedArtworks.splice(this.selectedArtworks.indexOf(artwork), 1);
  }

  addArtworksToEstimate(): void {
    this.selectedArtworks.map((item: any) => {
      const itemFound = this.activeEstimate.items.find((activeEstimateItem: any) => {
        return activeEstimateItem._id === item._id;
      });
      if (itemFound === undefined) {
        this.activeEstimate.items.push(item);
      }
      const artistFound = this.artists.find((artist: any) => {
        return artist._id === item.artist;
      });
      if (artistFound === undefined) {
        this.artists.push({
          _id: item.artist,
          name: item.artistName,
          priceMultiplier: item.artistPriceMultiplier,
          status: item.artistStatus
        });
      }
    });

    this.emptySearch();
  }

  removeArtworkFromEstimate(item): void {
    this.activeEstimate.items.splice(this.activeEstimate.items.indexOf(item), 1);
    const artistToSplice = this.artists.find((artist: any) => {
      return artist._id === item.artist;
    });
    this.artists.splice(this.artists.indexOf(artistToSplice), 1);
  }

  getEstimatePDF(): void {
    const estimateObject = {
      items: []
    };

    this.activeEstimate.items.forEach(item => {
      const itemName = item.name;
      let itemImage = null;

      if (item.coverImage) {
        itemImage = item.coverImage;
      } else if (item.images && item.images.length) {
        itemImage = item.images[0].fileId;
      }

      const itemArtistName = item.artistName;
      const itemCreated = moment(item.created).isValid() ? moment(item.created).locale('fi').format('YYYY') : null;
      const itemTechnique = item.technique;

      let itemSize = '';
      if (item.height) {
        itemSize += item.height;
      }
      if (item.width) {
        if (item.height) {
          itemSize += ' x ';
        }
        itemSize += item.width;
      }
      if (item.depth) {
        if (item.height) {
          itemSize += ' x ';
        }
        itemSize += item.depth;
      }
      const itemPrice = item.price.price + ' ' + item.price.currency;
      const itemAvailability = item.availability;

      let estimateItemsObject: any = {};
      estimateItemsObject = {
        'id': item._id,
        'account': this.activeAccount,
        'Artist': itemArtistName,
        'Item name': itemName
      };

      if (itemCreated) {
        estimateItemsObject['Item created'] = itemCreated;
      }

      if (itemTechnique) {
        estimateItemsObject['Item technique'] = itemTechnique;
      }

      if (itemSize) {
        estimateItemsObject['Item size'] = itemSize + ' cm';
      }

      if (item.price.price) {
        estimateItemsObject['Item price'] = item.price.price + ' ' + (item.price.currency ? item.price.currency : '');
      }

      /*if (itemAvailability) {
        estimateItemsObject['Item availability'] = itemAvailability;
      }*/

      if (this.editEstimateForm.value.editEstimateShowNotes) {
        estimateItemsObject.notes = this.editEstimateForm.value.editEstimateShowNotes;
      }

      if (itemImage) {
        estimateItemsObject.Image = itemImage;
      }

      estimateObject.items.push(estimateItemsObject);
    });

    this.fileService.getPDF('Collection-list', estimateObject);
  }

  openSlideshow($event): void {
    $event.preventDefault();
    UIkit.lightboxPanel({
    /* Ei tarvitse, jos muistaa käyttää $event.preventDefaultia
      autoplay: true,
      autoplayInterval: 5000,
      delayControls: 1000,*/
      items: this.activeEstimate.items.map(item => {
        let itemImage;
        if (item.coverImage) {
          itemImage = '/api/image/' + item.coverImage;
        } else if (item.images[0]) {
          itemImage = '/api/image/' + item.images[0].fileId;
        } else {
          itemImage = '/api/image/artbuddy-logo-placeholder.svg'
        }

        return {
          source: itemImage,
          caption: item.name
        }
      })
    }).show();
  }

  openArtist(artistId): void {
    this.router.navigateByUrl('/artist/' + artistId);
  }

  onAttachmentsUploadOutput(output: UploadOutput): void {
    const properFile = output.file && output.file.name && output.file.name.length > 0;
    if (output.type === 'allAddedToQueue') {
      const event: UploadInput = {
        type: 'uploadAll',
        url: '/api/file',
        method: 'POST',
        includeWebKitFormBoundary: true
      };
      this.uploadInput.emit(event);
    } else if (output.type === 'addedToQueue' && properFile) {
      if (this.filesToUpload.indexOf(output.file) == -1) {
        this.filesToUpload.push(output.file);
      }
    } else if (output.type === 'uploading' && properFile) {
      if (!this.activeEstimate['attachments'].some(image => image.placeholderId === output.file.id)) {
        this.uploadLoading = true;
        this.activeService.increasePendingRequests();
        this.activeEstimate['attachments'].push({ placeholderId: output.file.id });
        const index = this.filesToUpload.findIndex(file => file.id === output.file.id);
        this.filesToUpload[index] = output.file;
      }
    } else if (output.type === 'removed') {
      // remove file from array when removed
      this.filesToUpload = this.filesToUpload.filter((file: UploadFile) => file !== output.file);
    } else if (output.type === 'dragOver') {
      this.dragOver = true;
    } else if (output.type === 'dragOut') {
      this.dragOver = false;
    } else if (output.type === 'drop') {
      this.dragOver = false;
    } else if (output.type === 'removedAll') {
      this.filesToUpload.length = 0;
    } else if (output.type === 'done') {
      const fileObject = {
        itemId: this.activeEstimateId,
        fileId: output.file.response,
        name: output.file.name,
        contentType: output.file.type
      };
      this.removeFile(output.file.id);
      const indexToReplace = this.activeEstimate['attachments'].findIndex(file => file.placeholderId === output.file.id);
      this.activeEstimate['attachments'][indexToReplace] = fileObject;
      this.activeService.decreasePendingRequests();
      this.uploadLoading = false;
    }
  }

  startUpload(): void {
    const event: UploadInput = {
      type: 'uploadAll',
      url: '/api/file',
      method: 'POST',
      includeWebKitFormBoundary: true
    };
    this.uploadInput.emit(event);
  }

  cancelUpload(id: string): void {
    this.uploadInput.emit({ type: 'cancel', id: id });
  }

  removeFile(id: string): void {
    this.uploadInput.emit({ type: 'remove', id: id });
  }

  openAttachment(attachment) {
    this.itemService.openAttachment(this.activeAccount, attachment).then((response: any) => {
      if (response.url) {
        window.open(response.url);
      } else {
        UIkit.notification({
          message: 'Error fetching data',
          status: 'danger',
          pos: 'top-right',
          timeout: 1500
        });
      }
    });
  }

  removeAttachment(attachment) {
    const attachmentObject = {
      documentType: 'estimate',
      itemId: this.activeEstimateId,
      fileId: attachment.fileId
    };

    this.fileService.removeFile(attachmentObject).subscribe(
      res => {
        const attachmentToSplice = this.activeEstimate.attachments.find((accountAttachment: any) => {
          return attachment.fileId === accountAttachment.fileId;
        });
        this.activeEstimate.attachments.splice(this.activeEstimate.attachments.indexOf(attachmentToSplice), 1);
        UIkit.notification({
          message: 'File removed successfully',
          status: 'success',
          pos: 'top-right',
          timeout: 1500
        });
      },
      error => {
        UIkit.notification({
          message: 'Removing failed',
          status: 'danger',
          pos: 'top-right',
          timeout: 1500
        });
      }
    );
  }

  removeAllFiles(): void {
    this.uploadInput.emit({ type: 'removeAll' });
  }



  openAdvancedSearch(): void {
    this.showAdvancedArtworkSearchModalWindow = true;
    setTimeout(() => {
      this.advancedArtworkSearchModalWindow = window.document.getElementById('advancedArtworkSearch');
      UIkit.modal(this.advancedArtworkSearchModalWindow).show();
    }, 500);

  }

  setArtworkSearchResults(searchResult: Array<any>) {
    searchResult.forEach(item => this.selectedArtworks.push(item));
    this.addArtworksToEstimate();
  }

  createDate(inputDate: string): any {
    return {
      isRange: false,
      singleDate: {
        date: {
          year: new Date(inputDate).getFullYear(),
          month: new Date(inputDate).getMonth() + 1,
          day: new Date(inputDate).getDate()
        }
      }
    };
  }

  updateDate(fieldName: string, dateObject: any) {
    this.editEstimateForm.controls[fieldName].patchValue(moment(dateObject.singleDate.jsDate).format());
  }
}
