import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';
import { HttpClient , HttpParams, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';
import 'rxjs/add/operator/map';
import { ActiveService } from './active.service';

@Injectable()
export class AccountService {
  private token = localStorage.getItem('token');

  private httpOptions = {
    headers: new HttpHeaders({
      'Content-Type': 'application/json',
      'charset': 'UTF-8',
      'Authorization': 'Bearer ' + this.token
    })
  };

  currentAccount: string = '';
  currentAccountChange: Subject<string> = new Subject<string>();

  constructor(
    private http: HttpClient,
    private activeService: ActiveService
  ) {
    this.activeService.activeDataChange.subscribe(data => {
      this.currentAccount = data.account.id;
    });
  }

  setCurrentAccount(accountId): void {
    this.currentAccount = accountId;
    this.currentAccountChange.next(this.currentAccount);
  }

  getCurrentAccount(): string {
    return this.currentAccount;
  }

  getAccounts(): Observable<any> {
    return this.http.get('/api/accounts');
  }

  /*getUsersAccounts(): Observable<any> {
    return this.http.get('/api/accounts');
  }*/

  countAccounts(): Observable<any> {
    return this.http.get('/api/accounts/count');
  }

  addAccount(account): Observable<any> {
    return this.http.post('/api/account', JSON.stringify(account), this.httpOptions);
  }

  getAccount(account): Observable<any> {
    return this.http.get(`/api/account/${account._id}`);
  }

  getAccountData(getObject): Observable<any> {
    if (getObject.accountId) {
      if (getObject.accountId === '') { return; }
      return this.http.get(`/api/accountData/${ getObject.accountId }/${ getObject.requester }/${ getObject.requesterId }/${ getObject.sortingKey }/${ getObject.sortingOrder }/${ getObject.skipQuery }/${ getObject.limitQuery }/${ getObject.contentTypes }`);
    } else {
      if (getObject === '') { return; }
      return this.http.get(`/api/accountData/${ getObject }`);
    }
  }

  getFile(fileId): Observable<any> {
    let formData: FormData = new FormData();
    formData.append('id', fileId);
    return this.http.get('/api/file/' + fileId);
  }

  getUserAccounts(user: any, sortingKey?: string, sortingOrder?: boolean): Observable<any> {
    if (!user._id) {
      user._id = user.id;
    }
    if (sortingKey) {
      return this.http.get(`/api/useraccounts/${user._id}/${sortingKey}/${sortingOrder}`);
    } else {
      return this.http.get(`/api/useraccounts/${user._id}`);
    }
  }

  getUserLatestAccount(user): Observable<any> {
    return this.http.get(`/api/userlatestaccount/${user._id}`);
  }

  getAccountUsers(account): Observable<any> {
    return this.http.get(`/api/accountUsers/${account._id}`);
  }

  /*getFormUrlEncoded(toConvert) {
    const formBody = [];
    for (const property in toConvert) {
      const encodedKey = encodeURIComponent(property);
      const encodedValue = encodeURIComponent(toConvert[property]);
      formBody.push(encodedKey + '=' + encodedValue);
    }
    return formBody.join('&');
  }

  createFormData(formGroup, fileInput:any='',fileInputName:string = 'file') {
    let formData = new FormData();
    if(fileInput){
      let fileElement = fileInput.nativeElement;
      if (fileElement.files) {
        for (let [key, value] of Object.entries(fileElement.files)) {
          if(fileElement.files[key])
            formData.append(fileInputName+key, fileElement.files[key]);
        }
      }
    }
    for (let [key, value] of Object.entries(formGroup)) {
      formData.append(key, value);
    }
    return formData;
  }*/

  uploadFile(fileElement: any): Observable<any> {
    const _formData = new FormData();

    if (fileElement.files) {
      for (let [key, value] of Object.entries(fileElement.files)) {
        if (fileElement.files[key])
          _formData.append(fileElement.files[key].name + key, fileElement.files[key]);
      }
    }

    /*_formData.append('file', fileUpload, fileUpload.name);*/

    return this.http.post('/api/uploadFileOrig', JSON.stringify(fileElement), this.httpOptions);
  }

  addFileId(fileObject: object): Observable<any> {
    return this.http.post('/api/accountFileId', JSON.stringify(fileObject), this.httpOptions);
  }

  addImageId(fileObject: object): Observable<any> {
    return this.http.post('/api/accountImageId', JSON.stringify(fileObject), this.httpOptions);
  }

  removeImageId(fileObject: object): Observable<any> {
    return this.http.post('/api/removeAccountImageId', JSON.stringify(fileObject), this.httpOptions);
  }

  editAccount(account): Observable<any> {
    return this.http.put(`/api/account/${account.id}`, JSON.stringify(account), this.httpOptions);
  }

  editAccountStatus(account): Observable<any> {
    return this.http.put(`/api/account/singleValue/${account.id}`, JSON.stringify(account), this.httpOptions);
  }

  deleteAccount(account): Observable<any> {
    return this.http.delete(`/api/account/${account.id}`, this.httpOptions);
  }

  // Client groups
  addClientGroup(clientGroupObject): Observable<any> {
    return this.http.post(`/api/account/updateClientGroupTitles/${clientGroupObject.addClientGroupFormAccount}`, JSON.stringify(clientGroupObject), this.httpOptions);
  }

  editClientGroup(clientGroupObject): Observable<any> {
    return this.http.put(`/api/account/updateClientGroupTitles/${clientGroupObject.editClientGroupFormAccount}`, JSON.stringify(clientGroupObject), this.httpOptions);
  }

  deleteClientGroup(accountId, clientGroupObject): Observable<any> {
    return this.http.put(`/api/account/deleteClientGroupTitles/${accountId}`, JSON.stringify(clientGroupObject), this.httpOptions);
  }

  // Contact methods
  addContactMethod(contactMethodObject): Observable<any> {
    return this.http.post(`/api/account/updateContactMethodTitles/${contactMethodObject.addContactMethodFormAccount}`, JSON.stringify(contactMethodObject), this.httpOptions);
  }

  editContactMethod(contactMethodObject): Observable<any> {
    return this.http.put(`/api/account/updateContactMethodTitles/${contactMethodObject.editContactMethodFormAccount}`, JSON.stringify(contactMethodObject), this.httpOptions);
  }

  deleteContactMethod(accountId, contactMethodObject): Observable<any> {
    return this.http.put(`/api/account/deleteContactMethodTitles/${accountId}`, JSON.stringify(contactMethodObject), this.httpOptions);
  }

  // VAT
  addVAT(VATObject): Observable<any> {
    return this.http.post(`/api/account/updateVATRates/${VATObject.addVATFormAccount}`, JSON.stringify(VATObject), this.httpOptions);
  }

  editVAT(VATObject): Observable<any> {
    return this.http.put(`/api/account/updateVATRates/${VATObject.editVATFormAccount}`, JSON.stringify(VATObject), this.httpOptions);
  }

  deleteVAT(accountId, VATObject): Observable<any> {
    return this.http.put(`/api/account/deleteVATRates/${accountId}`, JSON.stringify(VATObject), this.httpOptions);
  }

  // Language
  addLanguage(languageObject): Observable<any> {
    return this.http.post(`/api/account/updateLanguageTitles/${languageObject.addLanguageFormAccount}`, JSON.stringify(languageObject), this.httpOptions);
  }

  editLanguage(languageObject): Observable<any> {
    return this.http.put(`/api/account/updateLanguageTitles/${languageObject.editLanguageFormAccount}`, JSON.stringify(languageObject), this.httpOptions);
  }

  deleteLanguage(accountId, languageObject): Observable<any> {
    return this.http.put(`/api/account/deleteLanguageTitles/${accountId}`, JSON.stringify(languageObject), this.httpOptions);
  }

  updateArtworkCode(ArtworkCodeObject): Observable<any> {
    return this.http.put(`/api/account/updateArtworkCode/${ArtworkCodeObject.editArtworkCodeFormAccount}`, JSON.stringify(ArtworkCodeObject), this.httpOptions);
  }
}
