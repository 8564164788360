import {Component, Inject, forwardRef, Input, OnInit, Optional} from '@angular/core';
import {BaseWidget, NgAisInstantSearch} from 'angular-instantsearch';
import {connectRefinementList} from 'instantsearch.js/es/connectors';
import {NgAisIndex} from 'angular-instantsearch';
import * as html2canvas from 'html2canvas';

@Component({
  selector: 'app-ais-refinement-list',
  template: `
    <div class="uk-margin-small" *ngIf="(state.items && state.items.length) || this.refinementAttribute === 'code'">
      <label class="uk-form-label header-row" [hidden]="!title">
        <div class="label-container">{{ title }}</div>
        <div class="action-container">
          <button class="uk-button uk-button-text capitalize" (click)="state.toggleShowMore()">
            <span *ngIf="!state.isShowingMore">Show all</span>
            <span *ngIf="state.isShowingMore">Show less</span>
          </button>
        </div>
      </label>
      <div class="uk-form-controls">
        <div class="uk-inline uk-width-expand" [hidden]="!searchable">
          <input type="text" class="uk-input" placeholder="Filter..." [(ngModel)]="refinementInput" (ngModelChange)="filter()" />
          <a class="uk-form-icon uk-form-icon-flip" (click)="clearFilter()" uk-icon="icon: close" [hidden]="!(refinementInput && refinementInput.length)"></a>
        </div>
        <div class="uk-panel uk-height-small uk-panel-scrollable no-resize">
          <div class="uk-inline uk-width-1-1" *ngFor="let item of state.items">
            <input type="checkbox" class="uk-checkbox"
                   (click)="state.refine(item.value)"
                   [checked]="item.isRefined"> {{ item.label }} ({{ item.count }})
          </div>
        </div>
      </div>
      <!--<ng-template #ngSelect>
        <div class="uk-form-controls">
          <ng-select [items]="state.items"
                     [addTag]="false"
                     [selectOnTab]="false"
                     [placeholder]="moreOptions"
                     bindLabel=""
                     bindValue="value"
                     class="uk-select"
                     [closeOnSelect]="true"
                     [multiple]="true"
                     (change)="setSelected()"¡
                     [(ngModel)]="selectedItem">
          </ng-select>
        </div>
      </ng-template>-->
    </div>`
})
export class SearchAisRefinementListComponent extends BaseWidget implements OnInit {
  @Input() title = '';
  @Input() attribute = '';
  @Input() sortName: string;
  @Input() sortDescription: string;
  @Input() refinementAttribute: string;
  @Input() searchable: boolean;
  public selectedItem: any;
  public refinementInput: String = '';
  public alwaysShow: boolean = false;

  /* TODO: The above parameters are not used. Algolia is throwing error if createWidget method is moved to ngInit phase with these parameters in use. Added refinementAttribute to tackle this issue and to separate widgets from each other. */

  public state: {
    items: object[];
    refine: Function;
    createURL: Function;
    isFromSearch: boolean;
    searchForItems: Function;
    isShowingMore: boolean;
    canToggleShowMore: boolean;
    toggleShowMore: Function;
    widgetParams: object;
  };

  constructor(
    @Inject(forwardRef(() => NgAisIndex))
    @Optional()
    public parentIndex: NgAisIndex,
    @Inject(forwardRef(() => NgAisInstantSearch))
    public instantSearchInstance: NgAisInstantSearch
  ) {
    super('RefinementList');
  }

  ngOnInit(): void {
    /*this.createWidget(connectRefinementList, {
      attribute: this.attribute,
      sortBy: [this.sortName + ':' + this.sortDescription ],
      searchPlaceholder: 'Search ...',
      autoHideContainer: true
    });*/

    // @ts-ignore
    this.createWidget(connectRefinementList, {
      // instance options
      attribute: this.refinementAttribute,
      sortBy: [this.sortName + ':' + this.sortDescription],
      searchPlaceholder: 'Search ...',
      autoHideContainer: true,
      limit: 10,
      showMoreLimit: 9999,
      searchable: true,
    });
    super.ngOnInit();

    setTimeout(() => {
      const params: any = this.state.widgetParams;

      if (params?.attribute === "code") {
        this.alwaysShow = true;
      }
    }, 1000);
  }

  setSelected(): void {
    this.state.refine(this.selectedItem.isRefined);
  }

  filter(): void {
    this.state.searchForItems(this.refinementInput);
  }

  clearFilter(): void {
    this.refinementInput = '';
    this.filter();
  }
}
