import {Component, EventEmitter, OnInit, ViewChild} from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { FormGroup, FormControl, Validators, FormBuilder } from '@angular/forms';
import { AuthService } from '../../services/auth.service';
import { UserService } from '../../services/user.service';
import { ActiveService } from '../../services/active.service';
import { AccountService } from '../../services/account.service';
import { FileService } from '../../services/file.service';
import { SearchService } from '../../services/search.service';
import { TradeService } from '../../services/trade.service';
import { ItemService } from '../../services/item.service';
import { UploadOutput, UploadInput, UploadFile, humanizeBytes, UploaderOptions } from 'ngx-uploader';
import {AngularMyDatePickerDirective, IAngularMyDpOptions, IMyDateModel} from 'angular-mydatepicker';
import 'rxjs/add/observable/merge';
import * as moment from 'moment/moment';
declare var UIkit: any;

@Component({
  selector: 'app-single-trade',
  templateUrl: './single-trade.component.html',
  styleUrls: ['./single-trade.component.scss']
})
export class SingleTradeComponent implements OnInit {
  public user: any = {};
  public activeTrade: any = {};
  public activeTradeId: string = '';
  public actorType: string;
  public activeAccount: any = {};
  private activeUser: string;
  public accounts: Array<Object> = [];
  public artists: Array<Object> = [];
  public actors: Array<Object> = [];
  public activeTradeSeller: any = {};
  public activeTradeAgent: any = {};
  public activeTradeBuyer: any = {};
  public tradingCurrency: string = '';
  public tradingVAT: number;
  public isLoading = true;
  public uploadLoading: Boolean = false;
  public addNewArtworkModalWindow: any;
  public advancedArtworkSearchModalWindow: any;
  public showAdvancedArtworkSearchModalWindow: boolean = false;
  public editTradeForm: FormGroup;
  public searchArtworkForm: FormGroup;
  public searchArtworkResults: Array<Object> = [];
  public selectedArtworks: Array<Object> = [];
  public searchResultsFound: boolean = false;
  public myDatePickerOptions: IAngularMyDpOptions = {
    dateFormat: 'dd.mm.yyyy'
  };
  private todaysDate: IMyDateModel;
  private validDate: IMyDateModel;
  @ViewChild('AngularMyDatePickerEditTradeCreated') myDp: AngularMyDatePickerDirective;
  public imageLoadOffset$: any;
  loadingImage = '/api/file/5a7193132d68a10c580620e5';
  public options: UploaderOptions;
  public formData: FormData;
  public filesToUpload: UploadFile[];
  public uploadInput: EventEmitter<UploadInput>;
  public humanizeBytes: Function;
  public dragOver: boolean;
  public numberWithDecimal = new RegExp(/^[0-9]+(\.[0-9]{1,2})?$/);

  constructor(
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private formBuilder: FormBuilder,
    private auth: AuthService,
    private userService: UserService,
    private activeService: ActiveService,
    private accountService: AccountService,
    private tradeService: TradeService,
    private fileService: FileService,
    private itemService: ItemService,
    private searchService: SearchService,
  ) {
    this.activatedRoute.data.subscribe(data => {
      this.activeTrade = data.resolvedData;
      if (this.activeTrade.items) {
        this.activeTrade.items.map(item => {
          item.created = moment(item.created).locale('fi').format('YYYY');
          /*switch (item.availability) {
            case 0:
              item.availability = 'Sold';
              break;
            case 1:
              item.availability = 'Booked';
              break;
            case 2: default:
            item.availability = 'Available';
            break;
          }*/
          const artistFound = this.artists.find((artist: any) => {
            return artist._id === item.artist;
          });
          if (artistFound === undefined) {
            this.artists.push({
              _id: item.artist,
              name: item.artistName,
              priceMultiplier: item.artistPriceMultiplier,
              status: item.artistStatus
            });
          }
        });
      } else {
        this.activeTrade.items = [];
      }
      this.activeTrade.created = this.createDate(this.activeTrade.created);
      this.activeTradeId = data.resolvedData._id;

      if (data.resolvedData.seller) {
        if (data.resolvedData.seller.lastName) {
          data.resolvedData.seller.fullName = data.resolvedData.seller.name + ' ' + data.resolvedData.seller.lastName;
        } else {
          data.resolvedData.seller.fullName = data.resolvedData.seller.name;
        }
      } else {
        data.resolvedData.seller = {
          fullName: null
        };
      }

      if (data.resolvedData.agent) {
        if (data.resolvedData.agent.lastName) {
          data.resolvedData.agent.fullName = data.resolvedData.agent.name + ' ' + data.resolvedData.agent.lastName;
        } else {
          data.resolvedData.agent.fullName = data.resolvedData.agent.name;
        }
      } else {
        data.resolvedData.agent = {
          fullName: null
        };
      }

      if (data.resolvedData.buyer) {
        if (data.resolvedData.buyer.lastName) {
          data.resolvedData.buyer.fullName = data.resolvedData.buyer.name + ' ' + data.resolvedData.buyer.lastName;
        } else {
          data.resolvedData.buyer.fullName = data.resolvedData.buyer.name;
        }
      } else {
        data.resolvedData.buyer = {
          fullName: null
        };
      }

      this.activeTradeSeller = data.resolvedData.seller;
      this.activeTradeAgent = data.resolvedData.agent;
      this.activeTradeBuyer = data.resolvedData.buyer;

      const createdYear = this.activeTrade.created.singleDate.date.year;
      const createdMonth = this.activeTrade.created.singleDate.date.month;
      const createdDay = this.activeTrade.created.singleDate.date.day;

      const createdDate = new Date(`${createdMonth}/${createdDay}/${createdYear}`);
      const createdMomentDate = moment(createdDate).format("L");

      this.activeTrade.created = this.createDate(createdMomentDate);

      if (this.activeTrade.vat) {
        this.tradingVAT = this.activeTrade.vat;
      }
    });

    this.editTradeForm = this.formBuilder.group({
      editTradeId: new FormControl('', [
        Validators.required,
        Validators.maxLength(100)
      ]),
      editTradeOwner: new FormControl('', [
        Validators.required,
        Validators.maxLength(100)
      ]),
      editTradeName: new FormControl('', [
        Validators.required,
        Validators.maxLength(100)
      ]),
      editTradeDescription: new FormControl('', [
        Validators.maxLength(2048)
      ]),
      editTradeSeller: new FormControl('', [
        Validators.required,
        Validators.maxLength(100)
      ]),
      editTradeAgent: new FormControl('', [
        Validators.maxLength(100)
      ]),
      editTradeBuyer: new FormControl('', [
        Validators.required,
        Validators.maxLength(100)
      ]),
      editTradeCreated: new FormControl('', [
        Validators.maxLength(10)
      ]),
      editTradeCurrency: new FormControl({value: '', disabled: true}, [
        Validators.maxLength(10)
      ]),
      editTradeVAT: new FormControl({value: null, disabled: true}, [
        Validators.maxLength(10)
      ]),
      editTradePrice: new FormControl('', [
        Validators.maxLength(10),
        Validators.pattern(this.numberWithDecimal)
      ]),
      editTradeDiscount: new FormControl('', [
        Validators.maxLength(10),
        Validators.pattern(this.numberWithDecimal)
      ]),
      editTradeAttachments: new FormControl('', [
        Validators.maxLength(100)
      ]),
      editTradeTags: new FormControl('', [
        Validators.maxLength(2048)
      ]),
      editTradeShowNotes: new FormControl('', [
        Validators.maxLength(2048)
      ]),
      editTradeNotes: new FormControl('', [
        Validators.maxLength(2048)
      ]),
      editTradeComments: new FormControl('', [
        Validators.maxLength(2048)
      ]),
      editTradeVisibility: new FormControl('', [
        Validators.maxLength(10)
      ])
    });

    this.searchArtworkForm = this.formBuilder.group({
      searchArtworkTerm: new FormControl('', [
        Validators.required,
        Validators.minLength(3),
        Validators.maxLength(50)
      ])
    });

    this.filesToUpload = [];
    this.uploadInput = new EventEmitter<UploadInput>();
    this.humanizeBytes = humanizeBytes;
  }

  ngOnInit() {
    if ([null, undefined].includes(this.activeTradeId)) {
      this.activeTradeId = this.tradeService.getCurrentTrade();
    }

    this.getUser();
  }

  getUser() {
    this.activeService.getActiveData().subscribe(
      data => {
        this.activeAccount = data.account.id;
        this.activeUser = data.user.id;
        this.userService.getUser(data.user).subscribe(
          data => {
            this.user = data;
            /*if (this.user.account[0]) {
              this.user.role = this.user.account[0].role;
            }*/
            this.getUsersAccounts();
          },
          () => {
            UIkit.notification({
              message: 'Error fetching user information',
              status: 'danger',
              pos: 'top-right',
              timeout: 1500
            });
          }
        );
      }
    );
    this.getAccountData();
  }

  getAccountData() {
    const getObject = {
      accountId: this.activeAccount,
      requester: 'trade',
      sortingKey: '_id',
      sortingOrder: 'desc',
      skipQuery: 0,
      limitQuery: null,
      contentTypes: ['artist', 'actor', 'account']
    };

    this.accountService.getAccountData(getObject).subscribe(
      data => {
        this.artists = data.artists;
        this.actors = data.actors.map(actor => {
          if (actor.lastName) {
            actor.fullName = actor.name + ' ' + actor.lastName;
          } else {
            actor.fullName = actor.name;
          }

          return actor;
        });
        this.tradingCurrency = data.account.tradingCurrency;

        if (!this.activeTrade.vat) {
          this.tradingVAT = data.account.vat;
        }
      },
      () => {
        UIkit.notification({
          message: 'Error fetching data',
          status: 'danger',
          pos: 'top-right',
          timeout: 1500
        });
      },
      () => {
        this.isLoading = false;
      }
    );
  }

  // TODO: Tämäkin pitäis siirtää resolvattaksi ns. MasterServicen avulla, jolloin myös aktiivisen accountin tiedot tulisivat sieltä
  getUsersAccounts() {
    this.accountService.getUserAccounts(this.user).subscribe(
      data => {
        this.accounts = data;
      },
      () => {
        UIkit.notification({
          message: 'Error fetching accounts',
          status: 'danger',
          pos: 'top-right',
          timeout: 1500
        });
      },
      () => this.isLoading = false
    );
  }

  editTrade() {
    const editTradeObject = {
      id: this.editTradeForm.value.editTradeId,
      owner: this.editTradeForm.value.editTradeOwner,
      name: this.editTradeForm.value.editTradeName,
      description: this.editTradeForm.value.editTradeDescription,
      items: this.activeTrade.items,
      seller: this.editTradeForm.value.editTradeSeller,
      agent: this.editTradeForm.value.editTradeAgent,
      buyer: this.editTradeForm.value.editTradeBuyer,
      created: this.editTradeForm.value.editTradeCreated ? moment(this.editTradeForm.value.editTradeCreated.singleDate.jsDate).format() : moment(new Date),
      currency: this.editTradeForm.value.editTradeCurrency,
      vat: this.activeTrade.vat,
      price: this.editTradeForm.value.editTradePrice,
      discount: this.editTradeForm.value.editTradeDiscount,
      attachments: this.activeTrade.attachments,
      showNotes: this.editTradeForm.value.editTradeShowNotes,
      tags: this.editTradeForm.value.editTradeTags,
      notes: this.editTradeForm.value.editTradeNotes,
      comments: this.editTradeForm.value.editTradeComments,
      visibility: this.editTradeForm.value.editTradeVisibility
    };

    this.tradeService.editTrade(editTradeObject).subscribe(
      res => {
        UIkit.notification({
          message: 'Saved successfully',
          status: 'success',
          pos: 'top-right',
          timeout: 1500
        });
      },
      error => {
        UIkit.notification({
          message: 'Saving failed',
          status: 'danger',
          pos: 'top-right',
          timeout: 1500
        });
      }
    );
  }

  getItemVATAmount(item: any): number {
    let itemVATAMount: number,
      itemPrice: number;

    if (item.discount && item.discount >= 0) {
      itemPrice = item.price.price - item.discount;

    } else if (typeof item.price.price === 'number') {
      itemPrice = parseFloat(item.price.price);
    }

    // if (item.VATPercentage) {
    //   itemVATAMount = itemPrice - (itemPrice * (100 / (100 + parseInt(item.VATPercentage, 0))));
    //   return itemVATAMount;
    // } else {
    //   return null;
    // }

    itemVATAMount = itemPrice - (itemPrice * (100 / (100 + parseInt(this.activeTrade.vat, 0))));

    return itemVATAMount > 0 ? itemVATAMount : 0;
  }

  getUniqueVATs(): any {
    const uniqueVATs: Array<any> = [];

    this.activeTrade.items.forEach(item => {
      if (item.VATPercentage === undefined) {
        item.VATPercentage = '24';
      }

      const uniqueIndex = uniqueVATs.findIndex((uniqueItem: any) => uniqueItem.percentage === item.VATPercentage);

      /* Jos itemin percentage ei ole vielä uniikeissa */
      if (uniqueIndex === -1) {
        /* Lisätään itemin VATPercentage ja hinta uniikkeihin */
        uniqueVATs.push({ percentage: item.VATPercentage, amount: this.getItemVATAmount(item) });
      } else {
        /* Jos percentage on jo arrayssa, lisätään itemin hinta amountiin */
        uniqueVATs[uniqueIndex].amount += this.getItemVATAmount(item);
      }
    });

    return uniqueVATs;
  }

  getItemsDiscountedPrice(): number {
    const totalPrice: number = this.getItemsTotalPrice();
    const discountedPrice: number = this.getDiscountedPrice().price;

    const itemsDiscountedPrice = totalPrice - discountedPrice;

    return itemsDiscountedPrice;
  }

  getItemsVAT(): number {
    let totalVAT = 0;
    const items = this.getUniqueVATs();
    items.forEach(item => totalVAT += item.amount);

    return totalVAT;
  }

  getItemsGrandTotalPrice(): number {
    let totalPrice = 0;
    let discount = this.editTradeForm.get("editTradeDiscount").value ?? 0;

    this.activeTrade.items.forEach(item => {
      if (item.discount && item.discount >= 0) {
        discount += parseFloat(item.discount);
        totalPrice += parseFloat(item.price.price);
      } else if (typeof item.price.price === 'number') {
        totalPrice += parseFloat(item.price.price);
      }
    });

    if (discount > 0) {
      return totalPrice - discount;
    } else {
      return totalPrice;
    }
  }

  getDiscountedPrice(): any {
    const itemsTotalPrice: number = this.getItemsTotalPrice();
    const itemsTradedPrice = this.getItemsGrandTotalPrice();

    let discount = {
      price: 0,
      percentage: 0
    };

    if (itemsTradedPrice > 0) {
      discount = {
        price: (itemsTotalPrice - itemsTradedPrice) > 0 ? itemsTotalPrice - itemsTradedPrice : 0,
        percentage: (1 - (itemsTradedPrice / itemsTotalPrice)) * 100
      };
    }

    return discount;
  }

  getItemsTotalPrice(): number {
    let totalPrice = 0;

    this.activeTrade.items.forEach(item => {
      if (typeof item.price.price === 'number') {
        totalPrice += parseFloat(item.price.price);
      }
    });

    return totalPrice;
  }

  getItemDiscountedPrice(item: any): number {
    const itemTotalPrice: number = item.price.price;
    const itemDiscountedPrice: number = item.discount;

    if (itemDiscountedPrice && itemDiscountedPrice > 0) {
      return item.price.price - itemDiscountedPrice;
    } else {
      return itemTotalPrice;
    }
  }

  getTradePDF(): void {
    const createdYear = this.editTradeForm.get("editTradeCreated").value.singleDate.date.year;
    const createdMonth = this.editTradeForm.get("editTradeCreated").value.singleDate.date.month;
    const createdDay = this.editTradeForm.get("editTradeCreated").value.singleDate.date.day;

    const createdDate = `${createdDay}.${createdMonth}.${createdYear}`;

    const tradeObject = {
      items: [],
      notes: this.activeTrade.showNotes,
      pricing: {},
      sellerInfo: {
        name: this.activeTradeSeller.fullName,
        streetAddress: this.activeTradeSeller.street,
        postalCode: this.activeTradeSeller.zip,
        city: this.activeTradeSeller.city,
      },
      agentInfo: {
        name: this.activeTradeAgent.fullName,
        streetAddress: this.activeTradeAgent.street,
        postalCode: this.activeTradeAgent.zip,
        city: this.activeTradeAgent.city,
      },
      clientInfo: {
        name: this.activeTradeBuyer.fullName,
        streetAddress: this.activeTradeBuyer.street,
        postalCode: this.activeTradeBuyer.zip,
        city: this.activeTradeBuyer.city,
      },
      date: createdDate
    };

    if (this.activeTrade.items.length) {
      this.activeTrade.items.forEach(item => {
        const itemName = item.name;
        let itemImage = null;

        if (item.coverImage) {
          itemImage = item.coverImage;
        } else if (item.images && item.images.length) {
          itemImage = item.images[0].fileId;
        }

        const itemArtistName = item.artistName;

        // const itemCreated = moment(this.editTradeForm.value.editTradeCreated.singleDate.jsDate).isValid() ? moment(this.editTradeForm.value.editTradeCreated.singleDate.jsDate).locale('fi').format('YYYY') : null;

        const artworkStarted = item.started ?? null;
        const artworkCreated = item.created ?? null;

        let itemCreated = "";

        if (artworkStarted) {
          itemCreated += artworkStarted;
        }

        if (artworkCreated) {
          if (artworkStarted) {
            itemCreated += " - ";
          }
          itemCreated += artworkCreated;
        }

        const itemTechnique = item.technique;

        let itemSize = '';
        if (item.height) {
          itemSize += item.height;
        }
        if (item.width) {
          if (item.height) {
            itemSize += ' x ';
          }
          itemSize += item.width;
        }
        if (item.depth) {
          if (item.height) {
            itemSize += ' x ';
          }
          itemSize += item.depth;
        }

        let TradeItem: any = {};
        TradeItem = {
          'id': item._id,
          'account': this.activeAccount,
          'Artist': itemArtistName,
          'Item name': itemName,
        };

        if (itemCreated) {
          TradeItem['Item created'] = itemCreated;
        }

        if (itemTechnique) {
          TradeItem['Item technique'] = itemTechnique;
        }

        if (itemSize) {
          TradeItem['Item size'] = itemSize + ' cm';
        }

        if (this.getItemDiscountedPrice(item)) {
          const price = this.getItemDiscountedPrice(item);

          if (price) {
            TradeItem['Item price'] = (price !== null ? price : '') + ' ' + (item.price.currency ? item.price.currency : '');
          } else {
            TradeItem['Item price'] = 'Not set';
          }
        }

        if (itemImage) {
          TradeItem.Image = itemImage;
        }

        tradeObject.items.push(TradeItem);
      });
    }

    tradeObject.pricing = {
      total: this.getItemsTotalPrice(),
      discount: this.getDiscountedPrice(),
      grandTotal: this.getItemsDiscountedPrice(),
      vatTotal: this.getItemsVAT().toFixed(2),
      currency: this.tradingCurrency,
      fixedPrice: this.editTradeForm.get('editTradePrice').value
    };

    this.fileService.getPDF('Purchase contract', tradeObject);
  }

  searchArtwork() {
    /*this.isLoading = true;*/
    const searchObject = {
      searchArtworkAccountId: this.activeAccount,
      searchArtworkUserId: this.activeUser,
      searchArtworkTerm: this.searchArtworkForm.value.searchArtworkTerm
    };
    this.searchService.searchArtwork(searchObject).subscribe(
      res => {
        if (res.length) {
          if (this.searchArtworkResults) {
            this.searchArtworkResults.length = 0;
          }
          res.forEach(artwork => {
            artwork.allResultType = 'artwork';
            this.searchArtworkResults.push(artwork);
          });
          /*this.isLoading = false;*/
          this.searchResultsFound = true;
          document.getElementById('search-artwork-results-container').classList.add('active');

          UIkit.notification({
            message: 'Search matched ' + this.searchArtworkResults.length + ' results',
            status: 'success',
            pos: 'top-right',
            timeout: 1500
          });
        } else {
          if (this.searchArtworkResults) {
            this.searchArtworkResults.length = 0;
          }
          UIkit.notification({
            message: 'No search results',
            status: 'danger',
            pos: 'top-right',
            timeout: 1500
          });
        }
      },
      error => {
        if (this.searchArtworkResults) {
          this.searchArtworkResults.length = 0;
        }
        UIkit.notification({
          message: 'No search results',
          status: 'danger',
          pos: 'top-right',
          timeout: 1500
        });
      }
    );
  }

  emptySearch(): void {
    /*document.getElementById('search-artwork-results-container').classList.remove('active');
    let element: HTMLElement = document.getElementsByClassName('uk-navbar-toggle uk-close')[1] as HTMLElement;
    element.click();*/

    setTimeout(() => {
      this.searchResultsFound = false;
      if (this.searchArtworkResults) {
        this.searchArtworkResults.length = 0;
      }
      if (this.selectedArtworks) {
        this.selectedArtworks.length = 0;
      }
      this.searchArtworkForm.patchValue({ searchArtworkTerm: ''});
    }, 300);
  }

  selectTradeArtwork(artwork): void {
    this.selectedArtworks.indexOf(artwork) === -1 ?
      this.selectedArtworks.push(artwork) : this.selectedArtworks.splice(this.selectedArtworks.indexOf(artwork), 1);
  }

  addArtworksToTrade(): void {
    this.selectedArtworks.map((item: any) => {
      const itemFound = this.activeTrade.items.find((activeTradeItem: any) => {
        return activeTradeItem._id === item._id;
      });
      if (itemFound === undefined) {
        this.activeTrade.items.push(item);
      }
      const artistFound = this.artists.find((artist: any) => {
        return artist._id === item.artist;
      });
      if (artistFound === undefined) {
        this.artists.push({
          _id: item.artist,
          name: item.artistName,
          priceMultiplier: item.artistPriceMultiplier,
          status: item.artistStatus
        });
      }
    });

    this.emptySearch();
  }

  removeArtworkFromTrade(item): void {
    this.activeTrade.items.splice(this.activeTrade.items.indexOf(item), 1);
    const artistToSplice = this.artists.find((artist: any) => {
      return artist._id === item.artist;
    });
    this.artists.splice(this.artists.indexOf(artistToSplice), 1);
  }

  openSlideshow($event): void {
    $event.preventDefault();
    UIkit.lightboxPanel({
    /* Ei tarvitse, jos muistaa käyttää $event.preventDefaultia
      autoplay: true,
      autoplayInterval: 5000,
      delayControls: 1000,*/
      items: this.activeTrade.items.map(item => {
        let itemImage;
        if (item.coverImage) {
          itemImage = '/api/image/' + item.coverImage;
        } else if (item.images[0]) {
          itemImage = '/api/image/' + item.images[0].fileId;
        } else {
          itemImage = '/api/image/artbuddy-logo-placeholder.svg'
        }

        return {
          source: itemImage,
          caption: item.name
        }
      })
    }).show();
  }

  openArtist(artistId): void {
    this.router.navigateByUrl('/artist/' + artistId);
  }

  onAttachmentsUploadOutput(output: UploadOutput): void {
    const properFile = output.file && output.file.name && output.file.name.length > 0;

    if (output.type === 'allAddedToQueue') {
      const event: UploadInput = {
        type: 'uploadAll',
        url: '/api/file',
        method: 'POST',
        includeWebKitFormBoundary: true
      };
      this.uploadInput.emit(event);
    } else if (output.type === 'addedToQueue' && properFile) {
      if (this.filesToUpload.indexOf(output.file) == -1) {
        this.filesToUpload.push(output.file);
      }
    } else if (output.type === 'uploading' && properFile) {
      if (!this.activeTrade['attachments'].some(image => image.placeholderId === output.file.id)) {
        this.uploadLoading = true;
        this.activeService.increasePendingRequests();
        this.activeTrade['attachments'].push({ placeholderId: output.file.id });
        const index = this.filesToUpload.findIndex(file => file.id === output.file.id);
        this.filesToUpload[index] = output.file;
      }
    } else if (output.type === 'removed') {
      // remove file from array when removed
      this.filesToUpload = this.filesToUpload.filter((file: UploadFile) => file !== output.file);
    } else if (output.type === 'dragOver') {
      this.dragOver = true;
    } else if (output.type === 'dragOut') {
      this.dragOver = false;
    } else if (output.type === 'drop') {
      this.dragOver = false;
    } else if (output.type === 'removedAll') {
      this.filesToUpload.length = 0;
    } else if (output.type === 'done') {
      const fileObject = {
        itemId: this.activeTradeId,
        fileId: output.file.response,
        name: output.file.name,
        contentType: output.file.type
      };
      /*this.itemService.addItem(fileObject).subscribe(() => {*/
      this.removeFile(output.file.id);
      const indexToReplace = this.activeTrade['attachments'].findIndex(file => file.placeholderId === output.file.id);
      this.activeTrade['attachments'][indexToReplace] = fileObject;
      this.activeService.decreasePendingRequests();
      this.uploadLoading = false;

      /*this.activeTrade.images.push({
        /!*contentType: output.file.response.mimetype,
        filename: output.file.response.filename,*!/
        fileId: output.file.response.id
      });*/
      /*});*/
    }
  }

  startUpload(): void {
    const event: UploadInput = {
      type: 'uploadAll',
      url: '/api/file',
      method: 'POST',
      includeWebKitFormBoundary: true
    };
    this.uploadInput.emit(event);
  }

  cancelUpload(id: string): void {
    this.uploadInput.emit({ type: 'cancel', id: id });
  }

  removeFile(id: string): void {
    this.uploadInput.emit({ type: 'remove', id: id });
  }

  openAttachment(attachment) {
    this.itemService.openAttachment(this.activeAccount, attachment).then((response: any) => {
      if (response.url) {
        window.open(response.url);
      } else {
        UIkit.notification({
          message: 'Error fetching data',
          status: 'danger',
          pos: 'top-right',
          timeout: 1500
        });
      }
    });
  }



  removeAttachment(attachment) {
    const attachmentObject = {
      documentType: 'trade',
      itemId: this.activeTradeId,
      fileId: attachment.fileId
    };

    this.fileService.removeFile(attachmentObject).subscribe(
      res => {
        const attachmentToSplice = this.activeTrade.attachments.find((accountAttachment: any) => {
          return attachment.fileId === accountAttachment.fileId;
        });
        this.activeTrade.attachments.splice(this.activeTrade.attachments.indexOf(attachmentToSplice), 1);
        UIkit.notification({
          message: 'File removed successfully',
          status: 'success',
          pos: 'top-right',
          timeout: 1500
        });
      },
      error => {
        UIkit.notification({
          message: 'Removing failed',
          status: 'danger',
          pos: 'top-right',
          timeout: 1500
        });
      }
    );
  }

  openAdvancedSearch(): void {
    this.showAdvancedArtworkSearchModalWindow = true;
    setTimeout(() => {
      this.advancedArtworkSearchModalWindow = window.document.getElementById('advancedArtworkSearch');
      UIkit.modal(this.advancedArtworkSearchModalWindow).show();
    }, 500);

  }

  setArtworkSearchResults(searchResult: Array<any>) {
    searchResult.forEach(item => this.selectedArtworks.push(item));
    this.addArtworksToTrade();
  }

  createDate(inputDate: string): any {
    return {
      isRange: false,
      singleDate: {
        date: {
          year: new Date(inputDate).getFullYear(),
          month: new Date(inputDate).getMonth() + 1,
          day: new Date(inputDate).getDate()
        }
      }
    };
  }

  updateDate(fieldName: string, dateObject: any) {
    this.editTradeForm.controls[fieldName].patchValue(moment(dateObject.singleDate.jsDate).format());
  }

  getCharactersLimitError(fieldName: string) {
    if (!fieldName || !this.editTradeForm.get(fieldName).value ) {
      return ''
    }

    const charactersOverLimit: number = this.editTradeForm.get(fieldName).value.length - this.editTradeForm.get(fieldName).errors.maxlength.requiredLength;

    return `Field length is ${ charactersOverLimit } characters over limit`;
  }

  getCharactersLeft(fieldName: string, charactersLimit: number) {
    if (!fieldName || !this.editTradeForm.get(fieldName).value ) {
      return ''
    }

    const fieldLength: number = this.editTradeForm.get(fieldName).value.length;
    const charactersOverLimit: number = fieldLength - charactersLimit;

    if (charactersOverLimit >= 0) { return `${ charactersLimit } / ${ charactersLimit }` }

    return `${ fieldLength } / ${ charactersLimit }`;
  }
}
