import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { JwtHelperService } from '@auth0/angular-jwt';
import { UserService } from '../services/user.service';
import { ActiveService } from '../services/active.service';
import { AccountService } from '../services/account.service';
import { Subject } from 'rxjs';
declare var UIkit: any;

@Injectable()
export class AuthService {
  public loggedIn: boolean = false;
  public isAdmin: boolean = false;

  public loggedInStatus: Subject<boolean> = new Subject<boolean>();
  private jwtHelper = new JwtHelperService();
  currentUser = { _id: '', username: '', role: '' };

  constructor(
    private activeService: ActiveService,
    private userService: UserService,
    private accountService: AccountService,
    private router: Router
  ) {
    const token = localStorage.getItem('token');
    if (token) {
      const decodedUser = this.decodeUserFromToken(token);
      this.setCurrentUser(decodedUser);
    }
  }

  getLoggedInStatus(): boolean {
    return this.loggedIn;
  }

  login(emailAndPassword) {
    return this.userService.login(emailAndPassword).map(res => {
        localStorage.setItem('token', res.token);
        const decodedUser = this.decodeUserFromToken(res.token);
        this.setCurrentUser(decodedUser);
      }
    );
  }


  logout() {
    localStorage.removeItem('token');
    this.loggedIn = false;
    this.loggedInStatus.next(this.loggedIn);
    this.isAdmin = false;
    this.currentUser = { _id: '', username: '', role: '' };
    /*this.activeService.setActiveUser(null);*/
    this.router.navigate(['/']);
  }

  decodeUserFromToken(token) {
    return this.jwtHelper.decodeToken(token);
  }

  setCurrentUser(decodedUser) {
    this.loggedIn = true;
    this.currentUser._id = decodedUser.user._id;
    this.currentUser.username = decodedUser.user.email;
    this.currentUser.role = decodedUser.user.role ? decodedUser.user.role : 'employee';
    decodedUser.user.admin ? this.isAdmin = true : this.isAdmin = false;
    delete decodedUser.role;

    this.userService.getUser({ _id: decodedUser.user._id }).subscribe(user => {
      this.activeService.setActiveUser(user._id);
      if (user.latestAccount) {
        this.activeService.setActiveAccount(user.latestAccount);
        this.loggedIn = true;
        this.loggedInStatus.next(this.loggedIn);
        return this.loggedIn;
      }
      else if (user.account.length && user.account[user.account.length-1].accountId) {
        /* Aktivoidaan viimeinen */
        this.activeService.setActiveAccount(user.account[user.account.length-1].accountId);
        this.loggedIn = true;
        this.loggedInStatus.next(this.loggedIn);
        return this.loggedIn;
      } else {
        this.accountService.getUserAccounts({ _id: decodedUser.user._id }).subscribe(
          data => {
            if (data && data[0] && data[0]._id) {
              this.activeService.setActiveAccount(data[0]._id);
            }
          },
          () => {
            UIkit.notification({
              message: 'Error fetching user accounts',
              status: 'danger',
              pos: 'top-right',
              timeout: 1500
            });
            return false
          },
          () => {
            this.loggedIn = true;
            this.loggedInStatus.next(this.loggedIn);
            return this.loggedIn;
          });
      }
    });
  }

}
