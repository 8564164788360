<div id="advancedArtworkSearch" class="uk-modal-container" uk-modal="stack: true; bg-close: false; esc-close: false; cls-panel: 'uk-overflow-hidden'">
  <div class="uk-modal-dialog">
    <div class="uk-modal-header">
      <h2 class="uk-modal-title">Search artworks</h2>
    </div>
    <div class="uk-modal-body" >
      <div uk-grid>
        <div class="uk-width-expand@m">
          <div class="uk-height-1-1">
            <ais-instantsearch
              [config]="algolia">
              <ais-configure
                [searchParameters]="{
                  hitsPerPage: 10,
                  distinct: true,
                  filters: 'contentType:artwork'
                  }">
                </ais-configure>
              <div class="uk-width-1-1 search-form-wrapper uk-margin-bottom">
                <app-ais-menu-select attribute="contentType"
                                     title=""
                                     moreOptions="All ..."
                                     [hideToggleShowMore]="true"
                                     sortName="name"
                                     sortDescription="asc"
                                     [defaultValue]="defaultContentType"
                                     class="uk-margin-small-right uk-hidden"
                                     hidden>
                </app-ais-menu-select>
                <app-ais-search-box class="uk-width-1-1"></app-ais-search-box>
              </div>
              <div class="uk-width-1-1">
                <div class="uk-grid-collapse uk-height-1-1" uk-grid>
                  <div id="search-terms" class="uk-width-1-1 uk-width-1-3@s uk-width-1-4@m">
                    <form class="uk-form-stacked">
                      <h3 class="section-title uk-margin-small-bottom">Search criteria</h3>
                      <app-ais-menu-select attribute="name" title="Name" sortName="name"
                                           sortDescription="asc"></app-ais-menu-select>

                      <app-ais-menu-select attribute="lastName" title="Last name" sortName="lastName"
                                           sortDescription="asc"></app-ais-menu-select>
                      <!--<app-ais-menu-select attribute="artistName" title="Artist name" sortName="artistName" sortDescription="asc"></app-ais-menu-select>-->
                      <app-ais-refinement-list attribute="artistName" title="Artist name"
                                               sortName="artistName" sortDescription="asc"
                                               refinementAttribute="artistName"></app-ais-refinement-list>
                      <!--<ais-refinement-list attribute="artistName"></ais-refinement-list>-->
                      <app-ais-menu-select attribute="visibleName" title="Visible name"
                                           sortName="visibleName"
                                           sortDescription="asc"></app-ais-menu-select>
                      <app-ais-range-input-select attribute="width"
                                                  title="Width"></app-ais-range-input-select>
                      <app-ais-range-input-select attribute="height"
                                                  title="Height"></app-ais-range-input-select>
                      <app-ais-range-input-select attribute="depth"
                                                  title="Depth"></app-ais-range-input-select>
                      <app-ais-range-input-select attribute="frameWidth"
                                                  title="Frame width"></app-ais-range-input-select>
                      <app-ais-range-input-select attribute="frameHeight"
                                                  title="Frame height"></app-ais-range-input-select>
                      <app-ais-range-input-select attribute="frameDepth"
                                                  title="Frame depth"></app-ais-range-input-select>
                      <app-ais-menu-select attribute="technique" title="Technique"
                                           sortName="technique"
                                           sortDescription="asc"></app-ais-menu-select>
                      <app-ais-menu-select attribute="material" title="Material" sortName="material"
                                           sortDescription="asc"></app-ais-menu-select>
                      <app-ais-menu-select attribute="artistCode" title="Artist code"
                                           sortName="artistCode"
                                           sortDescription="asc"></app-ais-menu-select>
                      <app-ais-menu-select attribute="artistType" title="Artist type"
                                           sortName="artistType"
                                           sortDescription="asc"></app-ais-menu-select>
                      <app-ais-menu-select attribute="priceMultiplier" title="Price multiplier"
                                           sortName="priceMultiplier"
                                           sortDescription="asc"></app-ais-menu-select>
                      <app-ais-menu-select attribute="logistics" title="Logistics"
                                           sortName="logistics"
                                           sortDescription="asc"></app-ais-menu-select>
                      <app-ais-menu-select attribute="packaging" title="Packaging"
                                           sortName="packaging"
                                           sortDescription="asc"></app-ais-menu-select>
                      <!--<app-ais-menu-select attribute="availability" title="Availability" sortName="name"
                                           sortDescription="asc"></app-ais-menu-select>-->
                      <app-ais-refinement-list attribute="availability" title="Availability"
                                               sortName="name"
                                               sortDescription="asc"
                                               refinementAttribute="availability"></app-ais-refinement-list>
                      <app-ais-range-input-select attribute="price.price"
                                                  title="Price"></app-ais-range-input-select>
                      <app-ais-menu-select attribute="created" title="Created" sortName="created"
                                           sortDescription="asc"></app-ais-menu-select>
                      <app-ais-menu-select attribute="started" title="Started" sortName="started"
                                           sortDescription="asc"></app-ais-menu-select>
                      <app-ais-menu-select attribute="city" title="City" sortName="city"
                                           sortDescription="asc"></app-ais-menu-select>
                      <app-ais-menu-select attribute="location" title="Location" sortName="location"
                                           sortDescription="asc"></app-ais-menu-select>
                      <app-ais-menu-select attribute="estimateBasis" title="Estimate basis"
                                           sortName="estimateBasis"
                                           sortDescription="asc"></app-ais-menu-select>
                      <app-ais-menu-select attribute="clientCode"
                                           title="Client code"></app-ais-menu-select>
                      <app-ais-menu-select attribute="clientGroup"
                                           title="Client group"></app-ais-menu-select>
                    </form>
                  </div>
                  <div id="search-results" class="uk-width-2-3@s uk-width-3-4@m">
                    <div id="results-amount" class="uk-flex uk-flex-between uk-flex-middle uk-background-muted">
                      <div class="wrapper">
                        <h5 class="section-title uk-margin-small-bottom">Results count</h5>
                        <app-ais-stats></app-ais-stats>
                      </div>
                    </div>
                    <div id="search-criteria" class="uk-flex uk-flex-between uk-flex-middle">
                      <div class="criteria-wrapper">
                        <h5 class="section-title uk-margin-small-bottom">Active search criteria</h5>
                        <ais-current-refinements></ais-current-refinements>
                      </div>
                      <div class="button-container">
                        <app-ais-clear-refinements></app-ais-clear-refinements>
                      </div>
                    </div>
                    <div id="results-sorting" class="uk-flex uk-flex-left uk-flex-middle">
                      <div class="wrapper">
                        <h5 class="section-title uk-margin-small-bottom">Results sorting</h5>
                        <app-ais-sort-by [activeAccount]="activeAccount"></app-ais-sort-by>
                      </div>
                    </div>
                    <div id="results-items">
                      <h3 class="section-title uk-margin-small-bottom">Search results</h3>
                      <ais-hits>
                        <ng-template let-hits="hits" let-results="results">
                          <div *ngIf="filterHiddenDocuments(hits)" class="uk-container uk-padding-small">
                            No results found matching <strong>{{results?.query}}</strong>.
                          </div>
                          <table class="uk-table uk-table-middle uk-table-divider" [hidden]="!hits.length">
                            <thead>
                            <tr>
                              <th colspan="4">
                                <button
                                  (click)="selectAll(hits)"
                                  class="uk-button uk-button-default"
                                  type="button"
                                >
                                  Toggle selected
                                </button>
                              </th>
                            </tr>
                            <tr>
                              <th class="uk-table-shrink">Select</th>
                              <th class="uk-table-shrink">Image</th>
                              <th class="uk-width-expand">Name</th>
                              <!--<th class="uk-table-shrink"></th>-->
                            </tr>
                            </thead>
                            <tbody>
                            <tr *ngFor="let hit of hits; trackBy: trackByObjectID" class="hit" [hidden]="hit.hidden">
                              <td>
                                <ng-container *ngIf="['artwork'].includes(hit.contentType)">
                                  <input (change)="toggleSelect($event, hit)" [checked]="getSelected(hit._id)" class="uk-checkbox" type="checkbox" id="{{ 'toggleSelect-' + hit._id }}">
                                </ng-container>
                              </td>
                              <td>
                                <ng-container *ngIf="['artist', 'artwork'].includes(hit.contentType)">
                                  <!-- Näytetään coverImage jos on -->
                                  <div
                                    class="image-max-height uk-preserve-width uk-background-contain uk-background-muted uk-height-small uk-panel uk-flex uk-flex-center uk-flex-middle"
                                    width="60" [attr.data-src]="'/api/storagefile/' + activeAccount + '/' + hit.coverImage + '/medium'"
                                    uk-img *ngIf="hit.coverImage">

                                  </div>

                                  <!-- Jos ei ole coverImagea, niin sit otetaan eka image -->
                                  <div
                                    class="image-max-height uk-preserve-width uk-background-contain uk-background-muted uk-height-small uk-panel uk-flex uk-flex-center uk-flex-middle"
                                    width="60" [attr.data-src]="'/api/storagefile/' + activeAccount + '/' + hit.images[0].fileId + '/medium'"
                                    uk-img
                                    *ngIf="!hit.coverImage && hit.images && hit.images.length">

                                  </div>

                                  <!-- Jos ei ole coverImagea tai ekan imagea, ni sit otetaan placeholder -->
                                  <div
                                    class="image-max-height uk-preserve-width uk-background-contain uk-background-muted uk-height-small uk-panel uk-flex uk-flex-center uk-flex-middle"
                                    width="60" [attr.data-src]="'/api/image/artbuddy-logo-placeholder.svg'"
                                    uk-img *ngIf="!hit.coverImage && !hit.images.length">

                                  </div>
                                </ng-container>
                              </td>
                              <td>
                                <div (click)="toggleSelect(hit)">{{ hit.name }} {{ hit.lastName }}</div>
                                <div class="uk-text-muted">{{ hit.contentType | ucfirst }}</div>
                              </td>
                              <!--<td class="uk-text-right">
                                <button (click)="openAdvancedSearchResult(hit)" class="uk-button uk-button-default" type="button">Open
                                </button>
                              </td>-->
                            </tr>
                            </tbody>
                          </table>
                        </ng-template>
                      </ais-hits>
                    </div>
                    <div id="results-paging">
                      <ais-pagination></ais-pagination>
                    </div>
                  </div>
                </div>
              </div>
            </ais-instantsearch>
          </div>
        </div>
      </div>
    </div>
    <div class="uk-modal-footer">
      <div class="uk-text-right bottom-row">
        <div class="selected-count uk-text-muted uk-margin-small-right" *ngIf="selectedArtworks.length">
          <span>Selected {{ selectedArtworks.length }} </span>
          <span *ngIf="selectedArtworks.length === 1">item</span>
          <span *ngIf="selectedArtworks.length > 1">items</span>
        </div>
        <button class="uk-button uk-button-default uk-modal-close uk-margin-small-right" type="button" id="addNewCollectionCancel">Cancel</button>
        <button type="submit" class="uk-button uk-button-primary uk-modal-close" (click)="setParentSearchResults()">Add</button>
      </div>
    </div>
  </div>
</div>
