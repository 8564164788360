<div class="uk-section uk-section-default uk-padding-remove-vertical">

  <div class="uk-flex uk-flex-between uk-flex-middle">
    <app-sidenav contentType="Artists" [contentItem]="activeArtist.name + ' ' + activeArtist.lastName"></app-sidenav>
    <div class="uk-padding-small uk-padding-remove-vertical">
      <button class="uk-button uk-button-default uk-button-small uk-margin-small-right" type="button"
              (click)="openPreviousItem()" *ngIf="previousItems.length">Previous
      </button>
      <button class="uk-button uk-button-default uk-button-small" type="button" (click)="openNextItem()"
              *ngIf="nextItems.length">Next
      </button>
      <div class="uk-button-group uk-margin-small-left" *ngIf="previousItems.length || nextItems.length">
        <button class="uk-button uk-button-default" type="button"><span uk-icon="icon: chevron-down"></span></button>
        <div id="itemsDropdown" uk-dropdown="mode: click; boundary: ! .uk-button-group; boundary-align: true;">
          <ul class="uk-nav uk-dropdown-nav">
            <li *ngFor="let item of previousItems">
              <a (click)="openPreviousItem(item._id)">{{ item.name }}</a>
            </li>
            <li *ngFor="let item of nextItems">
              <a (click)="openNextItem(item._id)">{{ item.name }}</a>
            </li>
          </ul>
        </div>
      </div>
    </div>
  </div>

  <div class="uk-container-expand uk-height-expand">
    <ul uk-tab *ngIf="!isLoading">
      <li class="uk-active"><a href="#artistinfo"><h4>Basic information</h4></a></li>
      <li *ngIf="activeArtist.items.length"><a href="#artistartworks"><h4>Artworks</h4></a></li>
      <li *ngIf="activeArtist.exhibitions.length"><a href="#artistexhibitions"><h4>Exhibitions</h4></a></li>
      <li *ngIf="activeArtist.collections.length"><a href="#artistcollections"><h4>Collections</h4></a></li>
      <li *ngIf="activeArtist.bids.length"><a href="#artistbids"><h4>Proposals</h4></a></li>
      <li *ngIf="activeArtist.reservations.length"><a href="#artistreservations"><h4>Reservations</h4></a></li>
      <li *ngIf="activeArtist.trades.length"><a href="#artisttrades"><h4>Trades</h4></a></li>
      <li *ngIf="activeArtist.clients.length"><a href="#artistclients"><h4>Related clients</h4></a></li>
      <li *ngIf="opportunityClients.length"><a href="#artistopportunityClients"><h4>Interested clients</h4></a></li>
    </ul>
    <ul class="uk-switcher uk-card uk-card-body uk-padding-remove-top" *ngIf="!isLoading">
      <div id="artistinfo" class="uk-padding-small">
        <form appNoSubmitEnter [formGroup]="editArtistForm" (ngSubmit)="editArtist()" class="uk-grid-small" uk-grid>
          <input type="hidden" name="actorType" name="actorType" formControlName="actorType" [value]="'Artist'">
          <input type="hidden" name="editArtistId" name="editArtistId" formControlName="editArtistId"
                 [ngModel]="activeArtist._id">
          <input type="hidden" name="editArtistOwner" name="editArtistOwner" formControlName="editArtistOwner"
                 [ngModel]="activeArtist.owner">
          <div class="uk-width-1-2@s uk-width-1-3@m">
            <div class="uk-margin">
              <label class="uk-form-label" for="editArtistName">First name <span class="required">*</span></label>
              <div class="uk-form-controls">
                <input id="editArtistName" name="editArtistName" formControlName="editArtistName" type="text"
                       [ngModel]="activeArtist.name" placeholder="First name ..." class="uk-input"
                       [ngClass]="{'uk-form-danger': editArtistForm.get('editArtistName').touched && editArtistForm.get('editArtistName').errors }">
                <div class="uk-text-danger uk-text-small"
                     *ngIf="editArtistForm.get('editArtistName').hasError('maxlength')"
                >
                  {{ getCharactersLimitError('editArtistName') }}
                </div>
                <div class="uk-text-danger uk-text-small"
                     *ngIf="editArtistForm.get('editArtistName').hasError('pattern')"
                >
                  Field value is incorrect
                </div>
              </div>
            </div>
          </div>
          <div class="uk-width-1-2@s uk-width-1-3@m">
            <div class="uk-margin">
              <label class="uk-form-label" for="editArtistLastName">Last name <span class="required">*</span></label>
              <div class="uk-form-controls">
                <input id="editArtistLastName" name="editArtistLastName" formControlName="editArtistLastName"
                       type="text" [ngModel]="activeArtist.lastName" placeholder="Last name ..." class="uk-input"
                       [ngClass]="{'uk-form-danger': editArtistForm.get('editArtistLastName').touched && editArtistForm.get('editArtistLastName').errors }">
                <div class="uk-text-danger uk-text-small"
                     *ngIf="editArtistForm.get('editArtistLastName').hasError('maxlength')"
                >
                  {{ getCharactersLimitError('editArtistLastName') }}
                </div>
              </div>
            </div>
          </div>
          <div class="uk-width-1-2@s uk-width-1-3@m">
            <div class="uk-margin">
              <label class="uk-form-label" for="editArtistType">Type</label>
              <div class="uk-form-controls">
                <select id="editArtistType" name="editArtistType"
                        formControlName="editArtistType" [ngModel]="activeArtist.artistType" class="uk-select">
                  <option value="" disabled>Please select ...</option>
                  <option value="Represented">Represented</option>
                  <option value="Visiting">Visiting</option>
                </select>
              </div>
            </div>
          </div>
          <div class="uk-width-1-2@s uk-width-1-3@m">
            <div class="uk-margin">
              <label class="uk-form-label" for="editArtistCode">Artist code</label>
              <div class="uk-form-controls">
                <input id="editArtistCode" name="editArtistCode" formControlName="editArtistCode" type="text"
                       [ngModel]="activeArtist.artistCode" placeholder="Code ..." class="uk-input"
                       [ngClass]="{'uk-form-danger': editArtistForm.get('editArtistCode').touched && editArtistForm.get('editArtistCode').errors }">
                <div class="uk-text-danger uk-text-small"
                     *ngIf="editArtistForm.get('editArtistCode').hasError('maxlength')"
                >
                  {{ getCharactersLimitError('editArtistCode') }}
                </div>
              </div>
            </div>
          </div>
<!--          <div class="uk-width-1-2@s uk-width-1-3@m">-->
<!--            <div class="uk-margin">-->
<!--              <label class="uk-form-label" for="editArtistBirthday">Birthday</label>-->
<!--              <div class="uk-form-controls">-->
<!--                &lt;!&ndash; [ngClass]="{'uk-form-danger': editArtistForm.get('editArtistBirthday').touched && editArtistForm.get('editArtistBirthday').errors }" &ndash;&gt;-->
<!--                <input-->
<!--                  id="editArtistBirthday"-->
<!--                  name="editArtistBirthday"-->
<!--                  formControlName="editArtistBirthday"-->
<!--                  [ngModel]="activeArtist.birthday"-->
<!--                  type="text"-->
<!--                  placeholder="Birthday ..."-->
<!--                  class="uk-input"-->
<!--                  angular-mydatepicker-->
<!--                  [options]="myDatePickerOptions"-->
<!--                  #AngularMyDatePickerEditArtistBirthday="angular-mydatepicker"-->
<!--                  (click)="AngularMyDatePickerEditArtistBirthday.toggleCalendar()"-->
<!--                  (dateChanged)="updateDate('editArtistBirthday', $event)"-->
<!--                />-->
<!--                &lt;!&ndash;                <div class="uk-text-danger uk-text-small"&ndash;&gt;-->
<!--                &lt;!&ndash;                     *ngIf="editArtistForm.get('editArtistBirthday').hasError('maxlength')"&ndash;&gt;-->
<!--                &lt;!&ndash;                >&ndash;&gt;-->
<!--                &lt;!&ndash;                  {{ getCharactersLimitError('editArtistBirthday') }}&ndash;&gt;-->
<!--                &lt;!&ndash;                </div>&ndash;&gt;-->
<!--              </div>-->
<!--            </div>-->
<!--          </div>-->
          <div class="uk-width-1-2@s uk-width-1-3@m">
            <div class="uk-margin">
              <label class="uk-form-label" for="editArtistStreet">Street address</label>
              <div class="uk-form-controls">
                <input id="editArtistStreet" name="editArtistStreet" formControlName="editArtistStreet" type="text"
                       [ngModel]="activeArtist.street" placeholder="Street address ..." class="uk-input"
                       [ngClass]="{'uk-form-danger': editArtistForm.get('editArtistStreet').touched && editArtistForm.get('editArtistStreet').errors }">
                <div class="uk-text-danger uk-text-small"
                     *ngIf="editArtistForm.get('editArtistStreet').hasError('maxlength')"
                >
                  {{ getCharactersLimitError('editArtistStreet') }}
                </div>
              </div>
            </div>
          </div>
          <div class="uk-width-1-2@s uk-width-1-3@m">
            <div class="uk-margin">
              <label class="uk-form-label" for="editArtistZip">Postal code</label>
              <div class="uk-form-controls">
                <input id="editArtistZip" name="editArtistZip" formControlName="editArtistZip" type="text"
                       [ngModel]="activeArtist.zip" placeholder="Postal code ..." class="uk-input"
                       [ngClass]="{'uk-form-danger': editArtistForm.get('editArtistZip').touched && editArtistForm.get('editArtistZip').errors }">
                <div class="uk-text-danger uk-text-small"
                     *ngIf="editArtistForm.get('editArtistZip').hasError('maxlength')"
                >
                  {{ getCharactersLimitError('editArtistZip') }}
                </div>
                <div class="uk-text-danger uk-text-small"
                     *ngIf="editArtistForm.get('editArtistZip').hasError('pattern')"
                >
                  Field value is incorrect
                </div>
              </div>
            </div>
          </div>
          <div class="uk-width-1-2@s uk-width-1-3@m">
            <div class="uk-margin">
              <label class="uk-form-label" for="editArtistCity">City</label>
              <div class="uk-form-controls">
                <input id="editArtistCity" name="editArtistCity" formControlName="editArtistCity" type="text"
                       [ngModel]="activeArtist.city" placeholder="City ..." class="uk-input"
                       [ngClass]="{'uk-form-danger': editArtistForm.get('editArtistCity').touched && editArtistForm.get('editArtistCity').errors }">
                <div class="uk-text-danger uk-text-small"
                     *ngIf="editArtistForm.get('editArtistCity').hasError('maxlength')"
                >
                  {{ getCharactersLimitError('editArtistCity') }}
                </div>
              </div>
            </div>
          </div>
          <div class="uk-width-1-2@s uk-width-1-3@m">
            <div class="uk-margin">
              <label class="uk-form-label" for="editArtistState">State</label>
              <div class="uk-form-controls">
                <input id="editArtistState" name="editArtistState" formControlName="editArtistState" type="text"
                       [ngModel]="activeArtist.state" placeholder="State ..." class="uk-input"
                       [ngClass]="{'uk-form-danger': editArtistForm.get('editArtistState').touched && editArtistForm.get('editArtistState').errors }">
                <div class="uk-text-danger uk-text-small"
                     *ngIf="editArtistForm.get('editArtistState').hasError('maxlength')"
                >
                  {{ getCharactersLimitError('editArtistState') }}
                </div>
              </div>
            </div>
          </div>
          <div class="uk-width-1-2@s uk-width-1-3@m">
            <div class="uk-margin">
              <label class="uk-form-label" for="editArtistCountry">Country</label>
              <div class="uk-form-controls">
                <input id="editArtistCountry" name="editArtistCountry" formControlName="editArtistCountry" type="text"
                       [ngModel]="activeArtist.country" placeholder="Country ..." class="uk-input"
                       [ngClass]="{'uk-form-danger': editArtistForm.get('editArtistCountry').touched && editArtistForm.get('editArtistCountry').errors }">
                <div class="uk-text-danger uk-text-small"
                     *ngIf="editArtistForm.get('editArtistCountry').hasError('maxlength')"
                >
                  {{ getCharactersLimitError('editArtistCountry') }}
                </div>
              </div>
            </div>
          </div>
          <div class="uk-width-1-2@s uk-width-1-3@m">
            <div class="uk-margin">
              <label class="uk-form-label" for="editArtistPhone">Phone number</label>
              <div class="uk-form-controls">
                <input id="editArtistPhone" name="editArtistPhone" formControlName="editArtistPhone" type="text"
                       [ngModel]="activeArtist.phone" placeholder="Phone number ..." class="uk-input"
                       [ngClass]="{'uk-form-danger': editArtistForm.get('editArtistPhone').touched && editArtistForm.get('editArtistPhone').errors }">
                <div class="uk-text-danger uk-text-small"
                     *ngIf="editArtistForm.get('editArtistPhone').hasError('maxlength')"
                >
                  {{ getCharactersLimitError('editArtistPhone') }}
                </div>
                <div class="uk-text-danger uk-text-small"
                     *ngIf="editArtistForm.get('editArtistPhone').hasError('pattern')"
                >
                  Field value is incorrect
                </div>
              </div>
            </div>
          </div>
          <div class="uk-width-1-2@s uk-width-1-3@m">
            <div class="uk-margin">
              <label class="uk-form-label" for="editArtistEmail">Email</label>
              <div class="uk-form-controls">
                <input id="editArtistEmail" name="editArtistEmail" formControlName="editArtistEmail" type="text"
                       [ngModel]="activeArtist.email" placeholder="Email ..." class="uk-input"
                       [ngClass]="{'uk-form-danger': editArtistForm.get('editArtistEmail').touched && editArtistForm.get('editArtistEmail').errors }">
                <div class="uk-text-danger uk-text-small"
                     *ngIf="editArtistForm.get('editArtistEmail').hasError('maxlength')"
                >
                  {{ getCharactersLimitError('editArtistEmail') }}
                </div>
              </div>
            </div>
          </div>
          <div class="uk-width-1-2@s uk-width-1-3@m">
            <div class="uk-margin">
              <div class="uk-flex uk-flex-between uk-text-meta">
                <label class="uk-form-label" for="editArtistDescription">Description</label>
                <div class="uk-text-small">
                  {{ getCharactersLeft('editArtistDescription', 2048) }}
                </div>
              </div>
              <div class="uk-form-controls">
                <textarea id="editArtistDescription" name="editArtistDescription"
                          formControlName="editArtistDescription" rows="5" [ngModel]="activeArtist.description"
                          placeholder="Description ..." class="uk-textarea"
                          [ngClass]="{'uk-form-danger': editArtistForm.get('editArtistDescription').touched && editArtistForm.get('editArtistDescription').errors }"></textarea>
                <div class="uk-text-danger uk-text-small"
                     *ngIf="editArtistForm.get('editArtistDescription').hasError('maxlength')"
                >
                  {{ getCharactersLimitError('editArtistDescription') }}
                </div>
              </div>
            </div>
          </div>
          <div class="uk-width-1-2@s uk-width-1-3@m">
            <div class="uk-margin">
              <label class="uk-form-label" for="editArtistMultiplier">Price multiplier</label>
              <div class="uk-form-controls">
                <ng2-completer id="editArtistMultiplier" name="editArtistMultiplier"
                               formControlName="editArtistMultiplier" [(ngModel)]="activeArtist.priceMultiplier"
                               [datasource]="variables.priceMultipliers" [minSearchLength]="0"
                               [textNoResults]="null"></ng2-completer>
                <div class="uk-text-danger uk-text-small"
                     *ngIf="editArtistForm.get('editArtistMultiplier').hasError('maxlength')"
                >
                  {{ getCharactersLimitError('editArtistMultiplier') }}
                </div>
                <div class="uk-text-danger uk-text-small"
                     *ngIf="editArtistForm.get('editArtistMultiplier').hasError('pattern')"
                >
                  Field value is incorrect
                </div>
              </div>
            </div>
          </div>
          <div class="uk-width-1-1">
            <label class="uk-form-label">Artist images</label>
          </div>
          <div class="uk-width-expand@s uk-text-right">
            <button class="uk-button uk-button-default" type="button" (click)="openAddArtistImage()">Manage images
            </button>
          </div>
          <div class="uk-width-1-1">
            <div class="uk-position-relative uk-visible-toggle" uk-slider>
              <ul class="uk-slider-items uk-child-width-1-2@s uk-child-width-1-3@m uk-grid">
                <li class="uk-text-center" *ngFor="let image of activeArtist.images; let index = index">
                  <div class="uk-inline-clip uk-transition-toggle uk-width-1-1" tabindex="index">
                    <div
                      class="uk-background-contain uk-background-muted uk-height-medium uk-panel uk-flex uk-flex-center uk-flex-middle"
                      [attr.data-src]="'/api/storagefile/' + activeAccount + '/' + image.fileId + '/medium'" uk-img>
                    </div>
                    <div class="uk-position-top-right uk-overlay uk-overlay-default uk-padding-small"
                         *ngIf="image.fileId == activeArtist.coverImage">Cover image
                    </div>
                    <div class="uk-transition-slide-bottom uk-position-bottom uk-overlay uk-overlay-default">
                      <button type="button" (click)="selectCover(image.fileId)" class="uk-button uk-button-text"
                              *ngIf="image.fileId !== activeArtist.coverImage">Select cover
                      </button>
                      <button type="button" (click)="removeImage(image)" class="uk-button uk-button-text uk-margin-left"
                              *ngIf="image.fileId !== activeArtist.coverImage">Remove
                      </button>
                      <button type="button" (click)="openImage(image)" class="uk-button uk-button-text uk-margin-left">
                        Download
                      </button>
                    </div>
                  </div>
                </li>
              </ul>
              <a class="uk-position-center-left uk-position-small uk-hidden-hover" uk-slidenav-previous
                 uk-slider-item="previous"></a>
              <a class="uk-position-center-right uk-position-small uk-hidden-hover" uk-slidenav-next
                 uk-slider-item="next"></a>
            </div>
          </div>
          <div id="addArtistImages" class="uk-modal-container" uk-modal="bg-close: false; esc-close: false;">
            <div class="uk-modal-dialog">
              <div class="uk-modal-header">
                <h2 class="uk-modal-title">Manage artist images</h2>
              </div>
              <div class="uk-modal-body">
                <div class="uk-width-1-1">
                  <div class="uk-position-relative uk-visible-toggle" uk-slider>
                    <ul class="uk-slider-items uk-child-width-1-2@s uk-child-width-1-3@m uk-grid">
                      <li class="uk-text-center" *ngFor="let image of activeArtist.images; let index = index">
                        <div class="uk-inline-clip uk-transition-toggle uk-width-1-1" tabindex="index">
                          <div
                            class="uk-background-contain uk-height-medium uk-panel uk-flex uk-flex-center uk-flex-middle"
                            [attr.data-src]="'/api/storagefile/' + activeAccount + '/' + image.fileId + '/medium'"
                            uk-img>
                          </div>
                          <div class="uk-position-top-right uk-overlay uk-overlay-default uk-padding-small"
                               *ngIf="image.fileId == activeArtist.coverImage">Cover image
                          </div>
                          <div class="uk-transition-slide-bottom uk-position-bottom uk-overlay uk-overlay-default">
                            <button type="button" (click)="selectCover(image.fileId)" class="uk-button uk-button-text"
                                    *ngIf="image.fileId !== activeArtist.coverImage">Select cover
                            </button>
                            <button type="button" (click)="removeImage(image)"
                                    class="uk-button uk-button-text uk-margin-left"
                                    *ngIf="image.fileId !== activeArtist.coverImage">Remove
                            </button>
                            <button type="button" (click)="openImage(image)"
                                    class="uk-button uk-button-text uk-margin-left">Download
                            </button>
                          </div>
                        </div>
                      </li>
                    </ul>
                    <a class="uk-position-center-left uk-position-small uk-hidden-hover" uk-slidenav-previous
                       uk-slider-item="previous"></a>
                    <a class="uk-position-center-right uk-position-small uk-hidden-hover" uk-slidenav-next
                       uk-slider-item="next"></a>
                  </div>
                </div>
                <div class="uk-width-1-1">
                  <div class="uk-margin">
                    <label class="uk-form-label">Manage artist images</label>
                    <p *ngIf="!activeArtist.images.length">No artist images</p>
                    <div class="uk-form-controls">
                      <div class="drop-container" ngFileDrop [options]="options" (uploadOutput)="onUploadOutput($event)"
                           (click)="imageFile.click()" [uploadInput]="uploadInput">
                        <div class="js-upload uk-placeholder uk-text-center" [ngClass]="{ 'is-drop-over': dragOver }">
                          <span class="uk-text-middle">Add images by dropping them here or open file dialog by clicking here.<br>Allowed filetypes include JPG, PNG and GIF.<br>Maximum allowed file size is 5 Mb.</span>
                        </div>
                        <input #imageFile type="file" ngFileSelect
                               [options]="{ concurrency: 1, allowedContentTypes: ['image/jpeg', 'image/jpg', 'image/png', 'image/gif'], maxFileSize: 5000000 }"
                               [uploadInput]="uploadInput" (uploadOutput)="onUploadOutput($event)" multiple
                               class="file-input">
                      </div>
                    </div>
                    <!--                    <progress id="js-progressbar" class="uk-progress" value="0" max="100" hidden></progress>-->
                    <!--                    <div class="table-container" *ngIf="filesToUpload.length">-->
                    <!--                      <table class="uk-table uk-table-middle uk-margin-top">-->
                    <!--                        <caption>Files to upload</caption>-->
                    <!--                        <thead>-->
                    <!--                        <tr>-->
                    <!--                          <th class="uk-table-shrink uk-text-capitalize">Image</th>-->
                    <!--                          <th class="uk-width-expand uk-text-capitalize">Name</th>-->
                    <!--                          <th class="uk-width-expand uk-text-capitalize">Size</th>-->
                    <!--                          <th class="uk-table-shrink uk-text-capitalize"></th>-->
                    <!--                        </tr>-->
                    <!--                        </thead>-->
                    <!--                        <tbody>-->
                    <!--                        <tr *ngFor="let file of filesToUpload; let i = index;">-->
                    <!--                          <td class="uk-background-muted"><img class="uk-preserve-width uk-border-circle" src="/api/image/artbuddy-logo-placeholder.svg" width="60" uk-img></td>-->
                    <!--                          <td>{{ file.name }}</td>-->
                    <!--                          <td class="uk-text-nowrap">{{ file.size | bytes: 2 }}</td>-->
                    <!--                          <td><button (click)="removeFile(file.id)" class="uk-button uk-button-default" type="button">Delete</button></td>-->
                    <!--                        </tr>-->
                    <!--                        </tbody>-->
                    <!--                      </table>-->
                    <!--                      <button type="button" (click)="startUpload()" class="uk-button uk-button-primary">Start upload</button>-->
                    <!--                    </div>-->
                  </div>
                </div>
              </div>
              <div class="uk-modal-footer">
                <p class="uk-text-right">
                  <button type="button" class="uk-button uk-button-primary uk-modal-close">Close</button>
                </p>
              </div>
            </div>
          </div>
          <div class="uk-width-1-1 uk-margin-medium-top">
            <h3 class="uk-margin-remove-bottom">Additional information</h3>
            <hr class="uk-margin-small-top">
          </div>
          <div class="uk-width-1-1@s">
            <div class="uk-margin">
              <label class="uk-form-label">Attachments</label>
              <div class="uk-form-controls uk-margin-small">
                <dl class="uk-description-list" *ngIf="activeArtist.attachments.length">
                  <div class="uk-flex uk-flex-middle" *ngFor="let attachment of activeArtist.attachments">
                    <div class="uk-padding-small">
                      <img class="contenttype" width="40" height="40"
                           src="/api/storagefile/{{ activeAccount }}/{{ attachment.fileId }}/small"
                           *ngIf="['image/png', 'image/jpg', 'image/jpeg', 'image/heic', 'image/gif', 'image/svg+xml'].includes(attachment.contentType)"/>
                      <img class="contenttype" width="40" height="40" src="/api/file/5a5de6b1229e86403d67db11"
                           *ngIf="['application/msword', 'application/vnd.openxmlformats-officedocument.wordprocessingml.document'].includes(attachment.contentType)"/>
                      <img class="contenttype" width="40" height="40" src="/api/file/5a5de6b1229e86403d67db10"
                           *ngIf="['application/octet-stream', 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'].includes(attachment.contentType)"/>
                      <img class="contenttype" width="40" height="40" src="/api/file/5a5de6b1229e86403d67db12"
                           *ngIf="['application/pdf'].includes(attachment.contentType)"/>
                      <img class="contenttype" width="40" height="40" src="/api/file/5a5de7bc229e86403d67db1b"
                           *ngIf="['text/plain'].includes(attachment.contentType)"/>
                      <img class="contenttype" width="40" height="40" src="/api/file/5a5de6b1229e86403d67db0f"
                           *ngIf="!(['image/png', 'image/jpg', 'image/jpeg', 'image/heic', 'image/gif', 'image/svg+xml', 'application/msword', 'application/vnd.openxmlformats-officedocument.wordprocessingml.document', 'application/octet-stream', 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet', 'application/pdf', 'text/plain'].includes(attachment.contentType))"/>
                    </div>
                    <div class="uk-width-expand">{{ attachment.name }}</div>
                    <div class="uk-button-group">
                      <button (click)="openAttachment(attachment)" class="uk-button uk-button-default" type="button">
                        Open
                      </button>
                      <div class="uk-inline uk-text-right">
                        <button class="uk-button uk-button-default" type="button"><span
                          uk-icon="icon:  chevron-down"></span></button>
                        <div uk-dropdown="mode: click; boundary: ! .uk-button-group; boundary-align: true;">
                          <ul class="uk-nav uk-dropdown-nav">
                            <li><a (click)="removeAttachment(attachment)">Remove</a></li>
                          </ul>
                        </div>
                      </div>
                    </div>
                  </div>
                </dl>
                <p *ngIf="!activeArtist.attachments.length">No related attachments</p>
              </div>
            </div>
          </div>
          <div class="uk-width-1-1@s">
            <div class="uk-margin">
              <label class="uk-form-label">Add new attachments</label>
              <div class="uk-form-controls">
                <div class="drop-container" ngFileDrop [options]="options"
                     (uploadOutput)="onAttachmentsUploadOutput($event)" (click)="attachmentFile.click()"
                     [uploadInput]="uploadInput">
                  <div class="js-upload uk-placeholder uk-text-center" [ngClass]="{ 'is-drop-over': dragOver }">
                    <span
                      class="uk-text-middle">Add files by dropping them here or open file dialog by clicking here.<br>Maximum allowed file size is 5 Mb.</span>
                  </div>
                  <input #attachmentFile type="file" ngFileSelect [options]="{ concurrency: 1, maxFileSize: 5000000 }"
                         [uploadInput]="uploadInput" (uploadOutput)="onAttachmentsUploadOutput($event)" multiple
                         class="file-input">
                </div>
              </div>
              <!--              <progress id="js-progressbar" class="uk-progress" value="0" max="100" hidden></progress>-->
              <!--              <div class="table-container" *ngIf="filesToUpload.length">-->
              <!--                <table class="uk-table uk-table-middle uk-margin-top">-->
              <!--                  <caption>Files to upload</caption>-->
              <!--                  <thead>-->
              <!--                  <tr>-->
              <!--                    <th class="uk-table-shrink uk-text-capitalize">Image</th>-->
              <!--                    <th class="uk-width-expand uk-text-capitalize">Name</th>-->
              <!--                    <th class="uk-width-expand uk-text-capitalize">Size</th>-->
              <!--                    <th class="uk-table-shrink uk-text-capitalize"></th>-->
              <!--                  </tr>-->
              <!--                  </thead>-->
              <!--                  <tbody>-->
              <!--                  <tr *ngFor="let file of filesToUpload; let i = index;">-->
              <!--                    <td class="uk-background-muted"><img class="uk-preserve-width uk-border-circle" src="/api/image/artbuddy-logo-placeholder.svg" width="60" uk-img></td>-->
              <!--                    <td>{{ file.name }}</td>-->
              <!--                    <td class="uk-text-nowrap">{{ file.size | bytes: 2 }}</td>-->
              <!--                    <td><button (click)="removeFile(file.id)" class="uk-button uk-button-default" type="button">Remove</button></td>-->
              <!--                  </tr>-->
              <!--                  </tbody>-->
              <!--                </table>-->
              <!--                <button type="button" (click)="startUpload()" class="uk-button uk-button-primary">Start upload</button>-->
              <!--              </div>-->
            </div>
          </div>
          <div class="uk-width-1-2@s uk-width-1-3@m">
            <div class="uk-margin">
              <div class="uk-flex uk-flex-between uk-text-meta">
                <label class="uk-form-label" for="editArtistNotes">Other information</label>
                <div class="uk-text-small">
                  {{ getCharactersLeft('editArtistNotes', 2048) }}
                </div>
              </div>
              <div class="uk-form-controls">
                <textarea id="editArtistNotes" name="editArtistNotes" formControlName="editArtistNotes" rows="5"
                          [ngModel]="activeArtist.notes" placeholder="Notes ..." class="uk-textarea"
                          [ngClass]="{'uk-form-danger': editArtistForm.get('editArtistNotes').touched && editArtistForm.get('editArtistNotes').errors }"></textarea>
              </div>
            </div>
          </div>
          <div class="uk-width-1-2@s uk-width-1-3@m">
            <div class="uk-margin">
              <div class="uk-flex uk-flex-between uk-text-meta">
                <label class="uk-form-label" for="editArtistComments">Comments</label>
                <div class="uk-text-small">
                  {{ getCharactersLeft('editArtistComments', 2048) }}
                </div>
              </div>
              <div class="uk-form-controls">
                <textarea id="editArtistComments" name="editArtistComments" formControlName="editArtistComments"
                          [ngModel]="activeArtist.comments" rows="5" type="text" placeholder="Comments ..."
                          class="uk-textarea"
                          [ngClass]="{'uk-form-danger': editArtistForm.get('editArtistComments').touched && editArtistForm.get('editArtistComments').errors }"></textarea>
              </div>
            </div>
          </div>
          <div class="uk-width-1-2@s uk-width-1-3@m">
            <div class="uk-margin">
              <label class="uk-form-label" for="editArtistTags">Tags</label>
              <div class="uk-form-controls">
                <input id="editArtistTags" name="editArtistTags" formControlName="editArtistTags"
                       [ngModel]="activeArtist.tags" type="text" placeholder="Tags ..." class="uk-input"
                       [ngClass]="{'uk-form-danger': editArtistForm.get('editArtistTags').touched && editArtistForm.get('editArtistTags').errors }">
              </div>
            </div>
          </div>
          <div class="uk-width-1-2@s uk-width-1-3@m">
            <div class="uk-margin">
              <label class="uk-form-label" for="editArtistStatus">Status</label>
              <div class="uk-form-controls">
                <div class="uk-form-controls">
                  <label><input id="editArtistStatus" name="editArtistStatus" formControlName="editArtistStatus"
                                [ngModel]="activeArtist.status" class="uk-checkbox" type="checkbox"> Active</label>
                </div>
              </div>
            </div>
          </div>
          <div class="uk-width-1-2@s uk-width-1-3@m">
            <div class="uk-margin">
              <label class="uk-form-label" for="editArtistVisibility">Visibility</label>
              <div class="uk-form-controls">
                <label><input id="editArtistVisibility" name="editArtistVisibility"
                              formControlName="editArtistVisibility" [ngModel]="activeArtist.visibility"
                              class="uk-checkbox" type="checkbox"> Public</label>
              </div>
            </div>
          </div>
          <div class="uk-width-1-1 uk-margin-medium-top">
            <hr class="uk-margin-small-top">
          </div>
          <div class="uk-width-1-1 uk-margin-remove sticky-bottom uk-background-default" uk-sticky="bottom: true">
            <div class="uk-width-1-1">
              <div class="uk-flex uk-flex-middle uk-flex-between uk-margin">
                <app-editing-history
                  [updatedAt]="activeArtist.updatedAt"
                  [name]="activeArtist.requestUser?.name"
                  [email]="activeArtist.requestUser?.email"
                ></app-editing-history>
                <div class="uk-text-right">
                  <button type="button" class="uk-button uk-button-default uk-margin-small-right"
                          [disabled]="!editArtistForm.valid" (click)="getCSV()">Get CSV
                  </button>
                  <button type="submit" class="uk-button uk-button-primary"
                          [disabled]="!editArtistForm.valid || uploadLoading">Save
                  </button>
                </div>
              </div>
            </div>
          </div>
        </form>
      </div>
      <div id="artistartworks" *ngIf="activeArtist.items.length" class="uk-padding-small">
        <table class="uk-table uk-table-middle uk-table-divider">
          <thead>
          <tr>
            <th class="uk-table-shrink">Image</th>
            <th class="uk-width-expand">Name</th>
            <th class="uk-table-shrink uk-text-nowrap">Technique</th>
            <th class="uk-table-shrink uk-text-nowrap">Availability</th>
            <th class="uk-table-shrink uk-text-nowrap">Visibility</th>
            <th class="uk-table-shrink"></th>
          </tr>
          </thead>
          <tbody>
          <tr *ngFor="let artwork of activeArtist.items">
            <td>
              <!-- Näytetään coverImage jos on -->
              <div
                class="image-max-height uk-preserve-width uk-background-contain uk-background-muted uk-height-small uk-panel uk-flex uk-flex-center uk-flex-middle"
                width="60" [attr.data-src]="'/api/storagefile/' + activeAccount + '/' + artwork.coverImage + '/small'"
                uk-img *ngIf="artwork.coverImage">

              </div>

              <!-- Jos ei ole coverImagea, niin sit otetaan eka image -->
              <div
                class="image-max-height uk-preserve-width uk-background-contain uk-background-muted uk-height-small uk-panel uk-flex uk-flex-center uk-flex-middle"
                width="60"
                [attr.data-src]="'/api/storagefile/' + activeAccount + '/' + artwork.images[0].fileId + '/small'" uk-img
                *ngIf="!artwork.coverImage && artwork.images && artwork.images.length">

              </div>

              <!-- Jos ei ole coverImagea tai ekan imagea, ni sit otetaan placeholder -->
              <div
                class="image-max-height uk-preserve-width uk-background-contain uk-background-muted uk-height-small uk-panel uk-flex uk-flex-center uk-flex-middle"
                width="60" [attr.data-src]="'/api/image/artbuddy-logo-placeholder.svg'" uk-img
                *ngIf="!artwork.coverImage && !artwork.images.length">
              </div>
            </td>
            <td>{{ artwork.name }}</td>
            <td>{{ artwork.technique }}</td>
            <td>{{ artwork.availability }}</td>
            <td>
              <span *ngIf="artwork.visibility">Visible</span>
              <span *ngIf="!artwork.visibility">Hidden</span>
            </td>
            <td class="uk-text-right">
              <div class="uk-button-group">
                <button (click)="openArtwork(artwork._id)" class="uk-button uk-button-default" type="button">Open
                </button>
                <div class="uk-inline" class="uk-visible@m">
                  <button class="uk-button uk-button-default" type="button"><span uk-icon="icon:  chevron-down"></span>
                  </button>
                  <div uk-dropdown="mode: click; boundary: ! .uk-button-group; boundary-align: true;">
                    <ul class="uk-nav uk-dropdown-nav">
                      <li><a (click)="removeArtwork(artwork._id)">Remove</a></li>
                    </ul>
                  </div>
                </div>
              </div>
            </td>
          </tr>
          </tbody>
        </table>
      </div>
      <div id="artistexhibitions" *ngIf="activeArtist.exhibitions.length" class="uk-padding-small">
        <table class="uk-table uk-table-middle uk-table-divider">
          <thead>
          <tr>
            <th class="uk-table-shrink">Name</th>
            <th class="uk-width-expand">Type</th>
            <th class="uk-table-shrink uk-text-nowrap">Start date</th>
            <th class="uk-table-shrink uk-text-nowrap">Location</th>
            <th class="uk-table-shrink"></th>
          </tr>
          </thead>
          <tbody>
          <tr *ngFor="let exhibition of activeArtist.exhibitions">
            <td>{{ exhibition.name }}</td>
            <td class="uk-visible@m">{{ exhibition.type }}</td>
            <td class="uk-visible@m">{{ exhibition.startDate | date: 'd.M.yyyy' }}</td>
            <td class="uk-visible@m">{{ exhibition.location }}</td>
            <td class="uk-text-right">
              <button (click)="openExhibition(exhibition._id)" class="uk-button uk-button-default" type="button">Open
              </button>
            </td>
          </tr>
          </tbody>
        </table>
      </div>
      <div id="artistcollections" *ngIf="activeArtist.collections.length" class="uk-padding-small">
        <table class="uk-table uk-table-middle uk-table-divider">
          <thead>
          <tr>
            <th class="uk-table-shrink">Name</th>
            <th class="uk-width-expand">Type</th>
            <th class="uk-table-shrink uk-text-nowrap">Location</th>
            <th class="uk-table-shrink"></th>
          </tr>
          </thead>
          <tbody>
          <tr *ngFor="let collection of activeArtist.collections">
            <td>{{ collection.name }}</td>
            <td class="uk-visible@m">{{ collection.type }}</td>
            <td class="uk-visible@m">{{ collection.location }}</td>
            <td class="uk-text-right">
              <button (click)="openCollection(collection._id)" class="uk-button uk-button-default" type="button">Open
              </button>
            </td>
          </tr>
          </tbody>
        </table>
      </div>
      <div id="artistbids" *ngIf="activeArtist.bids.length" class="uk-padding-small">
        <div class="uk-width-expand@m">
          <div class="uk-padding-small uk-padding-remove-top" *ngIf="!isLoading">
            <div class="uk-width-1-1 uk-text-right uk-margin-small-bottom uk-padding-remove">
              <button class="uk-button uk-button-default" type="button" (click)="getCSVforAllBids()">Get CSV</button>
            </div>
          </div>
        </div>
        <table class="uk-table uk-table-middle uk-table-divider">
          <thead>
          <tr>
            <th class="uk-table-shrink">Number</th>
            <th class="uk-width-expand">Title</th>
            <th class="uk-width-expand">Customer</th>
            <th class="uk-table-shrink uk-text-nowrap">Type</th>
            <th class="uk-table-shrink uk-text-nowrap">Price</th>
            <th class="uk-table-shrink uk-text-nowrap">Valid</th>
            <th class="uk-table-shrink"></th>
          </tr>
          </thead>
          <tbody>
          <tr *ngFor="let bid of activeArtist.bids">
            <td>{{ bid.sequence }}</td>
            <td>{{ bid.name }}</td>
            <td class="uk-visible@m">{{ bid.customer.name }} {{ bid.customer.lastName }}</td>
            <td class="uk-visible@m">{{ bid.type }}</td>
            <td class="uk-visible@m">{{ bid.price }} {{ bid.currency }}</td>
            <td class="uk-visible@m">{{ bid.valid | date: 'dd.MM.yyyy' }}</td>
            <td class="uk-text-right">
              <div class="uk-button-group">
                <button (click)="openBid(bid._id)" class="uk-button uk-button-default" type="button">Open</button>
                <div class="uk-inline" class="uk-visible@m">
                  <button class="uk-button uk-button-default" type="button"><span uk-icon="icon:  chevron-down"></span>
                  </button>
                  <div uk-dropdown="mode: click; boundary: ! .uk-button-group; boundary-align: true;">
                    <ul class="uk-nav uk-dropdown-nav">
                      <li><a (click)="getCSVforBid(bid)">Get CSV</a></li>
                    </ul>
                  </div>
                </div>
              </div>
            </td>
          </tr>
          </tbody>
        </table>
      </div>
      <div id="artistreservations" *ngIf="activeArtist.reservations.length" class="uk-padding-small">
        <div class="uk-width-expand@m">
          <div class="uk-padding-small uk-padding-remove-top" *ngIf="!isLoading">
            <div class="uk-width-1-1 uk-text-right uk-margin-small-bottom uk-padding-remove">
              <button class="uk-button uk-button-default" type="button" (click)="getCSVforAllReservations()">Get CSV
              </button>
            </div>
          </div>
        </div>
        <table class="uk-table uk-table-middle uk-table-divider">
          <thead>
          <tr>
            <th class="uk-table-shrink">Number</th>
            <th class="uk-width-expand">Title</th>
            <th class="uk-width-expand">Customer</th>
            <th class="uk-table-shrink uk-text-nowrap">Type</th>
            <th class="uk-table-shrink uk-text-nowrap">Price</th>
            <th class="uk-table-shrink uk-text-nowrap">Valid</th>
            <th class="uk-table-shrink"></th>
          </tr>
          </thead>
          <tbody>
          <tr *ngFor="let reservation of activeArtist.reservations">
            <td>{{ reservation.sequence }}</td>
            <td>{{ reservation.name }}</td>
            <td class="uk-visible@m">{{ reservation.customer.fullName }}</td>
            <td class="uk-visible@m">{{ reservation.type }}</td>
            <td class="uk-visible@m">{{ reservation.price }} {{ reservation.currency }}</td>
            <td class="uk-visible@m">{{ reservation.valid | date: 'dd.MM.yyyy' }}</td>
            <td class="uk-text-right">
              <div class="uk-button-group">
                <button (click)="openReservation(reservation._id)" class="uk-button uk-button-default" type="button">
                  Open
                </button>
                <div class="uk-inline" class="uk-visible@m">
                  <button class="uk-button uk-button-default" type="button"><span uk-icon="icon:  chevron-down"></span>
                  </button>
                  <div uk-dropdown="mode: click; boundary: ! .uk-button-group; boundary-align: true;">
                    <ul class="uk-nav uk-dropdown-nav">
                      <li><a (click)="getCSVforReservation(reservation)">Get CSV</a></li>
                    </ul>
                  </div>
                </div>
              </div>
            </td>
          </tr>
          </tbody>
        </table>
      </div>
      <div id="artisttrades" *ngIf="activeArtist.trades.length" class="uk-padding-small">
        <div class="uk-width-expand@m">
          <div class="uk-padding-small uk-padding-remove-top" *ngIf="!isLoading">
            <div class="uk-width-1-1 uk-text-right uk-margin-small-bottom uk-padding-remove">
              <button class="uk-button uk-button-default" type="button" (click)="getCSVforAllTrades()">Get CSV</button>
            </div>
          </div>
        </div>
        <table class="uk-table uk-table-middle uk-table-divider">
          <thead>
          <tr>
            <th class="uk-table-shrink">Number</th>
            <th class="uk-width-expand">Title</th>
            <th class="uk-width-expand">Customer</th>
            <th class="uk-table-shrink uk-text-nowrap">Type</th>
            <th class="uk-table-shrink uk-text-nowrap">Price</th>
            <th class="uk-table-shrink"></th>
          </tr>
          </thead>
          <tbody>
          <tr *ngFor="let trade of activeArtist.trades">
            <td>{{ trade.sequence }}</td>
            <td>{{ trade.name }}</td>
            <td class="uk-visible@m">{{ trade.seller.name }}</td>
            <td class="uk-visible@m">{{ trade.type }}</td>
            <td class="uk-visible@m">{{ trade.price }} {{ trade.currency }}</td>
            <td class="uk-text-right">
              <div class="uk-button-group">
                <button (click)="openTrade(trade._id)" class="uk-button uk-button-default" type="button">Open</button>
                <div class="uk-inline" class="uk-visible@m">
                  <button class="uk-button uk-button-default" type="button"><span uk-icon="icon:  chevron-down"></span>
                  </button>
                  <div uk-dropdown="mode: click; boundary: ! .uk-button-group; boundary-align: true;">
                    <ul class="uk-nav uk-dropdown-nav">
                      <li><a (click)="getCSVforTrade(trade)">Get CSV</a></li>
                    </ul>
                  </div>
                </div>
              </div>
            </td>
          </tr>
          </tbody>
        </table>
      </div>
      <div id="artistclients" *ngIf="activeArtist.clients.length" class="uk-padding-small">
        <table class="uk-table uk-table-middle uk-table-divider">
          <thead>
          <tr>
            <th class="uk-table-expand">Name</th>
            <th class="uk-table-small">Client group</th>
            <th class="uk-table-small">Status</th>
            <th class="uk-table-shrink"></th>
          </tr>
          </thead>
          <tbody>
          <tr *ngFor="let client of activeArtist.clients">
            <td>{{ client.name }} {{ client.lastName }}</td>
            <td>
              <div *ngIf="isArray(client.clientGroup); else clientGroupIsNotArray">
                <div *ngFor="let clientGroup of client.clientGroup">{{ clientGroup.title }}</div>
              </div>
              <ng-template #clientGroupIsNotArray>
                <div>{{ client.clientGroup }}</div>
              </ng-template>
            </td>
            <td>{{ client.status }}</td>
            <td class="uk-text-right">
              <button (click)="openClient(client._id)" class="uk-button uk-button-default" type="button">Open</button>
            </td>
          </tr>
          </tbody>
        </table>
      </div>
      <div id="artistopportunityClients" *ngIf="opportunityClients.length" class="uk-padding-small">
        <div class="uk-width-expand@m">
          <div class="uk-padding-small uk-padding-remove-top" *ngIf="!isLoading">
            <div class="uk-width-1-1 uk-text-right uk-margin-small-bottom uk-padding-remove">
              <div class="uk-button-group">
                <button class="uk-button uk-button-default" type="button" (click)="getCSVforAllOpportunities()">Get
                  CSV
                </button>
                <div class="uk-inline" class="uk-visible@m">
                  <button class="uk-button uk-button-default" type="button"><span uk-icon="icon:  chevron-down"></span>
                  </button>
                  <div uk-dropdown="mode: click; boundary: ! .uk-button-group; boundary-align: true;">
                    <ul class="uk-nav uk-dropdown-nav">
                      <li><a (click)="openGetFilteredCSV()">Get filtered CSV</a></li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <table class="uk-table uk-table-middle uk-table-divider">
          <thead>
          <tr>
            <th class="uk-table-expand">Name</th>
            <th class="uk-table-small">Client group</th>
            <th class="uk-table-small">Status</th>
            <th class="uk-table-shrink"></th>
          </tr>
          </thead>
          <tbody>
          <tr *ngFor="let client of opportunityClients">
            <td>{{ client.name }} {{ client.lastName }}</td>
            <td>
              <div *ngIf="isArray(client.clientGroup); else clientGroupIsNotArray">
                <div *ngFor="let clientGroup of client.clientGroup">{{ clientGroup.title }}</div>
              </div>
              <ng-template #clientGroupIsNotArray>
                <div>{{ client.clientGroup }}</div>
              </ng-template>
            </td>
            <td>{{ client.status }}</td>
            <td class="uk-text-right">
              <div class="uk-button-group">
                <button (click)="openClient(client._id)" class="uk-button uk-button-default" type="button">Open</button>
                <div class="uk-inline" class="uk-visible@m">
                  <button class="uk-button uk-button-default" type="button"><span uk-icon="icon:  chevron-down"></span>
                  </button>
                  <div uk-dropdown="mode: click; boundary: ! .uk-button-group; boundary-align: true;">
                    <ul class="uk-nav uk-dropdown-nav">
                      <li><a (click)="getCSVforOpportunity(client)">Get CSV</a></li>
                    </ul>
                  </div>
                </div>
              </div>
            </td>
          </tr>
          </tbody>
        </table>
      </div>
    </ul>
  </div>
  <div id="getFilteredCSVModal" class="uk-modal-container" uk-modal="bg-close: false; esc-close: false;">
    <div class="uk-modal-dialog">
      <div class="uk-modal-header">
        <h2 class="uk-modal-title">Get filtered CSV</h2>
      </div>
      <div class="uk-modal-body">
        <div class="uk-width-1-1">
          <div class="uk-width-1-1">
            <h3 class="uk-margin-remove-bottom">Client groups</h3>
          </div>
          <div *ngIf="allClientGroups.length === 0" class="uk-container uk-padding-small">
            No client groups found
          </div>
          <table class="uk-table uk-table-small" [hidden]="!allClientGroups.length">
            <thead>
            <tr>
              <th class="uk-padding-remove-left" colspan="2">
                <button
                  (click)="selectAllClientGroups()"
                  class="uk-button uk-button-default"
                  type="button"
                >
                  Select all
                </button>
                <button
                  (click)="removeAllSelectedClientGroups()"
                  class="uk-button uk-button-default uk-margin-left"
                  type="button"
                >
                  Select none
                </button>
              </th>
            </tr>
            <hr class="uk-margin-small-top">
            <!--              <tr>-->
            <!--                <th class="uk-table-shrink">Select</th>-->
            <!--                <th class="uk-table-shrink">Image</th>-->
            <!--                <th class="uk-width-expand">Name</th>-->
            <!--                <th class="uk-table-shrink"></th>-->
            <!--              </tr>-->
            </thead>
            <tbody>
            <tr *ngFor="let clientGroup of allClientGroups">
              <td class="uk-width-expand">
                <input class="uk-checkbox"
                       type="checkbox"
                       (click)="toggleSelectedClientGroup(clientGroup)"
                       [checked]="isClientGroupSelected(clientGroup)">
                <span class="uk-margin-left"
                      (click)="toggleSelectedClientGroup(clientGroup)">
                    {{ clientGroup.title }}
                  </span>
              </td>
            </tr>
            </tbody>
          </table>

          <div class="uk-width-1-1 uk-margin-medium-top">
            <h3 class="uk-margin-remove-bottom">Languages</h3>
          </div>
          <div *ngIf="allLanguages.length === 0" class="uk-container uk-padding-small">
            No languages found
          </div>
          <table class="uk-table uk-table-small" [hidden]="!allLanguages.length">
            <thead>
            <tr>
              <th class="uk-padding-remove-left" colspan="2">
                <button
                  (click)="selectAllLanguages()"
                  class="uk-button uk-button-default"
                  type="button"
                >
                  Select all
                </button>
                <button
                  (click)="removeAllSelectedLanguages()"
                  class="uk-button uk-button-default uk-margin-left"
                  type="button"
                >
                  Select none
                </button>
              </th>
            </tr>
            <hr class="uk-margin-small-top">
            <!--              <tr>-->
            <!--                <th class="uk-table-shrink">Select</th>-->
            <!--                <th class="uk-table-shrink">Image</th>-->
            <!--                <th class="uk-width-expand">Name</th>-->
            <!--                <th class="uk-table-shrink"></th>-->
            <!--              </tr>-->
            </thead>
            <tbody>
            <tr *ngFor="let language of allLanguages">
              <td class="uk-width-expand">
                <input class="uk-checkbox"
                       type="checkbox"
                       (click)="toggleSelectedLanguage(language)"
                       [checked]="isLanguageSelected(language)">
                <span class="uk-margin-left"
                      (click)="toggleSelectedLanguage(language)">
                    {{ language.title }}
                  </span>
              </td>
            </tr>
            </tbody>
          </table>
        </div>
      </div>
      <div class="uk-modal-footer">
        <p class="uk-text-right">
          <button class="uk-button uk-button-default uk-modal-close uk-margin-small-right"
                  type="button" id="getFilteredCSVModalCancel">Cancel
          </button>
          <button type="submit" class="uk-button uk-button-primary" (click)="getFilteredCSV('Opportunity')"
                  [disabled]="(selectedClientGroups.length === 0) && (selectedLanguages.length === 0)">
            Get CSV
          </button>
        </p>
      </div>
    </div>
  </div>
</div>
