import {Component, EventEmitter, OnInit, ViewChild} from '@angular/core';
import { AuthService } from '../services/auth.service';
import { ActiveService } from '../services/active.service';

@Component({
  selector: 'app-logout',
  template: '',
})
export class LogoutComponent implements OnInit {

  constructor(
    private auth: AuthService,
    private activeService: ActiveService
  ) { }

  ngOnInit() {
    this.activeService.resetActiveData();
    this.auth.logout();
  }

}
