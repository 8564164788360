import {Component, EventEmitter, OnInit, ViewChild} from '@angular/core';
import { Router } from '@angular/router';
import { ActivatedRoute } from '@angular/router';
import { FormGroup, FormControl, Validators, FormBuilder } from '@angular/forms';
import { AuthService } from '../../services/auth.service';
import { ActiveService } from '../../services/active.service';
import { UserService } from '../../services/user.service';
import { FileService } from '../../services/file.service';
import { AccountService } from '../../services/account.service';
import { SearchService } from '../../services/search.service';
import { ItemService } from '../../services/item.service';
import { UploadOutput, UploadInput, UploadFile, humanizeBytes, UploaderOptions } from 'ngx-uploader';
import { HttpClient, HttpHeaders } from '@angular/common/http';
declare var UIkit: any;

@Component({
  selector: 'app-account',
  templateUrl: './single-account.component.html',
  styleUrls: ['./single-account.component.scss']
})
export class SingleAccountComponent implements OnInit {
  public user: any = {};
  public accounts: Array<Object> = [];
  public attachments: Array<Object> = [];
  public activeAccount: any = {};
  public activeAccountOwner: any = {};
  public activeAccountUsers: Array<Object> = [];
  public isLoading: Boolean = true;
  public isLoadingComponent: Boolean = false;
  public uploadLoading: Boolean = false;
  public personalForm: FormGroup;
  public addAccountForm: FormGroup;
  public editAccountForm: FormGroup;

  // Client group
  public addClientGroupForm: FormGroup;
  public editClientGroupForm: FormGroup;
  public addClientGroupModalWindow: any;
  public editClientGroupModalWindow: any;

  // Contact method
  public addContactMethodForm: FormGroup;
  public editContactMethodForm: FormGroup;
  public addContactMethodModalWindow: any;
  public editContactMethodModalWindow: any;

  // Languages
  public addLanguageForm: FormGroup;
  public editLanguageForm: FormGroup;
  public addLanguageModalWindow: any;
  public editLanguageModalWindow: any;

  // VAT
  public addVATForm: FormGroup;
  public editVATForm: FormGroup;
  public addVATModalWindow: any;
  public editVATModalWindow: any;

  public editArtworkCodeForm: FormGroup;
  public registerForm: FormGroup;
  public editUserForm: FormGroup;
  public options: UploaderOptions;
  public filesToUpload: UploadFile[];
  public uploadInput: EventEmitter<UploadInput>;
  public humanizeBytes: Function;
  public dragOver: boolean;
  public registerModalWindow: any;
  public editUserModalWindow: any;
  public addArtworkImageModalWindow: any;

  private httpOptions = {
    headers: new HttpHeaders({
      'Content-Type':  'application/json',
      'charset': 'UTF-8'
    })
  };

  constructor(
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private http: HttpClient,
    private formBuilder: FormBuilder,
    private activeService: ActiveService,
    private searchService: SearchService,
    private auth: AuthService,
    private userService: UserService,
    private accountService: AccountService,
    private fileService: FileService,
    private itemService: ItemService
  ) {
    this.activatedRoute.data.subscribe(data => {
      this.activeAccount = data.resolvedData;
      if (this.activeAccount.actor && this.activeAccount.actor.images) {
        this.activeAccount.images = this.activeAccount.actor.images;
      } else {
        this.activeAccount.images = [];
      }

      this.activeAccountUsers = data.resolvedData.users;
      this.activeAccountOwner = data.resolvedData.owner;

      if (this.activeAccount && this.activeAccount.actor && this.activeAccount.actor.attachments && this.activeAccount.actor.attachments[0] == null ) {
        this.activeAccount.actor.attachments.shift(); // Delete first element, aka [0]
      }
    });

    this.personalForm = this.formBuilder.group({
      personalFormEmail: new FormControl('', [
        Validators.required,
        Validators.maxLength(100)
      ]),
      personalFormPassword: new FormControl('', [
        Validators.required,
        Validators.maxLength(100)
      ]),
      /* Tähän väliin avatar-kuva */
      personalFormName: new FormControl('', [
        Validators.required,
        Validators.maxLength(100)
      ]),
      personalFormOwner: new FormControl('', [
        Validators.maxLength(100)
      ]),
      personalFormStreet: new FormControl('', [
        Validators.maxLength(100)
      ]),
      personalFormCity: new FormControl('', [
        Validators.maxLength(100)
      ]),
      personalFormState: new FormControl('', [
        Validators.maxLength(100)
      ]),
      personalFormZip: new FormControl('', [
        Validators.maxLength(100)
      ]),
      personalFormPhone: new FormControl('', [
        Validators.maxLength(100)
      ])
    });

    this.addAccountForm = this.formBuilder.group({
      addAccountName: new FormControl('', [
        Validators.required,
        Validators.maxLength(100)
      ]),
      addAccountOwner: new FormControl('', [
        Validators.maxLength(100)
      ]),
      addAccountStreet: new FormControl('', [
        Validators.maxLength(100)
      ]),
      addAccountCity: new FormControl('', [
        Validators.maxLength(100)
      ]),
      addAccountState: new FormControl('', [
        Validators.maxLength(100)
      ]),
      addAccountZip: new FormControl('', [
        Validators.maxLength(100)
      ]),
      addAccountEmail: new FormControl('', [
        Validators.maxLength(100)
      ]),
      addAccountPhone: new FormControl('', [
        Validators.maxLength(100)
      ]),
      addAccountNotes: new FormControl('', [
        Validators.maxLength(2048)
      ]),
      addAccountAttachments: new FormControl('', [
        Validators.maxLength(100)
      ])
    });

    this.editAccountForm = this.formBuilder.group({
      editAccountId: new FormControl('', [
        Validators.required,
        Validators.maxLength(100)
      ]),
      editAccountName: new FormControl('', [
        Validators.required,
        Validators.maxLength(100)
      ]),
      editAccountVisibleName: new FormControl('', [
        Validators.maxLength(100)
      ]),
      editAccountOwner: new FormControl('', [
        Validators.maxLength(100)
      ]),
      editAccountStreet: new FormControl('', [
        Validators.maxLength(100)
      ]),
      editAccountCity: new FormControl('', [
        Validators.maxLength(100)
      ]),
      editAccountState: new FormControl('', [
        Validators.maxLength(100)
      ]),
      editAccountZip: new FormControl('', [
        Validators.maxLength(100)
      ]),
      editAccountEmail: new FormControl('', [
        Validators.maxLength(100)
      ]),
      editAccountPhone: new FormControl('', [
        Validators.maxLength(100)
      ]),
      editAccountCurrency: new FormControl({value: '', disabled: true}, [
        Validators.maxLength(100)
      ]),
      editAccountVAT: new FormControl({value: ''}, [
        Validators.maxLength(100),
        Validators.min(0),
        Validators.max(100)
      ]),
      editAccountType: new FormControl('', [
        Validators.maxLength(100)
      ]),
      editAccountPricing: new FormControl({value: '', disabled: true}, [
        Validators.maxLength(100)
      ]),
      editAccountTimezone: new FormControl({value: '', disabled: true}, [
        Validators.maxLength(100)
      ]),
      editAccountMeasurementUnit: new FormControl({value: '', disabled: false}, [
        Validators.maxLength(100)
      ]),
      editAccountPDFFont: new FormControl({value: '', disabled: false}, [
        Validators.maxLength(100)
      ]),
      editAccountNotes: new FormControl('', [
        Validators.maxLength(2048)
      ]),
      editAccountAttachments: new FormControl('', [
        Validators.maxLength(100)
      ])
    });

    this.registerForm = this.formBuilder.group({
      registerFormEmail: new FormControl('', [
        Validators.required,
        Validators.maxLength(100)
      ]),
      registerFormExistingUser: new FormControl(false, [
        Validators.maxLength(100)
      ]),
      registerFormPassword: new FormControl(null, [
        Validators.maxLength(100)
      ]),
      registerFormRole: new FormControl('', [
        Validators.required,
        Validators.maxLength(100)
      ]),
      registerFormAccount: new FormControl('', [
        Validators.maxLength(100)
      ])
    });

    this.editUserForm = this.formBuilder.group({
      editUserFormEmail: new FormControl('', [
        Validators.required,
        Validators.maxLength(100)
      ]),
      /*editUserFormPassword: new FormControl('', [
        Validators.required,
        Validators.maxLength(100)
      ]),*/
      editUserFormRole: new FormControl('', [
        Validators.maxLength(100)
      ]),
      editUserFormAccount: new FormControl('', [
        Validators.maxLength(100)
      ]),
      editUserFormId: new FormControl('', [
        Validators.maxLength(100)
      ])
    });

    this.addClientGroupForm = this.formBuilder.group({
      addClientGroupFormAccount: new FormControl('', [
        Validators.required,
        Validators.maxLength(100)
      ]),
      addClientGroupFormTitle: new FormControl('', [
        Validators.required,
        Validators.maxLength(100)
      ]),
      addClientGroupFormDescription: new FormControl('', [
        Validators.maxLength(100)
      ])
    });

    this.editClientGroupForm = this.formBuilder.group({
      editClientGroupFormAccount: new FormControl('', [
        Validators.required,
        Validators.maxLength(100)
      ]),
      editClientGroupFormClientGroupId: new FormControl('', [
        Validators.maxLength(100)
      ]),
      editClientGroupFormTitle: new FormControl('', [
        Validators.required,
        Validators.maxLength(100),
        Validators.min(0),
        Validators.max(100)
      ]),
      editClientGroupFormDescription: new FormControl('', [
        Validators.maxLength(100),
        Validators.required
      ])
    });

    this.addContactMethodForm = this.formBuilder.group({
      addContactMethodFormAccount: new FormControl('', [
        Validators.required,
        Validators.maxLength(100)
      ]),
      addContactMethodFormTitle: new FormControl('', [
        Validators.required,
        Validators.maxLength(100)
      ]),
      addContactMethodFormDescription: new FormControl('', [
        Validators.maxLength(100)
      ])
    });


    this.editContactMethodForm = this.formBuilder.group({
      editContactMethodFormAccount: new FormControl('', [
        Validators.required,
        Validators.maxLength(100)
      ]),
      editContactMethodFormContactMethodId: new FormControl('', [
        Validators.maxLength(100)
      ]),
      editContactMethodFormTitle: new FormControl('', [
        Validators.required,
        Validators.maxLength(100),
        Validators.min(0),
        Validators.max(100)
      ]),
      editContactMethodFormDescription: new FormControl('', [
        Validators.maxLength(100),
        Validators.required
      ])
    });

    this.addVATForm = this.formBuilder.group({
      addVATFormAccount: new FormControl('', [
        Validators.required,
        Validators.maxLength(100)
      ]),
      addVATFormPercentage: new FormControl('', [
        Validators.required,
        Validators.maxLength(100)
      ]),
      addVATFormDescription: new FormControl('', [
        Validators.maxLength(100)
      ])
    });

    this.editVATForm = this.formBuilder.group({
      editVATFormAccount: new FormControl('', [
        Validators.required,
        Validators.maxLength(100)
      ]),
      editVATFormVATId: new FormControl('', [
        Validators.maxLength(100)
      ]),
      editVATFormPercentage: new FormControl('', [
        Validators.required,
        Validators.maxLength(100),
        Validators.min(0),
        Validators.max(100)
      ]),
      editVATFormDescription: new FormControl('', [
        Validators.maxLength(100),
        Validators.required
      ])
    });

    this.addLanguageForm = this.formBuilder.group({
      addLanguageFormAccount: new FormControl('', [
        Validators.required,
        Validators.maxLength(100)
      ]),
      addLanguageFormTitle: new FormControl('', [
        Validators.required,
        Validators.maxLength(100)
      ]),
      addLanguageFormDescription: new FormControl('', [
        Validators.maxLength(100)
      ])
    });

    this.editLanguageForm = this.formBuilder.group({
      editLanguageFormAccount: new FormControl('', [
        Validators.required,
        Validators.maxLength(100)
      ]),
      editLanguageFormLanguageId: new FormControl('', [
        Validators.maxLength(100)
      ]),
      editLanguageFormTitle: new FormControl('', [
        Validators.required,
        Validators.maxLength(100),
        Validators.min(0),
        Validators.max(100)
      ]),
      editLanguageFormDescription: new FormControl('', [
        Validators.maxLength(100),
        Validators.required
      ])
    });

    this.editArtworkCodeForm = this.formBuilder.group({
      editArtworkCodeFormAccount: new FormControl('', [
        Validators.required,
        Validators.maxLength(100)
      ]),
      editArtworkCodeFormName: new FormControl('', [
        Validators.maxLength(100)
      ]),
      editArtworkCodeFormNumber: new FormControl('', [
        Validators.required,
        Validators.maxLength(10000),
        Validators.min(0)
      ])
    });

    this.filesToUpload = [];
    this.uploadInput = new EventEmitter<UploadInput>();
    this.humanizeBytes = humanizeBytes;
  }

  ngOnInit() {
    this.isLoading = true;
    this.activeService.getActiveData().subscribe(
      data => {
        if (this.activeAccountUsers.length) {
          this.activeAccountOwner = this.activeAccountUsers.find((user: any) => user._id === this.activeAccount.owner);
        }

        if (this.activeAccountOwner && this.activeAccountOwner._id) {
          this.activeAccount.owner = this.activeAccountOwner._id;
        }

        this.userService.getUser(data.user).subscribe(
          data => {
            this.user._id = data._id;
            const usersCurrentAccount = data.account.filter(account => account.accountId === this.activeAccount._id)[0];
            if (data.admin) {
              this.user.role = 'admin';
            } else if (usersCurrentAccount) {
              this.user.role = usersCurrentAccount.role;
            } else {
              this.user.role = 'employee';
            }
          },
          () => {
            UIkit.notification({
              message: 'Error fetching user information',
              status: 'danger',
              pos: 'top-right',
              timeout: 1500
            });
          },
          () => this.isLoading = false
        );
      });
  }

  openAddNew() {
    this.registerModalWindow = window.document.getElementById('addnew');

    this.registerForm.patchValue({ registerFormEmail: ''});
    this.registerForm.patchValue({ registerFormExistingUser: false });
    this.registerForm.patchValue({ registerFormPassword: ''});
    this.registerForm.patchValue({ registerFormRole: ''});

    UIkit.modal(this.registerModalWindow).show();
  }

  openEditUser(user) {
    this.editUserModalWindow = window.document.getElementById('edituser');
    this.editUserForm.patchValue({
      editUserFormId: user._id,
      editUserFormEmail: user.email,
      editUserFormRole: user.role
    });
    UIkit.modal(this.editUserModalWindow).show();
  }

  removeUser(userId) {
    if (this.activeAccountUsers.length === 1) {
      UIkit.modal(this.registerModalWindow).hide();

      UIkit.notification({
        message: 'Cannot remove the only user of account',
        status: 'warning',
        pos: 'top-right',
        timeout: 1500
      });

      return;
    }

    const userObject = {
      userId: userId._id,
      accountId: this.activeAccount._id
    };

    this.userService.deleteAccountUser(userObject).subscribe(
      res => {
      this.accountService.getAccount(this.activeAccount).subscribe(
        data => {
          /*this.activeAccount = data.account;*/
          this.activeAccountUsers = data.users;
          UIkit.notification({
            message: 'User removed successfully',
            status: 'success',
            pos: 'top-right',
            timeout: 1500
          });
        });
      },
      error => {
        UIkit.notification({
          message: 'Removing user failed',
          status: 'danger',
          pos: 'top-right',
          timeout: 1500
        });
      }
    );
  }

  register() {
    if (this.activeAccountUsers.find((user: any) => user.email === this.registerForm.value.registerFormEmail)) {
      UIkit.modal(this.registerModalWindow).hide();

      UIkit.notification({
        message: 'User already exists on this account',
        status: 'warning',
        pos: 'top-right',
        timeout: 1500
      });

      return;
    }

    if (this.registerForm.value.registerFormExistingUser) {
      this.registerForm.patchValue({ registerFormPassword: null });
    }

    this.userService.register(this.registerForm.value).subscribe(
      res => {
        let usersCurrentAccount = res.account.filter(account => account.accountId === this.activeAccount._id)[0];
        if (!usersCurrentAccount) {
          usersCurrentAccount = {
            role: this.registerForm.value.registerFormRole
          };
        }

        this.activeAccountUsers.push({
          email: res.email,
          role: usersCurrentAccount.role,
          _id: res._id
        });

        this.registerForm.patchValue({ registerFormEmail: ''});
        this.registerForm.patchValue({ registerFormExistingUser: false });
        this.registerForm.patchValue({ registerFormPassword: ''});
        this.registerForm.patchValue({ registerFormRole: ''});

        UIkit.modal(this.registerModalWindow).hide();

        UIkit.notification({
          message: 'New user added successfully',
          status: 'success',
          pos: 'top-right',
          timeout: 1500
        });
      },
      error => {
        UIkit.notification({
          message: 'Adding a new user failed',
          status: 'danger',
          pos: 'top-right',
          timeout: 1500
        });
      }
    );
  }

  editUser() {
    this.isLoading = true;
    this.userService.editAccountUser(this.editUserForm.value).subscribe(
      res => {
        const foundIndex = this.activeAccountUsers.findIndex((user: any) => user._id == this.editUserForm.value.id);
        this.activeAccountUsers[foundIndex] = {
          email: this.editUserForm.value.editUserFormEmail,
          role: this.editUserForm.value.editUserFormRole,
          _id: this.editUserForm.value.id
        };

        this.editUserForm.patchValue({ editUserFormEmail: ''});
        this.editUserForm.patchValue({ editUserFormPassword: ''});
        this.editUserForm.patchValue({ editUserFormRole: ''});

        UIkit.modal(this.editUserModalWindow).hide();
        UIkit.notification({
          message: 'User updated successfully',
          status: 'success',
          pos: 'top-right',
          timeout: 1500
        });
      },
      error => {
        UIkit.notification({
          message: 'Updating user failed',
          status: 'danger',
          pos: 'top-right',
          timeout: 1500
        });
      },
      () => this.isLoading = false
    );
  }

  openAddClientGroup() {
    this.addClientGroupModalWindow = window.document.getElementById('addClientGroup');

    this.addClientGroupForm.patchValue({ addClientGroupFormTitle: ''});
    this.addClientGroupForm.patchValue({ addClientGroupFormDescription: ''});

    UIkit.modal(this.addClientGroupModalWindow).show();
  }

  openEditClientGroup(clientGroup) {
    this.editClientGroupModalWindow = window.document.getElementById('editClientGroup');

    this.editClientGroupForm.patchValue({
      editClientGroupFormAccount: this.activeAccount._id,
      editClientGroupFormClientGroupId: clientGroup._id,
      editClientGroupFormTitle: clientGroup.title,
      editClientGroupFormDescription: clientGroup.description
    });

    UIkit.modal(this.editClientGroupModalWindow).show();
  }

  addClientGroup() {
    this.accountService.addClientGroup(this.addClientGroupForm.value).subscribe(
      (accountDocument) => {
        const addedClientGroupTitleDocument = accountDocument.clientGroupTitles[accountDocument.clientGroupTitles.length - 1];

        this.activeAccount.clientGroupTitles.push({
          _id: addedClientGroupTitleDocument._id,
          title: addedClientGroupTitleDocument.title,
          description: addedClientGroupTitleDocument.description
        });

        this.addClientGroupForm.patchValue({ addClientGroupFormTitle: ''});
        this.addClientGroupForm.patchValue({ addClientGroupFormDescription: ''});

        UIkit.modal(this.addClientGroupModalWindow).hide();

        UIkit.notification({
          message: 'New client group added successfully',
          status: 'success',
          pos: 'top-right',
          timeout: 1500
        });
      },
      () => {
        UIkit.notification({
          message: 'Saving a new client group failed',
          status: 'danger',
          pos: 'top-right',
          timeout: 1500
        });
      }
    );
  }

  editClientGroup() {
    this.isLoading = true;
    this.accountService.editClientGroup(this.editClientGroupForm.value).subscribe(
      res => {
        const foundIndex = this.activeAccount.clientGroupTitles.findIndex((clientGroup) => clientGroup._id === this.editClientGroupForm.value.editClientGroupFormClientGroupId);

        if (foundIndex >= 0) {
          this.activeAccount.clientGroupTitles[foundIndex] = {
            title: this.editClientGroupForm.value.editClientGroupFormTitle,
            description: this.editClientGroupForm.value.editClientGroupFormDescription
          };
        }

        this.editClientGroupForm.patchValue({ editClientGroupFormClientGroupId: ''});
        this.editClientGroupForm.patchValue({ editClientGroupFormTitle: ''});
        this.editClientGroupForm.patchValue({ editClientGroupFormDescription: ''});

        UIkit.modal(this.editClientGroupModalWindow).hide();

        UIkit.notification({
          message: 'Client group updated successfully',
          status: 'success',
          pos: 'top-right',
          timeout: 1500
        });
      },
      error => {
        UIkit.notification({
          message: 'Updating client group failed',
          status: 'danger',
          pos: 'top-right',
          timeout: 1500
        });
      },
      () => this.isLoading = false
    );
  }

  removeClientGroup(clientGroup) {
    this.accountService.deleteClientGroup(this.activeAccount._id, clientGroup).subscribe(
      res => {
        const ClientGroupIndex = this.activeAccount.clientGroupTitles.findIndex((clientGroupTitle: any) => clientGroupTitle._id == clientGroup._id);

        this.activeAccount.clientGroupTitles.splice(ClientGroupIndex, 1);

        UIkit.notification({
          message: 'Client group removed successfully',
          status: 'success',
          pos: 'top-right',
          timeout: 1500
        });
      },
      error => {
        UIkit.notification({
          message: 'Removing client group failed',
          status: 'danger',
          pos: 'top-right',
          timeout: 1500
        });
      }
    );
  }

  openAddContactMethod() {
    this.addContactMethodModalWindow = window.document.getElementById('addContactMethod');

    this.addContactMethodForm.patchValue({ addContactMethodFormTitle: ''});
    this.addContactMethodForm.patchValue({ addContactMethodFormDescription: ''});

    UIkit.modal(this.addContactMethodModalWindow).show();
  }

  openEditContactMethod(contactMethod) {
    this.editContactMethodModalWindow = window.document.getElementById('editContactMethod');

    this.editContactMethodForm.patchValue({
      editContactMethodFormAccount: this.activeAccount._id,
      editContactMethodFormContactMethodId: contactMethod._id,
      editContactMethodFormTitle: contactMethod.title,
      editContactMethodFormDescription: contactMethod.description
    });

    UIkit.modal(this.editContactMethodModalWindow).show();
  }

  addContactMethod() {
    this.accountService.addContactMethod(this.addContactMethodForm.value).subscribe(
      (accountDocument) => {
        const addedContactMethodTitleDocument = accountDocument.contactMethodTitles[accountDocument.contactMethodTitles.length - 1];

        this.activeAccount.contactMethodTitles.push({
          _id: addedContactMethodTitleDocument._id,
          title: addedContactMethodTitleDocument.title,
          description: addedContactMethodTitleDocument.description
        });

        this.addContactMethodForm.patchValue({ addContactMethodFormTitle: ''});
        this.addContactMethodForm.patchValue({ addContactMethodFormDescription: ''});

        UIkit.modal(this.addContactMethodModalWindow).hide();

        UIkit.notification({
          message: 'New client group added successfully',
          status: 'success',
          pos: 'top-right',
          timeout: 1500
        });
      },
      () => {
        UIkit.notification({
          message: 'Saving a new client group failed',
          status: 'danger',
          pos: 'top-right',
          timeout: 1500
        });
      }
    );
  }

  editContactMethod() {
    this.isLoading = true;
    this.accountService.editContactMethod(this.editContactMethodForm.value).subscribe(
      res => {
        const foundIndex = this.activeAccount.contactMethodTitles.findIndex((contactMethod) => contactMethod._id === this.editContactMethodForm.value.editContactMethodFormContactMethodId);

        if (foundIndex >= 0) {
          this.activeAccount.contactMethodTitles[foundIndex] = {
            title: this.editContactMethodForm.value.editContactMethodFormTitle,
            description: this.editContactMethodForm.value.editContactMethodFormDescription
          };
        }

        this.editContactMethodForm.patchValue({ editContactMethodFormContactMethodId: ''});
        this.editContactMethodForm.patchValue({ editContactMethodFormTitle: ''});
        this.editContactMethodForm.patchValue({ editContactMethodFormDescription: ''});

        UIkit.modal(this.editContactMethodModalWindow).hide();

        UIkit.notification({
          message: 'Client group updated successfully',
          status: 'success',
          pos: 'top-right',
          timeout: 1500
        });
      },
      error => {
        UIkit.notification({
          message: 'Updating client group failed',
          status: 'danger',
          pos: 'top-right',
          timeout: 1500
        });
      },
      () => this.isLoading = false
    );
  }

  removeContactMethod(contactMethod) {
    this.accountService.deleteContactMethod(this.activeAccount._id, contactMethod).subscribe(
      res => {
        const ContactMethodIndex = this.activeAccount.contactMethodTitles.findIndex((contactMethodTitle: any) => contactMethodTitle._id == contactMethod._id);

        this.activeAccount.contactMethodTitles.splice(ContactMethodIndex, 1);

        UIkit.notification({
          message: 'Client group removed successfully',
          status: 'success',
          pos: 'top-right',
          timeout: 1500
        });
      },
      error => {
        UIkit.notification({
          message: 'Removing client group failed',
          status: 'danger',
          pos: 'top-right',
          timeout: 1500
        });
      }
    );
  }

  openAddVAT() {
    this.addVATModalWindow = window.document.getElementById('addVAT');

    this.addVATForm.patchValue({ addVATFormPercentage: ''});
    this.addVATForm.patchValue({ addVATFormDescription: ''});

    UIkit.modal(this.addVATModalWindow).show();
  }

  openEditVAT(VAT) {
    this.editVATModalWindow = window.document.getElementById('editVAT');

    this.editVATForm.patchValue({
      editVATFormAccount: this.activeAccount._id,
      editVATFormVATId: VAT._id,
      editVATFormPercentage: VAT.percentage,
      editVATFormDescription: VAT.description
    });

    UIkit.modal(this.editVATModalWindow).show();
  }

  addVAT() {
    this.accountService.addVAT(this.addVATForm.value).subscribe(
      (accountDocument) => {
        const addedVATRateDocument = accountDocument.VATRates[accountDocument.VATRates.length - 1];

        this.activeAccount.VATRates.push({
          _id: addedVATRateDocument._id,
          percentage: addedVATRateDocument.percentage,
          description: addedVATRateDocument.description
        });

        this.addVATForm.patchValue({ addVATFormPercentage: ''});
        this.addVATForm.patchValue({ addVATFormDescription: ''});

        UIkit.modal(this.addVATModalWindow).hide();

        UIkit.notification({
          message: 'New VAT rate added successfully',
          status: 'success',
          pos: 'top-right',
          timeout: 1500
        });
      },
      () => {
        UIkit.notification({
          message: 'Saving a new VAT rate failed',
          status: 'danger',
          pos: 'top-right',
          timeout: 1500
        });
      }
    );
  }

  editVAT() {
    this.isLoading = true;
    this.accountService.editVAT(this.editVATForm.value).subscribe(
      res => {
        const foundIndex = this.activeAccount.VATRates.findIndex((VAT) => VAT._id === this.editVATForm.value.editVATFormVATId);

        if (foundIndex >= 0) {
          this.activeAccount.VATRates[foundIndex] = {
            percentage: this.editVATForm.value.editVATFormPercentage,
            description: this.editVATForm.value.editVATFormDescription
          };
        }

        this.editVATForm.patchValue({ editVATFormVATId: ''});
        this.editVATForm.patchValue({ editVATFormPercentage: ''});
        this.editVATForm.patchValue({ editVATFormDescription: ''});

        UIkit.modal(this.editVATModalWindow).hide();

        UIkit.notification({
          message: 'VAT rate updated successfully',
          status: 'success',
          pos: 'top-right',
          timeout: 1500
        });
      },
      error => {
        UIkit.notification({
          message: 'Updating VAT rate failed',
          status: 'danger',
          pos: 'top-right',
          timeout: 1500
        });
      },
      () => this.isLoading = false
    );
  }

  removeVAT(VAT) {
    this.accountService.deleteVAT(this.activeAccount._id, VAT).subscribe(
      res => {
        const VATIndex = this.activeAccount.VATRates.findIndex((VATrate: any) => VATrate._id == VAT._id);

        this.activeAccount.VATRates.splice(VATIndex, 1);

        UIkit.notification({
          message: 'VAT rate removed successfully',
          status: 'success',
          pos: 'top-right',
          timeout: 1500
        });
      },
      error => {
        UIkit.notification({
          message: 'Removing VAT rate failed',
          status: 'danger',
          pos: 'top-right',
          timeout: 1500
        });
      }
    );
  }

  openAddLanguage() {
    this.addLanguageModalWindow = window.document.getElementById('addLanguage');

    this.addLanguageForm.patchValue({ addLanguageFormTitle: ''});
    this.addLanguageForm.patchValue({ addLanguageFormDescription: ''});

    UIkit.modal(this.addLanguageModalWindow).show();
  }

  openEditLanguage(language) {
    this.editLanguageModalWindow = window.document.getElementById('editLanguage');

    this.editLanguageForm.patchValue({
      editLanguageFormAccount: this.activeAccount._id,
      editLanguageFormLanguageId: language._id,
      editLanguageFormTitle: language.title,
      editLanguageFormDescription: language.description
    });

    UIkit.modal(this.editLanguageModalWindow).show();
  }

  addLanguage() {
    this.accountService.addLanguage(this.addLanguageForm.value).subscribe(
      (accountDocument) => {
        const addedLanguageTitleDocument = accountDocument.languageTitles[accountDocument.languageTitles.length - 1];

        this.activeAccount.languageTitles.push({
          _id: addedLanguageTitleDocument._id,
          title: addedLanguageTitleDocument.title,
          description: addedLanguageTitleDocument.description
        });

        this.addLanguageForm.patchValue({ addLanguageFormTitle: ''});
        this.addLanguageForm.patchValue({ addLanguageFormDescription: ''});

        UIkit.modal(this.addLanguageModalWindow).hide();

        UIkit.notification({
          message: 'New language added successfully',
          status: 'success',
          pos: 'top-right',
          timeout: 1500
        });
      },
      () => {
        UIkit.notification({
          message: 'Saving a new language failed',
          status: 'danger',
          pos: 'top-right',
          timeout: 1500
        });
      }
    );
  }

  editLanguage() {
    this.isLoading = true;
    this.accountService.editLanguage(this.editLanguageForm.value).subscribe(
      res => {
        const foundIndex = this.activeAccount.languageTitles.findIndex((language) => language._id === this.editLanguageForm.value.editLanguageFormLanguageId);

        if (foundIndex >= 0) {
          this.activeAccount.languageTitles[foundIndex] = {
            title: this.editLanguageForm.value.editLanguageFormTitle,
            description: this.editLanguageForm.value.editLanguageFormDescription
          };
        }

        this.editLanguageForm.patchValue({ editLanguageFormLanguageId: ''});
        this.editLanguageForm.patchValue({ editLanguageFormTitle: ''});
        this.editLanguageForm.patchValue({ editLanguageFormDescription: ''});

        UIkit.modal(this.editLanguageModalWindow).hide();

        UIkit.notification({
          message: 'Language updated successfully',
          status: 'success',
          pos: 'top-right',
          timeout: 1500
        });
      },
      error => {
        UIkit.notification({
          message: 'Updating language failed',
          status: 'danger',
          pos: 'top-right',
          timeout: 1500
        });
      },
      () => this.isLoading = false
    );
  }

  removeLanguage(language) {
    this.accountService.deleteLanguage(this.activeAccount._id, language).subscribe(
      res => {
        const LanguageIndex = this.activeAccount.languageTitles.findIndex((languageTitle: any) => languageTitle._id == language._id);

        this.activeAccount.languageTitles.splice(LanguageIndex, 1);

        UIkit.notification({
          message: 'Language removed successfully',
          status: 'success',
          pos: 'top-right',
          timeout: 1500
        });
      },
      error => {
        UIkit.notification({
          message: 'Removing language failed',
          status: 'danger',
          pos: 'top-right',
          timeout: 1500
        });
      }
    );
  }

  updateArtworkCode() {
    this.accountService.updateArtworkCode(this.editArtworkCodeForm.value).subscribe(
      (accountDocument) => {
        UIkit.notification({
          message: 'Artwork code saved successfully',
          status: 'success',
          pos: 'top-right',
          timeout: 1500
        });
      },
      () => {
        UIkit.notification({
          message: 'Error updating artwork code',
          status: 'danger',
          pos: 'top-right',
          timeout: 1500
        });
      }
    );
  }

  onAttachmentsUploadOutput(output: UploadOutput): void {
    const properFile = output.file && output.file.name && output.file.name.length > 0;

    if (output.type === 'allAddedToQueue') {
      const event: UploadInput = {
        type: 'uploadAll',
        url: '/api/file',
        method: 'POST',
        includeWebKitFormBoundary: true
      };
      this.uploadInput.emit(event);
    } else if (output.type === 'addedToQueue' && properFile) {
      if (this.filesToUpload.indexOf(output.file) == -1) {
        this.filesToUpload.push(output.file);
      }
    } else if (output.type === 'uploading' && properFile) {
      const index = this.filesToUpload.findIndex(file => typeof output.file !== 'undefined' && file.id === output.file.id);
      this.filesToUpload[index] = output.file;
    } else if (output.type === 'removed') {
      // remove file from array when removed
      this.filesToUpload = this.filesToUpload.filter((file: UploadFile) => file !== output.file);
    } else if (output.type === 'dragOver') {
      this.dragOver = true;
    } else if (output.type === 'dragOut') {
      this.dragOver = false;
    } else if (output.type === 'drop') {
      this.dragOver = false;
    } else if (output.type === 'removedAll') {
      this.filesToUpload.length = 0;
    } else if (output.type === 'done') {
      const fileObject = {
        accountId: this.activeAccount._id,
        fileId: output.file.response
      };
      this.accountService.addFileId(fileObject).subscribe(() => {
        this.removeFile(output.file.id);
        if (!this.activeAccount.actor.attachments) {
          this.activeAccount.actor.attachments = [];
        }
        this.activeAccount.actor.attachments.push({
          itemId: this.activeAccount._id,
          fileId: output.file.response,
          name: output.file.name,
          contentType: output.file.type
        });
      });
    }
  }

  startUpload(): void {
    const event: UploadInput = {
      type: 'uploadAll',
      url: '/api/file',
      method: 'POST',
      includeWebKitFormBoundary: true
    };
    this.uploadInput.emit(event);
  }

  cancelUpload(id: string): void {
    this.uploadInput.emit({ type: 'cancel', id: id });
  }

  addFile(): void {
    const fileSelected: File = this.editAccountForm.value.editAccountAttachments;
    this.accountService.uploadFile(fileSelected)
      .subscribe(res => {});
  }

  onChange($event: any) {
    this.editAccountForm.value.editAccountAttachments = $event.target.files[0];
  }

  removeFile(id: string): void {
    this.uploadInput.emit({ type: 'remove', id: id });
  }

  removeAllFiles(): void {
    this.uploadInput.emit({ type: 'removeAll' });
  }

  editAccount() {
    /*const activeAccountOwner: any = this.activeAccountUsers.find((user: any) => this.user._id === this.activeAccount.owner);*/
    const editAccountObject = {
      id: this.editAccountForm.value.editAccountId,
      name: this.editAccountForm.value.editAccountName,
      visibleName: this.editAccountForm.value.editAccountVisibleName,
      owner: this.activeAccountOwner,
      street: this.editAccountForm.value.editAccountStreet,
      city: this.editAccountForm.value.editAccountCity,
      state: this.editAccountForm.value.editAccountState,
      email: this.editAccountForm.value.editAccountEmail,
      phone: this.editAccountForm.value.editAccountPhone,
      currency: this.editAccountForm.value.editAccountCurrency,
      vat: this.editAccountForm.value.editAccountVAT,
      type: this.editAccountForm.value.editAccountType,
      pricing: this.editAccountForm.value.editAccountPricing,
      timezone: this.editAccountForm.value.editAccountTimezone,
      measurementUnit: this.editAccountForm.value.editAccountMeasurementUnit,
      PDFFont: this.editAccountForm.value.editAccountPDFFont,
      notes: this.editAccountForm.value.editAccountNotes,
      attachments: this.activeAccount.actor.attachments
    };
    this.accountService.editAccount(editAccountObject).subscribe(
      res => {
        this.activeService.setActiveAccount(this.editAccountForm.value.editAccountId);
        UIkit.notification({
          message: 'Saved successfully',
          status: 'success',
          pos: 'top-right',
          timeout: 1500
        });
      },
      error => {
        UIkit.notification({
          message: 'Saving failed',
          status: 'danger',
          pos: 'top-right',
          timeout: 1500
        });
      }
    );
  }

  closeAccount(account) {
    const closeAccountObject = {
      id: account._id,
      status: false
    };
    this.accountService.editAccountStatus(closeAccountObject).subscribe(
      res => {
        this.router.navigateByUrl('/accounts');
        UIkit.notification({
          message: 'Account closed successfully',
          status: 'success',
          pos: 'top-right',
          timeout: 1500
        });
      },
      error => {
        UIkit.notification({
          message: 'Account closing failed',
          status: 'danger',
          pos: 'top-right',
          timeout: 1500
        });
      }
    );
  }

  deleteAccount(account) {
    const deleteAccountObject = {
      id: account._id,
    };
    this.accountService.deleteAccount(deleteAccountObject).subscribe(
      res => {
        this.router.navigateByUrl('/accounts');
        UIkit.notification({
          message: 'Account deleted successfully',
          status: 'success',
          pos: 'top-right',
          timeout: 1500
        });
      },
      error => {
        UIkit.notification({
          message: 'Account deletion failed',
          status: 'danger',
          pos: 'top-right',
          timeout: 1500
        });
      }
    );
  }

  openAttachment(attachment) {
    this.itemService.openAttachment(this.activeAccount._id, attachment).then((response: any) => {
      if (response.url) {
        window.open(response.url);
      } else {
        UIkit.notification({
          message: 'Error fetching data',
          status: 'danger',
          pos: 'top-right',
          timeout: 1500
        });
      }
    });
  }

  removeAttachment(attachment) {
    const attachmentObject = {
      documentType: 'account',
      itemId: this.activeAccount._id,
      fileId: attachment.fileId
    };

    this.fileService.removeFile(attachmentObject).subscribe(
      res => {
        /*this.activeAccount.actor.attachments.splice(this.activeAccount.actor.attachments.indexOf(attachment), 1);*/
        const attachmentToSplice = this.activeAccount.actor.attachments.find((accountAttachment: any) => {
          return attachment.fileId === accountAttachment.fileId;
        });
        this.activeAccount.actor.attachments.splice(this.activeAccount.actor.attachments.indexOf(attachmentToSplice), 1);
        UIkit.notification({
          message: 'File removed successfully',
          status: 'success',
          pos: 'top-right',
          timeout: 1500
        });
      },
      error => {
        UIkit.notification({
          message: 'Removing failed',
          status: 'danger',
          pos: 'top-right',
          timeout: 1500
        });
      }
    );
  }

  onUploadOutput(output: UploadOutput): void {
    const properFile = output.file && output.file.name && output.file.name.length > 0;

    if (output.type === 'allAddedToQueue') {
      const event: UploadInput = {
        type: 'uploadAll',
        url: '/api/file',
        method: 'POST',
        includeWebKitFormBoundary: true
      };
      this.uploadInput.emit(event);
    } else if (output.type === 'addedToQueue' && properFile) {
      if (this.filesToUpload.indexOf(output.file) == -1) {
        this.filesToUpload.push(output.file);
      }
    } else if (output.type === 'uploading' && properFile) {
      if (!this.activeAccount['images'].some(image => image.placeholderId === output.file.id)) {
        this.uploadLoading = true;
        this.activeService.increasePendingRequests();
        this.activeAccount['images'].push({placeholderId: output.file.id});
        const index = this.filesToUpload.findIndex(file => file.id === output.file.id);
        this.filesToUpload[index] = output.file;
      }
    } else if (output.type === 'removed') {
      // remove file from array when removed
      this.filesToUpload = this.filesToUpload.filter((file: UploadFile) => file !== output.file);
    } else if (output.type === 'dragOver') {
      this.dragOver = true;
    } else if (output.type === 'dragOut') {
      this.dragOver = false;
    } else if (output.type === 'drop') {
      this.dragOver = false;
    } else if (output.type === 'removedAll') {
      this.filesToUpload.length = 0;
    } else if (output.type === 'done') {
      const fileObject = {
        accountId: this.activeAccount._id,
        fileId: output.file.response
      };
      this.accountService.addImageId(fileObject).subscribe(() => {
        this.removeFile(output.file.id);
        const indexToReplace = this.activeAccount['images'].findIndex(file => file.placeholderId === output.file.id);
        this.activeAccount['images'][indexToReplace] = fileObject;
        this.activeService.decreasePendingRequests();
        this.uploadLoading = false;

        this.activeAccount['images'] = this.activeAccount['images'].filter(image => {
          return !('placeholderId' in image);
        });
      });
    }
  }

  openAddArtworkImage() {
    this.addArtworkImageModalWindow = window.document.getElementById('addArtworkImages');
    UIkit.modal(this.addArtworkImageModalWindow).show();
  }

  removeImage(image) {
    const imageObject = {
      documentType: 'item',
      accountId: this.activeAccount._id,
      _id: image._id
    };

    this.accountService.removeImageId(imageObject).subscribe(
      res => {
        const imageToSplice = this.activeAccount['images'].find((accountImage: any) => {
          return image._id === accountImage._id;
        });
        this.activeAccount['images'].splice(this.activeAccount['images'].indexOf(imageToSplice), 1);
        UIkit.notification({
          message: 'Image removed successfully',
          status: 'success',
          pos: 'top-right',
          timeout: 1500
        });
      },
      error => {
        UIkit.notification({
          message: 'Removing failed',
          status: 'danger',
          pos: 'top-right',
          timeout: 1500
        });
      }
    );
  }

  openImage(attachment) {
    window.open('/api/originalimage/' + this.activeAccount + '/' + attachment.fileId, '_blank');
  }

  resetRegisterUserForm(): void {
    this.registerForm.reset();
    this.registerForm.patchValue({
      registerFormAccount: this.activeAccount._id,
      registerFormEmail: '',
      registerFormPassword: '',
      registerFormRole: ''
    });
  }

  resetEditUserForm(): void {
    this.editUserForm.reset();
    this.editUserForm.patchValue({
      editUserFormAccount: this.activeAccount._id,
      editUserFormId: '',
      editUserFormEmail: '',
      editUserFormPassword: '',
      editUserFormRole: ''
    });
  }

  resetClientGroupForm(): void {
    this.addClientGroupForm.reset();
    this.addClientGroupForm.patchValue({
      addClientGroupFormAccount: this.activeAccount._id,
      addClientGroupFormTitle: '',
      addBidDescription: ''
    });
  }

  resetContactMethodForm(): void {
    this.addContactMethodForm.reset();
    this.addContactMethodForm.patchValue({
      addContactMethodFormAccount: this.activeAccount._id,
      addContactMethodFormTitle: '',
      addBidDescription: ''
    });
  }

  resetVATForm(): void {
    this.addVATForm.reset();
    this.addVATForm.patchValue({
      addVATFormAccount: this.activeAccount._id,
      addVATFormPercentage: '',
      addBidDescription: ''
    });
  }

  resetLanguageForm(): void {
    this.addLanguageForm.reset();
    this.addLanguageForm.patchValue({
      addLanguageFormAccount: this.activeAccount._id,
      addLanguageFormTitle: '',
      addBidDescription: ''
    });
  }

  indexContents() {
    this.isLoadingComponent = true;

    const indexObject = {
      accountId: this.activeAccount._id
    };

    this.searchService.createIndex(indexObject).subscribe(
      () => {
        UIkit.notification({
          message: 'Indexing done',
          status: 'success',
          pos: 'top-right',
          timeout: 1500
        });
      },
      () => {
        UIkit.notification({
          message: 'Error indexing',
          status: 'danger',
          pos: 'top-right',
          timeout: 1500
        });
      },
      () => {
        this.isLoadingComponent = false;
      }
    );
  }

}
