<app-loading [condition]="isLoading"></app-loading>

<app-toast [message]="toast.message"></app-toast>

<div class="card" *ngIf="!isLoading">
  <h4 class="card-header">Registered users ({{users.length}})</h4>
  <div class="card-block">
    <table class="table table-bordered table-striped">
      <thead class="thead-default">
        <tr>
          <th>Username</th>
          <th>Email</th>
          <th>Actions</th>
        </tr>
      </thead>
      <tbody *ngIf="users.length === 0">
        <tr>
          <td colspan="4">There are no registered users.</td>
        </tr>
      </tbody>
      <tbody>
        <tr *ngFor="let user of users">
          <td>{{user.username}}</td>
          <td>{{user.email}}</td>
          <td>
            <button class="btn btn-sm btn-danger" (click)="deleteUser(user)" [disabled]="auth.currentUser._id === user._id">
              <i class="fa fa-trash"></i>
            </button>
          </td>
        </tr>
      </tbody>
    </table>
  </div>
</div>
