import {Component, EventEmitter, OnInit, ViewChild} from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { UserService } from '../../services/user.service';
import { BidService } from '../../services/bid.service';
import { AccountService } from '../../services/account.service';
import { AuthService } from '../../services/auth.service';
import { ActiveService } from '../../services/active.service';
import { FileService } from '../../services/file.service';
import { SearchService } from '../../services/search.service';

import { UploadOutput, UploadInput, UploadFile, humanizeBytes, UploaderOptions } from 'ngx-uploader';
import {AngularMyDatePickerDirective, IAngularMyDpOptions, IMyDateModel} from 'angular-mydatepicker';
import * as moment from 'moment/moment';
import { Observable } from 'rxjs';
import { delay } from 'rxjs/operators';
declare var UIkit: any;

@Component({
  selector: 'app-bids',
  templateUrl: './bids.component.html',
  styleUrls: ['./bids.component.scss']
})
export class BidsComponent implements OnInit {
  public user: any = {};
  public activeBid: any = {};
  private activeUser: string;
  public accountActor: any = {};
  public actorType: string;
  public activeAccount: string;
  public account: Object = {};
  public artists: Array<Object> = [];
  public actors: Array<Object> = [];
  public clients: Array<Object> = [];
  public bids: Array<Object> = [];
  public openBids: Array<Object> = [];
  public VATRates: Array<Object> = [];
  public isLoading = true;
  public addBidForm: FormGroup;
  public addNewBidModalWindow: any;
  public showOpenBidsModalWindow: any;
  public advancedArtworkSearchModalWindow: any;
  public showAdvancedArtworkSearchModalWindow: boolean = false;
  public sortingKey: string;
  public sortAscending: boolean = false;
  public currentPage: number = 1;
  public limitQuery: number = 10;
  public pageCount: number = 1;
  public pages: Array<any> = [];
  public searchArtworkForm: FormGroup;
  public searchArtworkResults: Array<Object> = [];
  public selectedArtworks: Array<Object> = [];
  public searchResultsFound: boolean = false;
  public myDatePickerOptions: IAngularMyDpOptions = {
    dateFormat: 'dd.mm.yyyy'
  };
  private todaysDate: IMyDateModel;
  private validDate: IMyDateModel;
  @ViewChild('AngularMyDatePickerAddBidCreated') AngularMyDatePickerAddBidCreated: AngularMyDatePickerDirective;
  @ViewChild('AngularMyDatePickerAddBidValid') AngularMyDatePickerAddBidValid: AngularMyDatePickerDirective;
  public options: UploaderOptions;
  public formData: FormData;
  public filesToUpload: UploadFile[];
  public uploadInput: EventEmitter<UploadInput>;
  public humanizeBytes: Function;
  public dragOver: boolean;
  public imageLoadOffset$: any;
  public numberWithDecimal = new RegExp(/^[0-9]+(\.[0-9]{1,2})?$/);

  constructor(
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private formBuilder: FormBuilder,
    private auth: AuthService,
    private activeService: ActiveService,
    private userService: UserService,
    private accountService: AccountService,
    private bidService: BidService,
    private fileService: FileService,
    private searchService: SearchService
  ) {
    this.activatedRoute.data.subscribe(data => {
      this.activeAccount = data.resolvedData.account.id;
      this.user = data.resolvedData.user.id;
    });

    this.todaysDate = {
      isRange: false,
      singleDate: {date: {year: new Date().getFullYear(), month: new Date().getMonth() + 1, day: new Date().getDate()}}
    };
    this.validDate = {
      isRange: false,
      singleDate: {date: {year: new Date().getFullYear(), month: new Date().getMonth() + 1, day: new Date().getDate()}}
    };

    this.addBidForm = this.formBuilder.group({
      addBidOwner: new FormControl('', [
        Validators.required,
        Validators.maxLength(100)
      ]),
      addBidName: new FormControl('', [
        Validators.maxLength(100)
      ]),
      addBidDescription: new FormControl('', [
        Validators.maxLength(2048)
      ]),
      addBidType: new FormControl('', [
        Validators.required,
        Validators.maxLength(30)
      ]),
      addBidSeller: new FormControl(null, [
        Validators.required,
        Validators.maxLength(100)
      ]),
      addBidCustomer: new FormControl(null, [
        Validators.required,
        Validators.maxLength(100)
      ]),
      addBidCreated: new FormControl('', [
        Validators.maxLength(10)
      ]),
      addBidValid: new FormControl('', [
        Validators.maxLength(10)
      ]),
      addBidOtherTerms: new FormControl('', [
        Validators.maxLength(2048)
      ]),
      addBidClientReference: new FormControl('', [
        Validators.maxLength(100)
      ]),
      addBidSellerReference: new FormControl('', [
        Validators.maxLength(100)
      ]),
      addBidStatus: new FormControl('', [
        Validators.maxLength(30)
      ]),
      addBidCurrency: new FormControl({value: '', disabled: true}, [
        Validators.maxLength(10)
      ]),
      addBidVAT: new FormControl('', [
        Validators.maxLength(10),
        Validators.pattern(this.numberWithDecimal)
      ]),
      addBidShowNotes: new FormControl( '', [
        Validators.maxLength(100)
      ]),
      addBidPrice: new FormControl('', [
        Validators.maxLength(100),
        Validators.pattern(this.numberWithDecimal)
      ]),
      addBidAttachments: new FormControl('', [
        Validators.maxLength(100)
      ]),
      addBidTags: new FormControl('', [
        Validators.maxLength(2048)
      ]),
      addBidNotes: new FormControl('', [
        Validators.maxLength(2048)
      ]),
      addBidComments: new FormControl('', [
        Validators.maxLength(2048)
      ]),
      addBidVisibility: new FormControl('', [
        Validators.maxLength(10)
      ])
    });

    this.searchArtworkForm = this.formBuilder.group({
      searchArtworkTerm: new FormControl('', [
        Validators.required,
        Validators.minLength(3),
        Validators.maxLength(50)
      ])
    });

    this.activeBid.items = [];
    this.filesToUpload = [];
    this.uploadInput = new EventEmitter<UploadInput>();
    this.humanizeBytes = humanizeBytes;
  }

  ngOnInit() {
    this.isLoading = true;
    this.addBidForm.patchValue({
      addBidOwner: this.activeAccount,
      addBidType: 'Sell',
      addBidCreated: this.todaysDate,
      addBidValid: this.validDate
    });
    this.getAccountData();
  }

  getAccountData() {
    const getObject = {
      accountId: this.activeAccount,
      requester: 'bids',
      sortingKey: this.sortingKey ? this.sortingKey : 'sequence',
      sortingOrder: this.sortAscending ? 'asc' : 'desc',
      skipQuery: (this.currentPage - 1) * this.limitQuery,
      limitQuery: this.limitQuery,
      contentTypes: ['account', 'artist', 'bid', 'bidCount', 'actor', 'client', 'clientCount']
    };

    this.accountService.getAccountData(getObject).subscribe(
      data => {
        this.account = data.account;
        this.VATRates = data.account.VATRates;
        this.artists = data.artists;
        this.bids = data.bids;

        this.bids = this.bids.map((bid: any) => {
          let itemsTotalPrice: number = 0;

          if (bid.items && bid.items.length) {
            for (let i = 0; i < bid.items.length; i++) {
              const item: any = bid.items[i];
              if (item.price && item.price.price) {
                itemsTotalPrice += item.price.price;
              }
            }
          }

          bid.itemsTotalPrice = itemsTotalPrice > 0 ? itemsTotalPrice : "Unknown";

          return bid;
        });

        this.openBids = data.bids.filter(item => {
          return new Date(item.valid) >= new Date();
        });
        this.actors = data.actors;
        this.actors = this.actors.map((actor: any) => {
          let fullName = actor.name;
          if (actor.lastName) { fullName += " " + actor.lastName; }

          return {
            ...actor,
            fullName: fullName
          };
        });

        this.clients = data.clients;
        this.clients = this.clients.map((client: any) => {
          let fullName = client.name;
          if (client.lastName) { fullName += " " + client.lastName; }

          return {
            ...client,
            fullName: fullName
          };
        });

        this.accountActor = data.account.actor;

        this.pageCount = Math.ceil(data.bidCount / this.limitQuery);
        if (this.pageCount > 0) {
          this.pages = Array(this.pageCount).fill(null);
        }

        this.addBidForm.patchValue({
          addBidCurrency: data.account.tradingCurrency,
          addBidVAT: data.account.vat
        });
      },
      () => {
        UIkit.notification({
          message: 'Error fetching clients',
          status: 'danger',
          pos: 'top-right',
          timeout: 1500
        });
      },
      () => {
        this.isLoading = false;
      }
    );

    this.activeService.getActiveData().subscribe(
      data => {
        this.activeUser = data.user.id;
      }
    );
  }

  onDateChanged(event: IMyDateModel) {
    // this.todaysDate = event.singleDate;
  }

  showOpenBids() {
    this.showOpenBidsModalWindow = window.document.getElementById('showOpen');
    UIkit.modal(this.showOpenBidsModalWindow).show();
  }

  openAddNewBid() {
    this.addNewBidModalWindow = window.document.getElementById('addNewBid');
    UIkit.modal(this.addNewBidModalWindow).show();
  }

  addBid() {
    const addBidObject = {
      owner: this.addBidForm.value.addBidOwner,
      name: this.addBidForm.value.addBidName,
      description: this.addBidForm.value.addBidDescription,
      items: this.activeBid.items,
      /* If type = Sell, use account actor as seller; if type = Buy, use selected seller as seller */
      seller: this.addBidForm.value.addBidType === 'Sell' ? this.accountActor._id : this.addBidForm.value.addBidSeller,
      /* If type = Sell, use selected customer as customer; if type = Buy, use account actor as customer */
      customer: this.addBidForm.value.addBidType === 'Sell' ? this.addBidForm.value.addBidCustomer : this.accountActor._id,
      type: this.addBidForm.value.addBidType,
      created: this.addBidForm.value.addBidCreated ? moment(this.addBidForm.value.addBidCreated.singleDate.jsDate).format() : moment(new Date),
      valid: this.addBidForm.value.addBidValid ? moment(this.addBidForm.value.addBidValid.singleDate.jsDate).format() : moment(new Date),
      otherTerms: this.addBidForm.value.addBidOtherTerms,
      clientReference: this.addBidForm.value.addBidClientReference,
      sellerReference: this.addBidForm.value.addBidSellerReference,
      status: this.addBidForm.value.addBidStatus,
      currency: this.addBidForm.value.addBidCurrency,
      vat: this.addBidForm.value.addBidVAT,
      showNotes: this.addBidForm.value.addBidShowNotes,
      price: this.addBidForm.value.addBidPrice,
      /*attachments: this.addBidForm.value.addBidAttachments,*/
      tags: this.addBidForm.value.addBidTags,
      notes: this.addBidForm.value.addBidNotes,
      comments: this.addBidForm.value.addBidComments,
      visibility: this.addBidForm.value.addBidVisibility
    };

    this.bidService.addBid(addBidObject).subscribe(
      res => {
        this.ngOnInit();
        UIkit.modal(this.addNewBidModalWindow).hide();
        this.resetForm();
        UIkit.notification({
          message: 'Saved successfully',
          status: 'success',
          pos: 'top-right',
          timeout: 1500
        });
      },
      error => {
        UIkit.notification({
          message: 'Saving failed',
          status: 'danger',
          pos: 'top-right',
          timeout: 1500
        });
      }
    );
  }

  openBid(bidId): void {
    this.router.navigateByUrl('/bid/' + bidId);
  }

  removeBid(bidId): void {
    this.bidService.deleteBid(bidId).subscribe(
      () => {
        this.ngOnInit();
        UIkit.notification({
          message: 'Bid removed successfully',
          status: 'success',
          pos: 'top-right',
          timeout: 1500
        });
      },
      () => {
        UIkit.notification({
          message: 'Error removing bids',
          status: 'danger',
          pos: 'top-right',
          timeout: 1500
        });
      }
    );
  }

  sortBids(sortingKey: string): void {
    if (sortingKey !== this.sortingKey) {
      this.sortingKey = sortingKey;
    } else {
      this.sortAscending = !this.sortAscending;
    }
    this.getAccountData();
  }

  activeSort(key: string): boolean {
    return this.sortingKey === key;
  }

  resetForm(): void {
    this.addBidForm.reset();
    this.addBidForm.patchValue({
      addBidOwner: this.activeAccount,
      addBidType: 'Sell',
      addBidCreated: this.todaysDate,
      addBidValid: this.validDate
    });

    this.activeBid.items = [];
    this.searchArtworkResults = [];
    this.emptySearch();
  }

  searchArtwork() {
    /*this.isLoading = true;*/
    if (this.searchArtworkForm.value.searchArtworkTerm.trim().length === 0) {
      return
    }

    const searchObject = {
      searchArtworkAccountId: this.activeAccount,
      searchArtworkUserId: this.activeUser,
      searchArtworkTerm: this.searchArtworkForm.value.searchArtworkTerm
    };
    this.searchService.searchArtwork(searchObject).subscribe(
      res => {
        if (res.length) {
          if (this.searchArtworkResults) {
            this.searchArtworkResults.length = 0;
          }
          res.forEach(artwork => {
            artwork.allResultType = 'artwork';
            this.searchArtworkResults.push(artwork);
          });
          /*this.isLoading = false;*/
          this.searchResultsFound = true;
          /*document.getElementById('search-artwork-results-container').classList.add('active');*/
          Array.prototype.forEach.call(document.getElementsByClassName('results-container'), function (element) {
            element.classList.add('active');
          });



          UIkit.notification({
            message: 'Search matched ' + this.searchArtworkResults.length + ' results',
            status: 'success',
            pos: 'top-right',
            timeout: 1500
          });
        } else {
          if (this.searchArtworkResults) {
            this.searchArtworkResults.length = 0;
          }
          UIkit.notification({
            message: 'No search results',
            status: 'danger',
            pos: 'top-right',
            timeout: 1500
          });
        }
      },
      error => {
        if (this.searchArtworkResults) {
          this.searchArtworkResults.length = 0;
        }
        UIkit.notification({
          message: 'No search results',
          status: 'danger',
          pos: 'top-right',
          timeout: 1500
        });
      }
    );
  }

  emptySearch(): void {
    /*document.getElementById('search-artwork-results-container').classList.remove('active');*/
    /*Array.prototype.forEach.call(document.getElementsByClassName('results-container'), function (element) {
      element.classList.remove('active');
    });*/

    /*let element: HTMLElement = document.getElementsByClassName('uk-navbar-toggle uk-close')[1] as HTMLElement;
    element.click();*/
    setTimeout(() => {
      this.searchResultsFound = false;
      if (this.searchArtworkResults) {
        this.searchArtworkResults.length = 0;
      }
      if (this.selectedArtworks) {
        this.selectedArtworks.length = 0;
      }
      this.searchArtworkForm.patchValue({ searchArtworkTerm: ''});
    }, 300);
  }

  selectBidArtwork(artwork): void {
    this.selectedArtworks.indexOf(artwork) === -1 ?
      this.selectedArtworks.push(artwork) : this.selectedArtworks.splice(this.selectedArtworks.indexOf(artwork), 1);
  }

  addArtworksToBid(): void {
    this.selectedArtworks.map((item: any) => {
      const itemFound = this.activeBid.items.find((activeBidItem: any) => {
        return activeBidItem._id === item._id;
      });
      if (itemFound === undefined) {
        this.activeBid.items.push(item);
      }
      const artistFound = this.artists.find((artist: any) => {
        return artist._id === item.artist;
      });
      if (artistFound === undefined) {
        this.artists.push({
          _id: item.artist,
          name: item.artistName,
          priceMultiplier: item.artistPriceMultiplier,
          status: item.artistStatus
        });
      }
    });

    this.emptySearch();
  }

  removeArtworkFromBid(item): void {
    this.activeBid.items.splice(this.activeBid.items.indexOf(item), 1);
    const artistToSplice = this.artists.find((artist: any) => {
      return artist._id === item.artist;
    });
    this.artists.splice(this.artists.indexOf(artistToSplice), 1);
  }

  openSlideshow($event): void {
    $event.preventDefault();
    UIkit.lightboxPanel({
      /* Ei tarvitse, jos muistaa käyttää $event.preventDefaultia
        autoplay: true,
        autoplayInterval: 5000,
        delayControls: 1000,*/
      items: this.activeBid.items.map(item => {
        let itemImage;
        if (item.coverImage) {
          itemImage = '/api/image/' + item.coverImage;
        } else if (item.images[0]) {
          itemImage = '/api/image/' + item.images[0].fileId;
        } else {
          itemImage = '/api/image/artbuddy-logo-placeholder.svg'
        }

        return {
          source: itemImage,
          caption: item.name
        };
      })
    }).show();
  }



  setBidType(): void {
    if (this.addBidForm.value.addBidType === 'Sell') {
      /* If type = Sell, use account actor as seller and use selected customer as customer */
      /*this.addBidForm.patchValue({
        AddBidCustomer: null
      });*/
    } else {
      /* if type = Buy, use selected seller as seller and account actor as customer */
      /*this.addBidForm.patchValue({
        addBidSeller: null
      });*/
    }
  }

  openAdvancedSearch(): void {
    this.showAdvancedArtworkSearchModalWindow = true;
    setTimeout(() => {
      this.advancedArtworkSearchModalWindow = window.document.getElementById('advancedArtworkSearch');
      UIkit.modal(this.advancedArtworkSearchModalWindow).show();
    }, 500);

  }

  setArtworkSearchResults(searchResult: Array<any>) {
    searchResult.forEach(item => this.selectedArtworks.push(item));
    this.addArtworksToBid();
  }

  setLimit(limit: number): void {
    if (limit !== this.limitQuery) {
      this.limitQuery = limit;
      /* Set currentPage to 0 to start from the first page */
      this.currentPage = 1;
      this.getAccountData();
    }
  }

  activeLimit(limit: number): boolean {
    return this.limitQuery === limit;
  }

  setCurrentPageString(page: string): void {
    this.setCurrentPage(parseInt(page, 0));
  }

  setCurrentPage(page: number): void {
    if ((page !== this.currentPage) && (page <= this.pageCount)) {
      this.currentPage = page;
      this.getAccountData();
    }
  }

  updateDate(fieldName: string, dateObject: any) {
    this.addBidForm.controls[fieldName].patchValue(moment(dateObject.singleDate.jsDate).format());
  }

  getCharactersLimitError(fieldName: string) {
    if (!fieldName || !this.addBidForm.get(fieldName).value ) {
      return ''
    }

    const charactersOverLimit: number = this.addBidForm.get(fieldName).value.length - this.addBidForm.get(fieldName).errors.maxlength.requiredLength;

    return `Field length is ${ charactersOverLimit } characters over limit`;
  }

  getCharactersLeft(fieldName: string, charactersLimit: number) {
    if (!fieldName || !this.addBidForm.get(fieldName).value ) {
      return ''
    }

    const fieldLength: number = this.addBidForm.get(fieldName).value.length;
    const charactersOverLimit: number = fieldLength - charactersLimit;

    if (charactersOverLimit >= 0) { return `${ charactersLimit } / ${ charactersLimit }` }

    return `${ fieldLength } / ${ charactersLimit }`;
  }
}
