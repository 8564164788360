<div class="uk-section uk-section-default uk-padding-remove-top" uk-height-viewport="offset-top: true">

  <app-sidenav contentType="Artists"></app-sidenav>

  <div class="uk-container-expand uk-height-expand">
    <div uk-grid>
      <div class="uk-width-expand@m">
        <div class="uk-padding-small uk-padding-remove-top" *ngIf="!isLoading">
          <div class="uk-width-1-1 uk-text-right uk-margin-small-bottom uk-padding-remove">
            <app-search-content contentType="artist" fields="['image', 'name']"></app-search-content>
            <button class="uk-button uk-button-text uk-margin-right" type="button" (click)="openAddNewArtist()" *ngIf="(['trial'].includes(account.pricing) && artists.length < 10) || (['standard'].includes(account.pricing) && artists.length < 100) || (['professional'].includes(account.pricing))">Add new</button>
            <div id="addNewArtist" class="uk-modal-container" uk-modal="bg-close: false; esc-close: false;">
              <div class="uk-modal-dialog">
                <form appNoSubmitEnter [formGroup]="addArtistForm" (ngSubmit)="addArtist()">
                  <input type="hidden" name="addArtistActorType" name="addArtistActorType"
                         formControlName="addArtistActorType" [ngModel]="actorType">
                  <input type="hidden" name="addArtistOwner" name="addArtistOwner" formControlName="addArtistOwner"
                         [ngModel]="activeAccount">
                  <div class="uk-modal-header">
                    <h2 class="uk-modal-title">Add new artist</h2>
                  </div>
                  <div class="uk-modal-body" uk-grid>
                    <div class="uk-width-1-2@s uk-width-1-3@m@s">
                      <div class="uk-margin">
                        <label class="uk-form-label" for="addArtistName">First name <span class="required">*</span></label>
                        <div class="uk-form-controls">
                          <input id="addArtistName" name="addArtistName" formControlName="addArtistName" type="text"
                                 placeholder="First name ..." class="uk-input"
                                 [ngClass]="{'uk-form-danger': addArtistForm.get('addArtistName').touched && addArtistForm.get('addArtistName').errors }" (blur)="validateName()">
                          <div class="uk-text-danger uk-text-small"
                               *ngIf="addArtistForm.get('addArtistName').hasError('maxlength')"
                          >
                            {{ getCharactersLimitError('addArtistName') }}
                          </div>
                          <div class="uk-text-danger uk-text-small"
                               *ngIf="addArtistForm.get('addArtistName').hasError('pattern')"
                          >
                            Field value is incorrect
                          </div>
                          <span *ngIf="addArtistForm.controls.addArtistName.hasError('exists')" class="uk-text-danger uk-text-small">Name might not be unique</span>
                        </div>
                      </div>
                    </div>
                    <div class="uk-width-1-2@s uk-width-1-3@m@s">
                      <div class="uk-margin">
                        <label class="uk-form-label" for="addArtistLastName">Last name <span class="required">*</span></label>
                        <div class="uk-form-controls">
                          <input id="addArtistLastName" name="addArtistLastName" formControlName="addArtistLastName" type="text"
                                 placeholder="Last name ..." class="uk-input"
                                 [ngClass]="{'uk-form-danger': addArtistForm.get('addArtistLastName').touched && addArtistForm.get('addArtistLastName').errors }" (blur)="validateName()">
                          <div class="uk-text-danger uk-text-small"
                               *ngIf="addArtistForm.get('addArtistLastName').hasError('maxlength')"
                          >
                            {{ getCharactersLimitError('addArtistLastName') }}
                          </div>
                          <div class="uk-text-danger uk-text-small"
                               *ngIf="addArtistForm.get('addArtistLastName').hasError('pattern')"
                          >
                            Field value is incorrect
                          </div>
                          <span *ngIf="addArtistForm.controls.addArtistLastName.hasError('exists')" class="uk-text-danger uk-text-small">Name might not be unique</span>
                        </div>
                      </div>
                    </div>
                    <div class="uk-width-1-2@s uk-width-1-3@m@s">
                      <div class="uk-margin">
                        <label class="uk-form-label" for="addArtistType">Type</label>
                        <div class="uk-form-controls">
                          <select id="addArtistType" name="addArtistType"
                                  formControlName="addArtistType" class="uk-select">
                            <option value="" disabled>Please select ...</option>
                            <option value="Represented">Represented</option>
                            <option value="Visiting">Visiting</option>
                          </select>
                        </div>
                      </div>
                    </div>
                    <div class="uk-width-1-2@s uk-width-1-3@m@s">
                      <div class="uk-margin">
                        <label class="uk-form-label" for="addArtistCode">Artist code</label>
                        <div class="uk-form-controls">
                          <input id="addArtistCode" name="addArtistCode" formControlName="addArtistCode" type="text"
                                 placeholder="Code ..." class="uk-input"
                                 [ngClass]="{'uk-form-danger': addArtistForm.get('addArtistCode').touched && addArtistForm.get('addArtistCode').errors }">
                          <div class="uk-text-danger uk-text-small"
                               *ngIf="addArtistForm.get('addArtistCode').hasError('maxlength')"
                          >
                            {{ getCharactersLimitError('addArtistCode') }}
                          </div>
                        </div>
                      </div>
                    </div>
<!--                    <div class="uk-width-1-2@s uk-width-1-3@m@s">-->
<!--                      <div class="uk-margin">-->
<!--                        <label class="uk-form-label" for="addArtistBirthday">Birthday</label>-->
<!--                        <div class="uk-form-controls">-->
<!--                          <input-->
<!--                            id="addArtistBirthday"-->
<!--                            name="addArtistBirthday"-->
<!--                            formControlName="addArtistBirthday"-->
<!--                            type="text"-->
<!--                            placeholder="Birthday ..."-->
<!--                            class="uk-input"-->
<!--                            [ngClass]="{'uk-form-danger': addArtistForm.get('addArtistBirthday').touched && addArtistForm.get('addArtistBirthday').errors }"-->
<!--                            angular-mydatepicker-->
<!--                            [options]="myDatePickerOptions"-->
<!--                            #AngularMyDatePickerAddArtistBirthday="angular-mydatepicker"-->
<!--                            (click)="AngularMyDatePickerAddArtistBirthday.toggleCalendar()"-->
<!--                            (dateChanged)="updateDate('addArtistBirthday', $event)"-->
<!--                          />-->
<!--                          <div class="uk-text-danger uk-text-small"-->
<!--                               *ngIf="addArtistForm.get('addArtistBirthday').hasError('maxlength')"-->
<!--                          >-->
<!--                            {{ getCharactersLimitError('addArtistBirthday') }}-->
<!--                          </div>-->
<!--                        </div>-->
<!--                      </div>-->
<!--                    </div>-->
                    <div class="uk-width-1-2@s uk-width-1-3@m@s">
                      <div class="uk-margin">
                        <label class="uk-form-label" for="addArtistStreet">Street address</label>
                        <div class="uk-form-controls">
                          <input id="addArtistStreet" name="addArtistStreet" formControlName="addArtistStreet"
                                 type="text" placeholder="Street address ..." class="uk-input"
                                 [ngClass]="{'uk-form-danger': addArtistForm.get('addArtistStreet').touched && addArtistForm.get('addArtistStreet').errors }">
                          <div class="uk-text-danger uk-text-small"
                               *ngIf="addArtistForm.get('addArtistStreet').hasError('maxlength')"
                          >
                            {{ getCharactersLimitError('addArtistStreet') }}
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="uk-width-1-2@s uk-width-1-3@m@s">
                      <div class="uk-margin">
                        <label class="uk-form-label" for="addArtistZip">Postal code</label>
                        <div class="uk-form-controls">
                          <input id="addArtistZip" name="addArtistZip" formControlName="addArtistZip" type="text"
                                 placeholder="Postal code ..." class="uk-input"
                                 [ngClass]="{'uk-form-danger': addArtistForm.get('addArtistZip').touched && addArtistForm.get('addArtistZip').errors }">
                          <div class="uk-text-danger uk-text-small"
                               *ngIf="addArtistForm.get('addArtistZip').hasError('maxlength')"
                          >
                            {{ getCharactersLimitError('addArtistZip') }}
                          </div>
                          <div class="uk-text-danger uk-text-small"
                               *ngIf="addArtistForm.get('addArtistZip').hasError('pattern')"
                          >
                            Field value is incorrect
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="uk-width-1-2@s uk-width-1-3@m@s">
                      <div class="uk-margin">
                        <label class="uk-form-label" for="addArtistCountry">Country</label>
                        <div class="uk-form-controls">
                          <input id="addArtistCountry" name="addArtistCountry" formControlName="addArtistCountry" type="text"
                                 placeholder="Country ..." class="uk-input"
                                 [ngClass]="{'uk-form-danger': addArtistForm.get('addArtistCountry').touched && addArtistForm.get('addArtistCountry').errors }">
                          <div class="uk-text-danger uk-text-small"
                               *ngIf="addArtistForm.get('addArtistCountry').hasError('maxlength')"
                          >
                            {{ getCharactersLimitError('addArtistCountry') }}
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="uk-width-1-2@s uk-width-1-3@m@s">
                      <div class="uk-margin">
                        <label class="uk-form-label" for="addArtistCity">City</label>
                        <div class="uk-form-controls">
                          <input id="addArtistCity" name="addArtistCity" formControlName="addArtistCity" type="text"
                                 placeholder="City ..." class="uk-input"
                                 [ngClass]="{'uk-form-danger': addArtistForm.get('addArtistCity').touched && addArtistForm.get('addArtistCity').errors }">
                          <div class="uk-text-danger uk-text-small"
                               *ngIf="addArtistForm.get('addArtistCity').hasError('maxlength')"
                          >
                            {{ getCharactersLimitError('addArtistCity') }}
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="uk-width-1-2@s uk-width-1-3@m@s">
                      <div class="uk-margin">
                        <label class="uk-form-label" for="addArtistState">State</label>
                        <div class="uk-form-controls">
                          <input id="addArtistState" name="addArtistState" formControlName="addArtistState" type="text"
                                 placeholder="State ..." class="uk-input"
                                 [ngClass]="{'uk-form-danger': addArtistForm.get('addArtistState').touched && addArtistForm.get('addArtistState').errors }">
                          <div class="uk-text-danger uk-text-small"
                               *ngIf="addArtistForm.get('addArtistState').hasError('maxlength')"
                          >
                            {{ getCharactersLimitError('addArtistState') }}
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="uk-width-1-2@s uk-width-1-3@m@s">
                      <div class="uk-margin">
                        <label class="uk-form-label" for="addArtistPhone">Phone number</label>
                        <div class="uk-form-controls">
                          <input id="addArtistPhone" name="addArtistPhone" formControlName="addArtistPhone" type="text"
                                 placeholder="Phone number ..." class="uk-input"
                                 [ngClass]="{'uk-form-danger': addArtistForm.get('addArtistPhone').touched && addArtistForm.get('addArtistPhone').errors }">
                          <div class="uk-text-danger uk-text-small"
                               *ngIf="addArtistForm.get('addArtistPhone').hasError('maxlength')"
                          >
                            {{ getCharactersLimitError('addArtistPhone') }}
                          </div>
                          <div class="uk-text-danger uk-text-small"
                               *ngIf="addArtistForm.get('addArtistPhone').hasError('pattern')"
                          >
                            Field value is incorrect
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="uk-width-1-2@s uk-width-1-3@m@s">
                      <div class="uk-margin">
                        <label class="uk-form-label" for="addArtistEmail">Email</label>
                        <div class="uk-form-controls">
                          <input id="addArtistEmail" name="addArtistEmail" formControlName="addArtistEmail" type="text"
                                 placeholder="Email ..." class="uk-input"
                                 [ngClass]="{'uk-form-danger': addArtistForm.get('addArtistEmail').touched && addArtistForm.get('addArtistEmail').errors }">
                          <div class="uk-text-danger uk-text-small"
                               *ngIf="addArtistForm.get('addArtistEmail').hasError('maxlength')"
                          >
                            {{ getCharactersLimitError('addArtistEmail') }}
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="uk-width-1-2@s uk-width-1-3@m@s">
                      <div class="uk-margin">
                        <div class="uk-flex uk-flex-between uk-text-meta">
                        <label class="uk-form-label" for="addArtistDescription">Description</label>
                        <div class="uk-text-small">
                          {{ getCharactersLeft('addArtistDescription', 2048) }}
                        </div>
                        </div>
                        <div class="uk-form-controls">
                          <textarea id="addArtistDescription" name="addArtistDescription"
                                    formControlName="addArtistDescription" rows="5" placeholder="Description ..."
                                    class="uk-textarea"
                                    [ngClass]="{'uk-form-danger': addArtistForm.get('addArtistDescription').touched && addArtistForm.get('addArtistDescription').errors }"></textarea>
                          <div class="uk-text-danger uk-text-small"
                               *ngIf="addArtistForm.get('addArtistDescription').hasError('maxlength')"
                          >
                            {{ getCharactersLimitError('addArtistDescription') }}
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="uk-width-1-2@s uk-width-1-3@m@s">
                      <div class="uk-margin">
                        <label class="uk-form-label" for="addArtistPriceMultiplier">Price multiplier</label>
                        <div class="uk-form-controls">
                          <ng2-completer id="addArtistPriceMultiplier" name="addArtistPriceMultiplier" formControlName="addArtistPriceMultiplier" [datasource]="variables.priceMultipliers" [minSearchLength]="0" [textNoResults]="null"></ng2-completer>
                          <div class="uk-text-danger uk-text-small"
                               *ngIf="addArtistForm.get('addArtistPriceMultiplier').hasError('maxlength')"
                          >
                            {{ getCharactersLimitError('addArtistPriceMultiplier') }}
                          </div>
                          <div class="uk-text-danger uk-text-small"
                               *ngIf="addArtistForm.get('addArtistPriceMultiplier').hasError('pattern')"
                          >
                            Field value is incorrect
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="uk-width-1-1">
                      <label class="uk-form-label">Artist images</label>
                    </div>
                    <div class="uk-width-1-1">
                      <div class="uk-position-relative uk-visible-toggle" uk-slider>
                        <ul class="uk-slider-items uk-child-width-1-2@s uk-child-width-1-3@m uk-grid">
                          <li class="uk-text-center" *ngFor="let image of activeArtist.images; let index = index">
                            <div class="uk-inline-clip uk-transition-toggle uk-width-1-1" tabindex="index" *ngIf="image.fileId">
                              <div class="uk-background-contain uk-background-muted uk-height-medium uk-panel uk-flex uk-flex-center uk-flex-middle" [attr.data-src]="'/api/storagefile/' + activeAccount + '/' + image.fileId + '/medium'" uk-img>
                      </div>
                              <div class="uk-position-top-right uk-overlay uk-overlay-default uk-padding-small" *ngIf="image.fileId == activeArtist.coverImage">Cover image</div>
                              <div class="uk-transition-slide-bottom uk-position-bottom uk-overlay uk-overlay-default" *ngIf="image.fileId !== activeArtist.coverImage">
                                <button type="button" (click)="selectCover(image.fileId)" class="uk-button uk-button-text">Select cover</button>
                              </div>
                            </div>
                          </li>
                        </ul>
                        <a class="uk-position-center-left uk-position-small uk-hidden-hover" uk-slidenav-previous uk-slider-item="previous"></a>
                        <a class="uk-position-center-right uk-position-small uk-hidden-hover" uk-slidenav-next uk-slider-item="next"></a>
                      </div>
                    </div>
                    <div class="uk-width-1-1">
                      <div class="uk-margin">
                        <p *ngIf="!activeArtist.images.length">No artist images</p>
                        <div class="uk-form-controls">
                          <div class="drop-container" ngFileDrop [options]="{ concurrency: 1, allowedContentTypes: ['image/jpeg', 'image/jpg', 'image/png', 'image/gif'], maxFileSize: 3000000 }" (uploadOutput)="onUploadOutput($event)" (click)="imageFile.click()" [uploadInput]="uploadInput">
                            <div class="js-upload uk-placeholder uk-text-center" [ngClass]="{ 'is-drop-over': dragOver }">
                              <span class="uk-text-middle">Add images by dropping them here or open file dialog by clicking here.<br>Allowed filetypes include JPG, PNG and GIF.<br>Maximum allowed file size is 5 Mb.</span>
                            </div>
                            <input #imageFile type="file" ngFileSelect [options]="{ concurrency: 1, allowedContentTypes: ['image/jpeg', 'image/jpg', 'image/png', 'image/gif'], maxFileSize: 5000000 }" [uploadInput]="uploadInput" (uploadOutput)="onUploadOutput($event)" multiple class="file-input">
                          </div>
                        </div>
<!--                        <progress id="js-progressbar" class="uk-progress" value="0" max="100" hidden></progress>-->
<!--                        <div class="table-container" *ngIf="filesToUpload.length">-->
<!--                          <table class="uk-table uk-table-middle uk-margin-top">-->
<!--                            <caption>Files to upload</caption>-->
<!--                            <thead>-->
<!--                            <tr>-->
<!--                              <th class="uk-table-shrink uk-text-capitalize">Image</th>-->
<!--                              <th class="uk-width-expand uk-text-capitalize">Name</th>-->
<!--                              <th class="uk-width-expand uk-text-capitalize">Size</th>-->
<!--                              <th class="uk-table-shrink uk-text-capitalize"></th>-->
<!--                            </tr>-->
<!--                            </thead>-->
<!--                            <tbody>-->
<!--                            <tr *ngFor="let file of filesToUpload; let i = index;">-->
<!--                              <td class="uk-background-muted"><img class="uk-preserve-width uk-border-circle" src="/api/image/artbuddy-logo-placeholder.svg" width="60" uk-img></td>-->
<!--                              <td>{{ file.name }}</td>-->
<!--                              <td class="uk-text-nowrap">{{ file.size | bytes: 2 }}</td>-->
<!--                              <td><button (click)="removeFile(file.id)" class="uk-button uk-button-default" type="button">Delete</button></td>-->
<!--                            </tr>-->
<!--                            </tbody>-->
<!--                          </table>-->
<!--                          <button type="button" (click)="startUpload()" class="uk-button uk-button-primary">Start upload</button>-->
<!--                        </div>-->
                      </div>
                    </div>
                    <div class="uk-width-1-1 uk-margin-medium-top">
                      <h3 class="uk-margin-remove-bottom">Additional information</h3>
                      <hr class="uk-margin-small-top">
                    </div>
                    <div class="uk-width-1-2@s uk-width-1-3@m">
                      <div class="uk-margin">
                        <div class="uk-flex uk-flex-between uk-text-meta">
                        <label class="uk-form-label" for="addArtistNotes">Other information</label>
                        <div class="uk-text-small">
                          {{ getCharactersLeft('addArtistNotes', 2048) }}
                        </div>
                        </div>
                        <div class="uk-form-controls">
                          <textarea id="addArtistNotes" name="addArtistNotes" formControlName="addArtistNotes" rows="5" type="text" placeholder="Other information ..." class="uk-textarea" [ngClass]="{'uk-form-danger': addArtistForm.get('addArtistNotes').touched && addArtistForm.get('addArtistNotes').errors }"></textarea>
                          <div class="uk-text-danger uk-text-small"
                               *ngIf="addArtistForm.get('addArtistNotes').hasError('maxlength')"
                          >
                            {{ getCharactersLimitError('addArtistNotes') }}
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="uk-width-1-2@s uk-width-1-3@m">
                      <div class="uk-margin">
                        <div class="uk-flex uk-flex-between uk-text-meta">
                        <label class="uk-form-label" for="addArtistComments">Comments</label>
                          <div class="uk-text-small">
                            {{ getCharactersLeft('addArtistComments', 2048) }}
                          </div>
                          </div>
                        <div class="uk-form-controls">
                          <textarea id="addArtistComments" name="addArtistComments" formControlName="addArtistComments" rows="5" type="text" placeholder="Comments ..." class="uk-textarea" [ngClass]="{'uk-form-danger': addArtistForm.get('addArtistComments').touched && addArtistForm.get('addArtistComments').errors }"></textarea>
                          <div class="uk-text-danger uk-text-small"
                               *ngIf="addArtistForm.get('addArtistComments').hasError('maxlength')"
                          >
                            {{ getCharactersLimitError('addArtistComments') }}
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="uk-width-1-2@s uk-width-1-3@m">
                      <div class="uk-margin">
                        <label class="uk-form-label" for="addArtistTags">Tags</label>
                        <div class="uk-form-controls">
                          <input id="addArtistTags" name="addArtistTags" formControlName="addArtistTags" type="text" placeholder="Tags ..." class="uk-input" [ngClass]="{'uk-form-danger': addArtistForm.get('addArtistTags').touched && addArtistForm.get('addArtistTags').errors }">
                          <div class="uk-text-danger uk-text-small"
                               *ngIf="addArtistForm.get('addArtistTags').hasError('maxlength')"
                          >
                            {{ getCharactersLimitError('addArtistTags') }}
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="uk-width-1-2@s uk-width-1-3@m@s">
                      <div class="uk-margin">
                        <label class="uk-form-label" for="addArtistStatus">Status</label>
                        <div class="uk-form-controls">
                          <label><input id="addArtistStatus" name="addArtistStatus" formControlName="addArtistStatus"
                                        class="uk-checkbox" type="checkbox"> Active</label>
                        </div>
                      </div>
                    </div>
                    <div class="uk-width-1-2@s uk-width-1-3@m">
                      <div class="uk-margin">
                        <label class="uk-form-label" for="addArtistVisibility">Visibility</label>
                        <div class="uk-form-controls">
                          <label><input id="addArtistVisibility" name="addArtistVisibility" formControlName="addArtistVisibility" class="uk-checkbox" type="checkbox"> Public</label>
                        </div>
                      </div>
                    </div>

                  </div>
                  <div class="uk-modal-footer">
                    <p class="uk-text-right">
                      <button class="uk-button uk-button-default uk-modal-close uk-margin-small-right" type="button" id="addNewArtistCancel" (click)="resetForm()">Cancel</button>
                      <button type="submit" [disabled]="!addArtistForm.valid || uploadLoading" class="uk-button uk-button-primary">Save
                      </button>
                    </p>
                  </div>
                </form>
              </div>
            </div>
            <button class="uk-button uk-button-text uk-margin-right" type="button">Sort <span uk-icon="icon: chevron-down"></span></button>
            <div uk-dropdown="mode: click;">
              <ul class="uk-nav uk-dropdown-nav">
<!--                <li [ngClass]="{'uk-active': activeSort('created') }" (click)="sortItems('created')"><a>Created</a></li>-->
                <li [ngClass]="{'uk-active': activeSort('name') }" (click)="sortItems('name')"><a>Name</a></li>
                <li [ngClass]="{'uk-active': activeSort('multiplier') }" (click)="sortItems('multiplier')"><a>Multiplier</a>
                </li>
                <li [ngClass]="{'uk-active': activeSort('status') }" (click)="sortItems('status')"><a
                 >Status</a></li>
              </ul>
            </div>
            <button class="uk-button uk-button-text" type="button">Limit {{ limitQuery ? ': ' + limitQuery : null }} <span uk-icon="icon: chevron-down"></span></button>
            <div uk-dropdown="mode: click;">
              <ul class="uk-nav uk-dropdown-nav">
                <li [ngClass]="{'uk-active': activeLimit(10) }" (click)="setLimit(10)"><a>10</a></li>
                <li [ngClass]="{'uk-active': activeLimit(25) }" (click)="setLimit(25)"><a>25</a></li>
                <li [ngClass]="{'uk-active': activeLimit(50) }" (click)="setLimit(50)"><a>50</a></li>
                <li [ngClass]="{'uk-active': activeLimit(100) }" (click)="setLimit(100)"><a>100</a></li>
                <li [ngClass]="{'uk-active': activeLimit(200) }" (click)="setLimit(200)"><a>200</a></li>
              </ul>
            </div>
          </div>
          <div class="uk-height-1-1">
          <table class="uk-table uk-table-middle uk-table-divider">
            <thead>
            <tr>
              <!--<th class="uk-table-shrink"></th>-->
              <th class="uk-table-shrink">Image</th>
              <th class="uk-width-expand uk-text-nowrap" [ngClass]="{'active': activeSort('name') }" (click)="sortItems('name')">
                Name<span uk-icon="chevron-down" [ngClass]="{'inverse': !sortAscending }"></span></th>
              <th class="uk-width-expand uk-text-nowrap uk-visible@m" [ngClass]="{'active': activeSort('multiplier') }"
                  (click)="sortItems('multiplier')">Multiplier<span uk-icon="chevron-down"
                                                                    [ngClass]="{'inverse': !sortAscending }"></span>
              </th>
              <th class="uk-table-shrink uk-text-nowrap uk-visible@m" [ngClass]="{'active': activeSort('status') }"
                  (click)="sortItems('status')">Status<span uk-icon="chevron-down"
                                                            [ngClass]="{'inverse': !sortAscending }"></span></th>
              <th class="uk-table-shrink"></th>
            </tr>
            </thead>
            <tbody>
            <tr *ngFor="let artist of artists">
              <td (click)="openArtist(artist._id)" class="pointer">
                <!-- Näytetään coverImage jos on -->
                <div class="image-max-height uk-preserve-width uk-background-contain uk-background-muted uk-height-small uk-panel uk-flex uk-flex-center uk-flex-middle" width="60" [attr.data-src]="'/api/storagefile/' + activeAccount + '/' + artist.coverImage + '/small'" uk-img *ngIf="artist.coverImage">
                </div>
                <!-- Jos ei ole coverImagea, niin sit otetaan eka image -->
                <div class="image-max-height uk-preserve-width uk-background-contain uk-background-muted uk-height-small uk-panel uk-flex uk-flex-center uk-flex-middle" width="60" [attr.data-src]="'/api/storagefile/' + activeAccount + '/' + artist.images[0].fileId + '/small'" uk-img *ngIf="!artist.coverImage && artist.images && artist.images.length">
                </div>
                <!-- Jos ei ole coverImagea tai ekan imagea, ni sit otetaan placeholder -->
                <div class="image-max-height uk-preserve-width uk-background-contain uk-background-muted uk-height-small uk-panel uk-flex uk-flex-center uk-flex-middle" width="60" [attr.data-src]="'/api/image/artbuddy-logo-placeholder.svg'" uk-img *ngIf="!artist.coverImage && !artist.images">
                </div>
              </td>
              <td (click)="openArtist(artist._id)" class="pointer">{{ artist.name }} {{ artist.lastName }}</td>
              <td class="uk-visible@m">{{ artist.priceMultiplier }}</td>
              <td class="uk-visible@m">
                <span *ngIf="artist.status">Active</span>
                <span *ngIf="!artist.status">Inactive</span>
              </td>
              <td class="uk-text-right">
                <div class="uk-button-group">
                  <button (click)="openArtist(artist._id)" class="uk-button uk-button-text uk-margin-right" type="button">Open</button>
                  <div class="uk-inline" class="uk-visible@m">
                    <button class="uk-button uk-button-link" type="button"><span uk-icon="icon: chevron-down"></span></button>
                    <div uk-dropdown="mode: click; boundary: ! .uk-button-group; boundary-align: true;">
                      <ul class="uk-nav uk-dropdown-nav">
                        <li><a (click)="removeArtist(artist._id)">Remove</a></li>
                      </ul>
                    </div>
                  </div>
                </div>
              </td>
            </tr>
            </tbody>
          </table>
          </div>

          <ul class="uk-pagination uk-flex-center uk-flex-middle uk-margin-medium-top" *ngIf="pages.length > 2">
            <li class="uk-margin-small-right" [ngClass]="{'uk-disabled': (currentPage === 1) }">
              <a (click)="setCurrentPage(currentPage-1)">
                <span class="uk-margin-small-right" uk-pagination-previous></span> Previous
              </a>
            </li>
            <li>
              <select class="uk-select" [ngModel]="currentPage" (change)="setCurrentPageString($event.target.value)">
                <option *ngFor="let page of pages; let index = index; first as isFirst; last as isLast" [value]="index+1">{{ index+1 }}</option>
              </select>
            </li>
            <li class="uk-margin-small-left" [ngClass]="{'uk-disabled': (currentPage === pageCount) }">
              <a (click)="setCurrentPage(currentPage+1)">
                Next <span class="uk-margin-small-left" uk-pagination-next></span>
              </a>
            </li>
          </ul>
        </div>
      </div>
    </div>
  </div>
</div>
