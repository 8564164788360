/* Core */
import {CUSTOM_ELEMENTS_SCHEMA, NgModule} from '@angular/core';
import {JwtModule} from '@auth0/angular-jwt';
import {HTTP_INTERCEPTORS, HttpClientModule} from '@angular/common/http';
import {RoutingModule} from './routing.module';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';

/* Services */
import {ResolverService} from './services/resolver.service';
import {CustomHttpInterceptor} from './services/http.interceptor';
import {ActiveService} from './services/active.service';
import {SharedModule} from './shared-components/shared.module';
import {UserService} from './services/user.service';
import {AccountService} from './services/account.service';
import {ActorService} from './services/actor.service';
import {ItemService} from './services/item.service';
import {ExhibitionService} from './services/exhibition.service';
import {CollectionService} from './services/collection.service';
import {EstimateService} from './services/estimate.service';
import {BidService} from './services/bid.service';
import {ReservationService} from './services/reservation.service';
import {TradeService} from './services/trade.service';
import {AuthService} from './services/auth.service';
import {SearchService} from './services/search.service';
import {FileService} from './services/file.service';
import {AuthGuardLogin} from './services/auth-guard-login.service';
import {AuthGuardAdmin} from './services/auth-guard-admin.service';

import {NgxUploaderModule} from 'ngx-uploader';
import {NgPipesModule} from 'ngx-pipes';
import {AngularMyDatePickerModule} from 'angular-mydatepicker';
import {LazyLoadImageModule} from 'ng-lazyload-image';
import {Ng2CompleterModule} from 'ng2-completer';
import {NgAisModule} from 'angular-instantsearch';
import {NgSelectModule} from '@ng-select/ng-select';
// import { Http, RequestOptions } from '@angular/http';
// import { AuthHttp, AUTH_PROVIDERS, provideAuth, AuthConfig } from 'angular2-jwt/angular2-jwt';
// export function authHttpServiceFactory(http: Http, options: RequestOptions) {
//   return new AuthHttp(new AuthConfig(), http, options);
// }
/* Components */
import {AppComponent} from './app.component';
import {RegisterComponent} from './register/register.component';
import {LoginComponent} from './login/login.component';
import {ForgotComponent} from './forgot/forgot.component';
import {LogoutComponent} from './logout/logout.component';
import {AccountsComponent} from './accounts/all-accounts/accounts.component';
import {SingleAccountComponent} from './accounts/single-account/single-account.component';
import {ProfileComponent} from './profile/profile.component';
import {AdminComponent} from './admin/admin.component';
import {NotFoundComponent} from './not-found/not-found.component';
import {FileUploadComponent} from './services/uploadComponent';
import {OverviewComponent} from './overview/overview.component';
import {HeaderComponent} from './header/header.component';
import {SidenavComponent} from './shared-components/sidenav/sidenav.component';
import {SidebarComponent} from './shared-components/sidebar/sidebar.component';
import {SingleExhibitionComponent} from './exhibitions/single-exhibition/single-exhibition.component';
import {ExhibitionsComponent} from './exhibitions/all-exhibitions/exhibitions.component';
import {CollectionsComponent} from './collections/all-collections/collections.component';
import {SingleCollectionComponent} from './collections/single-collection/single-collection.component';
import {EstimatesComponent} from './estimates/all-estimates/estimates.component';
import {SingleEstimateComponent} from './estimates/single-estimate/single-estimate.component';
import {SingleBidComponent} from './bids/single-bid/single-bid.component';
import {BidsComponent} from './bids/all-bids/bids.component';
import {SingleReservationComponent} from './reservations/single-reservation/single-reservation.component';
import {ReservationsComponent} from './reservations/all-reservations/reservations.component';
import {SingleTradeComponent} from './trades/single-trade/single-trade.component';
import {TradesComponent} from './trades/all-trades/trades.component';
import {ArtistsComponent} from './artists/all-artists/artists.component';
import {SingleArtistComponent} from './artists/single-artist/single-artist.component';
import {ArtworksComponent} from './artworks/all-artworks/artworks.component';
import {SingleArtworkComponent} from './artworks/single-artwork/single-artwork.component';
import {ClientsComponent} from './clients/all-clients/clients.component';
import {SingleClientComponent} from './clients/single-client/single-client.component';
import {SafeUrlPipe} from './services/safe-url.pipe';
import {SecurePipe} from './services/secure.pipe';
import {NoSubmitDirective} from './shared-components/no-submit.directive';
import {TermsComponent} from './terms/terms.component';
import {SearchAisMenuComponent} from './header/search-components/search-ais-menu.component';
import {SearchAisRangeInputComponent} from './header/search-components/search-ais-range-input.component';
import {SearchAisRefinementListComponent} from './header/search-components/search-ais-refinement-list.component';
import {SearchAisStatsComponent} from './header/search-components/search-ais-stats.component';
import {SearchAisHitsPerPageComponent} from './header/search-components/search-ais-hits-per-page.component';
import {SearchAisClearRefinements} from './header/search-components/search-ais-clear-refinements.component';
import {SearchAisCurrentRefinementsComponent} from './header/search-components/search-ais-current-refinements.component';
import {SearchAisSortByComponent} from './header/search-components/search-ais-sort.component';
import {SearchAisRefreshResults} from './header/search-components/search-ais-refresh.component';
import {AdvancedSearchWrapperComponent} from './header/search-components/advanced-search-wrapper/advanced-search-wrapper.component';
import {AdvancedSearchModalComponent} from './header/search-components/advanced-search-modal/advanced-search-modal.component';
import {SearchComponent} from './shared-components/search/search.component';
import {SearchAisSearchBoxComponent} from './header/search-components/search-ais-search-box';
import {SearchAisSearchContentComponent} from './header/search-components/search-ais-search-content-box';

export function tokenGetter() {
  return localStorage.getItem('token');
}

export async function loadImage({imagePath}) {
  return await fetch(imagePath, {
    headers: {
      Authorization: 'Bearer ' + localStorage.getItem('token')
    }
  })
    .then(res => {
      if (res.ok) {
        return res.blob();
      }
      throw new Error('Image could not be loaded');
    })
    .then(blob => URL.createObjectURL(blob));
}

@NgModule({
  declarations: [
    AppComponent,
    RegisterComponent,
    LoginComponent,
    ForgotComponent,
    LogoutComponent,
    AccountsComponent,
    SingleAccountComponent,
    ProfileComponent,
    AdminComponent,
    NotFoundComponent,
    OverviewComponent,
    HeaderComponent,
    SidenavComponent,
    SidebarComponent,
    OverviewComponent,
    ExhibitionsComponent,
    SingleExhibitionComponent,
    CollectionsComponent,
    SingleCollectionComponent,
    EstimatesComponent,
    SingleEstimateComponent,
    BidsComponent,
    SingleBidComponent,
    ReservationsComponent,
    SingleReservationComponent,
    TradesComponent,
    SingleTradeComponent,
    ArtistsComponent,
    SingleArtistComponent,
    ArtworksComponent,
    SingleArtworkComponent,
    ClientsComponent,
    SingleClientComponent,
    FileUploadComponent,
    SafeUrlPipe,
    SecurePipe,
    NoSubmitDirective,
    TermsComponent,
    SearchAisMenuComponent,
    SearchAisRangeInputComponent,
    SearchAisRefinementListComponent,
    SearchAisStatsComponent,
    SearchAisHitsPerPageComponent,
    SearchAisClearRefinements,
    SearchAisCurrentRefinementsComponent,
    SearchAisSortByComponent,
    SearchAisRefreshResults,
    AdvancedSearchWrapperComponent,
    AdvancedSearchModalComponent,
    SearchComponent,
    SearchAisSearchBoxComponent,
    SearchAisSearchContentComponent
  ],
  imports: [
    RoutingModule,
    HttpClientModule,
    SharedModule,
    ReactiveFormsModule,
    FormsModule,
    NgxUploaderModule,
    NgPipesModule,
    AngularMyDatePickerModule,
    LazyLoadImageModule,
    Ng2CompleterModule,
    NgSelectModule,
    JwtModule.forRoot({
      config: {
        tokenGetter: tokenGetter,
        allowedDomains: ['https://artbuddy.com', 'localhost:4400']
        // blacklistedRoutes: ['localhost:3001/auth/']
      }
    }),
    NgAisModule.forRoot()
  ],
  providers: [
    ResolverService,
    ActiveService,
    AuthService,
    AuthGuardLogin,
    AuthGuardAdmin,
    UserService,
    SearchService,
    FileService,
    AccountService,
    ActorService,
    ItemService,
    ExhibitionService,
    CollectionService,
    EstimateService,
    BidService,
    ReservationService,
    TradeService,
    {
      provide: HTTP_INTERCEPTORS,
      useClass: CustomHttpInterceptor,
      multi: true
    },
    /*AUTH_PROVIDERS*/
    // {
    //   provide: AuthHttp,
    //   useFactory: authHttpServiceFactory,
    //   deps: [Http, RequestOptions]
    // }
  ],
  entryComponents: [
    AdvancedSearchWrapperComponent
  ],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
  bootstrap: [AppComponent]
})

export class AppModule {
}
