import { Component, OnInit, Output, EventEmitter } from '@angular/core';
import { Router, NavigationEnd } from '@angular/router';
import { FormGroup, FormControl, Validators, FormBuilder } from '@angular/forms';
import { AuthService } from 'app/services/auth.service';
import { ActiveService } from 'app/services/active.service';
import { AccountService } from 'app/services/account.service';
import { SearchService } from 'app/services/search.service';
import { CollectionService } from '../../../services/collection.service';
import { BidService } from '../../../services/bid.service';
import { FileService } from '../../../services/file.service';

import {environment} from 'environments/environment';

declare var UIkit: any;
import * as moment from 'moment/moment';
import algoliasearch from 'algoliasearch/lite';

@Component({
  selector: 'app-advanced-search-modal',
  templateUrl: './advanced-search-modal.component.html',
  styleUrls: ['./advanced-search-modal.component.scss']
})
export class AdvancedSearchModalComponent implements OnInit {
  public loggedIn: boolean;
  public searchGlobalForm: FormGroup;
  public advancedSearchForm: FormGroup;
  public isLoading: boolean = false;
  public activeAccount: string;
  private activeUser: string;
  public searchGlobalResults: any;
  public algolia: any = {};
  public defaultContentType = 'artwork';
  public searchResultsFound = false;
  public advancedSearchModalWindow: any;
  public createListModalWindow: any;
  public activeContentType: string;
  public account: any = {};
  public selectedArtworks: Array<any> = [];
  public collections: Array<Object> = [];
  public bids: Array<Object> = [];
  public addToExistingCollectionModalWindow: any;
  public addToExistingBidModalWindow: any;
  public sortingKey: string;
  public sortAscending: boolean = false;
  public offset = 100;
  public imageLoadOffset$: any;
  _ref: any;
  public loading: boolean = true;

  @Output() setResults: EventEmitter<any> = new EventEmitter<any>();

  constructor(
    private router: Router,
    private auth: AuthService,
    private formBuilder: FormBuilder,
    private activeService: ActiveService,
    private accountService: AccountService,
    private searchService: SearchService,
    private collectionService: CollectionService,
    private bidService: BidService,
    private fileService: FileService,
  ) {
    this.loggedIn = this.auth.getLoggedInStatus();

    router.events.subscribe(event => {
      if (event instanceof NavigationEnd) {
        const urlLength = event.url.length;
        this.activeContentType = event.url.substring(urlLength - (urlLength - 1));
      }
    });

    this.searchGlobalForm = this.formBuilder.group({
      searchGlobalTerm: new FormControl('', [
        Validators.required,
        Validators.minLength(3),
        Validators.maxLength(50)
      ])
    });

    this.advancedSearchForm = this.formBuilder.group({
      advancedSearchTerm: new FormControl('', [
        Validators.required,
        Validators.minLength(1),
        Validators.maxLength(50)
      ])
    });
  }

  ngOnInit() {
    this.auth.loggedInStatus.subscribe(data => {
      this.loggedIn = data;
    });

    this.activeService.activeDataChange.subscribe(data => {
      this.activeAccount = data.account.id;

      this.algolia = {
        indexName: this.activeAccount,
        searchClient: algoliasearch(
          environment.ALGOLIASEARCH_APPLICATION_ID,
          environment.ALGOLIASEARCH_API_KEY,
        ),
        routing: false,
      };

      const getObject = {
        accountId: data.account.id,
        requester: 'advanced-search',
        sortingKey: null,
        sortingOrder: 'desc',
        skipQuery: 0,
        limitQuery: 99999,
        contentTypes: ['account', 'collection']
      };

      if (getObject.accountId && getObject.accountId !== '') {
        this.accountService.getAccountData(getObject).subscribe(
          data => {
            this.account = data.account;
            this.collections = data.collections;
          }
        );
      }
    });

    this.activeService.getActiveData().subscribe(
      data => {
        this.activeAccount = data.account.id;
        this.activeUser = data.user.id;

        this.algolia = {
          indexName: this.activeAccount,
          searchClient: algoliasearch(
            environment.ALGOLIASEARCH_APPLICATION_ID,
            environment.ALGOLIASEARCH_API_KEY,
          ),
          routing: false,
        };

        const getObject = {
          accountId: data.account.id,
          requester: 'advanced-search',
          sortingKey: null,
          sortingOrder: 'desc',
          skipQuery: 0,
          limitQuery: 99999,
          contentTypes: ['account', 'collection']
        };

        if (getObject.accountId !== '' && getObject.accountId !== undefined) {
          this.accountService.getAccountData(getObject).subscribe(
            data => {
              this.account = data.account;
              this.collections = data.collections;

            }
          );
        }

        this.loading = false;
      }
    );
  }

  public getActiveAccount(): string {
    return this.activeAccount;
  }

  doAdvancedSearch(): void {}

  openAdvancedSearchResult(resultItem: any): void {
    /*const closeButton = document.getElementById('advancedSearchCloseButton');
    if (closeButton) {
      closeButton.click();
    }

    const navAdvancedSearchButton = document.getElementById('navSearchBar');
    if (navAdvancedSearchButton) {
      navAdvancedSearchButton.click();
    }*/

    const clearButton = document.getElementById('clearAdvancedSearch');
    if (clearButton) {
      clearButton.click();
    }

    /* Completely remove the component from DOM */

    /*if (window.document.getElementById('advanced-search-container')) {
      /!*UIkit.modal(window.document.getElementById('advanced-search-container')).hide();*!/
      window.document.getElementById('advanced-search-container').remove();
    }*/

    if (this._ref) {
      this._ref.destroy();
    }

    this.router.navigateByUrl(`/${resultItem.contentType}/${resultItem._id}`);
  }

  removeObject() {
    this._ref.destroy();
  }

  ngOnDestroy(): void {
    /*this._ref.destroy();*/
  }

  closeAddToDropdown(): void {
    const addToDropdownElement = window.document.getElementById('addToDropdown');
    UIkit.dropdown(addToDropdownElement).hide();
  }

  toggleSelect(event: any, item: any): void {
    if (event && item) {
      const itemFound = this.selectedArtworks.find((selectedItem: any) => {
        return item._id === selectedItem._id;
      });

      if (itemFound === undefined) {
        if (item && item._id && item.name) {
          this.selectedArtworks.push(item);
        }
      } else {
        if (item && item._id) {
          const itemToSplice = this.selectedArtworks.find((artwork: any) => {
            return artwork._id === item._id;
          });
          this.selectedArtworks.splice(this.selectedArtworks.indexOf(itemToSplice), 1);
        }
      }
    }
  }

  selectAll(hits: Array<any>): void {
    hits.forEach(hit => {
      document.getElementById('toggleSelect-' + hit._id).click();
    });
  }

  getSelected(hitId: string): boolean {
    return this.selectedArtworks.some(selectedArtwork => selectedArtwork._id === hitId);
  }

  removeSelections(): void {
    this.selectedArtworks.forEach((selectedArtwork: any) => {
      if (window.document.getElementById('toggleSelect-' + selectedArtwork._id)) {
        UIkit.formCustom(window.document.getElementById('toggleSelect-' + selectedArtwork._id)).$el.checked = false;
      }
    });

    this.selectedArtworks = [];
  }

  openAddToExistingCollection() {
    this.closeAddToDropdown();
    this.updateCollections();

    this.addToExistingCollectionModalWindow = window.document.getElementById('addToExistingCollection');
    UIkit.modal(this.addToExistingCollectionModalWindow).show();
  }

  closeAddToExistingCollection(): void {
    this.addToExistingCollectionModalWindow = window.document.getElementById('addToExistingCollection');
    UIkit.modal(this.addToExistingCollectionModalWindow).hide();
  }

  openAddToExistingBid() {
    this.closeAddToDropdown();
    this.updateBids();

    this.addToExistingBidModalWindow = window.document.getElementById('addToExistingBid');
    UIkit.modal(this.addToExistingBidModalWindow).show();
  }

  closeAddToExistingBid(): void {
    this.addToExistingBidModalWindow = window.document.getElementById('addToExistingBid');
    UIkit.modal(this.addToExistingBidModalWindow).hide();
  }

  activeSort(key: string): boolean {
    return this.sortingKey === key;
  }

  updateCollections() {
    this.isLoading = true;

    const getObject = {
      accountId: this.activeAccount,
      requester: 'advanced-search',
      sortingKey: this.sortingKey ? this.sortingKey : '_id',
      sortingOrder: this.sortAscending ? 'asc' : 'desc',
      skipQuery: 0,
      limitQuery: 99999,
      contentTypes: ['collection']
    };

    this.accountService.getAccountData(getObject).subscribe(
      data => {
        this.collections = data.collections;
      },
      () => {
        UIkit.notification({
          message: 'Error fetching collections',
          status: 'danger',
          pos: 'top-right',
          timeout: 1500
        });
      },
      () => this.isLoading = false
    );
  }

  updateBids() {
    this.isLoading = true;

    const getObject = {
      accountId: this.activeAccount,
      requester: 'advanced-search',
      sortingKey: this.sortingKey ? this.sortingKey : '_id',
      sortingOrder: this.sortAscending ? 'asc' : 'desc',
      skipQuery: 0,
      limitQuery: 99999,
      contentTypes: ['bid']
    };

    this.accountService.getAccountData(getObject).subscribe(
      data => {
        this.bids = data.bids;
      },
      () => {
        UIkit.notification({
          message: 'Error fetching bids',
          status: 'danger',
          pos: 'top-right',
          timeout: 1500
        });
      },
      () => this.isLoading = false
    );
  }

  sortCollections(sortingKey: string): void {
    if (sortingKey !== this.sortingKey) {
      this.sortingKey = sortingKey;
    } else {
      this.sortAscending = !this.sortAscending;
    }
    this.updateCollections();
  }

  sortBids(sortingKey: string): void {
    if (sortingKey !== this.sortingKey) {
      this.sortingKey = sortingKey;
    } else {
      this.sortAscending = !this.sortAscending;
    }
    this.updateBids();
  }

  createCollection(): void {
    const today = moment.utc().locale('fi').format('L');
    const itemsArray = [];
    this.selectedArtworks.forEach(artwork => {
      itemsArray.push(artwork._id);
    });

    const addCollectionObject = {
      owner: this.activeAccount,
      name: 'Collection-' + today,
      items: itemsArray,
    };

    this.removeSelections();
    this.closeAddToDropdown();

    this.collectionService.addCollection(addCollectionObject).subscribe(
      res => {
        this.ngOnInit();
        /*UIkit.modal(this.addNewCollectionModalWindow).hide();
        this.resetForm();*/
        UIkit.notification({
          message: 'Collection created successfully',
          status: 'success',
          pos: 'top-right',
          timeout: 1500
        });
      },
      error => {
        UIkit.notification({
          message: 'Creating collection failed',
          status: 'danger',
          pos: 'top-right',
          timeout: 1500
        });
      }
    );
  }

  addToExistingCollection(addToExistingCollection: any): void {
    const itemsArray = [];
    this.selectedArtworks.forEach(artwork => {
      itemsArray.push(artwork._id);
    });

    /* Concatenate arrays, may have duplicates*/
    const concatArr = [...addToExistingCollection.items, ...itemsArray];

    /* Remove duplicates */
    const mergedItemArray = Array.from(new Set([...concatArr])); // [1, 2, 3, 4, 5]


    const editCollectionObject = {
      id: addToExistingCollection._id,
      owner: addToExistingCollection.owner,
      name: addToExistingCollection.name,
      description: addToExistingCollection.description,
      items: mergedItemArray,
      type: addToExistingCollection.type,
      location: addToExistingCollection.location,
      notes: addToExistingCollection.notes,
      comments: addToExistingCollection.comments,
      tags: addToExistingCollection.tags,
      visibility: addToExistingCollection.visibility,
      attachments: addToExistingCollection.attachments
    };

    this.collectionService.editCollection(editCollectionObject).subscribe(
      res => {
        this.updateCollections();
        this.removeSelections();
        this.closeAddToExistingCollection();

        UIkit.notification({
          message: 'Added to collection successfully',
          status: 'success',
          pos: 'top-right',
          timeout: 1500
        });
      },
      error => {
        UIkit.notification({
          message: 'Adding failed',
          status: 'danger',
          pos: 'top-right',
          timeout: 1500
        });
      }
    );
  }

  createBid(): void {
    const today = moment.utc().locale('fi').format('L');
    const itemsArray = [];
    this.selectedArtworks.forEach(artwork => {
      itemsArray.push(artwork._id);
    });

    const addBidObject = {
      owner: this.activeAccount,
      name: 'Bid-' + today,
      items: itemsArray,
    };

    this.removeSelections();
    this.closeAddToDropdown();

    this.bidService.addBid(addBidObject).subscribe(
      res => {
        this.ngOnInit();
        /*UIkit.modal(this.addNewBidModalWindow).hide();
        this.resetForm();*/
        UIkit.notification({
          message: 'Bid created successfully',
          status: 'success',
          pos: 'top-right',
          timeout: 1500
        });
      },
      error => {
        UIkit.notification({
          message: 'Creating bid failed',
          status: 'danger',
          pos: 'top-right',
          timeout: 1500
        });
      }
    );
  }

  addToExistingBid(addToExistingBid: any): void {
    const itemsArray = [];
    this.selectedArtworks.forEach(artwork => {
      itemsArray.push(artwork._id);
    });

    /* Concatenate arrays, may have duplicates*/
    const concatArr = [...addToExistingBid.items, ...itemsArray];

    /* Remove duplicates */
    const mergedItemArray = Array.from(new Set([...concatArr])); // [1, 2, 3, 4, 5]


    const editBidObject = {
      id: addToExistingBid._id,
      owner: addToExistingBid.owner,
      name: addToExistingBid.name,
      description: addToExistingBid.description,
      items: mergedItemArray,
      type: addToExistingBid.type,
      location: addToExistingBid.location,
      notes: addToExistingBid.notes,
      comments: addToExistingBid.comments,
      tags: addToExistingBid.tags,
      visibility: addToExistingBid.visibility,
      attachments: addToExistingBid.attachments
    };

    this.bidService.editBid(editBidObject).subscribe(
      res => {
        this.updateBids();
        this.removeSelections();
        this.closeAddToExistingBid();

        UIkit.notification({
          message: 'Added to bid successfully',
          status: 'success',
          pos: 'top-right',
          timeout: 1500
        });
      },
      error => {
        UIkit.notification({
          message: 'Adding failed',
          status: 'danger',
          pos: 'top-right',
          timeout: 1500
        });
      }
    );
  }

  openPrintList(): void {
    this.createListModalWindow = window.document.getElementById('createList');
    UIkit.modal(this.createListModalWindow).show();

  }

  printList(): void {
    const printListArray = [];
    const resultArray = [];
    let collectionArtworks = this.selectedArtworks;

    Array.from(UIkit.sortable(document.getElementById('createListSortable')).$el.children).forEach(
      (element: any, index, array) => {
        printListArray.push(element.dataset.artworkid);
      }
    );

    printListArray.forEach(function(artworkid) {
      let found = false;
      collectionArtworks = collectionArtworks.filter(function(collectionArtwork) {
        if (!found && collectionArtwork._id === artworkid) {
          resultArray.push(collectionArtwork);
          found = true;
          return false;
        } else {
          return true;
        }
      });
    });

    const printListObject = {
      items: [],
      notes: {},
      pricing: {}
    };

    if (resultArray.length) {
      resultArray.forEach(item => {
        const itemName = item.name;
        let itemImage = null;

        if (item.coverImage) {
          itemImage = item.coverImage;
        } else if (item.images && item.images.length) {
          itemImage = item.images[0].fileId;
        }

        const itemArtistName = item.artistName;
        const itemCreated = moment.utc(item.created).isValid() ? moment.utc(item.created).locale('fi').format('YYYY') : null;
        const itemTechnique = item.technique;

        /* Näitä tietoja ei varmaankaan tarvita */
        let itemSize = '';
        if (item.height) {
          itemSize += item.height;
        }
        if (item.width) {
          if (item.height) {
            itemSize += ' x ';
          }
          itemSize += item.width;
        }
        if (item.depth) {
          if (item.height) {
            itemSize += ' x ';
          }
          itemSize += item.depth;
        }

        const itemAvailability = item.availability;

        let listObject: any = {};
        listObject = {
          'account': this.activeAccount,
          'Artist': itemArtistName,
          'Item name': itemName
        };

        if (itemCreated) {
          listObject['Item created'] = itemCreated;
        }

        if (itemTechnique) {
          listObject['Item technique'] = itemTechnique;
        }

        if (itemSize) {
          listObject['Item size'] = itemSize + ' cm';
        }

        if (!item.hidePrice) {
          if (item.price.price) {
            listObject['Item price'] = item.price.price + ' ' + (item.price.currency ? item.price.currency : '');
          }
        }

        /*if (itemAvailability) {
          listObject['Item availability'] = itemAvailability;
        }*/

        if (item.listNotes) {
          listObject['Item notes'] = item.listNotes;
        }

        if (itemImage) {
          listObject.Image = itemImage;
        }

        printListObject.items.push(listObject);
      });
    }

    /*bidObject.pricing = {
      total: this.getItemsTotalPrice(),
      discount: this.getDiscountedPrice(),
      grandTotal: this.getItemsDiscountedPrice(),
      vatTotal: this.getItemsVAT().toFixed(2),
      currency: this.tradingCurrency
    };*/

    this.fileService.getPDF('Collection-list', printListObject);
  }

  setParentSearchResults(): void {
    this.advancedSearchModalWindow = window.document.getElementById('advancedArtworkSearch');
    if (this.advancedSearchModalWindow) {
      UIkit.modal(this.advancedSearchModalWindow).hide();
    }

    this.setResults.emit(this.selectedArtworks);
  }

  filterHiddenDocuments(hits: any): any {
    if (!hits || hits.length === 0) {
      return [];
    }

    return hits.filter((hit: any) => {
      return hit.hidden !== true;
    });
  }
}
