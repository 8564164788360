<div class="uk-section uk-section-default uk-padding-remove-vertical">

  <app-sidenav contentType="Exhibitions" [contentItem]="activeExhibition.name"></app-sidenav>

  <div class="uk-container-expand uk-height-expand uk-background-default">
    <ul uk-tab *ngIf="!isLoading">
      <li class="uk-active"><a href="#exhibitioninfo"><h4>Basic information</h4></a></li>
      <li><a href="#exhibitionartworks"><h4>Artworks</h4></a></li>
      <li *ngIf="artists.length"><a href="#exhibitionartists"><h4>Artists</h4></a></li>
    </ul>
    <ul class="uk-switcher uk-card uk-card-body uk-padding-remove-top" *ngIf="!isLoading">
      <div id="exhibitioninfo" class="uk-padding-small">
        <form appNoSubmitEnter [formGroup]="editExhibitionForm" (ngSubmit)="editExhibition()" class="uk-grid-small"
              uk-grid>
          <input type="hidden" name="editExhibitionOwner" name="editExhibitionOwner"
                 formControlName="editExhibitionOwner" [ngModel]="activeAccount">
          <input type="hidden" name="editExhibitionId" name="editExhibitionId" formControlName="editExhibitionId"
                 [ngModel]="activeExhibition._id">
          <input type="hidden" name="editExhibitionOwner" name="editExhibitionOwner"
                 formControlName="editExhibitionOwner" [ngModel]="activeExhibition.owner">
          <div class="uk-width-1-2@s uk-width-1-3@m">
            <div class="uk-margin">
              <label class="uk-form-label" for="editExhibitionName">Name</label>
              <div class="uk-form-controls">
                <input id="editExhibitionName" name="editExhibitionName" formControlName="editExhibitionName"
                       type="text" [ngModel]="activeExhibition.name" placeholder="Name ..." class="uk-input"
                       [ngClass]="{'uk-form-danger': editExhibitionForm.get('editExhibitionName').touched && editExhibitionForm.get('editExhibitionName').errors }">
                <div class="uk-text-danger uk-text-small"
                     *ngIf="editExhibitionForm.get('editExhibitionName').hasError('maxlength')"
                >
                  {{ getCharactersLimitError('editExhibitionName') }}
                </div>
                <div class="uk-text-danger uk-text-small"
                     *ngIf="editExhibitionForm.get('editExhibitionName').hasError('pattern')"
                >
                  Field value is incorrect
                </div>
              </div>
            </div>
          </div>
          <div class="uk-width-1-2@s uk-width-1-3@m">
            <div class="uk-margin">
              <div class="uk-flex uk-flex-between uk-text-meta">
                <label class="uk-form-label" for="editExhibitionDescription">Description</label>
                <div class="uk-text-small">
                  {{ getCharactersLeft('editExhibitionDescription', 2048) }}
                </div>
              </div>
              <div class="uk-form-controls">
                <textarea id="editExhibitionDescription" name="editExhibitionDescription"
                          formControlName="editExhibitionDescription" rows="5" [ngModel]="activeExhibition.description"
                          placeholder="Description ..." class="uk-textarea"
                          [ngClass]="{'uk-form-danger': editExhibitionForm.get('editExhibitionDescription').touched && editExhibitionForm.get('editExhibitionDescription').errors }"></textarea>
                <div class="uk-text-danger uk-text-small"
                     *ngIf="editExhibitionForm.get('editExhibitionName').hasError('maxlength')"
                >
                  {{ getCharactersLimitError('editExhibitionName') }}
                </div>
              </div>
            </div>
          </div>
          <div class="uk-width-1-2@s uk-width-1-3@m">
            <div class="uk-margin">
              <label class="uk-form-label" for="editExhibitionStartDate">Start date</label>
              <div class="uk-form-controls">
                <input
                  id="editExhibitionStartDate"
                  name="editExhibitionStartDate"
                  formControlName="editExhibitionStartDate"
                  [ngModel]="activeExhibition.startDate"
                  type="text"
                  placeholder="Start date ..."
                  class="uk-input"
                  [ngClass]="{'uk-form-danger': editExhibitionForm.get('editExhibitionStartDate').touched && editExhibitionForm.get('editExhibitionStartDate').errors }"
                  angular-mydatepicker
                  [options]="myDatePickerOptions"
                  #AngularMyDatePickerEditExhibitionStartDate="angular-mydatepicker"
                  (click)="AngularMyDatePickerEditExhibitionStartDate.toggleCalendar()"
                  (dateChanged)="updateDate('editExhibitionStartDate', $event)"
                />
                <div class="uk-text-danger uk-text-small"
                     *ngIf="editExhibitionForm.get('editExhibitionStartDate').hasError('maxlength')"
                >
                  {{ getCharactersLimitError('editExhibitionStartDate') }}
                </div>
              </div>
            </div>
          </div>
          <div class="uk-width-1-2@s uk-width-1-3@m">
            <div class="uk-margin">
              <label class="uk-form-label" for="editExhibitionEndDate">End date</label>
              <div class="uk-form-controls">
                <input
                  id="editExhibitionEndDate"
                  name="editExhibitionEndDate"
                  formControlName="editExhibitionEndDate"
                  [ngModel]="activeExhibition.endDate"
                  type="text"
                  placeholder="End date ..."
                  class="uk-input"
                  [ngClass]="{'uk-form-danger': editExhibitionForm.get('editExhibitionEndDate').touched && editExhibitionForm.get('editExhibitionEndDate').errors }"
                  angular-mydatepicker
                  [options]="myDatePickerOptions"
                  #AngularMyDatePickerEditExhibitionEndDate="angular-mydatepicker"
                  (click)="AngularMyDatePickerEditExhibitionEndDate.toggleCalendar()"
                  (dateChanged)="updateDate('editExhibitionEndDate', $event)"
                />
                <div class="uk-text-danger uk-text-small"
                     *ngIf="editExhibitionForm.get('editExhibitionEndDate').hasError('maxlength')"
                >
                  {{ getCharactersLimitError('editExhibitionEndDate') }}
                </div>
              </div>
            </div>
          </div>
          <div class="uk-width-1-2@s uk-width-1-3@m">
            <div class="uk-margin">
              <label class="uk-form-label" for="editExhibitionType">Exhibition type</label>
              <div class="uk-form-controls">
                <select id="editExhibitionType" name="editExhibitionType" formControlName="editExhibitionType"
                        [ngModel]="activeExhibition.type" class="uk-select">
                  <option value="" disabled>Please select ...</option>
                  <option value="Internal">Internal</option>
                  <option value="Joint">Joint</option>
                </select>
              </div>
            </div>
          </div>
          <div class="uk-width-1-2@s uk-width-1-3@m">
            <div class="uk-margin">
              <label class="uk-form-label" for="editExhibitionLocation">Location</label>
              <div class="uk-form-controls">
                <ng2-completer id="editExhibitionLocation" name="editExhibitionLocation"
                               formControlName="editExhibitionLocation" [(ngModel)]="activeExhibition.location"
                               [datasource]="variables.locations" [minSearchLength]="0"
                               [textNoResults]="null"></ng2-completer>
                <div class="uk-text-danger uk-text-small"
                     *ngIf="editExhibitionForm.get('editExhibitionLocation').hasError('maxlength')"
                >
                  {{ getCharactersLimitError('editExhibitionLocation') }}
                </div>
              </div>
            </div>
          </div>
          <!--<div class="uk-width-1-1@s">
            <div class="uk-margin">
              <label class="uk-form-label" for="editExhibitionAttachments">Liitetiedostot</label>
              <div class="uk-form-controls">
                <div uk-form-custom="target: true">
                  <input type="file">
                  <input class="uk-input uk-form-width-medium" type="text" placeholder="Valitse tiedosto" disabled>
                </div>
                <button class="uk-button uk-button-default">Lisää</button>
              </div>
            </div>
          </div>-->
          <div class="uk-width-auto@s">
            <label class="uk-form-label">Installation images</label>
          </div>
          <div class="uk-width-expand@s uk-text-right">
            <button class="uk-button uk-button-default uk-margin-small-right" type="button"
                    (click)="openAddInstallShot()">Manage images
            </button>
            <button class="uk-button uk-button-default" type="button" (click)="openInstallShotsSlideshow($event)"
                    [disabled]="!activeExhibition['installShots'].length">Slideshow
            </button>
          </div>
          <div class="uk-width-1-1">
            <div class="uk-position-relative uk-visible-toggle" uk-slider>
              <ul class="uk-slider-items uk-child-width-1-2@s uk-child-width-1-3@m uk-grid">
                <li class="uk-text-center" *ngFor="let image of activeExhibition['installShots']; let index = index">
                  <div class="uk-inline-clip uk-transition-toggle uk-width-1-1" tabindex="index">
                    <div
                      class="uk-background-contain uk-background-muted uk-height-medium uk-panel uk-flex uk-flex-center uk-flex-middle"
                      [attr.data-src]="'/api/storagefile/' + activeAccount + '/' + image.fileId + '/medium'" uk-img>
                    </div>
                    <div class="uk-transition-slide-bottom uk-position-bottom uk-overlay uk-overlay-default">
                      <button type="button" (click)="removeImage(image)"
                              class="uk-button uk-button-text uk-margin-left">Remove
                      </button>
                      <button type="button" (click)="openImage(image)" class="uk-button uk-button-text uk-margin-left">
                        Download
                      </button>
                    </div>
                  </div>
                </li>
              </ul>
              <a class="uk-position-center-left uk-position-small uk-hidden-hover" uk-slidenav-previous
                 uk-slider-item="previous"></a>
              <a class="uk-position-center-right uk-position-small uk-hidden-hover" uk-slidenav-next
                 uk-slider-item="next"></a>
            </div>
          </div>
          <div id="addInstallShots" class="uk-modal-container" uk-modal="bg-close: false; esc-close: false;">
            <div class="uk-modal-dialog">
              <div class="uk-modal-header">
                <h2 class="uk-modal-title">Manage install shots</h2>
              </div>
              <div class="uk-modal-body">
                <div class="uk-width-1-1">
                  <div class="uk-position-relative uk-visible-toggle" uk-slider>
                    <ul class="uk-slider-items uk-child-width-1-2@s uk-child-width-1-3@m uk-grid">
                      <li class="uk-text-center"
                          *ngFor="let image of activeExhibition['installShots']; let index = index">
                        <div class="uk-inline-clip uk-transition-toggle uk-width-1-1" tabindex="index">
                          <div
                            class="uk-background-contain uk-height-medium uk-panel uk-flex uk-flex-center uk-flex-middle"
                            [attr.data-src]="'/api/storagefile/' + activeAccount + '/' + image.fileId + '/medium'"
                            uk-img>
                          </div>
                          <div class="uk-transition-slide-bottom uk-position-bottom uk-overlay uk-overlay-default">
                            <button type="button" (click)="removeImage(image)"
                                    class="uk-button uk-button-text uk-margin-left">Remove
                            </button>
                            <button type="button" (click)="openImage(image)"
                                    class="uk-button uk-button-text uk-margin-left">Download
                            </button>
                          </div>
                        </div>
                      </li>
                    </ul>
                    <a class="uk-position-center-left uk-position-small uk-hidden-hover" uk-slidenav-previous
                       uk-slider-item="previous"></a>
                    <a class="uk-position-center-right uk-position-small uk-hidden-hover" uk-slidenav-next
                       uk-slider-item="next"></a>
                  </div>
                </div>
                <div class="uk-width-1-1">
                  <div class="uk-margin">
                    <label class="uk-form-label">Manage install shots</label>
                    <p *ngIf="!activeExhibition['installShots'].length">No install shots</p>
                    <div class="uk-form-controls">
                      <div class="drop-container" ngFileDrop
                           [options]="{ concurrency: 1, allowedContentTypes: ['image/jpeg', 'image/jpg', 'image/png', 'image/gif'], maxFileSize: 3000000 }"
                           (uploadOutput)="onUploadOutput($event)" (click)="imageFile.click()"
                           [uploadInput]="uploadInput">
                        <div class="js-upload uk-placeholder uk-text-center" [ngClass]="{ 'is-drop-over': dragOver }">
                          <span class="uk-text-middle">Add images by dropping them here or open file dialog by clicking here.<br>Allowed filetypes include JPG, PNG and GIF.<br>Maximum allowed file size is 5 Mb.</span>
                        </div>
                        <input #imageFile type="file" ngFileSelect
                               [options]="{ concurrency: 1, allowedContentTypes: ['image/jpeg', 'image/jpg', 'image/png', 'image/gif'], maxFileSize: 5000000 }"
                               [uploadInput]="uploadInput" (uploadOutput)="onUploadOutput($event)" multiple
                               class="file-input">
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="uk-modal-footer">
                <p class="uk-text-right">
                  <button type="button" class="uk-button uk-button-primary uk-modal-close">Close</button>
                </p>
              </div>
            </div>
          </div>
          <div class="uk-width-1-1 uk-margin-medium-top">
            <h3 class="uk-margin-remove-bottom">Additional information</h3>
            <hr class="uk-margin-small-top">
          </div>
          <div class="uk-width-1-1@s">
            <div class="uk-margin">
              <label class="uk-form-label">Attachments</label>
              <div class="uk-form-controls uk-margin-small">
                <dl class="uk-description-list" *ngIf="activeExhibition.attachments.length">
                  <div class="uk-flex uk-flex-middle" *ngFor="let attachment of activeExhibition.attachments">
                    <div class="uk-padding-small">
                      <img class="contenttype" width="40" height="40"
                           src="/api/storagefile/{{ activeAccount }}/{{ attachment.fileId }}/small"
                           *ngIf="['image/png', 'image/jpg', 'image/jpeg', 'image/heic', 'image/gif', 'image/svg+xml'].includes(attachment.contentType)"/>
                      <img class="contenttype" width="40" height="40" src="/api/file/5a5de6b1229e86403d67db11"
                           *ngIf="['application/msword', 'application/vnd.openxmlformats-officedocument.wordprocessingml.document'].includes(attachment.contentType)"/>
                      <img class="contenttype" width="40" height="40" src="/api/file/5a5de6b1229e86403d67db10"
                           *ngIf="['application/octet-stream', 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'].includes(attachment.contentType)"/>
                      <img class="contenttype" width="40" height="40" src="/api/file/5a5de6b1229e86403d67db12"
                           *ngIf="['application/pdf'].includes(attachment.contentType)"/>
                      <img class="contenttype" width="40" height="40" src="/api/file/5a5de7bc229e86403d67db1b"
                           *ngIf="['text/plain'].includes(attachment.contentType)"/>
                      <img class="contenttype" width="40" height="40" src="/api/file/5a5de6b1229e86403d67db0f"
                           *ngIf="!(['image/png', 'image/jpg', 'image/jpeg', 'image/heic', 'image/gif', 'image/svg+xml', 'application/msword', 'application/vnd.openxmlformats-officedocument.wordprocessingml.document', 'application/octet-stream', 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet', 'application/pdf', 'text/plain'].includes(attachment.contentType))"/>
                    </div>
                    <div class="uk-width-expand">{{ attachment.name }}</div>
                    <div class="uk-button-group">
                      <button (click)="openAttachment(attachment)" class="uk-button uk-button-default" type="button">
                        Open
                      </button>
                      <div class="uk-inline uk-text-right">
                        <button class="uk-button uk-button-default" type="button"><span
                          uk-icon="icon:  chevron-down"></span></button>
                        <div uk-dropdown="mode: click; boundary: ! .uk-button-group; boundary-align: true;">
                          <ul class="uk-nav uk-dropdown-nav">
                            <li><a (click)="removeAttachment(attachment)">Remove</a></li>
                          </ul>
                        </div>
                      </div>
                    </div>
                  </div>
                </dl>
                <p *ngIf="!activeExhibition.attachments.length">No related attachments</p>
              </div>
            </div>
          </div>
          <div class="uk-width-1-1@s">
            <div class="uk-margin">
              <label class="uk-form-label">Add new attachments</label>
              <div class="uk-form-controls">
                <div class="drop-container" ngFileDrop [options]="options"
                     (uploadOutput)="onAttachmentsUploadOutput($event)" (click)="attachmentFile.click()"
                     [uploadInput]="uploadInput">
                  <div class="js-upload uk-placeholder uk-text-center" [ngClass]="{ 'is-drop-over': dragOver }">
                    <span
                      class="uk-text-middle">Add files by dropping them here or open file dialog by clicking here.<br>Maximum allowed file size is 5 Mb.</span>
                  </div>
                  <input #attachmentFile type="file" ngFileSelect [options]="{ concurrency: 1, maxFileSize: 5000000 }"
                         [uploadInput]="uploadInput" (uploadOutput)="onAttachmentsUploadOutput($event)" multiple
                         class="file-input">
                </div>
              </div>
              <!--              <progress id="js-progressbar" class="uk-progress" value="0" max="100" hidden></progress>-->
              <!--              <div class="table-container" *ngIf="filesToUpload.length">-->
              <!--                <table class="uk-table uk-table-middle uk-margin-top">-->
              <!--                  <caption>Files to upload</caption>-->
              <!--                  <thead>-->
              <!--                  <tr>-->
              <!--                    <th class="uk-table-shrink uk-text-capitalize">Image</th>-->
              <!--                    <th class="uk-width-expand uk-text-capitalize">Name</th>-->
              <!--                    <th class="uk-width-expand uk-text-capitalize">Size</th>-->
              <!--                    <th class="uk-table-shrink uk-text-capitalize"></th>-->
              <!--                  </tr>-->
              <!--                  </thead>-->
              <!--                  <tbody>-->
              <!--                  <tr *ngFor="let file of filesToUpload; let i = index;">-->
              <!--                    <td class="uk-background-muted"><img class="uk-preserve-width uk-border-circle" src="/api/image/artbuddy-logo-placeholder.svg" width="60" uk-img></td>-->
              <!--                    <td>{{ file.name }}</td>-->
              <!--                    <td class="uk-text-nowrap">{{ file.size | bytes: 2 }}</td>-->
              <!--                    <td><button (click)="removeFile(file.id)" class="uk-button uk-button-default" type="button">Remove</button></td>-->
              <!--                  </tr>-->
              <!--                  </tbody>-->
              <!--                </table>-->
              <!--                <button type="button" (click)="startUpload()" class="uk-button uk-button-primary">Start upload</button>-->
              <!--              </div>-->
            </div>
          </div>
          <div class="uk-width-1-2@s uk-width-1-3@m">
            <div class="uk-margin">
              <div class="uk-flex uk-flex-between uk-text-meta">
                <label class="uk-form-label" for="editExhibitionNotes">Other information</label>
                <div class="uk-text-small">
                  {{ getCharactersLeft('editExhibitionNotes', 2048) }}
                </div>
              </div>
              <div class="uk-form-controls">
                <textarea id="editExhibitionNotes" name="editExhibitionNotes" formControlName="editExhibitionNotes"
                          rows="5" [ngModel]="activeExhibition.notes" type="text" placeholder="Other information ..."
                          class="uk-textarea"
                          [ngClass]="{'uk-form-danger': editExhibitionForm.get('editExhibitionNotes').touched && editExhibitionForm.get('editExhibitionNotes').errors }"></textarea>
                <div class="uk-text-danger uk-text-small"
                     *ngIf="editExhibitionForm.get('editExhibitionNotes').hasError('maxlength')"
                >
                  {{ getCharactersLimitError('editExhibitionNotes') }}
                </div>
              </div>
            </div>
          </div>
          <div class="uk-width-1-2@s uk-width-1-3@m">
            <div class="uk-margin">
              <div class="uk-flex uk-flex-between uk-text-meta">
                <label class="uk-form-label" for="editExhibitionComments">Comments</label>
                <div class="uk-text-small">
                  {{ getCharactersLeft('editExhibitionComments', 2048) }}
                </div>
              </div>
              <div class="uk-form-controls">
                <textarea id="editExhibitionComments" name="editExhibitionComments"
                          formControlName="editExhibitionComments" rows="5" [ngModel]="activeExhibition.comments"
                          type="text" placeholder="Comments ..." class="uk-textarea"
                          [ngClass]="{'uk-form-danger': editExhibitionForm.get('editExhibitionComments').touched && editExhibitionForm.get('editExhibitionComments').errors }"></textarea>
                <div class="uk-text-danger uk-text-small"
                     *ngIf="editExhibitionForm.get('editExhibitionComments').hasError('maxlength')"
                >
                  {{ getCharactersLimitError('editExhibitionComments') }}
                </div>
              </div>
            </div>
          </div>
          <div class="uk-width-1-2@s uk-width-1-3@m">
            <div class="uk-margin">
              <label class="uk-form-label" for="editExhibitionTags">Tags</label>
              <div class="uk-form-controls">
                <input id="editExhibitionTags" name="editExhibitionTags" formControlName="editExhibitionTags"
                       [ngModel]="activeExhibition.tags" type="text" placeholder="Tags ..." class="uk-input"
                       [ngClass]="{'uk-form-danger': editExhibitionForm.get('editExhibitionTags').touched && editExhibitionForm.get('editExhibitionTags').errors }">
                <div class="uk-text-danger uk-text-small"
                     *ngIf="editExhibitionForm.get('editExhibitionTags').hasError('maxlength')"
                >
                  {{ getCharactersLimitError('editExhibitionTags') }}
                </div>
              </div>
            </div>
          </div>
          <div class="uk-width-1-2@s uk-width-1-3@m">
            <div class="uk-margin">
              <label class="uk-form-label" for="editExhibitionVisibility">Visibility</label>
              <div class="uk-form-controls">
                <label><input id="editExhibitionVisibility" name="editExhibitionVisibility"
                              formControlName="editExhibitionVisibility" [ngModel]="activeExhibition.visibility"
                              class="uk-checkbox" type="checkbox"> Public</label>
              </div>
            </div>
          </div>
          <div class="uk-width-1-1 uk-margin-medium-top">
            <hr class="uk-margin-small-top">
          </div>
          <div class="uk-width-1-1 uk-margin-remove sticky-bottom uk-background-default" uk-sticky="bottom: true">
            <div class="uk-width-1-1">
              <div class="uk-flex uk-flex-middle uk-flex-between uk-margin">
                <app-editing-history
                  [updatedAt]="activeExhibition.updatedAt"
                  [name]="activeExhibition.requestUser?.name"
                  [email]="activeExhibition.requestUser?.email"
                ></app-editing-history>
                <div class="uk-text-right">
                  <button type="button" class="uk-button uk-button-default uk-margin-small-right"
                          [disabled]="!editExhibitionForm.valid" (click)="getCSV()">Get CSV
                  </button>
                  <button type="button" class="uk-button uk-button-default uk-margin-small-right"
                          [disabled]="!editExhibitionForm.valid || !activeExhibition.items.length">Open PDF
                  </button>
                  <div uk-dropdown="mode: click">
                    <ul class="uk-nav uk-dropdown-nav">
                      <li><a (click)="openPrintList(true)"> Print as list</a></li>
                      <li><a (click)="openPrintList(false)"> Print one artwork per page</a></li>
                    </ul>
                  </div>
                  <button type="submit" class="uk-button uk-button-primary"
                          [disabled]="!editExhibitionForm.valid || uploadLoading">Save
                  </button>
                </div>
              </div>
            </div>
          </div>
        </form>
      </div>
      <div id="exhibitionartworks" class="uk-padding-small uk-padding-remove-top">
        <nav class="uk-navbar-container navbar-search-artwork-container" uk-navbar>
          <div class="nav-overlay uk-navbar-right">
            <button class="uk-button uk-button-default uk-margin-small-right" type="button"
                    (click)="openAdvancedSearch()">Add artwork
            </button>
            <button class="uk-button uk-button-default" type="button" (click)="openSlideshow($event)"
                    [disabled]="!this.activeExhibition.items.length">Slideshow
            </button>
          </div>
        </nav>
        <div class="uk-grid-match uk-child-width-1-2@s uk-child-width-1-3@m uk-child-width-1-4@xl" uk-grid uk-sortable>
          <div class="uk-card" *ngFor="let item of activeExhibition.items">
            <div class="uk-card-header uk-flex uk-flex-middle uk-flex-between uk-padding-remove-horizontal">
              <div class="uk-padding-remove-left">
                <h3 class="uk-card-title uk-margin-remove-bottom">{{ item.name }}</h3>
                <div class="uk-text-meta">
                  <span *ngIf="item.artistName">{{ item.artistName }}</span>
                  <span *ngIf="item.created">, {{ item.created }}</span>
                </div>
              </div>
              <!--<div>
                <div class="uk-text-meta">{{ item.availability }}</div>
                <div class="uk-text-meta" *ngIf="item.price.price">{{ item.price.price }} {{ item.price.currency }}</div>
              </div>-->
            </div>
            <div class="uk-card-body uk-padding-remove uk-background-muted artwork-image"
                 [attr.data-src]="'/api/storagefile/' + activeAccount + '/' + item.coverImage + '/medium'" uk-img
                 *ngIf="item.coverImage"></div>
            <div class="uk-card-body uk-padding-remove uk-background-muted artwork-image"
                 [attr.data-src]="'/api/storagefile/' + activeAccount + '/' + item.images[0].fileId + '/medium'" uk-img
                 *ngIf="!item.coverImage && item.images && item.images.length"></div>
            <div class="uk-card-body uk-padding-remove uk-background-muted artwork-image bg-max-size"
                 *ngIf="!item.coverImage && !item.images.length"></div>
            <div class="uk-card-footer uk-flex uk-flex-middle uk-flex-between uk-padding-remove-horizontal">
              <div>
                <div class="uk-text-meta" *ngIf="item.height && item.width">{{ item.height }} * {{ item.width }}cm
                </div>
                <!--<div class="uk-text-meta">{{ item.technique }}</div>-->
              </div>
              <div class="uk-flex uk-flex-column">
                <a (click)="removeArtworkFromExhibition(item)" class="uk-button uk-button-text">Remove</a>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div id="exhibitionartists" class="uk-padding-small">
        <table class="uk-table uk-table-middle uk-table-divider">
          <thead>
          <tr>
            <!--<th class="uk-table-shrink">Image</th>-->
            <th class="uk-width-expand">Name</th>
            <th class="uk-width-expand uk-text-nowrap">Multiplier</th>
            <th class="uk-table-shrink uk-text-nowrap">Status</th>
            <th class="uk-table-shrink"></th>
          </tr>
          </thead>
          <tbody>
          <tr *ngFor="let artist of artists">
            <!--<td><input class="uk-checkbox" type="checkbox"></td>-->
            <!--<td><img class="uk-preserve-width uk-border-circle"
                     src="/api/image/artbuddy-logo-placeholder.svg"
                     width="60"></td>-->


            <td>{{ artist.name }} {{ artist.lastName }}</td>
            <td>{{ artist.priceMultiplier }}</td>
            <td>
              <span *ngIf="artist.status">Active</span>
              <span *ngIf="!artist.status">Inactive</span>
            </td>
            <td class="uk-text-right">
              <button (click)="openArtist(artist._id)" class="uk-button uk-button-default" type="button">Open</button>
            </td>
          </tr>
          </tbody>
        </table>
      </div>
    </ul>
  </div>
</div>
<div id="createList" class="uk-modal-container" uk-modal="bg-close: false; esc-close: false; cls-panel:
       'uk-overflow-hidden'">
  <div class="uk-modal-dialog">
    <div class="uk-modal-header">
      <h2 class="uk-modal-title">Select artworks to print</h2>
    </div>
    <div class="uk-modal-body">
      <div id="createListSortable" class="uk-child-width-1-1">
        <div class="uk-card uk-grid-collapse uk-child-width-expand uk-flex-middle uk-margin"
             *ngFor="let item of activeExhibition.items" uk-grid [attr.data-artworkid]="item._id">
          <!-- Näytetään coverImage jos on -->
          <div
            class="image-max-height uk-preserve-width uk-background-contain uk-background-muted uk-width-small uk-height-small uk-panel uk-flex uk-flex-center uk-flex-middle"
            width="150" [attr.data-src]="'/api/storagefile/' + activeAccount + '/' + item.coverImage + '/medium'" uk-img
            *ngIf="item.coverImage">
          </div>

          <!-- Jos ei ole coverImagea, niin sit otetaan eka image -->
          <div
            class="image-max-height uk-preserve-width uk-background-contain uk-background-muted uk-width-small uk-height-small uk-panel uk-flex uk-flex-center uk-flex-middle"
            width="150" [attr.data-src]="'/api/storagefile/' + activeAccount + '/' + item.images[0].fileId + '/medium'"
            uk-img *ngIf="!item.coverImage && item.images && item.images.length">
          </div>

          <!-- Jos ei ole coverImagea tai ekan imagea, ni sit otetaan placeholder -->
          <div
            class="image-max-height uk-preserve-width uk-background-contain uk-background-muted uk-width-small uk-height-small uk-panel uk-flex uk-flex-center uk-flex-middle"
            width="150" [attr.data-src]="'/api/image/artbuddy-logo-placeholder.svg'" uk-img
            *ngIf="!item.coverImage && !item.images.length">
          </div>
          <div>
            <div class="uk-card-body">
              <h6 class="uk-card-title uk-margin-small-bottom">{{ item.name }}</h6>
              <p class="uk-margin-remove">Artist: {{ item.artistName }}</p>
              <p class="uk-margin-remove">Notes: {{ item.listNotes }}</p>
              <p>
                <input class="uk-checkbox uk-margin-small-right" type="checkbox" value="true"
                       (change)="updatePrintItem(item, $event)"> Hide from PDF
                <ng-container *ngIf="!printAsList">
                  <input class="uk-checkbox uk-margin-left" type="checkbox"
                         (change)="updateItemSettings('showQRCode', item, $event)">
                  <span class="uk-margin-small-left">Show QR code</span>
                </ng-container>
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="uk-modal-footer">
      <p class="uk-text-right">
        <button class="uk-button uk-button-default uk-modal-close uk-margin-small-right" type="button"
                id="addNewCollectionCancel">Cancel
        </button>
        <button type="submit" class="uk-button uk-button-primary" (click)="getExhibitionPDF()">Print</button>
      </p>
    </div>
  </div>
</div>
<app-advanced-search-modal (setResults)="setArtworkSearchResults($event)"
                           *ngIf="showAdvancedArtworkSearchModalWindow"></app-advanced-search-modal>
