import {Component, EventEmitter, OnInit, ViewChild} from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { FormGroup, FormControl, Validators, FormBuilder } from '@angular/forms';
import { AuthService } from '../../services/auth.service';
import { UserService } from '../../services/user.service';
import { ActiveService } from '../../services/active.service';
import { AccountService } from '../../services/account.service';
import { TradeService } from '../../services/trade.service';
import { FileService } from '../../services/file.service';
import { SearchService } from '../../services/search.service';
import { BidService } from '../../services/bid.service';
import { ItemService } from '../../services/item.service';
import { UploadOutput, UploadInput, UploadFile, humanizeBytes, UploaderOptions } from 'ngx-uploader';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import {AngularMyDatePickerDirective, IAngularMyDpOptions, IMyDateModel} from 'angular-mydatepicker';
import { Observable } from 'rxjs';
import { delay } from 'rxjs/operators';
import * as moment from 'moment/moment';

declare var UIkit: any;

@Component({
  selector: 'app-single-bid',
  templateUrl: './single-bid.component.html',
  styleUrls: ['./single-bid.component.scss']
})
export class SingleBidComponent implements OnInit {
  public user: any = {};
  public activeBid: any = {};
  public activeBidId = '';
  public accountActor: any = {};
  public actorType: string;
  public activeAccount: any = {};
  private activeUser: string;
  public accounts: Array<Object> = [];
  public artists: Array<Object> = [];
  public actors: Array<Object> = [];
  public VATRates: Array<Object> = [];
  public itemVATs: Array<Object> = [];
  public isLoading = true;
  public uploadLoading: Boolean = false;
  public addNewArtworkModalWindow: any;
  public advancedArtworkSearchModalWindow: any;
  public showAdvancedArtworkSearchModalWindow = false;
  public editBidForm: FormGroup;
  public searchArtworkForm: FormGroup;
  public searchArtworkResults: Array<Object> = [];
  public selectedArtworks: Array<Object> = [];
  public searchResultsFound = false;
  public VATPercentage = 0;
  public tradingCurrency = '';
  public myDatePickerOptions: IAngularMyDpOptions = {
    dateFormat: 'dd.mm.yyyy'
  };
  @ViewChild('AngularMyDatePickerEditBidCreated') AngularMyDatePickerEditBidCreated: AngularMyDatePickerDirective;
  @ViewChild('AngularMyDatePickerEditBidValid') AngularMyDatePickerEditBidValid: AngularMyDatePickerDirective;
  public options: UploaderOptions;
  public filesToUpload: UploadFile[];
  public uploadInput: EventEmitter<UploadInput>;
  public humanizeBytes: Function;
  public dragOver: boolean;
  private httpOptions = {
    headers: new HttpHeaders({
      'Content-Type':  'application/json',
      'charset': 'UTF-8'
    })
  };
  public imageLoadOffset$: any;
  private bidApproved = false;
  public numberWithDecimal = new RegExp(/^[0-9]+(\.[0-9]{1,2})?$/);

  constructor(
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private formBuilder: FormBuilder,
    private auth: AuthService,
    private userService: UserService,
    private activeService: ActiveService,
    private tradeService: TradeService,
    private accountService: AccountService,
    private bidService: BidService,
    private fileService: FileService,
    private searchService: SearchService,
    private itemService: ItemService
  ) {
    this.activatedRoute.data.subscribe(data => {
      this.activeBid = data.resolvedData;

      if (this.activeBid.items) {
        this.activeBid.items = this.activeBid.items.map(item => {
          item.created = moment.utc(item.created).locale('fi').format('L');
          /*switch (item.availability) {
            case 0:
              item.availability = 'Sold';
              break;
            case 1:
              item.availability = 'Booked';
              break;
            case 2: default:
            item.availability = 'Available';
            break;
          }*/

          const itemRowIndex = this.activeBid.itemRows.findIndex((itemRow: any) => itemRow._id === item._id);
          const defaultVAT: number = this.activeBid.vat ? this.activeBid.vat : 24;
          item = {
            VATPercentage: this.activeBid.itemRows[itemRowIndex] && this.activeBid.itemRows[itemRowIndex].vatpercentage ? this.activeBid.itemRows[itemRowIndex].vatpercentage : defaultVAT,
            discount: this.activeBid.itemRows[itemRowIndex] && this.activeBid.itemRows[itemRowIndex].discount ? this.activeBid.itemRows[itemRowIndex].discount : 0,
            ...item
          };

          const artistFound = this.artists.find((artist: any) => {
            return artist._id === item.artist;
          });

          if (artistFound === undefined) {
            this.artists.push({
              _id: item.artist,
              name: item.artistName,
              priceMultiplier: item.artistPriceMultiplier,
              status: item.artistStatus
            });
          }

          return item;
        });
      } else {
        this.activeBid.items = [];
      }

      this.activeBid.created = this.createDate(this.activeBid.created);
      this.activeBid.valid = this.createDate(this.activeBid.valid);
      this.activeBidId = data.resolvedData._id;

      if (data.resolvedData.customer) {
        if (data.resolvedData.customer.lastName) {
          data.resolvedData.customer.fullName = data.resolvedData.customer.name + ' ' + data.resolvedData.customer.lastName;
        } else {
          data.resolvedData.customer.fullName = data.resolvedData.customer.name;
        }
      } else {
        data.resolvedData.customer = {
          fullName: null
        };
      }

      if (data.resolvedData.seller) {
        if (data.resolvedData.seller.lastName) {
          data.resolvedData.seller.fullName = data.resolvedData.seller.name + ' ' + data.resolvedData.seller.lastName;
        } else {
          data.resolvedData.seller.fullName = data.resolvedData.seller.name;
        }
      } else {
        data.resolvedData.seller = {
          fullName: null
        };
      }
    });

    this.editBidForm = this.formBuilder.group({
      editBidId: new FormControl('', [
        Validators.required,
        Validators.maxLength(100)
      ]),
      editBidOwner: new FormControl('', [
        Validators.required,
        Validators.maxLength(100)
      ]),
      editBidName: new FormControl('', [
        Validators.maxLength(100)
      ]),
      editBidDescription: new FormControl('', [
        Validators.maxLength(2048)
      ]),
      editBidSeller: new FormControl({value: '', disabled: this.bidApproved}, [
        Validators.required,
        Validators.maxLength(100)
      ]),
      editBidCustomer: new FormControl({value: '', disabled: this.bidApproved}, [
        Validators.required,
        Validators.maxLength(100)
      ]),
      editBidType: new FormControl('', [
        Validators.required,
        Validators.maxLength(30)
      ]),
      editBidCreated: new FormControl('', [
        Validators.maxLength(10)
      ]),
      editBidValid: new FormControl('', [
        Validators.maxLength(10)
      ]),
      editBidOtherTerms: new FormControl('', [
        Validators.maxLength(2048)
      ]),
      editBidClientReference: new FormControl('', [
        Validators.maxLength(100)
      ]),
      editBidSellerReference: new FormControl('', [
        Validators.maxLength(100)
      ]),
      editBidStatus: new FormControl('', [
        Validators.maxLength(30)
      ]),
      editBidCurrency: new FormControl({value: '', disabled: true}, [
        Validators.maxLength(10)
      ]),
      editBidVAT: new FormControl({value: null, disabled: false}, [
        Validators.required,
        Validators.maxLength(10)
      ]),
      editBidShowNotes: new FormControl('', [
        Validators.maxLength(256)
      ]),
      editBidPrice: new FormControl('', [
        Validators.maxLength(100),
        Validators.pattern(this.numberWithDecimal)
      ]),
      editBidAttachments: new FormControl('', [
        Validators.maxLength(100)
      ]),
      editBidTags: new FormControl('', [
        Validators.maxLength(2048)
      ]),
      editBidNotes: new FormControl('', [
        Validators.maxLength(2048)
      ]),
      editBidComments: new FormControl('', [
        Validators.maxLength(2048)
      ]),
      editBidVisibility: new FormControl('', [
        Validators.maxLength(10)
      ])
    });

    this.searchArtworkForm = this.formBuilder.group({
      searchArtworkTerm: new FormControl('', [
        Validators.required,
        Validators.minLength(3),
        Validators.maxLength(50)
      ])
    });

    this.filesToUpload = [];
    this.uploadInput = new EventEmitter<UploadInput>();
    this.humanizeBytes = humanizeBytes;
  }

  ngOnInit() {
    if ([null, undefined].includes(this.activeBidId)) {
      this.activeBidId = this.bidService.getCurrentBid();
    }

    this.getUser();
  }

  getUser() {
    this.activeService.getActiveData().subscribe(
      data => {
        this.activeAccount = data.account.id;
        this.activeUser = data.user.id;
        this.userService.getUser(data.user).subscribe(
          data => {
            this.user = data;
            /*if (this.user.account[0]) {
              this.user.role = this.user.account[0].role;
            }*/
            this.getUsersAccounts();
          },
          () => {
            UIkit.notification({
              message: 'Error fetching user information',
              status: 'danger',
              pos: 'top-right',
              timeout: 1500
            });
          }
        );
      }
    );
    this.getAccountData();
  }

  disableSellerCustomer(): void {
    if (this.editBidForm.controls.editBidStatus.value === true) {
      this.editBidForm.controls.editBidSeller.disable();
      this.editBidForm.controls.editBidCustomer.disable();
    } else if (this.editBidForm.controls.editBidStatus.value === false) {
      if (this.editBidForm.value.editBidType === 'Sell') {
        this.editBidForm.controls.editBidSeller.disable();
        this.editBidForm.controls.editBidCustomer.enable();
      } else {
        this.editBidForm.controls.editBidSeller.enable();
        this.editBidForm.controls.editBidCustomer.disable();
      }
    }
  }

  getAccountData() {
    const getObject = {
      accountId: this.activeAccount,
      requester: 'bids',
      sortingKey: '_id',
      sortingOrder: 'desc',
      skipQuery: 0,
      limitQuery: null,
      contentTypes: ['account', 'artist', 'actor', 'variable']
    };

    this.accountService.getAccountData(getObject).subscribe(
      data => {
        this.artists = data.artists;
        this.actors = data.actors.map(actor => {
          if (actor.lastName) {
            actor.fullName = actor.name + ' ' + actor.lastName;
          } else {
            actor.fullName = actor.name;
          }

          return actor;
        });
        this.accountActor = data.account.actor;
        this.VATPercentage = data.account.vat;
        this.tradingCurrency = data.account.tradingCurrency;
        this.VATRates = data.account.VATRates.map((item) => {
          return {
            ...item,
            label: item.percentage + "% (" + item.description + ")"
          }});

        this.disableSellerCustomer();
      },
      () => {
        UIkit.notification({
          message: 'Error fetching data',
          status: 'danger',
          pos: 'top-right',
          timeout: 1500
        });
      },
      () => this.isLoading = false
    );
  }

  getUsersAccounts() {
    this.accountService.getUserAccounts(this.user).subscribe(
      data => {
        this.accounts = data;
      },
      () => {
        UIkit.notification({
          message: 'Error fetching accounts',
          status: 'danger',
          pos: 'top-right',
          timeout: 1500
        });
      },
      () => {
        this.isLoading = false;

      }
    );
  }

  editBid(silent?: Boolean) {
    const itemRows: Array<Object> = [];

    this.activeBid.items.forEach(item => {
      itemRows.push({
        _id: item._id,
        vatpercentage: item.VATPercentage,
        discount: item.discount
      });
    });

    // console.log('this.editBidForm.value.editBidCreated', this.editBidForm.value.editBidCreated.singleDate.jsDate);
    // console.log('moment.utc(item.created).localeData()', moment.utc(this.editBidForm.value.editBidCreated).localeData());
    // console.log('moment.utc(this.editBidForm.value.editBidCreated.jsdate)', moment.utc(this.editBidForm.value.editBidCreated.jsdate));
    // console.log('moment.utc(this.editBidForm.value.editBidCreated.jsdate).format()', moment.utc(this.editBidForm.value.editBidCreated.jsdate).format());

    const editBidObject = {
      id: this.editBidForm.value.editBidId,
      owner: this.editBidForm.value.editBidOwner,
      name: this.editBidForm.value.editBidName,
      description: this.editBidForm.value.editBidDescription,
      items: this.activeBid.items,
      itemRows: itemRows,
      actor: this.editBidForm.value.editBidSeller,
      seller: this.editBidForm.value.editBidType === 'Sell' ? this.accountActor._id : this.editBidForm.value.editBidSeller,
      customer: this.editBidForm.value.editBidType === 'Sell' ? this.editBidForm.value.editBidCustomer : this.accountActor._id,
      type: this.editBidForm.value.editBidType,
      created: this.editBidForm.value.editBidCreated ? moment(this.editBidForm.value.editBidCreated.singleDate.jsDate).format() : moment(new Date),
      valid: this.editBidForm.value.editBidValid ? moment(this.editBidForm.value.editBidValid.singleDate.jsDate).format() : moment(new Date),
      otherTerms: this.editBidForm.value.editBidOtherTerms,
      clientReference: this.editBidForm.value.editBidClientReference,
      sellerReference: this.editBidForm.value.editBidSellerReference,
      status: this.editBidForm.value.editBidStatus,
      currency: this.editBidForm.value.editBidCurrency,
      vat: this.editBidForm.value.editBidVAT.percentage,
      showNotes: this.editBidForm.value.editBidShowNotes,
      price: this.editBidForm.value.editBidPrice,
      attachments: this.activeBid.attachments,
      tags: this.editBidForm.value.editBidTags,
      notes: this.editBidForm.value.editBidNotes,
      comments: this.editBidForm.value.editBidComments,
      visibility: this.editBidForm.value.editBidVisibility
    };
    this.bidService.editBid(editBidObject).subscribe(
      res => {
        this.disableSellerCustomer();

        if (silent !== true) {
          UIkit.notification({
            message: 'Saved successfully',
            status: 'success',
            pos: 'top-right',
            timeout: 1500
          });
        }
      },
      error => {
        if (silent !== true) {
          UIkit.notification({
            message: 'Saving failed',
            status: 'danger',
            pos: 'top-right',
            timeout: 1500
          });
        }
      }
    );
  }

  approveBid(bid) {
    const approveBidObject = {
      id: bid._id,
      status: true
    };
    this.bidService.editBidApprovalStatus(approveBidObject).subscribe(
      res => {
        this.disableSellerCustomer();
        this.editBidForm.patchValue({
          editBidStatus: true
        });
        UIkit.notification({
          message: 'Bid approved successfully',
          status: 'success',
          pos: 'top-right',
          timeout: 1500
        });
      },
      error => {
        UIkit.notification({
          message: 'Bid closing failed',
          status: 'danger',
          pos: 'top-right',
          timeout: 1500
        });
      }
    );
  }

  searchArtwork() {
    /*this.isLoading = true;*/
    const searchObject = {
      searchArtworkAccountId: this.activeAccount,
      searchArtworkUserId: this.activeUser,
      searchArtworkTerm: this.searchArtworkForm.value.searchArtworkTerm
    };
    this.searchService.searchArtwork(searchObject).subscribe(
      res => {
        if (res.length) {
          if (this.searchArtworkResults) {
            this.searchArtworkResults.length = 0;
          }
          res.forEach(artwork => {
            artwork.allResultType = 'artwork';
            this.searchArtworkResults.push(artwork);
          });
          /*this.isLoading = false;*/
          this.searchResultsFound = true;
          document.getElementById('search-artwork-results-container').classList.add('active');

          UIkit.notification({
            message: 'Search matched ' + this.searchArtworkResults.length + ' results',
            status: 'success',
            pos: 'top-right',
            timeout: 1500
          });
        } else {
          if (this.searchArtworkResults) {
            this.searchArtworkResults.length = 0;
          }
          UIkit.notification({
            message: 'No search results',
            status: 'danger',
            pos: 'top-right',
            timeout: 1500
          });
        }
      },
      error => {
        if (this.searchArtworkResults) {
          this.searchArtworkResults.length = 0;
        }
        UIkit.notification({
          message: 'No search results',
          status: 'danger',
          pos: 'top-right',
          timeout: 1500
        });
      }
    );
  }

  emptySearch(): void {
    /*document.getElementById('search-artwork-results-container').classList.remove('active');
    let element: HTMLElement = document.getElementsByClassName('uk-navbar-toggle uk-close')[1] as HTMLElement;
    element.click();*/

    setTimeout(() => {
      this.searchResultsFound = false;
      if (this.searchArtworkResults) {
        this.searchArtworkResults.length = 0;
      }
      if (this.selectedArtworks) {
        this.selectedArtworks.length = 0;
      }
      this.searchArtworkForm.patchValue({ searchArtworkTerm: ''});
    }, 300);
  }

  selectBidArtwork(artwork): void {
    this.selectedArtworks.indexOf(artwork) === -1 ?
      this.selectedArtworks.push(artwork) : this.selectedArtworks.splice(this.selectedArtworks.indexOf(artwork), 1);
  }

  addArtworksToBid(): void {
    this.selectedArtworks.map((item: any) => {
      const itemFound = this.activeBid.items.find((activeBidItem: any) => {
        return activeBidItem._id === item._id;
      });
      if (itemFound === undefined) {
        this.activeBid.items.push(item);
      }
      const artistFound = this.artists.find((artist: any) => {
        return artist._id === item.artist;
      });
      if (artistFound === undefined) {
        this.artists.push({
          _id: item.artist,
          name: item.artistName,
          priceMultiplier: item.artistPriceMultiplier,
          status: item.artistStatus
        });
      }
    });

    this.editBid(true);
    this.emptySearch();
  }

  removeArtworkFromBid(item): void {
    this.activeBid.items.splice(this.activeBid.items.indexOf(item), 1);
    const artistToSplice = this.artists.find((artist: any) => {
      return artist._id === item.artist;
    });
    this.artists.splice(this.artists.indexOf(artistToSplice), 1);
  }

  openSlideshow($event): void {
    $event.preventDefault();
    UIkit.lightboxPanel({
    /* Ei tarvitse, jos muistaa käyttää $event.preventDefaultia
      autoplay: true,
      autoplayInterval: 5000,
      delayControls: 1000,*/
      items: this.activeBid.items.map(item => {
        let itemImage;
        if (item.coverImage) {
          itemImage = '/api/image/' + item.coverImage;
        } else if (item.images[0]) {
          itemImage = '/api/image/' + item.images[0].fileId;
        } else {
          itemImage = '/api/image/artbuddy-logo-placeholder.svg';
        }

        return {
          source: itemImage,
          caption: item.name
        };
      })
    }).show();
  }

  openArtist(artistId): void {
    this.router.navigateByUrl('/artist/' + artistId);
  }

  onAttachmentsUploadOutput(output: UploadOutput): void {
    const properFile = output.file && output.file.name && output.file.name.length > 0;

    if (output.type === 'allAddedToQueue') {
      const event: UploadInput = {
        type: 'uploadAll',
        url: '/api/file',
        method: 'POST',
        includeWebKitFormBoundary: true
      };
      this.uploadInput.emit(event);
    } else if (output.type === 'addedToQueue' && properFile) {
      if (this.filesToUpload.indexOf(output.file) == -1) {
        this.filesToUpload.push(output.file);
      }
    } else if (output.type === 'uploading' && properFile) {
      if (!this.activeBid['attachments'].some(image => image.placeholderId === output.file.id)) {
        this.uploadLoading = true;
        this.activeService.increasePendingRequests();
        this.activeBid['attachments'].push({ placeholderId: output.file.id });
        const index = this.filesToUpload.findIndex(file => file.id === output.file.id);
        this.filesToUpload[index] = output.file;
      }
    } else if (output.type === 'removed') {
      // remove file from array when removed
      this.filesToUpload = this.filesToUpload.filter((file: UploadFile) => file !== output.file);
    } else if (output.type === 'dragOver') {
      this.dragOver = true;
    } else if (output.type === 'dragOut') {
      this.dragOver = false;
    } else if (output.type === 'drop') {
      this.dragOver = false;
    } else if (output.type === 'removedAll') {
      this.filesToUpload.length = 0;
    } else if (output.type === 'done') {
      const fileObject = {
        itemId: this.activeBidId,
        fileId: output.file.response,
        name: output.file.name,
        contentType: output.file.type
      };
      this.removeFile(output.file.id);
      const indexToReplace = this.activeBid['attachments'].findIndex(file => file.placeholderId === output.file.id);
      this.activeBid['attachments'][indexToReplace] = fileObject;
      this.activeService.decreasePendingRequests();
      this.uploadLoading = false;

      /*this.activeBid.images.push({
        /!*contentType: output.file.response.mimetype,
        filename: output.file.response.filename,*!/
        fileId: output.file.response.id
      });*/
      /*});*/
    }
  }

  startUpload(): void {
    const event: UploadInput = {
      type: 'uploadAll',
      url: '/api/file',
      method: 'POST',
      includeWebKitFormBoundary: true
    };
    this.uploadInput.emit(event);
  }

  cancelUpload(id: string): void {
    this.uploadInput.emit({ type: 'cancel', id: id });
  }

  removeFile(id: string): void {
    this.uploadInput.emit({ type: 'remove', id: id });
  }

  openAttachment(attachment) {
    this.itemService.openAttachment(this.activeAccount, attachment).then((response: any) => {
      if (response.url) {
        window.open(response.url);
      } else {
        UIkit.notification({
          message: 'Error fetching data',
          status: 'danger',
          pos: 'top-right',
          timeout: 1500
        });
      }
    });
  }

  removeAttachment(attachment) {
    const attachmentObject = {
      documentType: 'bid',
      itemId: this.activeBidId,
      fileId: attachment.fileId
    };

    this.fileService.removeFile(attachmentObject).subscribe(
      res => {
        const attachmentToSplice = this.activeBid.attachments.find((accountAttachment: any) => {
          return attachment.fileId === accountAttachment.fileId;
        });
        this.activeBid.attachments.splice(this.activeBid.attachments.indexOf(attachmentToSplice), 1);
        UIkit.notification({
          message: 'File removed successfully',
          status: 'success',
          pos: 'top-right',
          timeout: 1500
        });
      },
      error => {
        UIkit.notification({
          message: 'Removing failed',
          status: 'danger',
          pos: 'top-right',
          timeout: 1500
        });
      }
    );
  }

  getCSV(): void {
    const getCSVObject: any = {
      Name: this.editBidForm.value.editBidName,
      Description: this.editBidForm.value.editBidDescription,
      Type: this.editBidForm.value.editBidType,
      Items: this.activeBid.items,
      'Actor': this.editBidForm.value.editBidSeller,
      'Seller name': this.editBidForm.value.editBidType === 'Sell' ? this.accountActor._id : this.editBidForm.value.editBidSeller,
      'Agent name': this.editBidForm.value.editBidType === 'Sell' ? this.accountActor._id : this.editBidForm.value.editBidSeller,
      'Buyer name': this.editBidForm.value.editBidType === 'Sell' ? this.activeBid.customer : this.accountActor._id,
      Created: this.editBidForm.value.editBidCreated ? moment(this.editBidForm.value.editBidCreated.singleDate.jsDate).format() : moment(new Date),
      Valid: this.editBidForm.value.editBidValid ? moment(this.editBidForm.value.editBidValid.singleDate.jsDate).format() : moment(new Date),
      'Client reference': this.editBidForm.value.editBidClientReference,
      'Seller reference': this.editBidForm.value.editBidSellerReference,
      'Approval status': this.editBidForm.value.editBidStatus,
      Currency: this.editBidForm.value.editBidCurrency,
      VAT: this.editBidForm.value.editBidVAT.percentage,
      Price: this.getItemsDiscountedPrice().toFixed(2),
      // VAT: this.getItemsVAT().toFixed(2),
      Tags: this.editBidForm.value.editBidTags,
      Notes: this.editBidForm.value.editBidNotes,
      Comments: this.editBidForm.value.editBidComments,
      Visibility: this.editBidForm.value.editBidVisibility
    };

    // If value is object rather than string
    if (typeof getCSVObject['Seller name'] === 'object') {
      if (getCSVObject['Seller name'].lastName) {
        // Combine name and lastName
        getCSVObject['Seller name'] = getCSVObject['Seller name'].name + ' ' + getCSVObject['Seller name'].lastName;
      } else {
        getCSVObject['Seller name'] = getCSVObject['Seller name'].name;
      }
    }

    if (typeof getCSVObject['Agent name'] === 'object') {
      if (getCSVObject['Agent name'].lastName) {
        getCSVObject['Agent name'] = getCSVObject['Agent name'].name + ' ' + getCSVObject['Agent name'].lastName;
      } else {
        getCSVObject['Agent name'] = getCSVObject['Agent name'].name;
      }
    }

    if (typeof getCSVObject['Buyer name'] === 'object') {
      if (getCSVObject['Buyer name'].lastName) {
        getCSVObject['Buyer name'] = getCSVObject['Buyer name'].name + ' ' + getCSVObject['Buyer name'].lastName;
      } else {
        getCSVObject['Buyer name'] = getCSVObject['Buyer name'].name;
      }
    }

    this.fileService.getCSV('Bid', getCSVObject);
  }

  getBidPDF(): void {
    if (this.isLoading) { return; }

    const createdYear = this.editBidForm.get("editBidCreated").value.singleDate.date.year;
    const createdMonth = this.editBidForm.get("editBidCreated").value.singleDate.date.month;
    const createdDay = this.editBidForm.get("editBidCreated").value.singleDate.date.day;

    const createdDate = `${createdDay}.${createdMonth}.${createdYear}`;

    const bidObject = {
      items: [],
      notes: this.editBidForm.value.editBidShowNotes,
      pricing: {},
      sellerInfo: {
        name: this.activeBid.seller.fullName,
        streetAddress: this.activeBid.seller.street,
        postalCode: this.activeBid.seller.zip,
        city: this.activeBid.seller.city,
      },
      clientInfo: {
        name: this.activeBid.customer.fullName,
        streetAddress: this.activeBid.customer.street,
        postalCode: this.activeBid.customer.zip,
        city: this.activeBid.customer.city,
      },
      date: createdDate
    };

    if (this.activeBid.items.length) {
      this.activeBid.items.forEach(item => {
        const itemName = item.name;
        let itemImage = null;

        if (item.coverImage) {
          itemImage = item.coverImage;
        } else if (item.images && item.images.length) {
          itemImage = item.images[0].fileId;
        }

        const itemArtistName = item.artistName;
        const itemCreated = moment(this.editBidForm.value.editBidCreated.singleDate.jsDate).isValid() ? moment(this.editBidForm.value.editBidCreated.singleDate.jsDate).locale('fi').format('YYYY') : null;
        const itemTechnique = item.technique;

        /* Näitä tietoja ei varmaankaan tarvita */
        let itemSize = '';
        if (item.height) {
          itemSize += item.height;
        }
        if (item.width) {
          if (item.height) {
            itemSize += ' x ';
          }
          itemSize += item.width;
        }
        if (item.depth) {
          if (item.height) {
            itemSize += ' x ';
          }
          itemSize += item.depth;
        }

        const itemPrice = item.price.price + ' ' + item.price.currency;
        const itemAvailability = item.availability;

        let BidItem: any = {};
        BidItem = {
          'id': item._id,
          'account': this.activeAccount,
          'Artist': itemArtistName,
          'Item name': itemName,
        };

        if (itemCreated) {
          BidItem['Item created'] = itemCreated;
        }

        if (itemTechnique) {
          BidItem['Item technique'] = itemTechnique;
        }

        if (itemSize) {
          BidItem['Item size'] = itemSize + ' cm';
        }

        if (this.getItemDiscountedPrice(item)) {
          const price = item.price.price; // this.getItemDiscountedPrice(item);

          if (price) {
            BidItem['Item price'] = (price !== null ? price : '') + ' ' + (item.price.currency ? item.price.currency : '');
          } else {
            BidItem['Item price'] = 'Not set';
          }
        }

        /*if (itemAvailability) {
          BidItem['Item availability'] = itemAvailability;
        }*/

        if (itemImage) {
          BidItem.Image = itemImage;
        }

        bidObject.items.push(BidItem);
      });
    }

    bidObject.pricing = {
      total: this.getItemsTotalPrice(),
      discount: this.getDiscountedPrice(),
      grandTotal: this.getItemsDiscountedPrice(),
      vatTotal: this.getItemsVAT().toFixed(2),
      currency: this.tradingCurrency
    };

    this.fileService.getPDF('Proposal', bidObject);
    this.closeOpenPDFDropdown();
  }

  getBidPDFExternal(): void {
    const bidObject = {
      date: moment.utc(new Date()).locale('fi').format('L'),
      accountId: this.activeAccount,
      accountLogo: (this.accountActor.images && this.accountActor.images.length > 0) ? this.accountActor.images[0].fileId : null,
      items: [],
      notes: this.editBidForm.value.editBidShowNotes,
      pricing: {},
      sellerInfo: {
        name: this.activeBid.seller.fullName,
        streetAddress: this.activeBid.seller.street,
        postalCode: this.activeBid.seller.zip,
        city: this.activeBid.seller.city,
      },
      clientInfo: {
        name: this.activeBid.customer.fullName,
        streetAddress: this.activeBid.customer.street,
        postalCode: this.activeBid.customer.zip,
        city: this.activeBid.customer.city,
      }
    };

    if (this.activeBid.items.length) {
      this.activeBid.items.forEach(item => {
        const itemName = item.name;
        let itemImage = null;

        if (item.coverImage) {
          itemImage = item.coverImage;
        } else if (item.images && item.images.length) {
          itemImage = item.images[0].fileId;
        }

        const itemArtistName = item.artistName;
        const itemCreated = moment(this.editBidForm.value.editBidCreated.singleDate.jsDate).isValid() ? moment(this.editBidForm.value.editBidCreated.singleDate.jsDate).locale('fi').format('YYYY') : null;
        const itemTechnique = item.technique;

        /* Näitä tietoja ei varmaankaan tarvita */
        let itemSize = '';
        if (item.height) {
          itemSize += item.height;
        }
        if (item.width) {
          if (item.height) {
            itemSize += ' x ';
          }
          itemSize += item.width;
        }
        if (item.depth) {
          if (item.height) {
            itemSize += ' x ';
          }
          itemSize += item.depth;
        }

        const itemPrice = item.price.price + ' ' + item.price.currency;
        const itemAvailability = item.availability;

        let BidItem: any = {};
        BidItem = {
          'id': item._id,
          'account': this.activeAccount,
          'Artist': itemArtistName,
          'Item name': itemName,
        };

        BidItem['Item created'] = itemCreated ?? '';
        BidItem['Item technique'] = itemTechnique ?? '';
        BidItem['Item size'] = itemSize ? itemSize + ' cm' : '';

        if (this.getItemDiscountedPrice(item)) {
          const price = this.getItemDiscountedPrice(item);

          if (price) {
            BidItem['Item price'] = (price !== null ? price : '') + ' ' + (item.price.currency ? item.price.currency : '');
          } else {
            BidItem['Item price'] = 'Not set';
          }
        } else {
          BidItem['Item price'] = 'Not set';
        }

        /*if (itemAvailability) {
          BidItem['Item availability'] = itemAvailability;
        }*/

        if (itemImage) {
          BidItem.Image = itemImage;
        }

        bidObject.items.push(BidItem);
      });
    }

    bidObject.pricing = {
      total: Math.round(this.getItemsTotalPrice()),
      discount: this.getDiscountedPrice(),
      grandTotal: Math.round(this.getItemsDiscountedPrice()),
      vatTotal: this.getItemsVAT().toFixed(2),
      currency: this.tradingCurrency
    };

    this.fileService.getPDFExternal('Proposal', bidObject);
    this.closeOpenPDFDropdown();
  }

  getItemsTotalPrice(): number {
    let totalPrice = 0;

    this.activeBid.items.forEach(item => {
      if (typeof item.price.price === 'number') {
        totalPrice += parseFloat(item.price.price);
      }
    });

    return totalPrice;
  }

  getItemsGrandTotalPrice(): number {
    let totalPrice = 0;
    let discount = 0;

    this.activeBid.items.forEach(item => {
      if (item.discount && item.discount >= 0) {
        discount += parseFloat(item.discount);
        totalPrice += parseFloat(item.price.price);
      } else if (typeof item.price.price === 'number') {
        totalPrice += parseFloat(item.price.price);
      }
    });

    if (discount > 0) {
      return totalPrice - discount;
    } else {
      return totalPrice;
    }
  }

  getItemsDiscountedPrice(): number {
    const totalPrice: number = this.getItemsTotalPrice();
    const discountedPrice: number = this.getDiscountedPrice().price;
    const itemsDiscountedPrice = totalPrice - discountedPrice;

    return itemsDiscountedPrice;
  }

  getItemsVAT(): number {
    let totalVAT = 0;
    const items = this.getUniqueVATs();
    items.forEach(item => totalVAT += item.amount);

    return totalVAT;
  }

  getDiscountedPrice(): any {
    const itemsTotalPrice: number = this.getItemsTotalPrice();
    const itemsBiddedPrice = this.getItemsGrandTotalPrice();

    let discount = {
      price: 0,
      percentage: 0
    };

    if (itemsBiddedPrice > 0) {
      discount = {
        price: Math.round((itemsTotalPrice - itemsBiddedPrice) > 0 ? itemsTotalPrice - itemsBiddedPrice : 0),
        percentage: Number(((1 - (itemsBiddedPrice / itemsTotalPrice)) * 100).toFixed(2))
      };
    }

    return discount;
  }

  getItemVATAmount(item: any): number {
    let itemVATAMount: number,
        itemPrice: number;

    if (item.discount && item.discount >= 0) {
      itemPrice = item.price.price - item.discount;

    } else if (typeof item.price.price === 'number') {
      itemPrice = parseFloat(item.price.price);
    }

    // if (item.VATPercentage) {
    //   itemVATAMount = itemPrice - (itemPrice * (100 / (100 + parseInt(item.VATPercentage, 0))));
    //   return itemVATAMount;
    // } else {
    //   return null;
    // }

    itemVATAMount = itemPrice - (itemPrice * (100 / (100 + parseInt(this.activeBid.vat, 0))));

    return itemVATAMount > 0 ? itemVATAMount : 0;
  }

  getItemPriceWithoutVAT(item: any): number {
    const itemVATAmount: number = this.getItemVATAmount(item);
    const priceWithoutVAT: number = item.price.price - itemVATAmount;
    return priceWithoutVAT;
  }

  getItemDiscountedPrice(item: any): number {
    const itemTotalPrice: number = item.price.price;
    const itemDiscountedPrice: number = item.discount;

    if (itemDiscountedPrice && itemDiscountedPrice > 0) {
      return item.price.price - itemDiscountedPrice;
    } else {
      return itemTotalPrice;
    }
  }

  setItemVATPercentage(itemId: string, event: any): void {
    const selectedVATPercentage = event.target.value;
    const activeItemIndex = this.activeBid.items.findIndex(item => item._id === itemId);
    this.activeBid.items[activeItemIndex].VATPercentage = selectedVATPercentage;
  }

  setItemTotalPrice(itemId: string, setItemTotalPrice: any): void {
    const activeItemIndex = this.activeBid.items.findIndex(item => item._id === itemId);
    this.activeBid.items[activeItemIndex].totalPrice = setItemTotalPrice;
  }

  setItemDiscount(itemId: string, setItemDiscount: any): void {
    const activeItemIndex = this.activeBid.items.findIndex(item => item._id === itemId);
    this.activeBid.items[activeItemIndex].discount = setItemDiscount;
  }

  getUniqueVATs(): any {
    const uniqueVATs: Array<any> = [];

    this.activeBid.items.forEach(item => {
      if (item.VATPercentage === undefined) {
        item.VATPercentage = this.activeBid.vat;
      }

      const uniqueIndex = uniqueVATs.findIndex((uniqueItem: any) => {
        return uniqueItem.percentage === this.activeBid.vat; // Always use activeBid.vat instead of item.VATPercentage
      });

      /* Jos itemin percentage ei ole vielä uniikeissa */
      if (uniqueIndex === -1) {
        /* Lisätään itemin VATPercentage ja hinta uniikkeihin */
        uniqueVATs.push({
          percentage: this.activeBid.vat, // Always use activeBid.vat instead of item.VATPercentage,
          amount: this.getItemVATAmount(item) }
        );
      } else {
        /* Jos percentage on jo arrayssa, lisätään itemin hinta amountiin */
        uniqueVATs[uniqueIndex].amount += this.getItemVATAmount(item);
      }
    });

    // uniqueVATs.push({ percentage: this.activeBid.VAT, amount: this.getItemsVAT() });

    return uniqueVATs;
  }

  createTrade(): void {
    let discount = 0;

    this.activeBid.items.forEach((item: any) => discount += parseInt(item.discount, 0));

    const createTradeObject = {
      owner: this.editBidForm.value.editBidOwner,
      name: this.editBidForm.value.editBidName,
      description: this.editBidForm.value.editBidDescription,
      bid: this.activeBidId,
      type: this.editBidForm.value.editBidType,
      items: this.activeBid.items,
      discount: discount,
      /*items: this.activeBid.items,*/ // Tämä tehdään vasta single-tradessa
      /*actor: this.editBidForm.value.editBidSeller,*/
      seller: this.editBidForm.value.editBidType === 'Sell' ? this.accountActor._id : this.editBidForm.value.editBidSeller,
      agent: this.editBidForm.value.editBidType === 'Sell' ? this.accountActor._id : this.editBidForm.value.editBidSeller,
      buyer: this.editBidForm.value.editBidType === 'Sell' ? (this.activeBid.customer.fullName.id ?? this.activeBid.customer._id ?? this.activeBid.customer) : this.accountActor._id,
      created: this.editBidForm.value.editBidCreated ? moment(this.editBidForm.value.editBidCreated.singleDate.jsDate).format() : moment(new Date),
      currency: this.editBidForm.value.editBidCurrency,
      valid: this.editBidForm.value.editBidValid ? moment(this.editBidForm.value.editBidValid.singleDate.jsDate).format() : moment(new Date),
      price: this.getItemsDiscountedPrice().toFixed(2),
      vat: this.activeBid.vat, // this.editBidForm.value.editBidVAT.percentage, // this.getItemsVAT().toFixed(2),
      showNotes: this.editBidForm.value.editBidShowNotes,
      tags: this.editBidForm.value.editBidTags,
      notes: this.editBidForm.value.editBidNotes,
      comments: this.editBidForm.value.editBidComments,
      visibility: this.editBidForm.value.editBidVisibility,
      attachments: this.activeBid.attachments
    };

    if (!createTradeObject.seller || !createTradeObject.buyer) {
      return UIkit.notification({
        message: 'Saving failed',
        status: 'danger',
        pos: 'top-right',
        timeout: 1500
      });
    }

    this.tradeService.addTrade(createTradeObject).subscribe(
      res => {
        this.approveBid({ _id: this.editBidForm.value.editBidId });
        UIkit.notification({
          message: 'Trade created successfully',
          status: 'success',
          pos: 'top-right',
          timeout: 1500
        });
      },
      error => {
        UIkit.notification({
          message: 'Saving failed',
          status: 'danger',
          pos: 'top-right',
          timeout: 1500
        });
      }
    );
  }

  openAdvancedSearch(): void {
    this.showAdvancedArtworkSearchModalWindow = true;
    setTimeout(() => {
      this.advancedArtworkSearchModalWindow = window.document.getElementById('advancedArtworkSearch');
      UIkit.modal(this.advancedArtworkSearchModalWindow).show();
    }, 500);
  }

  setArtworkSearchResults(searchResult: Array<any>) {
    searchResult.forEach(item => this.selectedArtworks.push(item));
    this.addArtworksToBid();
  }

  createDate(inputDate: string): any {
    return {
      isRange: false,
      singleDate: {
        date: {
          year: new Date(inputDate).getFullYear(),
          month: new Date(inputDate).getMonth() + 1,
          day: new Date(inputDate).getDate()
        }
      }
    };
  }

  updateDate(fieldName: string, dateObject: any) {
    this.editBidForm.controls[fieldName].patchValue(moment(dateObject.singleDate.jsDate).format());
  }

  getCharactersLimitError(fieldName: string) {
    if (!fieldName || !this.editBidForm.get(fieldName).value ) {
      return ''
    }

    const charactersOverLimit: number = this.editBidForm.get(fieldName).value.length - this.editBidForm.get(fieldName).errors.maxlength.requiredLength;

    return `Field length is ${ charactersOverLimit } characters over limit`;
  }

  getCharactersLeft(fieldName: string, charactersLimit: number) {
    if (!fieldName || !this.editBidForm.get(fieldName).value ) {
      return ''
    }

    const fieldLength: number = this.editBidForm.get(fieldName).value.length;
    const charactersOverLimit: number = fieldLength - charactersLimit;

    if (charactersOverLimit >= 0) { return `${ charactersLimit } / ${ charactersLimit }` }

    return `${ fieldLength } / ${ charactersLimit }`;
  }

  closeOpenPDFDropdown(): void {
    const openPDFDropdownElement = window.document.getElementById('openPDFDropdown');
    UIkit.dropdown(openPDFDropdownElement).hide();
  }
}
