import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { ResolverService } from './services/resolver.service';
/*import { AppComponent } from './app.component';*/
import { RegisterComponent } from './register/register.component';
import { LoginComponent } from './login/login.component';
import { ForgotComponent } from './forgot/forgot.component';
import { LogoutComponent } from './logout/logout.component';
import { AccountsComponent } from './accounts/all-accounts/accounts.component';
import { SingleAccountComponent } from './accounts/single-account/single-account.component';
import { ProfileComponent } from './profile/profile.component';
import { AdminComponent } from './admin/admin.component';
import { NotFoundComponent } from './not-found/not-found.component';
import { TermsComponent } from './terms/terms.component';

import { OverviewComponent } from './overview/overview.component';
import { ArtworksComponent } from './artworks/all-artworks/artworks.component';
import { SingleArtworkComponent } from './artworks/single-artwork/single-artwork.component';
import { ExhibitionsComponent } from './exhibitions/all-exhibitions/exhibitions.component';
import { SingleExhibitionComponent } from './exhibitions/single-exhibition/single-exhibition.component';
import { CollectionsComponent } from './collections/all-collections/collections.component';
import { SingleCollectionComponent } from './collections/single-collection/single-collection.component';
import { EstimatesComponent } from './estimates/all-estimates/estimates.component';
import { SingleEstimateComponent } from './estimates/single-estimate/single-estimate.component';
import { BidsComponent } from './bids/all-bids/bids.component';
import { SingleBidComponent } from './bids/single-bid/single-bid.component';
import { ReservationsComponent } from './reservations/all-reservations/reservations.component';
import { SingleReservationComponent } from './reservations/single-reservation/single-reservation.component';
import { TradesComponent } from './trades/all-trades/trades.component';
import { SingleTradeComponent } from './trades/single-trade/single-trade.component';
import { ArtistsComponent } from './artists/all-artists/artists.component';
import { SingleArtistComponent } from './artists/single-artist/single-artist.component';
import { ClientsComponent } from './clients/all-clients/clients.component';
import { SingleClientComponent } from './clients/single-client/single-client.component';

import { AuthGuardLogin } from './services/auth-guard-login.service';
import { AuthGuardAdmin } from './services/auth-guard-admin.service';
import { AdvancedSearchWrapperComponent } from './header/search-components/advanced-search-wrapper/advanced-search-wrapper.component';

const routes: Routes = [

  /* User */
  { path: 'register', component: RegisterComponent },
  { path: 'login', component: LoginComponent },
  { path: 'forgot', component: ForgotComponent },
  { path: 'logout', component: LogoutComponent, canActivate: [ AuthGuardLogin ] },

  /* Abstract */
  { path: 'overview', component: OverviewComponent, pathMatch: 'full', resolve: { resolvedData: ResolverService }, canActivate: [ AuthGuardLogin ] },

  /* Profile */
  { path: 'profile', component: ProfileComponent, canActivate: [ AuthGuardLogin ] },
  { path: 'admin', component: AdminComponent, canActivate: [AuthGuardAdmin] },

  /* Account */
  { path: 'accounts', component: AccountsComponent, resolve: { resolvedData: ResolverService }, canActivate: [ AuthGuardLogin ] },
  { path: 'account/:id', component: SingleAccountComponent, resolve: { resolvedData: ResolverService }, canActivate: [ AuthGuardLogin ] },

    /* Artwork */
  { path: 'artworks', component: ArtworksComponent, resolve: { resolvedData: ResolverService }, canActivate: [ AuthGuardLogin ] },
  { path: 'artwork/:id', component: SingleArtworkComponent, resolve: { resolvedData: ResolverService }, canActivate: [ AuthGuardLogin ] },

  /* Artist */
  { path: 'artists', component: ArtistsComponent, resolve: { resolvedData: ResolverService }, canActivate: [ AuthGuardLogin ] },
  { path: 'artist/:id', component: SingleArtistComponent, resolve: { resolvedData: ResolverService }, canActivate: [ AuthGuardLogin ] },

  /* Exhibition */
  { path: 'exhibitions', component: ExhibitionsComponent, resolve: { resolvedData: ResolverService }, canActivate: [ AuthGuardLogin ] },
  { path: 'exhibition/:id', component: SingleExhibitionComponent, resolve: { resolvedData: ResolverService }, canActivate: [ AuthGuardLogin ] },

  /*  Collection */
  { path: 'collections', component: CollectionsComponent, resolve: { resolvedData: ResolverService }, canActivate: [ AuthGuardLogin ] },
  { path: 'collection/:id', component: SingleCollectionComponent, resolve: { resolvedData: ResolverService }, canActivate: [ AuthGuardLogin ] },

  /* Client */
  { path: 'clients', component: ClientsComponent, resolve: { resolvedData: ResolverService }, canActivate: [ AuthGuardLogin ] },
  { path: 'client/:id', component: SingleClientComponent, resolve: { resolvedData: ResolverService }, canActivate: [ AuthGuardLogin ] },

  /* Estimate */
  { path: 'estimates', component: EstimatesComponent, resolve: { resolvedData: ResolverService }, canActivate: [ AuthGuardLogin ] },
  { path: 'estimate/:id', component: SingleEstimateComponent, resolve: { resolvedData: ResolverService }, canActivate: [ AuthGuardLogin ] },

  /* Bid */
  { path: 'bids', component: BidsComponent, resolve: { resolvedData: ResolverService }, canActivate: [ AuthGuardLogin ] },
  { path: 'bid/:id', component: SingleBidComponent, resolve: { resolvedData: ResolverService }, canActivate: [ AuthGuardLogin ] },

  /* Reservation */
  { path: 'reservations', component: ReservationsComponent, resolve: { resolvedData: ResolverService }, canActivate: [ AuthGuardLogin ] },
  { path: 'reservation/:id', component: SingleReservationComponent, resolve: { resolvedData: ResolverService }, canActivate: [ AuthGuardLogin ] },

  /* Trade */
  { path: 'trades', component: TradesComponent, resolve: { resolvedData: ResolverService }, canActivate: [ AuthGuardLogin ] },
  { path: 'trade/:id', component: SingleTradeComponent, resolve: { resolvedData: ResolverService }, canActivate: [ AuthGuardLogin ] },

  /* Static pages */
  { path: 'notfound', component: NotFoundComponent, canActivate: [ AuthGuardLogin ] },
  { path: 'terms', component: TermsComponent, canActivate: [ AuthGuardLogin ] },
  { path: 'search', component: AdvancedSearchWrapperComponent },

  /* Not found */
  /* TODO: Ohjataan tänne, jos resolverServicess tulee reject? */
  /*{ path: '**', redirectTo: '/notfound' }*/

  {
    path: '**',
    redirectTo: 'overview',
    pathMatch: 'full'
  }

];

@NgModule({
  imports: [ RouterModule.forRoot(routes, { enableTracing: false, onSameUrlNavigation: 'reload' }) ],
  exports: [ RouterModule ]
})

export class RoutingModule {}
