<div class="uk-section uk-section-default uk-padding-remove-top" uk-height-viewport="offset-top: true">

  <app-sidenav contentType="Collections"></app-sidenav>

  <div class="uk-container-expand uk-height-expand">
    <div uk-grid>
      <div class="uk-width-expand@m">
        <div class="uk-padding-small uk-padding-remove-top" *ngIf="!isLoading">
          <div class="uk-width-1-1 uk-text-right uk-margin-small-bottom uk-padding-remove">
            <app-search-content contentType="collection" fields="['name', 'location']"></app-search-content>
            <button class="uk-button uk-button-text uk-margin-right" type="button" (click)="openAddNewCollection()">Add new</button>
            <div id="addNewCollection" class="uk-modal-container" uk-modal="stack: true; bg-close: false; esc-close: false;">
              <div class="uk-modal-dialog">
                <form appNoSubmitEnter [formGroup]="addCollectionForm">
                  <input type="hidden" name="addCollectionOwner" name="addCollectionOwner" formControlName="addCollectionOwner" [ngModel]="activeAccount">
                  <div class="uk-modal-header">
                    <h2 class="uk-modal-title">Add new collection</h2>
                  </div>
                  <div class="uk-modal-body" uk-grid>
                    <div class="uk-width-1-2@s">
                      <div class="uk-margin">
                        <label class="uk-form-label" for="addCollectionName">Name <span class="required">*</span></label>
                        <div class="uk-form-controls">
                          <input id="addCollectionName" name="addCollectionName" formControlName="addCollectionName" type="text" placeholder="Name ..." class="uk-input" [ngClass]="{'uk-form-danger': addCollectionForm.get('addCollectionName').touched && addCollectionForm.get('addCollectionName').errors }" (blur)="validateName()">
                          <div class="uk-text-danger uk-text-small"
                               *ngIf="addCollectionForm.get('addCollectionName').hasError('maxlength')"
                          >
                            {{ getCharactersLimitError('addCollectionName') }}
                          </div>
                          <div class="uk-text-danger uk-text-small"
                               *ngIf="addCollectionForm.get('addCollectionName').hasError('pattern')"
                          >
                            Field value is incorrect
                          </div>
                          <span *ngIf="addCollectionForm.controls.addCollectionName.hasError('exists')" class="uk-text-danger uk-text-small">Name might not be unique</span>
                        </div>
                      </div>
                    </div>
                    <div class="uk-width-1-2@s">
                      <div class="uk-margin">
                        <div class="uk-flex uk-flex-between uk-text-meta">
                        <label class="uk-form-label" for="addCollectionDescription">Description</label>
                        <div class="uk-text-small">
                          {{ getCharactersLeft('addCollectionDescription', 2048) }}
                        </div>
                        </div>
                        <div class="uk-form-controls">
                          <textarea id="addCollectionDescription" name="addCollectionDescription" formControlName="addCollectionDescription" rows="5" placeholder="Description ..." class="uk-textarea" [ngClass]="{'uk-form-danger': addCollectionForm.get('addCollectionDescription').touched && addCollectionForm.get('addCollectionDescription').errors }"></textarea>
                          <div class="uk-text-danger uk-text-small"
                               *ngIf="addCollectionForm.get('addCollectionDescription').hasError('maxlength')"
                          >
                            {{ getCharactersLimitError('addCollectionDescription') }}
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="uk-width-1-2@s">
                      <div class="uk-margin">
                        <label class="uk-form-label" for="addCollectionType">Collection type</label>
                        <div class="uk-form-controls">
                          <select id="addCollectionType" name="addCollectionType" formControlName="addCollectionType" class="uk-select">
                            <option value="" disabled>Please select ...</option>
                            <option value="Internal">Internal</option>
                            <option value="Joint">Joint</option>
                          </select>
                        </div>
                      </div>
                    </div>
                    <div class="uk-width-1-2@s">
                      <div class="uk-margin">
                        <label class="uk-form-label" for="addCollectionLocation">Location</label>
                        <div class="uk-form-controls">
                          <ng2-completer id="addCollectionLocation" name="addCollectionLocation" formControlName="addCollectionLocation" [datasource]="variables.locations" [minSearchLength]="0" [textNoResults]="null"></ng2-completer>
                          <div class="uk-text-danger uk-text-small"
                               *ngIf="addCollectionForm.get('addCollectionLocation').hasError('maxlength')"
                          >
                            {{ getCharactersLimitError('addCollectionLocation') }}
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="uk-width-1-1">
                      <div class="uk-margin">
                        <label class="uk-form-label">Related artworks</label>
                        <!--<form appNoSubmitEnter id="searchArtwork" [formGroup]="searchArtworkForm">
                          <div class="uk-margin">
                            <div class="uk-form-controls uk-flex">
                              <input id="searchArtworkTerm" name="searchArtworkTerm" formControlName="searchArtworkTerm" class="uk-input uk-margin-small-right" type="search" placeholder="Search artworks..." [ngClass]="{' uk-form-danger': searchArtworkForm.get('searchArtworkTerm').touched && searchArtworkForm.get('searchArtworkTerm').hasError('invalid') }" autocomplete="off">
                              <button class="uk-button uk-button-default" (click)="searchArtwork()">Search</button>
                            </div>
                          </div>
                        </form>-->
                        <div class="uk-text-right">
                          <button class="uk-button uk-button-default" (click)="openAdvancedSearch()">Add</button>
                        </div>
                        <div class="uk-grid-match uk-child-width-1-2@s uk-child-width-1-3@m uk-child-width-1-4@xl" uk-grid uk-sortable [hidden]="!activeCollection.items.length">
                          <div class="uk-card" *ngFor="let item of activeCollection.items">
                            <div class="uk-card-header uk-flex uk-flex-middle uk-flex-between uk-padding-remove-horizontal">
                              <div class="uk-padding-remove-left">
                                <h3 class="uk-card-title uk-margin-remove-bottom">{{ item.name }}</h3>
                                <div class="uk-text-meta">
                                  <span *ngIf="item.artistName">{{ item.artistName }}</span>
                                  <span *ngIf="item.created">, {{ item.created }}</span>
                                </div>
                              </div>
                              <!--<div>
                                <div class="uk-text-meta">{{ item.availability }}</div>
                                <div class="uk-text-meta" *ngIf="item.price.price">{{ item.price.price }} {{ item.price.currency }}</div>
                              </div>-->
                            </div>
                            <div class="uk-card-body uk-padding-remove uk-background-muted artwork-image" [attr.data-src]="'/api/storagefile/' + activeAccount + '/' + item.coverImage + '/medium'" uk-img *ngIf="item.coverImage"></div>
                            <div class="uk-card-body uk-padding-remove uk-background-muted artwork-image" [attr.data-src]="'/api/storagefile/' + activeAccount + '/' + item.images[0].fileId + '/medium'" uk-img *ngIf="!item.coverImage && item.images && item.images.length"></div>
                            <div class="uk-card-body uk-padding-remove uk-background-muted artwork-image bg-max-size" *ngIf="!item.coverImage && !item.images.length"></div>
                            <div class="uk-card-footer uk-flex uk-flex-middle uk-flex-between uk-padding-remove-horizontal">
                              <!--<div>
                                <div class="uk-text-meta" *ngIf="item.width && item.height">{{ item.width }} * {{ item.height }} cm</div>
                              </div>-->
                              <div class="uk-flex uk-flex-column">
                                <a (click)="removeArtworkFromCollection(item)"  class="uk-button uk-button-text">Remove</a>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="uk-width-1-1 uk-margin-medium-top">
                      <h3 class="uk-margin-remove-bottom">Additional information</h3>
                      <hr class="uk-margin-small-top">
                    </div>
                    <div class="uk-width-1-2">
                      <div class="uk-margin">
                        <div class="uk-flex uk-flex-between uk-text-meta">
                        <label class="uk-form-label" for="addCollectionNotes">Other information</label>
                          <div class="uk-text-small">
                            {{ getCharactersLeft('addCollectionNotes', 2048) }}
                          </div>
                        </div>
                        <div class="uk-form-controls">
                          <textarea id="addCollectionNotes" name="addCollectionNotes" formControlName="addCollectionNotes" rows="5" type="text" placeholder="Other information ..." class="uk-textarea" [ngClass]="{'uk-form-danger': addCollectionForm.get('addCollectionNotes').touched && addCollectionForm.get('addCollectionNotes').errors }"></textarea>
                          <div class="uk-text-danger uk-text-small"
                               *ngIf="addCollectionForm.get('addCollectionNotes').hasError('maxlength')"
                          >
                            {{ getCharactersLimitError('addCollectionNotes') }}
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="uk-width-1-2">
                      <div class="uk-margin">
                        <div class="uk-flex uk-flex-between uk-text-meta">
                        <label class="uk-form-label" for="addCollectionComments">Comments</label>
                          <div class="uk-text-small">
                            {{ getCharactersLeft('addCollectionComments', 2048) }}
                          </div>
                          </div>
                        <div class="uk-form-controls">
                          <textarea id="addCollectionComments" name="addCollectionComments" formControlName="addCollectionComments" rows="5" type="text" placeholder="Comments ..." class="uk-textarea" [ngClass]="{'uk-form-danger': addCollectionForm.get('addCollectionComments').touched && addCollectionForm.get('addCollectionComments').errors }"></textarea>
                          <div class="uk-text-danger uk-text-small"
                               *ngIf="addCollectionForm.get('addCollectionComments').hasError('maxlength')"
                          >
                            {{ getCharactersLimitError('addCollectionComments') }}
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="uk-width-1-2">
                      <div class="uk-margin">
                        <label class="uk-form-label" for="addCollectionTags">Tags</label>
                        <div class="uk-form-controls">
                          <input id="addCollectionTags" name="addCollectionTags" formControlName="addCollectionTags" type="text" placeholder="Tags ..." class="uk-input" [ngClass]="{'uk-form-danger': addCollectionForm.get('addCollectionTags').touched && addCollectionForm.get('addCollectionTags').errors }">
                          <div class="uk-text-danger uk-text-small"
                               *ngIf="addCollectionForm.get('addCollectionTags').hasError('maxlength')"
                          >
                            {{ getCharactersLimitError('addCollectionTags') }}
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="uk-width-1-2">
                      <div class="uk-margin">
                        <label class="uk-form-label" for="addCollectionVisibility">Visibility</label>
                        <div class="uk-form-controls">
                          <label><input id="addCollectionVisibility" name="addCollectionVisibility" formControlName="addCollectionVisibility" class="uk-checkbox" type="checkbox"> Public</label>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="uk-modal-footer">
                    <p class="uk-text-right">
                      <button class="uk-button uk-button-default uk-modal-close uk-margin-small-right" type="button" id="addNewCollectionCancel" (click)="resetForm()">Cancel</button>
                      <button type="submit" [disabled]="!addCollectionForm.valid" (click)="addCollection()" class="uk-button uk-button-primary">Save</button>
                    </p>
                  </div>
                </form>
              </div>
            </div>
            <button class="uk-button uk-button-text uk-margin-right" type="button">Sort <span uk-icon="icon: chevron-down"></span></button>
            <div uk-dropdown="mode: click;">
              <ul class="uk-nav uk-dropdown-nav">
<!--                <li [ngClass]="{'uk-active': activeSort('created') }" (click)="sortCollections('created')"><a>Created</a></li>-->
                <li [ngClass]="{'uk-active': activeSort('name') }" (click)="sortCollections('name')"><a>Name</a></li>
                <li [ngClass]="{'uk-active': activeSort('type') }" (click)="sortCollections('type')"><a>Type</a></li>
                <li [ngClass]="{'uk-active': activeSort('location') }" (click)="sortCollections('location')"><a
                 >Location</a></li>
              </ul>
            </div>
            <button class="uk-button uk-button-text" type="button">Limit {{ limitQuery ? ': ' + limitQuery : null }} <span uk-icon="icon: chevron-down"></span></button>
            <div uk-dropdown="mode: click;">
              <ul class="uk-nav uk-dropdown-nav">
                <li [ngClass]="{'uk-active': activeLimit(10) }" (click)="setLimit(10)"><a>10</a></li>
                <li [ngClass]="{'uk-active': activeLimit(25) }" (click)="setLimit(25)"><a>25</a></li>
                <li [ngClass]="{'uk-active': activeLimit(50) }" (click)="setLimit(50)"><a>50</a></li>
                <li [ngClass]="{'uk-active': activeLimit(100) }" (click)="setLimit(100)"><a>100</a></li>
                <li [ngClass]="{'uk-active': activeLimit(200) }" (click)="setLimit(200)"><a>200</a></li>
              </ul>
            </div>
          </div>
          <div class="uk-height-1-1">
            <table class="uk-table uk-table-middle uk-table-divider">
              <thead>
                <tr>
                  <th class="uk-text-nowrap" [ngClass]="{'active': activeSort('name') }" (click)="sortCollections('name')">Name<span uk-icon="chevron-down" [ngClass]="{'inverse': !sortAscending }"></span></th>
                  <th class="uk-text-nowrap uk-visible@m" [ngClass]="{'active': activeSort('type') }" (click)="sortCollections('type')">Type<span uk-icon="chevron-down" [ngClass]="{'inverse': !sortAscending }"></span></th>
                  <th class="uk-text-nowrap uk-visible@m" [ngClass]="{'active': activeSort('location') }" (click)="sortCollections('location')">Location<span uk-icon="chevron-down" [ngClass]="{'inverse': !sortAscending }"></span></th>
                  <th class="uk-table-shrink"></th>
                </tr>
              </thead>
            <tbody>
              <tr *ngFor="let collection of collections">
                <!--<td>
                  <img class="uk-preserve-width uk-border-circle" src="/api/image/artbuddy-logo-placeholder.svg" *ngIf="!collection.images.length" width="60">
                  <img class="uk-preserve-width" [src]="'/api/storagefile/' + collection.images[0].fileId" width="60" *ngIf="collection.images.length">
                </td>-->
                <!--[ngStyle]="{'background-image': 'url(/api/storagefile/' + activeAccount + '/' + image.fileId + ')'}"-->

                <td (click)="openCollection(collection._id)" class="pointer">{{ collection.name }}</td>
                <td class="uk-visible@m">{{ collection.type }}</td>
                <td class="uk-visible@m">{{ collection.location }}</td>
                <td class="uk-text-right">
                  <div class="uk-button-group">
                    <button (click)="openCollection(collection._id)" class="uk-button uk-button-text uk-margin-right" type="button">Open</button>
                    <div class="uk-inline" class="uk-visible@m">
                      <button class="uk-button uk-button-link" type="button"><span uk-icon="icon: chevron-down"></span></button>
                      <div uk-dropdown="mode: click; boundary: ! .uk-button-group; boundary-align: true;">
                        <ul class="uk-nav uk-dropdown-nav">
                          <li><a (click)="removeCollection(collection._id)">Remove</a></li>
                        </ul>
                      </div>
                    </div>
                  </div>
                </td>
              </tr>
            </tbody>
          </table>
          </div>
          <ul class="uk-pagination uk-flex-center uk-flex-middle uk-margin-medium-top" *ngIf="pages.length > 1">
            <li class="uk-margin-small-right" [ngClass]="{'uk-disabled': (currentPage === 1) }">
              <a (click)="setCurrentPage(currentPage-1)">
                <span class="uk-margin-small-right" uk-pagination-previous></span> Previous
              </a>
            </li>
            <li>
              <select class="uk-select" [ngModel]="currentPage" (change)="setCurrentPageString($event.target.value)">
                <option *ngFor="let page of pages; let index = index; first as isFirst; last as isLast" [value]="index+1">{{ index+1 }}</option>
              </select>
            </li>
            <li class="uk-margin-small-left" [ngClass]="{'uk-disabled': (currentPage === pageCount) }">
              <a (click)="setCurrentPage(currentPage+1)">
                Next <span class="uk-margin-small-left" uk-pagination-next></span>
              </a>
            </li>
          </ul>
        </div>
      </div>
    </div>
  </div>
</div>
<app-advanced-search-modal (setResults)="setArtworkSearchResults($event)" *ngIf="showAdvancedArtworkSearchModalWindow"></app-advanced-search-modal>
