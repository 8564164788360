import {Component, EventEmitter, OnInit, ViewChild} from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { UserService } from '../../services/user.service';
import { ExhibitionService } from '../../services/exhibition.service';
import { AccountService } from '../../services/account.service';
import { AuthService } from '../../services/auth.service';
import { ActiveService } from '../../services/active.service';
import { FileService } from '../../services/file.service';
import { SearchService } from '../../services/search.service';
import {UploadInput, UploadFile, humanizeBytes, UploaderOptions, UploadOutput} from 'ngx-uploader';
import {AngularMyDatePickerDirective, IAngularMyDpOptions, IMyDateModel} from 'angular-mydatepicker';
declare var UIkit: any;
import * as moment from 'moment/moment';

@Component({
  selector: 'app-exhibitions',
  templateUrl: './exhibitions.component.html',
  styleUrls: ['./exhibitions.component.scss']
})
export class ExhibitionsComponent implements OnInit {
  public user: any = {};
  public actorType: string;
  public activeExhibition: any = {};
  private activeUser: string;
  public activeAccount: string;
  public account: Object = {};
  public artists: Array<Object> = [];
  public exhibitions: Array<Object> = [];
  public variables: Array<string> = [];
  public isLoading = true;
  public uploadLoading: Boolean = false;
  public addExhibitionForm: FormGroup;
  public addNewExhibitionModalWindow: any;
  public advancedArtworkSearchModalWindow: any;
  public showAdvancedArtworkSearchModalWindow: boolean = false;
  public sortingKey: string;
  public sortAscending: boolean = false;
  public currentPage: number = 1;
  public limitQuery: number = 10;
  public pageCount: number = 1;
  public pages: Array<any> = [];
  public searchArtworkForm: FormGroup;
  public searchArtworkResults: Array<Object> = [];
  public selectedArtworks: Array<Object> = [];
  public searchResultsFound: boolean = false;
  public myDatePickerOptions: IAngularMyDpOptions = {
    dateFormat: 'dd.mm.yyyy'
  };
  private todaysDate: IMyDateModel;
  private validDate: IMyDateModel;
  @ViewChild('AngularMyDatePickerAddExhibitionStartDate') AngularMyDatePickerAddExhibitionStartDate: AngularMyDatePickerDirective;
  @ViewChild('AngularMyDatePickerAddExhibitionEndDate') AngularMyDatePickerAddExhibitionEndDate: AngularMyDatePickerDirective;
  public options: UploaderOptions;
  public formData: FormData;
  public filesToUpload: UploadFile[];
  public uploadInput: EventEmitter<UploadInput>;
  public humanizeBytes: Function;
  public dragOver: boolean;
  public imageLoadOffset$: any;

  constructor(
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private formBuilder: FormBuilder,
    private auth: AuthService,
    private activeService: ActiveService,
    private userService: UserService,
    private accountService: AccountService,
    private exhibitionService: ExhibitionService,
    private fileService: FileService,
    private searchService: SearchService
  ) {
    this.activatedRoute.data.subscribe(data => {
      this.activeAccount = data.resolvedData.account.id;
      this.user = data.resolvedData.user.id
    });

    this.todaysDate = {
      isRange: false,
      singleDate: {date: {year: new Date().getFullYear(), month: new Date().getMonth() + 1, day: new Date().getDate()}}
    };
    this.validDate = {
      isRange: false,
      singleDate: {date: {year: new Date().getFullYear(), month: new Date().getMonth() + 1, day: new Date().getDate()}}
    };

    this.addExhibitionForm = this.formBuilder.group({
      addExhibitionOwner: new FormControl('', [
        Validators.required,
        Validators.maxLength(100)
      ]),
      addExhibitionName: new FormControl('', [
        Validators.required,
        Validators.maxLength(100)
      ]),
      addExhibitionDescription: new FormControl('', [
        Validators.maxLength(2048)
      ]),
      addExhibitionStartDate: new FormControl('', [
        Validators.maxLength(10)
      ]),
      addExhibitionEndDate: new FormControl('', [
        Validators.maxLength(10)
      ]),
      addExhibitionLocation: new FormControl('', [
        Validators.maxLength(30)
      ]),
      addExhibitionType: new FormControl('', [
        Validators.maxLength(30)
      ]),
      addExhibitionAttachments: new FormControl('', [
        Validators.maxLength(10)
      ]),
      addExhibitionTags: new FormControl('', [
        Validators.maxLength(2048)
      ]),
      addExhibitionNotes: new FormControl('', [
        Validators.maxLength(2048)
      ]),
      addExhibitionComments: new FormControl('', [
        Validators.maxLength(2048)
      ]),
      addExhibitionVisibility: new FormControl('', [
        Validators.maxLength(10)
      ])
    });

    this.searchArtworkForm = this.formBuilder.group({
      searchArtworkTerm: new FormControl('', [
        Validators.required,
        Validators.minLength(3),
        Validators.maxLength(50)
      ])
    });

    this.activeExhibition.items = [];
    this.activeExhibition.installShots = [];
    this.filesToUpload = [];
    this.uploadInput = new EventEmitter<UploadInput>();
    this.humanizeBytes = humanizeBytes;
  }

  ngOnInit() {
    this.isLoading = true;
    this.addExhibitionForm.patchValue({
      addExhibitionOwner: this.activeAccount
    });
    this.getAccountData();

    this.activeService.getActiveData().subscribe(
      data => {
        this.activeUser = data.user.id;
      }
    );
  }

  getAccountData() {
    const getObject = {
      accountId: this.activeAccount,
      requester: 'exhibitions',
      sortingKey: this.sortingKey ? this.sortingKey : '_id',
      sortingOrder: this.sortAscending ? 'asc' : 'desc',
      skipQuery: (this.currentPage - 1) * this.limitQuery,
      limitQuery: this.limitQuery,
      contentTypes: ['account', 'artist', 'exhibition', 'exhibitionCount', 'variable']
    };

    this.accountService.getAccountData(getObject).subscribe(
      data => {
        this.account = data.account;
        this.artists = data.artists;
        this.exhibitions = data.exhibitions;
        this.variables = data.variables ? data.variables : [];

        this.pageCount = Math.ceil(data.exhibitionCount / this.limitQuery);
        if (this.pageCount > 0) {
          this.pages = Array(this.pageCount).fill(null);
        }
      },
      () => {
        UIkit.notification({
          message: 'Error fetching clients',
          status: 'danger',
          pos: 'top-right',
          timeout: 1500
        });
      },
      () => this.isLoading = false
    );
  }

  onUploadOutput(output: UploadOutput): void {
    const properFile = output.file && output.file.name && output.file.name.length > 0;

    if (output.type === 'allAddedToQueue') {
      const event: UploadInput = {
        type: 'uploadAll',
        url: '/api/file',
        method: 'POST',
        includeWebKitFormBoundary: true
      };
      this.uploadInput.emit(event);
    } else if (output.type === 'addedToQueue' && properFile) {
      if (this.filesToUpload.indexOf(output.file) == -1) {
        this.filesToUpload.push(output.file);
      }
    } else if (output.type === 'uploading' && properFile) {
      if (!this.activeExhibition['installShots'].some(image => image.placeholderId === output.file.id)) {
        this.uploadLoading = true;
        this.activeService.increasePendingRequests();
        this.activeExhibition['installShots'].push({ placeholderId: output.file.id });
        const index = this.filesToUpload.findIndex(file => file.id === output.file.id);
        this.filesToUpload[index] = output.file;
      }
    } else if (output.type === 'removed') {
      // remove file from array when removed
      this.filesToUpload = this.filesToUpload.filter((file: UploadFile) => file !== output.file);
    } else if (output.type === 'dragOver') {
      this.dragOver = true;
    } else if (output.type === 'dragOut') {
      this.dragOver = false;
    } else if (output.type === 'drop') {
      this.dragOver = false;
    } else if (output.type === 'removedAll') {
      this.filesToUpload.length = 0;
    } else if (output.type === 'done') {

      const fileObject = {
        itemId: output.file.response,
        fileId: output.file.response,
      };

      this.removeFile(output.file.id);
      const indexToReplace = this.activeExhibition['installShots'].findIndex(file => file.placeholderId === output.file.id);
      this.activeExhibition['installShots'][indexToReplace] = fileObject;
      this.activeService.decreasePendingRequests();
      this.uploadLoading = false;
    }
  }

  startUpload(): void {
    const event: UploadInput = {
      type: 'uploadAll',
      url: '/api/file',
      method: 'POST',
      includeWebKitFormBoundary: true,
    };
    this.uploadInput.emit(event);
  }

  cancelUpload(id: string): void {
    this.uploadInput.emit({ type: 'cancel', id: id });
  }

  removeFile(id: string): void {
    this.uploadInput.emit({ type: 'remove', id: id });
  }

  openAddNewExhibition() {
    this.addNewExhibitionModalWindow = window.document.getElementById('addNewExhibition');
    UIkit.modal(this.addNewExhibitionModalWindow).show();
  }

  addExhibition() {
    const addExhibitionObject = {
      owner: this.addExhibitionForm.value.addExhibitionOwner,
      name: this.addExhibitionForm.value.addExhibitionName,
      description: this.addExhibitionForm.value.addExhibitionDescription,
      items: this.activeExhibition.items,
      installShots: this.activeExhibition['installShots'],
      startDate: this.addExhibitionForm.value.addExhibitionStartDate ? moment(this.addExhibitionForm.value.addExhibitionStartDate.singleDate.jsDate).format() : moment(new Date),
      endDate: this.addExhibitionForm.value.addExhibitionEndDate ? moment(this.addExhibitionForm.value.addExhibitionEndDate.singleDate.jsDate).format() : moment(new Date),
      location: this.addExhibitionForm.value.addExhibitionLocation,
      type: this.addExhibitionForm.value.addExhibitionType,
      /*attachments: this.addExhibitionForm.value.addExhibitionAttachments,*/
      tags: this.addExhibitionForm.value.addExhibitionTags,
      notes: this.addExhibitionForm.value.addExhibitionNotes,
      comments: this.addExhibitionForm.value.addExhibitionComments,
      visibility: this.addExhibitionForm.value.addExhibitionVisibility
    };
    this.exhibitionService.addExhibition(addExhibitionObject).subscribe(
      res => {
        this.ngOnInit();
        UIkit.modal(this.addNewExhibitionModalWindow).hide();
        this.resetForm();
        UIkit.notification({
          message: 'Saved successfully',
          status: 'success',
          pos: 'top-right',
          timeout: 1500
        });
      },
      error => {
        UIkit.notification({
          message: 'Saving failed',
          status: 'danger',
          pos: 'top-right',
          timeout: 1500
        });
      }
    );
  }

  openExhibition(exhibitionId): void {
    this.router.navigateByUrl('/exhibition/' + exhibitionId);
  }

  removeExhibition(exhibitionId): void {
    this.exhibitionService.deleteExhibition(exhibitionId).subscribe(
      () => {
        this.ngOnInit();
        UIkit.notification({
          message: 'Exhibition removed successfully',
          status: 'success',
          pos: 'top-right',
          timeout: 1500
        });
      },
      () => {
        UIkit.notification({
          message: 'Error removing exhibitions',
          status: 'danger',
          pos: 'top-right',
          timeout: 1500
        });
      }
    );
  }

  sortExhibitions(sortingKey: string): void {
    if (sortingKey !== this.sortingKey) {
      this.sortingKey = sortingKey;
    } else {
      this.sortAscending = !this.sortAscending;
    }
    this.getAccountData();
  }

  activeSort(key: string): boolean {
    return this.sortingKey === key;
  }

  resetForm(): void {
    this.addExhibitionForm.reset();
    this.addExhibitionForm.patchValue({
      addExhibitionOwner: this.activeAccount,
      addExhibitionType: ''
    });

    this.activeExhibition.items = [];
    this.searchArtworkResults = [];
    this.emptySearch();
  }

  searchArtwork() {
    /*this.isLoading = true;*/
    if (this.searchArtworkForm.value.searchArtworkTerm.trim().length === 0) {
      return
    }

    const searchObject = {
      searchArtworkAccountId: this.activeAccount,
      searchArtworkUserId: this.activeUser,
      searchArtworkTerm: this.searchArtworkForm.value.searchArtworkTerm
    };
    this.searchService.searchArtwork(searchObject).subscribe(
      res => {
        if (res.length) {
          if (this.searchArtworkResults) {
            this.searchArtworkResults.length = 0;
          }
          res.forEach(artwork => {
            artwork.allResultType = 'artwork';
            this.searchArtworkResults.push(artwork);
          });
          /*this.isLoading = false;*/
          this.searchResultsFound = true;
          /*document.getElementById('search-artwork-results-container').classList.add('active');*/
          Array.prototype.forEach.call(document.getElementsByClassName('results-container'), function (element) {
            element.classList.add('active');
          });



          UIkit.notification({
            message: 'Search matched ' + this.searchArtworkResults.length + ' results',
            status: 'success',
            pos: 'top-right',
            timeout: 1500
          });
        } else {
          if (this.searchArtworkResults) {
            this.searchArtworkResults.length = 0;
          }
          UIkit.notification({
            message: 'No search results',
            status: 'danger',
            pos: 'top-right',
            timeout: 1500
          });
        }
      },
      error => {
        if (this.searchArtworkResults) {
          this.searchArtworkResults.length = 0;
        }
        UIkit.notification({
          message: 'No search results',
          status: 'danger',
          pos: 'top-right',
          timeout: 1500
        });
      }
    );
  }

  emptySearch(): void {
    /*document.getElementById('search-artwork-results-container').classList.remove('active');*/
    /*Array.prototype.forEach.call(document.getElementsByClassName('results-container'), function (element) {
      element.classList.remove('active');
    });*/

    /*let element: HTMLElement = document.getElementsByClassName('uk-navbar-toggle uk-close')[1] as HTMLElement;
    element.click();*/
    setTimeout(() => {
      this.searchResultsFound = false;
      if (this.searchArtworkResults) {
        this.searchArtworkResults.length = 0;
      }
      if (this.selectedArtworks) {
        this.selectedArtworks.length = 0;
      }
      this.searchArtworkForm.patchValue({ searchArtworkTerm: ''});
    }, 300);
  }

  selectExhibitionArtwork(artwork): void {
    this.selectedArtworks.indexOf(artwork) === -1 ?
      this.selectedArtworks.push(artwork) : this.selectedArtworks.splice(this.selectedArtworks.indexOf(artwork), 1);
  }

  addArtworksToExhibition(): void {
    this.selectedArtworks.map((item: any) => {
      const itemFound = this.activeExhibition.items.find((activeExhibitionItem: any) => {
        return activeExhibitionItem._id === item._id;
      });
      if (itemFound === undefined) {
        this.activeExhibition.items.push(item);
      }
      const artistFound = this.artists.find((artist: any) => {
        return artist._id === item.artist;
      });
      if (artistFound === undefined) {
        this.artists.push({
          _id: item.artist,
          name: item.artistName,
          priceMultiplier: item.artistPriceMultiplier,
          status: item.artistStatus
        });
      }
    });

    this.emptySearch();
  }

  removeArtworkFromExhibition(item): void {
    this.activeExhibition.items.splice(this.activeExhibition.items.indexOf(item), 1);
    const artistToSplice = this.artists.find((artist: any) => {
      return artist._id === item.artist;
    });
    this.artists.splice(this.artists.indexOf(artistToSplice), 1);
  }

  openSlideshow($event): void {
    $event.preventDefault();
    UIkit.lightboxPanel({
      /* Ei tarvitse, jos muistaa käyttää $event.preventDefaultia
        autoplay: true,
        autoplayInterval: 5000,
        delayControls: 1000,*/
      items: this.activeExhibition.items.map(item => {
        let itemImage;
        if (item.coverImage) {
          itemImage = '/api/image/' + item.coverImage;
        } else if (item.images[0]) {
          itemImage = '/api/image/' + item.images[0].fileId;
        } else {
          itemImage = '/api/image/artbuddy-logo-placeholder.svg'
        }

        return {
          source: itemImage,
          caption: item.name
        };
      })
    }).show();
  }

  openAdvancedSearch(): void {
    this.showAdvancedArtworkSearchModalWindow = true;
    setTimeout(() => {
      this.advancedArtworkSearchModalWindow = window.document.getElementById('advancedArtworkSearch');
      UIkit.modal(this.advancedArtworkSearchModalWindow).show();
    }, 500);

  }

  setArtworkSearchResults(searchResult: Array<any>) {
    searchResult.forEach(item => this.selectedArtworks.push(item));
    this.addArtworksToExhibition();
  }

  setLimit(limit: number): void {
    if (limit !== this.limitQuery) {
      this.limitQuery = limit;
      /* Set currentPage to 0 to start from the first page */
      this.currentPage = 1;
      this.getAccountData();
    }
  }

  activeLimit(limit: number): boolean {
    return this.limitQuery === limit;
  }

  setCurrentPageString(page: string): void {
    this.setCurrentPage(parseInt(page, 0));
  }

  setCurrentPage(page: number): void {
    if ((page !== this.currentPage) && (page <= this.pageCount)) {
      this.currentPage = page;
      this.getAccountData();
    }
  }

  public validateName(): void {
    this.addExhibitionForm.controls['addExhibitionName'].setErrors({ exists: null });

    const actorObject = {
      owner: this.addExhibitionForm.value.addExhibitionOwner,
      name: this.addExhibitionForm.value.addExhibitionName,
      type: 'exhibition'
    };

    this.exhibitionService.validateExhibitionName(actorObject).subscribe(
      response => {
        if (response.error) {
          this.addExhibitionForm.controls['addExhibitionName'].setErrors({ exists: true });
        } else {
          this.addExhibitionForm.controls['addExhibitionName'].setErrors({ exists: null });
          this.addExhibitionForm.controls['addExhibitionName'].updateValueAndValidity()
        }
      }
    );
  }

  updateDate(fieldName: string, dateObject: any) {
    this.addExhibitionForm.controls[fieldName].patchValue(moment(dateObject.singleDate.jsDate).format());
  }

  getCharactersLimitError(fieldName: string) {
    if (!fieldName || !this.addExhibitionForm.get(fieldName).value ) {
      return ''
    }

    const charactersOverLimit: number = this.addExhibitionForm.get(fieldName).value.length - this.addExhibitionForm.get(fieldName).errors.maxlength.requiredLength;

    return `Field length is ${ charactersOverLimit } characters over limit`;
  }

  getCharactersLeft(fieldName: string, charactersLimit: number) {
    if (!fieldName || !this.addExhibitionForm.get(fieldName).value ) {
      return ''
    }

    const fieldLength: number = this.addExhibitionForm.get(fieldName).value.length;
    const charactersOverLimit: number = fieldLength - charactersLimit;

    if (charactersOverLimit >= 0) { return `${ charactersLimit } / ${ charactersLimit }` }

    return `${ fieldLength } / ${ charactersLimit }`;
  }
}
