<div class="uk-section uk-section-default uk-padding-remove-vertical">

  <app-sidenav contentType="Accounts" [contentItem]="activeAccount.actor.name"></app-sidenav>

  <div class="uk-container-expand uk-height-expand">
    <!--<div class="uk-card uk-card-body uk-margin-remove-top uk-padding-remove-top" *ngIf="!isLoading">-->
    <div class="uk-margin-medium uk-margin-remove-top uk-padding-remove-top" *ngIf="!isLoading">
      <!--          <div class="uk-padding-small">-->
      <div class="uk-section uk-section-default uk-padding-small uk-padding-remove-top uk-margin-bottom">
        <!--<div class="uk-container uk-container-small">-->
        <div class="uk-width-1-1">
          <h3 class="uk-margin-remove-bottom">{{ activeAccount.actor.name }}</h3>
          <hr class="uk-margin-small-top">
        </div>
        <form appNoSubmitEnter [formGroup]="editAccountForm" (ngSubmit)="editAccount()" class="uk-grid-small" uk-grid>
          <input type="hidden" name="editAccountId" name="editAccountId" formControlName="editAccountId"
                 [ngModel]="activeAccount._id">
          <div class="uk-width-1-2@s">
            <div class="uk-margin">
              <label class="uk-form-label" for="editAccountName">Name</label>
              <div class="uk-form-controls">
                <input id="editAccountName" name="editAccountName" formControlName="editAccountName" type="text"
                       [ngModel]="activeAccount.actor.name" placeholder="Name ..." class="uk-input"
                       [ngClass]="{'uk-form-danger': editAccountForm.get('editAccountName').touched && editAccountForm.get('editAccountName').hasError('required') }">
              </div>
            </div>
          </div>
          <div class="uk-width-1-2@s">
            <div class="uk-margin">
              <label class="uk-form-label" for="editAccountVisibleName">Visible name</label>
              <div class="uk-form-controls">
                <input id="editAccountVisibleName" name="editAccountVisibleName"
                       formControlName="editAccountVisibleName" type="text" [ngModel]="activeAccount.actor.visibleName"
                       placeholder="Visible name ..." class="uk-input"
                       [ngClass]="{'uk-form-danger': editAccountForm.get('editAccountVisibleName').touched && editAccountForm.get('editAccountVisibleName').hasError('required') }">
              </div>
            </div>
          </div>
          <!--<div class="uk-width-1-1" *ngIf="activeAccountUsers">
            <div class="uk-margin">
              <label class="uk-form-label" for="editAccountOwner">Owner</label>
              <div class="uk-form-controls">
                <select id="editAccountOwner" name="editAccountOwner" formControlName="editAccountOwner" class="uk-select" [ngModel]="activeAccount.owner" [ngClass]="{'uk-form-danger': editAccountForm.get('editAccountOwner').touched && editAccountForm.get('editAccountOwner').hasError('required'), 'uk-form-danger': editAccountForm.get('editAccountOwner').invalid }">
                    <option disabled>Please select ...</option>
                    <option *ngFor="let user of activeAccountUsers" value="{{ user._id }}">{{ user.email }}</option>
                  </select>
              </div>
            </div>
          </div>-->
          <div class="uk-width-1-2@s">
            <div class="uk-margin">
              <label class="uk-form-label" for="editAccountStreet">Street</label>
              <div class="uk-form-controls">
                <input id="editAccountStreet" name="editAccountStreet" formControlName="editAccountStreet" type="text"
                       [ngModel]="activeAccount.actor.street" placeholder="Street ..." class="uk-input"
                       [ngClass]="{'uk-form-danger': editAccountForm.get('editAccountStreet').touched && editAccountForm.get('editAccountStreet').hasError('required'), 'uk-form-danger': editAccountForm.get('editAccountStreet').invalid }">
              </div>
            </div>
          </div>
          <div class="uk-width-1-2@s">
            <div class="uk-margin">
              <label class="uk-form-label" for="editAccountCity">City</label>
              <div class="uk-form-controls">
                <input id="editAccountCity" name="editAccountCity" formControlName="editAccountCity" type="text"
                       [ngModel]="activeAccount.actor.city" placeholder="City ..." class="uk-input"
                       [ngClass]="{'uk-form-danger': editAccountForm.get('editAccountCity').touched && editAccountForm.get('editAccountCity').hasError('required'), 'uk-form-danger': editAccountForm.get('editAccountCity').invalid }">
              </div>
            </div>
          </div>
          <div class="uk-width-1-2@s">
            <div class="uk-margin">
              <label class="uk-form-label" for="editAccountState">State</label>
              <div class="uk-form-controls">
                <input id="editAccountState" name="editAccountState" formControlName="editAccountState" type="text"
                       [ngModel]="activeAccount.actor.state" placeholder="State ..." class="uk-input"
                       [ngClass]="{'uk-form-danger': editAccountForm.get('editAccountState').touched && editAccountForm.get('editAccountState').hasError('required'), 'uk-form-danger': editAccountForm.get('editAccountState').invalid }">
              </div>
            </div>
          </div>
          <div class="uk-width-1-2@s">
            <div class="uk-margin">
              <label class="uk-form-label" for="editAccountZip">Zip</label>
              <div class="uk-form-controls">
                <input id="editAccountZip" name="editAccountZip" formControlName="editAccountZip" type="text"
                       [ngModel]="activeAccount.actor.zip" placeholder="Zip ..." class="uk-input"
                       [ngClass]="{'uk-form-danger': editAccountForm.get('editAccountZip').touched && editAccountForm.get('editAccountZip').hasError('required'), 'uk-form-danger': editAccountForm.get('editAccountZip').invalid }">
              </div>
            </div>
          </div>
          <div class="uk-width-1-2@s">
            <div class="uk-margin">
              <label class="uk-form-label" for="editAccountEmail">Email</label>
              <div class="uk-form-controls">
                <input id="editAccountEmail" name="editAccountEmail" formControlName="editAccountEmail" type="text"
                       [ngModel]="activeAccount.actor.email" placeholder="Email ..." class="uk-input"
                       [ngClass]="{'uk-form-danger': editAccountForm.get('editAccountEmail').touched && editAccountForm.get('editAccountEmail').hasError('required'), 'uk-form-danger': editAccountForm.get('editAccountEmail').invalid }">
              </div>
            </div>
          </div>
          <div class="uk-width-1-2@s">
            <div class="uk-margin">
              <label class="uk-form-label" for="editAccountPhone">Phone</label>
              <div class="uk-form-controls">
                <input id="editAccountPhone" name="editAccountPhone" formControlName="editAccountPhone" type="text"
                       [ngModel]="activeAccount.actor.phone" placeholder="Phone ..." class="uk-input"
                       [ngClass]="{'uk-form-danger': editAccountForm.get('editAccountPhone').touched && editAccountForm.get('editAccountPhone').hasError('required'), 'uk-form-danger': editAccountForm.get('editAccountPhone').invalid }">
              </div>
            </div>
          </div>
          <div class="uk-width-1-2@s">
            <div class="uk-margin">
              <label class="uk-form-label" for="editAccountCurrency">Currency</label>
              <div class="uk-form-controls">
                <input id="editAccountCurrency" name="editAccountCurrency" formControlName="editAccountCurrency"
                       type="text" [ngModel]="activeAccount.tradingCurrency" placeholder="Currency ..." class="uk-input"
                       [ngClass]="{'uk-form-danger': editAccountForm.get('editAccountCurrency').touched && editAccountForm.get('editAccountCurrency').hasError('required'), 'uk-form-danger': editAccountForm.get('editAccountCurrency').invalid }">
              </div>
            </div>
          </div>
          <div class="uk-width-1-2@s">
            <div class="uk-margin">
              <label class="uk-form-label" for="editAccountVAT">VAT</label>
              <div class="uk-form-controls">
                <input id="editAccountVAT" name="editAccountVAT" formControlName="editAccountVAT" type="number"
                       [ngModel]="activeAccount.vat" placeholder="VAT ..." class="uk-input"
                       [ngClass]="{'uk-form-danger': editAccountForm.get('editAccountVAT').touched && editAccountForm.get('editAccountVAT').hasError('required'), 'uk-form-danger': editAccountForm.get('editAccountVAT').invalid }">
              </div>
            </div>
          </div>
          <div class="uk-width-1-2@s">
            <div class="uk-margin">
              <label class="uk-form-label" for="editAccountType">Type</label>
              <div class="uk-form-controls">
                <select id="editAccountType" name="editAccountType" formControlName="editAccountType"
                        [ngModel]="activeAccount.type" class="uk-select">
                  <option value="" disabled>Please select ...</option>
                  <option value="gallery">Gallery</option>
                  <option value="artcollector">Art collector</option>
                  <option value="artist">Artist</option>
                </select>
              </div>
            </div>
          </div>
          <div class="uk-width-1-2@s" *ngIf="user.role == 'admin'">
            <div class="uk-margin">
              <label class="uk-form-label" for="editAccountPricing">Pricing</label>
              <div class="uk-form-controls">
                <select id="editAccountPricing" name="editAccountPricing" formControlName="editAccountPricing"
                        [ngModel]="activeAccount.pricing" class="uk-select">
                  <option value="" disabled>Please select ...</option>
                  <option value="trial">Trial</option>
                  <option value="standard">Standard</option>
                  <option value="professional">Professional</option>
                </select>
              </div>
            </div>
          </div>
          <div class="uk-width-1-2@s">
            <div class="uk-margin">
              <label class="uk-form-label" for="editAccountTimezone">Timezone</label>
              <div class="uk-form-controls">
                <input id="editAccountTimezone" name="editAccountTimezone" formControlName="editAccountTimezone"
                       type="text" [ngModel]="activeAccount.timezone" placeholder="Timezone ..." class="uk-input"
                       [ngClass]="{'uk-form-danger': editAccountForm.get('editAccountTimezone').touched && editAccountForm.get('editAccountTimezone').hasError('required'), 'uk-form-danger': editAccountForm.get('editAccountTimezone').invalid }">
              </div>
            </div>
          </div>
          <div class="uk-width-1-2@s">
            <div class="uk-margin">
              <label class="uk-form-label" for="editAccountMeasurementUnit">Measurement unit</label>
              <div class="uk-form-controls">
                <select id="editAccountMeasurementUnit" name="editAccountMeasurementUnit"
                        formControlName="editAccountMeasurementUnit" [ngModel]="activeAccount.measurementUnit"
                        class="uk-select">
                  <option value="" disabled>Please select ...</option>
                  <option value="mm">Millimeter (mm)</option>
                  <option value="cm">Centimeter (cm)</option>
                  <option value="m">Meter (m)</option>
                  <option value="in">Inch (in)</option>
                  <option value="ft">Foot (ft)</option>
                  <option value="yd">Yard (yd)</option>
                </select>
              </div>
            </div>
          </div>
          <div class="uk-width-1-2@s">
            <div class="uk-margin">
              <label class="uk-form-label" for="editAccountPDFFont">PDF font</label>
              <div class="uk-form-controls">
                <select id="editAccountPDFFont" name="editAccountPDFFont" formControlName="editAccountPDFFont"
                        [ngModel]="activeAccount.PDFFont" class="uk-select">
                  <option value="" disabled>Please select ...</option>
                  <option value="Inter">Inter</option>
                  <option value="Roboto">Roboto</option>
                  <option value="Montserrat">Montserrat</option>
                  <option value="Open Sans">Open Sans</option>
                  <option value="Oswald">Oswald</option>
                </select>
              </div>
            </div>
          </div>
          <div class="uk-width-1-2@s">
            <div class="uk-margin">
              <label class="uk-form-label" for="editAccountNotes">Notes</label>
              <div class="uk-form-controls">
                <textarea id="editAccountNotes" name="editAccountNotes" formControlName="editAccountNotes" rows="5"
                          [ngModel]="activeAccount.actor.notes" placeholder="Notes ..." class="uk-textarea"
                          [ngClass]="{'uk-form-danger': editAccountForm.get('editAccountNotes').touched && editAccountForm.get('editAccountNotes').hasError('required'), 'uk-form-danger': editAccountForm.get('editAccountNotes').invalid }"></textarea>
              </div>
            </div>
          </div>
          <div class="uk-width-1-1@s">
            <label class="uk-form-label">Account logo</label>
          </div>
          <div class="uk-width-expand@s uk-text-right">
            <button class="uk-button uk-button-default uk-margin-small-right" type="button" (click)="openAddArtworkImage()">Manage logo</button>
          </div>
          <div class="uk-width-1-1">
            <div class="uk-position-relative uk-visible-toggle" uk-slider>
              <ul class="uk-slider-items uk-child-width-1-2@s uk-child-width-1-3@m uk-grid">
                <li class="uk-text-center" *ngFor="let image of activeAccount.images; let index = index">
                  <div class="uk-inline-clip uk-transition-toggle uk-width-1-1" tabindex="index">
                    <div class="uk-background-contain uk-background-muted uk-height-medium uk-panel uk-flex uk-flex-center uk-flex-middle" [attr.data-src]="'/api/storagefile/' + activeAccount._id + '/' + image.fileId + '/medium'" uk-img>
                    </div>
                    <div class="uk-transition-slide-bottom uk-position-bottom uk-overlay uk-overlay-default">
                      <button type="button" (click)="removeImage(image)" class="uk-button uk-button-text uk-margin-left">Remove</button>
                      <button type="button" (click)="openImage(image)" class="uk-button uk-button-text uk-margin-left">Download</button>
                    </div>
                  </div>
                </li>
              </ul>
            </div>
          </div>
          <div id="addArtworkImages" class="uk-modal-container" uk-modal="bg-close: false; esc-close: false;">
            <div class="uk-modal-dialog">
              <div class="uk-modal-header">
                <h2 class="uk-modal-title">Manage account logo</h2>
              </div>
              <div class="uk-modal-body">
                <div class="uk-width-1-1">
                  <div class="uk-position-relative uk-visible-toggle" uk-slider>
                    <ul class="uk-slider-items uk-child-width-1-2@s uk-child-width-1-3@m uk-grid">
                      <li class="uk-text-center" *ngFor="let image of activeAccount.images; let index = index">
                        <div class="uk-inline-clip uk-transition-toggle uk-width-1-1" tabindex="index">
                          <div class="uk-background-contain uk-height-medium uk-panel uk-flex uk-flex-center uk-flex-middle" [attr.data-src]="'/api/storagefile/' + activeAccount._id + '/' + image.fileId + '/medium'" uk-img>
                          </div>
                          <div class="uk-transition-slide-bottom uk-position-bottom uk-overlay uk-overlay-default">
                            <button type="button" (click)="removeImage(image)" class="uk-button uk-button-text uk-margin-left">Remove</button>
                            <button type="button" (click)="openImage(image)" class="uk-button uk-button-text uk-margin-left">Download</button>
                          </div>
                        </div>
                      </li>
                    </ul>
                  </div>
                </div>
                <div class="uk-width-1-1">
                  <div class="uk-margin">
                    <label class="uk-form-label">Manage account logo</label>
                    <p *ngIf="!activeAccount.images.length">No account logo</p>
                    <div class="uk-form-controls">
                      <div class="drop-container" ngFileDrop [options]="options" (uploadOutput)="onUploadOutput($event)" (click)="imageFile.click()" [uploadInput]="uploadInput">
                        <div class="js-upload uk-placeholder uk-text-center" [ngClass]="{ 'is-drop-over': dragOver }">
                          <span class="uk-text-middle">Add image by dropping it here or open file dialog by clicking here.<br>Allowed filetypes include JPG, PNG and GIF.<br>Maximum allowed file size is 5 Mb.</span>
                        </div>
                        <input #imageFile type="file" ngFileSelect [options]="{ concurrency: 1, allowedContentTypes: ['image/jpeg', 'image/jpg', 'image/png', 'image/gif'], maxFileSize: 5000000 }" [uploadInput]="uploadInput" (uploadOutput)="onUploadOutput($event)" multiple class="file-input">
                      </div>
                    </div>
<!--                    <progress id="js-progressbar" class="uk-progress" value="0" max="100" hidden></progress>-->
<!--                    <div class="table-container" *ngIf="filesToUpload.length">-->
<!--                      <table class="uk-table uk-table-middle uk-margin-top">-->
<!--                        <caption>Files to upload</caption>-->
<!--                        <thead>-->
<!--                        <tr>-->
<!--                          <th class="uk-table-shrink uk-text-capitalize">Image</th>-->
<!--                          <th class="uk-width-expand uk-text-capitalize">Name</th>-->
<!--                          <th class="uk-width-expand uk-text-capitalize">Size</th>-->
<!--                          <th class="uk-table-shrink uk-text-capitalize"></th>-->
<!--                        </tr>-->
<!--                        </thead>-->
<!--                        <tbody>-->
<!--                        <tr *ngFor="let file of filesToUpload; let i = index;">-->
<!--                          <td class="uk-background-muted"><img class="uk-preserve-width uk-border-circle" src="/api/image/artbuddy-logo-placeholder.svg" width="60" uk-img></td>-->
<!--                          <td>{{ file.name }}</td>-->
<!--                          <td class="uk-text-nowrap">{{ file.size | bytes: 2 }}</td>-->
<!--                          <td><button (click)="removeFile(file.id)" class="uk-button uk-button-default" type="button">Delete</button></td>-->
<!--                        </tr>-->
<!--                        </tbody>-->
<!--                      </table>-->
<!--                      <button type="button" (click)="startUpload()" class="uk-button uk-button-primary">Start upload</button>-->
<!--                    </div>-->
                  </div>
                </div>
              </div>
              <div class="uk-modal-footer">
                <p class="uk-text-right">
                  <button type="button" class="uk-button uk-button-primary uk-modal-close">Close</button>
                </p>
              </div>
            </div>
          </div>
          <div class="uk-width-1-1@s">
            <div class="uk-margin">
              <label class="uk-form-label" for="editAccountAttachments">Attachments</label>
              <div class="uk-form-controls uk-margin-small">
                <dl class="uk-description-list" *ngIf="activeAccount.actor.attachments.length">
                  <div class="uk-flex uk-flex-middle" *ngFor="let attachment of activeAccount.actor.attachments">
                    <div class="uk-padding-small">
                      <img class="contenttype" width="40" height="40"
                           src="/api/storagefile/{{ activeAccount._id }}/{{ attachment.fileId }}"
                           *ngIf="['image/png', 'image/jpg', 'image/jpeg', 'image/heic', 'image/gif', 'image/svg+xml'].includes(attachment.contentType)"/>
                      <img class="contenttype" width="40" height="40" src="/api/file/5a5de6b1229e86403d67db11"
                           *ngIf="['application/msword', 'application/vnd.openxmlformats-officedocument.wordprocessingml.document'].includes(attachment.contentType)"/>
                      <img class="contenttype" width="40" height="40" src="/api/file/5a5de6b1229e86403d67db10"
                           *ngIf="['application/octet-stream', 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'].includes(attachment.contentType)"/>
                      <img class="contenttype" width="40" height="40" src="/api/file/5a5de6b1229e86403d67db12"
                           *ngIf="['application/pdf'].includes(attachment.contentType)"/>
                      <img class="contenttype" width="40" height="40" src="/api/file/5a5de7bc229e86403d67db1b"
                           *ngIf="['text/plain'].includes(attachment.contentType)"/>
                      <img class="contenttype" width="40" height="40" src="/api/file/5a5de6b1229e86403d67db0f"
                           *ngIf="!(['image/png', 'image/jpg', 'image/jpeg', 'image/heic', 'image/gif', 'image/svg+xml', 'application/msword', 'application/vnd.openxmlformats-officedocument.wordprocessingml.document', 'application/octet-stream', 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet', 'application/pdf', 'text/plain'].includes(attachment.contentType))"/>
                    </div>
                    <div class="uk-width-expand">{{ attachment.name }}</div>
                    <div class="uk-button-group">
                      <button (click)="openAttachment(attachment)" class="uk-button uk-button-default" type="button">
                        Open
                      </button>
                      <div class="uk-inline uk-text-right">
                        <button class="uk-button uk-button-default" type="button"><span
                          uk-icon="icon:  chevron-down"></span></button>
                        <div uk-dropdown="mode: click; boundary: ! .uk-button-group; boundary-align: true;">
                          <ul class="uk-nav uk-dropdown-nav">
                            <li><a (click)="removeAttachment(attachment)">Remove</a></li>
                          </ul>
                        </div>
                      </div>
                    </div>
                  </div>
                </dl>
                <p *ngIf="!activeAccount.actor.attachments.length">No related attachments</p>
              </div>
            </div>
          </div>
          <div class="uk-width-1-1@s">
            <div class="uk-margin">
              <label class="uk-form-label" for="editAccountAttachments">Add new attachments</label>
              <div class="uk-form-controls">
                <div class="drop-container" ngFileDrop [options]="options" (uploadOutput)="onAttachmentsUploadOutput($event)"
                     (click)="attachmentFile.click()" [uploadInput]="uploadInput">
                  <div class="js-upload uk-placeholder uk-text-center" [ngClass]="{ 'is-drop-over': dragOver }">
                    <span
                      class="uk-text-middle">Add files by dropping them here or open file dialog by clicking here.<br>Maximum allowed file size is 5 Mb.</span>
                  </div>
                  <input #attachmentFile type="file" ngFileSelect [options]="{ concurrency: 1, maxFileSize: 5000000 }"
                         [uploadInput]="uploadInput"
                         (uploadOutput)="onAttachmentsUploadOutput($event)" multiple class="file-input">
                </div>
              </div>
<!--              <progress id="js-progressbar" class="uk-progress" value="0" max="100" hidden></progress>-->
<!--              <div class="table-container" *ngIf="filesToUpload.length">-->
<!--                <table class="uk-table uk-table-middle uk-margin-top">-->
<!--                  <caption>Files to upload</caption>-->
<!--                  <thead>-->
<!--                  <tr>-->
<!--                    <th class="uk-table-shrink uk-text-capitalize">Image</th>-->
<!--                    <th class="uk-width-expand uk-text-capitalize">Name</th>-->
<!--                    <th class="uk-width-expand uk-text-capitalize">Size</th>-->
<!--                    <th class="uk-table-shrink uk-text-capitalize"></th>-->
<!--                  </tr>-->
<!--                  </thead>-->
<!--                  <tbody>-->
<!--                  <tr *ngFor="let file of filesToUpload; let i = index;">-->
<!--                    <td><img class="uk-preserve-width uk-border-circle"-->
<!--                             src="/api/image/artbuddy-logo-placeholder.svg" width="60" uk-img></td>-->
<!--                    <td>{{ file.name }}</td>-->
<!--                    <td class="uk-text-nowrap">{{ file.size | bytes: 2 }}</td>-->
<!--                    <td>-->
<!--                      <button (click)="removeFile(file.id)" class="uk-button uk-button-default" type="button">Remove-->
<!--                      </button>-->
<!--                    </td>-->
<!--                  </tr>-->
<!--                  </tbody>-->
<!--                </table>-->
<!--                <button type="button" (click)="startUpload()" class="uk-button uk-button-primary">Start upload</button>-->
<!--              </div>-->
            </div>
          </div>
          <div class="uk-width-1-1 uk-margin-medium-top">
            <hr class="uk-margin-small-top">
          </div>
          <div class="uk-width-1-1@s">
            <div class="uk-margin">
              <div class="uk-text-right">
                <button type="submit" class="uk-button uk-button-primary" [disabled]="!editAccountForm.valid">Save
                </button>
              </div>
            </div>
          </div>
        </form>
        <!--</div>-->
      </div>
      <div class="uk-section uk-section-default uk-padding-small uk-margin-bottom">
        <!--<div class="uk-container uk-container-small">-->
        <div class="uk-width-1-1 uk-margin-medium-top">
          <h3 class="uk-margin-remove-bottom">Manage account users and roles</h3>
          <hr class="uk-margin-small-top">
        </div>
        <p>You can transfer the account's ownership to any member associated with the account. The account owner has
          full access to its' details and options.</p>
        <div class="uk-width-1-1 uk-text-right">
          <button class="uk-button uk-button-default" type="button" (click)="openAddNew()">Add new</button>
          <div id="addnew" uk-modal="bg-close: false; esc-close: false;">
            <div class="uk-modal-dialog">
              <form appNoSubmitEnter [formGroup]="registerForm" (ngSubmit)="register()" autocomplete="off"
                    class="uk-form-stacked">
                <input type="hidden" name="registerFormAccount" name="registerFormAccount"
                       formControlName="registerFormAccount" [ngModel]="activeAccount._id">
                <div class="uk-modal-header">
                  <h2 class="uk-modal-title">Add new user</h2>
                </div>
                <div class="uk-modal-body">
                  <div class="uk-width-1-1@s">
                    <div class="uk-margin">
                      <label class="uk-form-label" for="registerFormEmail">Email</label>
                      <div class="uk-form-controls">
                        <input id="registerFormEmail" name="registerFormEmail" formControlName="registerFormEmail"
                               type="text" placeholder="Email ..." class="uk-input"
                               [ngClass]="{'uk-form-danger': registerForm.get('registerFormEmail').touched && registerForm.get('registerFormEmail').hasError('required'), 'uk-form-danger': registerForm.get('registerFormEmail').invalid }">
                      </div>
                    </div>
                  </div>
                  <div class="uk-width-1-1@s">
                    <div class="uk-margin">
                      <label class="uk-form-label" for="registerFormExistingUser">Existing user</label>
                      <div class="uk-form-controls">
                        <label><input id="registerFormExistingUser" name="registerFormExistingUser"
                                      formControlName="registerFormExistingUser" class="uk-checkbox" type="checkbox">
                          Tick this box if user already exists. Otherwise a new user will be created with given email
                          address.</label>
                      </div>
                    </div>
                  </div>
                  <div class="uk-width-1-1@s" *ngIf="!registerForm.get('registerFormExistingUser').value">
                    <div class="uk-margin">
                      <label class="uk-form-label" for="editAccountEmail">Password</label>
                      <div class="uk-form-controls">
                        <input id="registerFormPassword" name="registerFormPassword"
                               formControlName="registerFormPassword" type="password" placeholder="Password ..."
                               class="uk-input"
                               [ngClass]="{'uk-form-danger': registerForm.get('registerFormPassword').touched && registerForm.get('registerFormPassword').hasError('required'), 'uk-form-danger': registerForm.get('registerFormPassword').invalid }">
                      </div>
                    </div>
                  </div>
                  <div class="uk-width-1-1@s">
                    <div class="uk-margin">
                      <label class="uk-form-label" for="editAccountEmail">Role</label>
                      <!--<div *ngFor="let accountUser of activeAccountUsers">
                        <div *ngIf="accountUser._id === user._id">
                          <div *ngFor="let userAccount of account">
                            {{ userAccount }}
                          </div>
                        </div>
                      </div>-->
                      <div class="uk-form-controls">
                        <select id="role" name="role" formControlName="registerFormRole" class="uk-select">
                          <option value="" disabled>Please select ...</option>
                          <option value="admin" *ngIf="['admin'].includes(user.role)">Admin</option>
                          <option value="owner" *ngIf="['admin', 'owner'].includes(user.role)">Owner</option>
                          <option value="employee" *ngIf="['admin', 'owner', 'employee'].includes(user.role)">Employee
                          </option>
                        </select>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="uk-modal-footer">
                  <p class="uk-text-right">
                    <button class="uk-button uk-button-default uk-modal-close uk-margin-small-right" type="button"
                            id="addnewcancel" (click)="resetRegisterUserForm()">Cancel
                    </button>
                    <button type="submit" [disabled]="!registerForm.valid" class="uk-button uk-button-primary">Save
                    </button>
                  </p>
                </div>
              </form>
            </div>
          </div>
          <div class="uk-width-1-1@s">
            <div class="uk-margin">
              <table class="uk-table uk-table-middle uk-table-divider">
                <thead>
                <tr>
                  <!--<th class="uk-table-shrink"></th>-->
                  <!--<th class="uk-width-expand uk-text-nowrap">Name</th>-->
                  <th class="uk-table-shrink uk-text-nowrap">Email</th>
                  <th class="uk-table-shrink uk-text-nowrap">Role</th>
                  <th class="uk-table-shrink"></th>
                </tr>
                </thead>
                <tbody>
                <tr *ngFor="let accountUser of activeAccountUsers">
                  <!--<td class="uk-text-left"><img class="uk-preserve-width uk-border-circle"
                                                src="/api/image/artbuddy-logo-placeholder.svg" width="60" uk-img>
                  </td>-->
                  <!--<td>{{ accountUser.name }}</td>-->
                  <td class="uk-text-left">{{ accountUser.email }}</td>
                  <td class="uk-text-left">{{ accountUser.role | ucfirst }}</td>
                  <td>
                    <button class="uk-button uk-button-default uk-margin-small-right" type="button"
                            (click)="openEditUser(accountUser)"
                            *ngIf="['admin', 'owner'].includes(user.role)">Edit
                    </button>
                    <button class="uk-button uk-button-default" type="button" (click)="removeUser(accountUser)"
                            *ngIf="['admin', 'owner'].includes(user.role)">Remove
                    </button>
                  </td>
                </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
        <!--</div>-->

        <div id="edituser" uk-modal="bg-close: false; esc-close: false;">
          <div class="uk-modal-dialog">
            <form appNoSubmitEnter [formGroup]="editUserForm" (ngSubmit)="editUser()" class="uk-form-stacked">
              <input type="hidden" name="editUserFormAccount" name="editUserFormAccount"
                     formControlName="editUserFormAccount" [ngModel]="activeAccount._id">
              <div class="uk-modal-header">
                <h2 class="uk-modal-title">Edit user</h2>
              </div>
              <div class="uk-modal-body">
                <div class="uk-width-1-1@s">
                  <div class="uk-margin">
                    <label class="uk-form-label" for="editAccountEmail">Email</label>
                    <div class="uk-form-controls">
                      <input id="editUserFormEmail" name="editUserFormEmail" formControlName="editUserFormEmail"
                             type="text" placeholder="Email ..." class="uk-input uk-disabled"
                             [ngClass]="{'uk-form-danger': editUserForm.get('editUserFormEmail').touched && editUserForm.get('editUserFormEmail').hasError('required'), 'uk-form-danger': editUserForm.get('editUserFormEmail').invalid }">
                    </div>
                  </div>
                </div>
                <!--<div class="uk-width-1-1@s">
                  <div class="uk-margin">
                    <label class="uk-form-label" for="editAccountEmail">Password</label>
                    <div class="uk-form-controls">
                      <input id="editUserFormPassword" name="editUserFormPassword"
                             formControlName="editUserFormPassword" type="password" placeholder="Password ..."
                             class="uk-input"
                             [ngClass]="{'uk-form-danger': editUserForm.get('editUserFormPassword').touched && editUserForm.get('editUserFormPassword').hasError('required'), 'uk-form-danger': editUserForm.get('editUserFormPassword').invalid, 'uk-form-blank': editUserForm.get('editUserFormPassword').valid, 'uk-form-blank': editUserForm.get('editUserFormPassword').untouched }">
                    </div>
                  </div>
                </div>-->
                <div class="uk-width-1-1@s">
                  <div class="uk-margin">
                    <label class="uk-form-label" for="editAccountEmail">Role</label>
                    <div class="uk-form-controls">
                      <select id="editUserFormRole" name="editUserFormRole" formControlName="editUserFormRole"
                              class="uk-select">
                        <option value="" disabled>Please select ...</option>
                        <option value="admin" *ngIf="['admin'].includes(user.role)">Admin</option>
                        <option value="owner" *ngIf="['admin', 'owner'].includes(user.role)">Owner</option>
                        <option value="employee" *ngIf="['admin', 'owner', 'employee'].includes(user.role)">Employee
                        </option>
                      </select>
                    </div>
                  </div>
                </div>
              </div>
              <div class="uk-modal-footer">
                <p class="uk-text-right">
                  <button class="uk-button uk-button-default uk-modal-close uk-margin-small-right" type="button"
                          id="editUsercancel" (click)="resetEditUserForm()">Cancel
                  </button>
                  <button type="submit" [disabled]="!editUserForm.valid" class="uk-button uk-button-primary">Save
                  </button>
                </p>
              </div>
            </form>
          </div>
        </div>

        <!-- Client groups -->

        <div class="uk-width-1-1 uk-margin-medium-top">
          <h3 class="uk-margin-remove-bottom">Client groups</h3>
          <hr class="uk-margin-small-top">
        </div>
        <!--<p>You can transfer the account's ownership to any member associated with the account. The account owner has
          full access to its' details and options.</p>-->
        <div class="uk-width-1-1 uk-text-right">
          <button class="uk-button uk-button-default" type="button" (click)="openAddClientGroup()">Add new</button>
          <div id="addClientGroup" uk-modal="bg-close: false; esc-close: false;">
            <div class="uk-modal-dialog">
              <form appNoSubmitEnter [formGroup]="addClientGroupForm" (ngSubmit)="addClientGroup()" autocomplete="off"
                    class="uk-form-stacked">
                <input type="hidden" name="addClientGroupFormAccount" name="addClientGroupFormAccount"
                       formControlName="addClientGroupFormAccount" [ngModel]="activeAccount._id">
                <div class="uk-modal-header">
                  <h2 class="uk-modal-title">Add new client group</h2>
                </div>
                <div class="uk-modal-body">
                  <div class="uk-width-1-1@s">
                    <div class="uk-margin">
                      <label class="uk-form-label" for="addClientGroupFormTitle">Title</label>
                      <div class="uk-form-controls">
                        <input id="addClientGroupFormTitle" name="addClientGroupFormTitle"
                               formControlName="addClientGroupFormTitle"
                               type="text" placeholder="Title ..." class="uk-input"
                               [ngClass]="{'uk-form-danger': addClientGroupForm.get('addClientGroupFormTitle').touched && addClientGroupForm.get('addClientGroupFormTitle').hasError('required'), 'uk-form-danger': addClientGroupForm.get('addClientGroupFormTitle').invalid, 'uk-form-blank': addClientGroupForm.get('addClientGroupFormTitle').valid }">
                      </div>
                    </div>
                  </div>
                  <div class="uk-width-1-1@s">
                    <div class="uk-margin">
                      <label class="uk-form-label" for="addClientGroupFormDescription">Description</label>
                      <div class="uk-form-controls">
                        <input id="addClientGroupFormDescription" name="addClientGroupFormDescription"
                               formControlName="addClientGroupFormDescription" type="text" placeholder="Description ..."
                               class="uk-input"
                               [ngClass]="{'uk-form-danger': addClientGroupForm.get('addClientGroupFormDescription').touched && addClientGroupForm.get('addClientGroupFormDescription').hasError('required'), 'uk-form-danger': addClientGroupForm.get('addClientGroupFormDescription').invalid}">
                      </div>
                    </div>
                  </div>
                </div>
                <div class="uk-modal-footer">
                  <p class="uk-text-right">
                    <button class="uk-button uk-button-default uk-modal-close uk-margin-small-right" type="button"
                            id="addClientGroupCancel" (click)="resetClientGroupForm()">Cancel
                    </button>
                    <button type="submit" [disabled]="!addClientGroupForm.valid" class="uk-button uk-button-primary">Add
                    </button>
                  </p>
                </div>
              </form>
            </div>
          </div>
          <div class="uk-width-1-1@s">
            <div class="uk-margin">
              <div *ngIf="activeAccount.clientGroupTitles.length; else noClientGroupTitles">
                <table class="uk-table uk-table-middle uk-table-divider">
                  <thead>
                  <tr>
                    <!--<th class="uk-table-shrink"></th>-->
                    <th class="uk-table-shrink uk-text-nowrap">Title</th>
                    <th class="uk-table-shrink uk-text-nowrap">Description</th>
                    <th class="uk-table-shrink"></th>
                  </tr>
                  </thead>
                  <tbody>
                  <tr *ngFor="let clientGroup of activeAccount.clientGroupTitles">
                    <!--<td class="uk-text-left"><img class="uk-preserve-width uk-border-circle"
                                                  src="/api/image/artbuddy-logo-placeholder.svg" width="60" uk-img>
                    </td>-->
                    <!--<td>{{ accountUser.name }}</td>-->
                    <td class="uk-text-left">{{ clientGroup.title }}</td>
                    <td class="uk-text-left">{{ clientGroup.description | ucfirst }}</td>
                    <td>
                      <button class="uk-button uk-button-default uk-margin-small-right" type="button"
                              (click)="openEditClientGroup(clientGroup)"
                              *ngIf="['admin', 'owner'].includes(user.role)">Edit
                      </button>
                      <button class="uk-button uk-button-default" type="button" (click)="removeClientGroup(clientGroup)"
                              *ngIf="['admin', 'owner'].includes(user.role)">Remove
                      </button>
                    </td>
                  </tr>
                  </tbody>
                </table>
              </div>
              <ng-template #noClientGroupTitles>
                <p class="uk-text-left">No client groups added</p>
              </ng-template>
            </div>
          </div>
        </div>

        <div id="editClientGroup" uk-modal="bg-close: false; esc-close: false;">
          <div class="uk-modal-dialog">
            <form appNoSubmitEnter [formGroup]="editClientGroupForm" (ngSubmit)="editClientGroup()"
                  class="uk-form-stacked">
              <input type="hidden" name="editClientGroupFormAccount" name="editClientGroupFormAccount"
                     formControlName="editClientGroupFormAccount" [ngModel]="activeAccount._id">
              <div class="uk-modal-header">
                <h2 class="uk-modal-title">Edit client group</h2>
              </div>
              <div class="uk-modal-body">
                <div class="uk-width-1-1@s">
                  <div class="uk-margin">
                    <label class="uk-form-label" for="editClientGroupFormTitle">Title</label>
                    <div class="uk-form-controls">
                      <input id="editClientGroupFormTitle" name="editClientGroupFormTitle"
                             formControlName="editClientGroupFormTitle"
                             type="text" placeholder="Title ..." class="uk-input"
                             [ngClass]="{'uk-form-danger': editClientGroupForm.get('editClientGroupFormTitle').touched && editClientGroupForm.get('editClientGroupFormTitle').hasError('required'), 'uk-form-danger': editClientGroupForm.get('editClientGroupFormTitle').invalid }">
                    </div>
                  </div>
                </div>
                <div class="uk-width-1-1@s">
                  <div class="uk-margin">
                    <label class="uk-form-label" for="editClientGroupFormDescription">Description</label>
                    <div class="uk-form-controls">
                      <input id="editClientGroupFormDescription" name="editClientGroupFormDescription"
                             formControlName="editClientGroupFormDescription" type="text" placeholder="Description ..."
                             class="uk-input"
                             [ngClass]="{'uk-form-danger': editClientGroupForm.get('editClientGroupFormDescription').touched && editClientGroupForm.get('editClientGroupFormDescription').hasError('required'), 'uk-form-danger': editClientGroupForm.get('editClientGroupFormDescription').invalid }">
                    </div>
                  </div>
                </div>
              </div>
              <div class="uk-modal-footer">
                <p class="uk-text-right">
                  <button class="uk-button uk-button-default uk-modal-close uk-margin-small-right" type="button"
                          id="editClientGroupcancel" (click)="resetClientGroupForm()">Cancel
                  </button>
                  <button type="submit" [disabled]="!editClientGroupForm.valid" class="uk-button uk-button-primary">Save
                  </button>
                </p>
              </div>
            </form>
          </div>
        </div>

        <!-- Contact methods -->

        <div class="uk-width-1-1 uk-margin-medium-top">
          <h3 class="uk-margin-remove-bottom">Contact methods</h3>
          <hr class="uk-margin-small-top">
        </div>
        <div class="uk-width-1-1 uk-text-right">
          <button class="uk-button uk-button-default" type="button" (click)="openAddContactMethod()">Add new</button>
          <div id="addContactMethod" uk-modal="bg-close: false; esc-close: false;">
            <div class="uk-modal-dialog">
              <form appNoSubmitEnter [formGroup]="addContactMethodForm" (ngSubmit)="addContactMethod()" autocomplete="off"
                    class="uk-form-stacked">
                <input type="hidden" name="addContactMethodFormAccount" name="addContactMethodFormAccount"
                       formControlName="addContactMethodFormAccount" [ngModel]="activeAccount._id">
                <div class="uk-modal-header">
                  <h2 class="uk-modal-title">Add new contact method</h2>
                </div>
                <div class="uk-modal-body">
                  <div class="uk-width-1-1@s">
                    <div class="uk-margin">
                      <label class="uk-form-label" for="addContactMethodFormTitle">Title</label>
                      <div class="uk-form-controls">
                        <input id="addContactMethodFormTitle" name="addContactMethodFormTitle"
                               formControlName="addContactMethodFormTitle"
                               type="text" placeholder="Title ..." class="uk-input"
                               [ngClass]="{'uk-form-danger': addContactMethodForm.get('addContactMethodFormTitle').touched && addContactMethodForm.get('addContactMethodFormTitle').hasError('required'), 'uk-form-danger': addContactMethodForm.get('addContactMethodFormTitle').invalid, 'uk-form-blank': addContactMethodForm.get('addContactMethodFormTitle').valid }">
                      </div>
                    </div>
                  </div>
                  <div class="uk-width-1-1@s">
                    <div class="uk-margin">
                      <label class="uk-form-label" for="addContactMethodFormDescription">Description</label>
                      <div class="uk-form-controls">
                        <input id="addContactMethodFormDescription" name="addContactMethodFormDescription"
                               formControlName="addContactMethodFormDescription" type="text" placeholder="Description ..."
                               class="uk-input"
                               [ngClass]="{'uk-form-danger': addContactMethodForm.get('addContactMethodFormDescription').touched && addContactMethodForm.get('addContactMethodFormDescription').hasError('required'), 'uk-form-danger': addContactMethodForm.get('addContactMethodFormDescription').invalid}">
                      </div>
                    </div>
                  </div>
                </div>
                <div class="uk-modal-footer">
                  <p class="uk-text-right">
                    <button class="uk-button uk-button-default uk-modal-close uk-margin-small-right" type="button"
                            id="addContactMethodCancel" (click)="resetContactMethodForm()">Cancel
                    </button>
                    <button type="submit" [disabled]="!addContactMethodForm.valid" class="uk-button uk-button-primary">Add
                    </button>
                  </p>
                </div>
              </form>
            </div>
          </div>
          <div class="uk-width-1-1@s">
            <div class="uk-margin">
              <div *ngIf="activeAccount.contactMethodTitles?.length; else noContactMethodTitles">
                <table class="uk-table uk-table-middle uk-table-divider">
                  <thead>
                  <tr>
                    <!--<th class="uk-table-shrink"></th>-->
                    <th class="uk-table-shrink uk-text-nowrap">Title</th>
                    <th class="uk-table-shrink uk-text-nowrap">Description</th>
                    <th class="uk-table-shrink"></th>
                  </tr>
                  </thead>
                  <tbody>
                  <tr *ngFor="let contactMethod of activeAccount.contactMethodTitles">
                    <!--<td class="uk-text-left"><img class="uk-preserve-width uk-border-circle"
                                                  src="/api/image/artbuddy-logo-placeholder.svg" width="60" uk-img>
                    </td>-->
                    <!--<td>{{ accountUser.name }}</td>-->
                    <td class="uk-text-left">{{ contactMethod.title }}</td>
                    <td class="uk-text-left">{{ contactMethod.description | ucfirst }}</td>
                    <td>
                      <button class="uk-button uk-button-default uk-margin-small-right" type="button"
                              (click)="openEditContactMethod(contactMethod)"
                              *ngIf="['admin', 'owner'].includes(user.role)">Edit
                      </button>
                      <button class="uk-button uk-button-default" type="button" (click)="removeContactMethod(contactMethod)"
                              *ngIf="['admin', 'owner'].includes(user.role)">Remove
                      </button>
                    </td>
                  </tr>
                  </tbody>
                </table>
              </div>
              <ng-template #noContactMethodTitles>
                <p class="uk-text-left">No client groups added</p>
              </ng-template>
            </div>
          </div>
        </div>

        <div id="editContactMethod" uk-modal="bg-close: false; esc-close: false;">
          <div class="uk-modal-dialog">
            <form appNoSubmitEnter [formGroup]="editContactMethodForm" (ngSubmit)="editContactMethod()"
                  class="uk-form-stacked">
              <input type="hidden" name="editContactMethodFormAccount" name="editContactMethodFormAccount"
                     formControlName="editContactMethodFormAccount" [ngModel]="activeAccount._id">
              <div class="uk-modal-header">
                <h2 class="uk-modal-title">Edit client group</h2>
              </div>
              <div class="uk-modal-body">
                <div class="uk-width-1-1@s">
                  <div class="uk-margin">
                    <label class="uk-form-label" for="editContactMethodFormTitle">Title</label>
                    <div class="uk-form-controls">
                      <input id="editContactMethodFormTitle" name="editContactMethodFormTitle"
                             formControlName="editContactMethodFormTitle"
                             type="text" placeholder="Title ..." class="uk-input"
                             [ngClass]="{'uk-form-danger': editContactMethodForm.get('editContactMethodFormTitle').touched && editContactMethodForm.get('editContactMethodFormTitle').hasError('required'), 'uk-form-danger': editContactMethodForm.get('editContactMethodFormTitle').invalid }">
                    </div>
                  </div>
                </div>
                <div class="uk-width-1-1@s">
                  <div class="uk-margin">
                    <label class="uk-form-label" for="editContactMethodFormDescription">Description</label>
                    <div class="uk-form-controls">
                      <input id="editContactMethodFormDescription" name="editContactMethodFormDescription"
                             formControlName="editContactMethodFormDescription" type="text" placeholder="Description ..."
                             class="uk-input"
                             [ngClass]="{'uk-form-danger': editContactMethodForm.get('editContactMethodFormDescription').touched && editContactMethodForm.get('editContactMethodFormDescription').hasError('required'), 'uk-form-danger': editContactMethodForm.get('editContactMethodFormDescription').invalid }">
                    </div>
                  </div>
                </div>
              </div>
              <div class="uk-modal-footer">
                <p class="uk-text-right">
                  <button class="uk-button uk-button-default uk-modal-close uk-margin-small-right" type="button"
                          id="editContactMethodcancel" (click)="resetContactMethodForm()">Cancel
                  </button>
                  <button type="submit" [disabled]="!editContactMethodForm.valid" class="uk-button uk-button-primary">Save
                  </button>
                </p>
              </div>
            </form>
          </div>
        </div>
          <div class="uk-width-1-1 uk-margin-medium-top">
            <h3 class="uk-margin-remove-bottom">Manage VAT rates</h3>
            <hr class="uk-margin-small-top">
          </div>
          <!--<p>You can transfer the account's ownership to any member associated with the account. The account owner has
            full access to its' details and options.</p>-->
          <div class="uk-width-1-1 uk-text-right">
            <button class="uk-button uk-button-default" type="button" (click)="openAddVAT()">Add new</button>
            <div id="addVAT" uk-modal="bg-close: false; esc-close: false;">
              <div class="uk-modal-dialog">
                <form appNoSubmitEnter [formGroup]="addVATForm" (ngSubmit)="addVAT()" autocomplete="off"
                      class="uk-form-stacked">
                  <input type="hidden" name="addVATFormAccount" name="addVATFormAccount"
                         formControlName="addVATFormAccount" [ngModel]="activeAccount._id">
                  <div class="uk-modal-header">
                    <h2 class="uk-modal-title">Add new VAT rate</h2>
                  </div>
                  <div class="uk-modal-body">
                    <div class="uk-width-1-1@s">
                      <div class="uk-margin">
                        <label class="uk-form-label" for="addVATFormPercentage">Percentage</label>
                        <div class="uk-form-controls">
                          <input id="addVATFormPercentage" name="addVATFormPercentage"
                                 formControlName="addVATFormPercentage"
                                 type="number" placeholder="Percentage ..." class="uk-input"
                                 [ngClass]="{'uk-form-danger': addVATForm.get('addVATFormPercentage').touched && addVATForm.get('addVATFormPercentage').hasError('required'), 'uk-form-danger': addVATForm.get('addVATFormPercentage').invalid }">
                        </div>
                      </div>
                    </div>
                    <div class="uk-width-1-1@s">
                      <div class="uk-margin">
                        <label class="uk-form-label" for="addVATFormDescription">Description</label>
                        <div class="uk-form-controls">
                          <input id="addVATFormDescription" name="addVATFormDescription"
                                 formControlName="addVATFormDescription" type="text" placeholder="Description ..."
                                 class="uk-input"
                                 [ngClass]="{'uk-form-danger': addVATForm.get('addVATFormDescription').touched && addVATForm.get('addVATFormDescription').hasError('required'), 'uk-form-danger': addVATForm.get('addVATFormDescription').invalid }">
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="uk-modal-footer">
                    <p class="uk-text-right">
                      <button class="uk-button uk-button-default uk-modal-close uk-margin-small-right" type="button"
                              id="addVATCancel" (click)="resetVATForm()">Cancel
                      </button>
                      <button type="submit" [disabled]="!addVATForm.valid" class="uk-button uk-button-primary">Add
                      </button>
                    </p>
                  </div>
                </form>
              </div>
            </div>
            <div class="uk-width-1-1@s">
              <div class="uk-margin">
                <div *ngIf="activeAccount.VATRates.length; else noVATRates">
                  <table class="uk-table uk-table-middle uk-table-divider">
                    <thead>
                    <tr>
                      <!--<th class="uk-table-shrink"></th>-->
                      <th class="uk-table-shrink uk-text-nowrap">Percentage</th>
                      <th class="uk-table-shrink uk-text-nowrap">Description</th>
                      <th class="uk-table-shrink"></th>
                    </tr>
                    </thead>
                    <tbody>
                    <tr *ngFor="let VAT of activeAccount.VATRates">
                      <!--<td class="uk-text-left"><img class="uk-preserve-width uk-border-circle"
                                                    src="/api/image/artbuddy-logo-placeholder.svg" width="60" uk-img>
                      </td>-->
                      <!--<td>{{ accountUser.name }}</td>-->
                      <td class="uk-text-left">{{ VAT.percentage }}</td>
                      <td class="uk-text-left">{{ VAT.description | ucfirst }}</td>
                      <td>
                        <button class="uk-button uk-button-default uk-margin-small-right" type="button"
                                (click)="openEditVAT(VAT)"
                                *ngIf="['admin', 'owner'].includes(user.role)">Edit
                        </button>
                        <button class="uk-button uk-button-default" type="button" (click)="removeVAT(VAT)"
                                *ngIf="['admin', 'owner'].includes(user.role)">Remove
                        </button>
                      </td>
                    </tr>
                    </tbody>
                  </table>
                </div>
                <ng-template #noVATRates>
                  <p class="uk-text-left">No VAT rates added</p>
                </ng-template>
              </div>
            </div>
          </div>

          <div id="editVAT" uk-modal="bg-close: false; esc-close: false;">
            <div class="uk-modal-dialog">
              <form appNoSubmitEnter [formGroup]="editVATForm" (ngSubmit)="editVAT()" class="uk-form-stacked">
                <input type="hidden" name="editVATFormAccount" name="editVATFormAccount"
                       formControlName="editVATFormAccount" [ngModel]="activeAccount._id">
                <div class="uk-modal-header">
                  <h2 class="uk-modal-title">Edit VAT</h2>
                </div>
                <div class="uk-modal-body">
                  <div class="uk-width-1-1@s">
                    <div class="uk-margin">
                      <label class="uk-form-label" for="editVATFormPercentage">Percentage</label>
                      <div class="uk-form-controls">
                        <input id="editVATFormPercentage" name="editVATFormPercentage"
                               formControlName="editVATFormPercentage"
                               type="number" placeholder="Percentage ..." class="uk-input"
                               [ngClass]="{'uk-form-danger': editVATForm.get('editVATFormPercentage').touched && editVATForm.get('editVATFormPercentage').hasError('required'), 'uk-form-danger': editVATForm.get('editVATFormPercentage').invalid }">
                      </div>
                    </div>
                  </div>
                  <div class="uk-width-1-1@s">
                    <div class="uk-margin">
                      <label class="uk-form-label" for="editVATFormDescription">Description</label>
                      <div class="uk-form-controls">
                        <input id="editVATFormDescription" name="editVATFormDescription"
                               formControlName="editVATFormDescription" type="text" placeholder="Description ..."
                               class="uk-input"
                               [ngClass]="{'uk-form-danger': editVATForm.get('editVATFormDescription').touched && editVATForm.get('editVATFormDescription').hasError('required'), 'uk-form-danger': editVATForm.get('editVATFormDescription').invalid }">
                      </div>
                    </div>
                  </div>
                </div>
                <div class="uk-modal-footer">
                  <p class="uk-text-right">
                    <button class="uk-button uk-button-default uk-modal-close uk-margin-small-right" type="button"
                            id="editVATcancel" (click)="resetVATForm()">Cancel
                    </button>
                    <button type="submit" [disabled]="!editVATForm.valid" class="uk-button uk-button-primary">Save
                    </button>
                  </p>
                </div>
              </form>
            </div>
          </div>

          <div class="uk-width-1-1 uk-margin-medium-top">
            <h3 class="uk-margin-remove-bottom">Languages</h3>
            <hr class="uk-margin-small-top">
          </div>
          <!--<p>You can transfer the account's ownership to any member associated with the account. The account owner has
            full access to its' details and options.</p>-->
          <div class="uk-width-1-1 uk-text-right">
            <button class="uk-button uk-button-default" type="button" (click)="openAddLanguage()">Add new</button>
            <div id="addLanguage" uk-modal="bg-close: false; esc-close: false;">
              <div class="uk-modal-dialog">
                <form appNoSubmitEnter [formGroup]="addLanguageForm" (ngSubmit)="addLanguage()" autocomplete="off"
                      class="uk-form-stacked">
                  <input type="hidden" name="addLanguageFormAccount" name="addLanguageFormAccount"
                         formControlName="addLanguageFormAccount" [ngModel]="activeAccount._id">
                  <div class="uk-modal-header">
                    <h2 class="uk-modal-title">Add new language</h2>
                  </div>
                  <div class="uk-modal-body">
                    <div class="uk-width-1-1@s">
                      <div class="uk-margin">
                        <label class="uk-form-label" for="addLanguageFormTitle">Title</label>
                        <div class="uk-form-controls">
                          <input id="addLanguageFormTitle" name="addLanguageFormTitle"
                                 formControlName="addLanguageFormTitle"
                                 type="text" placeholder="Title ..." class="uk-input"
                                 [ngClass]="{'uk-form-danger': addLanguageForm.get('addLanguageFormTitle').touched && addLanguageForm.get('addLanguageFormTitle').hasError('required'), 'uk-form-danger': addLanguageForm.get('addLanguageFormTitle').invalid }">
                        </div>
                      </div>
                    </div>
                    <div class="uk-width-1-1@s">
                      <div class="uk-margin">
                        <label class="uk-form-label" for="addLanguageFormDescription">Description</label>
                        <div class="uk-form-controls">
                          <input id="addLanguageFormDescription" name="addLanguageFormDescription"
                                 formControlName="addLanguageFormDescription" type="text" placeholder="Description ..."
                                 class="uk-input"
                                 [ngClass]="{'uk-form-danger': addLanguageForm.get('addLanguageFormDescription').touched && addLanguageForm.get('addLanguageFormDescription').hasError('required'), 'uk-form-danger': addLanguageForm.get('addLanguageFormDescription').invalid }">
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="uk-modal-footer">
                    <p class="uk-text-right">
                      <button class="uk-button uk-button-default uk-modal-close uk-margin-small-right" type="button"
                              id="addLanguageCancel" (click)="resetLanguageForm()">Cancel
                      </button>
                      <button type="submit" [disabled]="!addLanguageForm.valid" class="uk-button uk-button-primary">Add
                      </button>
                    </p>
                  </div>
                </form>
              </div>
            </div>
            <div class="uk-width-1-1@s">
              <div class="uk-margin">
                <div *ngIf="activeAccount.languageTitles.length; else noLanguageTitles">
                  <table class="uk-table uk-table-middle uk-table-divider">
                    <thead>
                    <tr>
                      <!--<th class="uk-table-shrink"></th>-->
                      <th class="uk-table-shrink uk-text-nowrap">Title</th>
                      <th class="uk-table-shrink uk-text-nowrap">Description</th>
                      <th class="uk-table-shrink"></th>
                    </tr>
                    </thead>
                    <tbody>
                    <tr *ngFor="let language of activeAccount.languageTitles">
                      <!--<td class="uk-text-left"><img class="uk-preserve-width uk-border-circle"
                                                    src="/api/image/artbuddy-logo-placeholder.svg" width="60" uk-img>
                      </td>-->
                      <!--<td>{{ accountUser.name }}</td>-->
                      <td class="uk-text-left">{{ language.title }}</td>
                      <td class="uk-text-left">{{ language.description | ucfirst }}</td>
                      <td>
                        <button class="uk-button uk-button-default uk-margin-small-right" type="button"
                                (click)="openEditLanguage(language)"
                                *ngIf="['admin', 'owner'].includes(user.role)">Edit
                        </button>
                        <button class="uk-button uk-button-default" type="button" (click)="removeLanguage(language)"
                                *ngIf="['admin', 'owner'].includes(user.role)">Remove
                        </button>
                      </td>
                    </tr>
                    </tbody>
                  </table>
                </div>
                <ng-template #noLanguageTitles>
                  <p class="uk-text-left">No languages added</p>
                </ng-template>
              </div>
            </div>
          </div>

          <div id="editLanguage" uk-modal="bg-close: false; esc-close: false;">
            <div class="uk-modal-dialog">
              <form appNoSubmitEnter [formGroup]="editLanguageForm" (ngSubmit)="editLanguage()" class="uk-form-stacked">
                <input type="hidden" name="editLanguageFormAccount" name="editLanguageFormAccount"
                       formControlName="editLanguageFormAccount" [ngModel]="activeAccount._id">
                <div class="uk-modal-header">
                  <h2 class="uk-modal-title">Edit client group</h2>
                </div>
                <div class="uk-modal-body">
                  <div class="uk-width-1-1@s">
                    <div class="uk-margin">
                      <label class="uk-form-label" for="editLanguageFormTitle">Title</label>
                      <div class="uk-form-controls">
                        <input id="editLanguageFormTitle" name="editLanguageFormTitle"
                               formControlName="editLanguageFormTitle"
                               type="text" placeholder="Title ..." class="uk-input"
                               [ngClass]="{'uk-form-danger': editLanguageForm.get('editLanguageFormTitle').touched && editLanguageForm.get('editLanguageFormTitle').hasError('required'), 'uk-form-danger': editLanguageForm.get('editLanguageFormTitle').invalid }">
                      </div>
                    </div>
                  </div>
                  <div class="uk-width-1-1@s">
                    <div class="uk-margin">
                      <label class="uk-form-label" for="editLanguageFormDescription">Description</label>
                      <div class="uk-form-controls">
                        <input id="editLanguageFormDescription" name="editLanguageFormDescription"
                               formControlName="editLanguageFormDescription" type="text" placeholder="Description ..."
                               class="uk-input"
                               [ngClass]="{'uk-form-danger': editLanguageForm.get('editLanguageFormDescription').touched && editLanguageForm.get('editLanguageFormDescription').hasError('required'), 'uk-form-danger': editLanguageForm.get('editLanguageFormDescription').invalid }">
                      </div>
                    </div>
                  </div>
                </div>
                <div class="uk-modal-footer">
                  <p class="uk-text-right">
                    <button class="uk-button uk-button-default uk-modal-close uk-margin-small-right" type="button"
                            id="editLanguagecancel" (click)="resetLanguageForm()">Cancel
                    </button>
                    <button type="submit" [disabled]="!editLanguageForm.valid" class="uk-button uk-button-primary">Save
                    </button>
                  </p>
                </div>
              </form>
            </div>
          </div>

          <div class="uk-section uk-section-default uk-padding-small uk-margin-bottom"
               *ngIf="['admin', 'owner'].includes(user.role) && activeAccount.status">
            <div class="uk-width-1-1 uk-margin-medium-top">
              <h3 class="uk-margin-remove-bottom">Artwork code</h3>
              <hr class="uk-margin-small-top">
            </div>
            <p>A default artwork code value is populated for new artworks by combining the artwork code basis, a dash
              and
              the
              starting number, eg. AccountABC-123. The starting number is automatically incremented by one when creating
              a
              new
              artwork.</p>
            <form appNoSubmitEnter [formGroup]="editArtworkCodeForm" (ngSubmit)="updateArtworkCode()"
                  class="uk-form-stacked uk-grid-small" uk-grid>
              <input type="hidden" name="editArtworkCodeFormAccount" name="editArtworkCodeFormAccount"
                     formControlName="editArtworkCodeFormAccount" [ngModel]="activeAccount._id">
              <div class="uk-width-1-2@s">
                <div class="uk-margin">
                  <label class="uk-form-label" for="editArtworkCodeFormName">Artwork code basis</label>
                  <div class="uk-form-controls">
                    <input id="editArtworkCodeFormName" name="editArtworkCodeFormName"
                           formControlName="editArtworkCodeFormName" type="text"
                           [ngModel]="activeAccount.artworkCodeName"
                           placeholder="Artwork code basis ..." class="uk-input"
                           [ngClass]="{'uk-form-danger': editArtworkCodeForm.get('editArtworkCodeFormName').touched && editArtworkCodeForm.get('editArtworkCodeFormName').hasError('required'), 'uk-form-danger': editArtworkCodeForm.get('editArtworkCodeFormName').invalid }">
                  </div>
                </div>
              </div>
              <div class="uk-width-1-2@s">
                <div class="uk-margin">
                  <label class="uk-form-label" for="editArtworkCodeFormNumber">Starting number</label>
                  <div class="uk-form-controls">
                    <input id="editArtworkCodeFormNumber" name="editArtworkCodeFormNumber"
                           formControlName="editArtworkCodeFormNumber" type="number"
                           [ngModel]="activeAccount.artworkCodeNumber" placeholder="Number ..." class="uk-input"
                           [ngClass]="{'uk-form-danger': editArtworkCodeForm.get('editArtworkCodeFormNumber').touched && editArtworkCodeForm.get('editArtworkCodeFormNumber').hasError('required'), 'uk-form-danger': editArtworkCodeForm.get('editArtworkCodeFormNumber').invalid }">
                  </div>
                </div>
              </div>
              <div class="uk-width-1-1">
                <div class="uk-margin">
                  <div class="uk-text-right">
                    <button type="submit" [disabled]="!editArtworkCodeForm.valid" class="uk-button uk-button-primary">
                      Save
                    </button>
                  </div>
                </div>
              </div>
            </form>
          </div>

          <div class="uk-section uk-section-default uk-padding-small uk-margin-bottom"
               *ngIf="['admin', 'owner'].includes(user.role) && activeAccount.status">
            <div class="uk-width-1-1 uk-margin-medium-top">
              <h3 class="uk-margin-remove-bottom">Rebuild search index</h3>
              <hr class="uk-margin-small-top">
            </div>
            <div class="uk-width-1-1@s">
              <div class="uk-margin">
                <div class="uk-text-right">
                  <button
                    (click)="indexContents()"
                    [disabled]="isLoadingComponent"
                    class="uk-button uk-button-default minWidth175"
                  >
                    <span *ngIf="!isLoadingComponent">Rebuild index</span>
                    <span *ngIf="isLoadingComponent"><div uk-spinner="ratio:0.7"></div></span>
                  </button>
                </div>
              </div>
            </div>
          </div>

          <div class="uk-section uk-section-default uk-padding-small uk-margin-bottom"
               *ngIf="['admin', 'owner'].includes(user.role) && activeAccount.status">
            <!--<div class="uk-container uk-container-small">-->
            <div class="uk-width-1-1 uk-margin-medium-top">
              <h3 class="uk-margin-remove-bottom">Close account</h3>
              <hr class="uk-margin-small-top">
            </div>
            <p>You can transfer the account's ownership to any member associated with the account. The account owner has
              full
              access to its' details and options.</p>
            <p><span class="uk-label uk-label-warning">Warning</span> Closing an account is irreversible.</p>
            <div class="uk-width-1-1@s">
              <div class="uk-margin">
                <div class="uk-text-right">
                  <button (click)="closeAccount(activeAccount)" class="uk-button uk-button-danger">Close account
                  </button>
                </div>
              </div>
            </div>
            <!--</div>-->
          </div>

          <div class="uk-section uk-section-default uk-padding-small uk-margin-bottom"
               *ngIf="['admin', 'owner'].includes(user.role)">
            <div class="uk-width-1-1 uk-margin-medium-top">
              <h3 class="uk-margin-remove-bottom">Delete account</h3>
              <hr class="uk-margin-small-top">
            </div>
            <p><span class="uk-label uk-label-warning">Warning</span> Deleting an account is irreversible.</p>
            <div class="uk-width-1-1@s">
              <div class="uk-margin">
                <div class="uk-text-right">
                  <button (click)="deleteAccount(activeAccount)" class="uk-button uk-button-danger">Delete account
                  </button>
                </div>
              </div>
            </div>
            <!--</div>-->
          </div>
          <!--</div>-->
        </div>
      </div>
    </div>
