<div class="uk-section uk-section-default uk-padding-remove-top" uk-height-viewport="offset-top: true">

  <app-sidenav contentType="Artworks"></app-sidenav>

  <div class="uk-container-expand uk-height-expand">
    <div uk-grid>
      <div class="uk-width-expand@m">
        <div class="uk-padding-small uk-padding-remove-top" *ngIf="!isLoading">
          <div class="uk-width-1-1 uk-text-right uk-margin-small-bottom uk-padding-remove">
            <app-search-content contentType="artwork" fields="['image', 'name']"></app-search-content>
            <button class="uk-button uk-button-text uk-margin-right" type="button"
                    (click)="openAddNewArtwork()"
                    *ngIf="(['trial'].includes(account.pricing) && items.length < 10) || (['standard'].includes(account.pricing) && items.length < 500) || (['professional'].includes(account.pricing))">
              Add new
            </button>
            <div id="addNewArtwork" class="uk-modal-container" uk-modal="bg-close: false; esc-close: false;">
              <div class="uk-modal-dialog">
                <form appNoSubmitEnter [formGroup]="addArtworkForm" (ngSubmit)="addArtwork()">
                  <input type="hidden" name="addArtworkOwner" formControlName="addArtworkOwner"
                         [ngModel]="activeAccount">
                  <div class="uk-modal-header">
                    <h2 class="uk-modal-title">Add new artwork</h2>
                  </div>
                  <div class="uk-modal-body" uk-grid>
                    <div class="uk-width-1-1">
                      <h3 class="uk-margin-remove-bottom">Basic information</h3>
                      <hr class="uk-margin-small-top">
                    </div>
                    <div class="uk-width-1-2@s uk-width-1-3@m">
                      <div class="uk-margin">
                        <label class="uk-form-label" for="addArtworkName">Name <span class="required">*</span></label>
                        <div class="uk-form-controls">
                          <input id="addArtworkName" name="addArtworkName" formControlName="addArtworkName" type="text"
                                 placeholder="Name ..." class="uk-input"
                                 [ngClass]="{'uk-form-danger': addArtworkForm.get('addArtworkName').touched && (addArtworkForm.get('addArtworkName').errors) }"
                                 (blur)="validateName()">
                          <div
                            *ngIf="addArtworkForm.get('addArtworkName').invalid && (addArtworkForm.get('addArtworkName').dirty || addArtworkForm.get('addArtworkName').touched)"
                            class="uk-text-danger uk-text-small">
                            <div *ngIf="addArtworkForm.get('addArtworkName').hasError('required')">
                              Name is required
                            </div>
                          </div>
                          <span class="uk-text-danger uk-text-small"
                                *ngIf="addArtworkForm.get('addArtworkName').hasError('maxlength')"
                          >
                      {{ getCharactersLimitError('addArtworkName') }}
                    </span>
                          <span *ngIf="artworkNameMightNotBeUnique" class="uk-text-warning uk-text-small">Name might not be unique</span>
                        </div>
                      </div>
                    </div>
                    <div class="uk-width-1-2@s uk-width-1-3@m">
                      <div class="uk-margin">
                        <label class="uk-form-label" for="addArtworkCode">Artwork code</label>
                        <div class="uk-form-controls">
                          <input id="addArtworkCode" name="addArtworkCode" formControlName="addArtworkCode" type="text"
                                 placeholder="Artwork code ..." class="uk-input"
                                 [ngClass]="{'uk-form-danger': addArtworkForm.get('addArtworkCode').touched && (addArtworkForm.get('addArtworkCode').errors) }" (blur)="validateArtworkCode()">
                          <div class="uk-text-danger uk-text-small"
                               *ngIf="addArtworkForm.get('addArtworkCode').hasError('maxlength')"
                          >
                            {{ getCharactersLimitError('addArtworkCode') }}
                          </div>
                          <span *ngIf="artworkArtworkCodeMightNotBeUnique" class="uk-text-warning uk-text-small">Name might not be unique</span>
                        </div>
                      </div>
                    </div>
                    <div class="uk-width-1-2@s uk-width-1-3@m">
                      <div class="uk-margin">
                        <div class="uk-flex uk-flex-between uk-text-meta">
                          <label class="uk-form-label" for="addArtworkDescription">Description</label>
                          <div class="uk-text-small">
                            {{ getCharactersLeft('addArtworkDescription', 2048) }}
                          </div>
                        </div>
                        <div class="uk-form-controls">
                          <textarea id="addArtworkDescription" name="addArtworkDescription"
                                    formControlName="addArtworkDescription" rows="5" placeholder="Description ..."
                                    class="uk-textarea"
                                    [ngClass]="{'uk-form-danger': addArtworkForm.get('addArtworkDescription').touched && (addArtworkForm.get('addArtworkDescription').errors) }"></textarea>
                          <div class="uk-text-danger uk-text-small"
                               *ngIf="addArtworkForm.get('addArtworkDescription').hasError('maxlength')"
                          >
                            {{ getCharactersLimitError('addArtworkDescription') }}
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="uk-width-1-1">
                      <label class="uk-form-label">Artwork images</label>
                    </div>
                    <div class="uk-width-1-1">
                      <div class="uk-position-relative uk-visible-toggle" uk-slider>
                        <ul class="uk-slider-items uk-child-width-1-2@s uk-child-width-1-3@m uk-grid">
                          <li class="uk-text-center" *ngFor="let image of activeArtwork.images; let index = index">
                            <div class="uk-inline-clip uk-transition-toggle uk-width-1-1" tabindex="index"
                                 *ngIf="image.fileId">
                              <div
                                class="uk-background-contain uk-background-muted uk-height-medium uk-panel uk-flex uk-flex-center uk-flex-middle"
                                [attr.data-src]="'/api/storagefile/' + activeAccount + '/' + image.fileId + '/medium'"
                                uk-img>
                              </div>
                              <div class="uk-position-top-right uk-overlay uk-overlay-default uk-padding-small"
                                   *ngIf="image.fileId == activeArtwork.coverImage">Cover image
                              </div>
                              <div class="uk-transition-slide-bottom uk-position-bottom uk-overlay uk-overlay-default"
                                   *ngIf="image.fileId !== activeArtwork.coverImage">
                                <button type="button" (click)="selectCover(image.fileId)"
                                        class="uk-button uk-button-text">Select cover
                                </button>
                              </div>
                            </div>
                          </li>
                        </ul>
                        <a class="uk-position-center-left uk-position-small uk-hidden-hover" uk-slidenav-previous
                           uk-slider-item="previous"></a>
                        <a class="uk-position-center-right uk-position-small uk-hidden-hover" uk-slidenav-next
                           uk-slider-item="next"></a>
                      </div>
                    </div>
                    <div class="uk-width-1-1">
                      <div class="uk-margin">
                        <p *ngIf="!activeArtwork.images.length">No artwork images</p>
                        <div class="uk-form-controls">
                          <div class="drop-container" ngFileDrop
                               [options]="{ concurrency: 1, allowedContentTypes: ['image/jpeg', 'image/jpg', 'image/png', 'image/gif'], maxFileSize: 5000000 }"
                               (uploadOutput)="onUploadOutput($event)" (click)="imageFile.click()"
                               [uploadInput]="uploadInput">
                            <div class="js-upload uk-placeholder uk-text-center"
                                 [ngClass]="{ 'is-drop-over': dragOver }">
                              <span class="uk-text-middle">Add images by dropping them here or open file dialog by clicking here.<br>Allowed filetypes include JPG, PNG and GIF.<br>Maximum allowed file size is 5 Mb.</span>
                            </div>
                            <input #imageFile type="file" ngFileSelect
                                   [options]="{ allowedContentTypes: ['image/jpeg', 'image/jpg', 'image/png', 'image/gif'], maxFileSize: 5000000 }"
                                   [uploadInput]="uploadInput" (uploadOutput)="onUploadOutput($event)" multiple
                                   class="file-input">
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="uk-width-1-2@s uk-width-1-3@m">
                      <div class="uk-margin">
                        <label class="uk-form-label" for="addArtworkArtist">Artist <span
                          class="required">*</span></label>
                        <div class="uk-form-controls">
                          <ng-select [items]="artists"
                                     [virtualScroll]="true"
                                     [addTag]="false"
                                     [selectOnTab]="false"
                                     addTagText="Add new: "
                                     placeholder="Please select ..."
                                     bindLabel="fullName"
                                     id="addArtworkArtist"
                                     formControlName="addArtworkArtist"
                                     class="uk-select"
                                     #addArtworkArtist (change)="addArtworkArtist.blur()">
                          </ng-select>
                        </div>
                      </div>
                    </div>
                    <div class="uk-width-1-2@s uk-width-1-3@m">
                      <div class="uk-margin">
                        <label class="uk-form-label" for="addArtworkType">Artwork type <span
                          class="required">*</span></label>
                        <div class="uk-form-controls">
                          <select id="addArtworkType" name="addArtworkType" formControlName="addArtworkType"
                                  class="uk-select">
                            <option value="" disabled>Please select ...</option>
                            <option value="Painting">Painting</option>
                            <option value="Photography">Photography</option>
                            <option value="Print">Print</option>
                            <option value="Work on paper">Work on paper</option>
                            <option value="Design">Design</option>
                            <option value="Drawing">Drawing</option>
                            <option value="Jewelry">Jewelry</option>
                            <option value="Performance art">Performance art</option>
                            <option value="Sculpture">Sculpture</option>
                            <option value="Video">Video</option>
                            <option value="Installation">Installation</option>
                            <option value="Mixed media">Mixed media</option>
                            <option value="Other">Other</option>
                          </select>
                        </div>
                      </div>
                    </div>
                    <div class="uk-width-1-2@s uk-width-1-3@m">
                      <div class="uk-margin">
                        <label class="uk-form-label" for="addArtworkTechnique">Technique</label>
                        <div class="uk-form-controls">
                          <ng-select [items]="variables.techniques"
                                     [addTag]="true"
                                     [selectOnTab]="false"
                                     addTagText="Add new: "
                                     placeholder="Please select ..."
                                     bindLabel=""
                                     id="addArtworkTechnique"
                                     formControlName="addArtworkTechnique"
                                     class="uk-select"
                                     #addArtworkTechnique (change)="addArtworkTechnique.blur()">
                          </ng-select>
                        </div>
                      </div>
                    </div>
                    <div class="uk-width-1-2@s uk-width-1-3@m"
                         *ngIf="['Sculpture'].includes(addArtworkForm.get('addArtworkType').value)">
                      <div class="uk-margin">
                        <label class="uk-form-label" for="addArtworkMaterial">Material</label>
                        <div class="uk-form-controls">
                          <ng-select [items]="variables.materials"
                                     [addTag]="true"
                                     [selectOnTab]="false"
                                     addTagText="Add new: "
                                     placeholder="Please select ..."
                                     bindLabel=""
                                     id="addArtworkMaterial"
                                     formControlName="addArtworkMaterial"
                                     class="uk-select"
                                     #addArtworkMaterial (change)="addArtworkMaterial.blur()">
                          </ng-select>
                        </div>
                      </div>
                    </div>
                    <div class="uk-width-1-2@s uk-width-1-3@m"
                         *ngIf="['Painting', 'Photography', 'Print', 'Work on paper', 'Design', 'Drawing','Jewelry', 'Performance art', 'Sculpture', 'Mixed media', 'Other'].includes(addArtworkForm.get('addArtworkType').value)">
                      <div class="uk-margin">
                        <label class="uk-form-label" for="addArtworkHeight">Height</label>
                        <div class="uk-form-controls">
                          <input id="addArtworkHeight" name="addArtworkHeight" formControlName="addArtworkHeight"
                                 type="text" placeholder="Height ..." class="uk-input"
                                 [ngClass]="{'uk-form-danger': addArtworkForm.get('addArtworkHeight').touched && (addArtworkForm.get('addArtworkHeight').errors) }">
                          <div class="measurement-unit-container">
                            <span
                              class="uk-badge uk-background-muted uk-text-muted">{{ account.measurementUnit }}</span>
                          </div>
                          <div class="uk-text-danger uk-text-small"
                               *ngIf="addArtworkForm.get('addArtworkHeight').hasError('maxlength')"
                          >
                            {{ getCharactersLimitError('addArtworkHeight') }}
                          </div>
                          <div class="uk-text-danger uk-text-small"
                               *ngIf="addArtworkForm.get('addArtworkHeight').hasError('pattern')"
                          >
                            Field value is incorrect
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="uk-width-1-2@s uk-width-1-3@m"
                         *ngIf="['Painting', 'Photography', 'Print', 'Work on paper', 'Design', 'Drawing','Jewelry', 'Performance art', 'Sculpture', 'Mixed media', 'Other'].includes(addArtworkForm.get('addArtworkType').value)">
                      <div class="uk-margin">
                        <label class="uk-form-label" for="addArtworkWidth">Width</label>
                        <div class="uk-form-controls">
                          <input id="addArtworkWidth" name="addArtworkWidth" formControlName="addArtworkWidth"
                                 type="text" placeholder="Width ..." class="uk-input"
                                 [ngClass]="{'uk-form-danger': addArtworkForm.get('addArtworkWidth').touched && (addArtworkForm.get('addArtworkWidth').errors) }">
                          <div class="measurement-unit-container">
                            <span
                              class="uk-badge uk-background-muted uk-text-muted">{{ account.measurementUnit }}</span>
                          </div>
                          <div class="uk-text-danger uk-text-small"
                               *ngIf="addArtworkForm.get('addArtworkWidth').hasError('maxlength')"
                          >
                            {{ getCharactersLimitError('addArtworkWidth') }}
                          </div>
                          <div class="uk-text-danger uk-text-small"
                               *ngIf="addArtworkForm.get('addArtworkWidth').hasError('pattern')"
                          >
                            Field value is incorrect
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="uk-width-1-2@s uk-width-1-3@m"
                         *ngIf="['Painting', 'Photography', 'Print', 'Work on paper', 'Design', 'Drawing','Jewelry', 'Performance art', 'Sculpture', 'Mixed media', 'Other'].includes(addArtworkForm.get('addArtworkType').value)">
                      <div class="uk-margin">
                        <label class="uk-form-label" for="addArtworkDepth">Depth</label>
                        <div class="uk-form-controls">
                          <input id="addArtworkDepth" name="addArtworkDepth" formControlName="addArtworkDepth"
                                 type="text" placeholder="Depth ..." class="uk-input"
                                 [ngClass]="{'uk-form-danger': addArtworkForm.get('addArtworkDepth').touched && addArtworkForm.get('addArtworkDepth').hasError('required') }">
                          <div class="measurement-unit-container">
                            <span
                              class="uk-badge uk-background-muted uk-text-muted">{{ account.measurementUnit }}</span>
                          </div>
                          <div class="uk-text-danger uk-text-small"
                               *ngIf="addArtworkForm.get('addArtworkDepth').hasError('maxlength')"
                          >
                            {{ getCharactersLimitError('addArtworkDepth') }}
                          </div>
                          <div class="uk-text-danger uk-text-small"
                               *ngIf="addArtworkForm.get('addArtworkDepth').hasError('pattern')"
                          >
                            Field value is incorrect
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="uk-width-1-2@s uk-width-1-3@m"
                         *ngIf="['Sculpture'].includes(addArtworkForm.get('addArtworkType').value)">
                      <div class="uk-margin">
                        <label class="uk-form-label" for="addArtworkWeight">Weight</label>
                        <div class="uk-form-controls">
                          <input id="addArtworkWeight" name="addArtworkWeight" formControlName="addArtworkWeight"
                                 type="text" placeholder="Weight ..." class="uk-input"
                                 [ngClass]="{'uk-form-danger': addArtworkForm.get('addArtworkWeight').touched && addArtworkForm.get('addArtworkWeight').errors }">
                          <div class="measurement-unit-container">
                            <span class="uk-badge uk-background-muted uk-text-muted">g</span>
                          </div>
                          <div class="uk-text-danger uk-text-small"
                               *ngIf="addArtworkForm.get('addArtworkWeight').hasError('maxlength')"
                          >
                            {{ getCharactersLimitError('addArtworkWeight') }}
                          </div>
                          <div class="uk-text-danger uk-text-small"
                               *ngIf="addArtworkForm.get('addArtworkWeight').hasError('pattern')"
                          >
                            Field value is incorrect
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="uk-width-1-2@s uk-width-1-3@m"
                         *ngIf="['Painting', 'Photography', 'Print', 'Work on paper', 'Design', 'Drawing','Jewelry', 'Performance art', 'Mixed media', 'Other'].includes(addArtworkForm.get('addArtworkType').value)">
                      <div class="uk-margin">
                        <label class="uk-form-label" for="addArtworkFrame">Frame</label>
                        <div class="uk-form-controls">
                          <label><input id="addArtworkFrame" name="addArtworkFrame" formControlName="addArtworkFrame"
                                        class="uk-checkbox" type="checkbox"> This artwork has a frame</label>
                        </div>
                      </div>
                    </div>
                    <div class="uk-width-1-2@s uk-width-1-3@m"
                         *ngIf="['Painting', 'Photography', 'Print', 'Work on paper', 'Design', 'Drawing','Jewelry', 'Performance art', 'Mixed media', 'Other'].includes(addArtworkForm.get('addArtworkType').value) && addArtworkForm.get('addArtworkFrame').value">
                      <div class="uk-margin">
                        <label class="uk-form-label" for="addArtworkFrameHeight">Frame height</label>
                        <div class="uk-form-controls">
                          <input id="addArtworkFrameHeight" name="addArtworkFrameHeight"
                                 formControlName="addArtworkFrameHeight" type="text" placeholder="Frame height ..."
                                 class="uk-input"
                                 [ngClass]="{'uk-form-danger': addArtworkForm.get('addArtworkFrameHeight').touched && addArtworkForm.get('addArtworkFrameHeight').errors }">
                          <div class="measurement-unit-container">
                            <span
                              class="uk-badge uk-background-muted uk-text-muted">{{ account.measurementUnit }}</span>
                          </div>
                          <div class="uk-text-danger uk-text-small"
                               *ngIf="addArtworkForm.get('addArtworkFrameHeight').hasError('maxlength')"
                          >
                            {{ getCharactersLimitError('addArtworkFrameHeight') }}
                          </div>
                          <div class="uk-text-danger uk-text-small"
                               *ngIf="addArtworkForm.get('addArtworkFrameHeight').hasError('pattern')"
                          >
                            Field value is incorrect
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="uk-width-1-2@s uk-width-1-3@m"
                         *ngIf="['Painting', 'Photography', 'Print', 'Work on paper', 'Design', 'Drawing','Jewelry', 'Performance art', 'Mixed media', 'Other'].includes(addArtworkForm.get('addArtworkType').value) && addArtworkForm.get('addArtworkFrame').value">
                      <div class="uk-margin">
                        <label class="uk-form-label" for="addArtworkFrameWidth">Frame width</label>
                        <div class="uk-form-controls">
                          <input id="addArtworkFrameWidth" name="addArtworkFrameWidth"
                                 formControlName="addArtworkFrameWidth" type="text" placeholder="Frame width ..."
                                 class="uk-input"
                                 [ngClass]="{'uk-form-danger': addArtworkForm.get('addArtworkFrameWidth').touched && addArtworkForm.get('addArtworkFrameWidth').errors }">
                          <div class="measurement-unit-container">
                            <span
                              class="uk-badge uk-background-muted uk-text-muted">{{ account.measurementUnit }}</span>
                          </div>
                          <div class="uk-text-danger uk-text-small"
                               *ngIf="addArtworkForm.get('addArtworkFrameWidth').hasError('maxlength')"
                          >
                            {{ getCharactersLimitError('addArtworkFrameWidth') }}
                          </div>
                          <div class="uk-text-danger uk-text-small"
                               *ngIf="addArtworkForm.get('addArtworkFrameWidth').hasError('pattern')"
                          >
                            Field value is incorrect
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="uk-width-1-2@s uk-width-1-3@m"
                         *ngIf="['Painting', 'Photography', 'Print', 'Work on paper', 'Design', 'Drawing','Jewelry', 'Performance art', 'Mixed media', 'Other'].includes(addArtworkForm.get('addArtworkType').value) && addArtworkForm.get('addArtworkFrame').value">
                      <div class="uk-margin">
                        <label class="uk-form-label" for="addArtworkFrameDepth">Frame depth</label>
                        <div class="uk-form-controls">
                          <input id="addArtworkFrameDepth" name="addArtworkFrameDepth"
                                 formControlName="addArtworkFrameDepth" type="text" placeholder="Frame depth ..."
                                 class="uk-input"
                                 [ngClass]="{'uk-form-danger': addArtworkForm.get('addArtworkFrameDepth').touched && addArtworkForm.get('addArtworkFrameDepth').errors }">
                          <div class="measurement-unit-container">
                            <span
                              class="uk-badge uk-background-muted uk-text-muted">{{ account.measurementUnit }}</span>
                          </div>
                          <div class="uk-text-danger uk-text-small"
                               *ngIf="addArtworkForm.get('addArtworkFrameDepth').hasError('maxlength')"
                          >
                            {{ getCharactersLimitError('addArtworkFrameDepth') }}
                          </div>
                          <div class="uk-text-danger uk-text-small"
                               *ngIf="addArtworkForm.get('addArtworkFrameDepth').hasError('pattern')"
                          >
                            Field value is incorrect
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="uk-width-1-2@s uk-width-1-3@m"
                         *ngIf="['Video'].includes(addArtworkForm.get('addArtworkType').value)">
                      <div class="uk-margin">
                        <label class="uk-form-label" for="addArtworkDuration">Duration</label>
                        <div class="uk-form-controls">
                          <input id="addArtworkDuration" name="addArtworkDuration" formControlName="addArtworkDuration"
                                 type="text" placeholder="Duration ..." class="uk-input"
                                 [ngClass]="{'uk-form-danger': addArtworkForm.get('addArtworkDuration').touched && addArtworkForm.get('addArtworkDuration').errors}">
                          <div class="measurement-unit-container">
                            <span class="uk-badge uk-background-muted uk-text-muted">mm:ss</span>
                          </div>
                          <div class="uk-text-danger uk-text-small"
                               *ngIf="addArtworkForm.get('addArtworkDuration').hasError('maxlength')"
                          >
                            {{ getCharactersLimitError('addArtworkDuration') }}
                          </div>
                          <div class="uk-text-danger uk-text-small"
                               *ngIf="addArtworkForm.get('addArtworkDuration').hasError('pattern')"
                          >
                            Field value is incorrect
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="uk-width-1-2@s uk-width-1-3@m">
                      <div class="uk-margin">
                        <label class="uk-form-label" for="addArtworkCreated">Creation year</label>
                        <div class="uk-form-controls">
                          <input id="addArtworkCreated" name="addArtworkCreated" formControlName="addArtworkCreated"
                                 type="number" placeholder="Creation year ..." class="uk-input"
                                 [ngClass]="{'uk-form-danger': addArtworkForm.get('addArtworkCreated').touched && addArtworkForm.get('addArtworkCreated').errors}">
                          <div class="uk-text-danger uk-text-small"
                               *ngIf="addArtworkForm.get('addArtworkCreated').hasError('maxlength')"
                          >
                            {{ getCharactersLimitError('addArtworkCreated') }}
                          </div>
                          <div class="uk-text-danger uk-text-small"
                               *ngIf="addArtworkForm.get('addArtworkCreated').hasError('pattern')"
                          >
                            Field value is incorrect
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="uk-width-1-2@s uk-width-1-3@m">
                      <div class="uk-margin">
                        <label class="uk-form-label" for="addArtworkStarted">Creation started year</label>
                        <div class="uk-form-controls">
                          <input id="addArtworkStarted" name="addArtworkStarted" formControlName="addArtworkStarted"
                                 type="number" placeholder="Creation started year ..." class="uk-input"
                                 [ngClass]="{'uk-form-danger': addArtworkForm.get('addArtworkStarted').touched && addArtworkForm.get('addArtworkStarted').errors }">
                          <div class="uk-text-danger uk-text-small"
                               *ngIf="addArtworkForm.get('addArtworkStarted').hasError('maxlength')"
                          >
                            {{ getCharactersLimitError('addArtworkStarted') }}
                          </div>
                          <div class="uk-text-danger uk-text-small"
                               *ngIf="addArtworkForm.get('addArtworkStarted').hasError('pattern')"
                          >
                            Field value is incorrect
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="uk-width-1-2@s uk-width-1-3@m">
                      <div class="uk-margin">
                        <label class="uk-form-label" for="addArtworkSRP">Suggested retail price</label>
                        <div class="uk-form-controls">
                          <input id="addArtworkSRP" name="addArtworkSRP" formControlName="addArtworkSRP" type="text"
                                 placeholder="Suggested retail price ..." class="uk-input"
                                 [ngClass]="{'uk-form-danger': addArtworkForm.get('addArtworkSRP').touched && addArtworkForm.get('addArtworkSRP').errors }">
                          <div class="uk-text-danger uk-text-small"
                               *ngIf="addArtworkForm.get('addArtworkSRP').hasError('maxlength')"
                          >
                            {{ getCharactersLimitError('addArtworkSRP') }}
                          </div>
                          <div class="uk-text-danger uk-text-small"
                               *ngIf="addArtworkForm.get('addArtworkSRP').hasError('pattern')"
                          >
                            Field value is incorrect
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="uk-width-1-2@s uk-width-1-3@m">
                      <div class="uk-margin">
                        <label class="uk-form-label" for="addArtworkPrice">Price</label>
                        <div class="uk-form-controls">
                          <input id="addArtworkPrice" name="addArtworkPrice" formControlName="addArtworkPrice"
                                 type="text" placeholder="Price ..." class="uk-input"
                                 [ngClass]="{'uk-form-danger': addArtworkForm.get('addArtworkPrice').touched && addArtworkForm.get('addArtworkPrice').errors }">
                          <div class="uk-text-danger uk-text-small"
                               *ngIf="addArtworkForm.get('addArtworkPrice').hasError('maxlength')"
                          >
                            {{ getCharactersLimitError('addArtworkPrice') }}
                          </div>
                          <div class="uk-text-danger uk-text-small"
                               *ngIf="addArtworkForm.get('addArtworkPrice').hasError('pattern')"
                          >
                            Field value is incorrect
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="uk-width-1-1 uk-margin-medium-top">
                      <div class="uk-flex uk-flex-between uk-text-meta">
                        <h3 class="uk-margin-remove-bottom">Notes in print</h3>
                        <div class="uk-text-small">
                          {{ getCharactersLeft('addArtworkListNotes', 2048) }}
                        </div>
                      </div>
                      <hr class="uk-margin-small-top">
                    </div>
                    <div class="uk-width-1-2@s uk-width-1-3@m">
                      <div class="uk-margin">
                        <label class="uk-form-label" for="addArtworkListNotes">Notes</label>
                        <div class="uk-form-controls">
                          <textarea id="addArtworkListNotes" name="addArtworkListNotes"
                                    formControlName="addArtworkListNotes" rows="5" type="text"
                                    placeholder="Notes shown on list ..." class="uk-textarea"
                                    [ngClass]="{'uk-form-danger': addArtworkForm.get('addArtworkListNotes').touched && addArtworkForm.get('addArtworkListNotes').errors }"></textarea>
                          <div class="uk-text-danger uk-text-small"
                               *ngIf="addArtworkForm.get('addArtworkListNotes').hasError('maxlength')"
                          >
                            {{ getCharactersLimitError('addArtworkListNotes') }}
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="uk-width-1-1 uk-margin-medium-top">
                      <h3 class="uk-margin-remove-bottom">Additional information</h3>
                      <hr class="uk-margin-small-top">
                    </div>
                    <div class="uk-width-1-2@s uk-width-1-3@m">
                      <div class="uk-margin">
                        <label class="uk-form-label" for="addArtworkAvailability">Availability</label>
                        <div class="uk-form-controls">
                          <ng-select [items]="variables.availabilities"
                                     [addTag]="true"
                                     [selectOnTab]="false"
                                     addTagText="Add new: "
                                     placeholder="Please select ..."
                                     bindLabel=""
                                     id="addArtworkAvailability"
                                     formControlName="addArtworkAvailability"
                                     class="uk-select"
                                     #addArtworkAvailability (change)="addArtworkAvailability.blur()">
                          </ng-select>
                          <div class="uk-text-danger uk-text-small"
                               *ngIf="addArtworkForm.get('addArtworkAvailability').hasError('maxlength')"
                          >
                            {{ getCharactersLimitError('addArtworkAvailability') }}
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="uk-width-1-2@s uk-width-1-3@m">
                      <div class="uk-margin">
                        <label class="uk-form-label" for="addArtworkLogistics">Logistics</label>
                        <div class="uk-form-controls">
                          <ng-select [items]="variables.logistics"
                                     [addTag]="true"
                                     [selectOnTab]="false"
                                     addTagText="Add new: "
                                     placeholder="Please select ..."
                                     bindLabel=""
                                     id="addArtworkLogistics"
                                     formControlName="addArtworkLogistics"
                                     class="uk-select"
                                     #addArtworkLogistics (change)="addArtworkLogistics.blur()">
                          </ng-select>
                          <div class="uk-text-danger uk-text-small"
                               *ngIf="addArtworkForm.get('addArtworkLogistics').hasError('maxlength')"
                          >
                            {{ getCharactersLimitError('addArtworkLogistics') }}
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="uk-width-1-2@s uk-width-1-3@m">
                      <div class="uk-margin">
                        <label class="uk-form-label" for="addArtworkPackaging">Packaging</label>
                        <div class="uk-form-controls">
                          <ng-select [items]="variables.packagings"
                                     [addTag]="true"
                                     [selectOnTab]="false"
                                     addTagText="Add new: "
                                     placeholder="Please select ..."
                                     bindLabel=""
                                     id="addArtworkPackaging"
                                     formControlName="addArtworkPackaging"
                                     class="uk-select"
                                     #addArtworkPackaging (change)="addArtworkPackaging.blur()">
                          </ng-select>
                          <div class="uk-text-danger uk-text-small"
                               *ngIf="addArtworkForm.get('addArtworkPackaging').hasError('maxlength')"
                          >
                            {{ getCharactersLimitError('addArtworkPackaging') }}
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="uk-width-1-2@s uk-width-1-3@m">
                      <div class="uk-margin">
                        <label class="uk-form-label" for="addArtworkLocation">Location</label>
                        <div class="uk-form-controls">
                          <ng-select [items]="variables.locations"
                                     [addTag]="true"
                                     [selectOnTab]="false"
                                     addTagText="Add new: "
                                     placeholder="Please select ..."
                                     bindLabel=""
                                     id="addArtworkLocation"
                                     formControlName="addArtworkLocation"
                                     class="uk-select"
                                     #addArtworkLocation (change)="addArtworkLocation.blur()">
                          </ng-select>
                          <div class="uk-text-danger uk-text-small"
                               *ngIf="addArtworkForm.get('addArtworkLocation').hasError('maxlength')"
                          >
                            {{ getCharactersLimitError('addArtworkLocation') }}
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="uk-width-1-2@s uk-width-1-3@m">
                      <div class="uk-margin">
                        <div class="uk-flex uk-flex-between uk-text-meta">
                          <label class="uk-form-label" for="addArtworkLocationInformation">Location information</label>
                          <div class="uk-text-small">
                            {{ getCharactersLeft('addArtworkLocationInformation', 2048) }}
                          </div>
                        </div>
                        <div class="uk-form-controls">
                          <textarea id="addArtworkLocationInformation" name="addArtworkLocationInformation"
                                    formControlName="addArtworkLocationInformation" rows="5" type="text"
                                    placeholder="Location information ..." class="uk-textarea"
                                    [ngClass]="{'uk-form-danger': addArtworkForm.get('addArtworkLocationInformation').touched && addArtworkForm.get('addArtworkLocationInformation').errors }"></textarea>
                          <div class="uk-text-danger uk-text-small"
                               *ngIf="addArtworkForm.get('addArtworkLocationInformation').hasError('maxlength')"
                          >
                            {{ getCharactersLimitError('addArtworkLocationInformation') }}
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="uk-width-1-2@s uk-width-1-3@m">
                      <div class="uk-margin">
                        <label class="uk-form-label" for="addArtworkPriceMultiplier">Price multiplier</label>
                        <div class="uk-form-controls">
                          <ng-select [items]="variables.priceMultipliers"
                                     [addTag]="true"
                                     [selectOnTab]="false"
                                     addTagText="Add new: "
                                     placeholder="Please select ..."
                                     bindLabel=""
                                     id="addArtworkPriceMultiplier"
                                     formControlName="addArtworkPriceMultiplier"
                                     class="uk-select"
                                     #addArtworkPriceMultiplier (change)="addArtworkPriceMultiplier.blur()">
                          </ng-select>
                          <div class="uk-text-danger uk-text-small"
                               *ngIf="addArtworkForm.get('addArtworkPriceMultiplier').hasError('maxlength')"
                          >
                            {{ getCharactersLimitError('addArtworkPriceMultiplier') }}
                          </div>
                          <div *ngIf="addArtworkForm.get('addArtworkPriceMultiplier').untouched && artistMultiplier"
                               class="uk-text-muted hint">
                            Set artist multiplier {{ artistMultiplier }} as default value
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="uk-width-1-2@s uk-width-1-3@m">
                      <div class="uk-margin">
                        <label class="uk-form-label" for="addArtworkClonable">May include editions</label>
                        <div class="uk-form-controls">
                          <label><input id="addArtworkClonable" name="addArtworkClonable"
                                        formControlName="addArtworkClonable" class="uk-checkbox" type="checkbox"> This
                            artwork may include editions</label>
                        </div>
                      </div>
                    </div>
                    <div class="uk-width-1-2@s uk-width-1-3@m">
                      <div class="uk-margin">
                        <label class="uk-form-label" for="addArtworkCloneOnOrder">Editions may be made on order</label>
                        <div class="uk-form-controls">
                          <label><input id="addArtworkCloneOnOrder" name="addArtworkCloneOnOrder"
                                        formControlName="addArtworkCloneOnOrder" class="uk-checkbox" type="checkbox">
                            Editions of this artwork may be made on order</label>
                        </div>
                      </div>
                    </div>
                    <div class="uk-width-1-2@s uk-width-1-3@m">
                      <div class="uk-margin">
                        <label class="uk-form-label" for="addArtworkArtistsproof">Artist's proof</label>
                        <div class="uk-form-controls">
                          <label><input id="addArtworkArtistsproof" name="addArtworkArtistsproof"
                                        formControlName="addArtworkArtistsproof" class="uk-checkbox" type="checkbox">
                            This artwork has artist's proof</label>
                        </div>
                      </div>
                    </div>
                    <div class="uk-width-1-2@s uk-width-1-3@m">
                      <div class="uk-margin">
                        <label class="uk-form-label" for="addArtworkSigning">Artist's signing</label>
                        <div class="uk-form-controls">
                          <label><input id="addArtworkSigning" name="addArtworkSigning"
                                        formControlName="addArtworkSigning" class="uk-checkbox" type="checkbox"> This
                            artwork has artist's signing</label>
                        </div>
                      </div>
                    </div>
                    <div class="uk-width-1-2@s uk-width-1-3@m"
                         *ngIf="['Installation'].includes(addArtworkForm.get('addArtworkType').value)">
                      <div class="uk-margin">
                        <label class="uk-form-label" for="addArtworkDeliveryOnOrder">Delivery on order</label>
                        <div class="uk-form-controls">
                          <label><input id="addArtworkDeliveryOnOrder" name="addArtworkDeliveryOnOrder"
                                        formControlName="addArtworkDeliveryOnOrder" class="uk-checkbox" type="checkbox">
                          </label>
                        </div>
                      </div>
                    </div>
                    <div class="uk-width-1-1 uk-margin-medium-top"
                         *ngIf="!(['gallery', 'artist'].includes(account.type))">
                      <h3 class="uk-margin-remove-bottom">Purchase information</h3>
                      <hr class="uk-margin-small-top">
                    </div>
                    <div class="uk-width-1-2@s uk-width-1-3@m" *ngIf="!(['gallery', 'artist'].includes(account.type))">
                      <div class="uk-margin">
                        <label class="uk-form-label" for="addArtworkSeller">Seller</label>
                        <div class="uk-form-controls">
                          <input id="addArtworkSeller" name="addArtworkSeller" formControlName="addArtworkSeller"
                                 type="text" placeholder="Seller ..." class="uk-input"
                                 [ngClass]="{'uk-form-danger': addArtworkForm.get('addArtworkSeller').touched && addArtworkForm.get('addArtworkSeller').errors }">
                        </div>
                      </div>
                    </div>
                    <div class="uk-width-1-2@s uk-width-1-3@m" *ngIf="!(['gallery', 'artist'].includes(account.type))">
                      <div class="uk-margin">
                        <div class="uk-flex uk-flex-between uk-text-meta">
                          <label class="uk-form-label" for="addArtworkSellerContactInfo">Seller contact
                            information</label>
                          <div class="uk-text-small">
                            {{ getCharactersLeft('addArtworkSellerContactInfo', 2048) }}
                          </div>
                        </div>
                        <div class="uk-form-controls">
                          <textarea id="addArtworkSellerContactInfo" name="addArtworkSellerContactInfo"
                                    formControlName="addArtworkSellerContactInfo" rows="5"
                                    placeholder="Seller contact information ..." class="uk-textarea"
                                    [ngClass]="{'uk-form-danger': addArtworkForm.get('addArtworkSellerContactInfo').touched && addArtworkForm.get('addArtworkSellerContactInfo').errors }"></textarea>
                          <div class="uk-text-danger uk-text-small"
                               *ngIf="addArtworkForm.get('addArtworkSellerContactInfo').hasError('maxlength')"
                          >
                            {{ getCharactersLimitError('addArtworkSellerContactInfo') }}
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="uk-width-1-2@s uk-width-1-3@m" *ngIf="!(['gallery', 'artist'].includes(account.type))">
                      <div class="uk-margin">
                        <label class="uk-form-label" for="addArtworkPurchaseDate">Purchase date</label>
                        <div class="uk-form-controls">
                          <input
                            id="addArtworkPurchaseDate"
                            name="addArtworkPurchaseDate"
                            formControlName="addArtworkPurchaseDate"
                            type="text"
                            placeholder="Purchase date ..."
                            class="uk-input"
                            [ngClass]="{'uk-form-danger': addArtworkForm.get('addArtworkPurchaseDate').touched && addArtworkForm.get('addArtworkPurchaseDate').errors }"
                            angular-mydatepicker
                            [options]="myDatePickerOptions"
                            #AngularMyDatePickerAddArtworkPurchaseDate="angular-mydatepicker"
                            (click)="AngularMyDatePickerAddArtworkPurchaseDate.toggleCalendar()"
                            (dateChanged)="updateDate('addArtworkPurchaseDate', $event)"
                          />
                          <div class="uk-text-danger uk-text-small"
                               *ngIf="addArtworkForm.get('addArtworkPurchaseDate').hasError('maxlength')"
                          >
                            {{ getCharactersLimitError('addArtworkPurchaseDate') }}
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="uk-width-1-2@s uk-width-1-3@m" *ngIf="!(['gallery', 'artist'].includes(account.type))">
                      <div class="uk-margin">
                        <label class="uk-form-label" for="addArtworkPurchasePrice">Purchase price</label>
                        <div class="uk-form-controls">
                          <input id="addArtworkPurchasePrice" name="addArtworkPurchasePrice"
                                 formControlName="addArtworkPurchasePrice" type="text" placeholder="Purchase price ..."
                                 class="uk-input"
                                 [ngClass]="{'uk-form-danger': addArtworkForm.get('addArtworkPurchasePrice').touched && addArtworkForm.get('addArtworkPurchasePrice').errors }">
                          <div class="uk-text-danger uk-text-small"
                               *ngIf="addArtworkForm.get('addArtworkPurchasePrice').hasError('maxlength')"
                          >
                            {{ getCharactersLimitError('addArtworkPurchasePrice') }}
                          </div>
                          <div class="uk-text-danger uk-text-small"
                               *ngIf="addArtworkForm.get('addArtworkPurchasePrice').hasError('pattern')"
                          >
                            Field value is incorrect
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="uk-width-1-1 uk-margin-medium-top"
                         *ngIf="!(['gallery', 'artist'].includes(account.type))">
                      <h3 class="uk-margin-remove-bottom">Estimate information</h3>
                      <hr class="uk-margin-small-top">
                    </div>
                    <div class="uk-width-1-2@s uk-width-1-3@m" *ngIf="!(['gallery', 'artist'].includes(account.type))">
                      <div class="uk-margin">
                        <label class="uk-form-label" for="addArtworkMinimumPrice">Minimum price</label>
                        <div class="uk-form-controls">
                          <input id="addArtworkMinimumPrice" name="addArtworkMinimumPrice"
                                 formControlName="addArtworkMinimumPrice" type="text" placeholder="Minimum price ..."
                                 class="uk-input"
                                 [ngClass]="{'uk-form-danger': addArtworkForm.get('addArtworkMinimumPrice').touched && addArtworkForm.get('addArtworkMinimumPrice').errors }">
                          <div class="uk-text-danger uk-text-small"
                               *ngIf="addArtworkForm.get('addArtworkMinimumPrice').hasError('maxlength')"
                          >
                            {{ getCharactersLimitError('addArtworkMinimumPrice') }}
                          </div>
                          <div class="uk-text-danger uk-text-small"
                               *ngIf="addArtworkForm.get('addArtworkMinimumPrice').hasError('pattern')"
                          >
                            Field value is incorrect
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="uk-width-1-2@s uk-width-1-3@m" *ngIf="!(['gallery', 'artist'].includes(account.type))">
                      <div class="uk-margin">
                        <label class="uk-form-label" for="addArtworkMaximumPrice">Maximum price</label>
                        <div class="uk-form-controls">
                          <input id="addArtworkMaximumPrice" name="addArtworkMaximumPrice"
                                 formControlName="addArtworkMaximumPrice" type="text" placeholder="Maximum price ..."
                                 class="uk-input"
                                 [ngClass]="{'uk-form-danger': addArtworkForm.get('addArtworkMaximumPrice').touched && addArtworkForm.get('addArtworkMaximumPrice').errors }">
                          <div class="uk-text-danger uk-text-small"
                               *ngIf="addArtworkForm.get('addArtworkMaximumPrice').hasError('maxlength')"
                          >
                            {{ getCharactersLimitError('addArtworkMaximumPrice') }}
                          </div>
                          <div class="uk-text-danger uk-text-small"
                               *ngIf="addArtworkForm.get('addArtworkMaximumPrice').hasError('pattern')"
                          >
                            Field value is incorrect
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="uk-width-1-2@s uk-width-1-3@m" *ngIf="!(['gallery', 'artist'].includes(account.type))">
                      <div class="uk-margin">
                        <label class="uk-form-label" for="addArtworkEstimateBasis">Estimate basis</label>
                        <div class="uk-form-controls">
                          <ng-select [items]="variables.estimateBasis"
                                     [addTag]="true"
                                     [selectOnTab]="false"
                                     addTagText="Add new: "
                                     placeholder="Please select ..."
                                     bindLabel=""
                                     id="addArtworkEstimateBasis"
                                     formControlName="addArtworkEstimateBasis"
                                     class="uk-select"
                                     #addArtworkEstimateBasis (change)="addArtworkEstimateBasis.blur()">
                          </ng-select>
                          <div class="uk-text-danger uk-text-small"
                               *ngIf="addArtworkForm.get('addArtworkEstimateBasis').hasError('maxlength')"
                          >
                            {{ getCharactersLimitError('addArtworkEstimateBasis') }}
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="uk-width-1-2@s uk-width-1-3@m" *ngIf="!(['gallery', 'artist'].includes(account.type))">
                      <div class="uk-margin">
                        <label class="uk-form-label" for="addArtworkEstimator">Estimator</label>
                        <div class="uk-form-controls">
                          <input id="addArtworkEstimator" name="addArtworkEstimator"
                                 formControlName="addArtworkEstimator" type="text" placeholder="Estimator ..."
                                 class="uk-input"
                                 [ngClass]="{'uk-form-danger': addArtworkForm.get('addArtworkEstimator').touched && addArtworkForm.get('addArtworkEstimator').errors }">
                          <div class="uk-text-danger uk-text-small"
                               *ngIf="addArtworkForm.get('addArtworkEstimator').hasError('maxlength')"
                          >
                            {{ getCharactersLimitError('addArtworkEstimator') }}
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="uk-width-1-2@s uk-width-1-3@m" *ngIf="!(['gallery', 'artist'].includes(account.type))">
                      <div class="uk-margin">
                        <div class="uk-flex uk-flex-between uk-text-meta">
                          <label class="uk-form-label" for="addArtworkEstimatorInformation">Estimator
                            information</label>
                          <div class="uk-text-small">
                            {{ getCharactersLeft('addArtworkEstimatorInformation', 2048) }}
                          </div>
                        </div>
                        <div class="uk-form-controls">
                          <textarea id="addArtworkEstimatorInformation" name="addArtworkEstimatorInformation"
                                    formControlName="addArtworkEstimatorInformation" rows="5" type="text"
                                    placeholder="Estimator information ..." class="uk-textarea"
                                    [ngClass]="{'uk-form-danger': addArtworkForm.get('addArtworkEstimatorInformation').touched && addArtworkForm.get('addArtworkEstimatorInformation').errors }"></textarea>
                          <div class="uk-text-danger uk-text-small"
                               *ngIf="addArtworkForm.get('addArtworkEstimatorInformation').hasError('maxlength')"
                          >
                            {{ getCharactersLimitError('addArtworkEstimatorInformation') }}
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="uk-width-1-2@s uk-width-1-3@m" *ngIf="!(['gallery', 'artist'].includes(account.type))">
                      <div class="uk-margin">
                        <label class="uk-form-label" for="addArtworkCollateralValue">Collateral value</label>
                        <div class="uk-form-controls">
                          <input id="addArtworkCollateralValue" name="addArtworkCollateralValue"
                                 formControlName="addArtworkCollateralValue" type="text"
                                 placeholder="Collateral value ..." class="uk-input"
                                 [ngClass]="{'uk-form-danger': addArtworkForm.get('addArtworkCollateralValue').touched && addArtworkForm.get('addArtworkCollateralValue').errors }">
                          <div class="uk-text-danger uk-text-small"
                               *ngIf="addArtworkForm.get('addArtworkCollateralValue').hasError('maxlength')"
                          >
                            {{ getCharactersLimitError('addArtworkCollateralValue') }}
                          </div>
                          <div class="uk-text-danger uk-text-small"
                               *ngIf="addArtworkForm.get('addArtworkCollateralValue').hasError('pattern')"
                          >
                            Field value is incorrect
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="uk-width-1-1 uk-margin-medium-top"
                         *ngIf="!(['gallery', 'artist'].includes(account.type))">
                      <h3 class="uk-margin-remove-bottom">Conservation information</h3>
                      <hr class="uk-margin-small-top">
                    </div>
                    <div class="uk-width-1-2@s uk-width-1-3@m" *ngIf="!(['gallery', 'artist'].includes(account.type))">
                      <div class="uk-margin">
                        <label class="uk-form-label" for="addArtworkConservationMinimumPrice">Conservation date</label>
                        <div class="uk-form-controls">
                          <input
                            id="addArtworkConservationDate"
                            name="addArtworkConservationDate"
                            formControlName="addArtworkConservationDate"
                            type="text"
                            placeholder="Conservation date ..."
                            class="uk-input"
                            [ngClass]="{'uk-form-danger': addArtworkForm.get('addArtworkConservationDate').touched && addArtworkForm.get('addArtworkConservationDate').errors }"
                            angular-mydatepicker
                            [options]="myDatePickerOptions"
                            #AngularMyDatePickerAddArtworkConservationDate="angular-mydatepicker"
                            (click)="AngularMyDatePickerAddArtworkConservationDate.toggleCalendar()"
                            (dateChanged)="updateDate('addArtworkConservationDate', $event)"
                          />
                          <div class="uk-text-danger uk-text-small"
                               *ngIf="addArtworkForm.get('addArtworkConservationDate').hasError('maxlength')"
                          >
                            {{ getCharactersLimitError('addArtworkConservationDate') }}
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="uk-width-1-2@s uk-width-1-3@m" *ngIf="!(['gallery', 'artist'].includes(account.type))">
                      <div class="uk-margin">
                        <label class="uk-form-label" for="addArtworkConservationCompany">Conservator company</label>
                        <div class="uk-form-controls">
                          <input id="addArtworkConservationCompany" name="addArtworkConservationCompany"
                                 formControlName="addArtworkConservationCompany" type="text"
                                 placeholder="Conservator company ..." class="uk-input"
                                 [ngClass]="{'uk-form-danger': addArtworkForm.get('addArtworkConservationCompany').touched && addArtworkForm.get('addArtworkConservationCompany').errors }">
                          <div class="uk-text-danger uk-text-small"
                               *ngIf="addArtworkForm.get('addArtworkConservationCompany').hasError('maxlength')"
                          >
                            {{ getCharactersLimitError('addArtworkConservationCompany') }}
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="uk-width-1-2@s uk-width-1-3@m" *ngIf="!(['gallery', 'artist'].includes(account.type))">
                      <div class="uk-margin">
                        <label class="uk-form-label" for="addArtworkConservationName">Conservator name</label>
                        <div class="uk-form-controls">
                          <input id="addArtworkConservationName" name="addArtworkConservationName"
                                 formControlName="addArtworkConservationName" type="text"
                                 placeholder="Conservator name ..." class="uk-input"
                                 [ngClass]="{'uk-form-danger': addArtworkForm.get('addArtworkConservationName').touched && addArtworkForm.get('addArtworkConservationName').errors }">
                          <div class="uk-text-danger uk-text-small"
                               *ngIf="addArtworkForm.get('addArtworkConservationName').hasError('maxlength')"
                          >
                            {{ getCharactersLimitError('addArtworkConservationName') }}
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="uk-width-1-2@s uk-width-1-3@m" *ngIf="!(['gallery', 'artist'].includes(account.type))">
                      <div class="uk-margin">
                        <div class="uk-flex uk-flex-between uk-text-meta">
                          <label class="uk-form-label" for="addArtworkConservationDetails">Conservation details</label>
                          <div class="uk-text-small">
                            {{ getCharactersLeft('addArtworkConservationDetails',
                            2048) }}
                          </div>
                        </div>
                        <div class="uk-form-controls">
                          <textarea id="addArtworkConservationDetails" name="addArtworkConservationDetails"
                                    formControlName="addArtworkConservationDetails" rows="5" type="text"
                                    placeholder="Conservation details ..." class="uk-textarea"
                                    [ngClass]="{'uk-form-danger': addArtworkForm.get('addArtworkConservationDetails').touched && addArtworkForm.get('addArtworkConservationDetails').errors }"></textarea>
                          <div class="uk-text-danger uk-text-small"
                               *ngIf="addArtworkForm.get('addArtworkConservationDetails').hasError('maxlength')"
                          >
                            {{ getCharactersLimitError('addArtworkConservationDetails') }}
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="uk-width-1-1@s">
                      <div class="uk-margin">
                        <label class="uk-form-label">Attachments</label>
                        <div class="uk-form-controls uk-margin-small">
                          <dl class="uk-description-list" *ngIf="activeArtwork.attachments.length">
                            <div class="uk-flex uk-flex-middle" *ngFor="let attachment of activeArtwork.attachments">
                              <div class="uk-padding-small">
                                <img class="contenttype" width="40" height="40"
                                     src="/api/storagefile/{{ activeAccount }}/{{ attachment.fileId }}/small"
                                     *ngIf="['image/png', 'image/jpg', 'image/jpeg', 'image/heic', 'image/gif', 'image/svg+xml'].includes(attachment.contentType)"/>
                                <img class="contenttype" width="40" height="40" src="/api/file/5a5de6b1229e86403d67db11"
                                     *ngIf="['application/msword', 'application/vnd.openxmlformats-officedocument.wordprocessingml.document'].includes(attachment.contentType)"/>
                                <img class="contenttype" width="40" height="40" src="/api/file/5a5de6b1229e86403d67db10"
                                     *ngIf="['application/octet-stream', 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'].includes(attachment.contentType)"/>
                                <img class="contenttype" width="40" height="40" src="/api/file/5a5de6b1229e86403d67db12"
                                     *ngIf="['application/pdf'].includes(attachment.contentType)"/>
                                <img class="contenttype" width="40" height="40" src="/api/file/5a5de7bc229e86403d67db1b"
                                     *ngIf="['text/plain'].includes(attachment.contentType)"/>
                                <img class="contenttype" width="40" height="40" src="/api/file/5a5de6b1229e86403d67db0f"
                                     *ngIf="!(['image/png', 'image/jpg', 'image/jpeg', 'image/heic', 'image/gif', 'image/svg+xml', 'application/msword', 'application/vnd.openxmlformats-officedocument.wordprocessingml.document', 'application/octet-stream', 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet', 'application/pdf', 'text/plain'].includes(attachment.contentType))"/>
                              </div>
                              <div class="uk-width-expand">{{ attachment.name }}</div>
                              <div class="uk-button-group">
                                <button (click)="openAttachment(attachment)" class="uk-button uk-button-default"
                                        type="button">Open
                                </button>
                                <div class="uk-inline uk-text-right">
                                  <button class="uk-button uk-button-default" type="button"><span
                                    uk-icon="icon:  chevron-down"></span></button>
                                  <div uk-dropdown="mode: click; boundary: ! .uk-button-group; boundary-align: true;">
                                    <ul class="uk-nav uk-dropdown-nav">
                                      <li><a (click)="removeAttachment(attachment)">Remove</a></li>
                                    </ul>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </dl>
                          <p *ngIf="!activeArtwork.attachments.length">No related attachments</p>
                        </div>
                      </div>
                    </div>
                    <div class="uk-width-1-1@s">
                      <div class="uk-margin">
                        <label class="uk-form-label">Add new attachments</label>
                        <div class="uk-form-controls">
                          <div class="drop-container" ngFileDrop [options]="options"
                               (uploadOutput)="onAttachmentsUploadOutput($event)" (click)="attachmentFile.click()"
                               [uploadInput]="uploadInput">
                            <div class="js-upload uk-placeholder uk-text-center"
                                 [ngClass]="{ 'is-drop-over': dragOver }">
                              <span class="uk-text-middle">Add files by dropping them here or open file dialog by clicking here.<br>Maximum allowed file size is 5 Mb.</span>
                            </div>
                            <input #attachmentFile type="file" ngFileSelect
                                   [options]="{ concurrency: 1, maxFileSize: 5000000 }" [uploadInput]="uploadInput"
                                   (uploadOutput)="onAttachmentsUploadOutput($event)" multiple class="file-input">
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="uk-width-1-2@s uk-width-1-3@m">
                      <div class="uk-margin">
                        <label class="uk-form-label" for="addArtworkTags">Tags</label>
                        <div class="uk-form-controls">
                          <input id="addArtworkTags" name="addArtworkTags" formControlName="addArtworkTags" type="text"
                                 placeholder="Tags ..." class="uk-input"
                                 [ngClass]="{'uk-form-danger': addArtworkForm.get('addArtworkTags').touched && addArtworkForm.get('addArtworkTags').errors }">
                          <div class="uk-text-danger uk-text-small"
                               *ngIf="addArtworkForm.get('addArtworkTags').hasError('maxlength')"
                          >
                            {{ getCharactersLimitError('addArtworkTags') }}
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="uk-width-1-2@s uk-width-1-3@m">
                      <div class="uk-margin">
                        <div class="uk-flex uk-flex-between uk-text-meta">
                          <label class="uk-form-label" for="addArtworkNotes">Notes</label>
                          <div class="uk-text-small">
                            {{ getCharactersLeft('addArtworkNotes', 2048) }}
                          </div>
                        </div>
                        <div class="uk-form-controls">
                          <textarea id="addArtworkNotes" name="addArtworkNotes" formControlName="addArtworkNotes"
                                    rows="5" type="text" placeholder="Other information ..." class="uk-textarea"
                                    [ngClass]="{'uk-form-danger': addArtworkForm.get('addArtworkNotes').touched && addArtworkForm.get('addArtworkNotes').errors }"></textarea>
                          <div class="uk-text-danger uk-text-small"
                               *ngIf="addArtworkForm.get('addArtworkNotes').hasError('maxlength')"
                          >
                            {{ getCharactersLimitError('addArtworkNotes') }}
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="uk-width-1-2@s uk-width-1-3@m">
                      <div class="uk-margin">
                        <div class="uk-flex uk-flex-between uk-text-meta">
                          <label class="uk-form-label" for="addArtworkComments">Comments</label>
                          <div class="uk-text-small">
                            {{ getCharactersLeft('addArtworkComments', 2048) }}
                          </div>
                        </div>
                        <div class="uk-form-controls">
                          <textarea id="addArtworkComments" name="addArtworkComments"
                                    formControlName="addArtworkComments" rows="5" type="text" placeholder="Comments ..."
                                    class="uk-textarea"
                                    [ngClass]="{'uk-form-danger': addArtworkForm.get('addArtworkComments').touched && addArtworkForm.get('addArtworkComments').errors }"></textarea>
                          <div class="uk-text-danger uk-text-small"
                               *ngIf="addArtworkForm.get('addArtworkComments').hasError('maxlength')"
                          >
                            {{ getCharactersLimitError('addArtworkComments') }}
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="uk-width-1-2@s uk-width-1-3@m">
                      <div class="uk-margin">
                        <label class="uk-form-label" for="addArtworkVisibility">Visibility</label>
                        <div class="uk-form-controls">
                          <label><input id="addArtworkVisibility" name="addArtworkVisibility"
                                        formControlName="addArtworkVisibility" class="uk-checkbox" type="checkbox">
                            Public</label>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="uk-modal-footer">
                    <p class="uk-text-right">
                      <button class="uk-button uk-button-default uk-modal-close uk-margin-small-right" type="button"
                              id="addNewArtworkCancel" (click)="resetForm()">Cancel
                      </button>
                      <button type="submit" [disabled]="(!addArtworkForm.valid) || uploadLoading"
                              class="uk-button uk-button-primary">Save
                      </button>
                    </p>
                  </div>
                </form>
              </div>
            </div>

            <div id="removeArtwork" uk-modal>
              <div class="uk-modal-dialog height-auto">
                <button class="uk-modal-close-default" type="button" uk-close></button>
                <div class="uk-modal-header">
                  <h2 class="uk-modal-title">Remove artwork</h2>
                </div>
                <div class="uk-modal-body">
                  <p>Please verify that you wish to remove the selected artwork by clicking the Remove button below.</p>
                </div>
                <div class="uk-modal-footer uk-text-right">
                  <button class="uk-button uk-button-default uk-modal-close uk-margin-small-right" type="button" id="removeArtworkCancel" (click)="resetArtworkToRemove()">Cancel
                  </button>
                  <button type="submit" class="uk-button uk-button-primary uk-modal-close" (click)="removeArtwork()">Remove
                  </button>
                </div>
              </div>
            </div>
            <button class="uk-button uk-button-text uk-margin-right" type="button">Sort <span uk-icon="icon: chevron-down"></span></button>
            <div uk-dropdown="mode: click;">
              <ul class="uk-nav uk-dropdown-nav">
<!--                <li [ngClass]="{'uk-active': activeSort('created') }" (click)="sortItems('created')"><a>Created</a></li>-->
                <li [ngClass]="{'uk-active': activeSort('name') }" (click)="sortItems('name')"><a>Name</a></li>
                <li [ngClass]="{'uk-active': activeSort('artist') }" (click)="sortItems('artist')"><a>Artist name</a>
                </li>
                <li [ngClass]="{'uk-active': activeSort('technique') }" (click)="sortItems('technique')"><a
                >Technique</a></li>
                <li [ngClass]="{'uk-active': activeSort('availability') }" (click)="sortItems('availability')"><a
                >Availability</a></li>
                <li [ngClass]="{'uk-active': activeSort('visibility') }" (click)="sortItems('visibility')"><a
                >Visibility</a></li>
              </ul>
            </div>
            <button class="uk-button uk-button-text" type="button">Limit {{ limitQuery ? ': ' + limitQuery : null }} <span uk-icon="icon: chevron-down"></span></button>
            <div uk-dropdown="mode: click;">
              <ul class="uk-nav uk-dropdown-nav">
                <li [ngClass]="{'uk-active': activeLimit(10) }" (click)="setLimit(10)"><a>10</a></li>
                <li [ngClass]="{'uk-active': activeLimit(25) }" (click)="setLimit(25)"><a>25</a></li>
                <li [ngClass]="{'uk-active': activeLimit(50) }" (click)="setLimit(50)"><a>50</a></li>
                <li [ngClass]="{'uk-active': activeLimit(100) }" (click)="setLimit(100)"><a>100</a></li>
                <li [ngClass]="{'uk-active': activeLimit(200) }" (click)="setLimit(200)"><a>200</a></li>
              </ul>
            </div>
          </div>
          <div class="uk-height-1-1">
            <table class="uk-table uk-table-middle uk-table-divider">
              <thead>
              <tr>
                <th class="uk-table-shrink">Image</th>
                <th class="uk-text-nowrap" [ngClass]="{'active': activeSort('name') }"
                    (click)="sortItems('name')">Name<span uk-icon="chevron-down"
                                                          [ngClass]="{'inverse': !sortAscending }"></span></th>
                <th class="uk-text-nowrap uk-visible@m" [ngClass]="{'active': activeSort('artist') }"
                    (click)="sortItems('artist')">Artist<span uk-icon="chevron-down"
                                                              [ngClass]="{'inverse': !sortAscending }"></span></th>
                <th class="uk-table-shrink uk-text-nowrap uk-visible@m" [ngClass]="{'active': activeSort('technique') }"
                    (click)="sortItems('technique')">Technique<span uk-icon="chevron-down"
                                                                    [ngClass]="{'inverse': !sortAscending }"></span>
                </th>
                <th class="uk-table-shrink uk-text-nowrap uk-visible@m"
                    [ngClass]="{'active': activeSort('availability') }" (click)="sortItems('availability')">Availability<span
                  uk-icon="chevron-down" [ngClass]="{'inverse': !sortAscending }"></span></th>
                <th class="uk-table-shrink uk-text-nowrap uk-visible@m" [ngClass]="{'active': activeSort('location') }"
                    (click)="sortItems('location')">Location<span uk-icon="chevron-down"
                                                                  [ngClass]="{'inverse': !sortAscending }"></span></th>
                <th class="uk-table-shrink"></th>
              </tr>
              </thead>
              <tbody>
              <tr *ngFor="let artwork of items">
                <td (click)="openArtwork(artwork._id)" class="pointer">
                  <div
                    class="image-max-height uk-preserve-width uk-background-contain uk-background-muted uk-height-small uk-panel uk-flex uk-flex-center uk-flex-middle"
                    width="60"
                    [attr.data-src]="'/api/storagefile/' + activeAccount + '/' + artwork.coverImage + '/small'" uk-img
                    *ngIf="artwork.coverImage">
                  </div>
                  <div
                    class="image-max-height uk-preserve-width uk-background-contain uk-background-muted uk-height-small uk-panel uk-flex uk-flex-center uk-flex-middle"
                    width="60"
                    [attr.data-src]="'/api/storagefile/' + activeAccount + '/' + artwork.images[0].fileId + '/small'"
                    uk-img *ngIf="!artwork.coverImage && artwork.images && artwork.images.length">
                  </div>
                  <div
                    class="image-max-height uk-preserve-width uk-background-contain uk-background-muted uk-height-small uk-panel uk-flex uk-flex-center uk-flex-middle"
                    width="60" [attr.data-src]="'/api/image/artbuddy-logo-placeholder.svg'" uk-img
                    *ngIf="!artwork.coverImage && !artwork.images.length">
                  </div>
                </td>
                <td (click)="openArtwork(artwork._id)" class="pointer">{{ artwork.name }}</td>
                <td class="uk-visible@m">{{ artwork.artist?.name }} {{ artwork.artist?.lastName }}</td>
                <td class="uk-visible@m">{{ artwork.technique | shorten: 50: '...' }}</td>
                <td class="uk-visible@m">{{ artwork.availability | shorten: 50: '...'  }}</td>
                <td class="uk-visible@m">{{ artwork.location | shorten: 50: '...'  }}</td>
                <td class="uk-text-right">
                  <div class="uk-button-group">
                    <button (click)="openArtwork(artwork._id)" class="uk-button uk-button-text uk-margin-right" type="button">Open</button>
                    <div class="uk-inline" class="uk-visible@m">
                      <button class="uk-button uk-button-link" type="button"><span uk-icon="icon: chevron-down"></span></button>
                      <div uk-dropdown="mode: click; boundary: ! .uk-button-group; boundary-align: true;">
                        <ul class="uk-nav uk-dropdown-nav">
                          <li><a (click)="openRemoveArtwork(artwork._id)">Remove</a></li>
                        </ul>
                      </div>
                    </div>
                  </div>
                </td>
              </tr>
              </tbody>
            </table>
          </div>
          <ul class="uk-pagination uk-flex-center uk-flex-middle uk-margin-medium-top" *ngIf="pages.length > 2">
            <li class="uk-margin-small-right" [ngClass]="{'uk-disabled': (currentPage === 1) }">
              <a (click)="setCurrentPage(currentPage-1)">
                <span class="uk-margin-small-right" uk-pagination-previous></span> Previous
              </a>
            </li>
            <li>
              <select class="uk-select" [ngModel]="currentPage" (change)="setCurrentPageString($event.target.value)">
                <option *ngFor="let page of pages; let index = index; first as isFirst; last as isLast"
                        [value]="index+1">{{ index + 1 }}</option>
              </select>
            </li>
            <li class="uk-margin-small-left" [ngClass]="{'uk-disabled': (currentPage === pageCount) }">
              <a (click)="setCurrentPage(currentPage+1)">
                Next <span class="uk-margin-small-left" uk-pagination-next></span>
              </a>
            </li>
          </ul>
        </div>
      </div>
    </div>
  </div>
</div>
