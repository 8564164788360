import { Injectable } from '@angular/core';
import { HttpClient , HttpParams, HttpHeaders } from '@angular/common/http';

import { Observable } from 'rxjs';
import 'rxjs/add/operator/map';

@Injectable()
export class UserService {

  private token = localStorage.getItem('token');

  private httpOptions = {
    headers: new HttpHeaders({
      'Content-Type':  'application/json',
      'charset': 'UTF-8',
      'Authorization': 'Bearer ' + this.token
    })
  };

  constructor(
    private http: HttpClient
  ) {}

  register(user): Observable<any> {
    return this.http.post('/api/user', JSON.stringify(user), this.httpOptions);
  }

  login(credentials): Observable<any> {
    return this.http.post('/api/login', JSON.stringify(credentials), this.httpOptions);
  }

  forgot(emailAddress: string): Observable<any> {
    return this.http.post('/api/forgot', JSON.stringify(emailAddress), this.httpOptions);
  }

  getUsers(): Observable<any> {
    return this.http.get('/api/accountUsers');
  }

  countUsers(): Observable<any> {
    return this.http.get('/api/users/count');
  }

  addUser(user): Observable<any> {
    return this.http.post('/api/user', JSON.stringify(user), this.httpOptions);
  }

  getUser(user): Observable<any> {
    if (!user._id) {
      user._id = user.id;
    }
    return this.http.get(`/api/user/${user._id}`);
  }

  editUser(user): Observable<any> {
    if (!user.id) {
      user.id = user.editUserFormId;
    }
    return this.http.put(`/api/user/${user.id}`, JSON.stringify(user), this.httpOptions);
  }

  editAccountUser(user): Observable<any> {
    if (!user.id) {
      user.id = user.editUserFormId;
    }
    return this.http.put(`/api/accountuser/${user.id}`, JSON.stringify(user), this.httpOptions);
  }

  deleteUser(user): Observable<any> {
    return this.http.delete(`/api/user/${user._id}`, this.httpOptions);
  }

  deleteAccountUser(userObject): Observable<any> {
    return this.http.delete(`/api/accountuser/${userObject.userId}/${userObject.accountId}`, this.httpOptions);
  }

  setUsersActiveAccount(user, accountId): Observable<any> {
    return this.http.put(`/api/userAccount/${user._id}/${accountId}`, this.httpOptions);
  }

}
