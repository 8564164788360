import {Component, EventEmitter, forwardRef, Inject, Input, OnInit, Optional, Output} from '@angular/core';
import {BaseWidget, NgAisInstantSearch} from 'angular-instantsearch';
import {connectSearchBox} from 'instantsearch.js/es/connectors';
import {NgAisIndex} from 'angular-instantsearch';

@Component({
  selector: 'app-ais-search-content-box',
  template: `
    <div class="uk-float-left">
      <div class="uk-search uk-search-default uk-width-medium">
        <a class="uk-form-icon uk-form-icon-flip" (click)="clear()" uk-icon="icon: close" [hidden]="!this.state.query"></a>
<!--        <a class="uk-form-icon uk-form-icon-flip" (click)="search()" uk-icon="icon: search" [hidden]="!searchInput.length"></a>-->
        <input #input type="search" [(ngModel)]="searchInput" [placeholder]="placeHolder" (keyup.enter)="search()"
               class="uk-input">
      </div>
    </div>
  `
})
export class SearchAisSearchContentComponent extends BaseWidget implements OnInit {
  @Input() title: string;
  @Input() attribute: string;
  @Input() sortName: string;
  @Input() sortDescription: string;
  @Input() contentType: string;
  @Input() onClose: any;
  @Output() showResults: EventEmitter<boolean> = new EventEmitter<boolean>();
  public searchInput = '';
  public placeHolder: string;

  public state: {
    query: string;
    refine: Function;
    clear: Function;
    isSearchStalled: boolean;
  };

  constructor(
    @Inject(forwardRef(() => NgAisIndex))
    @Optional()
    public parentIndex: NgAisIndex,
    @Inject(forwardRef(() => NgAisInstantSearch))
    public instantSearchInstance: NgAisInstantSearch
  ) {
    super('SearchBox');
  }

  ngOnInit() {
    const contentTypeName: string = this.contentType === 'bid' ? 'proposal' : this.contentType;
    this.placeHolder =  `Search all ${contentTypeName}s...`;
    // @ts-ignore
    this.createWidget(connectSearchBox);
    this.onClose.subscribe(() => this.clear());
    super.ngOnInit();
    setTimeout(() => {
      if (this.state.query) {
        this.searchInput = this.state.query;
        this.search();
      }
    }, 0);
  }

  public clear(): void {
    this.showResults.emit(false);
    this.searchInput = '';
    this.state.clear();
  }

  clearSearchFunction(): void {
    this.clear();
  }

  search(): void {
    if (!this.searchInput.length) {
      this.showResults.emit(false);
      this.state.refine(this.searchInput);
      return;
    }

    this.state.refine(this.searchInput);
    this.showResults.emit(true);
  }
}
