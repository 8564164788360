import {Component, EventEmitter, OnInit, ViewChild} from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { AccountService } from '../services/account.service';
import { ActiveService } from '../services/active.service';
import { ActorService } from '../services/actor.service';
import { ItemService } from '../services/item.service';
/*
import { EstimateService } from '../services/estimate.service';
import { EstimateService } from '../services/estimate.service';
import { EstimateService } from '../services/estimate.service';
import { AccountService } from '../services/account.service';
import { ActiveService } from '../services/active.service';
*/
import { Observable } from 'rxjs';
import { delay } from 'rxjs/operators';
declare var UIkit: any;

@Component({
  selector: 'app-layout',
  templateUrl: './overview.component.html'
})
export class OverviewComponent implements OnInit {
  public activeAccount: string;
  public user: any = {};
  public artists: Array<Object> = [];
  public artworks: Array<Object> = [];
  public clients: Array<Object> = [];
  public bids: Array<Object> = [];
  public counts: any = {
    artists: 0,
    artworks: 0,
    bids: 0,
    clients: 0
  };
  public isLoading = true;
  public isCollector = true;
  public imageLoadOffset$: any;

  constructor(
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private activeService: ActiveService,
    private accountService: AccountService,
    private actorService: ActorService,
    private itemService: ItemService
  ) {
    this.activatedRoute.data.subscribe(data => {
      this.activeAccount = data.resolvedData.account.id;
      this.user = data.resolvedData.user.id;
    });
  }

  ngOnInit() {
    this.activeService.getActiveData().subscribe(data => {
      if (data.account.id) {
        const getObject = {
          accountId: data.account.id,
          requester: 'overview',
          sortingKey: '_id',
          sortingOrder: 'desc',
          skipQuery: 0,
          limitQuery: 5,
          contentTypes: ['account', 'artist', 'artistCount', 'item', 'itemCount', 'client', 'clientCount', 'bid', 'bidCount']
        };
        this.getAccountData(getObject);
      }
    });
  }

  getAccountData(getObject) {
    this.accountService.getAccountData(getObject).subscribe(
      data => {
        this.artists = data.artists;
        this.artworks = data.items;

        data.clients = data.clients.map((client: any) => {
          if (client.clientGroup && client.clientGroup[0]) {
            client.clientGroup = client.clientGroup[0].title;
          } else {
            client.clientGroup = '';
          }

          return client;
        });

        this.clients = data.clients;

        /*const itemToSplice = data.actors.find((client: any) => {
          return client.owner == this.activeAccount;
        });
*/
        /* data.actors.splice(data.actors.indexOf(itemToSplice), 1); */

        /*if (!this.clients.length) {
          data.actors.forEach((client: any) => {
            // Filter out Artist
            if (['Organisation', 'Private', 'PrivatePerson'].includes(client._type)) {
              this.clients.push(client);
            }
          });
        }*/

        this.bids = data.bids;
        ['artcollector'].includes(data.account.type) ? this.isCollector = true : this.isCollector = false;

        this.counts = {
          artists: data.artistCount,
          artworks: data.itemsCount,
          bids: data.bidCount,
          clients: data.clientsCount
        };
      },
      () => {
        UIkit.notification({
          message: 'Error fetching clients',
          status: 'danger',
          pos: 'top-right',
          timeout: 1500
        });
      },
      () => {
        this.isLoading = false;
      }
    );
  }

  openArtist(artistId): void {
    this.router.navigateByUrl('/artist/' + artistId);
  }

  openArtwork(artworkId): void {
    this.itemService.setCurrentItem(artworkId);
    this.router.navigateByUrl('/artwork/' + artworkId);
  }

  openClient(clientId): void {
    this.actorService.setCurrentActor(clientId);
    this.router.navigateByUrl('/client/' + clientId);
  }

  openBid(bidId): void {
    this.router.navigateByUrl('/bid/' + bidId);
  }

  /**/
}
