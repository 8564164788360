import {Component, EventEmitter, OnInit, ViewChild} from '@angular/core';
import { Router } from '@angular/router';
import { FormGroup, FormControl, Validators, FormBuilder } from '@angular/forms';
import { UserService } from '../services/user.service';
import { ToastComponent } from '../shared-components/toast/toast.component';
declare var UIkit: any;

@Component({
  selector: 'app-register',
  templateUrl: './register.component.html',
  styleUrls: ['./register.component.scss']
})
export class RegisterComponent implements OnInit {

  public registerForm: FormGroup;

  constructor(
    private formBuilder: FormBuilder,
    private router: Router,
    public toast: ToastComponent,
    private userService: UserService)
  {
    this.registerForm = this.formBuilder.group({
      registerEmail: new FormControl('', [
        Validators.required,
        Validators.minLength(3),
        Validators.maxLength(100)]),
      registerPassword: new FormControl('', [
        Validators.required,
        Validators.minLength(6)]),
      registerRole: new FormControl('', [
        Validators.required]
      )
    });
  }

  ngOnInit() {}

  register() {
    this.userService.register(this.registerForm.value).subscribe(
      res => {
        UIkit.notification({
          message: 'Registered successfully',
          status: 'success',
          pos: 'top-right',
          timeout: 1500
        });
        this.router.navigate(['/login']);
      },
      error => {
        UIkit.notification({
          message: 'Email address already in use',
          status: 'danger',
          pos: 'top-right',
          timeout: 1500
        });
      }
    );
  }
}
