import { Injectable } from '@angular/core';
import { AccountService } from '../services/account.service';
import { ActiveService } from '../services/active.service';
import { Observable } from 'rxjs';
import { Subject } from 'rxjs';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import 'rxjs/add/operator/map';

@Injectable()
export class ReservationService {
  private httpOptions = {
    headers: new HttpHeaders({
      'Content-Type': 'application/json',
      charset: 'UTF-8'
    })
  };
  private currentAccount: any;
  currentReservation: string = '';
  currentReservationChange: Subject<string> = new Subject<string>();

  constructor(
    private http: HttpClient,
    private activeService: ActiveService,
    private accountService: AccountService
  ) {
    this.activeService.activeDataChange.subscribe(data => {
      this.currentAccount = data.account.id;
    });
  }

  setCurrentReservation(reservationId): void {
    this.currentReservation = reservationId;
    this.currentReservationChange.next(this.currentReservation);
  }

  getCurrentReservation(): string {
    return this.currentReservation;
  }

  getReservations(accountId: String, ReservationType: String): Observable<any> {
    return this.http.get(`/api/reservations/${accountId}/${ReservationType}`);
  }

  countReservations(): Observable<any> {
    return this.http.get('/api/reservations/count');
  }

  addReservation(reservation): Observable<any> {
    return this.http.post('/api/reservation', JSON.stringify(reservation), this.httpOptions);
  }

  getReservation(reservation): Observable<any> {
    return this.http.get(`/api/reservation/${reservation}`);
  }

  getUserReservation(reservation): Observable<any> {
    return this.http.get(`/api/userReservation/${reservation._id}`);
  }

  editReservation(reservation): Observable<any> {
    if (!reservation.id) {
      reservation.id = reservation.personalFormId;
    }
    return this.http.put(
      `/api/reservation/${reservation.id}`,
      JSON.stringify(reservation),
      this.httpOptions
    );
  }

  editReservationApprovalStatus(reservation): Observable<any> {
    return this.http.put(`/api/reservation/singleValue/${reservation.id}`, JSON.stringify(reservation), this.httpOptions);
  }

  deleteReservation(reservationId): Observable<any> {
    return this.http.delete(`/api/reservation/${reservationId}`, this.httpOptions);
  }

  reservationImageId(fileObject: object): Observable<any> {
    return this.http.post(
      '/api/reservationImageId',
      JSON.stringify(fileObject),
      this.httpOptions
    );
  }
}
