import {Component, EventEmitter, OnInit, ViewChild} from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { FormGroup, FormControl, Validators, FormBuilder } from '@angular/forms';
import { AuthService } from '../../services/auth.service';
import { UserService } from '../../services/user.service';
import { AccountService } from '../../services/account.service';
import { FileService } from '../../services/file.service';
import { ActorService } from '../../services/actor.service';
import { ActiveService } from '../../services/active.service';
import { UploadOutput, UploadInput, UploadFile, humanizeBytes } from 'ngx-uploader';
declare var UIkit: any;
import * as moment from 'moment/moment';
import {AngularMyDatePickerDirective, IAngularMyDpOptions, IMyDateModel} from 'angular-mydatepicker';
import itemGroup from '../../../../server/models/itemGroup';

@Component({
  selector: 'app-artists',
  templateUrl: './artists.component.html',
  styleUrls: ['./artists.component.scss']
})
export class ArtistsComponent implements OnInit {
  public user: any = {};
  public activeArtist: any = {};
  public actorType: string;
  public activeAccount: string;
  public account: Object = {};
  public artists: Array<Object> = [];
  public variables: Array<string> = [];
  public isLoading = true;
  public uploadLoading: Boolean = false;
  public addArtistForm: FormGroup;
  public addNewArtistModalWindow: any;
  public sortingKey: string;
  public sortAscending = false;
  public currentPage = 1;
  public limitQuery = 10;
  public pageCount = 1;
  public pages: Array<any> = [];
  public myDatePickerOptions: IAngularMyDpOptions = {
    dateFormat: 'dd.mm.yyyy'
  };
  private todaysDate: IMyDateModel;
  private validDate: IMyDateModel;
  // @ViewChild('AngularMyDatePickerAddArtistBirthday') AngularMyDatePickerAddArtistBirthday: AngularMyDatePickerDirective;
  public filesToUpload: UploadFile[];
  public uploadInput: EventEmitter<UploadInput>;
  public humanizeBytes: Function;
  public dragOver: boolean;
  public imageLoadOffset$: any;
  public numberWithDecimal = new RegExp(/^[0-9]+(\.[0-9]{1,2})?$/);

  constructor(
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private formBuilder: FormBuilder,
    private auth: AuthService,
    private activeService: ActiveService,
    private userService: UserService,
    private accountService: AccountService,
    private actorService: ActorService,
    private fileService: FileService
  ) {
    this.activatedRoute.data.subscribe(data => {
      this.activeAccount = data.resolvedData.account.id;
    });

    this.addArtistForm = this.formBuilder.group({
      addArtistActorType: new FormControl('', [
        Validators.maxLength(100)
      ]),
      addArtistOwner: new FormControl('', [
        /*Validators.required,*/
        Validators.maxLength(100)
      ]),
      addArtistName: new FormControl('', [
        Validators.required,
        Validators.maxLength(100)
      ]),
      addArtistLastName: new FormControl('', [
        Validators.required,
        Validators.maxLength(100)
      ]),
      addArtistCode: new FormControl('', [
        Validators.maxLength(100)
      ]),
      // addArtistBirthday: new FormControl('', [
      //   Validators.maxLength(10)
      // ]),
      addArtistStreet: new FormControl('', [
        Validators.maxLength(100)
      ]),
      addArtistCity: new FormControl('', [
        Validators.maxLength(30)
      ]),
      addArtistState: new FormControl('', [
        Validators.maxLength(30)
      ]),
      addArtistZip: new FormControl('', [
        Validators.maxLength(10),
        Validators.pattern(this.numberWithDecimal)
      ]),
      addArtistCountry: new FormControl('', [
        Validators.maxLength(30)
      ]),
      addArtistEmail: new FormControl('', [
        Validators.maxLength(100)
      ]),
      addArtistPhone: new FormControl('', [
        Validators.maxLength(100),
        // Validators.pattern(this.numberWithDecimal)
      ]),
      addArtistDescription: new FormControl('', [
        Validators.maxLength(2048)
      ]),
      addArtistPriceMultiplier: new FormControl('', [
        Validators.maxLength(10),
        Validators.pattern(this.numberWithDecimal)
      ]),
      addArtistMultiplier: new FormControl('', [
        Validators.maxLength(100)
      ]),
      addArtistStatus: new FormControl('', [
        Validators.maxLength(30)
      ]),
      addArtistType: new FormControl('', [
        Validators.maxLength(30)
      ]),
      addArtistTags: new FormControl('', [
        Validators.maxLength(2048)
      ]),
      addArtistNotes: new FormControl('', [
        Validators.maxLength(2048)
      ]),
      addArtistComments: new FormControl('', [
        Validators.maxLength(2048)
      ]),
      addArtistVisibility: new FormControl('', [
        Validators.maxLength(10)
      ])
      /*addArtistAttachments: new FormControl('', [
        Validators.maxLength(100)
      ])*/
    });

    this.todaysDate = {
      isRange: false,
      singleDate: {date: {year: new Date().getFullYear(), month: new Date().getMonth() + 1, day: new Date().getDate()}}
    };
    this.validDate = {
      isRange: false,
      singleDate: {date: {year: new Date().getFullYear(), month: new Date().getMonth() + 1, day: new Date().getDate()}}
    };

    this.activeArtist.images = [];
    this.filesToUpload = [];
    this.uploadInput = new EventEmitter<UploadInput>();
    this.humanizeBytes = humanizeBytes;
  }

  ngOnInit() {
    this.isLoading = true;
    this.actorType = 'Artist';
    this.addArtistForm.patchValue({
      addArtistOwner: this.activeAccount
    });
    this.getAccountArtists();
  }

  getAccountArtists() {
    const getObject = {
      accountId: this.activeAccount,
      requester: 'artists',
      actorType: this.actorType,
      sortingKey: this.sortingKey ? this.sortingKey : '_id',
      sortingOrder: this.sortAscending ? 'asc' : 'desc',
      skipQuery: (this.currentPage - 1) * this.limitQuery,
      limitQuery: this.limitQuery
    };

    this.actorService.getActors(getObject).subscribe(
      data => {
        this.artists = data;
        this.getAccountData();
      },
      () => {
        UIkit.notification({
          message: 'Error fetching artists',
          status: 'danger',
          pos: 'top-right',
          timeout: 1500
        });
      },
      () => this.isLoading = false
    );
  }

  getAccountData() {
    const getObject = {
      accountId: this.activeAccount,
      requester: 'artists',
      sortingKey: this.sortingKey ? this.sortingKey : '_id',
      sortingOrder: this.sortAscending ? 'asc' : 'desc',
      skipQuery: 0, // (this.currentPage - 1) * this.limitQuery,
      limitQuery: 99999, // this.limitQuery,
      contentTypes: ['account', 'artistCount', 'variable']
    };

    this.accountService.getAccountData(getObject).subscribe(
      data => {
        this.account = data.account;
        this.variables = data.variables ? data.variables : [];

        this.pageCount = Math.ceil(data.artistCount / this.limitQuery);
        if (this.pageCount > 0) {
          this.pages = Array(this.pageCount).fill(null);
        }
      },
      () => {
        UIkit.notification({
          message: 'Error fetching artwork',
          status: 'danger',
          pos: 'top-right',
          timeout: 1500
        });
      },
      () => {
        this.isLoading = false;

      }
    );
  }

  openAddNewArtist() {
    this.addNewArtistModalWindow = window.document.getElementById('addNewArtist');
    UIkit.modal(this.addNewArtistModalWindow).show();
  }

  onUploadOutput(output: UploadOutput): void {
    const properFile = output.file && output.file.name && output.file.name.length > 0;

    if (output.type === 'allAddedToQueue') {
      const event: UploadInput = {
        type: 'uploadAll',
        url: '/api/file',
        method: 'POST',
        includeWebKitFormBoundary: true
      };
      this.uploadInput.emit(event);
    } else if (output.type === 'addedToQueue' && properFile) {
      if (this.filesToUpload.indexOf(output.file) == -1) {
        this.filesToUpload.push(output.file);
      }
    } else if (output.type === 'uploading' && properFile) {
      // this.isLoading = true;

      if (!this.activeArtist['images'].some(image => image.placeholderId === output.file.id)) {
        this.uploadLoading = true;
        this.activeService.increasePendingRequests();
        this.activeArtist['images'].push({ placeholderId: output.file.id });
        const index = this.filesToUpload.findIndex(file => file.id === output.file.id);
        this.filesToUpload[index] = output.file;
      }
    } else if (output.type === 'removed') {
      // remove file from array when removed
      this.filesToUpload = this.filesToUpload.filter((file: UploadFile) => file !== output.file);
    } else if (output.type === 'dragOver') {
      this.dragOver = true;
    } else if (output.type === 'dragOut') {
      this.dragOver = false;
    } else if (output.type === 'drop') {
      this.dragOver = false;
    } else if (output.type === 'removedAll') {
      this.filesToUpload.length = 0;
    } else if (output.type === 'done') {
      const fileObject = {
        itemId: output.file.response,
        fileId: output.file.response // alkuperäinen: output.file.response.id
      };

      this.removeFile(output.file.id);
      const indexToReplace = this.activeArtist['images'].findIndex(file => file.placeholderId === output.file.id);
      this.activeArtist['images'][indexToReplace] = fileObject;
      this.activeService.decreasePendingRequests();
      this.uploadLoading = false;

      this.activeArtist['images'] = this.activeArtist['images'].filter(image => {
        return !('placeholderId' in image);
      });
    }
  }

  startUpload(): void {
    const event: UploadInput = {
      type: 'uploadAll',
      url: '/api/file',
      method: 'POST',
      includeWebKitFormBoundary: true
    };
    this.uploadInput.emit(event);
  }

  cancelUpload(id: string): void {
    this.uploadInput.emit({ type: 'cancel', id: id });
  }

  removeFile(id: string): void {
    this.uploadInput.emit({ type: 'remove', id: id });
  }

  selectCover(fileId: string): void {
    this.activeArtist['coverImage'] = fileId;
  }

  addArtist() {
    const addArtistObject = {
      actorType: this.addArtistForm.value.addArtistActorType,
      name: this.addArtistForm.value.addArtistName,
      lastName: this.addArtistForm.value.addArtistLastName,
      owner: this.addArtistForm.value.addArtistOwner,
      code: this.addArtistForm.value.addArtistCode,
      artistType: this.addArtistForm.value.addArtistType,
      // birthday: this.addArtistForm.value.addArtistBirthday ? moment(this.addArtistForm.value.addArtistBirthday.singleDate.jsDate).format() : "",
      street: this.addArtistForm.value.addArtistStreet,
      city: this.addArtistForm.value.addArtistCity,
      state: this.addArtistForm.value.addArtistState,
      zip: this.addArtistForm.value.addArtistZip,
      country: this.addArtistForm.value.addArtistCountry,
      email: this.addArtistForm.value.addArtistEmail,
      phone: this.addArtistForm.value.addArtistPhone,
      description: this.addArtistForm.value.addArtistDescription,
      priceMultiplier: this.addArtistForm.value.addArtistPriceMultiplier,
      status: this.addArtistForm.value.addArtistStatus,
      type: this.addArtistForm.value.addArtistType,
      tags: this.addArtistForm.value.addArtistTags,
      notes: this.addArtistForm.value.addArtistNotes,
      comments: this.addArtistForm.value.addArtistComments,
      images: this.activeArtist['images'],
      coverImage: this.activeArtist['coverImage'],
      visibility: this.addArtistForm.value.addArtistVisibility
    };
    this.actorService.addActor(addArtistObject).subscribe(
      res => {
        this.ngOnInit();
        UIkit.modal(this.addNewArtistModalWindow).hide();
        this.resetForm();
        UIkit.notification({
          message: 'Saved successfully',
          status: 'success',
          pos: 'top-right',
          timeout: 1500
        });
      },
      error => {
        UIkit.notification({
          message: 'Saving failed',
          status: 'danger',
          pos: 'top-right',
          timeout: 1500
        });
      }
    );
  }

  openArtist(artistId): void {
    let previousItems: Array<any>;
    let nextItems: Array<any>;
    const selectedItemIndex: number = this.artists.findIndex((artist: any) => artist._id === artistId);
    const itemsCount: number = this.artists.length;

    if (selectedItemIndex > 0) {
      previousItems = this.artists.slice(0, selectedItemIndex);
    }
    if (selectedItemIndex < itemsCount) {
      nextItems = this.artists.slice(selectedItemIndex + 1, itemsCount);
    }

    this.router.navigateByUrl('/artist/' + artistId,
      {
        state: {
          currentItem: this.artists[selectedItemIndex],
          previousItems: previousItems,
          nextItems: nextItems,
        }
      }
    );
  }

  removeArtist(artistId): void {
    this.actorService.deleteActor(artistId).subscribe(
      () => {
        this.ngOnInit();
        UIkit.notification({
          message: 'Artist removed successfully',
          status: 'success',
          pos: 'top-right',
          timeout: 1500
        });
      },
      () => {
        UIkit.notification({
          message: 'Error removing artist',
          status: 'danger',
          pos: 'top-right',
          timeout: 1500
        });
      }
    );
  }

  sortItems(sortingKey: string): void {
    if (sortingKey !== this.sortingKey) {
      this.sortingKey = sortingKey;
    } else {
      this.sortAscending = !this.sortAscending;
    }
    this.getAccountArtists();
  }

  activeSort(key: string): boolean {
    return this.sortingKey === key;
  }

  resetForm(): void {
    this.addArtistForm.reset();
    this.addArtistForm.patchValue({
      addArtistOwner: this.activeAccount,
      addArtistType: ''
    });

    this.activeArtist.images = [];
    this.filesToUpload = [];
  }

  setLimit(limit: number): void {
    if (limit !== this.limitQuery) {
      this.limitQuery = limit;
      /* Set currentPage to 0 to start from the first page */
      this.currentPage = 1;
      this.getAccountArtists();
    }
  }

  activeLimit(limit: number): boolean {
    return this.limitQuery === limit;
  }

  setCurrentPageString(page: string): void {
    this.setCurrentPage(parseInt(page, 0));
  }

  setCurrentPage(page: number): void {
    if ((page !== this.currentPage) && (page <= this.pageCount)) {
      this.currentPage = page;
      this.getAccountArtists();
    }
  }

  public validateName(): void {
    this.addArtistForm.controls['addArtistName'].setErrors({ exists: null });
    this.addArtistForm.controls['addArtistLastName'].setErrors({ exists: null });

    const actorObject = {
      owner: this.addArtistForm.value.addArtistOwner,
      name: this.addArtistForm.value.addArtistName,
      lastName: this.addArtistForm.value.addArtistLastName,
      type: 'artist'
    };

    this.actorService.validateActorName(actorObject).subscribe(
      response => {
        if (response.error) {
          this.addArtistForm.controls['addArtistName'].setErrors({ exists: true });
          this.addArtistForm.controls['addArtistLastName'].setErrors({ exists: true });
        } else {
          this.addArtistForm.controls['addArtistName'].setErrors({ exists: null });
          this.addArtistForm.controls['addArtistLastName'].setErrors({ exists: null });

          this.addArtistForm.controls['addArtistName'].updateValueAndValidity();
          this.addArtistForm.controls['addArtistLastName'].updateValueAndValidity();
        }
      }
    );
  }

  updateDate(fieldName: string, dateObject: any) {
    this.addArtistForm.controls[fieldName].patchValue(moment(dateObject.singleDate.jsDate).format());
  }

  getCharactersLimitError(fieldName: string) {
    if (!fieldName || !this.addArtistForm.get(fieldName).value ) {
      return ''
    }

    const charactersOverLimit: number = this.addArtistForm.get(fieldName).value.length - this.addArtistForm.get(fieldName).errors.maxlength.requiredLength;

    return `Field length is ${ charactersOverLimit } characters over limit`;
  }

  getCharactersLeft(fieldName: string, charactersLimit: number) {
    if (!fieldName || !this.addArtistForm.get(fieldName).value ) {
      return ''
    }

    const fieldLength: number = this.addArtistForm.get(fieldName).value.length;
    const charactersOverLimit: number = fieldLength - charactersLimit;

    if (charactersOverLimit >= 0) { return `${ charactersLimit } / ${ charactersLimit }` }

    return `${ fieldLength } / ${ charactersLimit }`;
  }
}
