import {Component, Inject, forwardRef, Optional} from "@angular/core";
import { BaseWidget, NgAisInstantSearch } from "angular-instantsearch";
import { connectSortBy } from "instantsearch.js/es/connectors";
import { ActiveService } from "../../services/active.service";
import {NgAisIndex} from "angular-instantsearch";

@Component({
  selector: "app-ais-sort-by",
  template: `<div class="uk-flex uk-flex-left uk-flex-middle uk-flex-row">
    <span class="uk-text-nowrap uk-margin-small-right">Sort by</span>
    <select id="sorting-criteria" class="uk-select uk-margin-small-right" (change)="onChange($event.target.value)">
      <!--<option value="">Please select ...</option>-->
      <option *ngFor="let option of state.options" [value]="getValue(option.value)">{{ option.label }}</option>
    </select>
<!--    <span class="uk-text-nowrap uk-margin-small-right">in</span>-->
<!--    <select id="sorting-order" class="uk-select uk-margin-small-right" (change)="setOrder($event.target.value); onChange($event.target.value)">-->
<!--      &lt;!&ndash;<option value="">Please select ...</option>&ndash;&gt;-->
<!--      <option value="ascending">ascending</option>-->
<!--      <option value="descending" [defaultSelected]="true">descending</option>-->
<!--    </select>-->
    <span class="uk-text-nowrap">in descending order</span>
  </div>`
})
export class SearchAisSortByComponent extends BaseWidget {
  public activeAccount: any = {};
  private ascendingOrder = true;

  public state: {
    options: object[];
    currentRefinement: string;
    hasNoResults: boolean;
    refine: Function;
    widgetParams: object;
  };

  constructor(
    private activeService: ActiveService,
    @Inject(forwardRef(() => NgAisIndex))
    @Optional()
    public parentIndex: NgAisIndex,
    @Inject(forwardRef(() => NgAisInstantSearch))
    public instantSearchInstance: NgAisInstantSearch
  ) {
    super("SortBy");
    this.activeService.getActiveData().subscribe(
      data => {
        this.activeAccount = data.account.id;
        this.createWidget(connectSortBy, {
          // instance options
          items: [
            { label: "Relevance", value: this.activeAccount },
            { label: "Created", value: "created" },
            // { label: "Name", value: "name" },
          ],
        });

        setTimeout(() => {
          this.state.refine(this.activeAccount);
        }, 500);
      }
    );
  }

  setOrder(order: string): void {
    order === "ascending" ? this.ascendingOrder = true : this.ascendingOrder = false;
  }

  getValue(value: string): string {
    if (["name", "created"].includes(value)) {
      // Changed to always use descending order
      // this.ascendingOrder === true ? value = this.activeAccount + value + "Asc" : value = this.activeAccount + value + "Desc";
      value = this.activeAccount + value + "Desc";

      // if (value !== this.state.currentRefinement) {
      //   console.log("asetetaan uusi arvo: " + value);
      //   this.state.refine(value);
      // }
    }

    return value;
  }

  onChange(event: string): void {
    let refinedValue = "";

    if (["ascending", "descending"].includes(event)) {
      if (event === "ascending") {
        refinedValue = this.state.currentRefinement.replace("Desc", "Asc");
      } else {
        refinedValue = this.state.currentRefinement.replace("Asc", "Desc");
      }
    }

    if (event.indexOf(this.activeAccount) > -1) {
      this.state.refine(event);
    } else {
      this.state.refine(refinedValue);
    }
  }
}
