<div class="uk-section uk-section-default uk-padding-remove-top" uk-height-viewport="offset-top: true">

  <app-sidenav contentType="Estimates"></app-sidenav>

  <div class="uk-container-expand uk-height-expand">
    <div uk-grid>
      <div class="uk-width-expand@m">
        <div class="uk-padding-small uk-padding-remove-top" *ngIf="!isLoading">
          <div class="uk-width-1-1 uk-text-right uk-margin-small-bottom uk-padding-remove">
            <app-search-content contentType="estimate" fields="['name']"></app-search-content>
            <button class="uk-button uk-button-text uk-margin-right" type="button" (click)="openAddNewEstimate()">Add new</button>
            <div id="addNewEstimate" class="uk-modal-container" uk-modal="stack: true; bg-close: false; esc-close: false;">
              <div class="uk-modal-dialog">
                <form appNoSubmitEnter [formGroup]="addEstimateForm">
                  <input type="hidden" name="addEstimateOwner" name="addEstimateOwner" formControlName="addEstimateOwner" [ngModel]="activeAccount">
                  <div class="uk-modal-header">
                    <h2 class="uk-modal-title">Add new estimate</h2>
                  </div>
                  <div class="uk-modal-body" uk-grid>
                    <div class="uk-width-1-2@s">
                      <div class="uk-margin">
                        <label class="uk-form-label" for="addEstimateName">Name <span class="required">*</span></label>
                        <div class="uk-form-controls">
                          <input id="addEstimateName" name="addEstimateName" formControlName="addEstimateName" type="text" placeholder="Name ..." class="uk-input" [ngClass]="{'uk-form-danger': addEstimateForm.get('addEstimateName').touched && addEstimateForm.get('addEstimateName').hasError('required') }">
                        </div>
                      </div>
                    </div>
                    <div class="uk-width-1-2@s">
                      <div class="uk-margin">
                        <label class="uk-form-label" for="addEstimateDescription">Description</label>
                        <div class="uk-form-controls">
                          <textarea id="addEstimateDescription" name="addEstimateDescription" formControlName="addEstimateDescription" rows="5" placeholder="Description ..." class="uk-textarea" [ngClass]="{'uk-form-danger': addEstimateForm.get('addEstimateDescription').touched && addEstimateForm.get('addEstimateDescription').hasError('required') }"></textarea>
                        </div>
                      </div>
                    </div>
                    <div class="uk-width-1-2@s">
                      <div class="uk-margin">
                        <label class="uk-form-label" for="addEstimateEstimator">Estimator <span class="required">*</span></label>
                        <div class="uk-form-controls">
                          <select id="addEstimateEstimator" name="addEstimateEstimator" formControlName="addEstimateEstimator" class="uk-select">
                            <option value="" disabled>Please select ...</option>
                            <option *ngFor="let actor of actors" value="{{ actor._id }}">{{ actor.name }} {{ actor.lastName }}</option>
                          </select>
                        </div>
                      </div>
                    </div>
                    <div class="uk-width-1-2@s">
                      <div class="uk-margin">
                        <label class="uk-form-label" for="addEstimateCreated">Creation date</label>
                        <div class="uk-form-controls">
                          <input
                            id="addEstimateCreated"
                            name="addEstimateCreated"
                            formControlName="addEstimateCreated"
                            type="text"
                            placeholder="Creation date ..."
                            class="uk-input"
                            [ngClass]="{'uk-form-danger': addEstimateForm.get('addEstimateCreated').touched && addEstimateForm.get('addEstimateCreated').hasError('required') }"
                            angular-mydatepicker
                            [options]="myDatePickerOptions"
                            #AngularMyDatePickerAddEstimateCreated="angular-mydatepicker"
                            (click)="AngularMyDatePickerAddEstimateCreated.toggleCalendar()"
                            (dateChanged)="updateDate('addEstimateCreated', $event)"
                          />
                        </div>
                      </div>
                    </div>
                    <div class="uk-width-1-2@s">
                      <div class="uk-margin">
                        <label class="uk-form-label" for="addEstimateBasis">Basis</label>
                        <div class="uk-form-controls">
                          <ng2-completer id="addEstimateBasis" name="addEstimateBasis" formControlName="addEstimateBasis" [datasource]="variables.estimateBasis" [minSearchLength]="0" [textNoResults]="null"></ng2-completer>
                        </div>
                      </div>
                    </div>
                    <div class="uk-width-1-2@s">
                      <div class="uk-margin">
                        <label class="uk-form-label" for="addEstimateMinValue">Minimum value</label>
                        <div class="uk-form-controls">
                          <input id="addEstimateMinValue" name="addEstimateMinValue" formControlName="addEstimateMinValue" type="text" placeholder="Minimum value ..." class="uk-input" [ngClass]="{'uk-form-danger': addEstimateForm.get('addEstimateMinValue').touched && addEstimateForm.get('addEstimateMinValue').hasError('required') }">
                        </div>
                      </div>
                    </div>
                    <div class="uk-width-1-2@s">
                      <div class="uk-margin">
                        <label class="uk-form-label" for="addEstimateMaxValue">Maximum value</label>
                        <div class="uk-form-controls">
                          <input id="addEstimateMaxValue" name="addEstimateMaxValue" formControlName="addEstimateMaxValue" type="text" placeholder="Maximum value ..." class="uk-input" [ngClass]="{'uk-form-danger': addEstimateForm.get('addEstimateMaxValue').touched && addEstimateForm.get('addEstimateMaxValue').hasError('required') }">
                        </div>
                      </div>
                    </div>
                    <div class="uk-width-1-2@s">
                      <div class="uk-margin">
                        <label class="uk-form-label" for="addEstimateInsuranceValue">Insurance value</label>
                        <div class="uk-form-controls">
                          <input id="addEstimateInsuranceValue" name="addEstimateInsuranceValue" formControlName="addEstimateInsuranceValue" type="text" placeholder="Insurance value ..." class="uk-input" [ngClass]="{'uk-form-danger': addEstimateForm.get('addEstimateInsuranceValue').touched && addEstimateForm.get('addEstimateInsuranceValue').hasError('required') }">
                        </div>
                      </div>
                    </div>
                    <div class="uk-width-1-2@s">
                      <div class="uk-margin">
                        <label class="uk-form-label" for="addEstimateCurrency">Currency</label>
                        <div class="uk-form-controls">
                          <input id="addEstimateCurrency" name="addEstimateCurrency" formControlName="addEstimateCurrency" type="text" placeholder="Currency ..." class="uk-input" [ngClass]="{'uk-form-danger': addEstimateForm.get('addEstimateCurrency').touched && addEstimateForm.get('addEstimateCurrency').hasError('required') }">
                        </div>
                      </div>
                    </div>
                    <div class="uk-width-1-1 uk-margin-medium-top">
                      <h3 class="uk-margin-remove-bottom">Notes on estimate</h3>
                      <hr class="uk-margin-small-top">
                    </div>
                    <div class="uk-width-1-2@s uk-width-1-3@m">
                      <div class="uk-margin">
                        <label class="uk-form-label" for="addEstimateShowNotes">Notes</label>
                        <div class="uk-form-controls">
                          <textarea id="addEstimateShowNotes" name="addEstimateShowNotes" formControlName="addEstimateShowNotes" rows="5" type="text" placeholder="Notes shown on estimate ..." class="uk-textarea" [ngClass]="{'uk-form-danger': addEstimateForm.get('addEstimateShowNotes').touched && addEstimateForm.get('addEstimateShowNotes').hasError('required') }"></textarea>
                        </div>
                      </div>
                    </div>
                    <div class="uk-width-1-1">
                      <div class="uk-margin">
                        <label class="uk-form-label">Related artworks</label>
                        <!--<form appNoSubmitEnter id="searchArtwork" [formGroup]="searchArtworkForm">
                          <div class="uk-margin">
                            <div class="uk-form-controls uk-flex">
                              <input id="searchArtworkTerm" name="searchArtworkTerm" formControlName="searchArtworkTerm" class="uk-input uk-margin-small-right" type="search" placeholder="Search artworks..." [ngClass]="{' uk-form-danger': searchArtworkForm.get('searchArtworkTerm').touched && searchArtworkForm.get('searchArtworkTerm').hasError('invalid') }" autocomplete="off">
                              <button class="uk-button uk-button-default" (click)="searchArtwork()">Search</button>
                            </div>
                          </div>
                        </form>-->
                        <div class="uk-text-right">
                          <button class="uk-button uk-button-default" (click)="openAdvancedSearch()">Add</button>
                        </div>
                        <!--<div class="uk-position-relative" [hidden]="!searchArtworkResults.length">
                          <div class="uk-text-right">
                            <button class="uk-button uk-button-primary" (click)="addArtworksToEstimate()" [disabled]="!selectedArtworks.length">Add selected</button>
                          </div>
                          <hr class="uk-margin-small-bottom">
                        </div>
                        <div id="search-artwork-results-container" class="results-container" [hidden]="!searchArtworkResults.length">
                          <div class="uk-flex uk-flex-column">
                            &lt;!&ndash;<div class="uk-container-expand uk-height-expand uk-padding-small uk-padding-remove-left uk-padding-remove-right" *ngIf="!searchArtworkResults.length">
                              <ul class="search-results uk-flex-center" uk-tab>
                                <h3>No search results</h3>
                              </ul>
                            </div>&ndash;&gt;
                            <div class="uk-container-expand uk-height-expand">
                              <div class="uk-margin">
                                <div class="uk-width-1-1">
                                  <table class="uk-table uk-table-middle uk-table-divider uk-margin-remove-bottom">
                                    <thead>
                                    <tr>
                                      <th class="uk-table-shrink">Select</th>
                                      <th class="uk-table-shrink">Image</th>
                                      <th class="uk-width-expand">Name</th>
                                      <th class="uk-width-expand uk-text-nowrap">Artist</th>
                                      <th class="uk-table-shrink uk-text-nowrap">Technique</th>
                                    </tr>
                                    </thead>
                                    <tbody>
                                    <tr *ngFor="let artwork of searchArtworkResults">
                                      <td><input class="uk-checkbox" type="checkbox" (click)="selectEstimateArtwork(artwork)"></td>
                                      <td>
                                        <div class="image-max-height uk-preserve-width uk-background-contain uk-background-muted uk-height-small uk-panel uk-flex uk-flex-center uk-flex-middle" width="60" [attr.data-src]="'/api/storagefile/' + activeAccount + '/' + artwork.coverImage + '/medium'" uk-img *ngIf="artwork.coverImage">

                                        </div>
                                        &lt;!&ndash; Jos ei ole coverImagea, niin sit otetaan eka image &ndash;&gt;
                                        &lt;!&ndash;<img class="uk-preserve-width" [src]="'/api/storagefile/' + artwork.images[0].fileId" width="60" *ngIf="!artwork.coverImage && artwork.images && artwork.images.length">&ndash;&gt;
                                        <div class="image-max-height uk-preserve-width uk-background-contain uk-background-muted uk-height-small uk-panel uk-flex uk-flex-center uk-flex-middle" width="60" [attr.data-src]="'/api/storagefile/' + activeAccount + '/' + artwork.images[0].fileId + '/medium'" uk-img *ngIf="!artwork.coverImage && artwork.images && artwork.images.length">

                                        </div>
                                        &lt;!&ndash; Jos ei ole coverImagea tai ekan imagea, ni sit otetaan placeholder &ndash;&gt;
                                        &lt;!&ndash;<img class="uk-preserve-width uk-border-circle" src="/api/image/artbuddy-logo-placeholder.svg" *ngIf="!artwork.coverImage && !artwork.images.length" width="60">&ndash;&gt;
                                        <div class="image-max-height uk-preserve-width uk-background-contain uk-background-muted uk-height-small uk-panel uk-flex uk-flex-center uk-flex-middle" width="60" [attr.data-src]="'/api/image/artbuddy-logo-placeholder.svg'" uk-img *ngIf="!artwork.coverImage && !artwork.images.length">

                                        </div>
                                      </td>
                                      <td>{{ artwork.name }}</td>
                                      <td>{{ artwork.artistName }}</td>
                                      <td>{{ artwork.technique }}</td>
                                    </tr>
                                    </tbody>
                                  </table>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>-->
                        <div class="uk-grid-match uk-child-width-1-2@s uk-child-width-1-3@m uk-child-width-1-4@xl" uk-grid uk-sortable [hidden]="!activeEstimate.items.length">
                          <div class="uk-card" *ngFor="let item of activeEstimate.items">
                            <div class="uk-card-header uk-flex uk-flex-middle uk-flex-between uk-padding-remove-horizontal">
                              <div class="uk-padding-remove-left">
                                <h3 class="uk-card-title uk-margin-remove-bottom">{{ item.name }}</h3>
                                <div class="uk-text-meta">
                                  <span *ngIf="item.artistName">{{ item.artistName }}</span>
                                </div>
                              </div>
                              <!--<div>
                                <div class="uk-text-meta">{{ item.availability }}</div>
                                <div class="uk-text-meta" *ngIf="item.price.price">{{ item.price.price }} {{ item.price.currency }}</div>
                              </div>-->
                            </div>
                            <div class="uk-card-body uk-padding-remove uk-background-muted artwork-image" [attr.data-src]="'/api/storagefile/' + activeAccount + '/' + item.coverImage + '/medium'" uk-img *ngIf="item.coverImage"></div>
                            <div class="uk-card-body uk-padding-remove uk-background-muted artwork-image" [attr.data-src]="'/api/storagefile/' + activeAccount + '/' + item.images[0].fileId + '/medium'" uk-img *ngIf="!item.coverImage && item.images && item.images.length"></div>
                            <div class="uk-card-body uk-padding-remove uk-background-muted artwork-image bg-max-size" *ngIf="!item.coverImage && !item.images.length"></div>
                            <div class="uk-card-footer uk-flex uk-flex-middle uk-flex-between uk-padding-remove-horizontal">
                              <!--<div>
                                <div class="uk-text-meta" *ngIf="item.width && item.height">{{ item.width }} * {{ item.height }} cm</div>
                              </div>-->
                              <div class="uk-flex uk-flex-column">
                                <a (click)="removeArtworkFromEstimate(item)"  class="uk-button uk-button-text">Remove</a>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="uk-width-1-1 uk-margin-medium-top">
                      <h3 class="uk-margin-remove-bottom">Additional information</h3>
                      <hr class="uk-margin-small-top">
                    </div>
                    <div class="uk-width-1-2">
                      <div class="uk-margin">
                        <label class="uk-form-label" for="addEstimateNotes">Other information</label>
                        <div class="uk-form-controls">
                          <textarea id="addEstimateNotes" name="addEstimateNotes" formControlName="addEstimateNotes" rows="5" type="text" placeholder="Other information ..." class="uk-textarea" [ngClass]="{'uk-form-danger': addEstimateForm.get('addEstimateNotes').touched && addEstimateForm.get('addEstimateNotes').hasError('required') }"></textarea>
                        </div>
                      </div>
                    </div>
                    <div class="uk-width-1-2">
                      <div class="uk-margin">
                        <label class="uk-form-label" for="addEstimateComments">Comments</label>
                        <div class="uk-form-controls">
                          <textarea id="addEstimateComments" name="addEstimateComments" formControlName="addEstimateComments" rows="5" type="text" placeholder="Comments ..." class="uk-textarea" [ngClass]="{'uk-form-danger': addEstimateForm.get('addEstimateComments').touched && addEstimateForm.get('addEstimateComments').hasError('required') }"></textarea>
                        </div>
                      </div>
                    </div>
                    <div class="uk-width-1-2">
                      <div class="uk-margin">
                        <label class="uk-form-label" for="addEstimateTags">Tags</label>
                        <div class="uk-form-controls">
                          <input id="addEstimateTags" name="addEstimateTags" formControlName="addEstimateTags" type="text" placeholder="Tags ..." class="uk-input" [ngClass]="{'uk-form-danger': addEstimateForm.get('addEstimateTags').touched && addEstimateForm.get('addEstimateTags').hasError('required') }">
                        </div>
                      </div>
                    </div>
                    <div class="uk-width-1-2">
                      <div class="uk-margin">
                        <label class="uk-form-label" for="addEstimateVisibility">Visibility</label>
                        <div class="uk-form-controls">
                          <label><input id="addEstimateVisibility" name="addEstimateVisibility" formControlName="addEstimateVisibility" class="uk-checkbox" type="checkbox"> Public</label>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="uk-modal-footer">
                    <p class="uk-text-right">
                      <button class="uk-button uk-button-default uk-modal-close uk-margin-small-right" type="button" id="addNewEstimateCancel" (click)="resetForm()">Cancel</button>
                      <button type="submit" [disabled]="!addEstimateForm.valid" (click)="addEstimate()" class="uk-button uk-button-primary">Save</button>
                    </p>
                  </div>
                </form>
              </div>
            </div>
            <button class="uk-button uk-button-text uk-margin-right" type="button">Sort <span uk-icon="icon: chevron-down"></span></button>
            <div uk-dropdown="mode: click;">
              <ul class="uk-nav uk-dropdown-nav">
<!--                <li [ngClass]="{'uk-active': activeSort('created') }" (click)="sortEstimates('created')"><a>Created</a></li>-->
                <li [ngClass]="{'uk-active': activeSort('name') }" (click)="sortEstimates('name')"><a>Name</a></li>
                <li [ngClass]="{'uk-active': activeSort('artwork') }" (click)="sortEstimates('artwork')"><a>Artwork</a></li>
                <li [ngClass]="{'uk-active': activeSort('estimator') }" (click)="sortEstimates('estimator')"><a
                 >Estimator</a></li>
                <li [ngClass]="{'uk-active': activeSort('maxValue') }" (click)="sortEstimates('maxValue')"><a
                 >Max price</a></li>
              </ul>
            </div>
            <button class="uk-button uk-button-text" type="button">Limit {{ limitQuery ? ': ' + limitQuery : null }} <span uk-icon="icon: chevron-down"></span></button>
            <div uk-dropdown="mode: click;">
              <ul class="uk-nav uk-dropdown-nav">
                <li [ngClass]="{'uk-active': activeLimit(10) }" (click)="setLimit(10)"><a>10</a></li>
                <li [ngClass]="{'uk-active': activeLimit(25) }" (click)="setLimit(25)"><a>25</a></li>
                <li [ngClass]="{'uk-active': activeLimit(50) }" (click)="setLimit(50)"><a>50</a></li>
                <li [ngClass]="{'uk-active': activeLimit(100) }" (click)="setLimit(100)"><a>100</a></li>
                <li [ngClass]="{'uk-active': activeLimit(200) }" (click)="setLimit(200)"><a>200</a></li>
              </ul>
            </div>
          </div>
          <div class="uk-height-1-1">
            <table class="uk-table uk-table-middle uk-table-divider">
              <thead>
                <tr>
                  <th class="uk-text-nowrap" [ngClass]="{'active': activeSort('name') }" (click)="sortEstimates('name')">Name<span uk-icon="chevron-down" [ngClass]="{'inverse': !sortAscending }"></span></th>
                  <th class="uk-visible@m">Image</th>
                  <th class="uk-text-nowrap" [ngClass]="{'active': activeSort('artwork') }" (click)="sortEstimates('artwork')">Artwork<span uk-icon="chevron-down" [ngClass]="{'inverse': !sortAscending }"></span></th>
                  <th class="uk-text-nowrap uk-visible@m" [ngClass]="{'active': activeSort('estimator') }" (click)="sortEstimates('estimator')">Estimator<span uk-icon="chevron-down" [ngClass]="{'inverse': !sortAscending }"></span></th>
                  <th class="uk-text-nowrap uk-visible@m" [ngClass]="{'active': activeSort('created') }" (click)="sortEstimates('created')">Created<span uk-icon="chevron-down" [ngClass]="{'inverse': !sortAscending }"></span></th>
                  <th class="uk-table-shrink"></th>
                </tr>
              </thead>
              <tbody>
                <tr *ngFor="let estimate of estimates">
                  <td>{{ estimate.name }}</td>
                  <td class="uk-visible@m">
                    <span *ngIf="estimate.items.length && estimate.items[0].coverImage">
                      <div class="image-max-height uk-preserve-width uk-background-contain uk-background-muted uk-height-small uk-panel uk-flex uk-flex-center uk-flex-middle" width="60" [attr.data-src]="'/api/storagefile/' + activeAccount + '/' + estimate.items[0].coverImage + '/small'" uk-img>
                      </div>
                    </span>
                    <span *ngIf="estimate.items.length && !estimate.items[0].coverImage && estimate.items[0].images && estimate.items[0].images[0]">
                      <div class="image-max-height uk-preserve-width uk-background-contain uk-background-muted uk-height-small uk-panel uk-flex uk-flex-center uk-flex-middle" width="60" [attr.data-src]="'/api/storagefile/' + activeAccount + '/' + estimate.items[0].images[0].fileId + '/small'" uk-img>
                      </div>
                    </span>
                    <span *ngIf="estimate.items.length && !estimate.items[0].coverImage && !estimate.items[0].images.length">
                      <div class="image-max-height uk-preserve-width uk-background-contain uk-background-muted uk-height-small uk-panel uk-flex uk-flex-center uk-flex-middle" width="60" [attr.data-src]="'/api/image/artbuddy-logo-placeholder.svg'" uk-img>
                      </div>
                    </span>
                  </td>
                  <td><span *ngIf="estimate.items.length">{{ estimate.items[0].name }}<span *ngIf="estimate.items.length > 1">, ...</span></span></td>
                  <td class="uk-visible@m">{{ estimate.estimator.name }}</td>
                  <td class="uk-visible@m">{{ estimate.created ? (estimate.created | date: 'yyyy') : '' }}</td>
                  <td>
                    <div class="uk-button-group">
                      <button (click)="openEstimate(estimate._id)" class="uk-button uk-button-text uk-margin-right" type="button">Open</button>
                      <div class="uk-inline" class="uk-visible@m">
                        <button class="uk-button uk-button-link" type="button"><span uk-icon="icon: chevron-down"></span></button>
                        <div uk-dropdown="mode: click; boundary: ! .uk-button-group; boundary-align: true;">
                          <ul class="uk-nav uk-dropdown-nav">
                            <li><a (click)="removeEstimate(estimate._id)">Remove</a></li>
                          </ul>
                        </div>
                      </div>
                    </div>
                  </td>
                </tr>
              </tbody>
          </table>
          </div>
          <ul class="uk-pagination uk-flex-center uk-flex-middle uk-margin-medium-top" *ngIf="pages.length > 1">
            <li class="uk-margin-small-right" [ngClass]="{'uk-disabled': (currentPage === 1) }">
              <a (click)="setCurrentPage(currentPage-1)">
                <span class="uk-margin-small-right" uk-pagination-previous></span> Previous
              </a>
            </li>
            <li>
              <select class="uk-select" [ngModel]="currentPage" (change)="setCurrentPageString($event.target.value)">
                <option *ngFor="let page of pages; let index = index; first as isFirst; last as isLast" [value]="index+1">{{ index+1 }}</option>
              </select>
            </li>
            <li class="uk-margin-small-left" [ngClass]="{'uk-disabled': (currentPage === pageCount) }">
              <a (click)="setCurrentPage(currentPage+1)">
                Next <span class="uk-margin-small-left" uk-pagination-next></span>
              </a>
            </li>
          </ul>
        </div>
      </div>
    </div>
  </div>
</div>
<app-advanced-search-modal (setResults)="setArtworkSearchResults($event)" *ngIf="showAdvancedArtworkSearchModalWindow"></app-advanced-search-modal>
