import { Injectable } from '@angular/core';
import { AccountService } from '../services/account.service';
import { ActiveService } from '../services/active.service';
import { Observable } from 'rxjs/Observable';
import { Subject } from 'rxjs';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import 'rxjs/add/operator/map';

@Injectable()
export class ItemService {
  private httpOptions = {
    headers: new HttpHeaders({
      'Content-Type': 'application/json',
      charset: 'UTF-8'
    })
  };
  private currentAccount: any;
  currentItem: string = '';
  currentItemChange: Subject<string> = new Subject<string>();

  constructor(
    private http: HttpClient,
    private activeService: ActiveService,
    private accountService: AccountService
  ) {
    this.activeService.activeDataChange.subscribe(data => {
      this.currentAccount = data.account.id;
    });
  }

  setCurrentItem(itemId): void {
    this.currentItem = itemId;
    this.currentItemChange.next(this.currentItem);
  }

  getCurrentItem(): string {
    return this.currentItem;
  }

  getItems(accountId: String, ItemType: String): Observable<any> {
    /*let activeAccount;
    this.activeService.getActiveData().subscribe(data => {
      activeAccount = data.account.id;
    });*/
    return this.http.get(`/api/items/${accountId}/${ItemType}`);
  }

  countItems(activeAccount: string): Observable<any> {
    return this.http.get(`/api/items/count`);
  }

  addItem(item): Observable<any> {
    return this.http.post('/api/item', JSON.stringify(item), this.httpOptions);
  }

  addItemEdition(editionObject): Observable<any> {
    return this.http.post('/api/itemEdition', JSON.stringify(editionObject), this.httpOptions);
  }

  getItem(item): Observable<any> {
    return this.http.get(`/api/item/${item}`);
  }

  getUserItem(item): Observable<any> {
    return this.http.get(`/api/userItem/${item._id}`);
  }

  editItem(item): Observable<any> {
    if (!item.id) {
      item.id = item.personalFormId;
    }
    return this.http.put(
      `/api/item/${item.id}`,
      JSON.stringify(item),
      this.httpOptions
    );
  }

  deleteItem(itemId): Observable<any> {
    return this.http.delete(`/api/item/${itemId}`, this.httpOptions);
  }

  addItemImageId(fileObject: object): Observable<any> {
    return this.http.post(
      '/api/itemImageId',
      JSON.stringify(fileObject),
      this.httpOptions
    );
  }

  addExhibitionImageId(fileObject: object): Observable<any> {
    return this.http.post(
      '/api/exhibitionImageId',
      JSON.stringify(fileObject),
      this.httpOptions
    );
  }

  addArtistImageId(fileObject: object): Observable<any> {
    return this.http.post(
      '/api/artistImageId',
      JSON.stringify(fileObject),
      this.httpOptions
    );
  }

  openAttachment(accountId: string, attachment: any): Promise<any> {
    return fetch(`/api/storagefile/${ accountId }/${ attachment.fileId}`, {
      headers: {
        Authorization: 'Bearer ' + localStorage.getItem('token')
      }
    })
    .then(response => {
      if (response.ok) {
        return response
      } else {
        return null
      }
    });
  }

  validateItemName(item: any): Observable<any> {
    return this.http.post(
      `/api/validateItemName`,
      JSON.stringify(item),
      this.httpOptions
    );
  }

  validateItemArtworkCode(item: any): Observable<any> {
    return this.http.post(
      `/api/validateItemArtworkCode`,
      JSON.stringify(item),
      this.httpOptions
    );
  }
}
