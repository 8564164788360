<div class="uk-section uk-section-default uk-padding-remove-vertical">

  <div class="uk-flex uk-flex-between uk-flex-middle">
    <app-sidenav contentType="Artworks" [contentItem]="activeArtwork.name"></app-sidenav>
    <div class="uk-padding-small uk-padding-remove-vertical">
      <button class="uk-button uk-button-default uk-button-small uk-margin-small-right" type="button" (click)="openPreviousItem()" *ngIf="previousItems.length">Previous</button>
      <button class="uk-button uk-button-default uk-button-small" type="button" (click)="openNextItem()" *ngIf="nextItems.length">Next</button>
      <div class="uk-button-group uk-margin-small-left" *ngIf="previousItems.length || nextItems.length">
        <button class="uk-button uk-button-default" type="button"><span uk-icon="icon: chevron-down"></span></button>
        <div id="itemsDropdown" uk-dropdown="mode: click; boundary: ! .uk-button-group; boundary-align: true;">
          <ul class="uk-nav uk-dropdown-nav">
            <li *ngFor="let item of previousItems">
              <a (click)="openPreviousItem(item._id)">{{ item.name }}</a>
            </li>
            <li *ngFor="let item of nextItems">
              <a (click)="openNextItem(item._id)">{{ item.name }}</a>
            </li>
          </ul>
        </div>
      </div>
    </div>
  </div>

  <div class="uk-container-expand uk-height-expand">
        <ul uk-tab *ngIf="!isLoading">
          <li class="uk-active"><a id="basicinfo" href="#basicinfo"><h4>Basic information</h4></a></li>
          <li *ngIf="activeArtworkEditions"><a href="#editions"><h4>Editions</h4></a></li>
          <li *ngIf="activeArtworkExhibitions.length"><a href="#exhibitions"><h4>Exhibitions</h4></a></li>
          <li *ngIf="activeArtworkEstimates.length"><a href="#estimates"><h4>Estimates</h4></a></li>
          <li *ngIf="activeArtworkBids.length"><a href="#bids"><h4>Proposals</h4></a></li>
          <li *ngIf="activeArtworkTrades.length"><a href="#trades"><h4>Trades</h4></a></li>
          <li *ngIf="activeArtworkReservations.length"><a href="#reservations"><h4>Reservations</h4></a></li>
        </ul>
        <ul id="artworkPaneSwither" class="uk-switcher uk-card uk-card-body uk-padding-remove-top" *ngIf="!isLoading">
          <div id="basicinfo" class="uk-padding-small">
            <form appNoSubmitEnter [formGroup]="editArtworkForm" (ngSubmit)="editArtwork()" class="uk-grid-small" uk-grid>
              <input type="hidden" name="editArtworkOwner" name="editArtworkOwner" formControlName="editArtworkOwner" [ngModel]="activeAccount">
              <div class="uk-width-1-1">
                <h3 class="uk-margin-remove-bottom">Basic information</h3>
                <hr class="uk-margin-small-top">
              </div>
              <div class="uk-width-1-2@s uk-width-1-3@m">
                <div class="uk-margin">
                  <label class="uk-form-label" for="editArtworkName">Name <span class="required">*</span></label>
                  <div class="uk-form-controls">
                    <input id="editArtworkName" name="editArtworkName" formControlName="editArtworkName" [ngModel]="activeArtwork.name" type="text" placeholder="Name ..." class="uk-input" [ngClass]="{'uk-form-danger': editArtworkForm.get('editArtworkName').touched && (editArtworkForm.get('editArtworkName').hasError('required') || editArtworkForm.get('editArtworkName').hasError('maxlength'))}">
                    <div *ngIf="editArtworkForm.get('editArtworkName').invalid && (editArtworkForm.get('editArtworkName').dirty || editArtworkForm.get('editArtworkName').touched)" class="uk-text-danger uk-text-small">
                      <div *ngIf="editArtworkForm.get('editArtworkName').hasError('required')">
                        Name is required
                      </div>
                    </div>
                    <span class="uk-text-danger uk-text-small"
                      *ngIf="editArtworkForm.get('editArtworkName').hasError('maxlength')"
                    >
                      {{ getCharactersLimitError('editArtworkName') }}
                    </span>
                  </div>
                  <span class="uk-badge uk-margin-small-top uk-padding-small" *ngIf="activeArtwork.master">Edition</span>
                </div>
              </div>
              <div class="uk-width-1-2@s uk-width-1-3@m">
                <div class="uk-margin">
                  <div class="uk-flex uk-flex-between uk-text-meta">
                    <label class="uk-form-label" for="editArtworkDescription">Description</label>
                    <div class="uk-text-small">
                      {{ getCharactersLeft('editArtworkDescription', 2048) }}
                    </div>
                  </div>
                  <div class="uk-form-controls">
                    <textarea id="editArtworkDescription" name="editArtworkDescription" formControlName="editArtworkDescription" [ngModel]="activeArtwork.description" rows="5" placeholder="Description ..." class="uk-textarea" [ngClass]="{'uk-form-danger': editArtworkForm.get('editArtworkDescription').touched && (editArtworkForm.get('editArtworkDescription').hasError('required') || editArtworkForm.get('editArtworkDescription').hasError('maxlength')) }"></textarea>
                    <div class="uk-text-danger uk-text-small"
                          *ngIf="editArtworkForm.get('editArtworkDescription').hasError('maxlength')"
                    >
                      {{ getCharactersLimitError('editArtworkDescription') }}
                    </div>
                  </div>
                </div>
              </div>
              <div class="uk-width-1-2@s uk-width-1-3@m">
                <div class="uk-margin">
                  <label class="uk-form-label" for="editArtworkCode">Artwork code</label>
                  <div class="uk-form-controls">
                    <input id="editArtworkCode" name="editArtworkCode" formControlName="editArtworkCode" [ngModel]="activeArtwork.code" type="text" placeholder="Artwork code ..." class="uk-textarea" [ngClass]="{'uk-form-danger': editArtworkForm.get('editArtworkCode').touched && (editArtworkForm.get('editArtworkCode').hasError('required') || editArtworkForm.get('editArtworkCode').hasError('maxlength')) }">
                    <div class="uk-text-danger uk-text-small"
                          *ngIf="editArtworkForm.get('editArtworkCode').hasError('maxlength')"
                    >
                      {{ getCharactersLimitError('editArtworkCode') }}
                    </div>
                  </div>
                </div>
              </div>
              <div class="uk-width-auto@s">
                <label class="uk-form-label">Artwork images</label>
              </div>
              <div class="uk-width-expand@s uk-text-right">
                <button class="uk-button uk-button-default uk-margin-small-right" type="button" (click)="openAddArtworkImage()">Manage images</button>
                <button class="uk-button uk-button-default" type="button" (click)="openSlideshow($event)" [disabled]="!activeArtwork.images.length">Slideshow</button>
              </div>
              <div class="uk-width-1-1">
                <div class="uk-position-relative uk-visible-toggle" uk-slider>
                  <ul class="uk-slider-items uk-child-width-1-2@s uk-child-width-1-3@m uk-grid">
                    <li class="uk-text-center" *ngFor="let image of activeArtwork.images; let index = index">
                      <div class="uk-inline-clip uk-transition-toggle uk-width-1-1" tabindex="index">
                        <div class="uk-background-contain uk-background-muted uk-height-medium uk-panel uk-flex uk-flex-center uk-flex-middle" [attr.data-src]="'/api/storagefile/' + activeAccount + '/' + image.fileId + '/medium'" uk-img *ngIf="activeAccount">
                      </div>
                        <div class="uk-position-top-right uk-overlay uk-overlay-default uk-padding-small" *ngIf="image.fileId == activeArtwork.coverImage">Cover image</div>
                        <div class="uk-transition-slide-bottom uk-position-bottom uk-overlay uk-overlay-default" *ngIf="image.fileId !== activeArtwork.coverImage">
                          <button type="button" (click)="selectCover(image.fileId)" class="uk-button uk-button-text uk-margin-right">Select cover</button>
                          <button type="button" (click)="removeImage(image)" class="uk-button uk-button-text uk-margin-left">Remove</button>
                          <button type="button" (click)="openImage(image)" class="uk-button uk-button-text uk-margin-left">Download</button>
                        </div>
                      </div>
                    </li>
                  </ul>
                  <a class="uk-position-center-left uk-position-small uk-hidden-hover" uk-slidenav-previous uk-slider-item="previous"></a>
                  <a class="uk-position-center-right uk-position-small uk-hidden-hover" uk-slidenav-next uk-slider-item="next"></a>
                </div>
              </div>
              <div id="addArtworkImages" class="uk-modal-container" uk-modal="bg-close: false; esc-close: false;">
                <div class="uk-modal-dialog">
                  <div class="uk-modal-header">
                    <h2 class="uk-modal-title">Manage artwork images</h2>
                  </div>
                  <div class="uk-modal-body">
                    <div class="uk-width-1-1">
                      <div class="uk-position-relative uk-visible-toggle" uk-slider>
                        <ul class="uk-slider-items uk-child-width-1-2@s uk-child-width-1-3@m uk-grid">
                          <li class="uk-text-center" *ngFor="let image of activeArtwork.images; let index = index">
                            <div class="uk-inline-clip uk-transition-toggle uk-width-1-1" tabindex="index">
                              <div class="uk-background-contain uk-height-medium uk-panel uk-flex uk-flex-center uk-flex-middle" [attr.data-src]="'/api/storagefile/' + activeAccount + '/' + image.fileId + '/medium'" uk-img *ngIf="activeAccount">
                      </div>
                              <div class="uk-position-top-right uk-overlay uk-overlay-default uk-padding-small" *ngIf="image.fileId == activeArtwork.coverImage">Cover image</div>
                              <div class="uk-transition-slide-bottom uk-position-bottom uk-overlay uk-overlay-default">
                                <button type="button" (click)="selectCover(image.fileId)" class="uk-button uk-button-text" *ngIf="image.fileId !== activeArtwork.coverImage">Select cover</button>
                                <button type="button" (click)="removeImage(image)" class="uk-button uk-button-text uk-margin-left" *ngIf="image.fileId !== activeArtwork.coverImage">Remove</button>
                                <button type="button" (click)="openImage(image)" class="uk-button uk-button-text uk-margin-left">Download</button>
                              </div>
                            </div>
                          </li>
                        </ul>
                        <a class="uk-position-center-left uk-position-small uk-hidden-hover" uk-slidenav-previous uk-slider-item="previous"></a>
                        <a class="uk-position-center-right uk-position-small uk-hidden-hover" uk-slidenav-next uk-slider-item="next"></a>
                      </div>
                    </div>
                    <div class="uk-width-1-1">
                      <div class="uk-margin">
                        <label class="uk-form-label">Manage artwork images</label>
                        <p *ngIf="!activeArtwork.images.length">No artwork images</p>
                        <div class="uk-form-controls">
                          <div class="drop-container" ngFileDrop [options]="{ concurrency: 1, allowedContentTypes: ['image/jpeg', 'image/jpg', 'image/png', 'image/gif'], maxFileSize: 3000000 }" (uploadOutput)="onUploadOutput($event)" (click)="imageFile.click()" [uploadInput]="uploadInput">
                            <div class="js-upload uk-placeholder uk-text-center" [ngClass]="{ 'is-drop-over': dragOver }">
                              <span class="uk-text-middle">Add images by dropping them here or open file dialog by clicking here.<br>Allowed filetypes include JPG, PNG and GIF.<br>Maximum allowed file size is 5 Mb.</span>
                            </div>
                            <input #imageFile type="file" ngFileSelect [options]="{ concurrency: 1, allowedContentTypes: ['image/jpeg', 'image/jpg', 'image/png', 'image/gif'], maxFileSize: 5000000 }" [uploadInput]="uploadInput" (uploadOutput)="onUploadOutput($event)" multiple class="file-input">
                          </div>
                        </div>
<!--                        <progress id="js-progressbar" class="uk-progress" value="0" max="100" hidden></progress>-->
<!--                        <div class="table-container" *ngIf="filesToUpload.length">-->
<!--                          <table class="uk-table uk-table-middle uk-margin-top">-->
<!--                            <caption>Files to upload</caption>-->
<!--                            <thead>-->
<!--                            <tr>-->
<!--                              <th class="uk-table-shrink uk-text-capitalize">Image</th>-->
<!--                              <th class="uk-width-expand uk-text-capitalize">Name</th>-->
<!--                              <th class="uk-width-expand uk-text-capitalize">Size</th>-->
<!--                              <th class="uk-table-shrink uk-text-capitalize"></th>-->
<!--                            </tr>-->
<!--                            </thead>-->
<!--                            <tbody>-->
<!--                            <tr *ngFor="let file of filesToUpload; let i = index;">-->
<!--                              <td class="uk-background-muted"><img class="uk-preserve-width uk-border-circle" src="/api/image/artbuddy-logo-placeholder.svg" width="60" uk-img></td>-->
<!--                              <td>{{ file.name }}</td>-->
<!--                              <td class="uk-text-nowrap">{{ file.size | bytes: 2 }}</td>-->
<!--                              <td><button (click)="removeFile(file.id)" class="uk-button uk-button-default" type="button">Delete</button></td>-->
<!--                            </tr>-->
<!--                            </tbody>-->
<!--                          </table>-->
<!--                          <button type="button" (click)="startUpload()" class="uk-button uk-button-primary">Start upload</button>-->
<!--                        </div>-->
                      </div>
                    </div>
                  </div>
                  <div class="uk-modal-footer">
                    <p class="uk-text-right">
                      <button type="button" class="uk-button uk-button-primary uk-modal-close">Close</button>
                    </p>
                  </div>
                </div>
              </div>
              <div class="uk-width-1-2@s uk-width-1-3@m">
                <div class="uk-margin">
                  <label class="uk-form-label" for="editArtworkArtist">Artist</label>
                  <div class="uk-form-controls">
                    <!--<select id="editArtworkArtist" name="editArtworkArtist" formControlName="editArtworkArtist" [ngModel]="activeArtwork.artist" class="uk-select">
                      <option *ngFor="let artist of artists | orderBy: 'lastName'" [ngValue]="artist._id" >{{ artist.name }} {{ artist.lastName }}</option>
                    </select>-->
                    <ng-select [items]="artists"
                               [virtualScroll]="true"
                               [addTag]="false"
                               [selectOnTab]="false"
                               addTagText="Add new: "
                               placeholder="Please select ..."
                               bindLabel="fullName"
                               id="editArtworkArtist"
                               formControlName="editArtworkArtist"
                               class="uk-select"
                               #editArtworkArtist (change)="editArtworkArtist.blur()">
                      <!--<ng-template ng-header-tmp>
                        <div class="uk-text-muted uk-text-small">New value:</div>
                      </ng-template>-->
                    </ng-select>
                  </div>
                </div>
              </div>
              <div class="uk-width-1-2@s uk-width-1-3@m">
                <div class="uk-margin">
                  <label class="uk-form-label" for="editArtworkType">Artwork type</label>
                  <div class="uk-form-controls">
                    <select id="editArtworkType" name="editArtworkType" formControlName="editArtworkType" [ngModel]="activeArtwork._type" class="uk-select">
                      <option value="" disabled>Please select ...</option>
                      <option value="Painting">Painting</option>
                      <option value="Photography">Photography</option>
                      <option value="Print">Print</option>
                      <option value="Work on paper">Work on paper</option>
                      <option value="Design">Design</option>
                      <option value="Drawing">Drawing</option>
                      <option value="Jewelry">Jewelry</option>
                      <option value="Performance art">Performance art</option>
                      <option value="Sculpture">Sculpture</option>
                      <option value="Video">Video</option>
                      <option value="Installation">Installation</option>
                      <option value="Mixed media">Mixed media</option>
                      <option value="Other">Other</option>
                    </select>
                  </div>
                </div>
              </div>
              <!-- *ngIf="['Painting', 'Photography', 'Print', 'Work on paper', 'Design', 'Drawing','Jewelry', 'Performance art', 'Sculpture'].includes(editArtworkForm.get('editArtworkType').value)" -->
              <div class="uk-width-1-2@s uk-width-1-3@m">
                <div class="uk-margin">
                  <label class="uk-form-label" for="editArtworkTechnique">Technique</label>
                  <div class="uk-form-controls">
                    <!--<ng2-completer id="editArtworkTechnique" name="editArtworkTechnique" formControlName="editArtworkTechnique" [(ngModel)]="activeArtwork.technique" [datasource]="variables.techniques" [minSearchLength]="0" [textNoResults]="null"></ng2-completer>-->
                    <ng-select [items]="variables.techniques"
                               [addTag]="true"
                               [selectOnTab]="false"
                               addTagText="Add new: "
                               placeholder="Please select ..."
                               bindLabel=""
                               id="editArtworkTechnique"
                               formControlName="editArtworkTechnique"
                               class="uk-select"
                               #editArtworkTechnique (change)="editArtworkTechnique.blur()">
                    </ng-select>
                  </div>
                </div>
              </div>
              <div class="uk-width-1-2@s uk-width-1-3@m" *ngIf="['Sculpture'].includes(editArtworkForm.get('editArtworkType').value)">
                <div class="uk-margin">
                  <label class="uk-form-label" for="editArtworkMaterial">Material</label>
                  <div class="uk-form-controls">
                    <!--<ng2-completer id="editArtworkMaterial" name="editArtworkMaterial" formControlName="editArtworkMaterial" [(ngModel)]="activeArtwork.material" [datasource]="variables.materials" [minSearchLength]="0" [textNoResults]="null"></ng2-completer>-->
                    <ng-select [items]="variables.materials"
                               [addTag]="true"
                               [selectOnTab]="false"
                               addTagText="Add new: "
                               placeholder="Please select ..."
                               bindLabel=""
                               id="editArtworkMaterial"
                               formControlName="editArtworkMaterial"
                               class="uk-select"
                               #editArtworkMaterial (change)="editArtworkMaterial.blur()">
                    </ng-select>
                  </div>
                </div>
              </div>
              <div class="uk-width-1-2@s uk-width-1-3@m" *ngIf="['Painting', 'Photography', 'Print', 'Work on paper', 'Design', 'Drawing', 'Jewelry', 'Performance art', 'Sculpture', 'Mixed media', 'Other'].includes(editArtworkForm.get('editArtworkType').value)">
                <div class="uk-margin">
                  <label class="uk-form-label" for="editArtworkHeight">Height</label>
                  <div class="uk-form-controls">
                    <input id="editArtworkHeight" name="editArtworkHeight" formControlName="editArtworkHeight" [ngModel]="activeArtwork.height" type="text" placeholder="Height ..." class="uk-input" [ngClass]="{'uk-form-danger': editArtworkForm.get('editArtworkHeight').touched && (editArtworkForm.get('editArtworkHeight').hasError('required') || editArtworkForm.get('editArtworkHeight').hasError('maxlength') || editArtworkForm.get('editArtworkHeight').hasError('pattern')) }">
                    <div class="measurement-unit-container">
                      <span class="uk-badge uk-background-muted uk-text-muted">{{ account.measurementUnit }}</span>
                    </div>
                    <div class="uk-text-danger uk-text-small"
                          *ngIf="editArtworkForm.get('editArtworkHeight').hasError('maxlength')"
                    >
                      {{ getCharactersLimitError('editArtworkHeight') }}
                    </div>
                    <div class="uk-text-danger uk-text-small"
                         *ngIf="editArtworkForm.get('editArtworkHeight').hasError('pattern')"
                    >
                      Field value is incorrect
                    </div>
                  </div>
                </div>
              </div>
              <div class="uk-width-1-2@s uk-width-1-3@m" *ngIf="['Painting', 'Photography', 'Print', 'Work on paper', 'Design', 'Drawing','Jewelry', 'Performance art', 'Sculpture', 'Mixed media', 'Other'].includes(editArtworkForm.get('editArtworkType').value)">
                <div class="uk-margin">
                  <label class="uk-form-label" for="editArtworkWidth">Width</label>
                  <div class="uk-form-controls">
                    <input id="editArtworkWidth" name="editArtworkWidth" formControlName="editArtworkWidth" [ngModel]="activeArtwork.width" type="text" placeholder="Width ..." class="uk-input" [ngClass]="{'uk-form-danger': editArtworkForm.get('editArtworkWidth').touched && (editArtworkForm.get('editArtworkWidth').hasError('required') || editArtworkForm.get('editArtworkWidth').hasError('maxlength') || editArtworkForm.get('editArtworkWidth').hasError('pattern')) }">
                    <div class="measurement-unit-container">
                      <span class="uk-badge uk-background-muted uk-text-muted">{{ account.measurementUnit }}</span>
                    </div>
                    <div class="uk-text-danger uk-text-small"
                          *ngIf="editArtworkForm.get('editArtworkWidth').hasError('maxlength')"
                    >
                      {{ getCharactersLimitError('editArtworkWidth') }}
                    </div>
                    <div class="uk-text-danger uk-text-small"
                         *ngIf="editArtworkForm.get('editArtworkWidth').hasError('pattern')"
                    >
                      Field value is incorrect
                    </div>
                  </div>
                </div>
              </div>
              <div class="uk-width-1-2@s uk-width-1-3@m" *ngIf="['Painting', 'Photography', 'Print', 'Work on paper', 'Design', 'Drawing','Jewelry', 'Performance art', 'Sculpture', 'Mixed media', 'Other'].includes(editArtworkForm.get('editArtworkType').value)">
                <div class="uk-margin">
                  <label class="uk-form-label" for="editArtworkDepth">Depth</label>
                  <div class="uk-form-controls">
                    <input id="editArtworkDepth" name="editArtworkDepth" formControlName="editArtworkDepth" [ngModel]="activeArtwork.depth" type="text" placeholder="Depth ..." class="uk-input" [ngClass]="{'uk-form-danger': editArtworkForm.get('editArtworkDepth').touched && (editArtworkForm.get('editArtworkDepth').hasError('required') || editArtworkForm.get('editArtworkDepth').hasError('maxlength') || editArtworkForm.get('editArtworkDepth').hasError('pattern')) }">
                    <div class="measurement-unit-container">
                      <span class="uk-badge uk-background-muted uk-text-muted">{{ account.measurementUnit }}</span>
                    </div>
                    <div class="uk-text-danger uk-text-small"
                          *ngIf="editArtworkForm.get('editArtworkDepth').hasError('maxlength')"
                    >
                      {{ getCharactersLimitError('editArtworkDepth') }}
                    </div>
                    <div class="uk-text-danger uk-text-small"
                         *ngIf="editArtworkForm.get('editArtworkDepth').hasError('pattern')"
                    >
                      Field value is incorrect
                    </div>
                  </div>
                </div>
              </div>
              <div class="uk-width-1-2@s uk-width-1-3@m" *ngIf="['Sculpture'].includes(editArtworkForm.get('editArtworkType').value)">
                <div class="uk-margin">
                  <label class="uk-form-label" for="editArtworkWeight">Weight</label>
                  <div class="uk-form-controls">
                    <input id="editArtworkWeight" name="editArtworkWeight" formControlName="editArtworkWeight" [ngModel]="activeArtwork.weight" type="text" placeholder="Weight ..." class="uk-input" [ngClass]="{'uk-form-danger': editArtworkForm.get('editArtworkWeight').touched && (editArtworkForm.get('editArtworkWeight').hasError('required') || editArtworkForm.get('editArtworkWeight').hasError('maxlength') || editArtworkForm.get('editArtworkWeight').hasError('pattern')) }">
                    <div class="measurement-unit-container">
                      <span class="uk-badge uk-background-muted uk-text-muted">g</span>
                      <span class="uk-badge uk-background-muted uk-text-muted">g</span>
                    </div>
                    <div class="uk-text-danger uk-text-small"
                          *ngIf="editArtworkForm.get('editArtworkWeight').hasError('maxlength')"
                    >
                      {{ getCharactersLimitError('editArtworkWeight') }}
                    </div>
                    <div class="uk-text-danger uk-text-small"
                         *ngIf="editArtworkForm.get('editArtworkWeight').hasError('pattern')"
                    >
                      Field value is incorrect
                    </div>
                  </div>
                </div>
              </div>
              <div class="uk-width-1-2@s uk-width-1-3@m" *ngIf="['Painting', 'Photography', 'Print', 'Work on paper', 'Design', 'Drawing','Jewelry', 'Performance art', 'Mixed media', 'Other'].includes(editArtworkForm.get('editArtworkType').value)">
                <div class="uk-margin">
                  <label class="uk-form-label" for="editArtworkFrame">Frame</label>
                  <div class="uk-form-controls">
                    <label><input id="editArtworkFrame" name="editArtworkFrame" formControlName="editArtworkFrame" class="uk-checkbox" [ngModel]="activeArtwork.frame" type="checkbox"> This artwork has a frame</label>
                  </div>
                </div>
              </div>
              <div class="uk-width-1-2@s uk-width-1-3@m" *ngIf="['Painting', 'Photography', 'Print', 'Work on paper', 'Design', 'Drawing','Jewelry', 'Performance art', 'Mixed media', 'Other'].includes(editArtworkForm.get('editArtworkType').value) && editArtworkForm.get('editArtworkFrame').value">
                <div class="uk-margin">
                  <label class="uk-form-label" for="editArtworkFrameHeight">Frame height</label>
                  <div class="uk-form-controls">
                    <input id="editArtworkFrameHeight" name="editArtworkFrameHeight" formControlName="editArtworkFrameHeight" [ngModel]="activeArtwork.frameHeight" type="text" placeholder="Frame height ..." class="uk-input" [ngClass]="{'uk-form-danger': editArtworkForm.get('editArtworkFrameHeight').touched && (editArtworkForm.get('editArtworkFrameHeight').hasError('required') || editArtworkForm.get('editArtworkFrameHeight').hasError('maxlength') || editArtworkForm.get('editArtworkFrameHeight').hasError('pattern')) }">
                    <div class="measurement-unit-container">
                      <span class="uk-badge uk-background-muted uk-text-muted">{{ account.measurementUnit }}</span>
                    </div>
                    <div class="uk-text-danger uk-text-small"
                          *ngIf="editArtworkForm.get('editArtworkFrameHeight').hasError('maxlength')"
                    >
                      {{ getCharactersLimitError('editArtworkFrameHeight') }}
                    </div>
                    <div class="uk-text-danger uk-text-small"
                         *ngIf="editArtworkForm.get('editArtworkFrameHeight').hasError('pattern')"
                    >
                      Field value is incorrect
                    </div>
                  </div>
                </div>
              </div>
              <div class="uk-width-1-2@s uk-width-1-3@m" *ngIf="['Painting', 'Photography', 'Print', 'Work on paper', 'Design', 'Drawing','Jewelry', 'Performance art', 'Mixed media', 'Other'].includes(editArtworkForm.get('editArtworkType').value) && editArtworkForm.get('editArtworkFrame').value">
                <div class="uk-margin">
                  <label class="uk-form-label" for="editArtworkFrameWidth">Frame width</label>
                  <div class="uk-form-controls">
                    <input id="editArtworkFrameWidth" name="editArtworkFrameWidth" formControlName="editArtworkFrameWidth" [ngModel]="activeArtwork.frameWidth" type="text" placeholder="Frame width ..." class="uk-input" [ngClass]="{'uk-form-danger': editArtworkForm.get('editArtworkFrameWidth').touched && (editArtworkForm.get('editArtworkFrameWidth').hasError('required') || editArtworkForm.get('editArtworkFrameWidth').hasError('maxlength') || editArtworkForm.get('editArtworkFrameWidth').hasError('pattern')) }">
                    <div class="measurement-unit-container">
                      <span class="uk-badge uk-background-muted uk-text-muted">{{ account.measurementUnit }}</span>
                    </div>
                    <div class="uk-text-danger uk-text-small"
                          *ngIf="editArtworkForm.get('editArtworkFrameWidth').hasError('maxlength')"
                    >
                      {{ getCharactersLimitError('editArtworkFrameWidth') }}
                    </div>
                    <div class="uk-text-danger uk-text-small"
                          *ngIf="editArtworkForm.get('editArtworkFrameWidth').hasError('pattern')"
                    >
                      Field value is incorrect
                    </div>
                  </div>
                </div>
              </div>
              <div class="uk-width-1-2@s uk-width-1-3@m" *ngIf="['Painting', 'Photography', 'Print', 'Work on paper', 'Design', 'Drawing','Jewelry', 'Performance art', 'Mixed media', 'Other'].includes(editArtworkForm.get('editArtworkType').value) && editArtworkForm.get('editArtworkFrame').value">
                <div class="uk-margin">
                  <label class="uk-form-label" for="editArtworkFrameDepth">Frame depth</label>
                  <div class="uk-form-controls">
                    <input id="editArtworkFrameDepth" name="editArtworkFrameDepth" formControlName="editArtworkFrameDepth" [ngModel]="activeArtwork.frameDepth" type="text" placeholder="Frame depth ..." class="uk-input" [ngClass]="{'uk-form-danger': editArtworkForm.get('editArtworkFrameDepth').touched && (editArtworkForm.get('editArtworkFrameDepth').hasError('required') || editArtworkForm.get('editArtworkFrameDepth').hasError('maxlength') || editArtworkForm.get('editArtworkFrameDepth').hasError('pattern')) }">
                    <div class="measurement-unit-container">
                      <span class="uk-badge uk-background-muted uk-text-muted">{{ account.measurementUnit }}</span>
                    </div>
                    <div class="uk-text-danger uk-text-small"
                          *ngIf="editArtworkForm.get('editArtworkFrameDepth').hasError('maxlength')"
                    >
                      {{ getCharactersLimitError('editArtworkFrameDepth') }}
                    </div>
                    <div class="uk-text-danger uk-text-small"
                         *ngIf="editArtworkForm.get('editArtworkFrameDepth').hasError('pattern')"
                    >
                      Field value is incorrect
                    </div>
                  </div>
                </div>
              </div>
              <div class="uk-width-1-2@s uk-width-1-3@m" *ngIf="['Video'].includes(editArtworkForm.get('editArtworkType').value)">
                <div class="uk-margin">
                  <label class="uk-form-label" for="editArtworkDuration">Duration</label>
                  <div class="uk-form-controls">
                    <input id="editArtworkDuration" name="editArtworkDuration" formControlName="editArtworkDuration" [ngModel]="activeArtwork.duration" type="text" placeholder="Duration ..." class="uk-input" [ngClass]="{'uk-form-danger': editArtworkForm.get('editArtworkDuration').touched && (editArtworkForm.get('editArtworkDuration').hasError('required') || editArtworkForm.get('editArtworkDuration').hasError('maxlength') || editArtworkForm.get('editArtworkDuration').hasError('pattern')) }">
                    <div class="measurement-unit-container">
                      <span class="uk-badge uk-background-muted uk-text-muted">mm:ss</span>
                    </div>
                    <div class="uk-text-danger uk-text-small"
                          *ngIf="editArtworkForm.get('editArtworkDuration').hasError('maxlength')"
                    >
                      {{ getCharactersLimitError('editArtworkDuration') }}
                    </div>
                    <div class="uk-text-danger uk-text-small"
                         *ngIf="editArtworkForm.get('editArtworkDuration').hasError('pattern')"
                    >
                      Field value is incorrect
                    </div>
                  </div>
                </div>
              </div>
              <div class="uk-width-1-2@s uk-width-1-3@m">
                <div class="uk-margin">
                  <label class="uk-form-label" for="editArtworkCreated">Creation year</label>
                  <div class="uk-form-controls">
                    <input id="editArtworkCreated" name="editArtworkCreated" formControlName="editArtworkCreated" [ngModel]="activeArtwork.created" type="text" placeholder="Creation year ..." class="uk-input" [ngClass]="{'uk-form-danger': editArtworkForm.get('editArtworkCreated').touched && (editArtworkForm.get('editArtworkCreated').hasError('required') || editArtworkForm.get('editArtworkCreated').hasError('maxlength')) }">
                    <div class="uk-text-danger uk-text-small"
                          *ngIf="editArtworkForm.get('editArtworkCreated').hasError('maxlength')"
                    >
                      {{ getCharactersLimitError('editArtworkCreated') }}
                    </div>
                    <div class="uk-text-danger uk-text-small"
                         *ngIf="editArtworkForm.get('editArtworkCreated').hasError('pattern')"
                    >
                      Field value is incorrect
                    </div>
                  </div>
                </div>
              </div>
              <div class="uk-width-1-2@s uk-width-1-3@m">
                <div class="uk-margin">
                  <label class="uk-form-label" for="editArtworkStarted">Creation started</label>
                  <div class="uk-form-controls">
                    <input id="editArtworkStarted" name="editArtworkStarted" formControlName="editArtworkStarted" [ngModel]="activeArtwork.started" type="text" placeholder="Creation started year ..." class="uk-input" [ngClass]="{'uk-form-danger': editArtworkForm.get('editArtworkStarted').touched && (editArtworkForm.get('editArtworkStarted').hasError('required') || editArtworkForm.get('editArtworkStarted').hasError('maxlength')) }">
                    <div class="uk-text-danger uk-text-small"
                          *ngIf="editArtworkForm.get('editArtworkStarted').hasError('maxlength')"
                    >
                      {{ getCharactersLimitError('editArtworkStarted') }}
                    </div>
                    <div class="uk-text-danger uk-text-small"
                         *ngIf="editArtworkForm.get('editArtworkStarted').hasError('pattern')"
                    >
                      Field value is incorrect
                    </div>
                  </div>
                </div>
              </div>
              <div class="uk-width-1-2@s uk-width-1-3@m">
                <div class="uk-margin">
                  <label class="uk-form-label" for="editArtworkSRP">Suggested retail price</label>
                  <div class="uk-form-controls">
                    <input id="editArtworkSRP" name="editArtworkSRP" formControlName="editArtworkSRP" [ngModel]="activeArtwork.srp?.price" type="text" placeholder="Suggested retail price ..." class="uk-input" [ngClass]="{'uk-form-danger': editArtworkForm.get('editArtworkSRP').touched && (editArtworkForm.get('editArtworkSRP').hasError('required') || editArtworkForm.get('editArtworkSRP').hasError('maxlength') || editArtworkForm.get('editArtworkSRP').hasError('pattern')) }">
                    <div class="uk-text-danger uk-text-small"
                          *ngIf="editArtworkForm.get('editArtworkSRP').hasError('maxlength')"
                    >
                      {{ getCharactersLimitError('editArtworkSRP') }}
                    </div>
                    <div class="uk-text-danger uk-text-small"
                         *ngIf="editArtworkForm.get('editArtworkSRP').hasError('pattern')"
                    >
                      Field value is incorrect
                    </div>
                  </div>
                </div>
              </div>
              <div class="uk-width-1-2@s uk-width-1-3@m">
                <div class="uk-margin">
                  <label class="uk-form-label" for="editArtworkPrice">Price</label>
                  <div class="uk-form-controls">
                    <input id="editArtworkPrice" name="editArtworkPrice" formControlName="editArtworkPrice" [ngModel]="activeArtwork.price?.price" type="text" placeholder="Price ..." class="uk-input" [ngClass]="{'uk-form-danger': editArtworkForm.get('editArtworkPrice').touched && (editArtworkForm.get('editArtworkPrice').hasError('required') || editArtworkForm.get('editArtworkPrice').hasError('maxlength') || editArtworkForm.get('editArtworkPrice').hasError('pattern')) }">
                    <div class="measurement-unit-container">
                      <span class="uk-badge uk-background-muted uk-text-muted">{{ account.tradingCurrency | lowercase }}</span>
                    </div>
                    <div class="uk-text-danger uk-text-small"
                          *ngIf="editArtworkForm.get('editArtworkPrice').hasError('maxlength')"
                    >
                      {{ getCharactersLimitError('editArtworkPrice') }}
                    </div>
                    <div class="uk-text-danger uk-text-small"
                         *ngIf="editArtworkForm.get('editArtworkPrice').hasError('pattern')"
                    >
                      Field value is incorrect
                    </div>
                  </div>
                </div>
              </div>
              <div class="uk-width-1-1 uk-margin-medium-top" *ngIf="!(['gallery', 'artist'].includes(account.type))">
                <h3 class="uk-margin-remove-bottom">Purchase information</h3>
                <hr class="uk-margin-small-top">
              </div>
              <div class="uk-width-1-2@s uk-width-1-3@m" *ngIf="!(['gallery', 'artist'].includes(account.type))">
                <div class="uk-margin">
                  <label class="uk-form-label" for="editArtworkSeller">Seller</label>
                  <div class="uk-form-controls">
                    <input id="editArtworkSeller" name="editArtworkSeller" formControlName="editArtworkSeller" [ngModel]="activeArtwork.seller" type="text" placeholder="Seller ..." class="uk-input" [ngClass]="{'uk-form-danger': editArtworkForm.get('editArtworkSeller').touched && (editArtworkForm.get('editArtworkSeller').hasError('required') || editArtworkForm.get('editArtworkSeller').hasError('maxlength')) }">
                    <div class="uk-text-danger uk-text-small"
                          *ngIf="editArtworkForm.get('editArtworkSeller').hasError('maxlength')"
                    >
                      {{ getCharactersLimitError('editArtworkSeller') }}
                    </div>
                  </div>
                </div>
              </div>
              <div class="uk-width-1-2@s uk-width-1-3@m" *ngIf="!(['gallery', 'artist'].includes(account.type))">
                <div class="uk-margin">
                  <div class="uk-flex uk-flex-between uk-text-meta">
                    <label class="uk-form-label" for="editArtworkSellerContactInfo">Seller contact information</label>
                    <div class="uk-text-small">
                      {{ getCharactersLeft('editArtworkSellerContactInfo', 2048) }}
                    </div>
                  </div>
                  <div class="uk-form-controls">
                    <textarea id="editArtworkSellerContactInfo" name="editArtworkSellerContactInfo" formControlName="editArtworkSellerContactInfo" [ngModel]="activeArtwork.sellerContactInfo" rows="5" placeholder="Seller contact information ..." class="uk-textarea" [ngClass]="{'uk-form-danger': editArtworkForm.get('editArtworkSellerContactInfo').touched && (editArtworkForm.get('editArtworkSellerContactInfo').hasError('required') || editArtworkForm.get('editArtworkSellerContactInfo').hasError('maxlength')) }"></textarea>
                    <div class="uk-text-danger uk-text-small"
                          *ngIf="editArtworkForm.get('editArtworkSellerContactInfo').hasError('maxlength')"
                    >
                      {{ getCharactersLimitError('editArtworkSellerContactInfo') }}
                    </div>
                  </div>
                </div>
              </div>
              <div class="uk-width-1-2@s uk-width-1-3@m" *ngIf="!(['gallery', 'artist'].includes(account.type))">
                <div class="uk-margin">
                  <label class="uk-form-label" for="editArtworkPurchaseDate">Purchase date</label>
                  <div class="uk-form-controls">
                    <input
                      id="editArtworkPurchaseDate"
                      name="editArtworkPurchaseDate"
                      formControlName="editArtworkPurchaseDate"
                      [ngModel]="activeArtwork.purchaseDate"
                      type="text"
                      placeholder="Purchase date ..."
                      class="uk-input"
                      [ngClass]="{'uk-form-danger': editArtworkForm.get('editArtworkPurchaseDate').touched && (editArtworkForm.get('editArtworkPurchaseDate').hasError('required') || editArtworkForm.get('editArtworkPurchaseDate').hasError('maxlength')) }"
                      angular-mydatepicker
                      [options]="myDatePickerOptions"
                      #AngularMyDatePickerEditArtworkPurchaseDate="angular-mydatepicker"
                      (click)="AngularMyDatePickerEditArtworkPurchaseDate.toggleCalendar()"
                      (dateChanged)="updateDate('editArtworkPurchaseDate', $event)"
                    />
                    <div class="uk-text-danger uk-text-small"
                          *ngIf="editArtworkForm.get('editArtworkPurchaseDate').hasError('maxlength')"
                    >
                      {{ getCharactersLimitError('editArtworkPurchaseDate') }}
                    </div>
                  </div>
                </div>
              </div>
              <div class="uk-width-1-2@s uk-width-1-3@m" *ngIf="!(['gallery', 'artist'].includes(account.type))">
                <div class="uk-margin">
                  <label class="uk-form-label" for="editArtworkPurchasePrice">Purchase price</label>
                  <div class="uk-form-controls">
                    <input id="editArtworkPurchasePrice" name="editArtworkPurchasePrice" formControlName="editArtworkPurchasePrice" [ngModel]="activeArtwork.purchasePrice" type="text" placeholder="Purchase price ..." class="uk-input" [ngClass]="{'uk-form-danger': editArtworkForm.get('editArtworkPurchasePrice').touched && (editArtworkForm.get('editArtworkPurchasePrice').hasError('required') || editArtworkForm.get('editArtworkPurchasePrice').hasError('maxlength') || editArtworkForm.get('editArtworkPurchasePrice').hasError('pattern')) }">
                    <div class="uk-text-danger uk-text-small"
                          *ngIf="editArtworkForm.get('editArtworkPurchasePrice').hasError('maxlength')"
                    >
                      {{ getCharactersLimitError('editArtworkPurchasePrice') }}
                    </div>
                    <div class="uk-text-danger uk-text-small"
                         *ngIf="editArtworkForm.get('editArtworkPurchasePrice').hasError('pattern')"
                    >
                      Field value is incorrect
                    </div>
                  </div>
                </div>
              </div>
              <div class="uk-width-1-1 uk-margin-medium-top" *ngIf="!(['gallery', 'artist'].includes(account.type))">
                <h3 class="uk-margin-remove-bottom">Estimate information</h3>
                <hr class="uk-margin-small-top">
              </div>
              <div class="uk-width-1-2@s uk-width-1-3@m" *ngIf="!(['gallery', 'artist'].includes(account.type))">
                <div class="uk-margin">
                  <label class="uk-form-label" for="editArtworkMinimumPrice">Minimum price</label>
                  <div class="uk-form-controls">
                    <input id="editArtworkMinimumPrice" name="editArtworkMinimumPrice" formControlName="editArtworkMinimumPrice" [ngModel]="activeArtwork.minimumPrice" type="text" placeholder="Minimum price ..." class="uk-input" [ngClass]="{'uk-form-danger': editArtworkForm.get('editArtworkMinimumPrice').touched && (editArtworkForm.get('editArtworkMinimumPrice').hasError('required') || editArtworkForm.get('editArtworkMinimumPrice').hasError('maxlength') || editArtworkForm.get('editArtworkMinimumPrice').hasError('pattern')) }">
                    <div class="uk-text-danger uk-text-small"
                          *ngIf="editArtworkForm.get('editArtworkMinimumPrice').hasError('maxlength')"
                    >
                      {{ getCharactersLimitError('editArtworkMinimumPrice') }}
                    </div>
                    <div class="uk-text-danger uk-text-small"
                         *ngIf="editArtworkForm.get('editArtworkMinimumPrice').hasError('pattern')"
                    >
                      Field value is incorrect
                    </div>
                  </div>
                </div>
              </div>
              <div class="uk-width-1-2@s uk-width-1-3@m" *ngIf="!(['gallery', 'artist'].includes(account.type))">
                <div class="uk-margin">
                  <label class="uk-form-label" for="editArtworkMaximumPrice">Maximum price</label>
                  <div class="uk-form-controls">
                    <input id="editArtworkMaximumPrice" name="editArtworkMaximumPrice" formControlName="editArtworkMaximumPrice" [ngModel]="activeArtwork.maximumPrice" type="text" placeholder="Maximum price ..." class="uk-input" [ngClass]="{'uk-form-danger': editArtworkForm.get('editArtworkMaximumPrice').touched && (editArtworkForm.get('editArtworkMaximumPrice').hasError('required') || editArtworkForm.get('editArtworkMaximumPrice').hasError('maxlength') || editArtworkForm.get('editArtworkMaximumPrice').hasError('pattern')) }">
                    <div class="uk-text-danger uk-text-small"
                          *ngIf="editArtworkForm.get('editArtworkMaximumPrice').hasError('maxlength')"
                    >
                      {{ getCharactersLimitError('editArtworkMaximumPrice') }}
                    </div>
                    <div class="uk-text-danger uk-text-small"
                         *ngIf="editArtworkForm.get('editArtworkMaximumPrice').hasError('pattern')"
                    >
                      Field value is incorrect
                    </div>
                  </div>
                </div>
              </div>
              <div class="uk-width-1-2@s uk-width-1-3@m" *ngIf="!(['gallery', 'artist'].includes(account.type))">
                <div class="uk-margin">
                  <label class="uk-form-label" for="editArtworkEstimateBasis">Estimate basis</label>
                  <div class="uk-form-controls">
                    <!--<ng2-completer id="editArtworkEstimateBasis" name="editArtworkEstimateBasis" formControlName="editArtworkEstimateBasis" [(ngModel)]="activeArtwork.estimateBasis" [datasource]="variables.estimateBasis" [minSearchLength]="0" [textNoResults]="null"></ng2-completer>-->
                    <ng-select [items]="variables.estimateBasis"
                               [addTag]="true"
                               [selectOnTab]="false"
                               addTagText="Add new: "
                               placeholder="Please select ..."
                               bindLabel=""
                               id="editArtworkEstimateBasis"
                               formControlName="editArtworkEstimateBasis"
                               class="uk-select"
                               #editArtworkEstimateBasis (change)="editArtworkEstimateBasis.blur()">
                    </ng-select>
                    <div class="uk-text-danger uk-text-small"
                          *ngIf="editArtworkForm.get('editArtworkEstimateBasis').hasError('maxlength')"
                    >
                      {{ getCharactersLimitError('editArtworkEstimateBasis') }}
                    </div>
                  </div>
                </div>
              </div>
              <div class="uk-width-1-2@s uk-width-1-3@m" *ngIf="!(['gallery', 'artist'].includes(account.type))">
                <div class="uk-margin">
                  <label class="uk-form-label" for="editArtworkEstimator">Estimator</label>
                  <div class="uk-form-controls">
                    <input id="editArtworkEstimator" name="editArtworkEstimator" formControlName="editArtworkEstimator" [ngModel]="activeArtwork.estimator" type="text" placeholder="Estimator ..." class="uk-input" [ngClass]="{'uk-form-danger': editArtworkForm.get('editArtworkEstimator').touched && (editArtworkForm.get('editArtworkEstimator').hasError('required') || editArtworkForm.get('editArtworkEstimator').hasError('maxlength')) }">
                    <div class="uk-text-danger uk-text-small"
                          *ngIf="editArtworkForm.get('editArtworkEstimator').hasError('maxlength')"
                    >
                      {{ getCharactersLimitError('editArtworkEstimator') }}
                    </div>
                  </div>
                </div>
              </div>
              <div class="uk-width-1-2@s uk-width-1-3@m" *ngIf="!(['gallery', 'artist'].includes(account.type))">
                <div class="uk-margin">
                  <div class="uk-flex uk-flex-between uk-text-meta">
                    <label class="uk-form-label" for="editArtworkEstimatorInformation">Estimator information</label>
                    <div class="uk-text-small">
                      {{ getCharactersLeft('editArtworkEstimatorInformation', 2048) }}
                    </div>
                  </div>
                  <div class="uk-form-controls">
                    <textarea id="editArtworkEstimatorInformation" name="editArtworkEstimatorInformation" formControlName="editArtworkEstimatorInformation" [ngModel]="activeArtwork.estimatorInformation" rows="5" type="text" placeholder="Estimator information ..." class="uk-textarea" [ngClass]="{'uk-form-danger': editArtworkForm.get('editArtworkEstimatorInformation').touched && (editArtworkForm.get('editArtworkEstimatorInformation').hasError('required') || editArtworkForm.get('editArtworkEstimatorInformation').hasError('maxlength')) }"></textarea>
                    <div class="uk-text-danger uk-text-small"
                          *ngIf="editArtworkForm.get('editArtworkEstimatorInformation').hasError('maxlength')"
                    >
                      {{ getCharactersLimitError('editArtworkEstimatorInformation') }}
                    </div>
                  </div>
                </div>
              </div>
              <div class="uk-width-1-2@s uk-width-1-3@m" *ngIf="!(['gallery', 'artist'].includes(account.type))">
                <div class="uk-margin">
                  <label class="uk-form-label" for="editArtworkCollateralValue">Collateral value</label>
                  <div class="uk-form-controls">
                    <input id="editArtworkCollateralValue" name="editArtworkCollateralValue" formControlName="editArtworkCollateralValue" [ngModel]="activeArtwork.collateralValue" type="text" placeholder="Collateral value ..." class="uk-input" [ngClass]="{'uk-form-danger': editArtworkForm.get('editArtworkCollateralValue').touched && (editArtworkForm.get('editArtworkCollateralValue').hasError('required') || editArtworkForm.get('editArtworkCollateralValue').hasError('maxlength')) }">
                    <div class="uk-text-danger uk-text-small"
                          *ngIf="editArtworkForm.get('editArtworkCollateralValue').hasError('maxlength')"
                    >
                      {{ getCharactersLimitError('editArtworkCollateralValue') }}
                    </div>
                    <div class="uk-text-danger uk-text-small"
                         *ngIf="editArtworkForm.get('editArtworkCollateralValue').hasError('pattern')"
                    >
                      Field value is incorrect
                    </div>
                  </div>
                </div>
              </div>
              <div class="uk-width-1-1 uk-margin-medium-top" *ngIf="!(['gallery', 'artist'].includes(account.type))">
                <h3 class="uk-margin-remove-bottom">Conservation information</h3>
                <hr class="uk-margin-small-top">
              </div>
              <div class="uk-width-1-2@s uk-width-1-3@m" *ngIf="!(['gallery', 'artist'].includes(account.type))">
                <div class="uk-margin">
                  <label class="uk-form-label" for="editArtworkConservationDate">Conservation date</label>
                  <div class="uk-form-controls">
                    <input
                      id="editArtworkConservationDate"
                      name="editArtworkConservationDate"
                      formControlName="editArtworkConservationDate"
                      [ngModel]="activeArtwork.conservationDate"
                      type="text"
                      placeholder="Conservation date ..."
                      class="uk-input"
                      [ngClass]="{'uk-form-danger': editArtworkForm.get('editArtworkConservationDate').touched && (editArtworkForm.get('editArtworkConservationDate').hasError('required') || editArtworkForm.get('editArtworkConservationDate').hasError('maxlength')) }"
                      angular-mydatepicker
                      [options]="myDatePickerOptions"
                      #AngularMyDatePickerEditArtworkPurchaseDate="angular-mydatepicker"
                      (click)="AngularMyDatePickerEditArtworkPurchaseDate.toggleCalendar()"
                      (dateChanged)="updateDate('editArtworkConservationDate', $event)"
                    />
                    <div class="uk-text-danger uk-text-small"
                          *ngIf="editArtworkForm.get('editArtworkConservationDate').hasError('maxlength')"
                    >
                      {{ getCharactersLimitError('editArtworkConservationDate') }}
                    </div>
                  </div>
                </div>
              </div>
              <div class="uk-width-1-2@s uk-width-1-3@m" *ngIf="!(['gallery', 'artist'].includes(account.type))">
                <div class="uk-margin">
                  <label class="uk-form-label" for="editArtworkConservationCompany">Conservator company</label>
                  <div class="uk-form-controls">
                    <input id="editArtworkConservationCompany" name="editArtworkConservationCompany" formControlName="editArtworkConservationCompany" [ngModel]="activeArtwork.conservationCompany" type="text" placeholder="Conservator company ..." class="uk-input" [ngClass]="{'uk-form-danger': editArtworkForm.get('editArtworkConservationCompany').touched && (editArtworkForm.get('editArtworkConservationCompany').hasError('required') || editArtworkForm.get('editArtworkConservationCompany').hasError('maxlength')) }">
                    <div class="uk-text-danger uk-text-small"
                          *ngIf="editArtworkForm.get('editArtworkConservationCompany').hasError('maxlength')"
                    >
                      {{ getCharactersLimitError('editArtworkConservationCompany') }}
                    </div>
                  </div>
                </div>
              </div>
              <div class="uk-width-1-2@s uk-width-1-3@m" *ngIf="!(['gallery', 'artist'].includes(account.type))">
                <div class="uk-margin">
                  <label class="uk-form-label" for="editArtworkConservationName">Conservator name</label>
                  <div class="uk-form-controls">
                    <input id="editArtworkConservationName" name="editArtworkConservationName" formControlName="editArtworkConservationName" [ngModel]="activeArtwork.conservationName" type="text" placeholder="Estimator ..." class="uk-input" [ngClass]="{'uk-form-danger': editArtworkForm.get('editArtworkConservationName').touched && (editArtworkForm.get('editArtworkConservationName').hasError('required') || editArtworkForm.get('editArtworkConservationName').hasError('maxlength')) }">
                    <div class="uk-text-danger uk-text-small"
                          *ngIf="editArtworkForm.get('editArtworkConservationName').hasError('maxlength')"
                    >
                      {{ getCharactersLimitError('editArtworkConservationName') }}
                    </div>
                  </div>
                </div>
              </div>
              <div class="uk-width-1-2@s uk-width-1-3@m" *ngIf="!(['gallery', 'artist'].includes(account.type))">
                <div class="uk-margin">
                  <div class="uk-flex uk-flex-between uk-text-meta">
                    <label class="uk-form-label" for="editArtworkConservationDetails">Conservation details</label>
                    <div class="uk-text-small">
                      {{ getCharactersLeft('editArtworkConservationDetails', 2048) }}
                    </div>
                  </div>
                  <div class="uk-form-controls">
                    <textarea id="editArtworkConservationDetails" name="editArtworkConservationDetails" formControlName="editArtworkConservationDetails" [ngModel]="activeArtwork.conservationDetails" rows="5" type="text" placeholder="Estimator information ..." class="uk-textarea" [ngClass]="{'uk-form-danger': editArtworkForm.get('editArtworkConservationDetails').touched && (editArtworkForm.get('editArtworkConservationDetails').hasError('required') || editArtworkForm.get('editArtworkConservationDetails').hasError('maxlength')) }"></textarea>
                    <div class="uk-text-danger uk-text-small"
                          *ngIf="editArtworkForm.get('editArtworkConservationDetails').hasError('maxlength')"
                    >
                      {{ getCharactersLimitError('editArtworkConservationDetails') }}
                    </div>
                  </div>
                </div>
              </div>
              <div class="uk-width-1-1 uk-margin-medium-top">
                <h3 class="uk-margin-remove-bottom">Notes in print</h3>
                <hr class="uk-margin-small-top">
              </div>
              <div class="uk-width-1-2@s uk-width-1-3@m">
                <div class="uk-margin">
                  <div class="uk-flex uk-flex-between uk-text-meta">
                    <label class="uk-form-label" for="editArtworkListNotes">Notes</label>
                    <div class="uk-text-small">
                      {{ getCharactersLeft('editArtworkListNotes', 2048) }}
                    </div>
                  </div>
                  <div class="uk-form-controls">
                    <textarea id="editArtworkListNotes" name="editArtworkListNotes" formControlName="editArtworkListNotes" rows="5" [ngModel]="activeArtwork.listNotes" type="text" placeholder="Notes shown on list ..." class="uk-textarea" [ngClass]="{'uk-form-danger': editArtworkForm.get('editArtworkListNotes').touched && (editArtworkForm.get('editArtworkListNotes').hasError('required') || editArtworkForm.get('editArtworkListNotes').hasError('maxlength')) }"></textarea>
                    <div class="uk-text-danger uk-text-small"
                          *ngIf="editArtworkForm.get('editArtworkListNotes').hasError('maxlength')"
                    >
                      {{ getCharactersLimitError('editArtworkListNotes') }}
                    </div>
                  </div>
                </div>
              </div>
              <div class="uk-width-1-1 uk-margin-medium-top">
                <h3 class="uk-margin-remove-bottom">Additional information</h3>
                <hr class="uk-margin-small-top">
              </div>
              <div class="uk-width-1-2@s uk-width-1-3@m">
                <div class="uk-margin">
                  <label class="uk-form-label" for="editArtworkAvailability">Availability</label>
                  <div class="uk-form-controls">
                    <!--<ng2-completer id="editArtworkAvailability" name="editArtworkAvailability" formControlName="editArtworkAvailability" [(ngModel)]="activeArtwork.availability" [datasource]="variables.availabilities" [minSearchLength]="0" [textNoResults]="null"></ng2-completer>-->
                    <ng-select [items]="variables.availabilities"
                               [addTag]="true"
                               [selectOnTab]="false"
                               addTagText="Add new: "
                               placeholder="Please select ..."
                               bindLabel=""
                               id="editArtworkAvailability"
                               formControlName="editArtworkAvailability"
                               class="uk-select"
                               #editArtworkAvailability (change)="editArtworkAvailability.blur()">
                    </ng-select>
                    <div class="uk-text-danger uk-text-small"
                          *ngIf="editArtworkForm.get('editArtworkAvailability').hasError('maxlength')"
                    >
                      {{ getCharactersLimitError('editArtworkAvailability') }}
                    </div>
                  </div>
                </div>
              </div>
              <div class="uk-width-1-2@s uk-width-1-3@m">
                <div class="uk-margin">
                  <label class="uk-form-label" for="editArtworkLogistics">Logistics</label>
                  <div class="uk-form-controls">
                    <!--<ng2-completer id="editArtworkLogistics" name="editArtworkLogistics" formControlName="editArtworkLogistics" [(ngModel)]="activeArtwork.logistics" [datasource]="variables.logistics" [minSearchLength]="0" [textNoResults]="null"></ng2-completer>-->
                    <ng-select [items]="variables.logistics"
                               [addTag]="true"
                               [selectOnTab]="false"
                               addTagText="Add new: "
                               placeholder="Please select ..."
                               bindLabel=""
                               id="editArtworkLogistics"
                               formControlName="editArtworkLogistics"
                               class="uk-select"
                               #editArtworkLogistics (change)="editArtworkLogistics.blur()">
                    </ng-select>
                    <div class="uk-text-danger uk-text-small"
                          *ngIf="editArtworkForm.get('editArtworkLogistics').hasError('maxlength')"
                    >
                      {{ getCharactersLimitError('editArtworkLogistics') }}
                    </div>
                  </div>
                </div>
              </div>
              <div class="uk-width-1-2@s uk-width-1-3@m">
                <div class="uk-margin">
                  <label class="uk-form-label" for="editArtworkPackaging">Packaging</label>
                  <div class="uk-form-controls">
                    <!--<ng2-completer id="editArtworkPackaging" name="editArtworkPackaging" formControlName="editArtworkPackaging" [(ngModel)]="activeArtwork.packaging" [datasource]="variables.packagings" [minSearchLength]="0" [textNoResults]="null"></ng2-completer>-->
                    <ng-select [items]="variables.packagings"
                               [addTag]="true"
                               [selectOnTab]="false"
                               addTagText="Add new: "
                               placeholder="Please select ..."
                               bindLabel=""
                               id="editArtworkPackaging"
                               formControlName="editArtworkPackaging"
                               class="uk-select"
                               #editArtworkPackaging (change)="editArtworkPackaging.blur()">
                    </ng-select>
                    <div class="uk-text-danger uk-text-small"
                          *ngIf="editArtworkForm.get('editArtworkPackaging').hasError('maxlength')"
                    >
                      {{ getCharactersLimitError('editArtworkPackaging') }}
                    </div>
                  </div>
                </div>
              </div>
              <div class="uk-width-1-2@s uk-width-1-3@m">
                <div class="uk-margin">
                  <label class="uk-form-label" for="editArtworkLocation">Location</label>
                  <div class="uk-form-controls">
                    <!--<ng2-completer id="editArtworkLocation" name="editArtworkLocation" formControlName="editArtworkLocation" [(ngModel)]="activeArtwork.location" [datasource]="variables.locations" [minSearchLength]="0" [textNoResults]="null"></ng2-completer>-->
                    <ng-select [items]="variables.locations"
                               [addTag]="true"
                               [selectOnTab]="false"
                               addTagText="Add new: "
                               placeholder="Please select ..."
                               bindLabel=""
                               id="editArtworkLocation"
                               formControlName="editArtworkLocation"
                               class="uk-select"
                               #editArtworkLocation (change)="editArtworkLocation.blur()">
                    </ng-select>
                    <div class="uk-text-danger uk-text-small"
                          *ngIf="editArtworkForm.get('editArtworkLocation').hasError('maxlength')"
                    >
                      {{ getCharactersLimitError('editArtworkLocation') }}
                    </div>
                    <!--<select id="editArtworkLocation" name="editArtworkLocation" formControlName="editArtworkLocation" [ngModel]="activeArtwork.location" class="uk-select">
                      <option value="" disabled>Please select ...</option>
                      <option value="1">1</option>
                      <option value="2">2</option>
                      <option value="3">3</option>
                      <option value="4">4</option>
                      <option value="5">5</option>
                    </select>-->
                  </div>
                </div>
              </div>
              <div class="uk-width-1-2@s uk-width-1-3@m">
                <div class="uk-margin">
                  <div class="uk-flex uk-flex-between uk-text-meta">
                    <label class="uk-form-label" for="editArtworkLocationInformation">Location information</label>
                    <div class="uk-text-small">
                      {{ getCharactersLeft('editArtworkLocationInformation', 2048) }}
                    </div>
                  </div>
                  <div class="uk-form-controls">
                    <textarea id="editArtworkLocationInformation" name="editArtworkLocationInformation" formControlName="editArtworkLocationInformation" [ngModel]="activeArtwork.locationInformation" rows="5" type="text" placeholder="Location information ..." class="uk-textarea" [ngClass]="{'uk-form-danger': editArtworkForm.get('editArtworkLocationInformation').touched && (editArtworkForm.get('editArtworkLocationInformation').hasError('required') || editArtworkForm.get('editArtworkLocationInformation').hasError('maxlength')) }"></textarea>
                    <div class="uk-text-danger uk-text-small"
                          *ngIf="editArtworkForm.get('editArtworkLocationInformation').hasError('maxlength')"
                    >
                      {{ getCharactersLimitError('editArtworkLocationInformation') }}
                    </div>
                  </div>
                </div>
              </div>
              <div class="uk-width-1-2@s uk-width-1-3@m">
                <div class="uk-margin">
                  <label class="uk-form-label" for="editArtworkPriceMultiplier">Price multiplier</label>
                  <div class="uk-form-controls">
                    <!--<ng2-completer id="editArtworkPriceMultiplier" name="editArtworkPriceMultiplier" formControlName="editArtworkPriceMultiplier" [(ngModel)]="activeArtwork.priceMultiplier" [datasource]="variables.priceMultiplier" [minSearchLength]="0" [textNoResults]="null"></ng2-completer>-->
                    <ng-select [items]="variables.priceMultipliers"
                               [addTag]="true"
                               [selectOnTab]="false"
                               addTagText="Add new: "
                               placeholder="Please select ..."
                               bindLabel=""
                               id="editArtworkPriceMultiplier"
                               formControlName="editArtworkPriceMultiplier"
                               class="uk-select"
                               #editArtworkPriceMultiplier (change)="editArtworkPriceMultiplier.blur()">
                    </ng-select>
                    <div class="uk-text-danger uk-text-small"
                          *ngIf="editArtworkForm.get('editArtworkPriceMultiplier').hasError('maxlength')"
                    >
                      {{ getCharactersLimitError('editArtworkPriceMultiplier') }}
                    </div>
                    <div *ngIf="((editArtworkForm.get('editArtworkPriceMultiplier').value == (null || undefined)) || !editArtworkForm.get('editArtworkPriceMultiplier').value) && artistMultiplier !== (null || undefined)" class="uk-text-muted hint">
                      Artist has multiplier {{ artistMultiplier }}
                    </div>
                  </div>
                </div>
              </div>
              <div class="uk-width-1-2@s uk-width-1-3@m">
                <div class="uk-margin">
                  <label class="uk-form-label" for="editArtworkClonable">May include editions</label>
                  <div class="uk-form-controls">
                    <label><input id="editArtworkClonable" name="editArtworkClonable" formControlName="editArtworkClonable" [ngModel]="activeArtwork.clonable" class="uk-checkbox" type="checkbox"> This artwork may include editions</label>
                  </div>
                </div>
              </div>
              <div class="uk-width-1-2@s uk-width-1-3@m">
                <div class="uk-margin">
                  <label class="uk-form-label" for="editArtworkCloneOnOrder">Editions may be made on order</label>
                  <div class="uk-form-controls">
                    <label><input id="editArtworkCloneOnOrder" name="editArtworkCloneOnOrder" formControlName="editArtworkCloneOnOrder" [ngModel]="activeArtwork.cloneOnOrder" class="uk-checkbox" type="checkbox"> Editions of this artwork may be made on order</label>
                  </div>
                </div>
              </div>
              <div class="uk-width-1-2@s uk-width-1-3@m">
                <div class="uk-margin">
                  <label class="uk-form-label" for="editArtworkArtistsproof">Artist's proof</label>
                  <div class="uk-form-controls">
                    <label><input id="editArtworkArtistsproof" name="editArtworkArtistsproof" formControlName="editArtworkArtistsproof" [ngModel]="activeArtwork.artistsproof" class="uk-checkbox" type="checkbox"> This artwork has artist's proof</label>
                  </div>
                </div>
              </div>
              <div class="uk-width-1-2@s uk-width-1-3@m">
                <div class="uk-margin">
                  <label class="uk-form-label" for="editArtworkSigning">Artist's proof</label>
                  <div class="uk-form-controls">
                    <label><input id="editArtworkSigning" name="editArtworkSigning" formControlName="editArtworkSigning" [ngModel]="activeArtwork.signing" class="uk-checkbox" type="checkbox"> This artwork has artist's signing</label>
                  </div>
                </div>
              </div>
              <div class="uk-width-1-2@s uk-width-1-3@m" *ngIf="['Installation'].includes(editArtworkForm.get('editArtworkType').value)">
                <div class="uk-margin">
                  <label class="uk-form-label" for="editArtworkDeliveryOnOrder">Delivery on order</label>
                  <div class="uk-form-controls">
                    <input id="editArtworkDeliveryOnOrder" name="editArtworkDeliveryOnOrder" formControlName="editArtworkDeliveryOnOrder" [ngModel]="activeArtwork.deliveryOnOrder" type="text" placeholder="Delivery on order ..." class="uk-input" [ngClass]="{'uk-form-danger': editArtworkForm.get('editArtworkDeliveryOnOrder').touched && (editArtworkForm.get('editArtworkDeliveryOnOrder').hasError('required') || editArtworkForm.get('editArtworkDeliveryOnOrder').hasError('maxlength')) }">
                    <div class="uk-text-danger uk-text-small"
                          *ngIf="editArtworkForm.get('editArtworkDeliveryOnOrder').hasError('maxlength')"
                    >
                      {{ getCharactersLimitError('editArtworkDeliveryOnOrder') }}
                    </div>
                  </div>
                </div>
              </div>
              <!--<div class="uk-width-1-2@s uk-width-1-3@m">
                <div class="uk-margin">
                  <label class="uk-form-label" for="form-stacked-text">Attachements</label>
                  <div class="uk-form-controls">
                    <div uk-form-custom="target: true">
                      <input type="file">
                      <input class="uk-input uk-form-width-medium" type="text" placeholder="Valitse tiedosto" disabled>
                    </div>
                    <button class="uk-button uk-button-default">Lisää</button>
                  </div>
                </div>
              </div>-->
              <div class="uk-width-1-1@s">
                <div class="uk-margin">
                  <label class="uk-form-label">Attachments</label>
                  <div class="uk-form-controls uk-margin-small">
                    <dl class="uk-description-list" *ngIf="activeArtwork.attachments.length">
                      <div class="uk-flex uk-flex-middle" *ngFor="let attachment of activeArtwork.attachments">
                        <div class="uk-padding-small">
                          <img class="contenttype" width="40" height="40" src="/api/storagefile/{{ activeAccount }}/{{ attachment.fileId }}/small" *ngIf="['image/png', 'image/jpg', 'image/jpeg', 'image/heic', 'image/gif', 'image/svg+xml'].includes(attachment.contentType)" />
                          <img class="contenttype" width="40" height="40" src="/api/file/5a5de6b1229e86403d67db11" *ngIf="['application/msword', 'application/vnd.openxmlformats-officedocument.wordprocessingml.document'].includes(attachment.contentType)" />
                          <img class="contenttype" width="40" height="40" src="/api/file/5a5de6b1229e86403d67db10" *ngIf="['application/octet-stream', 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'].includes(attachment.contentType)" />
                          <img class="contenttype" width="40" height="40" src="/api/file/5a5de6b1229e86403d67db12" *ngIf="['application/pdf'].includes(attachment.contentType)" />
                          <img class="contenttype" width="40" height="40" src="/api/file/5a5de7bc229e86403d67db1b" *ngIf="['text/plain'].includes(attachment.contentType)" />
                          <img class="contenttype" width="40" height="40" src="/api/file/5a5de6b1229e86403d67db0f" *ngIf="!(['image/png', 'image/jpg', 'image/jpeg', 'image/heic', 'image/gif', 'image/svg+xml', 'application/msword', 'application/vnd.openxmlformats-officedocument.wordprocessingml.document', 'application/octet-stream', 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet', 'application/pdf', 'text/plain'].includes(attachment.contentType))" />
                        </div>
                        <div class="uk-width-expand">{{ attachment.name }}</div>
                        <div class="uk-button-group">
                          <button (click)="openAttachment(attachment)" class="uk-button uk-button-default" type="button">Open</button>
                          <div class="uk-inline uk-text-right">
                            <button class="uk-button uk-button-default" type="button"><span uk-icon="icon:  chevron-down"></span></button>
                            <div uk-dropdown="mode: click; boundary: ! .uk-button-group; boundary-align: true;">
                              <ul class="uk-nav uk-dropdown-nav">
                                <li><a (click)="removeAttachment(attachment)">Remove</a></li>
                              </ul>
                            </div>
                          </div>
                        </div>
                      </div>
                    </dl>
                    <p *ngIf="!activeArtwork.attachments.length">No related attachments</p>
                  </div>
                </div>
              </div>
              <div class="uk-width-1-1@s">
                <div class="uk-margin">
                  <label class="uk-form-label">Add new attachments</label>
                  <div class="uk-form-controls">
                    <div class="drop-container" ngFileDrop [options]="options" (uploadOutput)="onAttachmentsUploadOutput($event)" (click)="attachmentFile.click()" [uploadInput]="uploadInput">
                      <div class="js-upload uk-placeholder uk-text-center" [ngClass]="{ 'is-drop-over': dragOver }">
                        <span class="uk-text-middle">Add files by dropping them here or open file dialog by clicking here.<br>Maximum allowed file size is 5 Mb.</span>
                      </div>
                      <input #attachmentFile type="file" ngFileSelect [options]="{ concurrency: 1, maxFileSize: 5000000 }" [uploadInput]="uploadInput" (uploadOutput)="onAttachmentsUploadOutput($event)" multiple class="file-input">
                    </div>
                  </div>
<!--                  <progress id="js-progressbar" class="uk-progress" value="0" max="100" hidden></progress>-->
<!--                  <div class="table-container" *ngIf="filesToUpload.length">-->
<!--                    <table class="uk-table uk-table-middle uk-margin-top">-->
<!--                      <caption>Files to upload</caption>-->
<!--                      <thead>-->
<!--                      <tr>-->
<!--                        <th class="uk-table-shrink uk-text-capitalize">Image</th>-->
<!--                        <th class="uk-width-expand uk-text-capitalize">Name</th>-->
<!--                        <th class="uk-width-expand uk-text-capitalize">Size</th>-->
<!--                        <th class="uk-table-shrink uk-text-capitalize"></th>-->
<!--                      </tr>-->
<!--                      </thead>-->
<!--                      <tbody>-->
<!--                      <tr *ngFor="let file of filesToUpload; let i = index;">-->
<!--                        <td class="uk-background-muted"><img class="uk-preserve-width uk-border-circle" src="/api/image/artbuddy-logo-placeholder.svg" width="60" uk-img></td>-->
<!--                        <td>{{ file.name }}</td>-->
<!--                        <td class="uk-text-nowrap">{{ file.size | bytes: 2 }}</td>-->
<!--                        <td><button (click)="removeFile(file.id)" class="uk-button uk-button-default" type="button">Remove</button></td>-->
<!--                      </tr>-->
<!--                      </tbody>-->
<!--                    </table>-->
<!--                    <button type="button" (click)="startUpload()" class="uk-button uk-button-primary">Start upload</button>-->
<!--                  </div>-->
                </div>
              </div>
              <div class="uk-width-1-2@s uk-width-1-3@m">
                <div class="uk-margin">
                  <label class="uk-form-label" for="editArtworkTags">Tags</label>
                  <div class="uk-form-controls">
                    <input id="editArtworkTags" name="editArtworkTags" formControlName="editArtworkTags" [ngModel]="activeArtwork.tags" type="text" placeholder="Tags ..." class="uk-input" [ngClass]="{'uk-form-danger': editArtworkForm.get('editArtworkTags').touched && (editArtworkForm.get('editArtworkTags').hasError('required') || editArtworkForm.get('editArtworkTags').hasError('maxlength')) }">
                    <div class="uk-text-danger uk-text-small"
                          *ngIf="editArtworkForm.get('editArtworkTags').hasError('maxlength')"
                    >
                      {{ getCharactersLimitError('editArtworkTags') }}
                    </div>
                  </div>
                </div>
              </div>
              <div class="uk-width-1-2@s uk-width-1-3@m">
                <div class="uk-margin">
                  <div class="uk-flex uk-flex-between uk-text-meta">
                    <label class="uk-form-label" for="editArtworkNotes">Notes</label>
                    <div class="uk-text-small">
                      {{ getCharactersLeft('editArtworkNotes', 2048) }}
                    </div>
                  </div>
                  <div class="uk-form-controls">
                    <textarea id="editArtworkNotes" name="editArtworkNotes" formControlName="editArtworkNotes" [ngModel]="activeArtwork.notes" rows="5" type="text" placeholder="Other information ..." class="uk-textarea" [ngClass]="{'uk-form-danger': editArtworkForm.get('editArtworkNotes').touched && (editArtworkForm.get('editArtworkNotes').hasError('required') || editArtworkForm.get('editArtworkNotes').hasError('maxlength')) }"></textarea>
                    <div class="uk-text-danger uk-text-small"
                          *ngIf="editArtworkForm.get('editArtworkNotes').hasError('maxlength')"
                    >
                      {{ getCharactersLimitError('editArtworkNotes') }}
                    </div>
                  </div>
                </div>
              </div>
              <div class="uk-width-1-2@s uk-width-1-3@m">
                <div class="uk-margin">
                  <div class="uk-flex uk-flex-between uk-text-meta">
                    <label class="uk-form-label" for="editArtworkComments">Comments</label>
                    <div class="uk-text-small">
                      {{ getCharactersLeft('editArtworkComments', 2048) }}
                    </div>
                  </div>
                  <div class="uk-form-controls">
                    <textarea id="editArtworkComments" name="editArtworkComments" formControlName="editArtworkComments" [ngModel]="activeArtwork.comments" rows="5" type="text" placeholder="Comments ..." class="uk-textarea" [ngClass]="{'uk-form-danger': (editArtworkForm.get('editArtworkComments').touched && editArtworkForm.get('editArtworkComments').hasError('required')) || editArtworkForm.controls.editArtworkComments.hasError('maxlength')}"></textarea>
                    <div class="uk-text-danger uk-text-small"
                          *ngIf="editArtworkForm.get('editArtworkComments').hasError('maxlength')"
                    >
                      {{ getCharactersLimitError('editArtworkComments') }}
                    </div>
                  </div>
                </div>
              </div>
              <div class="uk-width-1-2@s uk-width-1-3@m">
                <div class="uk-margin">
                  <label class="uk-form-label" for="editArtworkVisibility">Visibility</label>
                  <div class="uk-form-controls">
                    <label><input id="editArtworkVisibility" name="editArtworkVisibility" formControlName="editArtworkVisibility" [ngModel]="activeArtwork.visibility" class="uk-checkbox" type="checkbox"> Public</label>
                  </div>
                </div>
              </div>
              <div class="uk-width-1-1 uk-margin-large-top"></div>
              <div class="uk-width-1-1 uk-margin-remove sticky-bottom uk-background-default" uk-sticky="bottom: true">
                <div class="uk-width-1-1">
                  <div class="uk-flex uk-flex-middle uk-flex-between uk-margin">
                    <app-editing-history
                      [updatedAt]="activeArtwork.updatedAt"
                      [name]="activeArtwork.requestUser?.name"
                      [email]="activeArtwork.requestUser?.email"
                    ></app-editing-history>
                    <div class="uk-text-right">
                      <button type="button" class="uk-button uk-button-default uk-margin-small-right" [disabled]="!editArtworkForm.valid" (click)="getCSV()">Get CSV</button>
                      <button type="button" class="uk-button uk-button-default uk-margin-small-right" [disabled]="!editArtworkForm.valid || !activeArtwork.images.length">Open PDF</button>
                      <div uk-dropdown="mode: click">
                        <ul class="uk-nav uk-dropdown-nav">
                          <li class="uk-nav-header">Include information</li>
                          <li><label class="wide-nav-option"><input [(ngModel)]="openPDF.price" [ngModelOptions]="{standalone: true}" class="uk-checkbox" type="checkbox"> Price</label></li>
                          <li><label class="wide-nav-option"><input [(ngModel)]="openPDF.code" [ngModelOptions]="{standalone: true}" class="uk-checkbox" type="checkbox"> Code</label></li>
                          <li><label class="wide-nav-option"><input [(ngModel)]="openPDF.comments" [ngModelOptions]="{standalone: true}" class="uk-checkbox" type="checkbox"> Comments</label></li>
                          <li><label class="wide-nav-option"><input [(ngModel)]="openPDF.notesInPrint" [ngModelOptions]="{standalone: true}" class="uk-checkbox" type="checkbox"> Notes</label></li>
                          <li class="uk-nav-divider"></li>
                          <li><a (click)="getArtworkPDF()">Open</a></li>
                        </ul>
                      </div>
                      <button type="submit" class="uk-button uk-button-primary" [disabled]="!editArtworkForm.valid || uploadLoading">Save</button>
                    </div>
                  </div>
                </div>
              </div>
          </form>
        </div>
        <div id="editions" class="uk-padding-small">
          <div class="uk-flex uk-flex-between">
            <div class="uk-flex-left">
              <button (click)="openAddArtworkEditionModal()" class="uk-button uk-button-default" type="button">Add editions</button>
            </div>
            <div id="addArtworkEditionModal" uk-modal="bg-close: false; esc-close: false;">
              <div class="uk-modal-dialog">
                <form appNoSubmitEnter [formGroup]="addArtworkEditionForm" (ngSubmit)="addArtworkEdition()" class="uk-form-stacked">
                  <div class="uk-modal-header">
                    <h2 class="uk-modal-title">Add artwork editions</h2>
                  </div>
                  <div class="uk-modal-body">
                    <p>You can add a number of editions for an artwork. The current artwork will remain as a master version and the editions will be its' duplicates. The editions are freely editable later on.</p>
                    <div class="uk-width-1-1">
                      <div class="uk-margin">
                        <label class="uk-form-label" for="addArtworkEditionAmount">Number of editions</label>
                        <div class="uk-form-controls">
                          <select id="addArtworkEditionAmount" name="addArtworkEditionAmount" formControlName="addArtworkEditionAmount" class="uk-select">
                            <option value="" disabled>Please select ...</option>
                            <option value="{{ i+1 }}" *ngFor="let number of editionsAmountArray(99); let i = index;">{{ i+1 }}</option>
                          </select>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="uk-modal-footer">
                    <p class="uk-text-right">
                      <button class="uk-button uk-button-default uk-modal-close uk-margin-small-right" type="button" id="addnewcancel" (click)="resetForm()">Cancel</button>
                      <button type="submit" [disabled]="!editArtworkForm.get('editArtworkName')" class="uk-button uk-button-primary">Save</button>
                    </p>
                  </div>
                </form>
              </div>
              </div>
<!--            <div class="uk-flex-right uk-align-right uk-margin-remove-bottom" *ngIf="activeArtworkEditions && activeArtworkEditions.length">-->
<!--              <button class="uk-button uk-button-default" type="button">Sort</button>-->
<!--              <div uk-dropdown>-->
<!--                <ul class="uk-nav uk-dropdown-nav">-->
<!--                  <li class="uk-active"><a>Ajankohta</a></li>-->
<!--                  <li><a>Nimi</a></li>-->
<!--                  <li><a>Hinta</a></li>-->
<!--                  <li><a>Saatavuus</a></li>-->
<!--                </ul>-->
<!--              </div>-->
<!--            </div>-->
          </div>
          <table class="uk-table uk-table-middle uk-table-divider" *ngIf="activeArtworkEditions && activeArtworkEditions.length">
            <thead>
            <tr>
              <th class="uk-table-shrink">Image</th>
              <th class="uk-width-expand" [ngClass]="{'active': activeSort('name') }" (click)="sortItems('name')">Name<span uk-icon="chevron-down" [ngClass]="{'inverse': !sortAscending }"></span></th>
              <th class="uk-width-expand uk-text-nowrap" [ngClass]="{'active': activeSort('artist') }" (click)="sortItems('artist')">Artist<span uk-icon="chevron-down" [ngClass]="{'inverse': !sortAscending }"></span></th>
              <th class="uk-table-shrink uk-text-nowrap" [ngClass]="{'active': activeSort('technique') }" (click)="sortItems('technique')">Technique<span uk-icon="chevron-down" [ngClass]="{'inverse': !sortAscending }"></span></th>
              <th class="uk-table-shrink uk-text-nowrap" [ngClass]="{'active': activeSort('availability') }" (click)="sortItems('availability')">Availability<span uk-icon="chevron-down" [ngClass]="{'inverse': !sortAscending }"></span></th>
              <th class="uk-table-shrink uk-text-nowrap" [ngClass]="{'active': activeSort('visibility') }" (click)="sortItems('visibility')">Visibility<span uk-icon="chevron-down" [ngClass]="{'inverse': !sortAscending }"></span></th>
              <th class="uk-table-shrink"></th>
            </tr>
            </thead>
            <tbody>
            <tr *ngFor="let artwork of activeArtworkEditions">
              <td>
                <!--<img class="uk-preserve-width uk-border-circle" src="/api/image/artbuddy-logo-placeholder.svg" *ngIf="!artwork.master.images?.length" width="60">
                <img class="uk-preserve-width" [src]="'/api/storagefile/' + activeAccount + '/' + artwork.master.images[0].fileId + '/small'" width="60" *ngIf="artwork.master.images?.length">-->

                <!-- Näytetään coverImage jos on -->
                <div class="image-max-height uk-preserve-width uk-background-contain uk-background-muted uk-height-small uk-panel uk-flex uk-flex-center uk-flex-middle" width="60" [attr.data-src]="'/api/storagefile/' + activeAccount + '/' + artwork.coverImage + '/small'" uk-img *ngIf="artwork.coverImage">

                </div>

                <!-- Jos ei ole coverImagea, niin sit otetaan eka image -->
                <div class="image-max-height uk-preserve-width uk-background-contain uk-background-muted uk-height-small uk-panel uk-flex uk-flex-center uk-flex-middle" width="60" [attr.data-src]="'/api/storagefile/' + activeAccount + '/' + artwork.master.images[0].fileId + '/small'" uk-img *ngIf="!artwork.coverImage && artwork.master.images && artwork.master.images.length">

                </div>

                <!-- Jos ei ole coverImagea tai ekan imagea, ni sit otetaan placeholder -->
                <div class="image-max-height uk-preserve-width uk-background-contain uk-background-muted uk-height-small uk-panel uk-flex uk-flex-center uk-flex-middle" width="60" [attr.data-src]="'/api/image/artbuddy-logo-placeholder.svg'" uk-img *ngIf="!artwork.coverImage && !artwork.master.images.length">
                  </div>
              </td>
              <!--[ngStyle]="{'background-image': 'url(/api/filestorage/' + image.fileId + ')'}"-->
              <td>{{ artwork.name }}</td>
              <td>{{ artwork.artist.name }} {{ artwork.artist.lastName }}</td>
              <td>{{ artwork.technique }}</td>
              <td>{{ artwork.availability }}</td>
              <td>
                <span *ngIf="artwork.visibility">Visible</span>
                <span *ngIf="!artwork.visibility">Hidden</span>
              </td>
              <td class="uk-text-right">
                <div class="uk-button-group">
                  <button (click)="openArtworkEdition(artwork._id)" class="uk-button uk-button-default" type="button">Open</button>
                  <div class="uk-inline" class="uk-visible@m">
                    <button class="uk-button uk-button-default" type="button"><span uk-icon="icon:  chevron-down"></span></button>
                    <div uk-dropdown="mode: click; boundary: ! .uk-button-group; boundary-align: true;">
                      <ul class="uk-nav uk-dropdown-nav">
                        <li><a (click)="removeArtworkEdition(artwork._id)">Remove</a></li>
                      </ul>
                    </div>
                  </div>
                </div>
              </td>
            </tr>
            </tbody>
          </table>
        </div>
        <div id="exhibitions" class="uk-padding-small" *ngIf="activeArtworkExhibitions && activeArtworkExhibitions.length">
          <table class="uk-table uk-table-middle uk-table-divider">
            <thead>
              <tr>
                <th class="uk-text-nowrap" [ngClass]="{'active': activeSort('name') }" (click)="sortExhibitions('name')">Name<span uk-icon="chevron-down" [ngClass]="{'inverse': !sortAscending }"></span></th>
                <th class="uk-text-nowrap uk-visible@m" [ngClass]="{'active': activeSort('type') }" (click)="sortExhibitions('type')">Type<span uk-icon="chevron-down" [ngClass]="{'inverse': !sortAscending }"></span></th>
                <th class="uk-text-nowrap uk-visible@m" [ngClass]="{'active': activeSort('startDate') }" (click)="sortExhibitions('startDate')">Start date<span uk-icon="chevron-down" [ngClass]="{'inverse': !sortAscending }"></span></th>
                <th class="uk-text-nowrap uk-visible@m" [ngClass]="{'active': activeSort('location') }" (click)="sortExhibitions('location')">Location<span uk-icon="chevron-down" [ngClass]="{'inverse': !sortAscending }"></span></th>
                <th class="uk-table-shrink"></th>
              </tr>
            </thead>
            <tbody>
              <tr *ngFor="let exhibition of activeArtworkExhibitions | orderBy: (sortAscending ? '-'+sortingKey : sortingKey)">
                <td>{{ exhibition.name }}</td>
                <td class="uk-visible@m">{{ exhibition.type }}</td>
                <td class="uk-visible@m">{{ exhibition.startDate | date: 'd.M.yyyy' }}</td>
                <td class="uk-visible@m">{{ exhibition.location }}</td>
                <td class="uk-text-right">
                  <button (click)="openExhibition(exhibition._id)" class="uk-button uk-button-default" type="button">Open</button>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
        <div id="estimates" class="uk-padding-small" *ngIf="activeArtworkEstimates && activeArtworkEstimates.length">
            <!--<div class="uk-flex uk-flex-between">
              <div class="uk-flex-right uk-align-right uk-margin-remove-bottom">
                <button class="uk-button uk-button-default" type="button">Sort</button>
                <div uk-dropdown>
                  <ul class="uk-nav uk-dropdown-nav">
                    <li class="uk-active"><a>Ajankohta</a></li>
                    <li><a>Nimi</a></li>
                    <li><a>Hinta</a></li>
                    <li><a>Saatavuus</a></li>
                  </ul>
                </div>
              </div>
            </div>-->
            <table class="uk-table uk-table-middle uk-table-divider">
              <thead>
              <tr>
                <!--<th class="uk-visible@m">Image</th>-->
                <th class="uk-text-nowrap" [ngClass]="{'active': activeSort('name') }" (click)="sortEstimates('name')">Name<span uk-icon="chevron-down" [ngClass]="{'inverse': !sortAscending }"></span></th>
                <th class="uk-text-nowrap" [ngClass]="{'active': activeSort('artwork') }" (click)="sortEstimates('artwork')">Artwork<span uk-icon="chevron-down" [ngClass]="{'inverse': !sortAscending }"></span></th>
                <th class="uk-text-nowrap uk-visible@m" [ngClass]="{'active': activeSort('estimator') }" (click)="sortEstimates('estimator')">Estimator<span uk-icon="chevron-down" [ngClass]="{'inverse': !sortAscending }"></span></th>
                <th class="uk-text-nowrap uk-visible@m" [ngClass]="{'active': activeSort('created') }" (click)="sortEstimates('created')">Created<span uk-icon="chevron-down" [ngClass]="{'inverse': !sortAscending }"></span></th>
                <th class="uk-table-shrink"></th>
              </tr>
              </thead>
              <tbody>
              <tr *ngFor="let estimate of activeArtworkEstimates | orderBy: (sortAscending ? '-'+sortingKey : sortingKey)">
                <!--<td class="uk-visible@m">
                    <span *ngIf="estimate.items.length && estimate.items[0].coverImage">
                      <div class="image-max-height uk-preserve-width uk-background-contain uk-background-muted uk-height-small uk-panel uk-flex uk-flex-center uk-flex-middle" width="60" [attr.data-src]="'/api/storagefile/' + estimate.items[0].coverImage" uk-img>
                      </div>
                    </span>
                  <span *ngIf="estimate.items.length && !estimate.items[0].coverImage && estimate.items[0].images && estimate.items[0].images[0]">
                      <div class="image-max-height uk-preserve-width uk-background-contain uk-background-muted uk-height-small uk-panel uk-flex uk-flex-center uk-flex-middle" width="60" [attr.data-src]="'/api/storagefile/' + estimate.items[0].images[0].fileId" uk-img>
                      </div>
                    </span>
                  <span *ngIf="estimate.items.length && !estimate.items[0].coverImage && !estimate.items[0].images.length">
                      <div class="image-max-height uk-preserve-width uk-background-contain uk-background-muted uk-height-small uk-panel uk-flex uk-flex-center uk-flex-middle" width="60" [attr.data-src]="'/api/image/artbuddy-logo-placeholder.svg'" uk-img>
                      </div>
                    </span>
                </td>-->
                <td>{{ estimate.name }}</td>
                <td><span *ngIf="estimate.items.length">{{ estimate.items[0].name }}</span></td>
                <td class="uk-visible@m">{{ estimate.estimator.name }}</td>
                <td class="uk-visible@m">{{ estimate.created ? (estimate.created | date: 'yyyy') : '' }}</td>
                <td>
                  <button (click)="openEstimate(estimate._id)" class="uk-button uk-button-default" type="button">Open</button>
                </td>
              </tr>
              </tbody>
            </table>
        </div>
        <div id="bids" class="uk-padding-small" *ngIf="activeArtworkBids && activeArtworkBids.length">
          <!--<div class="uk-flex uk-flex-between">
            <div class="uk-flex-right uk-align-right uk-margin-remove-bottom">
              <button class="uk-button uk-button-default" type="button">Sort</button>
              <div uk-dropdown>
                <ul class="uk-nav uk-dropdown-nav">
                  <li class="uk-active"><a>Ajankohta</a></li>
                  <li><a>Nimi</a></li>
                  <li><a>Hinta</a></li>
                  <li><a>Saatavuus</a></li>
                </ul>
              </div>
            </div>
          </div>-->
          <table class="uk-table uk-table-middle uk-table-divider">
            <thead>
            <tr>
              <th class="uk-text-nowrap" [ngClass]="{'active': activeSort('sequence') }" (click)="sortBids('sequence')">Number<span uk-icon="chevron-down" [ngClass]="{'inverse': !sortAscending }"></span></th>
              <th class="uk-text-wrap" [ngClass]="{'active': activeSort('name') }" (click)="sortBids('name')">Name<span uk-icon="chevron-down" [ngClass]="{'inverse': !sortAscending }"></span></th>
              <th class="uk-text-nowrap uk-visible@m" [ngClass]="{'active': activeSort('seller') }" (click)="sortBids('seller')">Seller<span uk-icon="chevron-down" [ngClass]="{'inverse': !sortAscending }"></span></th>
              <th class="uk-text-nowrap uk-visible@m" [ngClass]="{'active': activeSort('type') }" (click)="sortBids('type')">Type<span uk-icon="chevron-down" [ngClass]="{'inverse': !sortAscending }"></span></th>
              <th class="uk-text-nowrap uk-visible@m" [ngClass]="{'active': activeSort('price') }" (click)="sortBids('price')">Price<span uk-icon="chevron-down" [ngClass]="{'inverse': !sortAscending }"></span></th>
              <th class="uk-text-nowrap uk-visible@m" [ngClass]="{'active': activeSort('valid') }" (click)="sortBids('valid')">Valid<span uk-icon="chevron-down" [ngClass]="{'inverse': !sortAscending }"></span></th>
              <th class="uk-table-shrink"></th>
            </tr>
            </thead>
            <tbody>
              <tr *ngFor="let bid of activeArtworkBids | orderBy: (sortAscending ? '-'+sortingKey : sortingKey)">
                <!--<td>
                  <img class="uk-preserve-width uk-border-circle" src="/api/image/artbuddy-logo-placeholder.svg" *ngIf="!bid.images.length" width="60">
                  <img class="uk-preserve-width" [src]="'/api/storage/' + bid.images[0].fileId" width="60" *ngIf="bid.images.length">
                </td>-->
                <!--[ngStyle]="{'background-image': 'url(/api/storage/' + image.fileId + ')'}"-->
                <td>{{ bid.sequence }}</td>
                <td>{{ bid.name }}</td>
                <td class="uk-visible@m">{{ bid.seller.name }}</td>
                <td class="uk-visible@m">{{ bid.type }}</td>
                <td class="uk-visible@m">{{ bid.price }} {{ bid.currency }}</td>
                <td class="uk-visible@m">{{ bid.valid | date: 'dd.MM.yyyy' }}</td>
                <td class="uk-text-right">
                  <button (click)="openBid(bid._id)" class="uk-button uk-button-default" type="button">Open</button>
                </td>
              </tr>
            </tbody>
          </table>
      </div>
      <div id="trades" class="uk-padding-small" *ngIf="activeArtworkTrades && activeArtworkTrades.length">
          <!--<div class="uk-flex uk-flex-between">
            <div class="uk-flex-right uk-align-right uk-margin-remove-bottom">
              <button class="uk-button uk-button-default" type="button">Sort</button>
              <div uk-dropdown>
                <ul class="uk-nav uk-dropdown-nav">
                  <li class="uk-active"><a>Ajankohta</a></li>
                  <li><a>Nimi</a></li>
                  <li><a>Hinta</a></li>
                  <li><a>Saatavuus</a></li>
                </ul>
              </div>
            </div>
          </div>-->
          <table class="uk-table uk-table-middle uk-table-divider">
            <thead>
              <tr>
                <th class="uk-text-nowrap" [ngClass]="{'active': activeSort('sequence') }" (click)="sortTrades('sequence')">Number<span uk-icon="chevron-down" [ngClass]="{'inverse': !sortAscending }"></span></th>
                <th class="uk-text-nowrap" [ngClass]="{'active': activeSort('name') }" (click)="sortTrades('name')">Name<span uk-icon="chevron-down" [ngClass]="{'inverse': !sortAscending }"></span></th>
                <th class="uk-text-nowrap uk-visible@m" [ngClass]="{'active': activeSort('actor') }" (click)="sortTrades('actor')">Actor<span uk-icon="chevron-down" [ngClass]="{'inverse': !sortAscending }"></span></th>
                <th class="uk-text-nowrap uk-visible@m" [ngClass]="{'active': activeSort('type') }" (click)="sortTrades('type')">Type<span uk-icon="chevron-down" [ngClass]="{'inverse': !sortAscending }"></span></th>
                <th class="uk-text-nowrap uk-visible@m" [ngClass]="{'active': activeSort('price') }" (click)="sortTrades('price')">Price<span uk-icon="chevron-down" [ngClass]="{'inverse': !sortAscending }"></span></th>
                <th class="uk-table-shrink"></th>
              </tr>
            </thead>
            <tbody>
            <tr *ngFor="let trade of activeArtworkTrades | orderBy: (sortAscending ? '-'+sortingKey : sortingKey)">
              <td>{{ trade.sequence }}</td>
              <td>{{ trade.name }}</td>
              <td class="uk-visible@m">{{ trade.seller.name }}</td>
              <td class="uk-visible@m">{{ trade.type }}</td>
              <td class="uk-visible@m">{{ trade.price }} {{ trade.currency }}</td>
              <td class="uk-text-right">
                <button (click)="openTrade(trade._id)" class="uk-button uk-button-default" type="button">Open</button>
              </td>
            </tr>
            </tbody>
          </table>
      </div>
      <div id="reservations" class="uk-padding-small"
           *ngIf="activeArtworkReservations && activeArtworkReservations.length">
        <!--<div class="uk-flex uk-flex-between">
          <div class="uk-flex-right uk-align-right uk-margin-remove-bottom">
            <button class="uk-button uk-button-default" type="button">Sort</button>
            <div uk-dropdown>
              <ul class="uk-nav uk-dropdown-nav">
                <li class="uk-active"><a>Ajankohta</a></li>
                <li><a>Nimi</a></li>
                <li><a>Hinta</a></li>
                <li><a>Saatavuus</a></li>
              </ul>
            </div>
          </div>
        </div>-->
        <table class="uk-table uk-table-middle uk-table-divider">
          <thead>
          <tr>
            <th class="uk-text-nowrap" [ngClass]="{'active': activeSort('sequence') }" (click)="sortReservations('sequence')">Number<span uk-icon="chevron-down" [ngClass]="{'inverse': !sortAscending }"></span></th>
            <th class="uk-text-wrap" [ngClass]="{'active': activeSort('name') }" (click)="sortReservations('name')">Name<span uk-icon="chevron-down" [ngClass]="{'inverse': !sortAscending }"></span></th>
            <th class="uk-text-nowrap uk-visible@m" [ngClass]="{'active': activeSort('seller') }" (click)="sortReservations('seller')">Seller<span uk-icon="chevron-down" [ngClass]="{'inverse': !sortAscending }"></span></th>
            <th class="uk-text-nowrap uk-visible@m" [ngClass]="{'active': activeSort('type') }" (click)="sortReservations('type')">Type<span uk-icon="chevron-down" [ngClass]="{'inverse': !sortAscending }"></span></th>
            <th class="uk-text-nowrap uk-visible@m" [ngClass]="{'active': activeSort('valid') }" (click)="sortReservations('valid')">Valid<span uk-icon="chevron-down" [ngClass]="{'inverse': !sortAscending }"></span></th>
            <th class="uk-table-shrink"></th>
          </tr>
          </thead>
          <tbody>
          <tr *ngFor="let reservation of activeArtworkReservations | orderBy: (sortAscending ? '-'+sortingKey : sortingKey)">
            <!--<td>
              <img class="uk-preserve-width uk-border-circle" src="/api/image/artbuddy-logo-placeholder.svg" *ngIf="!reservation.images.length" width="60">
              <img class="uk-preserve-width" [src]="'/api/storagefile/' + reservation.images[0].fileId" width="60" *ngIf="reservation.images.length">
            </td>-->
            <!--[ngStyle]="{'background-image': 'url(/api/storagefile/' + activeAccount + '/' + image.fileId + ')'}"-->
            <td>{{ reservation.sequence }}</td>
            <td>{{ reservation.name }}</td>
            <td class="uk-visible@m">{{ reservation.seller.name }}</td>
            <td class="uk-visible@m">{{ reservation.type }}</td>
            <td class="uk-visible@m">{{ reservation.valid | date: 'dd.MM.yyyy' }}</td>
            <td class="uk-text-right">
                <button (click)="openReservation(reservation._id)" class="uk-button uk-button-default" type="button">Open</button>
              </td>
          </tr>
          </tbody>
        </table>
      </div>

          <!--<div id="pricing" class="uk-padding-small">
            <form appNoSubmitEnter [formGroup]="genericForm" class="uk-grid-small" uk-grid>
              <div class="uk-width-1-2@s uk-width-1-3@m">
                <div class="uk-margin">
                  <label class="uk-form-label" for="form-stacked-text">Hinta</label>
                  <div class="uk-form-controls">
                    <input class="uk-input" id="form-stacked-text" type="text" placeholder="Hinta ...">
                  </div>
                </div>
              </div>
            </form>
          </div>-->
        </ul>
      <!--
      <div class="uk-margin-medium uk-card uk-card-body uk-margin-remove-top uk-padding-remove-top">
        <div class="uk-width-1-1 uk-margin-medium-top">
          <h3 class="uk-margin-remove-bottom">Close account</h3>
          <hr class="uk-margin-small-top">
        </div>
        <p>You can transfer the account's ownership to any member associated with the account. The account owner has full access to its' details and options.</p>
        <p><span class="uk-label uk-label-warning">Warning</span> Closing an account is irreversible.</p>
        <div class="uk-width-1-1">
          <div class="uk-margin">
            <div class="uk-text-right">
              <button (click)="removeItem(activeArtwork)" class="uk-button uk-button-danger">Close account</button>
            </div>
          </div>
        </div>
      </div>
      -->
  </div>
</div>
