<div class="uk-position-relative">
  <ais-instantsearch [config]="algolia">
    <ais-configure [searchParameters]="algoliaSearchParameters"></ais-configure>
    <div class="uk-width-1-1 search-form-wrapper uk-margin-bottom">
      <app-ais-search-content-box
        [contentType]="contentType"
        (showResults)="showResults($event)"
        [onClose]="clearSearch"
      ></app-ais-search-content-box>
    </div>
    <div class="uk-position-relative">

      <div id="searchresults" [hidden]="!searchResultsVisible" [ngClass]="{'active': searchResultsVisible }" uk-drop>
        <ais-hits>
          <ng-template let-hits="hits" let-results="results">
          <div class="uk-padding-small uk-flex uk-flex-between uk-position-relative">
              <h4 class="uk-margin-remove-bottom">Search results</h4>
              <a class="uk-form-icon uk-form-icon-flip" (click)="hideSearchResult()" uk-icon="icon: close"></a>
            </div>
            <div *ngIf="filterHiddenDocuments(hits)" class="uk-padding-small uk-text-left dark">
              No search results found for <strong>{{results?.query}}</strong>
            </div>
            <table class="uk-table uk-table-middle uk-table-divider uk-margin-remove-vertical" [hidden]="!hits.length">
              <thead>
              <tr>
                <th class="" *ngIf="fields.includes('image')">Image</th>
                <th class="uk-width-expand" *ngIf="fields.includes('name')">Name</th>
                <th class="" *ngIf="fields.includes('number')">Number</th>
                <th class="uk-width-expand" *ngIf="fields.includes('title')">Title</th>
<!--                <th class="" *ngIf="fields.includes('customer')">Customer</th>-->
                <th class="" *ngIf="fields.includes('type')">Type</th>
                <th class="" *ngIf="fields.includes('startDate')">Start date</th>
                <th class="" *ngIf="fields.includes('location')">Location</th>
                <th class="uk-table-shrink"></th>
              </tr>
              </thead>
              <tbody>
              <tr *ngFor="let hit of hits; trackBy: trackByObjectID" class="hit" [id]="'hit' + hit._id" [hidden]="hit.hidden">
                <td *ngIf="fields.includes('image')">
                  <ng-container *ngIf="['artist', 'artwork'].includes(hit.contentType)">
                    <!-- Näytetään coverImage jos on -->
                    <div
                      class="image-max-height uk-preserve-width uk-background-contain uk-background-muted uk-height-small uk-panel uk-flex uk-flex-center uk-flex-middle"
                      width="60"
                      [attr.data-src]="'/api/storagefile/' + activeAccount + '/' + hit.coverImage + '/medium'"
                      uk-img *ngIf="hit.coverImage">

                    </div>

                    <!-- Jos ei ole coverImagea, niin sit otetaan eka image -->
                    <div
                      class="image-max-height uk-preserve-width uk-background-contain uk-background-muted uk-height-small uk-panel uk-flex uk-flex-center uk-flex-middle"
                      width="60"
                      [attr.data-src]="'/api/storagefile/' + activeAccount + '/' + hit.images[0].fileId + '/medium'"
                      uk-img
                      *ngIf="!hit.coverImage && hit.images && hit.images.length">

                    </div>

                    <!-- Jos ei ole coverImagea tai ekan imagea, ni sit otetaan placeholder -->
                    <div
                      class="image-max-height uk-preserve-width uk-background-contain uk-background-muted uk-height-small uk-panel uk-flex uk-flex-center uk-flex-middle"
                      width="60"
                      [attr.data-src]="'/api/image/artbuddy-logo-placeholder.svg'"
                      uk-img *ngIf="!hit.coverImage && !hit.images.length">

                    </div>
                  </ng-container>
                </td>
                <td class="uk-text-left" *ngIf="fields.includes('name')">
                  <div>{{ hit.name }} {{ hit.lastName }}</div>
                </td>
                <td class="uk-visible@m" *ngIf="fields.includes('number')">{{ hit.sequence }}</td>
                <td class="uk-visible@m uk-text-left" *ngIf="fields.includes('title')">{{ hit.name }}</td>
<!--                <td class="uk-visible@m uk-text-left" *ngIf="fields.includes('customer')">{{ hit.customer.name }}</td>-->
                <td class="uk-visible@m uk-text-left" *ngIf="fields.includes('type')">{{ hit.type }}</td>
                <td class="uk-visible@m" *ngIf="fields.includes('startDate')">{{ hit.startDate | date: 'd.M.yyyy' }}</td>
                <td class="uk-visible@m" *ngIf="fields.includes('location')">{{ hit.location }}</td>
                <td class="uk-text-right">
                  <div class="uk-button-group">
                    <button (click)="openAdvancedSearchResult(hit)" class="uk-button uk-button-default"
                            type="button">Open
                    </button>
                  </div>
                </td>
              </tr>
              </tbody>
            </table>
          </ng-template>
        </ais-hits>
      </div>
    </div>
  </ais-instantsearch>
</div>
