import { Injectable } from '@angular/core';
import { AccountService } from '../services/account.service';
import { ActiveService } from '../services/active.service';
import { Observable } from 'rxjs';
import { Subject } from 'rxjs';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import 'rxjs/add/operator/map';

@Injectable()
export class BidService {
  private httpOptions = {
    headers: new HttpHeaders({
      'Content-Type': 'application/json',
      charset: 'UTF-8'
    })
  };
  private currentAccount: any;
  currentBid: string = '';
  currentBidChange: Subject<string> = new Subject<string>();

  constructor(
    private http: HttpClient,
    private activeService: ActiveService,
    private accountService: AccountService
  ) {
    this.activeService.activeDataChange.subscribe(data => {
      this.currentAccount = data.account.id;
    });
  }

  setCurrentBid(bidId): void {
    this.currentBid = bidId;
    this.currentBidChange.next(this.currentBid);
  }

  getCurrentBid(): string {
    return this.currentBid;
  }

  getBids(accountId: String, BidType: String): Observable<any> {
    return this.http.get(`/api/bids/${accountId}/${BidType}`);
  }

  countBids(): Observable<any> {
    return this.http.get('/api/bids/count');
  }

  addBid(bid): Observable<any> {
    return this.http.post('/api/bid', JSON.stringify(bid), this.httpOptions);
  }

  getBid(bid): Observable<any> {
    return this.http.get(`/api/bid/${bid}`);
  }

  getUserBid(bid): Observable<any> {
    return this.http.get(`/api/userBid/${bid._id}`);
  }

  editBid(bid): Observable<any> {
    if (!bid.id) {
      bid.id = bid.personalFormId;
    }
    return this.http.put(
      `/api/bid/${bid.id}`,
      JSON.stringify(bid),
      this.httpOptions
    );
  }

  editBidApprovalStatus(bid): Observable<any> {
    return this.http.put(`/api/bid/singleValue/${bid.id}`, JSON.stringify(bid), this.httpOptions);
  }

  deleteBid(bidId): Observable<any> {
    return this.http.delete(`/api/bid/${bidId}`, this.httpOptions);
  }

  bidImageId(fileObject: object): Observable<any> {
    return this.http.post(
      '/api/bidImageId',
      JSON.stringify(fileObject),
      this.httpOptions
    );
  }
}
