import { Directive, HostListener } from '@angular/core';

@Directive({
  selector: '[appNoSubmitEnter]'
})
export class NoSubmitDirective {
  @HostListener('document:keydown', ['$event']) onKeydownHandler(event: any) { // changed to type any from KeyboardEvent to escape TypeScript errors
    if (
      event.key === 'Enter' &&
      event.target.localName !== 'textarea'
      && event.target.id !== 'loginPassword'
      && event.target.id !== 'loginEmail'
      && event.target.name !== 'searchGlobalTerm'
      && event.target.name !== 'searchArtworkTerm'
    ) {
      event.stopPropagation();
      event.preventDefault();
    }
  }
}
