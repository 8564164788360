import {Component, EventEmitter, OnInit, ViewChild} from '@angular/core';
import {FormBuilder, FormControl, FormGroup, Validators} from '@angular/forms';
import {ActivatedRoute, Router} from '@angular/router';
import {UserService} from '../../services/user.service';
import {ReservationService} from '../../services/reservation.service';
import {AccountService} from '../../services/account.service';
import {AuthService} from '../../services/auth.service';
import {ActiveService} from '../../services/active.service';
import {FileService} from '../../services/file.service';
import {SearchService} from '../../services/search.service';
import {humanizeBytes, UploaderOptions, UploadFile, UploadInput} from 'ngx-uploader';
import {AngularMyDatePickerDirective, IAngularMyDpOptions, IMyDateModel} from 'angular-mydatepicker';
import * as moment from 'moment/moment';
declare var UIkit: any;

@Component({
  selector: 'app-reservations',
  templateUrl: './reservations.component.html',
  styleUrls: ['./reservations.component.scss']
})
export class ReservationsComponent implements OnInit {
  public user: any = {};
  public activeReservation: any = {};
  private activeUser: string;
  public accountActor: any = {};
  public actorType: string;
  public activeAccount: string;
  public account: Object = {};
  public artists: Array<Object> = [];
  public actors: Array<Object> = [];
  public reservations: Array<Object> = [];
  public openReservations: Array<Object> = [];
  public isLoading = true;
  public addReservationForm: FormGroup;
  public addNewReservationModalWindow: any;
  public showOpenReservationsModalWindow: any;
  public advancedArtworkSearchModalWindow: any;
  public showAdvancedArtworkSearchModalWindow: boolean = false;
  public sortingKey: string;
  public sortAscending: boolean = false;
  public currentPage: number = 1;
  public limitQuery: number = 10;
  public pageCount: number = 1;
  public pages: Array<any> = [];
  public searchArtworkForm: FormGroup;
  public searchArtworkResults: Array<Object> = [];
  public selectedArtworks: Array<Object> = [];
  public searchResultsFound: boolean = false;
  public myDatePickerOptions: IAngularMyDpOptions = {
    dateFormat: 'dd.mm.yyyy'
  };
  private todaysDate: IMyDateModel;
  private validDate: IMyDateModel;
  @ViewChild('AngularMyDatePickerAddReservationCreated') AngularMyDatePickerAddReservationCreated: AngularMyDatePickerDirective;
  @ViewChild('AngularMyDatePickerAddReservationValid') AngularMyDatePickerAddReservationValid: AngularMyDatePickerDirective;
  public options: UploaderOptions;
  public formData: FormData;
  public filesToUpload: UploadFile[];
  public uploadInput: EventEmitter<UploadInput>;
  public humanizeBytes: Function;
  public dragOver: boolean;
  public imageLoadOffset$: any;
  public numberWithDecimal = new RegExp(/^[0-9]+(\.[0-9]{1,2})?$/);

  constructor(
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private formBuilder: FormBuilder,
    private auth: AuthService,
    private activeService: ActiveService,
    private userService: UserService,
    private accountService: AccountService,
    private reservationService: ReservationService,
    private fileService: FileService,
    private searchService: SearchService
  ) {
    this.activatedRoute.data.subscribe(data => {
      this.activeAccount = data.resolvedData.account.id;
      this.user = data.resolvedData.user.id;
    });

    this.todaysDate = {
      isRange: false,
      singleDate: {date: {year: new Date().getFullYear(), month: new Date().getMonth() + 1, day: new Date().getDate()}}
    };
    this.validDate = {
      isRange: false,
      singleDate: {date: {year: new Date().getFullYear(), month: new Date().getMonth() + 1, day: new Date().getDate()}}
    };

    this.addReservationForm = this.formBuilder.group({
      addReservationOwner: new FormControl('', [
        Validators.required,
        Validators.maxLength(100)
      ]),
      addReservationName: new FormControl('', [
        Validators.required,
        Validators.maxLength(100)
      ]),
      addReservationDescription: new FormControl('', [
        Validators.maxLength(2048)
      ]),
      addReservationType: new FormControl('', [
        Validators.required,
        Validators.maxLength(100)
      ]),
      addReservationSeller: new FormControl(null, [
        Validators.required,
        Validators.maxLength(100)
      ]),
      addReservationCustomer: new FormControl(null, [
        Validators.required,
        Validators.maxLength(100)
      ]),
      addReservationCreated: new FormControl('', [
        Validators.maxLength(10)
      ]),
      addReservationValid: new FormControl('', [
        Validators.maxLength(10)
      ]),
      addReservationOtherTerms: new FormControl('', [
        Validators.maxLength(2048)
      ]),
      addReservationClientReference: new FormControl('', [
        Validators.maxLength(100)
      ]),
      addReservationSellerReference: new FormControl('', [
        Validators.maxLength(100)
      ]),
      addReservationStatus: new FormControl('', [
        Validators.maxLength(30)
      ]),
      addReservationCurrency: new FormControl({value: '', disabled: true}, [
        Validators.maxLength(10)
      ]),
      addReservationVAT: new FormControl({value: '', disabled: true}, [
        Validators.maxLength(10),
        Validators.pattern(this.numberWithDecimal)
      ]),
      addReservationShowNotes: new FormControl('', [
        Validators.maxLength(100)
      ]),
      addReservationPrice: new FormControl('', [
        Validators.maxLength(100),
        Validators.pattern(this.numberWithDecimal)
      ]),
      addReservationAttachments: new FormControl('', [
        Validators.maxLength(100)
      ]),
      addReservationTags: new FormControl('', [
        Validators.maxLength(2048)
      ]),
      addReservationNotes: new FormControl('', [
        Validators.maxLength(2048)
      ]),
      addReservationComments: new FormControl('', [
        Validators.maxLength(2048)
      ]),
      addReservationVisibility: new FormControl('', [
        Validators.maxLength(10)
      ])
    });

    this.searchArtworkForm = this.formBuilder.group({
      searchArtworkTerm: new FormControl('', [
        Validators.required,
        Validators.minLength(3),
        Validators.maxLength(50)
      ])
    });

    this.activeReservation.items = [];
    this.filesToUpload = [];
    this.uploadInput = new EventEmitter<UploadInput>();
    this.humanizeBytes = humanizeBytes;
  }

  ngOnInit() {
    this.isLoading = true;
    this.addReservationForm.patchValue({
      addReservationOwner: this.activeAccount,
      addReservationType: 'Sell',
      addReservationCreated: this.todaysDate,
      addReservationValid: this.validDate
    });
    this.getAccountData();
  }

  getAccountData() {
    const getObject = {
      accountId: this.activeAccount,
      requester: 'reservations',
      sortingKey: this.sortingKey ? this.sortingKey : '_id',
      sortingOrder: this.sortAscending ? 'asc' : 'desc',
      skipQuery: (this.currentPage - 1) * this.limitQuery,
      limitQuery: this.limitQuery,
      contentTypes: ['account', 'artist', 'reservation', 'reservationCount', 'actor']
    };

    this.accountService.getAccountData(getObject).subscribe(
      data => {
        this.account = data.account;
        this.artists = data.artists;

        data.reservations = data.reservations.map((reservation) => {
          let itemsTotalPrice: number = 0;

          if (reservation.customer) {
            if (reservation.customer.lastName) {
              reservation.customer.fullName = reservation.customer.name + ' ' + reservation.customer.lastName;
            } else {
              reservation.customer.fullName = reservation.customer.name;
            }
          } else {
            reservation.customer = {
              fullName: null
            };
          }

          if (reservation.seller) {
            if (reservation.seller.lastName) {
              reservation.seller.fullName = reservation.seller.name + ' ' + reservation.seller.lastName;
            } else {
              reservation.seller.fullName = reservation.seller.name;
            }
          } else {
            reservation.seller = {
              fullName: null
            };
          }

          if (reservation.items && reservation.items.length) {
            for (let i = 0; i < reservation.items.length; i++) {
              const item: any = reservation.items[i];
              if (item.price && item.price.price) {
                itemsTotalPrice += item.price.price;
              }
            }
          }

          reservation.itemsTotalPrice = itemsTotalPrice > 0 ? itemsTotalPrice : "Unknown";

          return reservation;
        });

        this.reservations = data.reservations;
        this.openReservations = data.reservations.filter(item => {
          return (new Date(item.valid) >= new Date());
        });
        this.actors = data.actors;
        this.actors = this.actors.map((actor: any) => {
          let fullName = actor.name;
          if (actor.lastName) { fullName += ' ' + actor.lastName; }

          return {
            ...actor,
            fullName: fullName
          };
        });

        this.accountActor = data.account.actor;
        this.pageCount = Math.ceil(data.reservationCount / this.limitQuery);
        if (this.pageCount > 0) {
          this.pages = Array(this.pageCount).fill(null);
        }

        this.addReservationForm.patchValue({
          addReservationCurrency: data.account.tradingCurrency,
          addReservationVAT: data.account.vat
        });
      },
      () => {
        UIkit.notification({
          message: 'Error fetching clients',
          status: 'danger',
          pos: 'top-right',
          timeout: 1500
        });
      },
      () => this.isLoading = false
    );

    this.activeService.getActiveData().subscribe(
      data => {
        this.activeUser = data.user.id;
      }
    );
  }

  updateDate(fieldName: string, dateObject: any) {
    this.addReservationForm.controls[fieldName].patchValue(moment(dateObject.singleDate.jsDate).format());
  }

  showOpenReservations() {
    this.showOpenReservationsModalWindow = window.document.getElementById('showOpen');
    UIkit.modal(this.showOpenReservationsModalWindow).show();
  }

  openAddNewReservation() {
    this.addNewReservationModalWindow = window.document.getElementById('addNewReservation');
    UIkit.modal(this.addNewReservationModalWindow).show();
  }

  addReservation() {
    const addReservationObject = {
      owner: this.addReservationForm.value.addReservationOwner,
      name: this.addReservationForm.value.addReservationName,
      description: this.addReservationForm.value.addReservationDescription,
      items: this.activeReservation.items,
      /* If type = Sell, use account actor as seller; if type = Buy, use selected seller as seller */
      seller: this.addReservationForm.value.addReservationType === 'Sell' ? this.accountActor._id : this.addReservationForm.value.addReservationSeller,
      /* If type = Sell, use selected customer as customer; if type = Buy, use account actor as customer */
      customer: this.addReservationForm.value.addReservationType === 'Sell' ? this.addReservationForm.value.addReservationCustomer : this.accountActor._id,
      type: this.addReservationForm.value.addReservationType,
      created: this.addReservationForm.value.addReservationCreated ? moment(this.addReservationForm.value.addReservationCreated.singleDate.jsDate).format() : moment(new Date),
      valid: this.addReservationForm.value.addReservationValid ? moment(this.addReservationForm.value.addReservationValid.singleDate.jsDate).format() : moment(new Date),
      otherTerms: this.addReservationForm.value.addReservationOtherTerms,
      clientReference: this.addReservationForm.value.addReservationClientReference,
      sellerReference: this.addReservationForm.value.addReservationSellerReference,
      status: this.addReservationForm.value.addReservationStatus,
      currency: this.addReservationForm.value.addReservationCurrency,
      vat: this.addReservationForm.value.addReservationVAT,
      showNotes: this.addReservationForm.value.addReservationShowNotes,
      price: this.addReservationForm.value.addReservationPrice,
      /*attachments: this.addReservationForm.value.addReservationAttachments,*/
      tags: this.addReservationForm.value.addReservationTags,
      notes: this.addReservationForm.value.addReservationNotes,
      comments: this.addReservationForm.value.addReservationComments,
      visibility: this.addReservationForm.value.addReservationVisibility
    };

    this.reservationService.addReservation(addReservationObject).subscribe(
      res => {
        this.ngOnInit();
        UIkit.modal(this.addNewReservationModalWindow).hide();
        this.resetForm();
        UIkit.notification({
          message: 'Saved successfully',
          status: 'success',
          pos: 'top-right',
          timeout: 1500
        });
      },
      error => {
        UIkit.notification({
          message: 'Saving failed',
          status: 'danger',
          pos: 'top-right',
          timeout: 1500
        });
      }
    );
  }

  openReservation(reservationId): void {
    this.router.navigateByUrl('/reservation/' + reservationId);
  }

  removeReservation(reservationId): void {
    this.reservationService.deleteReservation(reservationId).subscribe(
      () => {
        this.ngOnInit();
        UIkit.notification({
          message: 'Reservation removed successfully',
          status: 'success',
          pos: 'top-right',
          timeout: 1500
        });
      },
      () => {
        UIkit.notification({
          message: 'Error removing reservations',
          status: 'danger',
          pos: 'top-right',
          timeout: 1500
        });
      }
    );
  }

  sortReservations(sortingKey: string): void {
    if (sortingKey !== this.sortingKey) {
      this.sortingKey = sortingKey;
    } else {
      this.sortAscending = !this.sortAscending;
    }
    this.getAccountData();
  }

  activeSort(key: string): boolean {
    return this.sortingKey === key;
  }

  resetForm(): void {
    this.addReservationForm.reset();
    this.addReservationForm.patchValue({
      addReservationOwner: this.activeAccount,
      addReservationType: 'Sell',
      addReservationCreated: this.todaysDate,
      addReservationValid: this.validDate
    });

    this.activeReservation.items = [];
    this.searchArtworkResults = [];
    this.emptySearch();
  }

  searchArtwork() {
    /*this.isLoading = true;*/
    if (this.searchArtworkForm.value.searchArtworkTerm.trim().length === 0) {
      return;
    }

    const searchObject = {
      searchArtworkAccountId: this.activeAccount,
      searchArtworkUserId: this.activeUser,
      searchArtworkTerm: this.searchArtworkForm.value.searchArtworkTerm
    };
    this.searchService.searchArtwork(searchObject).subscribe(
      res => {
        if (res.length) {
          if (this.searchArtworkResults) {
            this.searchArtworkResults.length = 0;
          }
          res.forEach(artwork => {
            artwork.allResultType = 'artwork';
            this.searchArtworkResults.push(artwork);
          });
          /*this.isLoading = false;*/
          this.searchResultsFound = true;
          /*document.getElementById('search-artwork-results-container').classList.add('active');*/
          Array.prototype.forEach.call(document.getElementsByClassName('results-container'), function (element) {
            element.classList.add('active');
          });


          UIkit.notification({
            message: 'Search matched ' + this.searchArtworkResults.length + ' results',
            status: 'success',
            pos: 'top-right',
            timeout: 1500
          });
        } else {
          if (this.searchArtworkResults) {
            this.searchArtworkResults.length = 0;
          }
          UIkit.notification({
            message: 'No search results',
            status: 'danger',
            pos: 'top-right',
            timeout: 1500
          });
        }
      },
      error => {
        if (this.searchArtworkResults) {
          this.searchArtworkResults.length = 0;
        }
        UIkit.notification({
          message: 'No search results',
          status: 'danger',
          pos: 'top-right',
          timeout: 1500
        });
      }
    );
  }

  emptySearch(): void {
    /*document.getElementById('search-artwork-results-container').classList.remove('active');*/
    /*Array.prototype.forEach.call(document.getElementsByClassName('results-container'), function (element) {
      element.classList.remove('active');
    });*/

    /*let element: HTMLElement = document.getElementsByClassName('uk-navbar-toggle uk-close')[1] as HTMLElement;
    element.click();*/
    setTimeout(() => {
      this.searchResultsFound = false;
      if (this.searchArtworkResults) {
        this.searchArtworkResults.length = 0;
      }
      if (this.selectedArtworks) {
        this.selectedArtworks.length = 0;
      }
      this.searchArtworkForm.patchValue({searchArtworkTerm: ''});
    }, 300);
  }

  selectReservationArtwork(artwork): void {
    this.selectedArtworks.indexOf(artwork) === -1 ?
      this.selectedArtworks.push(artwork) : this.selectedArtworks.splice(this.selectedArtworks.indexOf(artwork), 1);
  }

  addArtworksToReservation(): void {
    this.selectedArtworks.map((item: any) => {
      const itemFound = this.activeReservation.items.find((activeReservationItem: any) => {
        return activeReservationItem._id === item._id;
      });
      if (itemFound === undefined) {
        this.activeReservation.items.push(item);
      }
      const artistFound = this.artists.find((artist: any) => {
        return artist._id === item.artist;
      });
      if (artistFound === undefined) {
        this.artists.push({
          _id: item.artist,
          name: item.artistName,
          priceMultiplier: item.artistPriceMultiplier,
          status: item.artistStatus
        });
      }
    });

    this.emptySearch();
  }

  removeArtworkFromReservation(item): void {
    this.activeReservation.items.splice(this.activeReservation.items.indexOf(item), 1);
    const artistToSplice = this.artists.find((artist: any) => {
      return artist._id === item.artist;
    });
    this.artists.splice(this.artists.indexOf(artistToSplice), 1);
  }

  openSlideshow($event): void {
    $event.preventDefault();
    UIkit.lightboxPanel({
      /* Ei tarvitse, jos muistaa käyttää $event.preventDefaultia
        autoplay: true,
        autoplayInterval: 5000,
        delayControls: 1000,*/
      items: this.activeReservation.items.map(item => {
        let itemImage;
        if (item.coverImage) {
          itemImage = '/api/image/' + item.coverImage;
        } else if (item.images[0]) {
          itemImage = '/api/image/' + item.images[0].fileId;
        } else {
          itemImage = '/api/image/artbuddy-logo-placeholder.svg';
        }

        return {
          source: itemImage,
          caption: item.name
        };
      })
    }).show();
  }


  setReservationType(): void {
    if (this.addReservationForm.value.addReservationType === 'Sell') {
      /* If type = Sell, use account actor as seller and use selected customer as customer */
      /*this.addReservationForm.patchValue({
        AddReservationCustomer: null
      });*/
    } else {
      /* if type = Buy, use selected seller as seller and account actor as customer */
      /*this.addReservationForm.patchValue({
        addReservationSeller: null
      });*/
    }
  }

  openAdvancedSearch(): void {
    this.showAdvancedArtworkSearchModalWindow = true;
    setTimeout(() => {
      this.advancedArtworkSearchModalWindow = window.document.getElementById('advancedArtworkSearch');
      UIkit.modal(this.advancedArtworkSearchModalWindow).show();
    }, 500);

  }

  setArtworkSearchResults(searchResult: Array<any>) {
    searchResult.forEach(item => this.selectedArtworks.push(item));
    this.addArtworksToReservation();
  }

  setLimit(limit: number): void {
    if (limit !== this.limitQuery) {
      this.limitQuery = limit;
      /* Set currentPage to 0 to start from the first page */
      this.currentPage = 1;
      this.getAccountData();
    }
  }

  activeLimit(limit: number): boolean {
    return this.limitQuery === limit;
  }

  setCurrentPageString(page: string): void {
    this.setCurrentPage(parseInt(page, 0));
  }

  setCurrentPage(page: number): void {
    if ((page !== this.currentPage) && (page <= this.pageCount)) {
      this.currentPage = page;
      this.getAccountData();
    }
  }

  getCharactersLimitError(fieldName: string) {
    if (!fieldName || !this.addReservationForm.get(fieldName).value ) {
      return ''
    }

    const charactersOverLimit: number = this.addReservationForm.get(fieldName).value.length - this.addReservationForm.get(fieldName).errors.maxlength.requiredLength;

    return `Field length is ${ charactersOverLimit } characters over limit`;
  }

  getCharactersLeft(fieldName: string, charactersLimit: number) {
    if (!fieldName || !this.addReservationForm.get(fieldName).value ) {
      return ''
    }

    const fieldLength: number = this.addReservationForm.get(fieldName).value.length;
    const charactersOverLimit: number = fieldLength - charactersLimit;

    if (charactersOverLimit >= 0) { return `${ charactersLimit } / ${ charactersLimit }` }

    return `${ fieldLength } / ${ charactersLimit }`;
  }
}
