import {Component, EventEmitter, OnInit, ViewChild} from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { FormGroup, FormControl, Validators, FormBuilder } from '@angular/forms';
import { AuthService } from '../services/auth.service';
import { ToastComponent } from '../shared-components/toast/toast.component';
declare var UIkit: any;

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit {
  loginForm: FormGroup;
  resetRequested: boolean = false;
  returnUrl: string;

  constructor(private auth: AuthService,
              private formBuilder: FormBuilder,
              private route: ActivatedRoute,
              private router: Router,
              public toast: ToastComponent
  ) {}

  ngOnInit() {
    this.returnUrl = this.route.snapshot.queryParams['returnUrl'] || '/';
    this.route.queryParams.subscribe(
      params => {
        if (params.resetRequested == 'true') {
          this.resetRequested = true;
        }
      });

    if (this.auth.loggedIn) {
      this.router.navigate([this.returnUrl]);
    }
    this.loginForm = this.formBuilder.group({
      loginEmail: new FormControl('', [
        Validators.required,
        Validators.minLength(3),
        Validators.maxLength(100)]),
      loginPassword: new FormControl('', [
        Validators.required,
        Validators.minLength(6)])
    });
  }

  login() {
    this.auth.login(this.loginForm.value).subscribe(
      res => {
        UIkit.notification({
          message: 'Logged in successfully',
          status: 'success',
          pos: 'top-right',
          timeout: 1500
        });
        this.router.navigate([this.returnUrl]);
      },
      error => {
        UIkit.notification({
          message: 'Invalid email or password',
          status: 'danger',
          pos: 'top-right',
          timeout: 1500
        });
      }
    );
  }

  goForgot(): void {
    this.router.navigateByUrl('/forgot');
  }

}
