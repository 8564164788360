import {Component, EventEmitter, OnInit, ViewChild} from '@angular/core';
import { Router } from '@angular/router';
import { FormGroup, FormControl, Validators, FormBuilder } from '@angular/forms';
import { ToastComponent } from '../shared-components/toast/toast.component';
import { UserService } from "../services/user.service";
declare var UIkit: any;

@Component({
  selector: 'app-forgot',
  templateUrl: './forgot.component.html',
  styleUrls: ['./forgot.component.scss']
})
export class ForgotComponent implements OnInit {
  forgotForm: FormGroup;

  constructor(
    private userService: UserService,
    private formBuilder: FormBuilder,
    private router: Router,
    public toast: ToastComponent
  ) {}

  ngOnInit() {
    this.forgotForm = this.formBuilder.group({
      forgotEmail: new FormControl('',
        [
          Validators.required,
          Validators.minLength(3),
          Validators.maxLength(100)
        ]
      )
    });
  }

  forgot() {
    this.userService.forgot(this.forgotForm.value).subscribe(
      res => {
        UIkit.notification({
          message: 'Sent email successfully',
          status: 'success',
          pos: 'top-right',
          timeout: 1500
        });
        this.router.navigate(['/login'], { queryParams: { resetRequested: true } });
      },
      error => {
        UIkit.notification({
          message: 'Error sending email to provided address',
          status: 'danger',
          pos: 'top-right',
          timeout: 1500
        });
      }
    );
  }

}
