import {Component, Inject, forwardRef, Input, Optional} from '@angular/core';
import { BaseWidget, NgAisInstantSearch } from 'angular-instantsearch';
import { connectSearchBox } from 'instantsearch.js/es/connectors';
import {NgAisIndex} from 'angular-instantsearch';

@Component({
  selector: 'app-ais-search-box',
  template: `<form (submit)="search(input.value)" class="uk-flex uk-flex-nowrap uk-flex-1 uk-position-relative uk-width-1-1">
      <input
        type="text"
        class="uk-input uk-margin-small-right margin-top-1"
        #input
        [value]="this.state.query"
        placeholder="Search all content..."
      />
      <!-- (keyup)="this.state.refine(input.value)" -->
      <button class="uk-button uk-button-primary uk-margin-small-right" (click)="search(input.value)" type="submit">Search</button>
      <button id="clearAdvancedSearch" class="uk-button uk-button-default" (click)="clear()">Clear</button>
  </form>`
})
export class SearchAisSearchBoxComponent extends BaseWidget {
  @Input() title: string;
  @Input() attribute: string;
  @Input() sortName: string;
  @Input() sortDescription: string;

  public state: {
    query: string;
    refine: Function;
    clear: Function;
    isSearchStalled: boolean;
  };

  constructor(
    @Inject(forwardRef(() => NgAisIndex))
    @Optional()
    public parentIndex: NgAisIndex,
    @Inject(forwardRef(() => NgAisInstantSearch))
    public instantSearchInstance: NgAisInstantSearch
  ) {
    super('SearchBox');
    this.createWidget(connectSearchBox, {});
  }

  clear(): void {
    this.state.clear();
  }

  search(value: string): void {
    this.state.refine(value);
  }
}
