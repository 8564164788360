import {Component, OnInit} from '@angular/core';
import {Router, NavigationEnd} from '@angular/router';
import {AuthService} from 'app/services/auth.service';
import {ActiveService} from 'app/services/active.service';
import {AccountService} from 'app/services/account.service';
import {SearchService} from 'app/services/search.service';
import {FormGroup, FormControl, Validators, FormBuilder} from '@angular/forms';
import {environment} from 'environments/environment';

import { AdvancedSearchWrapperComponent } from './search-components/advanced-search-wrapper/advanced-search-wrapper.component';
import { ViewChild,
  ComponentFactoryResolver,
  ViewContainerRef } from '@angular/core';

declare var UIkit: any;
import {AngularMyDatePickerDirective, IAngularMyDpOptions, IMyDateModel} from 'angular-mydatepicker';
import { Observable } from 'rxjs';
import { delay } from 'rxjs/operators';


@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss']
})
export class HeaderComponent implements OnInit {
  public loggedIn: boolean;
  public searchGlobalForm: FormGroup;
  public advancedSearchForm: FormGroup;
  public isLoading = false;
  public activeAccount: string;
  private activeUser: string;
  public searchGlobalResults: any;
  public algoliaConfig: any;
  public searchResultsFound = false;
  public advancedSearchModalWindow: any;
  public activeContentType: string;
  public account: any = {};
  public myDatePickerOptions: IAngularMyDpOptions = {
    dateFormat: 'dd.mm.yyyy'
  };
  private todaysDate: IMyDateModel;
  private validDate: IMyDateModel;
  @ViewChild('AngularMyDatePickerAddReservationCreated') myDp: AngularMyDatePickerDirective;
  @ViewChild('AngularMyDatePickerAddReservationValid') myDp2: AngularMyDatePickerDirective; public algolia: any = {};
  public offset = 100;
  public imageLoadOffset$: any;
  public backendConnection = true;

  @ViewChild('parent', { read: ViewContainerRef, static: false }) container: ViewContainerRef;

  constructor(
    private router: Router,
    private auth: AuthService,
    private activeService: ActiveService,
    private accountService: AccountService,
    private searchService: SearchService,
    private formBuilder: FormBuilder,
    private _cfr: ComponentFactoryResolver
  ) {
    this.loggedIn = this.auth.getLoggedInStatus();

    router.events.subscribe(event => {
      if (event instanceof NavigationEnd) {
        const urlLength = event.url.length;
        this.activeContentType = event.url.substring(urlLength - (urlLength - 1));
      }
    });
    this.activeService.activeDataChange.subscribe(data => {
      this.activeAccount = data.account.id;
      this.algoliaConfig = {
        appId: environment.ALGOLIASEARCH_APPLICATION_ID,
        apiKey: environment.ALGOLIASEARCH_API_KEY,
        indexName: this.activeAccount
      };
    });

    this.searchGlobalForm = this.formBuilder.group({
      searchGlobalTerm: new FormControl('', [
        Validators.required,
        Validators.minLength(3),
        Validators.maxLength(50)
      ])
    });

    this.advancedSearchForm = this.formBuilder.group({
      advancedSearchTerm: new FormControl('', [
        Validators.required,
        Validators.minLength(1),
        Validators.maxLength(50)
      ])
    });
  }

  addComponent() {
    const comp = this._cfr.resolveComponentFactory(AdvancedSearchWrapperComponent);
    const expComponent = this.container.createComponent(comp);
    expComponent.instance._ref = expComponent;
  }

  ngOnInit() {
    this.auth.loggedInStatus.subscribe(data => {
      this.loggedIn = data;
    });

    this.activeService.activeDataChange.subscribe(data => {

      const getObject = {
        accountId: data.account.id,
        requester: 'header',
        sortingKey: '_id',
        sortingOrder: 'desc',
        skipQuery: 0,
        limitQuery: 5,
        contentTypes: ['account']
      };

      if (getObject.accountId && getObject.accountId !== '') {
        this.accountService.getAccountData(getObject).subscribe(
          data => {
            this.account = data.account;
          }
        );
      }
    });

    this.activeService.getActiveData().subscribe(
      data => {
        this.activeAccount = data.account.id;
        this.activeUser = data.user.id;

        this.algolia = {
          appId: environment.ALGOLIASEARCH_APPLICATION_ID,
          apiKey: environment.ALGOLIASEARCH_API_KEY,
          indexName: this.activeAccount
        };

        const getObject = {
          accountId: this.activeAccount,
          requester: 'header',
          sortingKey: '_id',
          sortingOrder: 'desc',
          skipQuery: 0,
          limitQuery: 5,
          contentTypes: ['account']
        };

        if (getObject.accountId !== '' && getObject.accountId !== undefined) {
          this.accountService.getAccountData(getObject).subscribe(
            data => {
              this.account = data.account;

            }
          );
        }
      }
    );

    setInterval(() => {
      this.activeService.queryServerStatus().subscribe(response => {
        if (!response || !response.status || response.status !== "OK") {
          this.backendConnection = false;
        }
      });
    }, 10000);
  }

  public getActiveAccount(): string {
    return this.activeAccount;
  }

  searchGlobal() {
    this.isLoading = true;
    const searchObject = {
      searchGlobalAccountId: this.activeAccount,
      searchGlobalUserId: this.activeUser,
      searchGlobalTerm: this.searchGlobalForm.value.searchGlobalTerm
    };
    this.searchService.searchGlobal(searchObject).subscribe(
      res => {
        /*this.ngOnInit();*/
        this.searchGlobalResults = {};
        this.searchGlobalResults = res;
        this.searchGlobalResults.all = [];

        if (res.artists) {
          res.artists.forEach(artist => {
            artist.allResultType = 'artist';
            this.searchGlobalResults.all.push(artist);
          });
        }
        if (res.items) {
          res.items.forEach(artwork => {
            artwork.allResultType = 'artwork';
            this.searchGlobalResults.all.push(artwork);
          });
        }
        if (res.exhibitions) {
          res.exhibitions.forEach(exhibition => {
            exhibition.allResultType = 'exhibition';
            this.searchGlobalResults.all.push(exhibition);
          });
        }
        if (res.collections) {
          res.collections.forEach(collection => {
            collection.allResultType = 'collection';
            this.searchGlobalResults.all.push(collection);
          });
        }
        if (res.clients) {
          res.clients.forEach(client => {
            client.allResultType = 'client';
            this.searchGlobalResults.all.push(client);
          });
        }
        if (res.estimates) {
          res.estimates.forEach(estimate => {
            estimate.allResultType = 'estimate';
            this.searchGlobalResults.all.push(estimate);
          });
        }
        if (res.bids) {
          res.bids.forEach(bid => {
            bid.allResultType = 'bid';
            this.searchGlobalResults.all.push(bid);
          });
        }
        if (res.trades) {
          res.trades.forEach(trade => {
            trade.allResultType = 'trade';
            this.searchGlobalResults.all.push(trade);
          });
        }
        if (res.users) {
          res.users.forEach(user => {
            user.allResultType = 'user';
            this.searchGlobalResults.all.push(user);
          });
        }
        this.isLoading = false;
        this.searchResultsFound = true;
        document.getElementById('search-global-results-container').classList.add('active');



        if (this.searchGlobalResults.all.length) {
          UIkit.notification({
            message: 'Search matched ' + this.searchGlobalResults.all.length + ' results',
            status: 'success',
            pos: 'top-right',
            timeout: 1500
          });
        } else {
          UIkit.notification({
            message: 'No search results',
            status: 'danger',
            pos: 'top-right',
            timeout: 1500
          });
        }
      },
      error => {
        UIkit.notification({
          message: 'No search results',
          status: 'danger',
          pos: 'top-right',
          timeout: 1500
        });
      }
    );
  }

  openAdvancedSearch(): void {
    /*const comp = this._cfr.resolveComponentFactory(AdvancedSearchWrapperComponent);
    const expComponent = this.container.createComponent(comp);
    expComponent.instance._ref = expComponent;*/
    /*this.advancedSearchModalWindow = window.document.getElementById('advanced-search-container');
    UIkit.modal(this.advancedSearchModalWindow).show();*/

    this.activeService.clearSelections();

    const clearButton = document.getElementById('clearAdvancedSearch');
    if (clearButton) {
      clearButton.click();
    }

    const closeButton = document.getElementById('advancedSearchCloseButton');
    if (closeButton) {
      clearButton.click();
    }

    const navAdvancedSearchButton = document.getElementById('navSearchBar');

    if (navAdvancedSearchButton) {
      navAdvancedSearchButton.click();
    }

    this.router.navigateByUrl('/search');
  }

  doAdvancedSearch(): void {}

  emptySearch(): void {
    document.getElementById('search-global-results-container').classList.remove('active');
    setTimeout(() => {
      this.searchResultsFound = false;
      if (this.searchGlobalResults && this.searchGlobalResults.all) {
        this.searchGlobalResults.all.length = 0;
      }
      this.searchGlobalForm.patchValue({searchGlobalTerm: ''});
    }, 300);
  }

  closeNav(): void {
    this.activeService.clearSelections();
    Array.prototype.forEach.call(document.getElementsByClassName('uk-open'), function (element) {
      element.classList.remove('uk-open');
      element.setAttribute('aria-expanded', false);
    });
  }

  closeOffcanvasNav(): void {
    this.activeService.clearSelections();
    const element: HTMLElement = document.getElementsByClassName('uk-offcanvas-close')[0] as HTMLElement;
    element.click();
  }

  openSearchResult(item): void {
    if (item.allResultType !== 'user') {
      this.router.navigateByUrl('/' + item.allResultType + '/' + item._id);
      this.emptySearch();
      const element: HTMLElement = document.getElementsByClassName('uk-navbar-toggle uk-close')[0] as HTMLElement;
      element.click();
    } else {
      this.router.navigateByUrl('/profile');
    }
  }

  openAccountSettings(): void {
    this.activeService.clearSelections();
    this.activeService.getActiveData().subscribe(data => {
      this.router.navigateByUrl('/account/' + data.account.id);
    });
  }

  openAdvancedSearchResult(resultItem: any): void {
    this.activeService.clearSelections();

    const clearButton = document.getElementById('clearAdvancedSearch');
    clearButton.click();

    const closeButton = document.getElementById('advancedSearchCloseButton');
    closeButton.click();

    const navAdvancedSearchButton = document.getElementById('navSearchBar');
    navAdvancedSearchButton.click();

    this.router.navigateByUrl(`/${resultItem.contentType}/${resultItem._id}`);
  }


}
