<div id="editingHistory" class="uk-inline" *ngIf="updatedAt">
  <button class="uk-button uk-button-link uk-text-meta pointer" type="button">
    <span uk-icon="icon: history; ratio: 0.75"></span>
  </button>
  <div class="uk-card uk-card-small uk-card-body uk-card-default" uk-drop="mode: hover">
    <div class="uk-margin-remove-bottom uk-text-small">Last edited</div>
    <div class="uk-text-meta uk-margin-remove">
      <div class="uk-margin-small-top" *ngIf="updatedAt">{{ getLastUpdateTime(updatedAt) }}</div>
      <div>
        <span *ngIf="name">{{ name }}</span>
        <span *ngIf="email"> ({{ email }})</span>
      </div>
    </div>
  </div>
</div>
