import {Component, Inject, forwardRef, OnInit, Optional} from '@angular/core';
import {BaseWidget, NgAisInstantSearch} from 'angular-instantsearch';
import {connectHitsPerPage} from 'instantsearch.js/es/connectors';
import {NgAisIndex} from 'angular-instantsearch';

@Component({
  selector: 'app-ais-hits-per-page',
  template: `<select (change)="state.refine($event.target.value)" class="uk-select">
    <option
      *ngFor="let item of state.items"
      [value]="item.value"
      [selected]="item.isRefined"
    >
      {{ item.label }}
    </option>
  </select>`
})
export class SearchAisHitsPerPageComponent extends BaseWidget implements OnInit {
  public state: {
    items: object[];
    hasNoResults: boolean;
    refine: Function;
    widgetParams: object;
  };

  constructor(
    @Inject(forwardRef(() => NgAisIndex))
    @Optional()
    public parentIndex: NgAisIndex,
    @Inject(forwardRef(() => NgAisInstantSearch))
    public instantSearchInstance: NgAisInstantSearch
  ) {
    super('HitsPerPage');
  }

  ngOnInit() {
    this.createWidget(connectHitsPerPage, {
      items: [
        {value: 10, label: 'Limit: 10', default: true},
        {value: 25, label: 'Limit: 25'},
        {value: 50, label: 'Limit: 50'},
        {value: 100, label: 'Limit: 100'},
        {value: 200, label: 'Limit: 200'},
        {value: 99999, label: 'Limit: All'},
      ],
    });
    super.ngOnInit();
  }

}
