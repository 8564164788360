import {Component, Input, OnInit} from '@angular/core';
import { ActiveService } from "../../services/active.service";

@Component({
  selector: 'app-loading',
  templateUrl: './loading.component.html',
  styleUrls: ['./loading.component.scss']
})
export class LoadingComponent {
  public condition: boolean = false;

  constructor(
    private activeService: ActiveService
  ) {
    this.activeService.loadingStatusChange.subscribe(
      loadingStatus => {
        this.condition = loadingStatus;
      }
    );
  }
}
