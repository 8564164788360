import {Component, EventEmitter, OnDestroy, OnInit, Output} from "@angular/core";
import {Router} from "@angular/router";
import {FormBuilder, FormGroup} from "@angular/forms";
import {AuthService} from "app/services/auth.service";
import {ActiveService} from "app/services/active.service";
import {AccountService} from "app/services/account.service";
import {SearchService} from "app/services/search.service";
import {CollectionService} from "../../../services/collection.service";
import {BidService} from "../../../services/bid.service";
import {FileService} from "../../../services/file.service";
import algoliasearch from "algoliasearch/lite";
import * as moment from "moment/moment";
import {IAngularMyDpOptions} from "angular-mydatepicker";

import {environment} from "environments/environment";
import {ItemService} from "../../../services/item.service";
import {ActorService} from "../../../services/actor.service";
import {ReservationService} from "../../../services/reservation.service";
import {TradeService} from "../../../services/trade.service";
import {ExhibitionService} from "../../../services/exhibition.service";

declare const UIkit: any;

@Component({
  selector: "app-advanced-search",
  templateUrl: "./advanced-search-wrapper.component.html",
  styleUrls: ["./advanced-search-wrapper.component.scss"]
})
export class AdvancedSearchWrapperComponent implements OnInit, OnDestroy {
  public loggedIn: boolean;
  public searchGlobalForm: FormGroup;
  public advancedSearchForm: FormGroup;
  public isLoading = false;
  public activeAccount: string;
  private activeUser: string;
  public searchGlobalResults: any;
  public algoliaConfig: any;
  public algoliaSearchParameters: any;
  public searchResultsFound = false;
  public advancedSearchModalWindow: any;
  public createListModalWindow: any;
  public activeContentType: string;
  public account: any = {};
  public selectedArtworks: Array<any> = [];
  public collections: Array<Object> = [];
  public bids: Array<Object> = [];
  private itemToRemove: string;
  public removeItemModalWindow: any;
  public addToExistingCollectionModalWindow: any;
  public addToExistingBidModalWindow: any;
  public sortingKey: string;
  public sortAscending = false;
  public myDatePickerOptions: IAngularMyDpOptions = {
    dateFormat: "dd.mm.yyyy"
  };
  public algolia: any = {};
  public offset = 100;
  public imageLoadOffset$: any;
  _ref: any;
  public searchParameters = {
    hitsPerPage: 10,
    distinct: true
  };
  public defaultContentType = "artwork";
  public defaultContentTypeSet;
  public printAsList = true;

  @Output() setResults: EventEmitter<any> = new EventEmitter<any>();

  constructor(
    private router: Router,
    private auth: AuthService,
    private formBuilder: FormBuilder,
    private activeService: ActiveService,
    private accountService: AccountService,
    private searchService: SearchService,
    private actorService: ActorService,
    private itemService: ItemService,
    private exhibitionService: ExhibitionService,
    private collectionService: CollectionService,
    private bidService: BidService,
    private reservationService: ReservationService,
    private tradeService: TradeService,
    private fileService: FileService,
  ) {
    this.isLoading = true;
    this.defaultContentTypeSet = true;
    this.loggedIn = this.auth.getLoggedInStatus();

    /*router.events.subscribe(event => {
      if (event instanceof NavigationEnd) {
        const urlLength = event.url.length;
        this.activeContentType = event.url.substring(urlLength - (urlLength - 1));
      }
    });*/

    this.activeService.getActiveData().subscribe(
      data => {
        this.activeAccount = data.account.id;
        this.activeUser = data.user.id;

        this.algolia = {
          indexName: this.activeAccount,
          searchClient: algoliasearch(
            environment.ALGOLIASEARCH_APPLICATION_ID,
            environment.ALGOLIASEARCH_API_KEY,
          ),
          routing: true,
          /*searchFunction(helper) {
            console.log(helper);
            helper.search();
          }*/
        };

        this.algoliaSearchParameters = {
          hitsPerPage: 100,
          distinct: true,
          filters: "hidden:false",
        };

        this.isLoading = false;

        const getObject = {
          accountId: this.activeAccount,
          requester: "advanced-search",
          sortingKey: null,
          sortingOrder: "desc",
          skipQuery: 0,
          limitQuery: 99999,
          contentTypes: ["account", "collection"]
        };

        if (getObject.accountId !== "" && getObject.accountId !== undefined) {
          this.accountService.getAccountData(getObject).subscribe(
            (response: any) => {
              this.account = response.account;
              this.collections = response.collections;
            }
          );
        }
      }
    );
  }



  public getActiveAccount(): string {
    return this.activeAccount;
  }

  openAdvancedSearchResult(resultItem: any, allItems?: Array<any>): void {
    let previousItems: Array<any>;
    let nextItems: Array<any>;
    let selectedItemIndex: number;
    let itemsCount: number;

    const clearButton = document.getElementById("clearAdvancedSearch");
    if (clearButton) {
      clearButton.click();
    }

    if (this._ref) {
      this._ref.destroy();
    }

    if (["artwork", "artist"].includes(resultItem.contentType)) {
      allItems = allItems.filter((item: any) => item.contentType === resultItem.contentType);

      selectedItemIndex = allItems.findIndex((item: any) => item._id === resultItem._id);
      itemsCount = allItems.length;

      if (selectedItemIndex > 0) {
        previousItems = allItems.slice(0, selectedItemIndex);
      }
      if (selectedItemIndex < itemsCount) {
        nextItems = allItems.slice(selectedItemIndex + 1, itemsCount);
      }

      this.router.navigateByUrl(`/${ resultItem.contentType }/${ resultItem._id }`,
        {
          state: {
            currentItem: allItems[selectedItemIndex],
            previousItems: previousItems,
            nextItems: nextItems,
          }
        }
      );
    } else {
      this.router.navigateByUrl(`/${ resultItem.contentType }/${ resultItem._id }`);
    }
    /*const closeButton = document.getElementById('advancedSearchCloseButton');
    if (closeButton) {
      closeButton.click();
    }

    const navAdvancedSearchButton = document.getElementById('navSearchBar');
    if (navAdvancedSearchButton) {
      navAdvancedSearchButton.click();
    }*/

    /* Completely remove the component from DOM */

    /*if (window.document.getElementById('advanced-search-container')) {
      /!*UIkit.modal(window.document.getElementById('advanced-search-container')).hide();*!/
      window.document.getElementById('advanced-search-container').remove();
    }*/
  }

  openRemoveItem(item: any) {
    if (!item) { return; }

    this.itemToRemove = item;
    this.removeItemModalWindow = window.document.getElementById("removeItem");
    UIkit.modal(this.removeItemModalWindow).show();
  }

  openRemoveMultipleItems() {
    UIkit.modal(window.document.getElementById("removeMultipleItems")).show();
  }

  resetArtworkToRemove(): void {
    this.itemToRemove = null;
  }

  removeItem(resultItem: any): void {
    switch (resultItem.contentType) {
      case "artist":
        this.actorService.deleteActor(resultItem._id).subscribe(
        () => {
          window.document.getElementById("hit" + resultItem._id).classList.add("uk-hidden");
          UIkit.notification({
            message: "Artist removed successfully",
            status: "success",
            pos: "top-right",
            timeout: 1500
          });

          setTimeout(() => {
            window.document.getElementById("refreshResults").click();
          }, 2500);
        },
        () => {
          UIkit.notification({
            message: "Artist removing artwork",
            status: "danger",
            pos: "top-right",
            timeout: 1500
          });
        }
      );
        break;
      case "artwork":
        this.itemService.deleteItem(resultItem._id).subscribe(
          () => {
            window.document.getElementById("hit" + resultItem._id).classList.add("uk-hidden");
            UIkit.notification({
              message: "Artwork removed successfully",
              status: "success",
              pos: "top-right",
              timeout: 1500
            });

            setTimeout(() => {
              window.document.getElementById("refreshResults").click();
            }, 2500);
          },
          () => {
            UIkit.notification({
              message: "Error removing artwork",
              status: "danger",
              pos: "top-right",
              timeout: 1500
            });
          }
        );
        break;
      case "exhibition":
        this.exhibitionService.deleteExhibition(resultItem._id).subscribe(
          () => {
            window.document.getElementById("hit" + resultItem._id).classList.add("uk-hidden");
            UIkit.notification({
              message: "Exhibition removed successfully",
              status: "success",
              pos: "top-right",
              timeout: 1500
            });

            setTimeout(() => {
              window.document.getElementById("refreshResults").click();
            }, 2500);
          },
          () => {
            UIkit.notification({
              message: "Error removing exhibition",
              status: "danger",
              pos: "top-right",
              timeout: 1500
            });
          }
        );
        break;
      case "collection":
        this.collectionService.deleteCollection(resultItem._id).subscribe(
          () => {
            window.document.getElementById("hit" + resultItem._id).classList.add("uk-hidden");
            UIkit.notification({
              message: "Collection removed successfully",
              status: "success",
              pos: "top-right",
              timeout: 1500
            });

            setTimeout(() => {
              window.document.getElementById("refreshResults").click();
            }, 2500);
          },
          () => {
            UIkit.notification({
              message: "Error removing collection",
              status: "danger",
              pos: "top-right",
              timeout: 1500
            });
          }
        );
        break;
      case "client":
        this.actorService.deleteActor(resultItem._id).subscribe(
          () => {
            window.document.getElementById("hit" + resultItem._id).classList.add("uk-hidden");
            UIkit.notification({
              message: "Client removed successfully",
              status: "success",
              pos: "top-right",
              timeout: 1500
            });

            setTimeout(() => {
              window.document.getElementById("refreshResults").click();
            }, 2500);
          },
          () => {
            UIkit.notification({
              message: "Error removing client",
              status: "danger",
              pos: "top-right",
              timeout: 1500
            });
          }
        );
        break;
      case "bid":
        this.bidService.deleteBid(resultItem._id).subscribe(
          () => {
            window.document.getElementById("hit" + resultItem._id).classList.add("uk-hidden");
            UIkit.notification({
              message: "Bid removed successfully",
              status: "success",
              pos: "top-right",
              timeout: 1500
            });

            setTimeout(() => {
              window.document.getElementById("refreshResults").click();
            }, 2500);
          },
          () => {
            UIkit.notification({
              message: "Error removing bid",
              status: "danger",
              pos: "top-right",
              timeout: 1500
            });
          }
        );
        break;
      case "reservation":
        this.reservationService.deleteReservation(resultItem._id).subscribe(
          () => {
            window.document.getElementById("hit" + resultItem._id).classList.add("uk-hidden");
            UIkit.notification({
              message: "Reservation removed successfully",
              status: "success",
              pos: "top-right",
              timeout: 1500
            });

            setTimeout(() => {
              window.document.getElementById("refreshResults").click();
            }, 2500);
          },
          () => {
            UIkit.notification({
              message: "Error removing reservation",
              status: "danger",
              pos: "top-right",
              timeout: 1500
            });
          }
        );
        break;
      case "trade":
        this.tradeService.deleteTrade(resultItem._id).subscribe(
          () => {
            window.document.getElementById("hit" + resultItem._id).classList.add("uk-hidden");
            UIkit.notification({
              message: "Trade removed successfully",
              status: "success",
              pos: "top-right",
              timeout: 1500
            });

            setTimeout(() => {
              window.document.getElementById("refreshResults").click();
            }, 2500);
          },
          () => {
            UIkit.notification({
              message: "Trade removing artwork",
              status: "danger",
              pos: "top-right",
              timeout: 1500
            });
          }
        );
        break;
      default:
        UIkit.notification({
          message: "Error removing item",
          status: "danger",
          pos: "top-right",
          timeout: 1500
        });
    }
  }

  async removeMultiple(): Promise<void> {
    const removedDocuments: Array<any> = [];

    for (const artwork of this.selectedArtworks) {
      await new Promise<void>((resolve, reject) => {
        this.itemService.deleteItem(artwork._id).subscribe(
          () => {
            removedDocuments.push(artwork._id);
            resolve();
          },
          () => {
            UIkit.notification({
              message: "Error removing artwork",
              status: "danger",
              pos: "top-right",
              timeout: 1500
            });
            reject();
          }
        );
      });
    }

    setTimeout(() => {
      for (const artworkId of removedDocuments) {
        window.document.getElementById("hit" + artworkId).classList.add("uk-hidden");
      }

      window.document.getElementById("refreshResults").click();
      this.removeSelections();

      UIkit.notification({
        message: "Artworks removed successfully",
        status: "success",
        pos: "top-right",
        timeout: 1500
      });
    }, 2500);
  }

  removeObject() {
    this._ref.destroy();
  }

  ngOnDestroy(): void {
    /*this._ref.destroy();*/
  }

  closeAddToDropdown(): void {
    const addToDropdownElement = window.document.getElementById("addToDropdown");
    UIkit.dropdown(addToDropdownElement).hide();
  }

  toggleSelect(event: any, item: any): void {
    if (event && item) {
      const itemFound = this.selectedArtworks.find((selectedItem: any) => {
        return item._id === selectedItem._id;
      });

      if (itemFound === undefined) {
        if (item && item._id && item.name) {
          this.selectedArtworks.push(item);
        }
      } else {
        if (item && item._id) {
          const itemToSplice = this.selectedArtworks.find((artwork: any) => {
            return artwork._id === item._id;
          });
          this.selectedArtworks.splice(this.selectedArtworks.indexOf(itemToSplice), 1);
        }
      }
    }
  }

  toggleSelected(hits: Array<any>): void {
    hits.forEach(hit => {
      const foundElement = document.getElementById("toggleSelect-" + hit._id);
      if (foundElement) {
        foundElement.click();
      }
    });
  }

  selectAll(hits: Array<any>): void {
    this.removeSelections();

    setTimeout(() => {
      window.document.getElementById("toggleSelected").click();
    }, 500);
  }

  getSelected(hitId: string): boolean {
    return this.selectedArtworks.some(selectedArtwork => selectedArtwork._id === hitId);
  }

  removeSelections(): void {
    this.selectedArtworks.forEach((selectedArtwork: any) => {
      if (window.document.getElementById("toggleSelect-" + selectedArtwork._id)) {
        UIkit.formCustom(window.document.getElementById("toggleSelect-" + selectedArtwork._id)).$el.checked = false;
      }
    });

    this.selectedArtworks = [];
  }

  openAddToExistingCollection() {
    this.closeAddToDropdown();
    this.updateCollections();
  }

  closeAddToExistingCollection(): void {
    this.addToExistingCollectionModalWindow = window.document.getElementById("addToExistingCollection");
    UIkit.modal(this.addToExistingCollectionModalWindow).hide();
  }

  openAddToExistingBid() {
    this.closeAddToDropdown();
    this.updateBids();
  }

  closeAddToExistingBid(): void {
    this.addToExistingBidModalWindow = window.document.getElementById("addToExistingBid");
    UIkit.modal(this.addToExistingBidModalWindow).hide();
  }

  activeSort(key: string): boolean {
    return this.sortingKey === key;
  }

  updateCollections() {
    const getObject = {
      accountId: this.activeAccount,
      requester: "advanced-search",
      sortingKey: this.sortingKey ? this.sortingKey : "_id",
      sortingOrder: this.sortAscending ? "asc" : "desc",
      skipQuery: 0,
      limitQuery: 99999,
      contentTypes: ["collection"]
    };

    this.accountService.getAccountData(getObject).subscribe(
      data => {
        this.collections = data.collections;

        this.addToExistingCollectionModalWindow = window.document.getElementById("addToExistingCollection");
        if (this.addToExistingCollectionModalWindow) {
          UIkit.modal(this.addToExistingCollectionModalWindow).show();
        }
      },
      () => {
        UIkit.notification({
          message: "Error fetching collections",
          status: "danger",
          pos: "top-right",
          timeout: 1500
        });
      },
      () => this.isLoading = false
    );
  }

  updateBids() {
    const getObject = {
      accountId: this.activeAccount,
      requester: "advanced-search",
      sortingKey: this.sortingKey ? this.sortingKey : "_id",
      sortingOrder: this.sortAscending ? "asc" : "desc",
      skipQuery: 0,
      limitQuery: 99999,
      contentTypes: ["bid"]
    };

    this.accountService.getAccountData(getObject).subscribe(
      data => {
        this.bids = data.bids;
        this.addToExistingBidModalWindow = window.document.getElementById("addToExistingBid");
        if (this.addToExistingBidModalWindow) {
          UIkit.modal(this.addToExistingBidModalWindow).show();
        }
      },
      () => {
        UIkit.notification({
          message: "Error fetching bids",
          status: "danger",
          pos: "top-right",
          timeout: 1500
        });
      },
      () => {}
    );
  }

  sortCollections(sortingKey: string): void {
    if (sortingKey !== this.sortingKey) {
      this.sortingKey = sortingKey;
    } else {
      this.sortAscending = !this.sortAscending;
    }
    this.updateCollections();
  }

  sortBids(sortingKey: string): void {
    if (sortingKey !== this.sortingKey) {
      this.sortingKey = sortingKey;
    } else {
      this.sortAscending = !this.sortAscending;
    }
    this.updateBids();
  }

  createCollection(): void {
    const today = moment().locale("fi").format("L");
    const itemsArray = [];
    this.selectedArtworks.forEach(artwork => {
      itemsArray.push(artwork._id);
    });

    const addCollectionObject = {
      owner: this.activeAccount,
      name: "Collection-" + today,
      items: itemsArray,
    };

    this.removeSelections();
    this.closeAddToDropdown();

    this.collectionService.addCollection(addCollectionObject).subscribe(
      res => {
        this.ngOnInit();
        /*UIkit.modal(this.addNewCollectionModalWindow).hide();
        this.resetForm();*/
        UIkit.notification({
          message: "Collection created successfully",
          status: "success",
          pos: "top-right",
          timeout: 1500
        });
      },
      error => {
        UIkit.notification({
          message: "Creating collection failed",
          status: "danger",
          pos: "top-right",
          timeout: 1500
        });
      }
    );
  }

  addToExistingCollection(addToExistingCollection: any): void {
    const itemsArray = [];
    this.selectedArtworks.forEach(artwork => {
      itemsArray.push(artwork._id);
    });

    /* Concatenate arrays, may have duplicates*/
    const concatArr = [...addToExistingCollection.items, ...itemsArray];

    /* Remove duplicates */
    const mergedItemArray = Array.from(new Set([...concatArr]));

    const editCollectionObject = {
      id: addToExistingCollection._id,
      owner: addToExistingCollection.owner,
      name: addToExistingCollection.name,
      description: addToExistingCollection.description,
      items: mergedItemArray,
      type: addToExistingCollection.type,
      location: addToExistingCollection.location,
      notes: addToExistingCollection.notes,
      comments: addToExistingCollection.comments,
      tags: addToExistingCollection.tags,
      visibility: addToExistingCollection.visibility,
      attachments: addToExistingCollection.attachments
    };

    this.collectionService.editCollection(editCollectionObject).subscribe(
      res => {
        this.updateCollections();
        this.removeSelections();
        this.closeAddToExistingCollection();

        UIkit.notification({
          message: "Added to collection successfully",
          status: "success",
          pos: "top-right",
          timeout: 1500
        });
      },
      error => {
        UIkit.notification({
          message: "Adding failed",
          status: "danger",
          pos: "top-right",
          timeout: 1500
        });
      }
    );
  }

  createBid(): void {
    const today = moment.utc().locale("fi").format("L");
    const itemsArray = [];
    this.selectedArtworks.forEach(artwork => {
      itemsArray.push(artwork._id);
    });

    const addBidObject = {
      owner: this.activeAccount,
      name: "Bid-" + today,
      description: "",
      items: itemsArray,
      seller: this.account.actor,
      customer: this.account.actor,
      type: "Sell",
      created: new Date(),
      valid: new Date(),
      otherTerms: "",
      clientReference: "",
      sellerReference: "",
      status: "",
      currency: "",
      vat: "",
      showNotes: "",
      price: "",
      tags: "",
      notes: "",
      comments: "",
      visibility: ""
    };

    this.removeSelections();
    this.closeAddToDropdown();

    this.bidService.addBid(addBidObject).subscribe(
      res => {
        this.ngOnInit();
        /*UIkit.modal(this.addNewBidModalWindow).hide();
        this.resetForm();*/
        UIkit.notification({
          message: "Bid created successfully",
          status: "success",
          pos: "top-right",
          timeout: 1500
        });
      },
      error => {
        UIkit.notification({
          message: "Creating bid failed",
          status: "danger",
          pos: "top-right",
          timeout: 1500
        });
      }
    );
  }

  addToExistingBid(addToExistingBid: any): void {
    const itemsArray = [];
    this.selectedArtworks.forEach(artwork => {
      itemsArray.push(artwork._id);
    });

    /* Concatenate arrays, may have duplicates*/
    const concatArr = [...addToExistingBid.items, ...itemsArray];

    /* Remove duplicates */
    const mergedItemArray = Array.from(new Set([...concatArr])); // [1, 2, 3, 4, 5]

    const editBidObject = {
      id: addToExistingBid._id,
      owner: addToExistingBid.owner,
      name: addToExistingBid.name,
      description: addToExistingBid.description,
      items: mergedItemArray,
      type: addToExistingBid.type,
      location: addToExistingBid.location,
      notes: addToExistingBid.notes,
      comments: addToExistingBid.comments,
      tags: addToExistingBid.tags,
      visibility: addToExistingBid.visibility,
      attachments: addToExistingBid.attachments
    };

    this.bidService.editBid(editBidObject).subscribe(
      res => {
        this.updateBids();
        this.removeSelections();
        this.closeAddToExistingBid();

        UIkit.notification({
          message: "Added to bid successfully",
          status: "success",
          pos: "top-right",
          timeout: 1500
        });
      },
      error => {
        UIkit.notification({
          message: "Adding failed",
          status: "danger",
          pos: "top-right",
          timeout: 1500
        });
      }
    );
  }

  updateItemSettings(settingsType: string, item: any, event) {
    const changedItemIndex = this.selectedArtworks.findIndex(collectionItem => collectionItem._id === item._id);
    this.selectedArtworks[changedItemIndex][settingsType] = event.currentTarget.checked;
  }

  togglePriceSelections(): void {
    this.selectedArtworks.forEach((item: any, index: number) => {
      const itemShowPriceButton = window.document.getElementById("showPrice-" + item._id);
      itemShowPriceButton.click();
    });
  }

  toggleArtworkCodeSelections(): void {
    this.selectedArtworks.forEach((item: any, index: number) => {
      const itemShowArtworkCodeButton = window.document.getElementById("showArtworkCode-" + item._id);
      itemShowArtworkCodeButton.click();
    });
  }

  toggleQRCodeSelections(): void {
    this.selectedArtworks.forEach((item: any, index: number) => {
      const itemShowQRCodeButton = window.document.getElementById("showQRCode-" + item._id);
      itemShowQRCodeButton.click();
    });
  }

  openPrintListModal(asList: boolean): void {
    this.printAsList = asList;
    this.createListModalWindow = window.document.getElementById("createList");
    if (this.createListModalWindow) {
      UIkit.modal(this.createListModalWindow).show();
    }
  }

  printList(): void {
    const printListArray = [];
    const resultArray = [];
    let collectionArtworks = this.selectedArtworks;

    Array.from(UIkit.sortable(document.getElementById("createListSortable")).$el.children).forEach(
      (element: any, index, array) => {
        printListArray.push(element.dataset.artworkid);
      }
    );

    if (printListArray.length === 0) {
      this.createListModalWindow = window.document.getElementById("createList");
      if (this.createListModalWindow) {
        UIkit.modal(this.createListModalWindow).hide();
      }

      const selectNoneButton = window.document.getElementById("removeSelections");
      if (selectNoneButton) {
        selectNoneButton.click();
      }

      return UIkit.notification({
        message: "Printing PDF failed. Please try again",
        status: "danger",
        pos: "top-right",
        timeout: 1500
      });
    }

    printListArray.forEach((artworkid: string) => {
      let found = false;
      collectionArtworks = collectionArtworks.filter(function (collectionArtwork) {
        if (!found && collectionArtwork._id === artworkid) {
          resultArray.push(collectionArtwork);
          found = true;
          return false;
        } else {
          return true;
        }
      });
    });

    const printListObject = {
      items: [],
      notes: {},
      pricing: {},
      printAsList: this.printAsList
    };

    if (resultArray.length) {
      resultArray.forEach(item => {
        const itemName = item.name;
        let itemImage = null;

        if (item.coverImage) {
          itemImage = item.coverImage;
        } else if (item.images && item.images.length) {
          itemImage = item.images[0].fileId;
        }

        const itemArtistName = item.artistName;
        const itemCreated = moment.utc(item.created).isValid() ? moment.utc(item.created).locale("fi").format("YYYY") : null;
        const itemTechnique = item.technique;

        /* Näitä tietoja ei varmaankaan tarvita */
        let itemSize = "";
        if (item.height) {
          itemSize += item.height;
        }
        if (item.width) {
          if (item.height) {
            itemSize += " x ";
          }
          itemSize += item.width;
        }
        if (item.depth) {
          if (item.height) {
            itemSize += " x ";
          }
          itemSize += item.depth;
        }

        const itemAvailability = item.availability;

        let listObject: any = {};
        listObject = {
          "id": item._id,
          "account": this.activeAccount,
          "Artist": itemArtistName,
          "Item name": itemName,
          "showPrice": item.showPrice === true,
          "showArtworkCode": item.showArtworkCode === true,
          "showQRCode": item.showQRCode === true,
        };

        if (itemCreated) {
          listObject["Item created"] = itemCreated;
        }

        if (itemTechnique) {
          listObject["Item technique"] = itemTechnique;
        }

        if (itemSize) {
          listObject["Item size"] = itemSize + " cm";
        }

        if (!item.hidePrice) {
          if (item.price.price) {
            listObject["Item price"] = item.price.price + " " + (item.price.currency ? item.price.currency : "");
          }
        }

        /*if (itemAvailability) {
          listObject['Item availability'] = itemAvailability;
        }*/

        if (item.listNotes) {
          listObject["Item notes"] = item.listNotes;
        }

        if (itemImage) {
          listObject.Image = itemImage;
        }

        listObject["code"] = item.code;

        printListObject.items.push(listObject);
      });

      if (printListObject.items.length > 0) {
        this.fileService.getPDF("Collection-list", printListObject);
      } else {
        UIkit.notification({
          message: "Printing PDF failed. Please try again",
          status: "danger",
          pos: "top-right",
          timeout: 1500
        });
      }
    }
  }

  ngOnInit(): void {
  }

  filterHiddenDocuments(hits: any): any {
    if (!hits || hits.length === 0) {
      return [];
    }

    return hits.filter((hit: any) => {
      return hit.hidden !== true;
    });
  }
}
