import {Component, EventEmitter, OnInit, ViewChild} from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';
import {FormBuilder, FormControl, FormGroup, Validators} from '@angular/forms';
import {AuthService} from '../../services/auth.service';
import {UserService} from '../../services/user.service';
import {ActiveService} from '../../services/active.service';
import {AccountService} from '../../services/account.service';
import {TradeService} from '../../services/trade.service';
import {FileService} from '../../services/file.service';
import {SearchService} from '../../services/search.service';
import {ReservationService} from '../../services/reservation.service';
import {ItemService} from '../../services/item.service';
import {humanizeBytes, UploaderOptions, UploadFile, UploadInput, UploadOutput} from 'ngx-uploader';
import {HttpHeaders} from '@angular/common/http';
import {AngularMyDatePickerDirective, IAngularMyDpOptions, IMyDateModel} from 'angular-mydatepicker';
import * as moment from 'moment/moment';

declare var UIkit: any;

@Component({
  selector: 'app-single-reservation',
  templateUrl: './single-reservation.component.html',
  styleUrls: ['./single-reservation.component.scss']
})
export class SingleReservationComponent implements OnInit {
  public user: any = {};
  public activeReservation: any = {};
  public activeReservationId = '';
  public accountActor: any = {};
  public actorType: string;
  public activeAccount: any = {};
  private activeUser: string;
  public accounts: Array<Object> = [];
  public artists: Array<Object> = [];
  public actors: Array<Object> = [];
  public VATRates: Array<Object> = [];
  public itemVATs: Array<Object> = [];
  public isLoading = true;
  public uploadLoading: Boolean = false;
  public addNewArtworkModalWindow: any;
  public advancedArtworkSearchModalWindow: any;
  public showAdvancedArtworkSearchModalWindow = false;
  public editReservationForm: FormGroup;
  public searchArtworkForm: FormGroup;
  public searchArtworkResults: Array<Object> = [];
  public selectedArtworks: Array<Object> = [];
  public searchResultsFound = false;
  public VATPercentage = 0;
  public tradingCurrency = '';
  public myDatePickerOptions: IAngularMyDpOptions = {
    dateFormat: 'dd.mm.yyyy'
  };
  @ViewChild('AngularMyDatePickerEditReservationCreated') AngularMyDatePickerEditReservationCreated: AngularMyDatePickerDirective;
  @ViewChild('AngularMyDatePickerEditReservationValid') AngularMyDatePickerEditReservationValid: AngularMyDatePickerDirective;
  public options: UploaderOptions;
  public filesToUpload: UploadFile[];
  public uploadInput: EventEmitter<UploadInput>;
  public humanizeBytes: Function;
  public dragOver: boolean;
  private httpOptions = {
    headers: new HttpHeaders({
      'Content-Type': 'application/json',
      'charset': 'UTF-8'
    })
  };
  public imageLoadOffset$: any;
  public numberWithDecimal = new RegExp(/^[0-9]+(\.[0-9]{1,2})?$/);

  constructor(
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private formBuilder: FormBuilder,
    private auth: AuthService,
    private userService: UserService,
    private activeService: ActiveService,
    private tradeService: TradeService,
    private accountService: AccountService,
    private reservationService: ReservationService,
    private fileService: FileService,
    private searchService: SearchService,
    private itemService: ItemService
  ) {
    this.activatedRoute.data.subscribe(data => {
      this.activeReservation = data.resolvedData;
      if (this.activeReservation.items) {
        this.activeReservation.items = this.activeReservation.items.map(item => {
          item.created = moment.utc(item.created).locale('fi').format('L');
          /*switch (item.availability) {
            case 0:
              item.availability = 'Sold';
              break;
            case 1:
              item.availability = 'Booked';
              break;
            case 2: default:
            item.availability = 'Available';
            break;
          }*/

          const itemRowIndex = this.activeReservation.itemRows.findIndex((itemRow: any) => itemRow._id === item._id);
          item = {
            VATPercentage: this.activeReservation.itemRows[itemRowIndex] && this.activeReservation.itemRows[itemRowIndex].vatpercentage ? this.activeReservation.itemRows[itemRowIndex].vatpercentage : 24,
            discount: this.activeReservation.itemRows[itemRowIndex] && this.activeReservation.itemRows[itemRowIndex].discount ? this.activeReservation.itemRows[itemRowIndex].discount : 0,
            ...item
          };

          const artistFound = this.artists.find((artist: any) => {
            return artist._id === item.artist;
          });

          if (artistFound === undefined) {
            this.artists.push({
              _id: item.artist,
              name: item.artistName,
              priceMultiplier: item.artistPriceMultiplier,
              status: item.artistStatus
            });
          }

          return item;
        });
      } else {
        this.activeReservation.items = [];
      }

      this.activeReservation.created = this.createDate(this.activeReservation.created);
      this.activeReservation.valid = this.createDate(this.activeReservation.valid);
      this.activeReservationId = data.resolvedData._id;
    });

    this.editReservationForm = this.formBuilder.group({
      editReservationId: new FormControl('', [
        Validators.required,
        Validators.maxLength(100)
      ]),
      editReservationOwner: new FormControl('', [
        Validators.required,
        Validators.maxLength(100)
      ]),
      editReservationName: new FormControl('', [
        Validators.required,
        Validators.maxLength(100)
      ]),
      editReservationDescription: new FormControl('', [
        Validators.maxLength(2048)
      ]),
      editReservationSeller: new FormControl({value: '', disabled: true}, [
        Validators.required,
        Validators.maxLength(100)
      ]),
      editReservationCustomer: new FormControl({value: '', disabled: true}, [
        Validators.required,
        Validators.maxLength(100)
      ]),
      editReservationType: new FormControl('', [
        Validators.required,
        Validators.maxLength(100)
      ]),
      editReservationCreated: new FormControl('', [
        Validators.maxLength(10)
      ]),
      editReservationValid: new FormControl('', [
        Validators.maxLength(10)
      ]),
      editReservationOtherTerms: new FormControl('', [
        Validators.maxLength(2048)
      ]),
      editReservationClientReference: new FormControl('', [
        Validators.maxLength(100)
      ]),
      editReservationSellerReference: new FormControl('', [
        Validators.maxLength(100)
      ]),
      editReservationStatus: new FormControl('', [
        Validators.maxLength(30)
      ]),
      editReservationCurrency: new FormControl({value: '', disabled: true}, [
        Validators.maxLength(10)
      ]),
      editReservationShowNotes: new FormControl('', [
        Validators.maxLength(100)
      ]),
      editReservationPrice: new FormControl('', [
        Validators.maxLength(100),
        Validators.pattern(this.numberWithDecimal)
      ]),
      editReservationAttachments: new FormControl('', [
        Validators.maxLength(100)
      ]),
      editReservationTags: new FormControl('', [
        Validators.maxLength(2048)
      ]),
      editReservationNotes: new FormControl('', [
        Validators.maxLength(2048)
      ]),
      editReservationComments: new FormControl('', [
        Validators.maxLength(2048)
      ]),
      editReservationVisibility: new FormControl('', [
        Validators.maxLength(10)
      ])
    });

    this.searchArtworkForm = this.formBuilder.group({
      searchArtworkTerm: new FormControl('', [
        Validators.required,
        Validators.minLength(3),
        Validators.maxLength(50)
      ])
    });

    this.filesToUpload = [];
    this.uploadInput = new EventEmitter<UploadInput>();
    this.humanizeBytes = humanizeBytes;
  }

  ngOnInit() {
    if ([null, undefined].includes(this.activeReservationId)) {
      this.activeReservationId = this.reservationService.getCurrentReservation();
    }

    this.getUser();
  }

  getUser() {
    this.activeService.getActiveData().subscribe(
      data => {
        this.activeAccount = data.account.id;
        this.activeUser = data.user.id;
        this.userService.getUser(data.user).subscribe(
          data => {
            this.user = data;
            /*if (this.user.account[0]) {
              this.user.role = this.user.account[0].role;
            }*/
            this.getUsersAccounts();
          },
          () => {
            UIkit.notification({
              message: 'Error fetching user information',
              status: 'danger',
              pos: 'top-right',
              timeout: 1500
            });
          }
        );
      }
    );
    this.getAccountData();
  }

  getAccountData() {
    const getObject = {
      accountId: this.activeAccount,
      requester: 'reservations',
      sortingKey: '_id',
      sortingOrder: 'desc',
      skipQuery: 0,
      limitQuery: null,
      contentTypes: ['account', 'artist', 'actor', 'variable']
    };

    this.accountService.getAccountData(getObject).subscribe(
      data => {
        this.artists = data.artists;
        this.actors = data.actors;
        this.accountActor = data.account.actor;
        this.VATPercentage = data.account.vat;
        this.tradingCurrency = data.account.tradingCurrency;
        this.VATRates = data.account.VATRates;

        this.isLoading = false;
      },
      () => {
        UIkit.notification({
          message: 'Error fetching data',
          status: 'danger',
          pos: 'top-right',
          timeout: 1500
        });
      },
    )
  }

  getUsersAccounts() {
    this.accountService.getUserAccounts(this.user).subscribe(
      data => {
        this.accounts = data;
      },
      () => {
        UIkit.notification({
          message: 'Error fetching accounts',
          status: 'danger',
          pos: 'top-right',
          timeout: 1500
        });
      }
    );
  }

  editReservation(silent?: Boolean) {
    const itemRows: Array<Object> = [];

    this.activeReservation.items.forEach(item => {
      itemRows.push({
        _id: item._id,
        vatpercentage: item.VATPercentage,
        discount: item.discount
      });
    });

    const editReservationObject = {
      id: this.editReservationForm.value.editReservationId,
      owner: this.editReservationForm.value.editReservationOwner,
      name: this.editReservationForm.value.editReservationName,
      description: this.editReservationForm.value.editReservationDescription,
      items: this.activeReservation.items,
      itemRows: itemRows,
      actor: this.editReservationForm.value.editReservationSeller,
      seller: this.editReservationForm.value.editReservationType === 'Sell' ? this.accountActor._id : this.editReservationForm.value.editReservationSeller,
      customer: this.editReservationForm.value.editReservationType === 'Sell' ? this.editReservationForm.value.editReservationCustomer : this.accountActor._id,
      type: this.editReservationForm.value.editReservationType,
      created: this.editReservationForm.value.editReservationCreated ? moment(this.editReservationForm.value.editReservationCreated.singleDate.jsDate).format() : moment(new Date),
      valid: this.editReservationForm.value.editReservationValid ? moment(this.editReservationForm.value.editReservationValid.singleDate.jsDate).format() : moment(new Date),
      otherTerms: this.editReservationForm.value.editReservationOtherTerms,
      clientReference: this.editReservationForm.value.editReservationClientReference,
      sellerReference: this.editReservationForm.value.editReservationSellerReference,
      status: this.editReservationForm.value.editReservationStatus,
      currency: this.editReservationForm.value.editReservationCurrency,
      showNotes: this.editReservationForm.value.editReservationShowNotes,
      price: this.editReservationForm.value.editReservationPrice,
      attachments: this.activeReservation.attachments,
      tags: this.editReservationForm.value.editReservationTags,
      notes: this.editReservationForm.value.editReservationNotes,
      comments: this.editReservationForm.value.editReservationComments,
      visibility: this.editReservationForm.value.editReservationVisibility
    };
    this.reservationService.editReservation(editReservationObject).subscribe(
      res => {
        if (silent !== true) {
          UIkit.notification({
            message: 'Saved successfully',
            status: 'success',
            pos: 'top-right',
            timeout: 1500
          });
        }
      },
      error => {
        if (silent !== true) {
          UIkit.notification({
            message: 'Saving failed',
            status: 'danger',
            pos: 'top-right',
            timeout: 1500
          });
        }
      }
    );
  }

  approveReservation(reservation) {
    const approveReservationObject = {
      id: reservation._id,
      status: true
    };
    this.reservationService.editReservationApprovalStatus(approveReservationObject).subscribe(
      res => {
        this.editReservationForm.patchValue({
          editReservationStatus: true
        });
        UIkit.notification({
          message: 'Reservation approved successfully',
          status: 'success',
          pos: 'top-right',
          timeout: 1500
        });
      },
      error => {
        UIkit.notification({
          message: 'Reservation closing failed',
          status: 'danger',
          pos: 'top-right',
          timeout: 1500
        });
      }
    );
  }

  searchArtwork() {
    /*this.isLoading = true;*/
    const searchObject = {
      searchArtworkAccountId: this.activeAccount,
      searchArtworkUserId: this.activeUser,
      searchArtworkTerm: this.searchArtworkForm.value.searchArtworkTerm
    };
    this.searchService.searchArtwork(searchObject).subscribe(
      res => {
        if (res.length) {
          if (this.searchArtworkResults) {
            this.searchArtworkResults.length = 0;
          }
          res.forEach(artwork => {
            artwork.allResultType = 'artwork';
            this.searchArtworkResults.push(artwork);
          });
          /*this.isLoading = false;*/
          this.searchResultsFound = true;
          document.getElementById('search-artwork-results-container').classList.add('active');


          UIkit.notification({
            message: 'Search matched ' + this.searchArtworkResults.length + ' results',
            status: 'success',
            pos: 'top-right',
            timeout: 1500
          });
        } else {
          if (this.searchArtworkResults) {
            this.searchArtworkResults.length = 0;
          }
          UIkit.notification({
            message: 'No search results',
            status: 'danger',
            pos: 'top-right',
            timeout: 1500
          });
        }
      },
      error => {
        if (this.searchArtworkResults) {
          this.searchArtworkResults.length = 0;
        }
        UIkit.notification({
          message: 'No search results',
          status: 'danger',
          pos: 'top-right',
          timeout: 1500
        });
      }
    );
  }

  emptySearch(): void {
    /*document.getElementById('search-artwork-results-container').classList.remove('active');
    let element: HTMLElement = document.getElementsByClassName('uk-navbar-toggle uk-close')[1] as HTMLElement;
    element.click();*/

    setTimeout(() => {
      this.searchResultsFound = false;
      if (this.searchArtworkResults) {
        this.searchArtworkResults.length = 0;
      }
      if (this.selectedArtworks) {
        this.selectedArtworks.length = 0;
      }
      this.searchArtworkForm.patchValue({searchArtworkTerm: ''});
    }, 300);
  }

  selectReservationArtwork(artwork): void {
    this.selectedArtworks.indexOf(artwork) === -1 ?
      this.selectedArtworks.push(artwork) : this.selectedArtworks.splice(this.selectedArtworks.indexOf(artwork), 1);
  }

  addArtworksToReservation(): void {
    this.selectedArtworks.map((item: any) => {
      const itemFound = this.activeReservation.items.find((activeReservationItem: any) => {
        return activeReservationItem._id === item._id;
      });
      if (itemFound === undefined) {
        this.activeReservation.items.push(item);
      }
      const artistFound = this.artists.find((artist: any) => {
        return artist._id === item.artist;
      });
      if (artistFound === undefined) {
        this.artists.push({
          _id: item.artist,
          name: item.artistName,
          priceMultiplier: item.artistPriceMultiplier,
          status: item.artistStatus
        });
      }
    });

    this.editReservation(true);
    this.emptySearch();
  }

  removeArtworkFromReservation(item): void {
    this.activeReservation.items.splice(this.activeReservation.items.indexOf(item), 1);
    const artistToSplice = this.artists.find((artist: any) => {
      return artist._id === item.artist;
    });
    this.artists.splice(this.artists.indexOf(artistToSplice), 1);
  }

  openSlideshow($event): void {
    $event.preventDefault();
    UIkit.lightboxPanel({
      /* Ei tarvitse, jos muistaa käyttää $event.preventDefaultia
        autoplay: true,
        autoplayInterval: 5000,
        delayControls: 1000,*/
      items: this.activeReservation.items.map(item => {
        let itemImage;
        if (item.coverImage) {
          itemImage = '/api/image/' + item.coverImage;
        } else if (item.images[0]) {
          itemImage = '/api/image/' + item.images[0].fileId;
        } else {
          itemImage = '/api/image/artbuddy-logo-placeholder.svg';
        }

        return {
          source: itemImage,
          caption: item.name
        };
      })
    }).show();
  }

  openArtist(artistId): void {
    this.router.navigateByUrl('/artist/' + artistId);
  }

  onAttachmentsUploadOutput(output: UploadOutput): void {
    const properFile = output.file && output.file.name && output.file.name.length > 0;

    if (output.type === 'allAddedToQueue') {
      const event: UploadInput = {
        type: 'uploadAll',
        url: '/api/file',
        method: 'POST',
        includeWebKitFormBoundary: true
      };
      this.uploadInput.emit(event);
    } else if (output.type === 'addedToQueue' && properFile) {
      if (this.filesToUpload.indexOf(output.file) == -1) {
        this.filesToUpload.push(output.file);
      }
    } else if (output.type === 'uploading' && properFile) {
      if (!this.activeReservation['attachments'].some(image => image.placeholderId === output.file.id)) {
        this.uploadLoading = true;
        this.activeService.increasePendingRequests();
        this.activeReservation['attachments'].push({placeholderId: output.file.id});
        const index = this.filesToUpload.findIndex(file => file.id === output.file.id);
        this.filesToUpload[index] = output.file;
      }
    } else if (output.type === 'removed') {
      // remove file from array when removed
      this.filesToUpload = this.filesToUpload.filter((file: UploadFile) => file !== output.file);
    } else if (output.type === 'dragOver') {
      this.dragOver = true;
    } else if (output.type === 'dragOut') {
      this.dragOver = false;
    } else if (output.type === 'drop') {
      this.dragOver = false;
    } else if (output.type === 'removedAll') {
      this.filesToUpload.length = 0;
    } else if (output.type === 'done') {
      const fileObject = {
        itemId: this.activeReservationId,
        fileId: output.file.response,
        name: output.file.name,
        contentType: output.file.type
      };
      this.removeFile(output.file.id);
      const indexToReplace = this.activeReservation['attachments'].findIndex(file => file.placeholderId === output.file.id);
      this.activeReservation['attachments'][indexToReplace] = fileObject;
      this.activeService.decreasePendingRequests();
      this.uploadLoading = false;

      /*this.activeReservation.images.push({
        /!*contentType: output.file.response.mimetype,
        filename: output.file.response.filename,*!/
        fileId: output.file.response.id
      });*/
      /*});*/
    }
  }

  startUpload(): void {
    const event: UploadInput = {
      type: 'uploadAll',
      url: '/api/file',
      method: 'POST',
      includeWebKitFormBoundary: true
    };
    this.uploadInput.emit(event);
  }

  cancelUpload(id: string): void {
    this.uploadInput.emit({type: 'cancel', id: id});
  }

  removeFile(id: string): void {
    this.uploadInput.emit({type: 'remove', id: id});
  }

  openAttachment(attachment) {
    this.itemService.openAttachment(this.activeAccount, attachment).then((response: any) => {
      if (response.url) {
        window.open(response.url);
      } else {
        UIkit.notification({
          message: 'Error fetching data',
          status: 'danger',
          pos: 'top-right',
          timeout: 1500
        });
      }
    });
  }

  removeAttachment(attachment) {
    const attachmentObject = {
      documentType: 'reservation',
      itemId: this.activeReservationId,
      fileId: attachment.fileId
    };

    this.fileService.removeFile(attachmentObject).subscribe(
      res => {
        const attachmentToSplice = this.activeReservation.attachments.find((accountAttachment: any) => {
          return attachment.fileId === accountAttachment.fileId;
        });
        this.activeReservation.attachments.splice(this.activeReservation.attachments.indexOf(attachmentToSplice), 1);
        UIkit.notification({
          message: 'File removed successfully',
          status: 'success',
          pos: 'top-right',
          timeout: 1500
        });
      },
      error => {
        UIkit.notification({
          message: 'Removing failed',
          status: 'danger',
          pos: 'top-right',
          timeout: 1500
        });
      }
    );
  }

  getCSV(): void {
    const getCSVObject = {
      Name: this.editReservationForm.value.editReservationName,
      Description: this.editReservationForm.value.editReservationDescription,
      Type: this.editReservationForm.value.editReservationType,
      Items: this.activeReservation.items,
      'Actor': this.editReservationForm.value.editReservationSeller,
      'Seller name': this.editReservationForm.value.editReservationType === 'Sell' ? this.accountActor._id : this.editReservationForm.value.editReservationSeller,
      'Agent name': this.editReservationForm.value.editReservationType === 'Sell' ? this.accountActor._id : this.editReservationForm.value.editReservationSeller,
      'Buyer name': this.editReservationForm.value.editReservationType === 'Sell' ? this.activeReservation.customer : this.accountActor._id,
      'Created': this.editReservationForm.value.editReservationCreated && moment.utc(this.editReservationForm.value.editReservationCreated.jsdate).format() ? moment.utc(this.editReservationForm.value.editReservationCreated.jsdate).format() : null,
      'Client reference': this.editReservationForm.value.editReservationClientReference,
      'Seller reference': this.editReservationForm.value.editReservationSellerReference,
      'Approval status': this.editReservationForm.value.editReservationStatus,
      Currency: this.editReservationForm.value.editReservationCurrency,
      Valid: this.editReservationForm.value.editReservationValid && moment.utc(this.editReservationForm.value.editReservationValid.jsdate).format() ? moment.utc(this.editReservationForm.value.editReservationValid.jsdate).format() : null,
      Price: this.getItemsDiscountedPrice().toFixed(2),
      VAT: this.getItemsVAT().toFixed(2),
      Tags: this.editReservationForm.value.editReservationTags,
      Notes: this.editReservationForm.value.editReservationNotes,
      Comments: this.editReservationForm.value.editReservationComments,
      Visibility: this.editReservationForm.value.editReservationVisibility
    };
    this.fileService.getCSV('Reservation', getCSVObject);
  }

  getReservationPDF(): void {
    const createdYear = this.editReservationForm.get("editReservationCreated").value.singleDate.date.year;
    const createdMonth = this.editReservationForm.get("editReservationCreated").value.singleDate.date.month;
    const createdDay = this.editReservationForm.get("editReservationCreated").value.singleDate.date.day;

    const createdDate = `${createdDay}.${createdMonth}.${createdYear}`;


    let sellerName: string = this.activeReservation.seller.name;
    if (this.activeReservation.seller.lastName) {
      sellerName += " " + this.activeReservation.seller.lastName;
    }

    let buyerName: string = this.activeReservation.customer.name;
    if (this.activeReservation.customer.lastName) {
      buyerName += " " + this.activeReservation.customer.lastName;
    }

    const reservationObject = {
      items: [],
      notes: this.editReservationForm.value.editReservationShowNotes,
      pricing: {},
      sellerInfo: {
        name: sellerName,
        streetAddress: this.activeReservation.seller.street,
        postalCode: this.activeReservation.seller.zip,
        city: this.activeReservation.seller.city,
      },
      clientInfo: {
        name: buyerName,
        streetAddress: this.activeReservation.customer.street,
        postalCode: this.activeReservation.customer.zip,
        city: this.activeReservation.customer.city,
      },
      date: createdDate
    };

    if (this.activeReservation.items.length) {
      this.activeReservation.items.forEach(item => {
        const itemName = item.name;
        let itemImage = null;

        if (item.coverImage) {
          itemImage = item.coverImage;
        } else if (item.images && item.images.length) {
          itemImage = item.images[0].fileId;
        }

        const itemArtistName = item.artistName;
        const itemCreated = moment.utc(item.created).isValid() ? moment.utc(item.created).locale('fi').format('YYYY') : null;
        const itemTechnique = item.technique;

        /* Näitä tietoja ei varmaankaan tarvita */
        let itemSize = '';
        if (item.height) {
          itemSize += item.height;
        }
        if (item.width) {
          if (item.height) {
            itemSize += ' x ';
          }
          itemSize += item.width;
        }
        if (item.depth) {
          if (item.height) {
            itemSize += ' x ';
          }
          itemSize += item.depth;
        }

        const itemPrice = item.price.price + ' ' + item.price.currency;
        const itemAvailability = item.availability;

        let ReservationItem: any = {};
        ReservationItem = {
          'id': item._id,
          'account': this.activeAccount,
          'Artist': itemArtistName,
          'Item name': itemName,
        };

        if (itemCreated) {
          ReservationItem['Item created'] = itemCreated;
        }

        if (itemTechnique) {
          ReservationItem['Item technique'] = itemTechnique;
        }

        if (itemSize) {
          ReservationItem['Item size'] = itemSize + ' cm';
        }

        if (this.getItemDiscountedPrice(item)) {
          const price = this.getItemDiscountedPrice(item);

          if (price) {
            ReservationItem['Item price'] = (price !== null ? price : '') + ' ' + (item.price.currency ? item.price.currency : '');
          } else {
            ReservationItem['Item price'] = 'Not set';
          }
        }

        /*if (itemAvailability) {
          ReservationItem['Item availability'] = itemAvailability;
        }*/

        if (itemImage) {
          ReservationItem.Image = itemImage;
        }

        reservationObject.items.push(ReservationItem);
      });
    }

    reservationObject.pricing = {
      total: this.getItemsTotalPrice(),
      discount: this.getDiscountedPrice(),
      grandTotal: this.getItemsDiscountedPrice(),
      vatTotal: this.getItemsVAT().toFixed(2),
      currency: this.tradingCurrency
    };

    this.fileService.getPDF('Reservation', reservationObject);
  }

  getItemsTotalPrice(): number {
    let totalPrice = 0;

    this.activeReservation.items.forEach(item => {
      if (typeof item.price.price === 'number') {
        totalPrice += parseFloat(item.price.price);
      }
    });

    return totalPrice;
  }

  getItemsGrandTotalPrice(): number {
    let totalPrice = 0;
    let discount = 0;

    this.activeReservation.items.forEach(item => {
      if (item.discount && item.discount >= 0) {
        discount += parseFloat(item.discount);
        totalPrice += parseFloat(item.price.price);
      } else if (typeof item.price.price === 'number') {
        totalPrice += parseFloat(item.price.price);
      }
    });

    if (discount > 0) {
      return totalPrice - discount;
    } else {
      return totalPrice;
    }
  }

  getItemsDiscountedPrice(): number {
    const totalPrice: number = this.getItemsTotalPrice();
    const discountedPrice: number = this.getDiscountedPrice().price;
    const itemsDiscountedPrice = totalPrice - discountedPrice;

    return itemsDiscountedPrice;
  }

  getItemsVAT(): number {
    let totalVAT = 0;
    const items = this.getUniqueVATs();
    items.forEach(item => totalVAT += item.amount);

    return totalVAT;
  }

  getDiscountedPrice(): any {
    const itemsTotalPrice: number = this.getItemsTotalPrice();
    const itemsReservationdedPrice = this.getItemsGrandTotalPrice();

    let discount = {
      price: 0,
      percentage: 0
    };

    if (itemsReservationdedPrice > 0) {
      discount = {
        price: (itemsTotalPrice - itemsReservationdedPrice) > 0 ? itemsTotalPrice - itemsReservationdedPrice : 0,
        percentage: (1 - (itemsReservationdedPrice / itemsTotalPrice)) * 100
      };
    }

    return discount;
  }

  getItemVATAmount(item: any): number {
    let itemVATAMount: number,
      itemPrice: number;

    if (item.discount && item.discount >= 0) {
      itemPrice = item.price.price - item.discount;

    } else if (typeof item.price.price === 'number') {
      itemPrice = parseFloat(item.price.price);
    }

    if (item.VATPercentage) {
      itemVATAMount = itemPrice - (itemPrice * (100 / (100 + parseInt(item.VATPercentage, 0))));
      return itemVATAMount;
    } else {
      return null;
    }
  }

  getItemPriceWithoutVAT(item: any): number {
    const itemVATAmount: number = this.getItemVATAmount(item);
    const priceWithoutVAT: number = item.price.price - itemVATAmount;
    return priceWithoutVAT;
  }

  getItemDiscountedPrice(item: any): number {
    const itemTotalPrice: number = item.price.price;
    const itemDiscountedPrice: number = item.discount;

    if (itemDiscountedPrice && itemDiscountedPrice > 0) {
      return item.price.price - itemDiscountedPrice;
    } else {
      return itemTotalPrice;
    }
  }

  setItemVATPercentage(itemId: string, event: any): void {
    const selectedVATPercentage = event.target.value;
    const activeItemIndex = this.activeReservation.items.findIndex(item => item._id === itemId);
    this.activeReservation.items[activeItemIndex].VATPercentage = selectedVATPercentage;
  }

  setItemTotalPrice(itemId: string, setItemTotalPrice: any): void {
    const activeItemIndex = this.activeReservation.items.findIndex(item => item._id === itemId);
    this.activeReservation.items[activeItemIndex].totalPrice = setItemTotalPrice;
  }

  setItemDiscount(itemId: string, setItemDiscount: any): void {
    const activeItemIndex = this.activeReservation.items.findIndex(item => item._id === itemId);
    this.activeReservation.items[activeItemIndex].discount = setItemDiscount;
  }

  getUniqueVATs(): any {
    const uniqueVATs: Array<any> = [];

    this.activeReservation.items.forEach(item => {
      if (item.VATPercentage === undefined) {
        item.VATPercentage = '24';
      }

      const uniqueIndex = uniqueVATs.findIndex((uniqueItem: any) => uniqueItem.percentage === item.VATPercentage);

      /* Jos itemin percentage ei ole vielä uniikeissa */
      if (uniqueIndex === -1) {
        /* Lisätään itemin VATPercentage ja hinta uniikkeihin */
        uniqueVATs.push({percentage: item.VATPercentage, amount: this.getItemVATAmount(item)});
      } else {
        /* Jos percentage on jo arrayssa, lisätään itemin hinta amountiin */
        uniqueVATs[uniqueIndex].amount += this.getItemVATAmount(item);
      }
    });

    return uniqueVATs;
  }

  createTrade(): void {
    const createTradeObject = {
      owner: this.editReservationForm.value.editReservationOwner,
      name: this.editReservationForm.value.editReservationName,
      description: this.editReservationForm.value.editReservationDescription,
      reservation: this.activeReservationId,
      type: this.editReservationForm.value.editReservationType,
      items: this.activeReservation.items,
      /*items: this.activeReservation.items,*/ // Tämä tehdään vasta single-tradessa
      /*actor: this.editReservationForm.value.editReservationSeller,*/
      seller: this.editReservationForm.value.editReservationType === 'Sell' ? this.accountActor._id : this.activeReservation.seller,
      agent: this.editReservationForm.value.editReservationType === 'Sell' ? this.accountActor._id : this.editReservationForm.value.editReservationSeller,
      buyer: this.editReservationForm.value.editReservationType === 'Sell' ? this.activeReservation.customer : this.accountActor._id,
      created: this.editReservationForm.value.editReservationCreated && moment.utc(this.editReservationForm.value.editReservationCreated.jsdate).format() ? moment.utc(this.editReservationForm.value.editReservationCreated.jsdate).format() : null,
      currency: this.editReservationForm.value.editReservationCurrency,
      /*valid: this.editReservationForm.value.editReservationValid && moment.utc(this.editReservationForm.value.editReservationValid.jsdate).format() ? moment.utc(this.editReservationForm.value.editReservationValid.jsdate).format() : null,*/
      price: this.getItemsDiscountedPrice().toFixed(2),
      VAT: this.getItemsVAT().toFixed(2),
      tags: this.editReservationForm.value.editReservationTags,
      notes: this.editReservationForm.value.editReservationNotes,
      showNotes: this.editReservationForm.value.editReservationShowNotes,
      comments: this.editReservationForm.value.editReservationComments,
      visibility: this.editReservationForm.value.editReservationVisibility,
      attachments: this.activeReservation.attachments
    };
    this.tradeService.addTrade(createTradeObject).subscribe(
      res => {
        this.approveReservation({_id: this.editReservationForm.value.editReservationId});
        UIkit.notification({
          message: 'Trade created successfully',
          status: 'success',
          pos: 'top-right',
          timeout: 1500
        });
      },
      error => {
        UIkit.notification({
          message: 'Saving failed',
          status: 'danger',
          pos: 'top-right',
          timeout: 1500
        });
      }
    );
  }

  openAdvancedSearch(): void {
    this.showAdvancedArtworkSearchModalWindow = true;
    setTimeout(() => {
      this.advancedArtworkSearchModalWindow = window.document.getElementById('advancedArtworkSearch');
      UIkit.modal(this.advancedArtworkSearchModalWindow).show();
    }, 500);
  }

  setArtworkSearchResults(searchResult: Array<any>) {
    searchResult.forEach(item => this.selectedArtworks.push(item));
    this.addArtworksToReservation();
  }

  createDate(inputDate: string): any {
    return {
      isRange: false,
      singleDate: {
        date: {
          year: new Date(inputDate).getFullYear(),
          month: new Date(inputDate).getMonth() + 1,
          day: new Date(inputDate).getDate()
        }
      }
    };
  }

  updateDate(fieldName: string, dateObject: any) {
    this.editReservationForm.controls[fieldName].patchValue(moment(dateObject.singleDate.jsDate).format());
  }

  getCharactersLimitError(fieldName: string) {
    if (!fieldName || !this.editReservationForm.get(fieldName).value ) {
      return ''
    }

    const charactersOverLimit: number = this.editReservationForm.get(fieldName).value.length - this.editReservationForm.get(fieldName).errors.maxlength.requiredLength;

    return `Field length is ${ charactersOverLimit } characters over limit`;
  }

  getCharactersLeft(fieldName: string, charactersLimit: number) {
    if (!fieldName || !this.editReservationForm.get(fieldName).value ) {
      return ''
    }

    const fieldLength: number = this.editReservationForm.get(fieldName).value.length;
    const charactersOverLimit: number = fieldLength - charactersLimit;

    if (charactersOverLimit >= 0) { return `${ charactersLimit } / ${ charactersLimit }` }

    return `${ fieldLength } / ${ charactersLimit }`;
  }
}
