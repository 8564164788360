<div class="uk-section uk-section-default uk-padding-remove-vertical">

  <app-sidenav contentType="Proposals" [contentItem]="activeBid.name"></app-sidenav>

  <div class="uk-container-expand uk-height-expand">
    <ul uk-tab *ngIf="!isLoading">
      <li class="uk-active"><a href="#bidinfo"><h4>Basic information</h4></a></li>
      <li><a href="#bidartworks"><h4>Artworks</h4></a></li>
      <!--<li><a href="#bidartists"><h4>Artists</h4></a></li>-->
    </ul>
    <ul class="uk-switcher uk-card uk-card-body uk-padding-remove-top" *ngIf="!isLoading">
      <div id="bidinfo" class="uk-padding-small">
        <form appNoSubmitEnter [formGroup]="editBidForm" (ngSubmit)="editBid()" class="uk-grid-small" uk-grid>
          <input type="hidden" name="editBidOwner" name="editBidOwner" formControlName="editBidOwner"
                 [ngModel]="activeAccount">
          <input type="hidden" name="editBidId" name="editBidId" formControlName="editBidId" [ngModel]="activeBid._id">
          <input type="hidden" name="editBidOwner" name="editBidOwner" formControlName="editBidOwner"
                 [ngModel]="activeBid.owner">
          <div class="uk-width-1-2@s uk-width-1-3@m">
            <div class="uk-margin">
              <label class="uk-form-label" for="editBidName">Title</label>
              <div class="uk-form-controls">
                <input id="editBidName" name="editBidName" formControlName="editBidName" type="text"
                       [ngModel]="activeBid.name" placeholder="Title ..." class="uk-input"
                       [ngClass]="{'uk-form-danger': editBidForm.get('editBidName').touched && editBidForm.get('editBidName').errors }">
                <div class="uk-text-danger uk-text-small"
                     *ngIf="editBidForm.get('editBidName').hasError('maxlength')"
                >
                  {{ getCharactersLimitError('editBidName') }}
                </div>
                <div class="uk-text-danger uk-text-small"
                     *ngIf="editBidForm.get('editBidName').hasError('pattern')"
                >
                  Field value is incorrect
                </div>
              </div>
            </div>
          </div>
          <div class="uk-width-1-2@s uk-width-1-3@m">
            <div class="uk-margin">
              <div class="uk-flex uk-flex-between uk-text-meta">
                <label class="uk-form-label" for="editBidDescription">Description</label>
                <div class="uk-text-small">
                  {{ getCharactersLeft('editBidDescription', 2048) }}
                </div>
              </div>
              <div class="uk-form-controls">
                <textarea id="editBidDescription" name="editBidDescription" formControlName="editBidDescription"
                          rows="5" [ngModel]="activeBid.description" placeholder="Description ..." class="uk-textarea"
                          [ngClass]="{'uk-form-danger': editBidForm.get('editBidDescription').touched && editBidForm.get('editBidDescription').errors }"></textarea>
                <div class="uk-text-danger uk-text-small"
                     *ngIf="editBidForm.get('editBidDescription').hasError('maxlength')"
                >
                  {{ getCharactersLimitError('editBidDescription') }}
                </div>
              </div>
            </div>
          </div>
          <div class="uk-width-1-2@s uk-width-1-3@m">
            <div class="uk-margin">
              <label class="uk-form-label" for="editBidType">Proposal type</label>
              <div class="uk-form-controls">
                <select id="editBidType" name="editBidType" formControlName="editBidType" [ngModel]="activeBid.type"
                        class="uk-select" (change)="disableSellerCustomer()">
                  <option value="" disabled>Please select ...</option>
                  <option value="Buy">Buy</option>
                  <option value="Sell">Sell</option>
                </select>
              </div>
            </div>
          </div>
          <div class="uk-width-1-2@s uk-width-1-3@m" *ngIf="['Sell'].includes(editBidForm.get('editBidType').value)">
            <div class="uk-margin">
              <label class="uk-form-label" for="editBidSeller">Seller <span class="required">*</span></label>
              <div class="uk-form-controls">
                <!--                <input id="editBidSeller" name="editBidSeller" formControlName="editBidSeller" type="text" placeholder="{{ accountActor.name }}" class="uk-input" [ngModel]="accountActor.name">-->
                <ng-select [items]="actors"
                           [virtualScroll]="true"
                           [addTag]="false"
                           [selectOnTab]="false"
                           addTagText="Add new: "
                           placeholder="Please select ..."
                           bindLabel="fullName"
                           id="editBidSeller"
                           formControlName="editBidSeller"
                           class="uk-select"
                           [(ngModel)]="accountActor.name"
                           #editBidSeller (change)="editBidSeller.blur()">
                </ng-select>
                <div class="uk-text-danger uk-text-small"
                     *ngIf="editBidForm.get('editBidSeller').hasError('maxlength')"
                >
                  {{ getCharactersLimitError('editBidSeller') }}
                </div>
              </div>
            </div>
          </div>
          <div class="uk-width-1-2@s uk-width-1-3@m" *ngIf="['Sell'].includes(editBidForm.get('editBidType').value)">
            <div class="uk-margin">
              <label class="uk-form-label" for="editBidCustomer">Customer <span class="required">*</span></label>
              <div class="uk-form-controls">
                <!--                <select id="editBidCustomer" name="editBidCustomer" formControlName="editBidCustomer" [ngModel]="activeBid.customer" class="uk-select">-->
                <!--                  <option value="" disabled>Please select ...</option>-->
                <!--                  <option *ngFor="let actor of actors" value="{{ actor._id }}">{{ actor.name }} {{ actor.lastName }}</option>-->
                <!--                </select>-->
                <!--<select id="editBidCustomer" name="editBidCustomer" formControlName="editBidCustomer" [ngModel]="activeBid.customer" class="uk-select">
                  <option *ngFor="let actor of actor" [ngValue]="actor._id" >{{ actor.name }} {{ actor.lastName }}</option>
                </select>-->
                <ng-select [items]="actors"
                           [virtualScroll]="true"
                           [addTag]="false"
                           [selectOnTab]="false"
                           addTagText="Add new: "
                           placeholder="Please select ..."
                           bindLabel="fullName"
                           id="editBidCustomer"
                           formControlName="editBidCustomer"
                           class="uk-select"
                           [(ngModel)]="activeBid.customer.fullName"
                           #editBidCustomer (change)="editBidCustomer.blur()">
                </ng-select>
                <div class="uk-text-danger uk-text-small"
                     *ngIf="editBidForm.get('editBidCustomer').hasError('maxlength')"
                >
                  {{ getCharactersLimitError('editBidCustomer') }}
                </div>
              </div>
            </div>
          </div>
          <div class="uk-width-1-2@s uk-width-1-3@m" *ngIf="['Buy'].includes(editBidForm.get('editBidType').value)">
            <div class="uk-margin">
              <label class="uk-form-label" for="editBidSeller">Seller <span class="required">*</span></label>
              <div class="uk-form-controls">
                <!--                <select id="editBidSeller" name="editBidSeller" formControlName="editBidSeller" [ngModel]="activeBid.seller" class="uk-select">-->
                <!--                  <option value="" disabled>Please select ...</option>-->
                <!--                  <option *ngFor="let actor of actors" value="{{ actor._id }}">{{ actor.name }} {{ actor.lastName }}</option>-->
                <!--                </select>-->
                <ng-select [items]="actors"
                           [virtualScroll]="true"
                           [addTag]="false"
                           [selectOnTab]="false"
                           addTagText="Add new: "
                           placeholder="Please select ..."
                           bindLabel="fullName"
                           id="editBidSeller"
                           formControlName="editBidSeller"
                           class="uk-select"
                           [(ngModel)]="activeBid.seller"
                           #editBidSeller (change)="editBidSeller.blur()">
                </ng-select>
                <div class="uk-text-danger uk-text-small"
                     *ngIf="editBidForm.get('editBidSeller').hasError('maxlength')"
                >
                  {{ getCharactersLimitError('editBidSeller') }}
                </div>
              </div>
            </div>
          </div>
          <div class="uk-width-1-2@s uk-width-1-3@m" *ngIf="['Buy'].includes(editBidForm.get('editBidType').value)">
            <div class="uk-margin">
              <label class="uk-form-label" for="editBidCustomer">Customer <span class="required">*</span></label>
              <div class="uk-form-controls">
                <input id="editBidCustomer" name="editBidCustomer" formControlName="editBidCustomer" type="text"
                       placeholder="{{ accountActor.name }}" class="uk-input" [ngModel]="accountActor.name">
                <div class="uk-text-danger uk-text-small"
                     *ngIf="editBidForm.get('editBidCustomer').hasError('maxlength')"
                >
                  {{ getCharactersLimitError('editBidCustomer') }}
                </div>
              </div>
            </div>
          </div>
          <div class="uk-width-1-2@s uk-width-1-3@m">
            <div class="uk-margin">
              <label class="uk-form-label" for="editBidCreated">Creation date</label>
              <div class="uk-form-controls">
                <input
                  id="editBidCreated"
                  name="editBidCreated"
                  formControlName="editBidCreated"
                  [ngModel]="activeBid.created"
                  type="text"
                  placeholder="Creation date ..."
                  class="uk-input"
                  [ngClass]="{'uk-form-danger': editBidForm.get('editBidCreated').touched && editBidForm.get('editBidCreated').errors }"
                  angular-mydatepicker
                  [options]="myDatePickerOptions"
                  #AngularMyDatePickerEditBidCreated="angular-mydatepicker"
                  (click)="AngularMyDatePickerEditBidCreated.toggleCalendar()"
                  (dateChanged)="updateDate('editBidCreated', $event)"
                />
                <div class="uk-text-danger uk-text-small"
                     *ngIf="editBidForm.get('editBidCreated').hasError('maxlength')"
                >
                  {{ getCharactersLimitError('editBidCreated') }}
                </div>
              </div>
            </div>
          </div>
          <div class="uk-width-1-2@s uk-width-1-3@m">
            <div class="uk-margin">
              <label class="uk-form-label" for="editBidValid">Valid until date</label>
              <div class="uk-form-controls">
                <input
                  id="editBidValid"
                  name="editBidValid"
                  formControlName="editBidValid"
                  [ngModel]="activeBid.valid"
                  type="text"
                  placeholder="Valid until date ..."
                  class="uk-input"
                  [ngClass]="{'uk-form-danger': editBidForm.get('editBidValid').touched && editBidForm.get('editBidValid').errors }"
                  angular-mydatepicker
                  [options]="myDatePickerOptions"
                  #AngularMyDatePickerEditBidValid="angular-mydatepicker"
                  (click)="AngularMyDatePickerEditBidValid.toggleCalendar()"
                  (dateChanged)="updateDate('editBidValid', $event)"
                />
                <div class="uk-text-danger uk-text-small"
                     *ngIf="editBidForm.get('editBidValid').hasError('maxlength')"
                >
                  {{ getCharactersLimitError('editBidValid') }}
                </div>
              </div>
            </div>
          </div>
          <div class="uk-width-1-2@s uk-width-1-3@m">
            <div class="uk-margin">
              <div class="uk-flex uk-flex-between uk-text-meta">
                <label class="uk-form-label" for="editBidOtherTerms">Other terms</label>
                <div class="uk-text-small">
                  {{ getCharactersLeft('editBidOtherTerms', 2048) }}
                </div>
              </div>
              <div class="uk-form-controls">
                <textarea id="editBidOtherTerms" name="editBidOtherTerms" formControlName="editBidOtherTerms" rows="5"
                          [ngModel]="activeBid.otherTerms" placeholder="Other terms ..." class="uk-textarea"
                          [ngClass]="{'uk-form-danger': editBidForm.get('editBidOtherTerms').touched && editBidForm.get('editBidOtherTerms').errors }"></textarea>
                <div class="uk-text-danger uk-text-small"
                     *ngIf="editBidForm.get('editBidOtherTerms').hasError('maxlength')"
                >
                  {{ getCharactersLimitError('editBidOtherTerms') }}
                </div>
              </div>
            </div>
          </div>
          <div class="uk-width-1-2@s uk-width-1-3@m">
            <div class="uk-margin">
              <div class="uk-flex uk-flex-between uk-text-meta">
                <label class="uk-form-label" for="editBidClientReference">Client reference</label>
                <div class="uk-text-small">
                  {{ getCharactersLeft('editBidClientReference', 2048) }}
                </div>
              </div>
              <div class="uk-form-controls">
                <textarea id="editBidClientReference" name="editBidClientReference"
                          formControlName="editBidClientReference" rows="5" [ngModel]="activeBid.clientReference"
                          placeholder="Client reference ..." class="uk-textarea"
                          [ngClass]="{'uk-form-danger': editBidForm.get('editBidClientReference').touched && editBidForm.get('editBidClientReference').errors }"></textarea>
                <div class="uk-text-danger uk-text-small"
                     *ngIf="editBidForm.get('editBidClientReference').hasError('maxlength')"
                >
                  {{ getCharactersLimitError('editBidClientReference') }}
                </div>
              </div>
            </div>
          </div>
          <div class="uk-width-1-2@s uk-width-1-3@m">
            <div class="uk-margin">
              <div class="uk-flex uk-flex-between uk-text-meta">
                <label class="uk-form-label" for="editBidSellerReference">Seller reference</label>
                <div class="uk-text-small">
                  {{ getCharactersLeft('editBidSellerReference', 2048) }}
                </div>
              </div>
              <div class="uk-form-controls">
                <textarea id="editBidSellerReference" name="editBidSellerReference"
                          formControlName="editBidSellerReference" rows="5" [ngModel]="activeBid.sellerReference"
                          placeholder="Seller reference ..." class="uk-textarea"
                          [ngClass]="{'uk-form-danger': editBidForm.get('editBidSellerReference').touched && editBidForm.get('editBidSellerReference').errors }"></textarea>
                <div class="uk-text-danger uk-text-small"
                     *ngIf="editBidForm.get('editBidSellerReference').hasError('maxlength')"
                >
                  {{ getCharactersLimitError('editBidSellerReference') }}
                </div>
              </div>
            </div>
          </div>
          <div class="uk-width-1-2@s uk-width-1-3@m" *ngIf="activeBid.items.length">
            <div class="uk-margin">
              <label class="uk-form-label" for="editBidCurrency">Currency</label>
              <div class="uk-form-controls">
                <input id="editBidCurrency" name="editBidCurrency" formControlName="editBidCurrency" type="text"
                       placeholder="Currency ..." [ngModel]="tradingCurrency" class="uk-input"
                       [ngClass]="{'uk-form-danger': editBidForm.get('editBidCurrency').touched && editBidForm.get('editBidCurrency').errors }">
                <div class="uk-text-danger uk-text-small"
                     *ngIf="editBidForm.get('editBidCurrency').hasError('maxlength')"
                >
                  {{ getCharactersLimitError('editBidCurrency') }}
                </div>
              </div>
            </div>
          </div>
          <div class="uk-width-1-2@s uk-width-1-3@m">
            <div class="uk-margin">
              <label class="uk-form-label" for="editBidVAT">VAT</label>
              <div class="uk-form-controls">
                <ng-select [items]="VATRates"
                           [virtualScroll]="true"
                           [addTag]="false"
                           [selectOnTab]="false"
                           addTagText="Please select ..."
                           placeholder="Please select ..."
                           bindLabel="percentage"
                           id="editBidVAT"
                           formControlName="editBidVAT"
                           class="uk-select"
                           [(ngModel)]="activeBid.vat"
                           (change)="editBidVAT.blur()"
                           #editBidVAT>
                </ng-select>

<!--                <select  [ngModel]="activeBid.vat" class="uk-select" >-->
<!--                  <option *ngFor="let VAT of VATRates" value="{{ VAT.percentage }}">{{ VAT.percentage }} %-->
<!--                    ({{ VAT.description }})-->
<!--                  </option>-->
<!--                </select>-->
              </div>
            </div>
          </div>
          <div class="uk-width-1-2@s uk-width-1-3@m">
            <div class="uk-margin">
              <label class="uk-form-label" for="editBidStatus">Approval status</label>
              <div class="uk-form-controls">
                <label><input id="editBidStatus" name="editBidStatus" formControlName="editBidStatus"
                              [ngModel]="activeBid.status" class="uk-checkbox" type="checkbox"> Approved</label>
              </div>
            </div>
          </div>
          <div class="uk-width-1-1 uk-margin-medium-top">
            <h3 class="uk-margin-remove-bottom">Pricing information</h3>
            <hr class="uk-margin-small-top">
          </div>
          <div class="uk-width-1-1">
            <div class="uk-margin">
              <table class="uk-table uk-table-middle uk-table-divider uk-margin-remove-bottom"
                     *ngIf="activeBid.items.length">
                <thead>
                <tr>
                  <th>Image</th>
                  <th>Name</th>
                  <th class="uk-text-nowrap">Artist</th>
                  <th class="uk-text-nowrap">List price<br>incl. VAT</th>
                  <th class="uk-text-nowrap">Discount</th>
                  <th class="uk-text-nowrap uk-text-right">Total price</th>
                </tr>
                </thead>
                <tbody>
                <tr *ngFor="let item of activeBid.items | orderBy: '_id'">
                  <td>
                    <div
                      class="image-max-height uk-preserve-width uk-background-contain uk-background-muted uk-height-small uk-panel uk-flex uk-flex-center uk-flex-middle"
                      width="60"
                      [attr.data-src]="'/api/storagefile/' + activeAccount + '/' + item.coverImage + '/small'" uk-img
                      *ngIf="item.coverImage">

                    </div>
                    <!-- Jos ei ole coverImagea, niin sit otetaan eka image -->
                    <div
                      class="image-max-height uk-preserve-width uk-background-contain uk-background-muted uk-height-small uk-panel uk-flex uk-flex-center uk-flex-middle"
                      width="60"
                      [attr.data-src]="'/api/storagefile/' + activeAccount + '/' + item.images[0].fileId + '/small'"
                      uk-img *ngIf="!item.coverImage && item.images && item.images.length">

                    </div>
                    <!-- Jos ei ole coverImagea tai ekan imagea, ni sit otetaan placeholder -->
                    <div
                      class="image-max-height uk-preserve-width uk-background-contain uk-background-muted uk-height-small uk-panel uk-flex uk-flex-center uk-flex-middle"
                      width="60" [attr.data-src]="'/api/image/artbuddy-logo-placeholder.svg'" uk-img
                      *ngIf="!item.coverImage && !item.images.length">

                    </div>
                  </td>
                  <td>{{ item.name }}</td>
                  <td>{{ item.artistName }}</td>
<!--                  <td>-->
<!--                    <div class="uk-form-controls">-->
<!--                      <select (change)="setItemVATPercentage(item._id, $event)" [ngModel]="item.VATPercentage"-->
<!--                              [ngModelOptions]="{standalone: true}" class="uk-select">-->
<!--                        <option *ngFor="let VAT of VATRates" value="{{ VAT.percentage }}">{{ VAT.percentage }} %-->
<!--                          ({{ VAT.description }})-->
<!--                        </option>-->
<!--                      </select>-->
<!--                      &lt;!&ndash;<span *ngIf="getItemVATAmount(item) > 0">-->
<!--                        {{ getItemVATAmount(item) | round: 2 }} {{ item.price.currency }}-->
<!--                      </span>&ndash;&gt;-->
<!--                    </div>-->
<!--                  </td>-->
                  <!--<td>{{ getItemPriceWithoutVAT(item) | round: 2 }} {{ item.price.currency }}</td>-->
                  <td>{{ item.price.price | round: 2 | number : '1.2-2' }} {{ item.price.currency }}</td>
                  <td>
                    <div class="uk-form-controls">
                      <input type="number" (input)="setItemDiscount(item._id, $event.target.value)"
                             [placeholder]="item.price.price" [ngModel]="item.discount"
                             [ngModelOptions]="{standalone: true}" class="uk-input"
                             [ngClass]="{'uk-form-danger': ((item.totalPrice > item.price.price) || item.totalPrice < 0) }">
                      <div class="measurement-unit-container">
                        <span
                          class="uk-badge uk-background-muted uk-text-muted">{{ tradingCurrency | lowercase }}</span>
                      </div>
                    </div>
                  </td>
                  <td
                    class="uk-text-right">{{ getItemDiscountedPrice(item) | round: 2 | number : '1.2-2' }} {{ item.price.currency }}</td>
                </tr>
                <tr [hidden]="getDiscountedPrice().price <= 0">
                  <td colspan="5" class="uk-text-right">List prices total</td>
                  <td class="uk-text-right">{{ getItemsTotalPrice() | round: 2 | number : '1.2-2' }} {{ activeBid.items[0].price.currency }}</td>
                </tr>
                <tr [hidden]="getDiscountedPrice().price <= 0">
                  <td colspan="5" class="uk-text-right">Discount <span
                    *ngIf="getDiscountedPrice().price > 0">{{ getDiscountedPrice().percentage | round: 2 | number : '1.2-2' }}
                    %</span></td>
                  <td class="uk-text-right" *ngIf="getDiscountedPrice().price > 0">
                    - {{ getDiscountedPrice().price | round: 2 | number : '1.2-2' }} {{ activeBid.items[0].price.currency }}
                  </td>
                  <td class="uk-text-right" *ngIf="getDiscountedPrice().price == 0">
                    0
                  </td>
                </tr>
                <tr>
                  <td colspan="5" class="uk-text-right bold">Total</td>
                  <td class="uk-text-right">{{ getItemsDiscountedPrice() | round: 2 | number : '1.2-2' }} {{ activeBid.items[0].price.currency | lowercase }}</td>
                </tr>
                <ng-container *ngIf="getUniqueVATs().length > 1">
                  <tr>
                    <td colspan="5" class="uk-text-right uk-text-meta uk-text-right">VAT total</td>
                    <td
                      class="uk-text-meta uk-text-right">{{ getItemsVAT() | round: 2 | number : '1.2-2' }} {{ tradingCurrency | lowercase }}</td>
                  </tr>
                  <tr *ngFor="let VAT of getUniqueVATs()" class="vat-row uk-text-right">
                    <td colspan="5" class="uk-text-right uk-text-meta">VAT {{ VAT.percentage }} %</td>
                    <td
                      class="uk-text-meta">{{ VAT.amount | round: 2 | number : '1.2-2' }} {{ tradingCurrency | lowercase }}</td>
                  </tr>
                </ng-container>
                <ng-container *ngIf="getUniqueVATs().length <= 1">
                  <tr>
                    <td colspan="5" class="uk-text-right uk-text-meta uk-text-right"
                        *ngFor="let VAT of getUniqueVATs()">VAT {{ VAT.percentage }} %
                    </td>
                    <td class="uk-text-meta uk-text-right"
                        *ngFor="let VAT of getUniqueVATs()">{{ VAT.amount | round: 2 | number : '1.2-2' }} {{ tradingCurrency | lowercase }}</td>
                  </tr>
                </ng-container>
                </tbody>
              </table>
              <p class="uk-text-left" *ngIf="!activeBid.items.length">No artworks added</p>
            </div>
          </div>
          <!--<div class="uk-width-1-2@s uk-width-1-3@m" *ngIf="activeBid.items.length">
            <div class="uk-margin">
              <label class="uk-form-label" for="editBidPrice">Price</label>
              <div class="uk-form-controls">
                <input id="editBidPrice" name="editBidPrice" formControlName="editBidPrice" type="text" [ngModel]="activeBid.price" placeholder="Price ..." class="uk-input" [ngClass]="{'uk-form-danger': ((editBidForm.get('editBidPrice').touched && editBidForm.get('editBidPrice').errors) || editBidForm.get('editBidPrice').value > getItemsTotalPrice() || editBidForm.get('editBidPrice').value < 0) }">
              </div>
            </div>
          </div>-->
          <!--<div class="uk-width-1-2@s uk-width-1-3@m" *ngIf="activeBid.items.length">
            <div class="uk-margin">
              <label class="uk-form-label" for="editBidPrice">Discount</label>
              <div class="uk-form-controls" *ngIf="getDiscountedPrice().price > 0">
                <label>{{ getDiscountedPrice().price | round: 2 }} {{ activeBid.items[0].price.currency }} / {{ getDiscountedPrice().percentage | round: 2 }} %</label>
              </div>
              <div class="uk-form-controls" *ngIf="getDiscountedPrice().price == 0">
                <label>No discount given</label>
              </div>
            </div>
          </div>-->
          <!--<div class="uk-width-1-2@s uk-width-1-3@m" *ngIf="activeBid.items.length">
            <div class="uk-margin">
              <label class="uk-form-label" for="editBidCurrency">Currency</label>
              <div class="uk-form-controls">
                <input id="editBidCurrency" name="editBidCurrency" formControlName="editBidCurrency" type="text" placeholder="Currency ..." [ngModel]="tradingCurrency" class="uk-input" [ngClass]="{'uk-form-danger': editBidForm.get('editBidCurrency').touched && editBidForm.get('editBidCurrency').errors }">
              </div>
            </div>
          </div>-->
          <div class="uk-width-1-1 uk-margin-medium-top">
            <h3 class="uk-margin-remove-bottom">Notes on proposal</h3>
            <hr class="uk-margin-small-top">
          </div>
          <div class="uk-width-1-2@s uk-width-1-3@m">
            <div class="uk-margin">
              <div class="uk-flex uk-flex-between uk-text-meta">
                <label class="uk-form-label" for="editBidShowNotes">Notes</label>
                <div class="uk-text-small">
                  {{ getCharactersLeft('editBidShowNotes', 256) }}
                </div>
              </div>
              <div class="uk-form-controls">
                <textarea id="editBidShowNotes" name="editBidShowNotes" formControlName="editBidShowNotes" rows="5"
                          [ngModel]="activeBid.showNotes" type="text" placeholder="Notes shown on proposal ..."
                          class="uk-textarea"
                          [ngClass]="{'uk-form-danger': editBidForm.get('editBidShowNotes').touched && editBidForm.get('editBidShowNotes').errors }"></textarea>
                <div class="uk-text-danger uk-text-small"
                     *ngIf="editBidForm.get('editBidShowNotes').hasError('maxlength')"
                >
                  {{ getCharactersLimitError('editBidShowNotes') }}
                </div>
              </div>
            </div>
          </div>
          <div class="uk-width-1-1 uk-margin-medium-top">
            <h3 class="uk-margin-remove-bottom">Additional information</h3>
            <hr class="uk-margin-small-top">
          </div>
          <div class="uk-width-1-1@s">
            <div class="uk-margin">
              <label class="uk-form-label">Attachments</label>
              <div class="uk-form-controls uk-margin-small">
                <dl class="uk-description-list" *ngIf="activeBid.attachments.length">
                  <div class="uk-flex uk-flex-middle" *ngFor="let attachment of activeBid.attachments">
                    <div class="uk-padding-small">
                      <img class="contenttype" width="40" height="40"
                           src="/api/storagefile/{{ activeAccount }}/{{ attachment.fileId }}/small"
                           *ngIf="['image/png', 'image/jpg', 'image/jpeg', 'image/heic', 'image/gif', 'image/svg+xml'].includes(attachment.contentType)"/>
                      <img class="contenttype" width="40" height="40" src="/api/file/5a5de6b1229e86403d67db11"
                           *ngIf="['application/msword', 'application/vnd.openxmlformats-officedocument.wordprocessingml.document'].includes(attachment.contentType)"/>
                      <img class="contenttype" width="40" height="40" src="/api/file/5a5de6b1229e86403d67db10"
                           *ngIf="['application/octet-stream', 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'].includes(attachment.contentType)"/>
                      <img class="contenttype" width="40" height="40" src="/api/file/5a5de6b1229e86403d67db12"
                           *ngIf="['application/pdf'].includes(attachment.contentType)"/>
                      <img class="contenttype" width="40" height="40" src="/api/file/5a5de7bc229e86403d67db1b"
                           *ngIf="['text/plain'].includes(attachment.contentType)"/>
                      <img class="contenttype" width="40" height="40" src="/api/file/5a5de6b1229e86403d67db0f"
                           *ngIf="!(['image/png', 'image/jpg', 'image/jpeg', 'image/heic', 'image/gif', 'image/svg+xml', 'application/msword', 'application/vnd.openxmlformats-officedocument.wordprocessingml.document', 'application/octet-stream', 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet', 'application/pdf', 'text/plain'].includes(attachment.contentType))"/>
                    </div>
                    <div class="uk-width-expand">{{ attachment.name }}</div>
                    <div class="uk-button-group">
                      <button (click)="openAttachment(attachment)" class="uk-button uk-button-default" type="button">
                        Open
                      </button>
                      <div class="uk-inline uk-text-right">
                        <button class="uk-button uk-button-default" type="button"><span
                          uk-icon="icon:  chevron-down"></span></button>
                        <div uk-dropdown="mode: click; boundary: ! .uk-button-group; boundary-align: true;">
                          <ul class="uk-nav uk-dropdown-nav">
                            <li><a (click)="removeAttachment(attachment)">Remove</a></li>
                          </ul>
                        </div>
                      </div>
                    </div>
                  </div>
                </dl>
                <p *ngIf="!activeBid.attachments.length">No related attachments</p>
              </div>
            </div>
          </div>
          <div class="uk-width-1-1@s">
            <div class="uk-margin">
              <label class="uk-form-label">Add new attachments</label>
              <div class="uk-form-controls">
                <div class="drop-container" ngFileDrop [options]="options"
                     (uploadOutput)="onAttachmentsUploadOutput($event)" (click)="attachmentFile.click()"
                     [uploadInput]="uploadInput">
                  <div class="js-upload uk-placeholder uk-text-center" [ngClass]="{ 'is-drop-over': dragOver }">
                    <span
                      class="uk-text-middle">Add files by dropping them here or open file dialog by clicking here.<br>Maximum allowed file size is 5 Mb.</span>
                  </div>
                  <input #attachmentFile type="file" ngFileSelect [options]="{ concurrency: 1, maxFileSize: 5000000 }"
                         [uploadInput]="uploadInput" (uploadOutput)="onAttachmentsUploadOutput($event)" multiple
                         class="file-input">
                </div>
              </div>
              <!--              <progress id="js-progressbar" class="uk-progress" value="0" max="100" hidden></progress>-->
              <!--              <div class="table-container" *ngIf="filesToUpload.length">-->
              <!--                <table class="uk-table uk-table-middle uk-margin-top">-->
              <!--                  <caption>Files to upload</caption>-->
              <!--                  <thead>-->
              <!--                  <tr>-->
              <!--                    <th class="uk-table-shrink uk-text-capitalize">Image</th>-->
              <!--                    <th class="uk-width-expand uk-text-capitalize">Name</th>-->
              <!--                    <th class="uk-width-expand uk-text-capitalize">Size</th>-->
              <!--                    <th class="uk-table-shrink uk-text-capitalize"></th>-->
              <!--                  </tr>-->
              <!--                  </thead>-->
              <!--                  <tbody>-->
              <!--                  <tr *ngFor="let file of filesToUpload; let i = index;">-->
              <!--                    <td class="uk-background-muted"><img class="uk-preserve-width uk-border-circle"-->
              <!--                                                         src="/api/image/artbuddy-logo-placeholder.svg" width="60"-->
              <!--                                                         uk-img></td>-->
              <!--                    <td>{{ file.name }}</td>-->
              <!--                    <td class="uk-text-nowrap">{{ file.size | bytes: 2 }}</td>-->
              <!--                    <td>-->
              <!--                      <button (click)="removeFile(file.id)" class="uk-button uk-button-default" type="button">Remove-->
              <!--                      </button>-->
              <!--                    </td>-->
              <!--                  </tr>-->
              <!--                  </tbody>-->
              <!--                </table>-->
              <!--                <button type="button" (click)="startUpload()" class="uk-button uk-button-primary">Start upload</button>-->
              <!--              </div>-->
            </div>
          </div>
          <div class="uk-width-1-2@s uk-width-1-3@m">
            <div class="uk-margin">
              <div class="uk-flex uk-flex-between uk-text-meta">
                <label class="uk-form-label" for="editBidNotes">Other information</label>
                <div class="uk-text-small">
                  {{ getCharactersLeft('editBidNotes', 2048) }}
                </div>
              </div>
              <div class="uk-form-controls">
                <textarea id="editBidNotes" name="editBidNotes" formControlName="editBidNotes" rows="5"
                          [ngModel]="activeBid.notes" type="text" placeholder="Other information ..."
                          class="uk-textarea"
                          [ngClass]="{'uk-form-danger': editBidForm.get('editBidNotes').touched && editBidForm.get('editBidNotes').errors }"></textarea>
                <div class="uk-text-danger uk-text-small"
                     *ngIf="editBidForm.get('editBidNotes').hasError('maxlength')"
                >
                  {{ getCharactersLimitError('editBidNotes') }}
                </div>
              </div>
            </div>
          </div>
          <div class="uk-width-1-2@s uk-width-1-3@m">
            <div class="uk-margin">
              <div class="uk-flex uk-flex-between uk-text-meta">
                <label class="uk-form-label" for="editBidComments">Comments</label>
                <div class="uk-text-small">
                  {{ getCharactersLeft('editBidComments', 2048) }}
                </div>
              </div>
              <div class="uk-form-controls">
                <textarea id="editBidComments" name="editBidComments" formControlName="editBidComments" rows="5"
                          [ngModel]="activeBid.comments" type="text" placeholder="Comments ..." class="uk-textarea"
                          [ngClass]="{'uk-form-danger': editBidForm.get('editBidComments').touched && editBidForm.get('editBidComments').errors }"></textarea>
                <div class="uk-text-danger uk-text-small"
                     *ngIf="editBidForm.get('editBidComments').hasError('maxlength')"
                >
                  {{ getCharactersLimitError('editBidComments') }}
                </div>
              </div>
            </div>
          </div>
          <div class="uk-width-1-2@s uk-width-1-3@m">
            <div class="uk-margin">
              <label class="uk-form-label" for="editBidTags">Tags</label>
              <div class="uk-form-controls">
                <input id="editBidTags" name="editBidTags" formControlName="editBidTags" [ngModel]="activeBid.tags"
                       type="text" placeholder="Tags ..." class="uk-input"
                       [ngClass]="{'uk-form-danger': editBidForm.get('editBidTags').touched && editBidForm.get('editBidTags').errors }">
                <div class="uk-text-danger uk-text-small"
                     *ngIf="editBidForm.get('editBidTags').hasError('maxlength')"
                >
                  {{ getCharactersLimitError('editBidTags') }}
                </div>
              </div>
            </div>
          </div>
          <div class="uk-width-1-2@s uk-width-1-3@m">
            <div class="uk-margin">
              <label class="uk-form-label" for="editBidVisibility">Visibility</label>
              <div class="uk-form-controls">
                <label><input id="editBidVisibility" name="editBidVisibility" formControlName="editBidVisibility"
                              [ngModel]="activeBid.visibility" class="uk-checkbox" type="checkbox"> Public</label>
              </div>
            </div>
          </div>
          <div class="uk-width-1-1 uk-margin-medium-top">
            <hr class="uk-margin-small-top">
          </div>
          <div class="uk-width-1-1 uk-margin-remove sticky-bottom uk-background-default" uk-sticky="bottom: true">
            <div class="uk-width-1-1">
              <div class="uk-flex uk-flex-middle uk-flex-between uk-margin">
                <app-editing-history
                  [updatedAt]="activeBid.updatedAt"
                  [name]="activeBid.requestUser?.name"
                  [email]="activeBid.requestUser?.email"
                ></app-editing-history>
                <div class="uk-text-right">
                  <button type="button" class="uk-button uk-button-default uk-margin-small-right"
                          [disabled]="!editBidForm.valid || isLoading" (click)="getCSV()">Get CSV
                  </button>
                  <button type="button" class="uk-button uk-button-default uk-margin-small-right"
                          [disabled]="!editBidForm.valid || !activeBid.items.length || isLoading">Open PDF
                  </button>
                  <div id="openPDFDropdown" uk-dropdown="mode: click">
                    <ul class="uk-nav uk-dropdown-nav">
                      <li><a (click)="getBidPDF()" type="button">Get PDF</a></li>
<!--                      <li><a (click)="getBidPDFExternal()" type="button">Get PDF with new template</a></li>-->
                    </ul>
                  </div>
                  <button type="button" class="uk-button uk-button-default uk-margin-small-right"
                          [disabled]="!editBidForm.valid || editBidForm.get('editBidStatus').value || !activeBid.items.length || isLoading"
                          (click)="createTrade()">Create trade
                  </button>
                  <button type="submit" class="uk-button uk-button-primary"
                          [disabled]="!editBidForm.valid || uploadLoading || isLoading">Save
                  </button>
                </div>
              </div>
            </div>
          </div>
        </form>
      </div>
      <div id="bidartworks" class="uk-padding-small uk-padding-remove-top">
        <nav class="uk-navbar-container navbar-search-artwork-container" uk-navbar>
          <div class="nav-overlay uk-navbar-right">
            <button class="uk-button uk-button-default uk-margin-small-right" type="button"
                    (click)="openAdvancedSearch()">Add artwork
            </button>
            <button class="uk-button uk-button-default" type="button" (click)="openSlideshow($event)"
                    [disabled]="!activeBid.items.length">Slideshow
            </button>
          </div>
          <!--<div id="nav-search-artwork" class="nav-overlay uk-navbar-container uk-navbar-left uk-flex-1" hidden>
            <div class="uk-navbar-item uk-width-expand">
              <form id="searchArtwork" [formGroup]="searchArtworkForm" (ngSubmit)="searchArtwork()" class="uk-search uk-search-navbar uk-width-1-1">
                <input id="searchArtworkTerm" name="searchArtworkTerm" formControlName="searchArtworkTerm" class="uk-search-input" type="search" placeholder="Search artworks..." [ngClass]="{' uk-form-danger': searchArtworkForm.get('searchArtworkTerm').touched && searchArtworkForm.get('searchArtworkTerm').hasError('invalid') }" autocomplete="off" autofocus>
                <button type="submit" class="uk-button uk-button-primary" hidden>Search</button>
              </form>
            </div>
            <a class="uk-navbar-toggle" uk-close uk-toggle="target: #bidartworks .nav-overlay; animation: uk-animation-fade" (click)="emptySearch()"></a>
          </div>-->
        </nav>
        <!--<div id="search-artwork-results-container">
          <div class="uk-flex uk-flex-column">
            &lt;!&ndash;<div class="uk-container-expand uk-height-expand uk-padding-small uk-padding-remove-left uk-padding-remove-right" *ngIf="!searchArtworkResults.length">
              <ul class="search-results uk-flex-center" uk-tab>
                <h3>No search results</h3>
              </ul>
            </div>&ndash;&gt;
            <div class="uk-container-expand uk-height-expand">
              <div class="uk-margin">
                <div class="uk-width-1-1">
                  <table class="uk-table uk-table-middle uk-table-divider uk-margin-remove-bottom">
                    <thead>
                      <tr>
                        <th class="uk-table-shrink">Select</th>
                        <th class="uk-table-shrink">Image</th>
                        <th class="uk-width-expand">Name</th>
                        <th class="uk-width-expand uk-text-nowrap">Artist</th>
                        <th class="uk-table-shrink uk-text-nowrap">Technique</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr *ngFor="let artwork of searchArtworkResults">
                        <td><input class="uk-checkbox" type="checkbox" (click)="selectBidArtwork(artwork)"></td>
                        <td>
                          <div class="image-max-height uk-preserve-width uk-background-contain uk-background-muted uk-height-small uk-panel uk-flex uk-flex-center uk-flex-middle" width="60" [attr.data-src]="'/api/storagefile/' + activeAccount + '/' + artwork.coverImage + '/small'" uk-img *ngIf="artwork.coverImage">

                          </div>
                          &lt;!&ndash; Jos ei ole coverImagea, niin sit otetaan eka image &ndash;&gt;
                          &lt;!&ndash;<img class="uk-preserve-width" [src]="'/api/storagefile/' + artwork.images[0].fileId" width="60" *ngIf="!artwork.coverImage && artwork.images && artwork.images.length">&ndash;&gt;
                          <div class="image-max-height uk-preserve-width uk-background-contain uk-background-muted uk-height-small uk-panel uk-flex uk-flex-center uk-flex-middle" width="60" [attr.data-src]="'/api/storagefile/' + activeAccount + '/' + artwork.images[0].fileId + '/small'" uk-img *ngIf="!artwork.coverImage && artwork.images && artwork.images.length">

                          </div>
                          &lt;!&ndash; Jos ei ole coverImagea tai ekan imagea, ni sit otetaan placeholder &ndash;&gt;
                          &lt;!&ndash;<img class="uk-preserve-width uk-border-circle" src="/api/image/artbuddy-logo-placeholder.svg" *ngIf="!artwork.coverImage && !artwork.images.length" width="60">&ndash;&gt;
                          <div class="image-max-height uk-preserve-width uk-background-contain uk-background-muted uk-height-small uk-panel uk-flex uk-flex-center uk-flex-middle" width="60" [attr.data-src]="'/api/image/artbuddy-logo-placeholder.svg'" uk-img *ngIf="!artwork.coverImage && !artwork.images.length">

                          </div>
                        </td>
                        <td>{{ artwork.name }}</td>
                        <td>{{ artwork.artistName }}</td>
                        <td>{{ artwork.technique }}</td>
                      </tr>
                    </tbody>
                  </table>
                  <hr class="uk-margin-small-top">
                  <div class="uk-text-right">
                    <button type="submit" class="uk-button uk-button-primary" (click)="addArtworksToBid()" [disabled]="!selectedArtworks.length">Add artworks</button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>-->
        <div class="uk-grid-match uk-child-width-1-2@s uk-child-width-1-3@m uk-child-width-1-4@xl" uk-grid uk-sortable>
          <div class="uk-card" *ngFor="let item of activeBid.items">
            <div class="uk-card-header uk-flex uk-flex-middle uk-flex-between uk-padding-remove-horizontal">
              <div class="uk-padding-remove-left">
                <h3 class="uk-card-title uk-margin-remove-bottom">{{ item.name }}</h3>
                <div class="uk-text-meta">{{ item.artistName }}</div>
              </div>
              <!--<div>
                <div class="uk-text-meta">{{ item.availability | shorten: 50: '...' }}</div>
                <div class="uk-text-meta" *ngIf="item.price.price">{{ item.price.price }} {{ item.price.currency }}</div>
              </div>-->
            </div>
            <div class="uk-card-body uk-padding-remove uk-background-muted artwork-image"
                 [attr.data-src]="'/api/storagefile/' + activeAccount + '/' + item.coverImage + '/medium'" uk-img
                 *ngIf="item.coverImage"></div>
            <div class="uk-card-body uk-padding-remove uk-background-muted artwork-image"
                 [attr.data-src]="'/api/storagefile/' + activeAccount + '/' + item.images[0].fileId + '/medium'" uk-img
                 *ngIf="!item.coverImage && item.images && item.images.length"></div>
            <div class="uk-card-body uk-padding-remove uk-background-muted artwork-image bg-max-size"
                 [attr.data-src]="'/api/image/artbuddy-logo-placeholder.svg'" uk-img
                 *ngIf="!item.coverImage && !item.images.length"></div>
            <div class="uk-card-footer uk-flex uk-flex-middle uk-flex-between uk-padding-remove-horizontal">
              <div>
                <div class="uk-text-meta" *ngIf="item.width && item.height">{{ item.width }} * {{ item.height }}cm
                </div>
                <!--<div class="uk-text-meta">{{ item.technique }}</div>-->
              </div>
              <div class="uk-flex uk-flex-column">
                <a (click)="removeArtworkFromBid(item)" class="uk-button uk-button-text">Remove</a>
              </div>
            </div>
          </div>
        </div>
      </div>
      <!--<div id="bidartists" class="uk-padding-small">
        <table class="uk-table uk-table-middle uk-table-divider">
          <thead>
          <tr>
            <th class="uk-table-shrink"></th>
            <th class="uk-table-shrink">Image</th>
            <th class="uk-width-expand">Name</th>
            <th class="uk-width-expand uk-text-nowrap">Multiplier</th>
            <th class="uk-table-shrink uk-text-nowrap">Status</th>
            <th class="uk-table-shrink"></th>
          </tr>
          </thead>
          <tbody>
          <tr *ngFor="let artist of artists">
            &lt;!&ndash;<td><input class="uk-checkbox" type="checkbox"></td>&ndash;&gt;
            <td><img class="uk-preserve-width uk-border-circle"
                     src="/api/image/artbuddy-logo-placeholder.svg"
                     width="60"></td>
            <td>{{ artist.name }}</td>
            <td>{{ artist.priceMultiplier }}</td>
            <td>{{ artist.status }}</td>
            <td>
              <button (click)="openArtist(artist._id)" class="uk-button uk-button-default" type="button">Open</button>
            </td>
          </tr>
          </tbody>
        </table>
      </div>-->
    </ul>
  </div>
</div>
<app-advanced-search-modal (setResults)="setArtworkSearchResults($event)"
                           *ngIf="showAdvancedArtworkSearchModalWindow"></app-advanced-search-modal>
