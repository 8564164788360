import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { ActiveService } from './active.service';

import { Observable } from 'rxjs';
import 'rxjs/add/operator/map';
import * as moment from 'moment/moment';

@Injectable()
export class FileService {

  /*private headers = new Headers({ 'Content-Type': 'application/json', 'charset': 'UTF-8' });
  private options = new RequestOptions({ headers: this.headers });*/
  private httpOptions = {
    headers: new HttpHeaders({
      'Content-Type':  'application/json',
      'charset': 'UTF-8'
    })
  };

  private currentAccount: any;

  constructor(
    private http: HttpClient,
    private activeService: ActiveService
  ) {
    this.activeService.activeDataChange.subscribe(data => {
      this.currentAccount = data.account.id;
    });
  }

  getAccountFiles(): Observable<any> {
    return this.http.get('/api/accounts');
  }

  // Tässä pitäis käyttää nykyistä accountia, ei useria
  getAccountsFiles(currentUser): Observable<any> {
    return this.http.get(`/api/file/${currentUser._id}`);
  }

  getFile(fileId): Observable<any> {
    let formData: FormData = new FormData();
    formData.append('id', fileId);
    return this.http.get('/api/file/' + fileId);
  }

  getPDF(documentType, object, printAsList?: boolean): void {
    /* Fill in required fields if not provided */
    object.title = documentType;
    object.author = object.author ? object.author : 'Artbuddy';

    this.http.post('/api/file/pdf', JSON.stringify(object),
      {
        headers: new HttpHeaders({
          'Content-Type': 'application/json',
          charset: 'UTF-8'
        }),
        responseType: 'blob'
      })
      .map(res => new Blob([res], {type: 'application/pdf'}))
      .subscribe(
        (data: Blob) => {
          const fileURL = window.URL.createObjectURL(data);
          /*window.open(fileURL);*/

          const linkElement = document.createElement('a');
          linkElement.setAttribute('href', fileURL);

          const today = moment().locale('fi').format('L');
          linkElement.setAttribute('download', 'Artbuddy-' + documentType.toLowerCase() + '-' + today + '.pdf');

          const clickEvent = new MouseEvent('click', {
            'view': window,
            'bubbles': true,
            'cancelable': false
          });

          linkElement.dispatchEvent(clickEvent);
        }
      );
  }

  getPDFExternal(documentType, object, printAsList?: boolean): void {
    /* Fill in required fields if not provided */
    object.title = documentType;
    object.author = object.author ? object.author : 'Artbuddy';

    this.http.post('/api/file/pdfExternal', JSON.stringify(object),
      {
        headers: new HttpHeaders({
          'Content-Type': 'application/json',
          charset: 'UTF-8'
        }),
        responseType: 'blob'
      })
      .map(res => new Blob([res], {type: 'application/pdf'}))
      .subscribe(
        (data: Blob) => {
          const fileURL = window.URL.createObjectURL(data);
          /*window.open(fileURL);*/

          const linkElement = document.createElement('a');
          linkElement.setAttribute('href', fileURL);

          const today = moment().locale('fi').format('L');
          linkElement.setAttribute('download', 'Artbuddy-' + documentType.toLowerCase() + '-' + today + '.pdf');

          const clickEvent = new MouseEvent('click', {
            'view': window,
            'bubbles': true,
            'cancelable': false
          });

          linkElement.dispatchEvent(clickEvent);
        }
      );
  }

  getCSV(documentType, object): void {
    /* Fill in required fields if not provided */
    object.title = documentType;
    object.author = object.author ? object.author : 'Artbuddy';

    this.http.post('/api/file/csv', JSON.stringify(object),
      {
        headers: new HttpHeaders({
          'Content-Type': 'application/json',
          charset: 'UTF-8'
        }),
        responseType: 'blob'
      })
      .map(res => new Blob([res], {type: 'text/csv'}))
      .subscribe(
        (data: Blob) => {
          const fileURL = window.URL.createObjectURL(data);
          /*window.open(fileURL);*/

          const linkElement = document.createElement('a');
          linkElement.setAttribute('href', fileURL);

          const today = moment().locale('fi').format('L');
          linkElement.setAttribute('download', 'Artbuddy-' + documentType.toLowerCase() + '-' + today + '.csv');

          const clickEvent = new MouseEvent('click', {
            'view': window,
            'bubbles': true,
            'cancelable': false
          });

          linkElement.dispatchEvent(clickEvent);
        }
      );
  }

  removeFile(attachmentObject): Observable<any> {
    return this.http.delete(`/api/file/${ attachmentObject.documentType }/${ attachmentObject.itemId }/${ attachmentObject.fileId }`, this.httpOptions);
  }

  editAccount(account): Observable<any> {
    return this.http.put(`/api/account/${account.id}`, JSON.stringify(account), this.httpOptions);
  }

  deleteAccount(account): Observable<any> {
    return this.http.delete(`/api/account/${account._id}`, this.httpOptions);
  }

}
