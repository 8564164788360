<div class="uk-section uk-section-default uk-padding-remove-top" uk-height-viewport="offset-top: true">

  <app-sidenav contentType="Accounts"></app-sidenav>

  <div class="uk-container-expand uk-height-expand">
    <div uk-grid>
      <div class="uk-width-expand@m">
        <div class="uk-padding-small uk-padding-remove-top" *ngIf="!isLoading">
          <div class="uk-width-1-1 uk-text-right uk-margin-small-bottom uk-padding-remove">
            <button class="uk-button uk-button-text uk-margin-right" type="button" (click)="showOnlyActive()" [ngClass]="{ 'uk-button-primary': onlyActive }">Filter active</button>
            <button class="uk-button uk-button-text uk-margin-right" type="button" (click)="openAddNewAccount()" *ngIf="user && isAdmin">Add new</button>
            <div id="addNewAccount" class="uk-modal-container" uk-modal="bg-close: false; esc-close: false;">
              <div class="uk-modal-dialog">
                <form appNoSubmitEnter [formGroup]="addAccountForm" (ngSubmit)="addAccount()">
                  <input type="hidden" name="addAccountOwner" name="addAccountOwner" formControlName="addAccountOwner" [ngModel]="user">
                  <div class="uk-modal-header">
                    <h2 class="uk-modal-title">Add new account</h2>
                  </div>
                  <div class="uk-modal-body" uk-grid>
                    <div class="uk-width-1-2@s">
                      <div class="uk-margin">
                        <label class="uk-form-label" for="addAccountName">Name</label>
                        <div class="uk-form-controls">
                          <input id="addAccountName" name="addAccountName" formControlName="addAccountName" type="text" placeholder="Name ..." class="uk-input" [ngClass]="{'uk-form-danger': addAccountForm.get('addAccountName').touched && addAccountForm.get('addAccountName').hasError('required') }">
                        </div>
                      </div>
                    </div>
                    <div class="uk-width-1-2@s">
                      <div class="uk-margin">
                        <label class="uk-form-label" for="addAccountVisibleName">Visible name</label>
                        <div class="uk-form-controls">
                          <input id="addAccountVisibleName" name="addAccountVisibleName" formControlName="addAccountVisibleName" type="text" placeholder="Visible name ..." class="uk-input" [ngClass]="{'uk-form-danger': addAccountForm.get('addAccountVisibleName').touched && addAccountForm.get('addAccountVisibleName').hasError('required') }">
                        </div>
                      </div>
                    </div>
                    <!-- TODO: Tämä kommentoitu pois, koska luoja on aina sisäänkirjaunut käyttäjä ja uuden luominen sallitaan vain adminille -->
                    <!--<div class="uk-width-1-2@s">
                      <div class="uk-margin">
                        <label class="uk-form-label" for="addAccountOwner">Owner</label>
                        <div class="uk-form-controls">
                          <select id="addAccountOwner" name="addAccountOwner" formControlName="addAccountOwner" class="uk-select" [ngClass]="{'uk-form-danger': addAccountForm.get('addAccountOwner').touched && addAccountForm.get('addAccountOwner').hasError('required'), 'uk-form-danger': addAccountForm.get('addAccountOwner').invalid }">
                              <option value="" disabled>Please select ...</option>
                              <option *ngFor="let user of users" value="{{user._id}}">{{ user.email }}</option>
                            </select>
                        </div>
                      </div>
                    </div>-->
                    <div class="uk-width-1-2@s">
                      <div class="uk-margin">
                        <label class="uk-form-label" for="addAccountStreet">Street</label>
                        <div class="uk-form-controls">
                          <input id="addAccountStreet" name="addAccountStreet" formControlName="addAccountStreet" type="text" placeholder="Street ..." class="uk-input" [ngClass]="{'uk-form-danger': addAccountForm.get('addAccountStreet').touched && addAccountForm.get('addAccountStreet').hasError('required'), 'uk-form-danger': addAccountForm.get('addAccountStreet').invalid }">
                        </div>
                      </div>
                    </div>
                    <div class="uk-width-1-2@s">
                      <div class="uk-margin">
                        <label class="uk-form-label" for="addAccountCity">City</label>
                        <div class="uk-form-controls">
                          <input id="addAccountCity" name="addAccountCity" formControlName="addAccountCity" type="text" placeholder="City ..." class="uk-input" [ngClass]="{'uk-form-danger': addAccountForm.get('addAccountCity').touched && addAccountForm.get('addAccountCity').hasError('required'), 'uk-form-danger': addAccountForm.get('addAccountCity').invalid }">
                        </div>
                      </div>
                    </div>
                    <div class="uk-width-1-2@s">
                      <div class="uk-margin">
                        <label class="uk-form-label" for="addAccountState">State</label>
                        <div class="uk-form-controls">
                          <input id="addAccountState" name="addAccountState" formControlName="addAccountState" type="text" placeholder="State ..." class="uk-input" [ngClass]="{'uk-form-danger': addAccountForm.get('addAccountState').touched && addAccountForm.get('addAccountState').hasError('required'), 'uk-form-danger': addAccountForm.get('addAccountState').invalid }">
                        </div>
                      </div>
                    </div>
                    <div class="uk-width-1-2@s">
                      <div class="uk-margin">
                        <label class="uk-form-label" for="addAccountZip">Zip</label>
                        <div class="uk-form-controls">
                          <input id="addAccountZip" name="addAccountZip" formControlName="addAccountZip" type="text" placeholder="Zip ..." class="uk-input" [ngClass]="{'uk-form-danger': addAccountForm.get('addAccountZip').touched && addAccountForm.get('addAccountZip').hasError('required'), 'uk-form-danger': addAccountForm.get('addAccountZip').invalid }">
                        </div>
                      </div>
                    </div>
                    <div class="uk-width-1-2@s">
                      <div class="uk-margin">
                        <label class="uk-form-label" for="addAccountEmail">Email</label>
                        <div class="uk-form-controls">
                          <input id="addAccountEmail" name="addAccountEmail" formControlName="addAccountEmail" type="text" placeholder="Email ..." class="uk-input" [ngClass]="{'uk-form-danger': addAccountForm.get('addAccountEmail').touched && addAccountForm.get('addAccountEmail').hasError('required'), 'uk-form-danger': addAccountForm.get('addAccountEmail').invalid }">
                        </div>
                      </div>
                    </div>
                    <div class="uk-width-1-2@s">
                      <div class="uk-margin">
                        <label class="uk-form-label" for="addAccountPhone">Phone</label>
                        <div class="uk-form-controls">
                          <input id="addAccountPhone" name="addAccountPhone" formControlName="addAccountPhone" type="text" placeholder="Phone ..." class="uk-input" [ngClass]="{'uk-form-danger': addAccountForm.get('addAccountPhone').touched && addAccountForm.get('addAccountPhone').hasError('required'), 'uk-form-danger': addAccountForm.get('addAccountPhone').invalid }">
                        </div>
                      </div>
                    </div>
                    <div class="uk-width-1-2@s">
                      <div class="uk-margin">
                        <label class="uk-form-label" for="addAccountCurrency">Currency</label>
                        <div class="uk-form-controls">
                          <ng2-completer id="addAccountCurrency" name="addAccountCurrency" formControlName="addAccountCurrency" placeholder="Currency ..." [datasource]="currencies" [minSearchLength]="0" [textNoResults]="null"></ng2-completer>
                        </div>
                      </div>
                    </div>
                    <div class="uk-width-1-2@s">
                      <div class="uk-margin">
                        <label class="uk-form-label" for="addAccountVAT">VAT</label>
                        <div class="uk-form-controls">
                          <input id="addAccountVAT" name="addAccountVAT" formControlName="addAccountVAT" type="number" placeholder="VAT ..." class="uk-input" [ngClass]="{'uk-form-danger': addAccountForm.get('addAccountVAT').touched && addAccountForm.get('addAccountVAT').hasError('required'), 'uk-form-danger': addAccountForm.get('addAccountVAT').invalid }">
                        </div>
                      </div>
                    </div>
                    <div class="uk-width-1-2@s">
                      <div class="uk-margin">
                        <label class="uk-form-label" for="addAccountType">Type</label>
                        <div class="uk-form-controls">
                          <select id="addAccountType" name="addAccountType" formControlName="addAccountType" class="uk-select">
                            <option value="" disabled>Please select ...</option>
                            <option value="gallery">Gallery</option>
                            <option value="artcollector">Art collector</option>
                            <option value="artist">Artist</option>
                          </select>
                        </div>
                      </div>
                    </div>
                    <div class="uk-width-1-2@s">
                      <div class="uk-margin">
                        <label class="uk-form-label" for="addAccountPricing">Pricing</label>
                        <div class="uk-form-controls">
                          <select id="addAccountPricing" name="addAccountPricing" formControlName="addAccountPricing" class="uk-select">
                            <option value="" disabled>Please select ...</option>
                            <option value="trial">Trial</option>
                            <option value="standard">Standard</option>
                            <option value="professional">Professional</option>
                          </select>
                        </div>
                      </div>
                    </div>
                    <div class="uk-width-1-2@s">
                      <div class="uk-margin">
                        <label class="uk-form-label" for="addAccountTimezone">Timezone</label>
                        <div class="uk-form-controls">
                          <ng2-completer id="addAccountTimezone" name="addAccountTimezone" formControlName="addAccountTimezone" placeholder="Timezone ..." [datasource]="timezones" [minSearchLength]="0" [textNoResults]="null" disableInput="true"></ng2-completer>
                        </div>
                      </div>
                    </div>
                    <div class="uk-width-1-2@s">
                      <div class="uk-margin">
                        <label class="uk-form-label" for="addAccountMeasurementUnit">Measurement unit</label>
                        <div class="uk-form-controls">
                          <select id="addAccountMeasurementUnit" name="addAccountMeasurementUnit" formControlName="addAccountMeasurementUnit" class="uk-select">
                            <option value="" disabled>Please select ...</option>
                            <option value="mm">Millimeter (mm)</option>
                            <option value="cm">Centimeter (cm)</option>
                            <option value="m">Meter (m)</option>
                            <option value="in">Inch (in)</option>
                            <option value="ft">Foot (ft)</option>
                            <option value="yd">Yard (yd)</option>
                          </select>
                        </div>
                      </div>
                    </div>
                    <div class="uk-width-1-2@s">
                      <div class="uk-margin">
                        <label class="uk-form-label" for="addAccountNotes">Notes</label>
                        <div class="uk-form-controls">
                          <textarea id="addAccountNotes" name="addAccountNotes" formControlName="addAccountNotes" rows="5" placeholder="Notes ..." class="uk-textarea" [ngClass]="{'uk-form-danger': addAccountForm.get('addAccountNotes').touched && addAccountForm.get('addAccountNotes').hasError('required'), 'uk-form-danger': addAccountForm.get('addAccountNotes').invalid }"></textarea>
                        </div>
                      </div>
                    </div>
                    <!--<div class="uk-width-1-1">
                      <div class="uk-margin">
                        <label class="uk-form-label" for="addAccountAttachments">Attachments</label>
                        <div class="uk-form-controls">
                          <div uk-form-custom="target: true">
                            <input type="file">
                            <input type="text" id="addAccountAttachments" name="addAccountAttachments" formControlName="addAccountAttachments" class="uk-input uk-form-width-medium" [ngClass]="{'uk-form-danger': addAccountForm.get('addAccountAttachments').touched && addAccountForm.get('addAccountAttachments').hasError('required'), 'uk-form-danger': addAccountForm.get('addAccountAttachments').invalid }" placeholder="Attachments ..." disabled>
                          </div>
                          <button class="uk-button uk-button-default">Add</button>
                        </div>
                      </div>
                    </div>-->
                  </div>
                  <div class="uk-modal-footer">
                    <p class="uk-text-right">
                      <button class="uk-button uk-button-default uk-modal-close uk-margin-small-right" type="button" id="addnewcancel" (click)="resetForm()">Cancel</button>
                      <button type="submit" [disabled]="!addAccountForm.valid" class="uk-button uk-button-primary">Save</button>
                    </p>
                  </div>
                </form>
              </div>
            </div>
            <button class="uk-button uk-button-default" type="button">Sort</button>
            <div uk-dropdown="mode: click;">
              <ul class="uk-nav uk-dropdown-nav">
                <li [ngClass]="{'uk-active': activeSort('name') }" (click)="sortItems('name')"><a>Name</a></li>
                <li [ngClass]="{'uk-active': activeSort('status') }" (click)="sortItems('status')"><a>Status</a></li>
              </ul>
            </div>
          </div>
          <table class="uk-table uk-table-middle uk-table-divider">
            <thead>
            <tr>
              <th class="uk-table-shrink">Selected</th>
              <!--<th class="uk-table-shrink">Image</th>-->
              <th class="uk-table-expand" [ngClass]="{'active': activeSort('name') }" (click)="sortItems('name')">Name<span uk-icon="chevron-down" [ngClass]="{'inverse': !sortAscending }"></span></th>
              <th class="uk-width-small" [ngClass]="{'active': activeSort('status') }" (click)="sortItems('status')">Status<span uk-icon="chevron-down" [ngClass]="{'inverse': !sortAscending }"></span></th>
              <th class="uk-table-shrink"></th>
            </tr>
            </thead>
            <tbody>
            <tr *ngFor="let account of accounts | filterBy: ['status']: getOnlyActive()">
                <td>
                  <span class="uk-badge uk-padding-small" *ngIf="account._id == activeAccount">Selected</span>
                </td>
                <!--<td class="uk-background-muted"><img class="uk-preserve-width uk-border-circle" src="/api/image/artbuddy-logo-placeholder.svg" width="60" uk-img></td>-->
                <td>
                  <div>{{ getAccountName(account) }}</div>
                  <div class="uk-text-meta">{{ account.type }}</div>
                </td>
                <td>
                  <span class="uk-label uk-label-default" *ngIf="account.status">Active</span>
                  <span class="uk-label uk-label-warning" *ngIf="!account.status">Inactive</span>
                </td>
                <td class="uk-text-right">
                  <div class="uk-button-group">
                    <button (click)="openAccount(account._id)" class="uk-button uk-button-text uk-margin-right" type="button">Open</button>
                    <div class="uk-inline" class="uk-visible@m">
                      <button class="uk-button uk-button-link" type="button"><span uk-icon="icon: chevron-down"></span></button>
                      <div uk-dropdown="mode: click; boundary: ! .uk-button-group; boundary-align: true;">
                        <ul class="uk-nav uk-dropdown-nav">
                          <li><a (click)="setActiveAccount(account._id)" *ngIf="account._id !== activeAccount">Set selected</a></li>
                          <!-- *ngIf="account._id == activeAccount" -->
                          <li *ngIf="account.status"><a (click)="closeAccount(account)">Inactivate</a></li>
                          <li *ngIf="!account.status && user && isAdmin"><a (click)="activateAccount(account)">Activate</a></li>
                          <li *ngIf="user && isAdmin"><a (click)="deleteAccount(account)">Delete</a></li>
                        </ul>
                      </div>
                    </div>
                  </div>
                </td>
              <!--<td><input class="uk-checkbox" type="checkbox"></td>-->
            </tr>
            </tbody>
          </table>
          <!--<ul class="uk-pagination uk-flex-center uk-margin-medium-top" uk-margin>
            <li><a><span uk-pagination-previous></span></a></li>
            <li class="uk-active"><a>1</a></li>
            <li class="uk-disabled"><span>...</span></li>
            <li><a>5</a></li>
            <li><a>6</a></li>
            <li><a>7</a></li>
            <li><a>8</a></li>
            <li><a><span uk-pagination-next></span></a></li>
          </ul>-->
        </div>
      </div>
    </div>
  </div>
</div>
