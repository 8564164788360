import {Component, Input} from "@angular/core";
import * as moment from "moment";

@Component({
  selector: "app-editing-history",
  templateUrl: "./editingHistory.component.html",
  styleUrls: ["./editingHistory.component.scss"]
})
export class EditingHistoryComponent {
  @Input() updatedAt;
  @Input() name;
  @Input() email;

  constructor() {}

  getLastUpdateTime = (timestamp: string) => {
    let formattedTimestamp = "";

    if (timestamp) {
      formattedTimestamp = moment.utc(timestamp).local().format("D.M.YYYY H.mm");
    }

    return formattedTimestamp;
  }
}
