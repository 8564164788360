<div class="uk-section uk-section-default uk-padding-remove-top" uk-height-viewport="offset-top: true">

  <app-sidenav contentType="Trades"></app-sidenav>

  <div class="uk-container-expand uk-height-expand">
    <div uk-grid>
      <div class="uk-width-expand@m">
        <div class="uk-padding-small uk-padding-remove-top" *ngIf="!isLoading">
          <div class="uk-width-1-1 uk-text-right uk-margin-small-bottom uk-padding-remove">
            <app-search-content contentType="trade" fields="['number', 'title']"></app-search-content>
            <button class="uk-button uk-button-text uk-margin-right" type="button" (click)="openAddNewTrade()">
              Add new
            </button>
            <div id="addNewTrade" class="uk-modal-container" uk-modal="stack: true; bg-close: false; esc-close: false;">
              <div class="uk-modal-dialog">
                <form appNoSubmitEnter [formGroup]="addTradeForm">
                  <input type="hidden" name="addTradeOwner" name="addTradeOwner" formControlName="addTradeOwner"
                         [ngModel]="activeAccount">
                  <div class="uk-modal-header">
                    <h2 class="uk-modal-title">Add new trade</h2>
                  </div>
                  <div class="uk-modal-body" uk-grid>
                    <div class="uk-width-1-2@s">
                      <div class="uk-margin">
                        <label class="uk-form-label" for="addTradeName">Name <span class="required">*</span></label>
                        <div class="uk-form-controls">
                          <input id="addTradeName" name="addTradeName" formControlName="addTradeName" type="text"
                                 placeholder="Name ..." class="uk-input"
                                 [ngClass]="{'uk-form-danger': addTradeForm.get('addTradeName').touched && addTradeForm.get('addTradeName').errors }">
                          <div class="uk-text-danger uk-text-small"
                               *ngIf="addTradeForm.get('addTradeName').hasError('maxlength')"
                          >
                            {{ getCharactersLimitError('addTradeName') }}
                          </div>
                          <div class="uk-text-danger uk-text-small"
                               *ngIf="addTradeForm.get('addTradeName').hasError('pattern')"
                          >
                            Field value is incorrect
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="uk-width-1-2@s">
                      <div class="uk-margin">
                        <div class="uk-flex uk-flex-between uk-text-meta">
                          <label class="uk-form-label" for="addTradeDescription">Description</label>
                          <div class="uk-text-small">
                            {{ getCharactersLeft('addTradeDescription', 2048) }}
                          </div>
                        </div>
                        <div class="uk-form-controls">
                          <textarea id="addTradeDescription" name="addTradeDescription"
                                    formControlName="addTradeDescription" rows="5" placeholder="Description ..."
                                    class="uk-textarea"
                                    [ngClass]="{'uk-form-danger': addTradeForm.get('addTradeDescription').touched && addTradeForm.get('addTradeDescription').errors }"></textarea>
                          <div class="uk-text-danger uk-text-small"
                               *ngIf="addTradeForm.get('addTradeDescription').hasError('maxlength')"
                          >
                            {{ getCharactersLimitError('addTradeDescription') }}
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="uk-width-1-2@s">
                      <div class="uk-margin">
                        <label class="uk-form-label" for="addTradeType">Trade type <span
                          class="required">*</span></label>
                        <div class="uk-form-controls">
                          <select id="addTradeType" name="addTradeType" formControlName="addTradeType"
                                  class="uk-select">
                            <option value="" disabled>Please select ...</option>
                            <option value="Represented">Represented</option>
                            <option value="Direct">Direct</option>
                          </select>
                        </div>
                      </div>
                    </div>
                    <div class="uk-width-1-2@s">
                      <div class="uk-margin">
                        <label class="uk-form-label" for="addTradeSeller">Seller <span class="required">*</span></label>
                        <div class="uk-form-controls">
                          <!--                          <select id="addTradeSeller" name="addTradeSeller" formControlName="addTradeSeller" class="uk-select">-->
                          <!--                            <option value="" disabled>Please select ...</option>-->
                          <!--                            <option *ngFor="let actor of actors" value="{{ actor._id }}">{{ actor.name }} {{ actor.lastName }}</option>-->
                          <!--                          </select>-->
                          <ng-select [items]="actors"
                                     [virtualScroll]="true"
                                     [ngModel]="accountActor"
                                     [addTag]="false"
                                     [selectOnTab]="false"
                                     addTagText="Add new: "
                                     placeholder="Please select ..."
                                     bindLabel="fullName"
                                     id="addTradeSeller"
                                     formControlName="addTradeSeller"
                                     class="uk-select"
                                     #addTradeSeller (change)="addTradeSeller.blur()">
                          </ng-select>
                          <div class="uk-text-danger uk-text-small"
                               *ngIf="addTradeForm.get('addTradeSeller').hasError('maxlength')"
                          >
                            {{ getCharactersLimitError('addTradeSeller') }}
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="uk-width-1-2@s"
                         *ngIf="['Represented'].includes(addTradeForm.get('addTradeType').value)">
                      <div class="uk-margin">
                        <label class="uk-form-label" for="addTradeAgent">Agent <span class="required">*</span></label>
                        <div class="uk-form-controls">
                          <!--                          <select id="addTradeAgent" name="addTradeAgent" formControlName="addTradeAgent" class="uk-select">-->
                          <!--                            <option value="" disabled>Please select ...</option>-->
                          <!--                            <option *ngFor="let actor of actors" value="{{ actor._id }}">{{ actor.name }} {{ actor.lastName }}</option>-->
                          <!--                          </select>-->
                          <ng-select [items]="actors"
                                     [virtualScroll]="true"
                                     [addTag]="false"
                                     [selectOnTab]="false"
                                     addTagText="Add new: "
                                     placeholder="Please select ..."
                                     bindLabel="fullName"
                                     id="addTradeAgent"
                                     formControlName="addTradeAgent"
                                     class="uk-select"
                                     #addTradeAgent (change)="addTradeAgent.blur()">
                          </ng-select>
                          <div class="uk-text-danger uk-text-small"
                               *ngIf="addTradeForm.get('addTradeAgent').hasError('maxlength')"
                          >
                            {{ getCharactersLimitError('addTradeAgent') }}
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="uk-width-1-2@s">
                      <div class="uk-margin">
                        <label class="uk-form-label" for="addTradeBuyer">Buyer <span class="required">*</span></label>
                        <div class="uk-form-controls">
                          <!--                          <select id="addTradeBuyer" name="addTradeBuyer" formControlName="addTradeBuyer" class="uk-select">-->
                          <!--                            <option value="" disabled>Please select ...</option>-->
                          <!--                            <option *ngFor="let actor of actors" value="{{ actor._id }}">{{ actor.name }} {{ actor.lastName }}</option>-->
                          <!--                          </select>-->
                          <ng-select [items]="actors"
                                     [virtualScroll]="true"
                                     [addTag]="false"
                                     [selectOnTab]="false"
                                     addTagText="Add new: "
                                     placeholder="Please select ..."
                                     bindLabel="fullName"
                                     id="addTradeBuyer"
                                     formControlName="addTradeBuyer"
                                     class="uk-select"
                                     #addTradeBuyer (change)="addTradeBuyer.blur()">
                          </ng-select>
                          <div class="uk-text-danger uk-text-small"
                               *ngIf="addTradeForm.get('addTradeBuyer').hasError('maxlength')"
                          >
                            {{ getCharactersLimitError('addTradeBuyer') }}
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="uk-width-1-2@s">
                      <div class="uk-margin">
                        <label class="uk-form-label">Creation date</label>
                        <div class="uk-form-controls">
                          <input
                            id="addEstimateCreated"
                            name="addTradeCreated"
                            formControlName="addTradeCreated"
                            type="text"
                            placeholder="Creation date ..."
                            class="uk-input"
                            [ngClass]="{'uk-form-danger': addTradeForm.get('addTradeCreated').touched && addTradeForm.get('addTradeCreated').errors }"
                            angular-mydatepicker
                            [options]="myDatePickerOptions"
                            #AngularMyDatePickerAddTradeCreated="angular-mydatepicker"
                            (click)="AngularMyDatePickerAddTradeCreated.toggleCalendar()"
                            (dateChanged)="updateDate('addTradeCreated', $event)"
                          />
                          <div class="uk-text-danger uk-text-small"
                               *ngIf="addTradeForm.get('addTradeCreated').hasError('maxlength')"
                          >
                            {{ getCharactersLimitError('addTradeCreated') }}
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="uk-width-1-2@s">
                      <div class="uk-margin">
                        <label class="uk-form-label" for="addTradePrice">Price</label>
                        <div class="uk-form-controls">
                          <input id="addTradePrice" name="addTradePrice" formControlName="addTradePrice" type="text"
                                 placeholder="Price ..." class="uk-input"
                                 [ngClass]="{'uk-form-danger': addTradeForm.get('addTradePrice').touched && addTradeForm.get('addTradePrice').errors }">
                          <div class="uk-text-danger uk-text-small"
                               *ngIf="addTradeForm.get('addTradePrice').hasError('maxlength')"
                          >
                            {{ getCharactersLimitError('addTradePrice') }}
                          </div>
                          <div class="uk-text-danger uk-text-small"
                               *ngIf="addTradeForm.get('addTradePrice').hasError('pattern')"
                          >
                            Field value is incorrect
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="uk-width-1-2@s">
                      <div class="uk-margin">
                        <label class="uk-form-label" for="addTradeDiscount">Discount</label>
                        <div class="uk-form-controls">
                          <input id="addTradeDiscount" name="addTradeDiscount" formControlName="addTradeDiscount" type="text"
                                 placeholder="Discount ..." class="uk-input"
                                 [ngClass]="{'uk-form-danger': addTradeForm.get('addTradeDiscount').touched && addTradeForm.get('addTradeDiscount').errors }">
                          <div class="uk-text-danger uk-text-small"
                               *ngIf="addTradeForm.get('addTradeDiscount').hasError('maxlength')"
                          >
                            {{ getCharactersLimitError('addTradeDiscount') }}
                          </div>
                          <div class="uk-text-danger uk-text-small"
                               *ngIf="addTradeForm.get('addTradeDiscount').hasError('pattern')"
                          >
                            Field value is incorrect
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="uk-width-1-2@s">
                      <div class="uk-margin">
                        <label class="uk-form-label" for="addTradeCurrency">Currency</label>
                        <div class="uk-form-controls">
                          <input id="addTradeCurrency" name="addTradeCurrency" formControlName="addTradeCurrency"
                                 type="text" placeholder="Currency ..." class="uk-input"
                                 [ngClass]="{'uk-form-danger': addTradeForm.get('addTradeCurrency').touched && addTradeForm.get('addTradeCurrency').errors }"
                                 disabled>
                        </div>
                      </div>
                    </div>
                    <div class="uk-width-1-2@s">
                      <div class="uk-margin">
                        <label class="uk-form-label" for="addTradeCurrency">VAT</label>
                        <div class="uk-form-controls">
                          <select id="addTradeVAT" name="addTradeVAT" formControlName="addTradeVAT" class="uk-select">
                            <option *ngFor="let VAT of VATRates" value="{{ VAT.percentage }}">{{ VAT.percentage }} %
                          </select>
                        </div>
                      </div>
                    </div>
                    <div class="uk-width-1-1">
                      <div class="uk-margin">
                        <label class="uk-form-label">Related artworks</label>
                        <!--<form appNoSubmitEnter id="searchArtwork" [formGroup]="searchArtworkForm">
                          <div class="uk-margin">
                            <div class="uk-form-controls uk-flex">
                              <input id="searchArtworkTerm" name="searchArtworkTerm" formControlName="searchArtworkTerm" class="uk-input uk-margin-small-right" type="search" placeholder="Search artworks..." [ngClass]="{' uk-form-danger': searchArtworkForm.get('searchArtworkTerm').touched && searchArtworkForm.get('searchArtworkTerm').hasError('invalid') }" autocomplete="off">
                              <button class="uk-button uk-button-default" (click)="searchArtwork()">Search</button>
                            </div>
                          </div>
                        </form>-->
                        <div class="uk-text-right">
                          <button class="uk-button uk-button-default" (click)="openAdvancedSearch()">Add</button>
                        </div>
                        <!--<div class="uk-position-relative" [hidden]="!searchArtworkResults.length">
                          <div class="uk-text-right">
                            <button class="uk-button uk-button-primary" (click)="addArtworksToTrade()" [disabled]="!selectedArtworks.length">Add selected</button>
                          </div>
                          <hr class="uk-margin-small-bottom">
                        </div>
                        <div id="search-artwork-results-container" class="results-container" [hidden]="!searchArtworkResults.length">
                          <div class="uk-flex uk-flex-column">
                            &lt;!&ndash;<div class="uk-container-expand uk-height-expand uk-padding-small uk-padding-remove-left uk-padding-remove-right" *ngIf="!searchArtworkResults.length">
                              <ul class="search-results uk-flex-center" uk-tab>
                                <h3>No search results</h3>
                              </ul>
                            </div>&ndash;&gt;
                            <div class="uk-container-expand uk-height-expand">
                              <div class="uk-margin">
                                <div class="uk-width-1-1">
                                  <table class="uk-table uk-table-middle uk-table-divider uk-margin-remove-bottom">
                                    <thead>
                                    <tr>
                                      <th class="uk-table-shrink">Select</th>
                                      <th class="uk-table-shrink">Image</th>
                                      <th class="uk-width-expand">Name</th>
                                      <th class="uk-width-expand uk-text-nowrap">Artist</th>
                                      <th class="uk-table-shrink uk-text-nowrap">Technique</th>
                                    </tr>
                                    </thead>
                                    <tbody>
                                    <tr *ngFor="let artwork of searchArtworkResults">
                                      <td><input class="uk-checkbox" type="checkbox" (click)="selectTradeArtwork(artwork)"></td>
                                      <td>
                                        <div class="image-max-height uk-preserve-width uk-background-contain uk-background-muted uk-height-small uk-panel uk-flex uk-flex-center uk-flex-middle" width="60" [attr.data-src]="'/api/storagefile/' + activeAccount + '/' + artwork.coverImage + '/medium'" uk-img *ngIf="artwork.coverImage">

                                        </div>
                                        &lt;!&ndash; Jos ei ole coverImagea, niin sit otetaan eka image &ndash;&gt;
                                        &lt;!&ndash;<img class="uk-preserve-width" [src]="'/api/storagefile/' + artwork.images[0].fileId" width="60" *ngIf="!artwork.coverImage && artwork.images && artwork.images.length">&ndash;&gt;
                                        <div class="image-max-height uk-preserve-width uk-background-contain uk-background-muted uk-height-small uk-panel uk-flex uk-flex-center uk-flex-middle" width="60" [attr.data-src]="'/api/storagefile/' + activeAccount + '/' + artwork.images[0].fileId + '/medium'" uk-img *ngIf="!artwork.coverImage && artwork.images && artwork.images.length">

                                        </div>
                                        &lt;!&ndash; Jos ei ole coverImagea tai ekan imagea, ni sit otetaan placeholder &ndash;&gt;
                                        &lt;!&ndash;<img class="uk-preserve-width uk-border-circle" src="/api/image/artbuddy-logo-placeholder.svg" *ngIf="!artwork.coverImage && !artwork.images.length" width="60">&ndash;&gt;
                                        <div class="image-max-height uk-preserve-width uk-background-contain uk-background-muted uk-height-small uk-panel uk-flex uk-flex-center uk-flex-middle" width="60" [attr.data-src]="'/api/image/artbuddy-logo-placeholder.svg'" uk-img *ngIf="!artwork.coverImage && !artwork.images.length">

                                        </div>
                                      </td>
                                      <td>{{ artwork.name }}</td>
                                      <td>{{ artwork.artistName }}</td>
                                      <td>{{ artwork.technique }}</td>
                                    </tr>
                                    </tbody>
                                  </table>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>-->
                        <div class="uk-grid-match uk-child-width-1-2@s uk-child-width-1-3@m uk-child-width-1-4@xl"
                             uk-grid uk-sortable [hidden]="!activeTrade.items.length">
                          <div class="uk-card" *ngFor="let item of activeTrade.items">
                            <div
                              class="uk-card-header uk-flex uk-flex-middle uk-flex-between uk-padding-remove-horizontal">
                              <div class="uk-padding-remove-left">
                                <h3 class="uk-card-title uk-margin-remove-bottom">{{ item.name }}</h3>
                                <div class="uk-text-meta">
                                  <span *ngIf="item.artistName">{{ item.artistName }}</span>
                                  <span *ngIf="item.created">, {{ item.created }}</span>
                                </div>
                              </div>
                              <!--<div>
                                <div class="uk-text-meta">{{ item.availability }}</div>
                                <div class="uk-text-meta" *ngIf="item.price.price">{{ item.price.price }} {{ item.price.currency }}</div>
                              </div>-->
                            </div>
                            <div class="uk-card-body uk-padding-remove uk-background-muted artwork-image"
                                 [attr.data-src]="'/api/storagefile/' + activeAccount + '/' + item.coverImage + '/medium'"
                                 uk-img *ngIf="item.coverImage"></div>
                            <div class="uk-card-body uk-padding-remove uk-background-muted artwork-image"
                                 [attr.data-src]="'/api/storagefile/' + activeAccount + '/' + item.images[0].fileId + '/medium'"
                                 uk-img *ngIf="!item.coverImage && item.images && item.images.length"></div>
                            <div class="uk-card-body uk-padding-remove uk-background-muted artwork-image bg-max-size"
                                 *ngIf="!item.coverImage && !item.images.length"></div>
                            <div
                              class="uk-card-footer uk-flex uk-flex-middle uk-flex-between uk-padding-remove-horizontal">
                              <!--<div>
                                <div class="uk-text-meta" *ngIf="item.width && item.height">{{ item.width }} * {{ item.height }} cm</div>
                              </div>-->
                              <div class="uk-flex uk-flex-column">
                                <a (click)="removeArtworkFromTrade(item)" class="uk-button uk-button-text">Remove</a>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="uk-width-1-1 uk-margin-medium-top">
                      <h3 class="uk-margin-remove-bottom">Notes on trade</h3>
                      <hr class="uk-margin-small-top">
                    </div>
                    <div class="uk-width-1-2@s uk-width-1-3@m">
                      <div class="uk-margin">
                        <div class="uk-flex uk-flex-between uk-text-meta">
                          <label class="uk-form-label" for="addTradeShowNotes">Notes</label>
                          <div class="uk-text-small">
                            {{ getCharactersLeft('addTradeShowNotes', 2048) }}
                          </div>
                        </div>
                        <div class="uk-form-controls">
                          <textarea id="addTradeShowNotes" name="addTradeShowNotes" formControlName="addTradeShowNotes"
                                    rows="5" type="text" placeholder="Notes shown on proposal ..." class="uk-textarea"
                                    [ngClass]="{'uk-form-danger': addTradeForm.get('addTradeShowNotes').touched && addTradeForm.get('addTradeShowNotes').errors }"></textarea>
                          <div class="uk-text-danger uk-text-small"
                               *ngIf="addTradeForm.get('addTradeShowNotes').hasError('maxlength')"
                          >
                            {{ getCharactersLimitError('addTradeShowNotes') }}
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="uk-width-1-1 uk-margin-medium-top">
                      <h3 class="uk-margin-remove-bottom">Additional information</h3>
                      <hr class="uk-margin-small-top">
                    </div>
                    <div class="uk-width-1-2">
                      <div class="uk-margin">
                        <div class="uk-flex uk-flex-between uk-text-meta">
                          <label class="uk-form-label" for="addTradeNotes">Other information</label>
                          <div class="uk-text-small">
                            {{ getCharactersLeft('addTradeNotes', 2048) }}
                          </div>
                        </div>
                        <div class="uk-form-controls">
                          <textarea id="addTradeNotes" name="addTradeNotes" formControlName="addTradeNotes" rows="5"
                                    type="text" placeholder="Other information ..." class="uk-textarea"
                                    [ngClass]="{'uk-form-danger': addTradeForm.get('addTradeNotes').touched && addTradeForm.get('addTradeNotes').errors }"></textarea>
                          <div class="uk-text-danger uk-text-small"
                               *ngIf="addTradeForm.get('addTradeNotes').hasError('maxlength')"
                          >
                            {{ getCharactersLimitError('addTradeNotes') }}
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="uk-width-1-2">
                      <div class="uk-margin">
                        <div class="uk-flex uk-flex-between uk-text-meta">
                          <label class="uk-form-label" for="addTradeComments">Comments</label>
                          <div class="uk-text-small">
                            {{ getCharactersLeft('addTradeComments', 2048) }}
                          </div>
                        </div>
                        <div class="uk-form-controls">
                          <textarea id="addTradeComments" name="addTradeComments" formControlName="addTradeComments"
                                    rows="5" type="text" placeholder="Comments ..." class="uk-textarea"
                                    [ngClass]="{'uk-form-danger': addTradeForm.get('addTradeComments').touched && addTradeForm.get('addTradeComments').errors }"></textarea>
                          <div class="uk-text-danger uk-text-small"
                               *ngIf="addTradeForm.get('addTradeComments').hasError('maxlength')"
                          >
                            {{ getCharactersLimitError('addTradeComments') }}
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="uk-width-1-2">
                      <div class="uk-margin">
                        <label class="uk-form-label" for="addTradeTags">Tags</label>
                        <div class="uk-form-controls">
                          <input id="addTradeTags" name="addTradeTags" formControlName="addTradeTags" type="text"
                                 placeholder="Tags ..." class="uk-input"
                                 [ngClass]="{'uk-form-danger': addTradeForm.get('addTradeTags').touched && addTradeForm.get('addTradeTags').errors }">
                          <div class="uk-text-danger uk-text-small"
                               *ngIf="addTradeForm.get('addTradeTags').hasError('maxlength')"
                          >
                            {{ getCharactersLimitError('addTradeTags') }}
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="uk-width-1-2">
                      <div class="uk-margin">
                        <label class="uk-form-label" for="addTradeVisibility">Visibility</label>
                        <div class="uk-form-controls">
                          <label><input id="addTradeVisibility" name="addTradeVisibility"
                                        formControlName="addTradeVisibility" class="uk-checkbox" type="checkbox"> Public</label>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="uk-modal-footer">
                    <p class="uk-text-right">
                      <button class="uk-button uk-button-default uk-modal-close uk-margin-small-right" type="button"
                              id="addNewTradeCancel" (click)="resetForm()">Cancel
                      </button>
                      <button type="submit" [disabled]="!addTradeForm.valid" (click)="addTrade()"
                              class="uk-button uk-button-primary">Save
                      </button>
                    </p>
                  </div>
                </form>
              </div>
            </div>
            <button class="uk-button uk-button-text uk-margin-right" type="button">Sort <span uk-icon="icon: chevron-down"></span></button>
            <div uk-dropdown="mode: click;">
              <ul class="uk-nav uk-dropdown-nav">
<!--                <li [ngClass]="{'uk-active': activeSort('created') }" (click)="sortTrades('created')"><a>Created</a></li>-->
                <li [ngClass]="{'uk-active': activeSort('sequence') }" (click)="sortTrades('sequence')"><a>Number</a>
                </li>
                <li [ngClass]="{'uk-active': activeSort('name') }" (click)="sortTrades('name')"><a>Name</a></li>
                <li [ngClass]="{'uk-active': activeSort('actor') }" (click)="sortTrades('actor')"><a>Actor</a></li>
                <li [ngClass]="{'uk-active': activeSort('type') }" (click)="sortTrades('type')"><a>Type</a></li>
                <li [ngClass]="{'uk-active': activeSort('price') }" (click)="sortTrades('price')"><a
                >Price</a></li>
              </ul>
            </div>
            <button class="uk-button uk-button-text" type="button">Limit {{ limitQuery ? ': ' + limitQuery : null }} <span uk-icon="icon: chevron-down"></span></button>
            <div uk-dropdown="mode: click;">
              <ul class="uk-nav uk-dropdown-nav">
                <li [ngClass]="{'uk-active': activeLimit(10) }" (click)="setLimit(10)"><a>10</a></li>
                <li [ngClass]="{'uk-active': activeLimit(25) }" (click)="setLimit(25)"><a>25</a></li>
                <li [ngClass]="{'uk-active': activeLimit(50) }" (click)="setLimit(50)"><a>50</a></li>
                <li [ngClass]="{'uk-active': activeLimit(100) }" (click)="setLimit(100)"><a>100</a></li>
                <li [ngClass]="{'uk-active': activeLimit(200) }" (click)="setLimit(200)"><a>200</a></li>
              </ul>
            </div>
          </div>
          <div class="uk-height-1-1">
            <table class="uk-table uk-table-middle uk-table-divider">
              <thead>
              <tr>
                <th class="uk-text-nowrap" [ngClass]="{'active': activeSort('sequence') }"
                    (click)="sortTrades('sequence')">Number<span uk-icon="chevron-down"
                                                                 [ngClass]="{'inverse': !sortAscending }"></span></th>
                <th class="uk-text-nowrap" [ngClass]="{'active': activeSort('name') }" (click)="sortTrades('name')">Name<span
                  uk-icon="chevron-down" [ngClass]="{'inverse': !sortAscending }"></span></th>
                <th class="uk-text-nowrap uk-visible@m" [ngClass]="{'active': activeSort('actor') }"
                    (click)="sortTrades('actor')">Actor<span uk-icon="chevron-down"
                                                             [ngClass]="{'inverse': !sortAscending }"></span></th>
                <th class="uk-text-nowrap uk-visible@m" [ngClass]="{'active': activeSort('type') }"
                    (click)="sortTrades('type')">Type<span uk-icon="chevron-down"
                                                           [ngClass]="{'inverse': !sortAscending }"></span></th>
                <th class="uk-text-nowrap uk-visible@m" [ngClass]="{'active': activeSort('price') }"
                    (click)="sortTrades('price')">Price<span uk-icon="chevron-down"
                                                             [ngClass]="{'inverse': !sortAscending }"></span></th>
                <th class="uk-table-shrink"></th>
              </tr>
              </thead>
              <tbody>
              <tr *ngFor="let trade of trades">
                <td>{{ trade.sequence }}</td>
                <td (click)="openTrade(trade._id)" class="pointer">{{ trade.name }}</td>
                <td class="uk-visible@m">{{ trade.seller?.name }} {{ trade.seller?.lastName }}</td>
                <td class="uk-visible@m">{{ trade.type }}</td>
                <td class="uk-visible@m">{{ trade.price }} {{ trade.currency }}</td>
                <td class="uk-text-right">
                  <div class="uk-button-group">
                    <button (click)="openTrade(trade._id)" class="uk-button uk-button-text uk-margin-right" type="button">Open</button>
                    <div class="uk-inline" class="uk-visible@m">
                      <button class="uk-button uk-button-link" type="button"><span uk-icon="icon: chevron-down"></span></button>
                      <div uk-dropdown="mode: click; boundary: ! .uk-button-group; boundary-align: true;">
                        <ul class="uk-nav uk-dropdown-nav">
                          <li><a (click)="removeTrade(trade._id)">Remove</a></li>
                        </ul>
                      </div>
                    </div>
                  </div>
                </td>
              </tr>
              </tbody>
            </table>
          </div>
          <ul class="uk-pagination uk-flex-center uk-flex-middle uk-margin-medium-top" *ngIf="pages.length > 1">
            <li class="uk-margin-small-right" [ngClass]="{'uk-disabled': (currentPage === 1) }">
              <a (click)="setCurrentPage(currentPage-1)">
                <span class="uk-margin-small-right" uk-pagination-previous></span> Previous
              </a>
            </li>
            <li>
              <select class="uk-select" [ngModel]="currentPage" (change)="setCurrentPageString($event.target.value)">
                <option *ngFor="let page of pages; let index = index; first as isFirst; last as isLast"
                        [value]="index+1">{{ index + 1 }}</option>
              </select>
            </li>
            <li class="uk-margin-small-left" [ngClass]="{'uk-disabled': (currentPage === pageCount) }">
              <a (click)="setCurrentPage(currentPage+1)">
                Next <span class="uk-margin-small-left" uk-pagination-next></span>
              </a>
            </li>
          </ul>
        </div>
      </div>
    </div>
  </div>
</div>
<app-advanced-search-modal (setResults)="setArtworkSearchResults($event)"
                           *ngIf="showAdvancedArtworkSearchModalWindow"></app-advanced-search-modal>
