import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, RouterStateSnapshot, Resolve, Router } from '@angular/router';
import { ActiveService } from './active.service';
import { ActorService } from './actor.service';
import { ItemService } from './item.service';
import { ExhibitionService } from './exhibition.service';
import { CollectionService } from './collection.service';
import { EstimateService } from './estimate.service';
import { BidService } from './bid.service';
import { ReservationService } from './reservation.service';
import { TradeService } from './trade.service';
import { AccountService } from './account.service';
import { HeaderComponent } from '../header/header.component';
declare var UIkit: any;

@Injectable()
export class ResolverService implements Resolve<any> {

  constructor(
    private router: Router,
    private activeService: ActiveService,
    private accountService: AccountService,
    private itemService: ItemService,
    private exhibitionService: ExhibitionService,
    private collectionService: CollectionService,
    private estimateService: EstimateService,
    private bidService: BidService,
    private reservationService: ReservationService,
    private tradeService: TradeService,
    private actorService: ActorService) {
  }

  resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Promise<any> {
    return new Promise<void>((resolve, reject) => {
      route.pathFromRoot.forEach(routerState => {
        if (routerState.url.length) {
          const currentPath: string = routerState.url[0].path;
          if (['artworks', 'artists', 'exhibitions', 'collections', 'clients', 'estimates', 'bids', 'reservations', 'trades', 'accounts'].indexOf(currentPath) >= 0) {
            this.activeService.getActiveData().subscribe(
              activeData => {
                resolve(activeData);
              },
              err => {
                UIkit.notification({
                  message: 'Error fetching information',
                  status: 'danger',
                  pos: 'top-right',
                  timeout: 1500
                });
                reject({message: err , ngNavigationCancelingError: true});
              }
            );
          } else if (currentPath === 'artist') {
            const artistId = routerState.params.id;
            this.actorService.getActor(artistId).subscribe(
              response => {
                response = {
                  ...response.actor,
                  items: response.items,
                  exhibitions: response.exhibitions,
                  collections: response.collections,
                  clients: response.clients,
                  bids: response.bids,
                  reservations: response.reservations,
                  trades: response.trades,
                  interestedClients: response.interestedClients,
                };
                this.activeService.setActiveArtist(response._id);
                if (response) {
                  resolve(response);
                }
              },
              err => {
                UIkit.notification({
                  message: 'Error fetching information',
                  status: 'danger',
                  pos: 'top-right',
                  timeout: 1500
                });
                reject({message: err, ngNavigationCancelingError: true});
              }
            );
          } else if (currentPath === 'artwork') {
            const artworkId = routerState.params.id;
            this.itemService.getItem(artworkId).subscribe(
              artwork => {
                if (artwork.item) {
                  artwork.item.editions = artwork.editions;
                  this.activeService.setActiveItem(artwork.item._id);
                  resolve(artwork);
                } else {
                  UIkit.notification({
                    message: 'Error fetching information',
                    status: 'danger',
                    pos: 'top-right',
                    timeout: 1500
                  });
                  reject({message: '' , ngNavigationCancelingError: true});
                }
              },
              err => {
                this.router.navigate(['notfound']);
                UIkit.notification({
                  message: 'Error fetching information',
                  status: 'danger',
                  pos: 'top-right',
                  timeout: 1500
                });
                reject({message: err , ngNavigationCancelingError: true});
              }
            );
          } else if (currentPath === 'exhibition') {
            const exhibitionId = routerState.params.id;
            this.exhibitionService.getExhibition(exhibitionId).subscribe(
              data => {
                const exhibition = data.exhibition;
                this.activeService.setActiveExhibition(exhibition._id);
                if (exhibition) {
                  resolve(exhibition);
                }
              },
              err => {
                UIkit.notification({
                  message: 'Error fetching information',
                  status: 'danger',
                  pos: 'top-right',
                  timeout: 1500
                });
                reject({message: err , ngNavigationCancelingError: true});
              }
            );
          } else if (currentPath === 'collection') {
            const collectionId = routerState.params.id;
            this.collectionService.getCollection(collectionId).subscribe(
              data => {
                let collection = data.collection;
                collection.items = data.collectionItems;
                this.activeService.setActiveCollection(collection._id);
                if (collection) {
                  resolve(collection);
                }
              },
              err => {
                UIkit.notification({
                  message: 'Error fetching information',
                  status: 'danger',
                  pos: 'top-right',
                  timeout: 1500
                });
                reject({message: err , ngNavigationCancelingError: true});
              }
            );
          } else if (currentPath === 'estimate') {
            const estimateId = routerState.params.id;
            this.estimateService.getEstimate(estimateId).subscribe(
              data => {
                let estimate = data.estimate;
                estimate.items = data.estimateItems;
                this.activeService.setActiveEstimate(estimate._id);
                if (estimate) {
                  resolve(estimate);
                }
              },
              err => {
                UIkit.notification({
                  message: 'Error fetching information',
                  status: 'danger',
                  pos: 'top-right',
                  timeout: 1500
                });
                reject({message: err , ngNavigationCancelingError: true});
              }
            );
          } else if (currentPath === 'bid') {
            const bidId = routerState.params.id;
            this.bidService.getBid(bidId).subscribe(
              data => {
                let bid = data.bid;
                bid.items = data.bidItems;
                this.activeService.setActiveBid(bid._id);
                if (bid) {
                  resolve(bid);
                }
              },
              err => {
                UIkit.notification({
                  message: 'Error fetching information',
                  status: 'danger',
                  pos: 'top-right',
                  timeout: 1500
                });
                reject({message: err , ngNavigationCancelingError: true});
              }
            );
          } else if (currentPath === 'reservation') {
            const reservationId = routerState.params.id;
            this.reservationService.getReservation(reservationId).subscribe(
              data => {
                let reservation = data.reservation;
                reservation.items = data.reservationItems;
                this.activeService.setActiveReservation(reservation._id);
                if (reservation) {
                  resolve(reservation);
                }
              },
              err => {
                UIkit.notification({
                  message: 'Error fetching information',
                  status: 'danger',
                  pos: 'top-right',
                  timeout: 1500
                });
                reject({message: err , ngNavigationCancelingError: true});
              }
            );
          } else if (currentPath === 'trade') {
            const tradeId = routerState.params.id;
            this.tradeService.getTrade(tradeId).subscribe(
              data => {
                let trade = data.trade;
                trade.items = data.tradeItems;
                this.activeService.setActiveTrade(trade._id);
                if (trade) {
                  resolve(trade);
                }
              },
              err => {
                UIkit.notification({
                  message: 'Error fetching information',
                  status: 'danger',
                  pos: 'top-right',
                  timeout: 1500
                });
                reject({message: err , ngNavigationCancelingError: true});
              }
            );
          } else if (currentPath === 'account') {
            const accountObject = {
              _id: routerState.params.id
            };
            this.accountService.getAccount(accountObject).subscribe(
              data => {
                this.activeService.setActiveAccount(data.account.account._id);
                let combinedAccount = data.account.account;
                combinedAccount.users = data.users;

                /* replace attachments array with a combination
                 * of attachments and attachmentsWithMeta */
                if (combinedAccount.attachments) {
                  combinedAccount.attachments = combinedAccount.attachments.map((attachment) => {
                    if (attachment) {
                      for (let attachmentMeta of data.account.attachmentsWithMeta) {
                        if (attachment.fileId && attachment.fileId == attachmentMeta._id) Object.assign(attachment, attachmentMeta);
                      }
                      return attachment;
                    }
                  });
                  /* Delete original attachmentsWithMeta */
                  delete combinedAccount.attachmentsMeta;

                  if ((combinedAccount.attachments.length == 1) && (combinedAccount.attachments[0] == null)) {
                    delete combinedAccount.attachments;
                  }
                }

                if (combinedAccount) {
                  resolve(combinedAccount);
                } else {
                  reject();
                }
              },
              err => {
                UIkit.notification({
                  message: 'Error fetching information',
                  status: 'danger',
                  pos: 'top-right',
                  timeout: 1500
                });
                reject({message: err , ngNavigationCancelingError: true});
              }
            );
          } else if (currentPath === 'client') {
            const clientId = routerState.params.id;
            this.actorService.getActor(clientId).subscribe(
              client => {
                this.activeService.setActiveClient(client._id);
                if (client) {
                  resolve(client);
                }
              },
              err => {
                UIkit.notification({
                  message: 'Error fetching information',
                  status: 'danger',
                  pos: 'top-right',
                  timeout: 1500
                });
                reject({message: err , ngNavigationCancelingError: true});
              }
            );
          } else if (currentPath === 'overview') {
            this.activeService.getActiveData().subscribe(
              activeData => {
                resolve(activeData);
              },
              err => {
                UIkit.notification({
                  message: 'Error fetching information',
                  status: 'danger',
                  pos: 'top-right',
                  timeout: 1500
                });
                reject({message: err , ngNavigationCancelingError: true});
              }
            );
          }
        }
      });
    });
  }

}
