import { Injectable } from '@angular/core';
import { AccountService } from '../services/account.service';
import { ActiveService } from '../services/active.service';
import { Observable } from 'rxjs';
import { Subject } from 'rxjs';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import 'rxjs/add/operator/map';

@Injectable()
export class EstimateService {
  private httpOptions = {
    headers: new HttpHeaders({
      'Content-Type': 'application/json',
      charset: 'UTF-8'
    })
  };
  private currentAccount: any;
  currentEstimate: string = '';
  currentEstimateChange: Subject<string> = new Subject<string>();

  constructor(
    private http: HttpClient,
    private activeService: ActiveService,
    private accountService: AccountService
  ) {
    this.activeService.activeDataChange.subscribe(data => {
      this.currentAccount = data.account.id;
    });
  }

  setCurrentEstimate(estimateId): void {
    this.currentEstimate = estimateId;
    this.currentEstimateChange.next(this.currentEstimate);
  }

  getCurrentEstimate(): string {
    return this.currentEstimate;
  }

  getEstimates(accountId: String, EstimateType: String): Observable<any> {
    /*let activeAccount;
    this.activeService.getActiveData().subscribe(data => {
      activeAccount = data.account.id;
    });*/
    return this.http.get(`/api/estimates/${accountId}/${EstimateType}`);
  }

  countEstimates(): Observable<any> {
    return this.http.get('/api/estimates/count');
  }

  addEstimate(estimate): Observable<any> {
    return this.http.post('/api/estimate', JSON.stringify(estimate), this.httpOptions);
  }

  getEstimate(estimate): Observable<any> {
    return this.http.get(`/api/estimate/${estimate}`);
  }

  getUserEstimate(estimate): Observable<any> {
    return this.http.get(`/api/userEstimate/${estimate._id}`);
  }

  editEstimate(estimate): Observable<any> {
    if (!estimate.id) {
      estimate.id = estimate.personalFormId;
    }
    return this.http.put(
      `/api/estimate/${estimate.id}`,
      JSON.stringify(estimate),
      this.httpOptions
    );
  }

  deleteEstimate(estimateId): Observable<any> {
    return this.http.delete(`/api/estimate/${estimateId}`, this.httpOptions);
  }

  estimateImageId(fileObject: object): Observable<any> {
    return this.http.post(
      '/api/estimateImageId',
      JSON.stringify(fileObject),
      this.httpOptions
    );
  }
}
