<app-toast [message]="toast.message"></app-toast>

<div class="uk-flex uk-flex-middle uk-text-center uk-section" *ngIf="resetRequested">
  <div class="uk-container uk-flex-center">
    <div class="uk-placeholder uk-background-default uk-text-center">Your password has been reset. Please check your email to continue.</div>
  </div>
</div>

<div id="login" uk-height-viewport="offset-top: true" class="uk-flex uk-flex-middle uk-text-center uk-section">
  <div class="uk-container uk-width-viewport">
    <div class="uk-grid-match uk-child-width-expand@m" uk-grid>
      <div class="uk-flex-center">
        <div class="uk-card uk-card-default uk-width-1-2@s">
          <div class="uk-card-header no-min-height">
            <div class="uk-grid-small uk-flex-middle" uk-grid>
              <div class="uk-width-expand">
                <h3 class="uk-card-title uk-margin-remove-bottom">Log in</h3>
              </div>
            </div>
          </div>
          <form appNoSubmitEnter [formGroup]="loginForm" (submit)="login()" (keydown.meta.Enter)="login()" class="uk-form-stacked">
            <div class="uk-card-body uk-text-left">
              <div class="uk-margin">
                <div class="uk-width-1-1">
                  <div class="uk-margin">
                    <label class="uk-form-label" for="loginEmail">Email</label>
                    <div class="uk-form-controls">
                      <input autoFocus id="loginEmail" name="loginEmail" formControlName="loginEmail" type="text" placeholder="Email ..." class="uk-input" [ngClass]="{' uk-form-danger': loginForm.get('loginEmail').touched && loginForm.get('loginEmail').hasError('required') }">
                      <div *ngIf="loginForm.get('loginEmail').invalid && (loginForm.get('loginEmail').dirty || loginForm.get('loginEmail').touched)" class="uk-text-danger uk-text-small">
                        <div *ngIf="loginForm.get('loginEmail').hasError('required')">
                          Email is required
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="uk-width-1-1">
                  <label class="uk-form-label" for="loginPassword">Password</label>
                  <div class="uk-form-controls">
                    <input id="loginPassword" name="loginPassword" formControlName="loginPassword" type="password" placeholder="Password ..." class="uk-input" [ngClass]="{ 'uk-form-danger': loginForm.get('loginPassword').touched && loginForm.get('loginPassword').hasError('required') }">
                    <div *ngIf="loginForm.get('loginPassword').invalid && (loginForm.get('loginPassword').dirty || loginForm.get('loginPassword').touched)" class="uk-text-danger uk-text-small">
                      <div *ngIf="loginForm.get('loginPassword').hasError('required')">
                        Password is required
                      </div>
                    </div>
                  </div>
                </div>
                <div class="uk-width-1-1 uk-text-right">
                  <button id="forgot" type="button" class="uk-button uk-button-text uk-text-muted" (click)="goForgot()">Reset password</button>
                </div>
              </div>
            </div>
            <div class="uk-card-footer uk-text-center">
              <button type="submit" class="uk-button uk-button-primary">Log in</button>
            </div>
          </form>
        </div>
      </div>
    </div>
  </div>
</div>


<!--<div class="card">
  <h4 class="card-header">Login</h4>
  <div class="card-block">
    <form appNoSubmitEnter [formGroup]="loginForm" (ngSubmit)="login()">
      <div class="input-group" [ngClass]="setClassEmail()">
        <span class="input-group-addon"><i class="fa fa-envelope"></i></span>
        <input class="form-control" type="loginEmail" name="loginEmail" formControlName="loginEmail" placeholder="Email" autofocus>
      </div>
      <div class="input-group" [ngClass]="setClassPassword()">
        <span class="input-group-addon"><i class="fa fa-key"></i></span>
        <input class="form-control" type="loginPassword" name="loginPassword" formControlName="loginPassword" placeholder="Password">
      </div>
      <button class="btn btn-primary" type="submit" [disabled]="!loginForm.valid"><i class="fa fa-sign-in"></i> Login</button>
    </form>
  </div>
</div>-->
