import {Component, EventEmitter, OnInit, ViewChild} from '@angular/core';
import { FormGroup, FormControl, Validators, FormBuilder } from '@angular/forms';
import { AuthService } from '../services/auth.service';
import { UserService } from '../services/user.service';
import { ActorService } from '../services/actor.service';
import { ActiveService } from '../services/active.service';
import { FileService } from '../services/file.service';
import { AccountService } from '../services/account.service';
import { ItemService } from '../services/item.service';
import { UploadOutput, UploadInput, UploadFile, humanizeBytes, UploaderOptions } from 'ngx-uploader';
declare var UIkit: any;

@Component({
  selector: 'app-account',
  templateUrl: './profile.component.html',
  styleUrls: ['./profile.component.scss']
})
export class ProfileComponent implements OnInit {
  actor: any = {};
  user: any = {};
  accounts: Array<Object> = [];
  isLoading = true;
  public activeAccount: any = {};
  profileForm: FormGroup;
  personalForm: FormGroup;
  addAccountForm: FormGroup;
  editAccountForm: FormGroup;
  options: UploaderOptions;
  formData: FormData;
  filesToUpload: UploadFile[];
  uploadInput: EventEmitter<UploadInput>;
  humanizeBytes: Function;
  dragOver: boolean;

  constructor(
    private auth: AuthService,
    private userService: UserService,
    private actorService: ActorService,
    private activeService: ActiveService,
    private accountService: AccountService,
    private fileService: FileService,
    private itemService: ItemService,
    private formBuilder: FormBuilder
  ) {
    this.filesToUpload = []; // local uploading files array
    this.uploadInput = new EventEmitter<UploadInput>(); // input events, we use this to emit data to ngx-uploader
    this.humanizeBytes = humanizeBytes;

    this.profileForm = this.formBuilder.group({
      profileFormEmail: new FormControl('', [
        Validators.required,
        Validators.maxLength(100)
      ]),
      profileFormPassword: new FormControl('', [
        Validators.required,
        Validators.maxLength(100)
      ]),
      profileFormAvatar: new FormControl(''),
      id: new FormControl('', [
        Validators.maxLength(100)
      ])
    });

    this.personalForm = this.formBuilder.group({
      personalFormName: new FormControl('', [
        Validators.required,
        Validators.maxLength(100)
      ]),
      personalFormStreet: new FormControl('', [
        Validators.maxLength(100)
      ]),
      personalFormCity: new FormControl('', [
        Validators.maxLength(100)
      ]),
      personalFormState: new FormControl('', [
        Validators.maxLength(100)
      ]),
      personalFormZip: new FormControl('', [
        Validators.maxLength(100)
      ]),
      personalFormCountry: new FormControl('', [
        Validators.maxLength(100)
      ]),
      personalFormPhone: new FormControl('', [
        Validators.maxLength(100)
      ]),
      personalFormId: new FormControl('', [
        Validators.maxLength(100)
      ]),
      personalFormOwner: new FormControl('', [
        Validators.maxLength(100)
      ]),
      type: new FormControl('', [
        Validators.maxLength(100)
      ])
    });

    this.filesToUpload = [];
    this.uploadInput = new EventEmitter<UploadInput>();
    this.humanizeBytes = humanizeBytes;
  }

  onUploadOutput(output: UploadOutput): void {
    const properFile = output.file && output.file.name && output.file.name.length > 0;

    if (output.type === 'allAddedToQueue') {
      const event: UploadInput = {
        type: 'uploadAll',
        url: '/api/file',
        method: 'POST',
        includeWebKitFormBoundary: true
      };
      this.uploadInput.emit(event);
    } else if (output.type === 'addedToQueue' && properFile) {
      if (this.filesToUpload.indexOf(output.file) == -1) {
        this.filesToUpload.push(output.file);
      }
    } else if (output.type === 'uploading' && properFile) {
      const index = this.filesToUpload.findIndex(file => typeof output.file !== 'undefined' && file.id === output.file.id);
      this.filesToUpload[index] = output.file;
    } else if (output.type === 'removed') {
      // remove file from array when removed
      this.filesToUpload = this.filesToUpload.filter((file: UploadFile) => file !== output.file);
    } else if (output.type === 'dragOver') {
      this.dragOver = true;
    } else if (output.type === 'dragOut') {
      this.dragOver = false;
    } else if (output.type === 'drop') {
      this.dragOver = false;
    } else if (output.type === 'removedAll') {
      this.filesToUpload.length = 0;
    } else if (output.type === 'done') {
      this.removeFile(output.file.id);
    }
  }

  startUpload(): void {
    const event: UploadInput = {
      type: 'uploadAll',
      url: '/api/file',
      method: 'POST',
    };
    this.uploadInput.emit(event);
  }

  cancelUpload(id: string): void {
    this.uploadInput.emit({ type: 'cancel', id: id });
  }

  removeFile(id: string): void {
    this.uploadInput.emit({ type: 'remove', id: id });
  }

  openAttachment(attachment) {
    this.itemService.openAttachment(this.activeAccount, attachment).then((response: any) => {
      if (response.url) {
        window.open(response.url);
      } else {
        UIkit.notification({
          message: 'Error fetching data',
          status: 'danger',
          pos: 'top-right',
          timeout: 1500
        });
      }
    });
  }

  /*removeAttachment(attachment) {
    const attachmentObject = {
      accountId: this.activeAccount,
      attachmentId: attachment.fileId
    };
    this.fileService.removeFile(attachmentObject).subscribe(
      res => {
        const attachmentToSplice = this.activeTrade.attachments.find((accountAttachment: any) => {
          return attachment.fileId === accountAttachment.fileId;
        });
        this.activeTrade.attachments.splice(this.activeTrade.attachments.indexOf(attachmentToSplice), 1);
        UIkit.notification({
          message: 'File removed successfully',
          status: 'success',
          pos: 'top-right',
          timeout: 1500
        });
      },
      error => {
        UIkit.notification({
          message: 'Removing failed',
          status: 'danger',
          pos: 'top-right',
          timeout: 1500
        });
      }
    );
  }*/

  removeAllFiles(): void {
    this.uploadInput.emit({ type: 'removeAll' });
  }

  ngOnInit() {
    this.getActor();
    this.getUser();
    this.activeService.getActiveData().subscribe(
      data => {
        this.activeAccount = data.account.id;
      }
    );
  }

  getActor() {
    this.actorService.getUserActor(this.auth.currentUser).subscribe(
      data => {
        if (data) {
          this.actor = data;
        } else {
          this.actor = {
            _id: null,
            owner: null
          };
        }
      },
      error => {
        UIkit.notification({
          message: 'Error fetching user information',
          status: 'danger',
          pos: 'top-right',
          timeout: 1500
        });
      },
      () => this.isLoading = false
    );
  }

  // TODO: Tämä pitäis yhdistää tietokannan päässä yhteen hakuun, joka palauttaa sekä userId (ownerin) että actorin tiedot
  getUser() {
    this.userService.getUser(this.auth.currentUser).subscribe(
      data => {
        this.user = data;
        this.profileForm.patchValue({
          profileFormEmail: data.email
        });
      },
      error => {
        UIkit.notification({
          message: 'Error fetching user information',
          status: 'danger',
          pos: 'top-right',
          timeout: 1500
        });
      },
      () => this.isLoading = false
    );
  }

  submitProfileForm() {
    this.userService.editUser(this.profileForm.value).subscribe(
      res => {
        UIkit.notification({
          message: 'Saved successfully',
          status: 'success',
          pos: 'top-right',
          timeout: 1500
        });
      },
      error => {
        UIkit.notification({
          message: 'Saving failed',
          status: 'danger',
          pos: 'top-right',
          timeout: 1500
        });
      }
    );
  }

  submitPersonalForm() {
    if (!this.actor._id) {
      this.personalForm.value.personalFormOwner = this.user._id;
      this.personalForm.value.actorType = 'Personal';
      this.actorService.addActor(this.personalForm.value).subscribe(
        res => {
          this.ngOnInit();
          this.isLoading = false;
          UIkit.notification({
            message: 'Saved successfully',
            status: 'success',
            pos: 'top-right',
            timeout: 1500
          });
        },
        error => {
          this.isLoading = false;
          UIkit.notification({
            message: 'Saving failed',
            status: 'danger',
            pos: 'top-right',
            timeout: 1500
          });
        }
      );
    } else {
      this.actorService.editActor(this.user._id, this.personalForm.value).subscribe(
        res => {
          this.ngOnInit();
          this.isLoading = false;
          UIkit.notification({
            message: 'Saved successfully',
            status: 'success',
            pos: 'top-right',
            timeout: 1500
          });
        },
        error => {
          this.isLoading = false;
          UIkit.notification({
            message: 'Saving failed',
            status: 'danger',
            pos: 'top-right',
            timeout: 1500
          });
        }
      );
    }
  }

  addFile(): void {
    const fileSelected: File = this.editAccountForm.value.editAccountAttachments;
    this.accountService.uploadFile(fileSelected)
      .subscribe(res => {});
  }

  onChange($event: any) {
    this.editAccountForm.value.editAccountAttachments = $event.target.files[0];
  }

}
