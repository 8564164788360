import { Injectable } from '@angular/core';
import { AccountService } from '../services/account.service';
import { ActiveService } from '../services/active.service';
import { Observable } from 'rxjs';
import { Subject } from 'rxjs';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import 'rxjs/add/operator/map';

@Injectable()
export class CollectionService {
  private httpOptions = {
    headers: new HttpHeaders({
      'Content-Type': 'application/json',
      charset: 'UTF-8'
    })
  };
  private currentAccount: any;
  currentCollection: string = '';
  currentCollectionChange: Subject<string> = new Subject<string>();

  constructor(
    private http: HttpClient,
    private activeService: ActiveService,
    private accountService: AccountService
  ) {
    this.activeService.activeDataChange.subscribe(data => {
      this.currentAccount = data.account.id;
    });
  }

  setCurrentCollection(collectionId): void {
    this.currentCollection = collectionId;
    this.currentCollectionChange.next(this.currentCollection);
  }

  getCurrentCollection(): string {
    return this.currentCollection;
  }

  getCollections(accountId: String, CollectionType: String): Observable<any> {
    /*let activeAccount;
    this.activeService.getActiveData().subscribe(data => {
      activeAccount = data.account.id;
    });*/
    return this.http.get(`/api/collections/${accountId}/${CollectionType}`);
  }

  countCollections(): Observable<any> {
    return this.http.get('/api/collections/count');
  }

  addCollection(collection): Observable<any> {
    return this.http.post('/api/collection', JSON.stringify(collection), this.httpOptions);
  }

  getCollection(collection): Observable<any> {
    return this.http.get(`/api/collection/${collection}`);
  }

  getUserCollection(collection): Observable<any> {
    return this.http.get(`/api/userCollection/${collection._id}`);
  }

  editCollection(collection): Observable<any> {
    if (!collection.id) {
      collection.id = collection.personalFormId;
    }
    return this.http.put(
      `/api/collection/${collection.id}`,
      JSON.stringify(collection),
      this.httpOptions
    );
  }

  deleteCollection(collectionId): Observable<any> {
    return this.http.delete(`/api/collection/${collectionId}`, this.httpOptions);
  }

  collectionImageId(fileObject: object): Observable<any> {
    return this.http.post(
      '/api/collectionImageId',
      JSON.stringify(fileObject),
      this.httpOptions
    );
  }

  validateCollectionName(item: any): Observable<any> {
    return this.http.post(
      `/api/validateItemGroupName`,
      JSON.stringify(item),
      this.httpOptions
    );
  }
}
