<div class="uk-section uk-section-default uk-padding-remove-vertical">

  <app-sidenav contentType="Clients" contentItem="{{activeClient.name}} {{activeClient.lastName}}"></app-sidenav>

  <div class="uk-container-expand uk-height-expand">
    <ul uk-tab>
      <li class="uk-active"><a href="#clientinfo"><h4>Basic information</h4></a></li>
      <li *ngIf="activeClientArtworks.length"><a href="#clientartworks"><h4>Artworks</h4></a></li>
      <li *ngIf="activeClientArtists.length"><a href="#clientartists"><h4>Artists</h4></a></li>
      <li *ngIf="activeClientBids.length"><a href="#clientbids"><h4>Proposals</h4></a></li>
      <li *ngIf="activeClientReservations.length"><a href="#clientreservations"><h4>Reservations</h4></a></li>
      <li *ngIf="activeClientTrades.length"><a href="#clienttrades"><h4>Trades</h4></a></li>
    </ul>
    <ul class="uk-switcher uk-card uk-card-body uk-padding-remove-top" [hidden]="isLoading">
      <li id="clientinfo" class="uk-padding-small">
        <form appNoSubmitEnter [formGroup]="editClientForm" (ngSubmit)="editClient()" class="uk-grid-small" uk-grid>
          <input type="hidden" name="editClientActorType" name="editClientActorType"
                 formControlName="editClientActorType" [ngModel]="actorType">
          <input type="hidden" name="editClientOwner" name="editClientOwner" formControlName="editClientOwner"
                 [ngModel]="user._id">
          <div class="uk-width-1-1@s uk-margin-medium-top">
            <h3 class="uk-margin-remove-bottom">Basic information</h3>
            <hr class="uk-margin-small-top">
          </div>
          <div class="uk-width-1-2@s uk-width-1-3@m">
            <div class="uk-margin">
              <label class="uk-form-label" for="editClientName">Name <span class="required">*</span></label>
              <div class="uk-form-controls">
                <input id="editClientName" name="editClientName" formControlName="editClientName"
                       [ngModel]="activeClient.name" type="text" placeholder="Name ..." class="uk-input"
                       [ngClass]="{'uk-form-danger': editClientForm.get('editClientName').touched && editClientForm.get('editClientName').hasError('required') }">
                <div class="uk-text-danger uk-text-small"
                     *ngIf="editClientForm.get('editClientName').hasError('maxlength')"
                >
                  {{ getCharactersLimitError('editClientName') }}
                </div>
                <div class="uk-text-danger uk-text-small"
                     *ngIf="editClientForm.get('editClientName').hasError('pattern')"
                >
                  Field value is incorrect
                </div>
              </div>
            </div>
          </div>
          <div class="uk-width-1-2@s uk-width-1-3@m" *ngIf="clientType == 'PrivatePerson'">
            <div class="uk-margin">
              <label class="uk-form-label" for="editClientLastName">Last name</label>
              <div class="uk-form-controls">
                <input id="editClientLastName" name="editClientLastName" formControlName="editClientLastName"
                       [ngModel]="activeClient.lastName" type="text" placeholder="Last name ..." class="uk-input"
                       [ngClass]="{'uk-form-danger': editClientForm.get('editClientLastName').touched && editClientForm.get('editClientLastName').hasError('required') }">
                <div class="uk-text-danger uk-text-small"
                     *ngIf="editClientForm.get('editClientLastName').hasError('maxlength')"
                >
                  {{ getCharactersLimitError('editClientLastName') }}
                </div>
              </div>
            </div>
          </div>
          <div class="uk-width-1-2@s uk-width-1-3@m" *ngIf="clientType == 'Organisation'">
            <div class="uk-margin">
              <label class="uk-form-label" for="editClientBID">Business identification number</label>
              <div class="uk-form-controls">
                <input id="editClientBID" name="editClientBID" formControlName="editClientBID"
                       [ngModel]="activeClient.bid" type="text" placeholder="BID ..." class="uk-input"
                       [ngClass]="{'uk-form-danger': editClientForm.get('editClientBID').touched && editClientForm.get('editClientBID').hasError('required') }">
                <div class="uk-text-danger uk-text-small"
                     *ngIf="editClientForm.get('editClientBID').hasError('maxlength')"
                >
                  {{ getCharactersLimitError('editClientBID') }}
                </div>
              </div>
            </div>
          </div>
          <!--<div class="uk-width-1-2@s uk-width-1-3@m" *ngIf="clientType == 'privatePerson'">
            <div class="uk-margin">
              <label class="uk-form-label" for="editClientSSN">Social security number</label>
              <div class="uk-form-controls">
                <input id="editClientSSN" name="editClientSSN" formControlName="editClientSSN" [ngModel]="activeClient.bid"  type="text" placeholder="BID ..." class="uk-input" [ngClass]="{'uk-form-danger': editClientForm.get('editClientSSN').touched && editClientForm.get('editClientSSN').hasError('required') }">
              </div>
            </div>
          </div>-->
          <div class="uk-width-1-2@s uk-width-1-3@m">
            <div class="uk-margin">
              <label class="uk-form-label" for="editClientClientCode">Client code</label>
              <div class="uk-form-controls">
                <input id="editClientClientCode" name="editClientClientCode" formControlName="editClientClientCode"
                       type="text" [ngModel]="activeClient.clientCode" placeholder="Client code ..." class="uk-input"
                       [ngClass]="{'uk-form-danger': editClientForm.get('editClientClientCode').touched && editClientForm.get('editClientClientCode').hasError('required') }">
                <div class="uk-text-danger uk-text-small"
                     *ngIf="editClientForm.get('editClientClientCode').hasError('maxlength')"
                >
                  {{ getCharactersLimitError('editClientClientCode') }}
                </div>
              </div>
            </div>
          </div>
          <div class="uk-width-1-2@s uk-width-1-3@m">
            <div class="uk-margin">
              <label class="uk-form-label" for="editClientBillingInfo">Billing information</label>
              <div class="uk-form-controls">
                <input id="editClientBillingInfo" name="editClientBillingInfo" formControlName="editClientBillingInfo"
                       type="text" [ngModel]="activeClient.billingInfo" placeholder="Billing information ..."
                       class="uk-input"
                       [ngClass]="{'uk-form-danger': editClientForm.get('editClientBillingInfo').touched && editClientForm.get('editClientBillingInfo').hasError('required') }">
                <div class="uk-text-danger uk-text-small"
                     *ngIf="editClientForm.get('editClientBillingInfo').hasError('maxlength')"
                >
                  {{ getCharactersLimitError('editClientBillingInfo') }}
                </div>
              </div>
            </div>
          </div>
          <div class="uk-width-1-2@s uk-width-1-3@m">
            <div class="uk-margin">
              <div class="uk-flex uk-flex-between uk-text-meta">
                <label class="uk-form-label" for="editClientDescription">Description</label>
                <div class="uk-text-small">
                  {{ getCharactersLeft('editClientDescription', 2048) }}
                </div>
              </div>
              <div class="uk-form-controls">
                <textarea id="editClientDescription" name="editClientDescription"
                          formControlName="editClientDescription" [ngModel]="activeClient.description" rows="5"
                          placeholder="Description ..." class="uk-textarea"
                          [ngClass]="{'uk-form-danger': editClientForm.get('editClientDescription').touched && editClientForm.get('editClientDescription').errors }"></textarea>
                <div class="uk-text-danger uk-text-small"
                     *ngIf="editClientForm.get('editClientDescription').hasError('maxlength')"
                >
                  {{ getCharactersLimitError('editClientDescription') }}
                </div>
              </div>
            </div>
          </div>
          <div class="uk-width-1-2@s uk-width-1-3@m">
            <div class="uk-margin">
              <label class="uk-form-label" for="editClientGroup">Client groups</label>
              <div class="uk-form-controls">
                <ng-select
                  [items]="activeClientClientGroups"
                  [ngModel]="selectedClientGroups"
                  [ngModelOptions]="{standalone: true}"
                  [multiple]="true"
                  [addTag]="false"
                  [selectOnTab]="false"
                  [closeOnSelect]="false"
                  [clearable]="false"
                  bindLabel="title"
                  placeholder="Please select ..."
                  id="editClientGroup"
                  class="uk-select"
                  #editClientGroup
                  (change)="editClientGroup.blur()"
                >
                  <ng-template ng-option-tmp let-item="item">
                    <div class="uk-margin-small-right" (click)="setClientGroups(item)">
                      <input class="uk-checkbox uk-margin-small-right" type="checkbox"
                             [checked]="clientGroupIsSelected(item)">
                      {{item.title}}
                    </div>
                  </ng-template>
                </ng-select>
                <div class="uk-text-danger uk-text-small"
                     *ngIf="editClientForm.get('editClientGroup').hasError('maxlength')"
                >
                  {{ getCharactersLimitError('editClientGroup') }}
                </div>
              </div>
            </div>
          </div>
          <div class="uk-width-1-2@s uk-width-1-3@m">
            <div class="uk-margin">
              <label class="uk-form-label" for="editLanguages">Languages</label>
              <div class="uk-form-controls">
                <ng-select
                  [items]="activeClientLanguages"
                  [ngModel]="selectedLanguages"
                  [ngModelOptions]="{standalone: true}"
                  [multiple]="true"
                  [addTag]="false"
                  [selectOnTab]="false"
                  [closeOnSelect]="false"
                  [clearable]="false"
                  bindLabel="title"
                  placeholder="Please select ..."
                  id="editLanguages"
                  class="uk-select"
                  #editLanguage
                  (change)="editLanguage.blur()"
                >
                  <ng-template ng-option-tmp let-item="item">
                    <div class="uk-margin-small-right" (click)="setLanguages(item)">
                      <input class="uk-checkbox uk-margin-small-right" type="checkbox"
                             [checked]="languageIsSelected(item)">
                      {{item.title}}
                    </div>
                  </ng-template>
                </ng-select>
              </div>
            </div>
          </div>
          <div class="uk-width-1-2@s uk-width-1-3@m">
            <div class="uk-margin">
              <label class="uk-form-label" for="editClientOrganisation">Organisation</label>
              <div class="uk-form-controls">
                <input id="editClientOrganisation" name="editClientOrganisation"
                       formControlName="editClientOrganisation" type="text" [ngModel]="activeClient.organisation"
                       placeholder="Organisation ..." class="uk-input"
                       [ngClass]="{'uk-form-danger': editClientForm.get('editClientOrganisation').touched && editClientForm.get('editClientOrganisation').errors }">
                <div class="uk-text-danger uk-text-small"
                     *ngIf="editClientForm.get('editClientOrganisation').hasError('maxlength')"
                >
                  {{ getCharactersLimitError('editClientOrganisation') }}
                </div>
              </div>
            </div>
          </div>

          <div class="uk-width-1-2@s uk-width-1-3@m">
            <div class="uk-margin">
              <label class="uk-form-label" for="editClientContactMethod">Contact method</label>
              <div class="uk-form-controls">
                <ng-select
                  [items]="activeClientContactMethods"
                  [ngModel]="selectedContactMethods"
                  [ngModelOptions]="{standalone: true}"
                  [multiple]="true"
                  [addTag]="false"
                  [selectOnTab]="false"
                  [closeOnSelect]="false"
                  [clearable]="false"
                  bindLabel="title"
                  placeholder="Please select ..."
                  id="editClientContactMethod"
                  class="uk-select"
                  #editClientContactMethod
                  (change)="editClientContactMethod.blur()"
                >
                  <ng-template ng-option-tmp let-item="item">
                    <div class="uk-margin-small-right" (click)="setContactMethod(item)">
                      <input class="uk-checkbox uk-margin-small-right" type="checkbox" [checked]="contactMethodIsSelected(item)">
                      {{item.title}}
                    </div>
                  </ng-template>
                </ng-select>
              </div>
            </div>
          </div>

          <div class="uk-width-1-1@s uk-margin-medium-top">
            <h3 class="uk-margin-remove-bottom">Contact information</h3>
            <hr class="uk-margin-small-top">
          </div>
          <div class="uk-width-1-2@s uk-width-1-3@m">
            <div class="uk-margin">
              <label class="uk-form-label" for="editClientStreetAddress">Street address</label>
              <div class="uk-form-controls">
                <input id="editClientStreetAddress" name="editClientStreetAddress"
                       formControlName="editClientStreetAddress" type="text" [ngModel]="activeClient.street"
                       placeholder="Street address ..." class="uk-input"
                       [ngClass]="{'uk-form-danger': editClientForm.get('editClientStreetAddress').touched && editClientForm.get('editClientStreetAddress').errors }">
                <div class="uk-text-danger uk-text-small"
                     *ngIf="editClientForm.get('editClientStreetAddress').hasError('maxlength')"
                >
                  {{ getCharactersLimitError('editClientStreetAddress') }}
                </div>
              </div>
            </div>
          </div>
          <div class="uk-width-1-2@s uk-width-1-3@m">
            <div class="uk-margin">
              <label class="uk-form-label" for="editClientCity">City</label>
              <div class="uk-form-controls">
                <input id="editClientCity" name="editClientCity" formControlName="editClientCity"
                       [ngModel]="activeClient.city" type="text" placeholder="City ..." class="uk-input"
                       [ngClass]="{'uk-form-danger': editClientForm.get('editClientCity').touched && editClientForm.get('editClientCity').errors }">
                <div class="uk-text-danger uk-text-small"
                     *ngIf="editClientForm.get('editClientCity').hasError('maxlength')"
                >
                  {{ getCharactersLimitError('editClientCity') }}
                </div>
              </div>
            </div>
          </div>
          <div class="uk-width-1-2@s uk-width-1-3@m">
            <div class="uk-margin">
              <label class="uk-form-label" for="editClientState">State</label>
              <div class="uk-form-controls">
                <input id="editClientState" name="editClientState" formControlName="editClientState" type="text"
                       [ngModel]="activeClient.state" placeholder="State ..." class="uk-input"
                       [ngClass]="{'uk-form-danger': editClientForm.get('editClientState').touched && editClientForm.get('editClientState').errors }">
                <div class="uk-text-danger uk-text-small"
                     *ngIf="editClientForm.get('editClientState').hasError('maxlength')"
                >
                  {{ getCharactersLimitError('editClientState') }}
                </div>
              </div>
            </div>
          </div>
          <div class="uk-width-1-2@s uk-width-1-3@m">
            <div class="uk-margin">
              <label class="uk-form-label" for="editClientZip">Postal code</label>
              <div class="uk-form-controls">
                <input id="editClientZip" name="editClientZip" formControlName="editClientZip"
                       [ngModel]="activeClient.zip" type="text" placeholder="Postal code ..." class="uk-input"
                       [ngClass]="{'uk-form-danger': editClientForm.get('editClientZip').touched && editClientForm.get('editClientZip').errors }">
                <div class="uk-text-danger uk-text-small"
                     *ngIf="editClientForm.get('editClientZip').hasError('maxlength')"
                >
                  {{ getCharactersLimitError('editClientZip') }}
                </div>
                <div class="uk-text-danger uk-text-small"
                     *ngIf="editClientForm.get('editClientZip').hasError('pattern')"
                >
                  Field value is incorrect
                </div>
              </div>
            </div>
          </div>
          <div class="uk-width-1-2@s uk-width-1-3@m">
            <div class="uk-margin">
              <label class="uk-form-label" for="editClientCountry">Country</label>
              <div class="uk-form-controls">
                <input id="editClientCountry" name="editClientCountry" formControlName="editClientCountry"
                       [ngModel]="activeClient.country" type="text" placeholder="Country ..." class="uk-input"
                       [ngClass]="{'uk-form-danger': editClientForm.get('editClientCountry').touched && editClientForm.get('editClientCountry').errors }">
                <div class="uk-text-danger uk-text-small"
                     *ngIf="editClientForm.get('editClientCountry').hasError('maxlength')"
                >
                  {{ getCharactersLimitError('editClientCountry') }}
                </div>
              </div>
            </div>
          </div>
          <div class="uk-width-1-2@s uk-width-1-3@m">
            <div class="uk-margin">
              <label class="uk-form-label" for="editClientPhone">Phone number</label>
              <div class="uk-form-controls">
                <input id="editClientPhone" name="editClientPhone" formControlName="editClientPhone" type="text"
                       [ngModel]="activeClient.phone" placeholder="Phone number ..." class="uk-input"
                       [ngClass]="{'uk-form-danger': editClientForm.get('editClientPhone').touched && editClientForm.get('editClientPhone').errors }">
                <div class="uk-text-danger uk-text-small"
                     *ngIf="editClientForm.get('editClientPhone').hasError('maxlength')"
                >
                  {{ getCharactersLimitError('editClientPhone') }}
                </div>
                <div class="uk-text-danger uk-text-small"
                     *ngIf="editClientForm.get('editClientPhone').hasError('pattern')"
                >
                  Field value is incorrect
                </div>
              </div>
            </div>
          </div>
          <div class="uk-width-1-2@s uk-width-1-3@m">
            <div class="uk-margin">
              <label class="uk-form-label" for="editClientEmail">Email</label>
              <div class="uk-form-controls">
                <input id="editClientEmail" name="editClientEmail" formControlName="editClientEmail" type="text"
                       [ngModel]="activeClient.email" placeholder="Email ..." class="uk-input"
                       [ngClass]="{'uk-form-danger': editClientForm.get('editClientEmail').touched && editClientForm.get('editClientEmail').errors }">
                <div class="uk-text-danger uk-text-small"
                     *ngIf="editClientForm.get('editClientEmail').hasError('maxlength')"
                >
                  {{ getCharactersLimitError('editClientEmail') }}
                </div>
              </div>
            </div>
          </div>
          <div class="uk-width-1-1 uk-margin-medium-top" *ngIf="clientType == 'Organisation'">
            <h5 class="uk-margin-remove-bottom">Visiting address</h5>
            <hr class="uk-margin-small-top">
          </div>
          <div class="uk-width-1-2@s" *ngIf="clientType == 'Organisation'">
            <div class="uk-margin">
              <label class="uk-form-label" for="editClientVisitingStreetAddress">Street address</label>
              <div class="uk-form-controls">
                <input id="editClientVisitingStreetAddress" name="editClientVisitingStreetAddress"
                       formControlName="editClientVisitingStreetAddress" type="text"
                       [ngModel]="activeClient.visitingStreet" placeholder="Street address ..." class="uk-input"
                       [ngClass]="{'uk-form-danger': editClientForm.get('editClientVisitingStreetAddress').touched && editClientForm.get('editClientVisitingStreetAddress').errors }">
                <div class="uk-text-danger uk-text-small"
                     *ngIf="editClientForm.get('editClientVisitingStreetAddress').hasError('maxlength')"
                >
                  {{ getCharactersLimitError('editClientVisitingStreetAddress') }}
                </div>
              </div>
            </div>
          </div>
          <div class="uk-width-1-2@s" *ngIf="clientType == 'Organisation'">
            <div class="uk-margin">
              <label class="uk-form-label" for="editClientVisitingCity">City</label>
              <div class="uk-form-controls">
                <input id="editClientVisitingCity" name="editClientVisitingCity"
                       formControlName="editClientVisitingCity" type="text" [ngModel]="activeClient.visitingCity"
                       placeholder="City ..." class="uk-input"
                       [ngClass]="{'uk-form-danger': editClientForm.get('editClientVisitingCity').touched && editClientForm.get('editClientVisitingCity').errors }">
                <div class="uk-text-danger uk-text-small"
                     *ngIf="editClientForm.get('editClientVisitingCity').hasError('maxlength')"
                >
                  {{ getCharactersLimitError('editClientVisitingCity') }}
                </div>
              </div>
            </div>
          </div>
          <div class="uk-width-1-2@s" *ngIf="clientType == 'Organisation'">
            <div class="uk-margin">
              <label class="uk-form-label" for="editClientVisitingState">State</label>
              <div class="uk-form-controls">
                <input id="editClientVisitingState" name="editClientVisitingState"
                       formControlName="editClientVisitingState" type="text" [ngModel]="activeClient.visitingState"
                       placeholder="State ..." class="uk-input"
                       [ngClass]="{'uk-form-danger': editClientForm.get('editClientVisitingState').touched && editClientForm.get('editClientVisitingState').errors }">
                <div class="uk-text-danger uk-text-small"
                     *ngIf="editClientForm.get('editClientVisitingState').hasError('maxlength')"
                >
                  {{ getCharactersLimitError('editClientVisitingState') }}
                </div>
              </div>
            </div>
          </div>
          <div class="uk-width-1-2@s" *ngIf="clientType == 'Organisation'">
            <div class="uk-margin">
              <label class="uk-form-label" for="editClientVisitingZip">Postal code</label>
              <div class="uk-form-controls">
                <input id="editClientVisitingZip" name="editClientVisitingZip" formControlName="editClientVisitingZip"
                       type="text" [ngModel]="activeClient.visitingZip" placeholder="Postal code ..." class="uk-input"
                       [ngClass]="{'uk-form-danger': editClientForm.get('editClientVisitingZip').touched && editClientForm.get('editClientVisitingZip').errors }">
                <div class="uk-text-danger uk-text-small"
                     *ngIf="editClientForm.get('editClientVisitingZip').hasError('maxlength')"
                >
                  {{ getCharactersLimitError('editClientVisitingZip') }}
                </div>
                <div class="uk-text-danger uk-text-small"
                     *ngIf="editClientForm.get('editClientVisitingZip').hasError('pattern')"
                >
                  Field value is incorrect
                </div>
              </div>
            </div>
            <div class="uk-margin">
              <label class="uk-form-label" for="editClientVisitingCountry">Country</label>
              <div class="uk-form-controls">
                <input id="editClientVisitingCountry" name="editClientVisitingCountry"
                       formControlName="editClientVisitingCountry" type="text" [ngModel]="activeClient.visitingCountry"
                       placeholder="Country ..." class="uk-input"
                       [ngClass]="{'uk-form-danger': editClientForm.get('editClientVisitingCountry').touched && editClientForm.get('editClientVisitingCountry').errors }">
                <div class="uk-text-danger uk-text-small"
                     *ngIf="editClientForm.get('editClientVisitingCountry').hasError('maxlength')"
                >
                  {{ getCharactersLimitError('editClientVisitingCountry') }}
                </div>
              </div>
            </div>
          </div>
          <div class="uk-width-1-1@s uk-margin-medium-top">
            <h5 class="uk-margin-remove-bottom">Contact person</h5>
            <hr class="uk-margin-small-top">
          </div>
          <div class="uk-width-1-3@s">
            <div class="uk-margin">
              <label class="uk-form-label" for="editClientContactPersonName">Name</label>
              <div class="uk-form-controls">
                <input id="editClientContactPersonName" name="editClientContactPersonName"
                       formControlName="editClientContactPersonName" type="text"
                       [ngModel]="activeClient.contactPersonName" placeholder="Name ..." class="uk-input"
                       [ngClass]="{'uk-form-danger': editClientForm.get('editClientContactPersonName').touched && editClientForm.get('editClientContactPersonName').errors }">
                <div class="uk-text-danger uk-text-small"
                     *ngIf="editClientForm.get('editClientContactPersonName').hasError('maxlength')"
                >
                  {{ getCharactersLimitError('editClientContactPersonName') }}
                </div>
              </div>
            </div>
          </div>
          <div class="uk-width-1-3@s" *ngIf="clientType == 'Organisation'">
            <div class="uk-margin">
              <label class="uk-form-label" for="editClientContactPersonLastName">Last name</label>
              <div class="uk-form-controls">
                <input id="editClientContactPersonLastName" name="editClientContactPersonLastName"
                       formControlName="editClientContactPersonLastName" type="text"
                       [ngModel]="activeClient.contactPersonLastName" placeholder="Last name ..." class="uk-input"
                       [ngClass]="{'uk-form-danger': editClientForm.get('editClientContactPersonLastName').touched && editClientForm.get('editClientContactPersonLastName').errors }">
                <div class="uk-text-danger uk-text-small"
                     *ngIf="editClientForm.get('editClientContactPersonLastName').hasError('maxlength')"
                >
                  {{ getCharactersLimitError('editClientContactPersonLastName') }}
                </div>
              </div>
            </div>
          </div>
          <div class="uk-width-1-3@s">
            <div class="uk-margin">
              <label class="uk-form-label" for="editClientContactPersonRole">Role</label>
              <div class="uk-form-controls">
                <input id="editClientContactPersonRole" name="editClientContactPersonRole"
                       formControlName="editClientContactPersonRole" type="text"
                       [ngModel]="activeClient.contactPersonRole" placeholder="Role ..." class="uk-input"
                       [ngClass]="{'uk-form-danger': editClientForm.get('editClientContactPersonRole').touched && editClientForm.get('editClientContactPersonRole').errors }">
                <div class="uk-text-danger uk-text-small"
                     *ngIf="editClientForm.get('editClientContactPersonRole').hasError('maxlength')"
                >
                  {{ getCharactersLimitError('editClientContactPersonRole') }}
                </div>
              </div>
            </div>
          </div>
          <div class="uk-width-1-3@s">
            <div class="uk-margin">
              <label class="uk-form-label" for="editClientContactPersonEmail">Email</label>
              <div class="uk-form-controls">
                <input id="editClientContactPersonEmail" name="editClientContactPersonEmail"
                       formControlName="editClientContactPersonEmail" type="text"
                       [ngModel]="activeClient.contactPersonEmail" placeholder="Email ..." class="uk-input"
                       [ngClass]="{'uk-form-danger': editClientForm.get('editClientContactPersonEmail').touched && editClientForm.get('editClientContactPersonEmail').errors }">
                <div class="uk-text-danger uk-text-small"
                     *ngIf="editClientForm.get('editClientContactPersonEmail').hasError('maxlength')"
                >
                  {{ getCharactersLimitError('editClientContactPersonEmail') }}
                </div>
              </div>
            </div>
          </div>
          <div class="uk-width-1-3@s">
            <div class="uk-margin">
              <label class="uk-form-label" for="editClientContactPersonPhone">Phone number</label>
              <div class="uk-form-controls">
                <input id="editClientContactPersonPhone" name="editClientContactPersonPhone"
                       formControlName="editClientContactPersonPhone" type="text"
                       [ngModel]="activeClient.contactPersonPhone" placeholder="Phone number ..." class="uk-input"
                       [ngClass]="{'uk-form-danger': editClientForm.get('editClientContactPersonPhone').touched && editClientForm.get('editClientContactPersonPhone').errors }">
                <div class="uk-text-danger uk-text-small"
                     *ngIf="editClientForm.get('editClientContactPersonPhone').hasError('maxlength')"
                >
                  {{ getCharactersLimitError('editClientContactPersonPhone') }}
                </div>
                <div class="uk-text-danger uk-text-small"
                     *ngIf="editClientForm.get('editClientContactPersonPhone').hasError('pattern')"
                >
                  Field value is incorrect
                </div>
              </div>
            </div>
          </div>
          <div class="uk-width-1-1 uk-margin-medium-top" *ngIf="clientType == 'Organisation'">
            <h5 class="uk-margin-remove-bottom">Billing information</h5>
            <hr class="uk-margin-small-top">
          </div>
          <div class="uk-width-1-2@s" *ngIf="clientType == 'Organisation'">
            <div class="uk-margin">
              <label class="uk-form-label" for="editClientBillingPostalCode">Postal code</label>
              <div class="uk-form-controls">
                <input id="editClientBillingPostalCode" name="editClientBillingPostalCode"
                       formControlName="editClientBillingPostalCode" type="text"
                       [ngModel]="activeClient.billingPostalCode" placeholder="Postal code ..." class="uk-input"
                       [ngClass]="{'uk-form-danger': editClientForm.get('editClientBillingPostalCode').touched && editClientForm.get('editClientBillingPostalCode').errors }">
                <div class="uk-text-danger uk-text-small"
                     *ngIf="editClientForm.get('editClientBillingPostalCode').hasError('maxlength')"
                >
                  {{ getCharactersLimitError('editClientBillingPostalCode') }}
                </div>
                <div class="uk-text-danger uk-text-small"
                     *ngIf="editClientForm.get('editClientBillingPostalCode').hasError('pattern')"
                >
                  Field value is incorrect
                </div>
              </div>
            </div>
          </div>
          <div class="uk-width-1-2@s" *ngIf="clientType == 'Organisation'">
            <div class="uk-margin">
              <label class="uk-form-label" for="editClientBillingElectronicInvoiceAddress">
                Electronic invoice address</label>
              <div class="uk-form-controls">
                <input id="editClientBillingElectronicInvoiceAddress" name="editClientBillingElectronicInvoiceAddress"
                       formControlName="editClientBillingElectronicInvoiceAddress" type="text"
                       [ngModel]="activeClient.billingElectronicInvoiceAddress" placeholder="
Electronic invoice address ..." class="uk-input"
                       [ngClass]="{'uk-form-danger': editClientForm.get('editClientBillingElectronicInvoiceAddress').touched && editClientForm.get('editClientBillingElectronicInvoiceAddress').errors }">
                <div class="uk-text-danger uk-text-small"
                     *ngIf="editClientForm.get('editClientBillingElectronicInvoiceAddress').hasError('maxlength')"
                >
                  {{ getCharactersLimitError('editClientBillingElectronicInvoiceAddress') }}
                </div>
              </div>
            </div>
          </div>
          <div class="uk-width-1-2@s" *ngIf="clientType == 'Organisation'">
            <div class="uk-margin">
              <label class="uk-form-label" for="editClientBillingEmail">Email</label>
              <div class="uk-form-controls">
                <input id="editClientBillingEmail" name="editClientBillingEmail"
                       formControlName="editClientBillingEmail" type="text" [ngModel]="activeClient.billingEmail"
                       placeholder="Email ..." class="uk-input"
                       [ngClass]="{'uk-form-danger': editClientForm.get('editClientBillingEmail').touched && editClientForm.get('editClientBillingEmail').errors }">
                <div class="uk-text-danger uk-text-small"
                     *ngIf="editClientForm.get('editClientBillingEmail').hasError('maxlength')"
                >
                  {{ getCharactersLimitError('editClientBillingEmail') }}
                </div>
              </div>
            </div>
          </div>
          <div class="uk-width-1-1 uk-margin-medium-top">
            <h3 class="uk-margin-remove-bottom">Artist prospects</h3>
            <hr class="uk-margin-small-top">
          </div>
          <div class="uk-width-1-1 uk-text-right">
            <button class="uk-button uk-button-default" type="button" (click)="openAddInterestingArtist()">Add new
            </button>
            <div class="uk-width-1-1@s">
              <div class="uk-margin">
                <div
                  *ngIf="activeClient.interestingArtists && activeClient.interestingArtists.length; else noInterestingArtists">
                  <table class="uk-table uk-table-middle uk-table-divider">
                    <thead>
                    <tr>
                      <th class="uk-table-shrink">Image</th>
                      <th class="uk-width-expand">Name</th>
                      <th></th>
                    </tr>
                    </thead>
                    <tbody>
                    <tr *ngFor="let interestingArtist of activeClient.interestingArtists; let index = index">
                      <td>
                        <div
                          class="image-max-height uk-preserve-width uk-background-contain uk-background-muted uk-height-small uk-panel uk-flex uk-flex-center uk-flex-middle"
                          width="60"
                          [attr.data-src]="'/api/storagefile/' + activeAccount + '/' + interestingArtist.coverImage + '/small'"
                          uk-img *ngIf="interestingArtist.coverImage">
                        </div>
                        <div
                          class="image-max-height uk-preserve-width uk-background-contain uk-background-muted uk-height-small uk-panel uk-flex uk-flex-center uk-flex-middle"
                          width="60"
                          [attr.data-src]="'/api/storagefile/' + activeAccount + '/' + interestingArtist.images[0].fileId + '/small'"
                          uk-img
                          *ngIf="!interestingArtist.coverImage && interestingArtist.images && interestingArtist.images.length">
                        </div>
                        <div
                          class="image-max-height uk-preserve-width uk-background-contain uk-background-muted uk-height-small uk-panel uk-flex uk-flex-center uk-flex-middle"
                          width="60" [attr.data-src]="'/api/image/artbuddy-logo-placeholder.svg'" uk-img
                          *ngIf="!interestingArtist.coverImage && !interestingArtist.images">
                        </div>
                      </td>
                      <td class="uk-text-left">{{ interestingArtist.name }} {{ interestingArtist.lastName }}</td>
                      <td>
                        <div class="uk-button-group">
                          <div class="uk-inline" class="uk-visible@m">
                            <button class="uk-button uk-button-default" type="button"><span
                              uk-icon="icon:  chevron-down"></span></button>
                            <div uk-dropdown="mode: click; boundary: ! .uk-button-group; boundary-align: true;">
                              <ul class="uk-nav uk-dropdown-nav">
                                <li><a (click)="openArtist(interestingArtist._id)">Open</a></li>
                                <li><a (click)="toggleInterestingArtist(interestingArtist)">Remove</a></li>
                              </ul>
                            </div>
                          </div>
                        </div>
                      </td>
                    </tr>
                    </tbody>
                  </table>
                </div>
                <ng-template #noInterestingArtists>
                  <p class="uk-text-left">No artists added</p>
                </ng-template>
              </div>
            </div>
          </div>
          <div class="uk-width-1-1 uk-margin-medium-top">
            <h3 class="uk-margin-remove-bottom">External notes</h3>
            <hr class="uk-margin-small-top">
          </div>
          <div class="uk-width-1-1 uk-text-right">
            <button class="uk-button uk-button-default" type="button" (click)="openAddExternalNotes()">Add new</button>
            <div class="uk-width-1-1@s">
              <div class="uk-margin">
                <div *ngIf="activeClient.externalNotes && activeClient.externalNotes.length; else noExternalNotes">
                  <table class="uk-table uk-table-divider">
                    <thead>
                    <tr>
                      <th class="uk-table-expand uk-text-nowrap">Note</th>
                      <th class="uk-width-medium"></th>
                    </tr>
                    </thead>
                    <tbody>
                    <tr *ngFor="let externalNote of activeClient.externalNotes; let index = index">
                      <td class="uk-text-left">
                        <span class="prewrap" [innerHTML]="externalNote.note | shorten: 250: '...'"
                              *ngIf="!externalNote.expand"></span>
                        <span class="prewrap" [innerHTML]="externalNote.note" *ngIf="externalNote.expand"></span>
                      </td>
                      <td class="uk-width-medium uk-text-top">
                        <div class="uk-button-group">
                          <button (click)="toggleHeight(externalNote)" class="uk-button uk-button-text uk-margin-right"
                                  type="button"
                                  *ngIf="externalNote.note.length > 250">{{ externalNote.expand ? 'Hide' : 'Expand' }}</button>
                          <div class="uk-inline" class="uk-visible@m">
                            <button class="uk-button uk-button-default" type="button"><span
                              uk-icon="icon:  chevron-down"></span></button>
                            <div uk-dropdown="mode: click; boundary: ! .uk-button-group; boundary-align: true;">
                              <ul class="uk-nav uk-dropdown-nav">
                                <li><a (click)="openEditExternalNotes(externalNote)">Edit</a></li>
                                <li><a (click)="removeExternalNotes(externalNote)">Remove</a></li>
                              </ul>
                            </div>
                          </div>
                        </div>
                      </td>
                    </tr>
                    </tbody>
                  </table>
                </div>
                <ng-template #noExternalNotes>
                  <p class="uk-text-left">No notes added</p>
                </ng-template>
              </div>
            </div>
          </div>
          <div class="uk-width-1-1@s uk-margin-medium-top">
            <h3 class="uk-margin-remove-bottom">Additional information</h3>
            <hr class="uk-margin-small-top">
          </div>
          <div class="uk-width-1-1@s">
            <div class="uk-margin">
              <label class="uk-form-label">Attachments</label>
              <div class="uk-form-controls uk-margin-small">
                <dl class="uk-description-list" *ngIf="activeClient.attachments.length">
                  <div class="uk-flex uk-flex-middle" *ngFor="let attachment of activeClient.attachments">
                    <div class="uk-padding-small">
                      <img class="contenttype" width="40" height="40"
                           src="/api/storagefile/{{ activeAccount }}/{{ attachment.fileId }}/small"
                           *ngIf="['image/png', 'image/jpg', 'image/jpeg', 'image/heic', 'image/gif', 'image/svg+xml'].includes(attachment.contentType)"/>
                      <img class="contenttype" width="40" height="40" src="/api/file/5a5de6b1229e86403d67db11"
                           *ngIf="['application/msword', 'application/vnd.openxmlformats-officedocument.wordprocessingml.document'].includes(attachment.contentType)"/>
                      <img class="contenttype" width="40" height="40" src="/api/file/5a5de6b1229e86403d67db10"
                           *ngIf="['application/octet-stream', 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'].includes(attachment.contentType)"/>
                      <img class="contenttype" width="40" height="40" src="/api/file/5a5de6b1229e86403d67db12"
                           *ngIf="['application/pdf'].includes(attachment.contentType)"/>
                      <img class="contenttype" width="40" height="40" src="/api/file/5a5de7bc229e86403d67db1b"
                           *ngIf="['text/plain'].includes(attachment.contentType)"/>
                      <img class="contenttype" width="40" height="40" src="/api/file/5a5de6b1229e86403d67db0f"
                           *ngIf="!(['image/png', 'image/jpg', 'image/jpeg', 'image/heic', 'image/gif', 'image/svg+xml', 'application/msword', 'application/vnd.openxmlformats-officedocument.wordprocessingml.document', 'application/octet-stream', 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet', 'application/pdf', 'text/plain'].includes(attachment.contentType))"/>
                    </div>
                    <div class="uk-width-expand">{{ attachment.name }}</div>
                    <div class="uk-button-group">
                      <button (click)="openAttachment(attachment)" class="uk-button uk-button-default" type="button">
                        Open
                      </button>
                      <div class="uk-inline uk-text-right">
                        <button class="uk-button uk-button-default" type="button"><span
                          uk-icon="icon:  chevron-down"></span></button>
                        <div uk-dropdown="mode: click; boundary: ! .uk-button-group; boundary-align: true;">
                          <ul class="uk-nav uk-dropdown-nav">
                            <li><a (click)="removeAttachment(attachment)">Remove</a></li>
                          </ul>
                        </div>
                      </div>
                    </div>
                  </div>
                </dl>
                <p *ngIf="!activeClient.attachments.length">No related attachments</p>
              </div>
            </div>
          </div>
          <div class="uk-width-1-1@s">
            <div class="uk-margin">
              <label class="uk-form-label">Add new attachments</label>
              <div class="uk-form-controls">
                <div class="drop-container" ngFileDrop [options]="options"
                     (uploadOutput)="onAttachmentsUploadOutput($event)" (click)="attachmentFile.click()"
                     [uploadInput]="uploadInput">
                  <div class="js-upload uk-placeholder uk-text-center" [ngClass]="{ 'is-drop-over': dragOver }">
                    <span
                      class="uk-text-middle">Add files by dropping them here or open file dialog by clicking here.<br>Maximum allowed file size is 5 Mb.</span>
                  </div>
                  <input #attachmentFile type="file" ngFileSelect [options]="{ concurrency: 1, maxFileSize: 5000000 }"
                         [uploadInput]="uploadInput" (uploadOutput)="onAttachmentsUploadOutput($event)" multiple
                         class="file-input">
                </div>
              </div>
            </div>
          </div>
          <div class="uk-width-1-2@s uk-width-1-3@m">
            <div class="uk-margin">
              <div class="uk-flex uk-flex-between uk-text-meta">
                <label class="uk-form-label" for="editClientNotes">Other information</label>
                <div class="uk-text-small">
                  {{ getCharactersLeft('editClientNotes', 2048) }}
                </div>
              </div>
              <div class="uk-form-controls">
                <textarea id="editClientNotes" name="editClientNotes" formControlName="editClientNotes" rows="5"
                          [ngModel]="activeClient.notes" type="text" placeholder="Other information ..."
                          class="uk-textarea"
                          [ngClass]="{'uk-form-danger': editClientForm.get('editClientNotes').touched && editClientForm.get('editClientNotes').errors }"></textarea>
                <div class="uk-text-danger uk-text-small"
                     *ngIf="editClientForm.get('editClientNotes').hasError('maxlength')"
                >
                  {{ getCharactersLimitError('editClientNotes') }}
                </div>
              </div>
            </div>
          </div>
          <div class="uk-width-1-2@s uk-width-1-3@m">
            <div class="uk-margin">
              <div class="uk-flex uk-flex-between uk-text-meta">
                <label class="uk-form-label" for="editClientComments">Comments</label>
                <div class="uk-text-small">
                  {{ getCharactersLeft('editClientComments', 2048) }}
                </div>
              </div>
              <div class="uk-form-controls">
                <textarea id="editClientComments" name="editClientComments" formControlName="editClientComments"
                          rows="5" [ngModel]="activeClient.comments" type="text" placeholder="Comments ..."
                          class="uk-textarea"
                          [ngClass]="{'uk-form-danger': editClientForm.get('editClientComments').touched && editClientForm.get('editClientComments').errors }"></textarea>
                <div class="uk-text-danger uk-text-small"
                     *ngIf="editClientForm.get('editClientComments').hasError('maxlength')"
                >
                  {{ getCharactersLimitError('editClientComments') }}
                </div>
              </div>
            </div>
          </div>
          <div class="uk-width-1-2@s uk-width-1-3@m">
            <div class="uk-margin">
              <label class="uk-form-label" for="editClientTags">Tags</label>
              <div class="uk-form-controls">
                <input id="editClientTags" name="editClientTags" formControlName="editClientTags"
                       [ngModel]="activeClient.tags" type="text" placeholder="Tags ..." class="uk-input"
                       [ngClass]="{'uk-form-danger': editClientForm.get('editClientTags').touched && editClientForm.get('editClientTags').errors }">
                <div class="uk-text-danger uk-text-small"
                     *ngIf="editClientForm.get('editClientTags').hasError('maxlength')"
                >
                  {{ getCharactersLimitError('editClientTags') }}
                </div>
              </div>
            </div>
          </div>
          <div class="uk-width-1-2@s uk-width-1-3@m">
            <div class="uk-margin">
              <label class="uk-form-label" for="editClientVisibility">Visibility</label>
              <div class="uk-form-controls">
                <label><input id="editClientVisibility" name="editClientVisibility"
                              formControlName="editClientVisibility" [ngModel]="activeClient.visibility"
                              class="uk-checkbox" type="checkbox"> Public</label>
              </div>
            </div>
          </div>
          <div class="uk-width-1-2@s uk-width-1-3@m">
            <div class="uk-margin">
              <label class="uk-form-label" for="editClientStatus">Status</label>
              <div class="uk-form-controls">
                <label><input id="editClientStatus" name="editClientStatus" formControlName="editClientStatus"
                              [ngModel]="activeClient.status" class="uk-checkbox" type="checkbox"> Active</label>
              </div>
            </div>
          </div>
          <div class="uk-width-1-1 uk-margin-medium-top">
            <hr class="uk-margin-small-top">
          </div>
          <div class="uk-width-1-1 uk-margin-remove sticky-bottom uk-background-default" uk-sticky="bottom: true">
            <div class="uk-width-1-1">
            <div class="uk-flex uk-flex-middle uk-flex-between uk-margin">
              <app-editing-history
                [updatedAt]="activeClient.updatedAt"
                [name]="activeClient.requestUser?.name"
                [email]="activeClient.requestUser?.email"
              ></app-editing-history>
              <div class="uk-text-right">
                <button type="submit" [disabled]="!editClientForm.valid || isLoading" class="uk-button uk-button-primary">Save
                </button>
              </div>
            </div>
          </div>
          </div>
        </form>
      </li>
      <li id="clientartworks" class="uk-padding-small" *ngIf="activeClientArtworks.length">
        <table class="uk-table uk-table-middle uk-table-divider">
          <thead>
          <tr>
            <th class="uk-table-shrink">Image</th>
            <th class="uk-text-wrap" [ngClass]="{'active': activeSort('name') }" (click)="sortBids('name')">Name<span
              uk-icon="chevron-down" [ngClass]="{'inverse': !sortAscending }"></span></th>
            <th class="uk-text-nowrap uk-visible@m" [ngClass]="{'active': activeSort('technique') }"
                (click)="sortArtworks('technique')">Technique<span uk-icon="chevron-down"
                                                                   [ngClass]="{'inverse': !sortAscending }"></span></th>
            <th class="uk-text-nowrap uk-visible@m" [ngClass]="{'active': activeSort('availability') }"
                (click)="sortArtworks('availability')">Availability<span uk-icon="chevron-down"
                                                                         [ngClass]="{'inverse': !sortAscending }"></span>
            </th>
            <th class="uk-text-nowrap uk-visible@m" [ngClass]="{'active': activeSort('visibility') }"
                (click)="sortArtworks('visibility')">Visibility<span uk-icon="chevron-down"
                                                                     [ngClass]="{'inverse': !sortAscending }"></span>
            </th>
            <th class="uk-table-shrink"></th>
          </tr>
          </thead>

          <tbody>
          <tr *ngFor="let artwork of activeClientArtworks">
            <td>
              <!-- Näytetään coverImage jos on -->
              <div
                class="image-max-height uk-preserve-width uk-background-contain uk-background-muted uk-height-small uk-panel uk-flex uk-flex-center uk-flex-middle"
                width="60" [attr.data-src]="'/api/storagefile/' + activeAccount + '/' + artwork.coverImage + '/small'"
                uk-img *ngIf="artwork.coverImage">

              </div>

              <!-- Jos ei ole coverImagea, niin sit otetaan eka image -->
              <div
                class="image-max-height uk-preserve-width uk-background-contain uk-background-muted uk-height-small uk-panel uk-flex uk-flex-center uk-flex-middle"
                width="60"
                [attr.data-src]="'/api/storagefile/' + activeAccount + '/' + artwork.images[0].fileId + '/small'" uk-img
                *ngIf="!artwork.coverImage && artwork.images && artwork.images.length">

              </div>

              <!-- Jos ei ole coverImagea tai ekan imagea, ni sit otetaan placeholder -->
              <div
                class="image-max-height uk-preserve-width uk-background-contain uk-background-muted uk-height-small uk-panel uk-flex uk-flex-center uk-flex-middle"
                width="60" [attr.data-src]="'/api/image/artbuddy-logo-placeholder.svg'" uk-img
                *ngIf="!artwork.coverImage && !artwork.images.length">
              </div>
            </td>
            <td>{{ artwork.name }}</td>
            <td>{{ artwork.technique }}</td>
            <td>{{ artwork.availability }}</td>
            <td>
              <span *ngIf="artwork.visibility">Visible</span>
              <span *ngIf="!artwork.visibility">Hidden</span>
            </td>
            <td class="uk-text-right">
              <div class="uk-button-group">
                <button (click)="openArtwork(artwork._id)" class="uk-button uk-button-default" type="button">Open
                </button>
              </div>
            </td>
          </tr>
          </tbody>
        </table>
      </li>
      <li id="clientartists" class="uk-padding-small" *ngIf="activeClientArtists.length">
        <table class="uk-table uk-table-middle uk-table-divider">
          <thead>
          <tr>
            <th class="uk-table-shrink">Image</th>
            <th class="uk-width-expand">Name</th>
            <th class="uk-width-expand uk-text-nowrap">Multiplier</th>
            <th class="uk-table-shrink uk-text-nowrap">Status</th>
            <th class="uk-table-shrink"></th>
          </tr>
          </thead>
          <tbody>
          <tr *ngFor="let artist of activeClientArtists">
            <td>
              <!-- Näytetään coverImage jos on -->
              <div
                class="image-max-height uk-preserve-width uk-background-contain uk-background-muted uk-height-small uk-panel uk-flex uk-flex-center uk-flex-middle"
                width="60" [attr.data-src]="'/api/storagefile/' + activeAccount + '/' + artist.coverImage + '/small'"
                uk-img *ngIf="artist.coverImage">
              </div>
              <!-- Jos ei ole coverImagea, niin sit otetaan eka image -->
              <div
                class="image-max-height uk-preserve-width uk-background-contain uk-background-muted uk-height-small uk-panel uk-flex uk-flex-center uk-flex-middle"
                width="60"
                [attr.data-src]="'/api/storagefile/' + activeAccount + '/' + artist.images[0].fileId + '/small'" uk-img
                *ngIf="!artist.coverImage && artist.images && artist.images.length">
              </div>
              <!-- Jos ei ole coverImagea tai ekan imagea, ni sit otetaan placeholder -->
              <div
                class="image-max-height uk-preserve-width uk-background-contain uk-background-muted uk-height-small uk-panel uk-flex uk-flex-center uk-flex-middle"
                width="60" [attr.data-src]="'/api/image/artbuddy-logo-placeholder.svg'" uk-img
                *ngIf="!artist.coverImage && !artist.images.length">
              </div>
            </td>
            <td>{{ artist.name }} {{ artist.lastName }}</td>
            <td>{{ artist.priceMultiplier }}</td>
            <td>
              <span *ngIf="artist.status">Active</span>
              <span *ngIf="!artist.status">Inactive</span>
            </td>
            <td class="uk-text-right">
              <button (click)="openArtist(artist._id)" class="uk-button uk-button-default" type="button">Open</button>
            </td>
          </tr>
          </tbody>
        </table>
      </li>
      <li id="clientbids" class="uk-padding-small" *ngIf="activeClientBids.length">
        <table class="uk-table uk-table-middle uk-table-divider">
          <thead>
          <tr>
            <th class="uk-text-nowrap" [ngClass]="{'active': activeSort('sequence') }" (click)="sortBids('sequence')">
              Number<span uk-icon="chevron-down" [ngClass]="{'inverse': !sortAscending }"></span></th>
            <th class="uk-text-wrap" [ngClass]="{'active': activeSort('name') }" (click)="sortBids('name')">Name<span
              uk-icon="chevron-down" [ngClass]="{'inverse': !sortAscending }"></span></th>
            <th class="uk-text-nowrap uk-visible@m" [ngClass]="{'active': activeSort('seller') }"
                (click)="sortBids('seller')">Seller<span uk-icon="chevron-down"
                                                         [ngClass]="{'inverse': !sortAscending }"></span></th>
            <th class="uk-text-nowrap uk-visible@m" [ngClass]="{'active': activeSort('type') }"
                (click)="sortBids('type')">Type<span uk-icon="chevron-down"
                                                     [ngClass]="{'inverse': !sortAscending }"></span></th>
            <th class="uk-text-nowrap uk-visible@m" [ngClass]="{'active': activeSort('price') }"
                (click)="sortBids('price')">Price<span uk-icon="chevron-down"
                                                       [ngClass]="{'inverse': !sortAscending }"></span></th>
            <th class="uk-text-nowrap uk-visible@m" [ngClass]="{'active': activeSort('valid') }"
                (click)="sortBids('valid')">Valid<span uk-icon="chevron-down"
                                                       [ngClass]="{'inverse': !sortAscending }"></span></th>
            <th class="uk-table-shrink"></th>
          </tr>
          </thead>
          <tbody>
          <tr *ngFor="let bid of activeClientBids">
            <!--<td>
              <img class="uk-preserve-width uk-border-circle" src="/api/image/artbuddy-logo-placeholder.svg" *ngIf="!bid.images.length" width="60">
              <img class="uk-preserve-width" [src]="'/api/storagefile/' + bid.images[0].fileId" width="60" *ngIf="bid.images.length">
            </td>-->
            <!--[ngStyle]="{'background-image': 'url(/api/storagefile/' + activeAccount + '/' + image.fileId + ')'}"-->
            <td>{{ bid.sequence }}</td>
            <td>{{ bid.name }}</td>
            <td class="uk-visible@m">{{ bid.seller.name }}</td>
            <td class="uk-visible@m">{{ bid.type }}</td>
            <td class="uk-visible@m">{{ bid.price }} {{ bid.currency }}</td>
            <td class="uk-visible@m">{{ bid.valid | date: 'dd.MM.yyyy' }}</td>
            <td class="uk-text-right">
              <button (click)="openBid(bid._id)" class="uk-button uk-button-default" type="button">Open</button>
            </td>
          </tr>
          </tbody>
        </table>
      </li>
      <li id="clientreservations" class="uk-padding-small" *ngIf="activeClientReservations.length">
        <table class="uk-table uk-table-middle uk-table-divider">
          <thead>
          <tr>
            <th class="uk-text-nowrap" [ngClass]="{'active': activeSort('sequence') }"
                (click)="sortReservations('sequence')">Number<span uk-icon="chevron-down"
                                                                   [ngClass]="{'inverse': !sortAscending }"></span></th>
            <th class="uk-text-wrap" [ngClass]="{'active': activeSort('name') }" (click)="sortReservations('name')">Name<span
              uk-icon="chevron-down" [ngClass]="{'inverse': !sortAscending }"></span></th>
            <th class="uk-text-nowrap uk-visible@m" [ngClass]="{'active': activeSort('seller') }"
                (click)="sortReservations('seller')">Seller<span uk-icon="chevron-down"
                                                                 [ngClass]="{'inverse': !sortAscending }"></span></th>
            <th class="uk-text-nowrap uk-visible@m" [ngClass]="{'active': activeSort('type') }"
                (click)="sortReservations('type')">Type<span uk-icon="chevron-down"
                                                             [ngClass]="{'inverse': !sortAscending }"></span></th>
            <th class="uk-text-nowrap uk-visible@m" [ngClass]="{'active': activeSort('valid') }"
                (click)="sortReservations('valid')">Valid<span uk-icon="chevron-down"
                                                               [ngClass]="{'inverse': !sortAscending }"></span></th>
            <th class="uk-table-shrink"></th>
          </tr>
          </thead>
          <tbody>
          <tr *ngFor="let reservation of activeClientReservations">
            <!--<td>
              <img class="uk-preserve-width uk-border-circle" src="/api/image/artbuddy-logo-placeholder.svg" *ngIf="!reservation.images.length" width="60">
              <img class="uk-preserve-width" [src]="'/api/storagefile/' + reservation.images[0].fileId" width="60" *ngIf="reservation.images.length">
            </td>-->
            <!--[ngStyle]="{'background-image': 'url(/api/storagefile/' + activeAccount + '/' + image.fileId + ')'}"-->
            <td>{{ reservation.sequence }}</td>
            <td>{{ reservation.name }}</td>
            <td class="uk-visible@m">{{ reservation.seller.name }}</td>
            <td class="uk-visible@m">{{ reservation.type }}</td>
            <td class="uk-visible@m">{{ reservation.valid | date: 'dd.MM.yyyy' }}</td>
            <td class="uk-text-right">
              <button (click)="openReservation(reservation._id)" class="uk-button uk-button-default" type="button">
                Open
              </button>
            </td>
          </tr>
          </tbody>
        </table>
      </li>
      <li id="clienttrades" class="uk-padding-small" *ngIf="activeClientTrades.length">
        <div class="uk-width-1-1 uk-text-right uk-margin-small-bottom uk-padding-remove">
          <button class="uk-button uk-button-default" type="button">
            View: {{ viewList ? 'Trades' : 'Artworks' }}</button>
          <div uk-dropdown="mode: click;">
            <ul class="uk-nav uk-dropdown-nav">
              <li [ngClass]="{'uk-active': viewList }" (click)="setViewList(true)"><a>Trades</a></li>
              <li [ngClass]="{'uk-active': !viewList }" (click)="setViewList(false)"><a>Artworks</a></li>
            </ul>
          </div>
        </div>
        <table class="uk-table uk-table-middle uk-table-divider" *ngIf="viewList">
          <thead>
          <tr>
            <th class="uk-text-nowrap" [ngClass]="{'active': activeSort('sequence') }" (click)="sortTrades('sequence')">
              Number<span uk-icon="chevron-down" [ngClass]="{'inverse': !sortAscending }"></span></th>
            <th class="uk-text-nowrap" [ngClass]="{'active': activeSort('name') }" (click)="sortTrades('name')">
              Name<span uk-icon="chevron-down" [ngClass]="{'inverse': !sortAscending }"></span></th>
            <th class="uk-text-nowrap uk-visible@m" [ngClass]="{'active': activeSort('actor') }"
                (click)="sortTrades('actor')">Actor<span uk-icon="chevron-down"
                                                         [ngClass]="{'inverse': !sortAscending }"></span></th>
            <th class="uk-text-nowrap uk-visible@m" [ngClass]="{'active': activeSort('type') }"
                (click)="sortTrades('type')">Type<span uk-icon="chevron-down"
                                                       [ngClass]="{'inverse': !sortAscending }"></span></th>
            <th class="uk-text-nowrap uk-visible@m" [ngClass]="{'active': activeSort('price') }"
                (click)="sortTrades('price')">Price<span uk-icon="chevron-down"
                                                         [ngClass]="{'inverse': !sortAscending }"></span></th>
            <th class="uk-table-shrink"></th>
          </tr>
          </thead>
          <tbody>
          <tr *ngFor="let trade of activeClientTrades">
            <td>{{ trade.sequence }}</td>
            <td>{{ trade.name }}</td>
            <td class="uk-visible@m">{{ trade.seller.name }}</td>
            <td class="uk-visible@m">{{ trade.type }}</td>
            <td class="uk-visible@m">{{ trade.price }} {{ trade.currency }}</td>
            <td class="uk-text-right">
              <button (click)="openTrade(trade._id)" class="uk-button uk-button-default" type="button">Open</button>
            </td>
          </tr>
          </tbody>
        </table>
        <table class="uk-table uk-table-middle uk-table-divider" *ngIf="!viewList">
          <thead>
          <tr>
            <th class="uk-text-nowrap" [ngClass]="{'active': activeSort('sequence') }" (click)="sortTrades('sequence')">
              Number<span uk-icon="chevron-down" [ngClass]="{'inverse': !sortAscending }"></span></th>
            <th class="uk-text-nowrap" [ngClass]="{'active': activeSort('name') }" (click)="sortTrades('name')">
              Name<span uk-icon="chevron-down" [ngClass]="{'inverse': !sortAscending }"></span></th>
            <th class="uk-text-nowrap uk-visible@m" [ngClass]="{'active': activeSort('actor') }"
                (click)="sortTrades('actor')">Actor<span uk-icon="chevron-down"
                                                         [ngClass]="{'inverse': !sortAscending }"></span></th>
            <th class="uk-text-nowrap uk-visible@m" [ngClass]="{'active': activeSort('type') }"
                (click)="sortTrades('type')">Type<span uk-icon="chevron-down"
                                                       [ngClass]="{'inverse': !sortAscending }"></span></th>
            <th class="uk-text-nowrap uk-visible@m" [ngClass]="{'active': activeSort('price') }"
                (click)="sortTrades('price')">Price<span uk-icon="chevron-down"
                                                         [ngClass]="{'inverse': !sortAscending }"></span></th>
            <th class="uk-table-shrink"></th>
          </tr>
          </thead>
          <tbody>
          <ng-container *ngFor="let trade of activeClientTrades">
            <tr>
              <td>{{ trade.sequence }}</td>
              <td>{{ trade.name }}</td>
              <td class="uk-visible@m">{{ trade.seller.name }}</td>
              <td class="uk-visible@m">{{ trade.type }}</td>
              <td class="uk-visible@m">{{ trade.price }} {{ trade.currency }}</td>
              <td class="uk-text-right">
                <button (click)="openTrade(trade._id)" class="uk-button uk-button-default" type="button">Open</button>
              </td>
            </tr>
            <tr *ngFor="let artwork of trade.items">
              <td></td>
              <td>
                <!-- Näytetään coverImage jos on -->
                <div
                  class="image-max-height uk-preserve-width uk-background-contain uk-background-muted uk-height-small uk-panel uk-flex uk-flex-center uk-flex-middle"
                  width="60" [attr.data-src]="'/api/storagefile/' + activeAccount + '/' + artwork.coverImage + '/small'"
                  uk-img *ngIf="artwork.coverImage">
                </div>
                <!-- Jos ei ole coverImagea, niin sit otetaan eka image -->
                <div
                  class="image-max-height uk-preserve-width uk-background-contain uk-background-muted uk-height-small uk-panel uk-flex uk-flex-center uk-flex-middle"
                  width="60"
                  [attr.data-src]="'/api/storagefile/' + activeAccount + '/' + artwork.images[0].fileId + '/small'"
                  uk-img *ngIf="!artwork.coverImage && artwork.images && artwork.images.length">
                </div>
                <!-- Jos ei ole coverImagea tai ekan imagea, ni sit otetaan placeholder -->
                <div
                  class="image-max-height uk-preserve-width uk-background-contain uk-background-muted uk-height-small uk-panel uk-flex uk-flex-center uk-flex-middle"
                  width="60" [attr.data-src]="'/api/image/artbuddy-logo-placeholder.svg'" uk-img
                  *ngIf="!artwork.coverImage && !artwork.images.length">
                </div>
              </td>
              <td>{{ artwork.name }}</td>
              <td>{{ artwork.technique }}</td>
              <td></td>
              <td class="uk-text-right">
                <div class="uk-button-group">
                  <button (click)="openArtwork(artwork._id)" class="uk-button uk-button-default" type="button">Open
                  </button>
                </div>
              </td>
            </tr>
          </ng-container>
          </tbody>
        </table>
      </li>
    </ul>
  </div>
</div>

<div id="addInterestingArtist" uk-modal="bg-close: false; esc-close: false;">
  <div class="uk-modal-dialog uk-height-large">
    <div class="uk-modal-header">
      <h2 class="uk-modal-title">Add new artist prospect</h2>
    </div>
    <div class="uk-modal-body">
      <div class="uk-width-1-1@s">
        <div class="uk-margin">
          <!--              <textarea id="addInterestingArtistFormArtist" name="addInterestingArtistFormArtist" formControlName="addInterestingArtistFormArtist" rows="10" autofocus placeholder="Note ..." class="uk-textarea" [ngClass]="{'uk-form-danger': addInterestingArtistForm.get('addInterestingArtistFormArtist').touched && addInterestingArtistForm.get('addInterestingArtistFormArtist').errors }"></textarea>-->
          <div class="uk-panel uk-height-large no-resize no-border">
            <div class="uk-flex uk-flex-row uk-flex-wrap uk-flex-middle uk-margin-small-bottom"
                 *ngFor="let artist of allArtists">
              <input
                type="checkbox"
                (click)="toggleInterestingArtist(artist)"
                [checked]="isInInterestingArtists(artist)"
                class="uk-checkbox uk-margin-right"
              >
              <div class="uk-margin-right">
                <div
                  class="image-max-height uk-preserve-width uk-background-contain uk-background-muted uk-height-small uk-panel uk-flex uk-flex-center uk-flex-middle"
                  width="60" [attr.data-src]="'/api/storagefile/' + activeAccount + '/' + artist.coverImage + '/small'"
                  uk-img *ngIf="artist.coverImage">
                </div>
                <div
                  class="image-max-height uk-preserve-width uk-background-contain uk-background-muted uk-height-small uk-panel uk-flex uk-flex-center uk-flex-middle"
                  width="60"
                  [attr.data-src]="'/api/storagefile/' + activeAccount + '/' + artist.images[0].fileId + '/small'"
                  uk-img *ngIf="!artist.coverImage && artist.images && artist.images.length">
                </div>
                <div
                  class="image-max-height uk-preserve-width uk-background-contain uk-background-muted uk-height-small uk-panel uk-flex uk-flex-center uk-flex-middle"
                  width="60" [attr.data-src]="'/api/image/artbuddy-logo-placeholder.svg'" uk-img
                  *ngIf="!artist.coverImage && !artist.images.length">
                </div>
              </div>
              <div>{{ artist.name }} ({{ artist.lastName }})</div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="uk-modal-footer">
      <p class="uk-text-right">
        <button class="uk-button uk-button-default uk-modal-close uk-margin-small-right" type="button">Close</button>
      </p>
    </div>
  </div>
</div>

<div id="addExternalNotes" uk-modal="bg-close: false; esc-close: false;">
  <div class="uk-modal-dialog height-auto">
    <form appNoSubmitEnter [formGroup]="addExternalNotesForm" (ngSubmit)="addExternalNotes()" autocomplete="off"
          class="uk-form-stacked">
      <input type="hidden" name="addExternalNotesFormClient" name="addExternalNotesFormClient"
             formControlName="addExternalNotesFormClient" [ngModel]="activeClientId">
      <div class="uk-modal-header">
        <h2 class="uk-modal-title">Add new note</h2>
      </div>
      <div class="uk-modal-body">
        <div class="uk-width-1-1@s">
          <div class="uk-margin">
            <div class="uk-flex uk-flex-between uk-text-meta">
              <label class="uk-form-label" for="addExternalNotesFormNote">Note</label>
            </div>
            <div class="uk-form-controls">
              <textarea id="addExternalNotesFormNote" name="addExternalNotesFormNote"
                        formControlName="addExternalNotesFormNote" rows="10" autofocus placeholder="Note ..."
                        class="uk-textarea"
                        [ngClass]="{'uk-form-danger': addExternalNotesForm.get('addExternalNotesFormNote').touched && addExternalNotesForm.get('addExternalNotesFormNote').errors }"></textarea>
            </div>
          </div>
        </div>
      </div>
      <div class="uk-modal-footer">
        <p class="uk-text-right">
          <button class="uk-button uk-button-default uk-modal-close uk-margin-small-right" type="button"
                  id="addExternalNotesCancel" (click)="resetExternalNotesForm()">Cancel
          </button>
          <button type="submit" [disabled]="!addExternalNotesForm.valid" class="uk-button uk-button-primary">Add
          </button>
        </p>
      </div>
    </form>
  </div>
</div>

<div id="viewExternalNotes" uk-modal="bg-close: false; esc-close: false;">
  <div class="uk-modal-dialog height-auto">
    <div class="uk-modal-header">
      <h2 class="uk-modal-title">View note</h2>
    </div>
    <div class="uk-modal-body">
      <div class="uk-width-1-1@s">
        <div class="prewrap">{{ getExternalNote() }}</div>
      </div>
    </div>
    <div class="uk-modal-footer">
      <p class="uk-text-right">
        <button class="uk-button uk-button-default uk-modal-close" type="button" (click)="resetActiveExternalNote()">
          Close
        </button>
      </p>
    </div>
  </div>
</div>

<div id="editExternalNotes" uk-modal="bg-close: false; esc-close: false;">
  <div class="uk-modal-dialog height-auto">
    <form appNoSubmitEnter [formGroup]="editExternalNotesForm" (ngSubmit)="editExternalNotes()" class="uk-form-stacked">
      <input type="hidden" name="editExternalNotesFormClient" name="editExternalNotesFormClient"
             formControlName="editExternalNotesFormClient" [ngModel]="activeClientId">
      <div class="uk-modal-header">
        <h2 class="uk-modal-title">Edit notes</h2>
      </div>
      <div class="uk-modal-body">
        <div class="uk-width-1-1@s">
          <div class="uk-margin">
            <div class="uk-flex uk-flex-between uk-text-meta">
              <label class="uk-form-label" for="editExternalNotesFormNote">Note</label>
            </div>
            <div class="uk-form-controls">
              <textarea id="editExternalNotesFormNote" name="editExternalNotesFormNote"
                        formControlName="editExternalNotesFormNote" rows="10" autofocus placeholder="Note ..."
                        class="uk-textarea"
                        [ngClass]="{'uk-form-danger': editExternalNotesForm.get('editExternalNotesFormNote').touched && editExternalNotesForm.get('editExternalNotesFormNote').errors }"></textarea>
            </div>
          </div>
        </div>
      </div>
      <div class="uk-modal-footer">
        <p class="uk-text-right">
          <button class="uk-button uk-button-default uk-modal-close uk-margin-small-right" type="button"
                  id="editExternalNotescancel" (click)="resetExternalNotesForm()">Cancel
          </button>
          <button type="submit" [disabled]="!editExternalNotesForm.valid" class="uk-button uk-button-primary">Save
          </button>
        </p>
      </div>
    </form>
  </div>
</div>
