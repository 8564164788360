import {Component, EventEmitter, OnInit, ViewChild} from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { UserService } from '../../services/user.service';
import { EstimateService } from '../../services/estimate.service';
import { AccountService } from '../../services/account.service';
import { AuthService } from '../../services/auth.service';
import { ActiveService } from '../../services/active.service';
import { FileService } from '../../services/file.service';
import { SearchService } from '../../services/search.service';

import { UploadInput, UploadFile, humanizeBytes, UploaderOptions } from 'ngx-uploader';
import {AngularMyDatePickerDirective, IAngularMyDpOptions, IMyDateModel} from 'angular-mydatepicker';
import * as moment from 'moment/moment';
declare var UIkit: any;

@Component({
  selector: 'app-estimates',
  templateUrl: './estimates.component.html',
  styleUrls: ['./estimates.component.scss']
})
export class EstimatesComponent implements OnInit {
  public user: any = {};
  public activeEstimate: any = {};
  private activeUser: string;
  public actorType: string;
  public activeAccount: string;
  public account: Object = {};
  public artists: Array<Object> = [];
  public actors: Array<Object> = [];
  public estimates: Array<Object> = [];
  public variables: Array<string> = [];
  public isLoading = true;
  public addEstimateForm: FormGroup;
  public addNewEstimateModalWindow: any;
  public advancedArtworkSearchModalWindow: any;
  public showAdvancedArtworkSearchModalWindow: boolean = false;
  public sortingKey: string;
  public sortAscending: boolean = false;
  public currentPage: number = 1;
  public limitQuery: number = 10;
  public pageCount: number = 1;
  public pages: Array<any> = [];
  public searchArtworkForm: FormGroup;
  public searchArtworkResults: Array<Object> = [];
  public selectedArtworks: Array<Object> = [];
  public searchResultsFound: boolean = false;
  public myDatePickerOptions: IAngularMyDpOptions = {
    dateFormat: 'dd.mm.yyyy'
  };
  private todaysDate: IMyDateModel;
  private validDate: IMyDateModel;
  @ViewChild('AngularMyDatePickerAddEstimateCreated') AngularMyDatePickerAddEstimateCreated: AngularMyDatePickerDirective;
  public options: UploaderOptions;
  public formData: FormData;
  public filesToUpload: UploadFile[];
  public uploadInput: EventEmitter<UploadInput>;
  public humanizeBytes: Function;
  public dragOver: boolean;
  public imageLoadOffset$: any;

  constructor(
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private formBuilder: FormBuilder,
    private auth: AuthService,
    private activeService: ActiveService,
    private userService: UserService,
    private accountService: AccountService,
    private estimateService: EstimateService,
    private fileService: FileService,
    private searchService: SearchService
  ) {
    this.activatedRoute.data.subscribe(data => {
      this.activeAccount = data.resolvedData.account.id;
      this.user = data.resolvedData.user.id;
    });

    this.todaysDate = {
      isRange: false,
      singleDate: {date: {year: new Date().getFullYear(), month: new Date().getMonth() + 1, day: new Date().getDate()}}
    };
    this.validDate = {
      isRange: false,
      singleDate: {date: {year: new Date().getFullYear(), month: new Date().getMonth() + 1, day: new Date().getDate()}}
    };

    this.addEstimateForm = this.formBuilder.group({
      addEstimateOwner: new FormControl('', [
        Validators.required,
        Validators.maxLength(100)
      ]),
      addEstimateName: new FormControl('', [
        Validators.required,
        Validators.maxLength(100)
      ]),
      addEstimateDescription: new FormControl('', [
        Validators.maxLength(2048)
      ]),
      addEstimateEstimator: new FormControl('', [
        Validators.required,
        Validators.maxLength(100)
      ]),
      addEstimateCreated: new FormControl('', [
        Validators.maxLength(100)
      ]),
      addEstimateBasis: new FormControl('', [
        Validators.maxLength(100)
      ]),
      addEstimateCurrency: new FormControl({value: '', disabled: true}, [
        Validators.maxLength(100)
      ]),
      addEstimateMinValue: new FormControl('', [
        Validators.maxLength(100)
      ]),
      addEstimateMaxValue: new FormControl('', [
        Validators.maxLength(100)
      ]),
      addEstimateInsuranceValue: new FormControl('', [
        Validators.maxLength(100)
      ]),
      addEstimateShowNotes: new FormControl('', [
        Validators.maxLength(100)
      ]),
      addEstimateAttachments: new FormControl('', [
        Validators.maxLength(100)
      ]),
      addEstimateTags: new FormControl('', [
        Validators.maxLength(100)
      ]),
      addEstimateNotes: new FormControl('', [
        Validators.maxLength(2048)
      ]),
      addEstimateComments: new FormControl('', [
        Validators.maxLength(2048)
      ]),
      addEstimateVisibility: new FormControl('', [
        Validators.maxLength(100)
      ])
    });

    this.searchArtworkForm = this.formBuilder.group({
      searchArtworkTerm: new FormControl('', [
        Validators.required,
        Validators.minLength(3),
        Validators.maxLength(50)
      ])
    });

    this.activeEstimate.items = [];
    this.filesToUpload = [];
    this.uploadInput = new EventEmitter<UploadInput>();
    this.humanizeBytes = humanizeBytes;
  }

  ngOnInit() {
    this.isLoading = true;
    this.addEstimateForm.patchValue({
      addEstimateOwner: this.activeAccount
    });
    this.getAccountData();

    this.activeService.getActiveData().subscribe(
      data => {
        this.activeUser = data.user.id;
      }
    );
  }

  getAccountData() {
    const getObject = {
      accountId: this.activeAccount,
      requester: 'estimates',
      sortingKey: this.sortingKey ? this.sortingKey : '_id',
      sortingOrder: this.sortAscending ? 'asc' : 'desc',
      skipQuery: (this.currentPage - 1) * this.limitQuery,
      limitQuery: this.limitQuery,
      contentTypes: ['account', 'actor', 'estimate', 'estimateCount', 'variable']
    };

    this.accountService.getAccountData(getObject).subscribe(
      data => {
        this.account = data.account;
        this.actors = data.actors;
        this.estimates = data.estimates;
        this.variables = data.variables ? data.variables : [];

        this.pageCount = Math.ceil(data.estimateCount / this.limitQuery);
        if (this.pageCount > 0) {
          this.pages = Array(this.pageCount).fill(null);
        }

        this.addEstimateForm.patchValue({
          addEstimateCurrency: 'EUR'
        });
      },
      () => {
        UIkit.notification({
          message: 'Error fetching estimates',
          status: 'danger',
          pos: 'top-right',
          timeout: 1500
        });
      },
      () => {
        this.isLoading = false;

      }
    );
  }

  openAddNewEstimate() {
    this.addNewEstimateModalWindow = window.document.getElementById('addNewEstimate');
    UIkit.modal(this.addNewEstimateModalWindow).show();
  }

  addEstimate() {
    const addEstimateObject = {
      owner: this.addEstimateForm.value.addEstimateOwner,
      name: this.addEstimateForm.value.addEstimateName,
      description: this.addEstimateForm.value.addEstimateDescription,
      items: this.activeEstimate.items,
      estimator: this.addEstimateForm.value.addEstimateEstimator,
      created: this.addEstimateForm.value.addEstimateCreated ? moment(this.addEstimateForm.value.addEstimateCreated.singleDate.jsDate).format() : moment(new Date),
      estimateBasis: this.addEstimateForm.value.addEstimateBasis,
      currency: this.addEstimateForm.value.addEstimateCurrency,
      minValue: this.addEstimateForm.value.addEstimateMinValue,
      maxValue: this.addEstimateForm.value.addEstimateMaxValue,
      insuranceValue: this.addEstimateForm.value.addEstimateInsuranceValue,
      showNotes: this.addEstimateForm.value.addEstimateShowNotes,
      /*attachments: this.addEstimateForm.value.addEstimateAttachments,*/
      tags: this.addEstimateForm.value.addEstimateTags,
      notes: this.addEstimateForm.value.addEstimateNotes,
      comments: this.addEstimateForm.value.addEstimateComments,
      visibility: this.addEstimateForm.value.addEstimateVisibility
    };
    this.estimateService.addEstimate(addEstimateObject).subscribe(
      res => {
        this.ngOnInit();
        UIkit.modal(this.addNewEstimateModalWindow).hide();
        this.resetForm();
        UIkit.notification({
          message: 'Saved successfully',
          status: 'success',
          pos: 'top-right',
          timeout: 1500
        });
      },
      error => {
        UIkit.notification({
          message: 'Saving failed',
          status: 'danger',
          pos: 'top-right',
          timeout: 1500
        });
      }
    );
  }

  openEstimate(estimateId): void {
    this.router.navigateByUrl('/estimate/' + estimateId);
  }

  removeEstimate(estimateId): void {
    this.estimateService.deleteEstimate(estimateId).subscribe(
      () => {
        this.ngOnInit();
        UIkit.notification({
          message: 'Estimate removed successfully',
          status: 'success',
          pos: 'top-right',
          timeout: 1500
        });
      },
      () => {
        UIkit.notification({
          message: 'Error removing estimates',
          status: 'danger',
          pos: 'top-right',
          timeout: 1500
        });
      }
    );
  }

  sortEstimates(sortingKey: string): void {
    if (sortingKey !== this.sortingKey) {
      this.sortingKey = sortingKey;
    } else {
      this.sortAscending = !this.sortAscending;
    }
    this.getAccountData();
  }

  activeSort(key: string): boolean {
    return this.sortingKey === key;
  }

  resetForm(): void {
    this.addEstimateForm.reset();
    this.addEstimateForm.patchValue({
      addEstimateOwner: this.activeAccount,
      addEstimateEstimator: ''
    });

    this.activeEstimate.items = [];
    this.searchArtworkResults = [];
    this.emptySearch();
  }

  searchArtwork() {
    /*this.isLoading = true;*/
    if (this.searchArtworkForm.value.searchArtworkTerm.trim().length === 0) {
      return
    }

    const searchObject = {
      searchArtworkAccountId: this.activeAccount,
      searchArtworkUserId: this.activeUser,
      searchArtworkTerm: this.searchArtworkForm.value.searchArtworkTerm
    };
    this.searchService.searchArtwork(searchObject).subscribe(
      res => {
        if (res.length) {
          if (this.searchArtworkResults) {
            this.searchArtworkResults.length = 0;
          }
          res.forEach(artwork => {
            artwork.allResultType = 'artwork';
            this.searchArtworkResults.push(artwork);
          });
          /*this.isLoading = false;*/
          this.searchResultsFound = true;
          /*document.getElementById('search-artwork-results-container').classList.add('active');*/
          Array.prototype.forEach.call(document.getElementsByClassName('results-container'), function (element) {
            element.classList.add('active');
          });



          UIkit.notification({
            message: 'Search matched ' + this.searchArtworkResults.length + ' results',
            status: 'success',
            pos: 'top-right',
            timeout: 1500
          });
        } else {
          if (this.searchArtworkResults) {
            this.searchArtworkResults.length = 0;
          }
          UIkit.notification({
            message: 'No search results',
            status: 'danger',
            pos: 'top-right',
            timeout: 1500
          });
        }
      },
      error => {
        if (this.searchArtworkResults) {
          this.searchArtworkResults.length = 0;
        }
        UIkit.notification({
          message: 'No search results',
          status: 'danger',
          pos: 'top-right',
          timeout: 1500
        });
      }
    );
  }

  emptySearch(): void {
    /*document.getElementById('search-artwork-results-container').classList.remove('active');*/
    /*Array.prototype.forEach.call(document.getElementsByClassName('results-container'), function (element) {
      element.classList.remove('active');
    });*/

    /*let element: HTMLElement = document.getElementsByClassName('uk-navbar-toggle uk-close')[1] as HTMLElement;
    element.click();*/
    setTimeout(() => {
      this.searchResultsFound = false;
      if (this.searchArtworkResults) {
        this.searchArtworkResults.length = 0;
      }
      if (this.selectedArtworks) {
        this.selectedArtworks.length = 0;
      }
      this.searchArtworkForm.patchValue({ searchArtworkTerm: ''});
    }, 300);
  }

  selectEstimateArtwork(artwork): void {
    this.selectedArtworks.indexOf(artwork) === -1 ?
      this.selectedArtworks.push(artwork) : this.selectedArtworks.splice(this.selectedArtworks.indexOf(artwork), 1);
  }

  addArtworksToEstimate(): void {
    this.selectedArtworks.map((item: any) => {
      const itemFound = this.activeEstimate.items.find((activeEstimateItem: any) => {
        return activeEstimateItem._id === item._id;
      });
      if (itemFound === undefined) {
        this.activeEstimate.items.push(item);
      }
      const artistFound = this.artists.find((artist: any) => {
        return artist._id === item.artist;
      });
      if (artistFound === undefined) {
        this.artists.push({
          _id: item.artist,
          name: item.artistName,
          priceMultiplier: item.artistPriceMultiplier,
          status: item.artistStatus
        });
      }
    });

    this.emptySearch();
  }

  removeArtworkFromEstimate(item): void {
    this.activeEstimate.items.splice(this.activeEstimate.items.indexOf(item), 1);
    const artistToSplice = this.artists.find((artist: any) => {
      return artist._id === item.artist;
    });
    this.artists.splice(this.artists.indexOf(artistToSplice), 1);
  }

  openSlideshow($event): void {
    $event.preventDefault();
    UIkit.lightboxPanel({
      /* Ei tarvitse, jos muistaa käyttää $event.preventDefaultia
        autoplay: true,
        autoplayInterval: 5000,
        delayControls: 1000,*/
      items: this.activeEstimate.items.map(item => {
        let itemImage;
        if (item.coverImage) {
          itemImage = '/api/image/' + item.coverImage;
        } else if (item.images[0]) {
          itemImage = '/api/image/' + item.images[0].fileId;
        } else {
          itemImage = '/api/image/artbuddy-logo-placeholder.svg'
        }

        return {
          source: itemImage,
          caption: item.name
        };
      })
    }).show();
  }

  openAdvancedSearch(): void {
    this.showAdvancedArtworkSearchModalWindow = true;
    setTimeout(() => {
      this.advancedArtworkSearchModalWindow = window.document.getElementById('advancedArtworkSearch');
      UIkit.modal(this.advancedArtworkSearchModalWindow).show();
    }, 500);

  }

  setArtworkSearchResults(searchResult: Array<any>) {
    searchResult.forEach(item => this.selectedArtworks.push(item));
    this.addArtworksToEstimate();
  }

  setLimit(limit: number): void {
    if (limit !== this.limitQuery) {
      this.limitQuery = limit;
      /* Set currentPage to 0 to start from the first page */
      this.currentPage = 1;
      this.getAccountData();
    }
  }

  activeLimit(limit: number): boolean {
    return this.limitQuery === limit;
  }

  setCurrentPageString(page: string): void {
    this.setCurrentPage(parseInt(page, 0));
  }

  setCurrentPage(page: number): void {
    if ((page !== this.currentPage) && (page <= this.pageCount)) {
      this.currentPage = page;
      this.getAccountData();
    }
  }

  updateDate(fieldName: string, dateObject: any) {
    this.addEstimateForm.controls[fieldName].patchValue(moment(dateObject.singleDate.jsDate).format());
  }
}
